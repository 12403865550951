import { gql } from "apollo-boost";

export const SEE_CUPS = gql`
  query seeCups($isAll:Boolean) {
    seeCups(isAll:$isAll) {
      id
      host
      sponsor
      cupName
      cupProfile
      cupStartDate
      cupEndDate
      place {
        id
        placeName
        address
      }
      youtubeUrl
      target
      firstPrize
      secondPrize
      thirdPrize
      mvpPrize
      mannerPrize
      joinPrice
    }
  }
`;

export const CREATE_ACCOUNT = gql`
  mutation createAccountByAdmin(
    $avatar: String!
    $username: String!
    $email: String!
    $contact: String
  ) {
    createAccountByAdmin(
      avatar: $avatar
      username: $username
      email: $email
      contact: $contact
    ) {
      id
      avatar
      username
      email
      contact
      basket {
        id
      }
    }
  }
`;

export const UPSERT_BASKET = gql`
  mutation upsertBasket(
    $id: String!
    $userId: String!
    $position: String
    $height: Int
    $weight: Int
    $backNo: Int
    $level: Int
    $grade: String
  ) {
    upsertBasket(
      id: $id
      userId: $userId
      position: $position
      height: $height
      weight: $weight
      backNo: $backNo
      level: $level
      grade: $grade
    ) {
      id
    }
  }
`;

export const EDIT_TEAM = gql`
  mutation editTeamByAdmin(
    $id: String!
    $teamName: String
    $level: Int
    $contact: String
    $action: String!
  ) {
    editTeamByAdmin(
      id: $id
      teamName: $teamName
      level: $level
      contact: $contact
      action: $action
    ) {
      id
    }
  }
`;

export const DELETE_TEAM = gql`
  mutation editTeamByAdmin($id: String!, $action: String!) {
    editTeamByAdmin(id: $id, action: $action) {
      id
    }
  }
`;
