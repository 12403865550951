import React from 'react';
import styled from 'styled-components';
import { withRouter } from "react-router-dom";

const Container = styled.div`
  flex:1;
  align-items:center;
  justify-content:center;
  display:flex;
  background-color:#fff;
  color:#000;
  flex-direction: column;
`;

const Logo = styled.img`
  margin-bottom:20px;
  width: 100px;
  margin-bottom: 50px;
`;

const Code = styled.div`
  color:red;
  font-size:20px;
  margin-bottom: 15px;
`;
const Text = styled.div`
  font-size:18px;
`;

const HomeButton = styled.button`
  background-color:${props=>props.theme.yellowColor};
  border: 1px solid ${props=>props.theme.yellowColor};
`;

const InternalServer = (({ text, history }) => {
  return (
    <Container>
      <Logo src={"https://d1jozj1mdbjtd9.cloudfront.net/weplay_symbol.png"} />
      <Code>
        {"500 InternalServer Error"}
      </Code>
      <Text>
        {text}
      </Text>
      <HomeButton onClick={() => history.replace('/')}>
        홈 화면으로
      </HomeButton>

    </Container>
  )
});

export default withRouter(InternalServer);