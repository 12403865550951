import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { GET_GAME } from "./CupGameQueries";
import CupGamePresenter from "./CupGamePresenter";
import { useQuery } from "react-apollo-hooks";


const userColumn = [
  { title: "이름", field: "username", editable: "never" },
  { title: "포지션", field: "basket.position", editable: "never" },
  { title: "신장", field: "basket.height", editable: "never" },
  { title: "체중", field: "basket.weight", editable: "never" },
  {
    title: "득점",
    field: "thisUserStat.score",
    type: "numeric",
    emptyValue: 0
  },
  {
    title: "리바운드",
    field: "thisUserStat.rebound",
    type: "numeric",
    emptyValue: 0
  },
  {
    title: "어시스트",
    field: "thisUserStat.assist",
    type: "numeric",
    emptyValue: 0
  },
  {
    title: "블로킹",
    field: "thisUserStat.blocking",
    type: "numeric",
    emptyValue: 0
  },
  {
    title: "스틸",
    field: "thisUserStat.steal",
    type: "numeric",
    emptyValue: 0
  },
  { title: "파울", field: "thisUserStat.foul", type: "numeric", emptyValue: 0 },
  {
    title: "턴오버",
    field: "thisUserStat.turnOver",
    type: "numeric",
    emptyValue: 0
  }
];

const CupGameContainer = ({ match: { params } }) => {
  const cupId = params.cup;
  const gameId = params.game;

  const { data, loading } = useQuery(GET_GAME, {
    variables: {
      id: gameId.toString()
    }
  });

  const [homeScore, setHomeScore] = useState([0, 0, 0, 0]);
  const [awayScore, setAwayScore] = useState([0, 0, 0, 0]);

  const [homeUsers, setHomeUsers] = useState([]);
  const [awayUsers, setAwayUsers] = useState([]);

  const [playTime, setPlayTime] = useState("00:00");
  const [homeTeamFoul, setHomeTeamFoul] = useState(0);
  const [awayTeamFoul, setAwayTeamFoul] = useState(0);


  return (
    <CupGamePresenter
      loading={loading}
      data={data}
      cupId={cupId}
      gameId={gameId}
      homeUsers={homeUsers}
      setHomeUsers={setHomeUsers}
      awayUsers={awayUsers}
      setAwayUsers={setAwayUsers}
      homeScore={homeScore}
      setHomeScore={setHomeScore}
      awayScore={awayScore}
      setAwayScore={setAwayScore}
      userColumn={userColumn}
      playTime={playTime}
      setPlayTime={setPlayTime}
      homeTeamFoul={homeTeamFoul}
      setHomeTeamFoul={setHomeTeamFoul}
      awayTeamFoul={awayTeamFoul}
      setAwayTeamFoul={setAwayTeamFoul}
    />
  );
};

export default withRouter(CupGameContainer);
