import React from 'react';
import styled from "styled-components";
import { Refresh } from "./Icons";
const CardWrap = styled.div`
  display:flex;
  align-items:center;
  justify-content:center;
  color:#e1e1e1;
  font-size:14px;
  width: 90%;
  margin: 0 auto;
  border: 2px #1750a0;
  border-style: dashed;
  border-radius: 10px;
  padding: 50px;

  & > div {
    margin-right: 15px;
  }
`;

export default function BlankCard({ text, onRefresh = null }) {

  return (
    <CardWrap>
      <div onClick={onRefresh}><Refresh /></div>
      {text}
    </CardWrap>
  );
}