
import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { HttpLink } from "apollo-link-http";
import { onError } from "apollo-link-error";
import { ApolloLink, Observable, split } from "apollo-link";
import { WebSocketLink } from "apollo-link-ws";
//import { withClientState } from 'apollo-link-state';
import { getMainDefinition } from "apollo-utilities";
import { defaults, resolvers } from "./LocalState";

const TEST_HTTP = "http://localhost:5000/";
const TEST_WS = "ws://localhost:5000/";
console.log(`process.env.NODE_ENV:`,process.env.NODE_ENV);

const httpLink = new HttpLink({
  uri:
    process.env.NODE_ENV === "development"
      ? `${TEST_HTTP}`
      : "https://weplay2921.cafe24.com/"
});

// Create a WebSocket link:
const wsLink = new WebSocketLink({
  uri:
    process.env.NODE_ENV === "development"
      ? `${TEST_WS}`
      : "wss://weplay2921.cafe24.com/",
  options: {
    reconnect: true,
  }
});

const request = async operation => {
  //console.log(token);
  operation.setContext({
    clientState: {
      defaults,
      resolvers
    },
    headers: {
      //authorization: token
      Authorization: `Bearer ${localStorage.getItem("token")}`
    }
  });
};

const cache = new InMemoryCache();

const requestLink = new ApolloLink(
  (operation, forward) =>
    new Observable(observer => {
      let handle;
      Promise.resolve(operation)
        .then(oper => request(oper))
        .then(() => {
          handle = forward(operation).subscribe({
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer)
          });
        })
        .catch(observer.error.bind(observer));

      return () => {
        if (handle) handle.unsubscribe();
      };
    })
);

export default new ApolloClient({
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors)
        graphQLErrors.forEach(({ message, locations, path }) => {
          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
          );
          if (message.indexOf("log in") !== -1) {
            localStorage.removeItem("token");
            localStorage.removeItem("sport");
            localStorage.removeItem("uid");
            if(window.Android) {
              window.Android.passIsLoggedIn("false");
            }
            window.location = "/"; 
          }
        });
      if (networkError) console.log(`[Network error]: ${networkError}`);
    }),
    requestLink,
    //stateLink,
    split(
      // split based on operation type
      ({ query }) => {
        const definition = getMainDefinition(query);
        return (
          definition.kind === "OperationDefinition" &&
          definition.operation === "subscription"
        );
      },
      wsLink,
      httpLink
    )
  ]),
  //link,
  cache,
  resolvers,
  connectToDevTools:true
});

cache.writeData({
  data: {
    isLoggedIn: Boolean(localStorage.getItem("token")) || false,
    curSports: Boolean(localStorage.getItem("sport")) || "1001"
  }
});
