import { gql } from "apollo-boost";

export const SEE_NOTICES = gql`
  query seeNotices($cupId: String!) {
    seeNotices(cupId: $cupId) {
      id
      writer {
        id
        username
      }
      title
      text
      createdAt
    }
  }
`;
