import React from "react";
import styled from "styled-components";
import Loader from "../Loader";
import { Search } from "../Icons";

const Container = styled.div`
  margin: 0 auto;
  width: 100%;
  /* visibility: hidden; */
`;

const Map = styled.div`
  margin: 0 auto;
  width: 100%;
  height: 40vh;
  display: none;
`;

const AddressInput = styled.input`
  /* margin: 0.2em 0;
  width: 100%;
  height: 35px;
  border: 1px solid #ccc;
  border-color: #dbdbdb #d2d2d2 #d0d0d0 #d2d2d3;
  box-shadow: inset 0.1em 0.1em 0.15em rgba(0, 0, 0, 0.1);
  vertical-align: middle;
  border-radius: 4px;
  background-color: ${props => props.theme.whiteColor};
  line-height: 1.25em;
  padding: 5px 15px;
  outline: 0;
  color: #3a4149; */

  width: 100%;
  height: 40px;
  max-width: 500px;
  padding: 5px 15px;
  font-size: 16px;
  color : #000;
  background-color:#EBEBEB;
  border: ${props => props.theme.boxBorder};
  border-radius: 10px;
  font-family: 'NanumSqure';
  font-weight: 400;
`;

const AddressBtn = styled.input`
  display: inline-block;
  padding: 0.5em 1em;
  margin: 0.4em 0em;
  border: 1px solid #ccc;
  border-color: #dbdbdb #d2d2d2 #b2b2b2 #d2d2d3;
  cursor: pointer;
  color: #464646;
  border-radius: 0.2em;
  vertical-align: middle;
  font-size: 16px;
  line-height: 1.25em;
  background-color: #fff;
  border: 1px solid;
  border-radius: 8px;
`;

export default ({
  type,
  onDaumPostCode,
  kakao,
  daum,
  value,
  text,
  search
}) => {
  //const kakao = window.kakao;

  //console.log(kakao);
  if (kakao && daum) {
    return (
      <>
        {value ? (
          <AddressInput
            type="text"
            id="sample5_address"
            label="경기장"
            defaultValue={value}
            readOnly
          />
        ) : (
          <AddressInput
            readOnly
            type="text"
            id="sample5_address"
            defaultValue={''}
            placeholder="경기장을 검색해주세요"
          />
        )}
        {search && (
          <AddressBtn type="button" onClick={onDaumPostCode} value={text} />
        )}
        <Map id="map" />
      </>
    );
  } else {
    return (
      <Container>
        <Loader />
      </Container>
    );
  }
};
