import React from "react";
import MapPresenter from "./MapPresenter";

const MapContainer = ({
  isInit,
  lat,
  lng,
  placeName,
  address,
  showPlaceName = true,
  showAddress
}) => {
  return (
    <MapPresenter
      lat={lat}
      lng={lng}
      isInit={isInit}
      placeName={placeName}
      address={address}
      showPlaceName={showPlaceName}
      showAddress={showAddress}
    />
  );
};

export default MapContainer;
