import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import {
  SEE_CUPS
} from "./CupListQueries";
import CupListPresenter from "./CupListPresenter";
import { useQuery } from "react-apollo-hooks";





export default withRouter(({ history }) => {

  const { data, loading } = useQuery(SEE_CUPS, {
    variables: {
      isAll:false
    },
    fetchPolicy:"network-only"
  });

  const [cups, setCups] = useState([]);

  const onCreatePage = () => {
    history.push(`/cup/create`);
  };

  const onUpdatePage = id => {
    history.push(`/cup/${id}`);
  };

  return (
    <CupListPresenter
      loading={loading}
      data={data}
      cups={cups}
      setCups={setCups}
      onCreatePage={onCreatePage}
      onUpdatePage={onUpdatePage}
    />
  );
});

