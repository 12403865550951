import React from "react";
import styled from "styled-components";
import Proptypes from "prop-types";
import useInput from "../Hooks/useInput";
import { TextField } from "@material-ui/core";

const Container = styled.form`
  width: 100%;
  max-width: 500px;
  font-size: 14px;
  color: #000;
  background-color: #fff;
  border: 1px solid #000;
  font-family: "NanumSqure";
  display: flex;
  padding: 15px 0px;
  margin-bottom: 10px;
`;

const Wrapper = styled.div`
  width: 50%;
  padding: 10px 15px;
`;

const Row = styled.div``;

const Title = styled.div`
      margin-top: 13px;
    margin-bottom: 10px;
    font-weight: 500;
  & > span {
    font-size:10px;
    position: absolute;
    z-index: 1;
  }
`;

const Input = styled.div`
  & .MuiFormControl-root {
    display: flex !important;
  }
  & .MuiInput-root {
    border-bottom: 1px solid #000;
    width: 100%;
  }
  & .MuiInputBase-input {
    padding: 3px 0px !important;
    /* min-width: 195px; */
    text-overflow: ellipsis;
  }
`;

const basketPositions = [
  {
    value: "PG",
    label: "PG",
  },
  {
    value: "SG",
    label: "SG",
  },
  {
    value: "SF",
    label: "SF",
  },
  {
    value: "PF",
    label: "PF",
  },
  {
    value: "C",
    label: "C",
  },
];

const CupUserForm = ({ disabled, users, index, setUsers }) => {
  const email = useInput("");
  const contact = useInput("", newValue=> /^[0-9-]{1,20}$/g.test(newValue), false, true);
  const username = useInput("");
  const backNo = useInput(0, (newValue) => !isNaN(newValue));
  const age = useInput("", (newValue) => !isNaN(newValue));
  const height = useInput("", (newValue) => !isNaN(newValue));
  const weight = useInput("", (newValue) => !isNaN(newValue));
  const position = useInput("PG");

  const onChange = (e) => {

    const arr = ["age","height","weight","backNo"];
    if(arr.includes(e.target.name) && isNaN(e.target.value) ) {
      return;
    }

    if(e.target.name === 'contact' && e.target.value !== "" &&!/^[0-9-]{1,20}$/g.test(e.target.value) ) {
      return;
    }
    const data = [...users];
    data[index] = {
      ...data[index],
      [e.target.name]: e.target.value,
    };
    setUsers(data);
  };

  return (
    <Container onChange={onChange}>
      <Wrapper>
        <Row>
          <Title>이름</Title>
          <Input>
            <TextField
              disabled={disabled}
              name={"username"}
              value={users[index].username}
              onChange={username.onChange}
            />
          </Input>
        </Row>
        <Row>
          <Title>포지션</Title>
          <Input>
            <TextField
              disabled={disabled}
              select
              name={"position"}
              value={users[index].position}
              onChange={position.onChange}
              SelectProps={{
                native: true,
              }}
            >
              {basketPositions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </TextField>
          </Input>
        </Row>
        <Row>
          <Title>신장</Title>
          <Input>
            <TextField
              disabled={disabled}
              name={"height"}
              value={users[index].height ? users[index].height : ""}
              onChange={height.onChange}
            />
          </Input>
        </Row>
        <Row>
          <Title>H.P</Title>
          <Input>
            <TextField
              disabled={disabled}
              name={"contact"}
              value={users[index].contact ? users[index].contact : ""}
              onChange={contact.onChange}
            />
          </Input>
        </Row>
      </Wrapper>
      <Wrapper>
        <Row>
          <Title>E-mail<br/><span>(FIBA 등록과 동일한 이메일)</span></Title>
          <Input>
            <TextField
              disabled={disabled}
              name={"email"}
              value={users[index].email ? users[index].email : ""}
              onChange={email.onChange}
            />
          </Input>
        </Row>
        <Row>
          <Title>나이</Title>
          <Input>
            <TextField
              disabled={disabled}
              name={"age"}
              value={users[index].age ? users[index].age : ""}
              onChange={age.onChange}
            />
          </Input>
        </Row>
        <Row>
          <Title>등번호</Title>
          <Input>
            <TextField
              disabled={disabled}
              name={"backNo"}
              value={users[index].backNo ? users[index].backNo : ""}
              onChange={backNo.onChange}
            />
          </Input>
        </Row>
        <Row>
          <Title>체중</Title>
          <Input>
            <TextField
              disabled={disabled}
              name={"weight"}
              value={users[index].weight ? users[index].weight : ""}
              onChange={weight.onChange}
            />
          </Input>
        </Row>
      </Wrapper>
    </Container>
  );
};

CupUserForm.propTypes = {
  disabled: Proptypes.bool.isRequired,
  placeholder: Proptypes.string,
  required: Proptypes.bool,
  value: Proptypes.string,
  onChange: Proptypes.func,
  type: Proptypes.string,
  onKeyDown: Proptypes.func,
};

export default CupUserForm;
