import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import {
  SEE_HISTORYS,
  UPDATE_HISTORY,
  SEE_TEAM
} from "./HistoryQueries";
import HistoryPresenter from "./HistoryPresenter";
import { useQuery, useSubscription } from "react-apollo-hooks";
import moment from "moment";

const HistoryContainer = ({ match: { params}, history }) => {
  const teamId = params.team;
  const [first] = useState(10);

  const [skip] = useState(0);
  const [loadMore, setLoadMore] = useState(false);
  const [canMore, setCanMore] = useState(true);
  const [refreshing, setRefreshing] = useState();
  const [games, setGames] = useState([]);
  const [openGameManage, setOpenGameManage] = useState(false);

  const { data, loading, fetchMore } = useQuery(SEE_HISTORYS, {
    variables: {
      sportId:localStorage.getItem('sport'),
      teamId,
      skip: skip,
      first: first
    },
    //fetchPolicy:"network-only"
  });

  const { data:dataTeam, loading:loadingTeam, refetch } = useQuery(SEE_TEAM, {
    variables: {
      teamId
    }
  });


  const onRefresh = () => {
    //console.log(currentTarget);
    setRefreshing(true);
    console.log(games);
    //refetch();
    fetchMore({
      variables: {
        skip: 0,
        first:data.seeGameHistorys.length
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        setRefreshing(false);
        if (!fetchMoreResult) return prev;

        console.log(fetchMoreResult.seeGameHistorys);
        //setGames([...fetchMoreResult.seeGameHistorys])

        const _historys = fetchMoreResult.seeGameHistorys;

        setGames(
          _historys.reduce((acc, item) => {
            (acc[moment(item.gameDate).format("YYYY-MM")] =
              acc[moment(item.gameDate).format("YYYY-MM")] || []).push(item);
            return acc;
          }, {})
        );

        return Object.assign({}, prev, {
          seeGameHistorys: [...fetchMoreResult.seeGameHistorys]
        });
      }
    });
  };

  const onScroll = ({currentTarget}) => {
    //console.log(currentTarget);
    if (
      canMore && 
      !loadMore &&
      currentTarget.scrollTop + currentTarget.clientHeight >= currentTarget.scrollHeight - 50
    ) {
      console.log("fetchMore");
      //console.log(data.seeGameHistorys);

      setLoadMore(true);
      fetchMore({
        variables: {
          skip: data.seeGameHistorys.length
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          setLoadMore(false);
         
          if (!fetchMoreResult) return prev;

          if(fetchMoreResult.seeGameHistorys.length === 0) {
            setCanMore(false);
          }

          return Object.assign({}, prev, {
            seeGameHistorys: [...prev.seeGameHistorys, ...fetchMoreResult.seeGameHistorys]
          });
        }
      });
    }   
  };

  const { data : updateHistoryData } = useSubscription(UPDATE_HISTORY, {
    variables: {
      teamId
    }
  });

  const handleOpenGameManage = () => {
    setOpenGameManage(true);
  }

  const handleCloseGameManage = () => {
    setOpenGameManage(false);
  } 

  const MoveToGameManage = () => {
    handleCloseGameManage();
    history.push(`/team/${teamId}/match`);
  }

  const MoveToGameCreate = () => {
    handleCloseGameManage();
    history.push(`/game/create`);
  }

  


  return (
    <HistoryPresenter
      loading={loading}
      data={data}
      dataTeam={dataTeam}
      loadingTeam={loadingTeam}
      games={games}
      setGames={setGames}
      loadMore={loadMore}
      teamId={teamId}
      onScroll={onScroll}
      canMore={canMore}
      updateHistoryData={updateHistoryData}

      refreshing={refreshing}
      onRefresh={onRefresh}
      MoveToGameManage={MoveToGameManage}
      MoveToGameCreate={MoveToGameCreate}
      openGameManage={openGameManage}
      handleOpenGameManage={handleOpenGameManage}
      handleCloseGameManage={handleCloseGameManage}
    />
  );
};

export default withRouter(HistoryContainer);
