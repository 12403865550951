import { gql } from "apollo-boost";

export const GET_TEAM = gql`
  query seeFullTeam($id: String!) {
    seeFullTeam(id: $id) {
      id
      teamName
      profile {
        id
        url
      }
      place {
        id
        placeName
        address
      }
      logo 
      location {
        id
        latitude
        longitude
      }
      contact
      uniformColor
      matchingInfo
      matchingPosition
      bio
      level
    }
  }
`;

export const EDIT_TEAM = gql`
  mutation editTeam(
    $id: String!
    $logo: String
    $profiles: [String]
    $teamName: String
    $contact: String!
    $uniformColor: String
    $matchingInfo: String
    $matchingPosition: String
    $bio: String
    $placeId: String
    $lat: Float
    $lng: Float
    $placeName: String
    $address: String
    $shortAddress: String
    $action: String!
  ) {
    editTeam(
      id:$id
      logo: $logo
      profiles: $profiles
      teamName: $teamName
      contact: $contact
      uniformColor: $uniformColor
      matchingInfo: $matchingInfo
      matchingPosition: $matchingPosition
      bio: $bio
      placeId:$placeId
      lat: $lat
      lng: $lng
      placeName: $placeName
      address: $address
      shortAddress: $shortAddress
      action:$action
    ) {
      id
      teamName
      profile {
        id
        url
      }
      place {
        id
        placeName
        address
      }
      logo 
      location {
        id
        latitude
        longitude
      }
      contact
      uniformColor
      matchingInfo
      matchingPosition
      bio
      level
    }
  }
`;

export const SEARCH_PLACE = gql`
  query seePlaces($skip: Int, $first: Int, $term: String) {
    seePlaces(skip: $skip, first: $first, term: $term) {
      id
      placeName
      address
      picture {
        id
        url
      }
    }
  }
`;

export const DELETE_PROFILE = gql`
  mutation deleteProfile($id: String!) {
    deleteProfile(id: $id)
  }
`;
