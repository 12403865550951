import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Helmet } from "rl-react-helmet";
import Avatar from "../../Components/Avatar";
import SwipeableViews from "react-swipeable-views";
import Button from "../../Components/Button";
import BoldText from "../../Components/BoldText";
import Map from "../../Components/Map";
import BaseHeader from "../../Components/Header/BaseHeader";
import { Info } from "../../Components/Icons";
import ScoreInput from "../../Components/ScoreInput";

import { Link } from "react-router-dom";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import moment from "moment";
import "moment/locale/ko";
import MVPSelect from "../MVPSelect";
import { Slide, Dialog } from "@material-ui/core";
import Fab from "../../Components/Fab";

const Wrapper = styled.div``;

const ContentWrapper = styled.div`
  padding-top: 56px;
  /* margin: auto 10px; */
  background-color: ${props => props.theme.innerBgColor};
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
`;

const GameHeader = styled.header`
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  width: 60%;
  margin: 0 auto;
  padding-top: 10px;
  @media (max-width: 768px) {
    width: 95%;
  }
`;

const GameHeaderColumn = styled.div`
  padding: 7px;
  font-size: 16px;
  text-align: center;
  padding-top: 20px;
  &:nth-child(2) {
    padding-top: 0px;
    align-self: center;
  }
`;

const ColumnWrap = styled.div``;

const ColumnRow = styled.div`
  font-size: 15px;
  margin: 5px 0px;
  position: relative;
  padding: 0px 10px;
  max-width: 130px;
`;

const VSText = styled.span`
  font-size: 20px;
`;

const HeaderOrder = styled.div`
  font-size: 16px;
  font-weight: 800;
  color: #ffbe21;
`;

const HeaderAvatar = styled.div`
  border: 1px solid #fff;
  border-radius: 50%;
  padding: 6px;
  min-width: 99px;
  min-height: 99px;
  width: 99px;
`;

const GameInfoWrap = styled.div`
  padding: 8px 16px;
  display: flex;
  align-items: center;
  min-height: 70px;
  justify-content: space-evenly;
`;
const GameInfoColumn = styled.div``;

const ResultWrap = styled.div`
  height: ${props => props.canEnterScore ? "calc(100% - 80px)" : "100%"};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  max-height: 300px;
  max-width: 400px;
  padding: 8px 16px;
  margin-bottom: 20px;
  margin: 0 auto;
  width: 100%;
  /* padding-bottom: 60px; */
`;

const ResultRow = styled.div`
  margin-bottom: 25px;
  width: 100%;
`;

const ProfileIcon = styled.span`
  position: relative;
  right: -5px;
  top: 3px;
`;

const LineupContainer = styled.div`
  background-color: #fff;
  padding-bottom: 130px;
  color: #000;
  position:relative;
`;

const LineupTitle = styled.div`
  padding-top: 10px;
  font-size: 15px;
  /* padding-left: 20px; */
  border-bottom: 1px solid;
  margin-left: 20px;
  margin-right: 20px;
  padding-bottom: 7px;
  font-weight: 600;
`;

const LineupWrapper = styled.div`
  display: flex;
`;

const LineupUl = styled.ul`
  width: 50%;
  padding-top: 10px;
  display: inline-block;
  &:first-child li {
    padding: 5px 0px 5px 15px;
    & > div {
      margin-left: 10px;
    }
  }
  &:nth-child(2) li{
    flex-direction:row-reverse;
    padding: 5px 15px 5px 0px;
    & > div {
      margin-right: 10px;
    }
  }
`;
const LineupInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
 
  & > h3 {
    font-size: 14px;
    font-weight: 600;
  }
  & > h4 {
    font-size: 12px;
  }
`;
const LineupList = styled.li`
  display: flex;
 
`;



const MyTabs = styled(Tabs)`
  color: #fff;
  padding: 5px 25px 0px;
  max-width: 500px;
  width: 100%;
  margin: 0 auto;
  & button:not(.Mui-selected) {
    z-index: 0;
  }
  .MuiTabs-flexContainer {
    background-color: #040f20;
    border-radius: 20px;
  }
  .MuiTabs-indicator {
    top: 0px;
    height: 4px;
    border-radius: 20px;
    height: 38px;
    background-color: ${props => props.theme.blueColor}!important;
  }
`;

const MyTabPanel = styled(TabPanel)`
  .MuiListItemText-secondary {
    color: #000;
  }
  & {
    /* padding-bottom: 47px; */
  }
`;

const useStyles = makeStyles(theme => ({
  card: {
    borderRadius: 0,
    backgroundColor: "transparent"
  },
  media: {
    height: 0,
    paddingTop: "56.25%" // 16:9
  },
  tabRoot: {
    fontSize: 16,
    fontFamily: "NanumSqure",
    zIndex: 1,
    minHeight: "38px",
    [theme.breakpoints.down('400')]: {
      fontSize: 13,
    },
  },
  userWrap: {
    // maxHeight: "400px",
    maxWidth: "600px",
    margin: "0 auto",
    width: "100%",
    color: "#000",
    overflowY: "auto",
    backgroundColor: "#fff",
    "& ul.MuiList-padding": {
      padding: 0,
      paddingBottom: 74,
      backgroundColor: "#fff"
    }
  },
  scoreWrap: {
    padding: "0px 15px;",
  },
  SwipeView: {
    flex: 1,
    width: "100%",
    maxWidth: "924px",
    display: "flex",
    flexDirection: "column",
    margin: "0 auto",
    "& > .react-swipeable-view-container": {
      flex: 1
    }
  },
  boxRoot: {
    height: "100%",
    display: "flex",
    flexDirection: "column"
  },
  panelRoot: {
    height: "100%"
  }
}));


const Title = styled.div`
  background-color: #fff;
  padding: 10px 20px;
  color: #000;
  height: 56px;
  display: flex;
  align-items: center;
`;

const Message = styled.div`
  background-color: #f2f2f2;
  padding: 10px 20px;
  color: #000;
  flex: 1;
  & > span:last-child {
    margin-bottom: 70px;
    display: block;
  }
`;

const Form = styled.form`
  position:relative;
`;

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  const classes = useStyles();
  if (props.className.indexOf("mapWrapper") !== -1) {
    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        <Box className={classes.boxRoot} p={0}>
          {children}
        </Box>
      </Typography>
    );
  } else {
    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        // {...other}
        className={classes.panelRoot}
      >
        <Box className={classes.boxRoot} p={0}>
          {children}
        </Box>
      </Typography>
    );
  }
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`
  };
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const _gameWeekText = gameDate => {
  let week = ["일", "월", "화", "수", "목", "금", "토"];
  let date = new Date(gameDate);
  return `(${week[date.getDay()]})`;
};

const ButtonRender = ({
  isSendResult,
  canEnterScore,
  result,
  myTeamSended,
  onSendResult,
  onConfirm
}) => {
  if (isSendResult) return null;

  // console.log({
  //   isSendResult,
  //   canEnterScore,
  //   result,
  //   myTeamSended,
  //   onSendResult,
  //   onConfirm
  // });

  // 호스트 권한을 가진 사람들
  if (canEnterScore) {
    // 입력된 결과가 있을 경우
    if (result) {
      // 우리팀이 마지막에 보냈을 경우
      if (myTeamSended) {
        return (
          <Button
            text={"대기중"}
            color={"#000"}
            position={"absolute"}
            disabled={true}
          />
        );
      } else {
        return (
          <Button
            text={"재요청"}
            rightText={"확인"}
            color={"#000"}
            position={"absolute"}
            half={true}
            onClick={onSendResult}
            rightOnClick={onConfirm}
          />
        );
      }
    } else {
      if (myTeamSended) {
        return (
          <Button
            text={"대기중"}
            color={"#000"}
            position={"absolute"}
            disabled={true}
          />
        );
      } else {
        return (
          <Button
            text={"승점 요청"}
            color={"#000"}
            position={"absolute"}
            onClick={onSendResult}
          />
        );
      }
    }
  } else return null;
};

function PLAYING(props) {
  console.log(props);

  const {
    isSendResult,
    homeScore,
    awayScore,
    setHomeScore,
    setAwayScore,
    canEnterScore,
    myTeamSended,
    onSendResult,
    onConfirm,
    history,
    data: {
      seeFullGame: {
        id,
        participants,
        gameDate,
        isParticipant,
        gameStatus,
        startTime,
        endTime,
        result,
        homeTeam,
        mvpVotes,
        awayTeam,
        place,
        title,
        message
      },
      me: { id: myId }
    }
  } = props;

  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = useState(0);
  const [mapInit, setMapInit] = useState(false);
  const [open, setOpen] = useState(false);
  const paramString = new URLSearchParams(history.location.search);

  const homeParticipants = participants.filter(vote => vote.team.id === homeTeam.id);
  const awayParticipants = participants.filter(vote => vote.team.id === awayTeam.id);

  const myVote =
    mvpVotes.length > 0
      ? mvpVotes[mvpVotes.findIndex(i => i.user.id === myId)]
      : null;

  const handleChange = (event, newValue) => {
    setMapInit(true);
    setValue(newValue);
  };

  const handleChangeIndex = index => {
    setValue(index);
  };

  const handleOpen = () => {
    history.push(`/game/${id}?mvp=true`)
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handlePopState = (e) => {
    if(window.location.search) {
      //history.push(`/game/${id}`)
    }
    else if(e) {
      handleClose();
    } 
  }

  useEffect(() => {
    window.addEventListener('popstate', handlePopState, false);

    if(paramString.get('mvp')) {
      setOpen(true);
    }

    return () => {
      window.removeEventListener('popstate', handlePopState, false);
    };
    
  }, []);

  return (
    <Wrapper>
      <BaseHeader
        text={gameStatus === "PLAYING" ? "경기 중" : "경기 종료"}
        isBack={true}
        isClose={false}
        isMenu={true}
      />
      <Helmet>
        <title>경기 진행 | WePlay</title>
      </Helmet>

      <ContentWrapper>
        <GameHeader>
          <GameHeaderColumn>
            <ColumnWrap>
              <ColumnRow>
                <HeaderOrder>홈</HeaderOrder>
              </ColumnRow>
              <ColumnRow>
                <Link to={`/team/${homeTeam.id}`}>
                  <HeaderAvatar>
                    <Avatar url={homeTeam.logo} size={"lmd"} />
                  </HeaderAvatar>
                </Link>
              </ColumnRow>
              <ColumnRow>
                <Link to={`/team/${homeTeam.id}`}>
                  <BoldText text={homeTeam.teamName} />
                  <ProfileIcon>
                    <Info size={16} />
                  </ProfileIcon>
                </Link>
              </ColumnRow>
              <ColumnRow>{`Lv.${homeTeam.level}`}</ColumnRow>
            </ColumnWrap>
          </GameHeaderColumn>
          <GameHeaderColumn>
            <ColumnWrap>
              <ColumnRow>
                <VSText>VS</VSText>
              </ColumnRow>
            </ColumnWrap>
          </GameHeaderColumn>
          <GameHeaderColumn>
            <ColumnWrap>
              <ColumnRow>
                <HeaderOrder>원정</HeaderOrder>
              </ColumnRow>
              <ColumnRow>
                <Link to={`/team/${awayTeam.id}`}>
                  <HeaderAvatar>
                    <Avatar url={awayTeam.logo} size={"lmd"} />
                  </HeaderAvatar>
                </Link>
              </ColumnRow>
              <ColumnRow>
                <Link to={`/team/${awayTeam.id}`}>
                  <BoldText text={awayTeam.teamName} />
                  <ProfileIcon>
                    <Info size={16} />
                  </ProfileIcon>
                </Link>
              </ColumnRow>
              <ColumnRow>{`Lv.${awayTeam.level}`}</ColumnRow>
            </ColumnWrap>
          </GameHeaderColumn>
        </GameHeader>

        <MyTabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="inherit"
          variant="fullWidth"
          aria-label="tabs"
        >
          <Tab
            className={classes.tabRoot}
            label="경기 정보"
            {...a11yProps(0)}
          />
          <Tab
            className={classes.tabRoot}
            label="경기장 정보"
            {...a11yProps(1)}
          />
        </MyTabs>

        <SwipeableViews
          className={classes.SwipeView}
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={value}
          onChangeIndex={handleChangeIndex}
          disabled={true}
        >
          <MyTabPanel value={value} index={0}>
            <GameInfoWrap>
              <GameInfoColumn>
                <BoldText text={moment(gameDate).format("YYYY. MM. DD")} />
                {_gameWeekText(gameDate)}
              </GameInfoColumn>
              <GameInfoColumn>
                <BoldText text={`${startTime} ~ ${endTime}`} />
              </GameInfoColumn>
            </GameInfoWrap>

            <ResultWrap canEnterScore={canEnterScore}>
              <ResultRow>
                <ScoreInput
                  index={0}
                  homeScore={homeScore}
                  awayScore={awayScore}
                  setHomeScore={setHomeScore}
                  setAwayScore={setAwayScore}
                  myTeamSended={myTeamSended}
                  endGame={!canEnterScore}
                />
              </ResultRow>
              <ResultRow>
                <ScoreInput
                  index={1}
                  homeScore={homeScore}
                  awayScore={awayScore}
                  setHomeScore={setHomeScore}
                  setAwayScore={setAwayScore}
                  myTeamSended={myTeamSended}
                  endGame={!canEnterScore}
                />
              </ResultRow>
              <ResultRow>
                <ScoreInput
                  index={2}
                  homeScore={homeScore}
                  awayScore={awayScore}
                  setHomeScore={setHomeScore}
                  setAwayScore={setAwayScore}
                  myTeamSended={myTeamSended}
                  endGame={!canEnterScore}
                />
              </ResultRow>
            </ResultWrap>

            {/* <Title>{title}</Title>
              <Message>
                {message.split("\n").map((line, i) => {
                  return (
                    <span key={i}>
                      {line}
                      <br />
                    </span>
                  );
                })}
              </Message> */}

            <LineupContainer>
              {isParticipant && (
                <Fab
                  type={"mvp"}
                  top={"-30px"}
                  right={"3px"}
                  onClick={handleOpen}
                />
              )}
              <LineupTitle>참석 라인업</LineupTitle>
              <LineupWrapper>
                <LineupUl>
                  {homeParticipants &&
                    homeParticipants.map(vote => {
                      return (
                        <Link key={vote.id} to={`/user/${vote.user.id}`}>
                          <LineupList>
                            <Avatar url={vote.user.avatar} />
                            <LineupInfo>
                              <h3>{`${vote.user.username}(${
                                vote.user.basket && vote.user.basket.backNo
                                  ? vote.user.basket.backNo
                                  : "없음"
                              })`}</h3>
                              <h4>{`Lv.${vote.user.level} | ${
                                vote.user.basket && vote.user.basket.position
                                  ? vote.user.basket.position
                                  : "포지션 미등록"
                              }`}</h4>
                            </LineupInfo>
                          </LineupList>
                        </Link>
                      );
                    })}
                </LineupUl>
                <LineupUl>
                  {awayParticipants &&
                    awayParticipants.map(vote => {
                      return (
                        <Link key={vote.id} to={`/user/${vote.user.id}`}>
                          <LineupList>
                            <Avatar url={vote.user.avatar} />
                            <LineupInfo>
                              <h3>{`${vote.user.username}(${
                                vote.user.basket && vote.user.basket.backNo
                                  ? vote.user.basket.backNo
                                  : "없음"
                              })`}</h3>
                              <h4>{`Lv.${vote.user.level} | ${
                                vote.user.basket && vote.user.basket.position
                                  ? vote.user.basket.position
                                  : "포지션 미등록"
                              }`}</h4>
                            </LineupInfo>
                          </LineupList>
                        </Link>
                      );
                    })}
                </LineupUl>
              </LineupWrapper>
            </LineupContainer>
          </MyTabPanel>

          <MyTabPanel className={"mapWrapper"} value={value} index={1}>
            {value === 1 && mapInit && place && (
              <Map
                isInit={value}
                address={place.address}
                placeName={place.placeName}
                lat={place.location.latitude}
                lng={place.location.longitude}
              ></Map>
            )}
          </MyTabPanel>
        </SwipeableViews>
      </ContentWrapper>

      <Form>
        <ButtonRender
          isSendResult={isSendResult}
          canEnterScore={canEnterScore}
          result={result}
          myTeamSended={myTeamSended}
          onSendResult={onSendResult}
          onConfirm={onConfirm}
        />
      </Form>

      <Dialog
        className={classes.dialog}
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <MVPSelect
          gameId={id}
          teamId={homeTeam.isMine ? homeTeam.id : awayTeam.id}
          votes={participants}
          homeTeam={homeTeam}
          awayteam={awayTeam}
          myVote={myVote}
          onBack={handleClose}
        />
      </Dialog>
    </Wrapper>
  );
}

export default PLAYING;
