import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { useQuery } from "react-apollo-hooks";
import {
  SEARCH_PLACE,
  SEARCH_TEAM,
  SEARCH_USER
} from "./SearchQueries";
import SearchPresenter from "./SearchPresenter";
import useInput from "../../Hooks/useInput";

export default withRouter(({ history, location }) => {
  //console.log(history.location);
  const paramString = new URLSearchParams(history.location.search);
  const search = useInput(paramString.get('text')?paramString.get('text'):"");
  const [searching, setSearching] = useState(false);
  const [loadMore, setLoadMore] = useState(false);

  const [activeTab, setActiveTab] = useState(0);
  const [first] = useState({
    user: 20,
    team: 20,
    place: 20
  });
  
  const [skip] = useState({
    user: 0,
    team: 0,
    place: 0
  });

  const { data, loading, fetchMore } = useQuery(SEARCH_USER, {
    skip: search.value === "",
    variables: {
      term: search.value,
      first:first.user,
      skip:skip.user
    }
  });

  const { data: dataTeam, loading: loadingTeam } = useQuery(SEARCH_TEAM, {
    skip: search.value === "",
    variables: {
      term: search.value,
      first:first.team,
      skip:skip.team
    }
  });

  const { data: dataPlace, loading: loadingPlace } = useQuery(SEARCH_PLACE, {
    skip: search.value === "",
    variables: {
      term: search.value,
      first:first.place,
      skip:skip.place
    }
  });

  const onScrollUser = ({ currentTarget }) => {
    if (
      currentTarget.scrollTop + currentTarget.clientHeight >=
      currentTarget.scrollHeight
    ) {
      setLoadMore(true);
      fetchMore({
        variables: {
          skip: data.searchUser.length
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          console.log(fetchMoreResult);
          console.log(fetchMoreResult.searchUser);
          setLoadMore(false);
          // if(fetchMoreResult.seeUsers.length === 0)
          //   toast.error("조회할 데이터가 없습니다.");
          // else
          //   setUsers({...users, data:[...users.data, ...fetchMoreResult.seeUsers]});

          if (!fetchMoreResult) return prev;

          return Object.assign({}, prev, {
            searchUser: [...prev.searchUser, ...fetchMoreResult.searchUser]
          });
        }
      });
    }
  };

  const onScrollTeam = ({ currentTarget }) => {
    if (
      currentTarget.scrollTop + currentTarget.clientHeight >=
      currentTarget.scrollHeight
    ) {
      setLoadMore(true);
      fetchMore({
        variables: {
          skip: dataTeam.searchTeam.length
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          setLoadMore(false);
          // if(fetchMoreResult.seeUsers.length === 0)
          //   toast.error("조회할 데이터가 없습니다.");
          // else
          //   setUsers({...users, data:[...users.data, ...fetchMoreResult.seeUsers]});

          if (!fetchMoreResult) return prev;

          return Object.assign({}, prev, {
            searchTeam: [...prev.searchTeam, ...fetchMoreResult.searchTeam]
          });
        }
      });
    }
  };

  const onScrollPlace = ({ currentTarget }) => {
    if (
      currentTarget.scrollTop + currentTarget.clientHeight >=
      currentTarget.scrollHeight
    ) {
      setLoadMore(true);
      fetchMore({
        variables: {
          skip: dataPlace.searchPlace.length
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          setLoadMore(false);
          // if(fetchMoreResult.seeUsers.length === 0)
          //   toast.error("조회할 데이터가 없습니다.");
          // else
          //   setUsers({...users, data:[...users.data, ...fetchMoreResult.seeUsers]});

          if (!fetchMoreResult) return prev;

          return Object.assign({}, prev, {
            searchPlace: [...prev.searchPlace, ...fetchMoreResult.searchPlace]
          });
        }
      });
    }
  };

  useEffect(() => {
    //console.log("Container UseEffect!");
    if (search.value.length > 0) {
      history.replace(`/search?text=${search.value}`);
      setSearching(true);
    } else {
      history.replace(`/search`);
      setSearching(false);
    }

    if(!loading && !loadingTeam && !loadingPlace) {
      setSearching(false);
    }

  }, [search.value, loading, loadingTeam, loadingPlace]);

  const onClickClose = () => {
    history.push(`/`);
  };

  return (
    <SearchPresenter
      activeTab={activeTab}
      setActiveTab={setActiveTab}
      search={search}
      searching={searching}
      loadMore={loadMore}
      loading={loading}
      data={data}
      dataTeam={dataTeam}
      loadingTeam={loadingTeam}
      dataPlace={dataPlace}
      loadingPlace={loadingPlace}
      onClickClose={onClickClose}
      onScrollUser={onScrollUser}
      onScrollTeam={onScrollTeam}
      onScrollPlace={onScrollPlace}
    />
  );
});
