import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import {
  GET_GAME,
  SEND_GAMERESULT,
  CONFIRM_GAMERESULT,
  UPDATE_GAME,
  UPDATE_GAME_RESULT,
  DELETE_GAME
} from "./GameProfileQueries";
import GameProfilePresenter from "./GameProfilePresenter";
import { useQuery, useMutation, useSubscription } from "react-apollo-hooks";
import { toast } from "../../Components/Popup";

const GameProfileContainer = ({ match: { params }, history }) => {
  const gameId = params.game;
  const { data, loading, refetch, error } = useQuery(GET_GAME, {
    variables: {
      id: gameId
    }
  });

  const [uploading, setUploading] = useState(false);
  const [GAMESTATUS, setGAMESTATUS] = useState();

  // 경기 결과 관련
  const [isSendResult, setIsSendResult] = useState(false);
  const [resultId, setResultId] = useState();
  const [homeScore, setHomeScore] = useState(new Array(3));
  const [awayScore, setAwayScore] = useState(new Array(3));
  const [myTeamSended, setMyTeamSended] = useState(false);

  // MVP 선택 관련
  const [openSearch, setOpenSearch] = useState(false);

  const handleSearchOpen = () => {
    setOpenSearch(true);
  };

  const handleSearchClose = () => {
    setOpenSearch(false);
  };

  const sendResultMutation = useMutation(SEND_GAMERESULT);
  const confirmResultMutation = useMutation(CONFIRM_GAMERESULT);
  const deleteGameMutation = useMutation(DELETE_GAME);
  

  const { data: updateGameData } = useSubscription(UPDATE_GAME, {
    variables: {
      id: gameId
    }
  });

  const { data: updateGameResultData } = useSubscription(UPDATE_GAME_RESULT, {
    variables: {
      id: gameId
    }
  });

  // 매칭 신청 관련
  const [canMatchRequest, setCanMatchRequest] = useState(true);

  const handleSendMatch = () => {
    setCanMatchRequest(false);
    refetch();
  };

  const onSendResult = async e => {
    e.preventDefault();
    try {
      setUploading(true);
      setIsSendResult(true);

      const filteredHomeScore = homeScore.filter(
        score => score != null && score !== ""
      );
      const filteredAwayScore = awayScore.filter(
        score => score != null && score !== ""
      );

      console.log(filteredHomeScore, filteredAwayScore);
      if (
        filteredHomeScore.length === 0 ||
        filteredAwayScore.length === 0 ||
        filteredHomeScore.length !== filteredAwayScore.length
      ) {
        toast.error(
          <div>
            입력한 점수값이 잘못되었습니다. <br />
            다시 입력해주시길 바랍니다.
          </div>
        );
        setIsSendResult(false);
        return false;
      }

      const {
        data: { sendGameResult }
      } = await sendResultMutation({
        variables: {
          id: gameId,
          homeScore: filteredHomeScore.map(i => parseInt(i)),
          awayScore: filteredAwayScore.map(i => parseInt(i)),
          resultId,
          sendTeam: data.me.myHostTeam.id,
          sendUser: data.me.id
        }
      });
      if (sendGameResult) {
        toast.success("승점 확인을 요청하였습니다.");
        refetch();
        setMyTeamSended(true);
      } else {
        setIsSendResult(false);
        toast.error("전달에 실패했습니다.");
      }
    } catch (e) {
      console.log(e);
      setIsSendResult(false);
      toast.error("전달에 실패했습니다. 다시 시도해주세요.");
    } finally {
      setIsSendResult(false);
      setUploading(false);
    }
  };

  const onConfirm = async e => {
    e.preventDefault();
    try {
      setUploading(true);
      setIsSendResult(true);

      const {
        data: { confirmGameResult }
      } = await confirmResultMutation({
        variables: {
          gameId,
          resultId,
          sendTeam: data.me.myHostTeam ? data.me.myHostTeam.id : "",
          sendUser: data.me.id
        }
      });

      if (confirmGameResult) {
        toast.success("최종 확인되었습니다.");
        refetch();
        setGAMESTATUS("CONFIRM");
      } else {
        setIsSendResult(false);
        toast.error("전달에 실패했습니다.");
      }
    } catch (e) {
      console.log(e);
      setIsSendResult(false);
      toast.error("전달에 실패했습니다. 다시 시도해주세요.");
    } finally {
      setUploading(false);
    }
  };

  const handleDeleteGame = async() => {
    try {
      setUploading(true);

      const { data : { editGame } } = await deleteGameMutation({
        variables: {
          id:gameId,
          action:"CANCEL",
          homeId: data.me.myHostTeam.id
        }
      });
      if(editGame.id) {
        toast.success("매칭을 취소했습니다.");
        history.replace('/');
      }
        
    } catch (e) {
      console.log(e);
      toast.error("요청에 실패했습니다.");
    } finally {
      setUploading(false);
    }
  }

  return (
    <GameProfilePresenter
      error={error}
      loading={loading}
      canMatchRequest={canMatchRequest}
      setCanMatchRequest={setCanMatchRequest}
      handleSendMatch={handleSendMatch}
      onSendResult={onSendResult}
      onConfirm={onConfirm}
      handleDeleteGame={handleDeleteGame}
      data={data}
      uploading={uploading}
      isSendResult={isSendResult}
      myTeamSended={myTeamSended}
      setMyTeamSended={setMyTeamSended}
      homeScore={homeScore}
      awayScore={awayScore}
      setResultId={setResultId}
      setHomeScore={setHomeScore}
      setAwayScore={setAwayScore}
      GAMESTATUS={GAMESTATUS}
      setGAMESTATUS={setGAMESTATUS}
      openSearch={openSearch}
      handleSearchOpen={handleSearchOpen}
      handleSearchClose={handleSearchClose}
      updateGameData={updateGameData}
      updateGameResultData={updateGameResultData}
      history={history}
    />
  );
};

export default withRouter(GameProfileContainer);
