import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { GET_CUP, CREATE_TEAM } from "./CupRequestFormQueries";
import CupRequestFormPresenter from "./CupRequestFormPresenter";
import useInput from "../../Hooks/useInput";
import { useQuery, useMutation } from "react-apollo-hooks";
import axios from "axios";
import loadImage from "blueimp-load-image";
import { toast } from "../../Components/Popup";

const CupRequestFormContainer = ({ match: { params }, history }) => {
  const cupId = params.cup;
  const [teamId, setTeamId] = useState(false);

  const { data, loading } = useQuery(GET_CUP, {
    variables: {
      id: cupId.toString(),
      isLoggedIn:Boolean(localStorage.getItem("token"))
    }
  });

  const [logo, setLogo] = useState();
  const [profiles, setProfiles] = useState([]);
  const teamNameInput = useInput("");
  const agentNameInput = useInput("");
  const contactInput = useInput("", newValue=> /^[0-9-]{1,20}$/g.test(newValue), false, true);
  const bioInput = useInput("");

  const [cupPlayerCnt, setCupPlayerCnt] = useState();

  //const [host, setHost] = useState();
  const [users, setUsers] = useState([]);
  const [members, setMembers] = useState([]);
  const [selectedMember, setSelectedMember] = useState([]);
  
  const [IsHost, setIsHost] = useState(false);
  const [IsAgree, setIsAgree] = useState(false);
  const [isAppInfo, setIsAppInfo] = useState(false);
  

  const [uploading, setUploading] = useState(false);
  const [openDialog, setOpenDialog] = useState({
    select:false,
    search:false
  });

  const createTeamMutation = useMutation(CREATE_TEAM);

  const onClickClose = () => {
    history.push(`/cup/${cupId}`);
  };

  const handleAddUser = user => () => {
    handleDialogClose();
    setSelectedMember(null);

    if(cupPlayerCnt === 3 && users.length === 5) {
      toast.error("최대 5명까지 등록 가능합니다.")
    } else if(cupPlayerCnt === 5 && users.length === 12) {
      toast.error("최대 12명까지 등록 가능합니다.")
    } else {
      if(user) {
        const _users = [...users];
        if(_users.findIndex(u => u.id === user.id) === -1) {
          _users.push(user);
          setUsers(_users);
        }
        
      } else {
        const _users = [...users];
        _users.push({
          avatar: "/imgs/user_icon_list.png",
          contact: "",
          email: "",
          username: "",
          backNo: "",
          age: "",
          height: "",
          weight: "",
          position: "PG"
        });
        setUsers(_users);
      }
    }
  };

  const handleLogoChange = e => {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];
    const fileType = file.type;

    if (file) {
      // reader.onloadend = () => {
      //   file["url"] = reader.result;
      //   setLogo(file);
      // };

      loadImage(file, (img) => {
        img.toBlob(blob => {
          const createdFile = new File([blob], file.name);
          createdFile['url'] = img.toDataURL();
          setLogo(createdFile);
        }, fileType);
      }, { 
        orientation:true, 
        maxWidth:200,
        maxHeight:200,
        contain:true,
        canvas:true,
        crop:true,
        aspectRatio:1/1,
      });

      reader.readAsDataURL(file);
    }
  };

  const handleProfileChange = e => {
    e.preventDefault();

    if(profiles.length >= 5) {
      toast.error("최대 5장까지 가능합니다.");
      return;
    }

    let reader = new FileReader();
    let file = e.target.files[0];

    const fileType = file.type;

    if (file) {
      // reader.onloadend = () => {
      //   file["url"] = reader.result;
      //   const data = [...profiles];
      //   data.push(file);
      //   setProfiles(data);
      // };

      loadImage(file, (img) => {
        img.toBlob(blob => {
          const createdFile = new File([blob], file.name);
          const data = [...profiles];
          createdFile['url'] = img.toDataURL();
          data.push(createdFile);
          setProfiles(data);
        }, fileType);
      }, { 
        aspectRatio:16/9,
        orientation:true, 
        maxWidth:480,
        maxHeight:270,
        cover:false,
        canvas:true,
        crop:true
      });

      reader.readAsDataURL(file);
    }
  };

  const onRemoveProfile = removeFile => e => {
    e.preventDefault();

    const data = [...profiles];
    data.splice(
      data.findIndex(i => i.name === removeFile.name),
      1
    );
    setProfiles(data);
  };

  const handleToggle = value => () => {
    setSelectedMember(value);
  };

  const handleRemoveUser = key => e => {
    e.preventDefault();
    console.log(key);
    const _data = [...users];
    _data.splice(key, 1);
    console.log(_data);
    setUsers(_data);
  };

  // 전체 업로드
  const handleSubmit = async (e) => {
    e.preventDefault();
    // POST to a test endpoint for demo purposes
    console.log({
      cupId,
      teamName: teamNameInput.value,
      contact: contactInput.value,
      bio: bioInput.value,
      //host: JSON.stringify(host),
      users: JSON.stringify(users)
    });

    if (uploading) return false;

    try {
      if (cupPlayerCnt === 3 && users.length < 3) {
        toast.error("선수는 최소 3명 이상 등록해주세요");
      } 
      else  if (cupPlayerCnt === 5 && users.length < 5) {
        toast.error("선수는 최소 5명 이상 등록해주세요");
      }
      else if (teamNameInput.value === "") {
        toast.error("팀 이름은 필수값입니다.");
      } 
      else if (contactInput.value === "") {
        toast.error("연락처는 필수값입니다.");
      } 
      else if (bioInput.value === "") {
        toast.error("원활한 대회를 위해 팀 소개를 작성해주세요");
      }
      else {
        setUploading(true);

        let userFormError = false;
        users.forEach( (user,i) => {
          console.log(i, user)
          if(user.email === "") {
            userFormError=true;
            return;
          }

          if(user.contact === "") {
            userFormError=true;
            return;
          }

          if(user.username === "") {
            userFormError=true;
            return;
          }

          if(user.backNo === "") {
            userFormError=true;
            return; 
          }

          if(user.age === "") {
            userFormError=true;
            return;
          }

          if(user.height === "") {
            userFormError=true;
            return;
          }

          if(user.weight === "") {
            userFormError=true;
            return;
          }

          if(user.position === "") {
            userFormError=true;
            return;
          }
        });
        if(userFormError) {
          toast.error("참가 팀원 정보를 모두 입력해주세요.");
          return;
        }



        let LogoUrl;
        let ProfileUrls;
        if(teamId === null) {
          if (logo) {
            const formData = new FormData();
            formData.append("logo", logo);
  
            const {
              data: { location }
            } = await axios.post(
              "https://weplay2921.cafe24.com/api/team/upload",
              formData,
              {
                headers: {
                  "content-type": "multipart/form-data"
                }
              }
            );
            LogoUrl = location;
          }
  
          if (profiles && profiles.length > 0) {
            const formData = new FormData();
            profiles.forEach(file => {
              formData.append("profile", file);
            });
  
            const { data } = await axios.post(
              "https://weplay2921.cafe24.com/api/team/profile/upload",
              formData,
              {
                headers: {
                  "content-type": "multipart/form-data"
                }
              }
            );
  
            ProfileUrls = data;
          }
        }
        

        const {
          data: { createCupTeam }
        } = await createTeamMutation({
          variables: {
            cupId,
            teamId,
            agent:agentNameInput.value,
            sportId: "1001",
            teamName: teamNameInput.value,
            contact: contactInput.value,
            bio: bioInput.value,
            logo: LogoUrl,
            profiles: ProfileUrls,
            //host: JSON.stringify(host),
            users: JSON.stringify(users)
          }
        });

        if (createCupTeam.id) {
          toast.success("신청 완료!");

          setTimeout(() => {
            history.push(`/cup/${cupId}/team/${createCupTeam.id}`);
          }, 1000);
        }
      }
    } catch (e) {
      console.log(e);
      toast.error("다시 시도해주세요.");
    } finally {
      setUploading(false);
    }
  };


  const handleDialogOpen = type => () => {
    //setOpenDialog(true);
    if(type === 'select')
      setIsAgree(true);
    if(IsHost)
      setOpenDialog( { ...openDialog, [type]:true} );
  };

  const handleDialogClose = type => () => {
    //setOpenDialog(false);
    setOpenDialog( { ...openDialog, [type]:false} );
  };

  const handleAppInfo = () => {
    setIsAppInfo(true);
    setOpenDialog( { ...openDialog, select:false} );
  }

  const handleToggleAppInfo = () => {
    setUsers([]);
    setIsAppInfo(!isAppInfo);
  }

  return (
    <CupRequestFormPresenter
      data={data}
      loading={loading}
      uploading={uploading}
      handleAddUser={handleAddUser}
      handleRemoveUser={handleRemoveUser}
      setCupPlayerCnt={setCupPlayerCnt}
      handleSubmit={handleSubmit}

      handleToggleAppInfo={handleToggleAppInfo}
      handleAppInfo={handleAppInfo}
      isAppInfo={isAppInfo}
      setTeamId={setTeamId}
      IsAgree={IsAgree}
      setIsAgree={setIsAgree}
      IsHost={IsHost}
      setIsHost={setIsHost}

      logo={logo}
      profiles={profiles}
      handleLogoChange={handleLogoChange}
      handleProfileChange={handleProfileChange}
      onRemoveProfile={onRemoveProfile}
      teamNameInput={teamNameInput}
      agentNameInput={agentNameInput}
      contactInput={contactInput}
      bioInput={bioInput}

      users={users}
      setUsers={setUsers}

      onClickClose={onClickClose}

      handleDialogOpen={handleDialogOpen}
      handleDialogClose={handleDialogClose}
      openDialog={openDialog}
      selectedMember={selectedMember}
      handleToggle={handleToggle}
      members={members}
      setMembers={setMembers}
    />
  );
};

export default withRouter(CupRequestFormContainer);
