import React, { useEffect, useState } from "react";
import styled from "styled-components";

// const getAttr = isInit => {
//   if (isInit) {
//     return `
//       position: unset;
//       z-index: -1;
//       visibility: unset;
//     `;
//   } else {
//     return `
//       position: absolute;
//       z-index: -1;
//       visibility: hidden;
//     `;
//   }
// };

const Container = styled.a`
  margin: 15px auto 70px;
  width: 100%;
  cursor:pointer;
`;

const Map = styled.div`
  margin: 0 auto;
  width: 100%;
  height: 300px;
  & > div {
    touch-action:auto!important;
  }
`;

const AddressTitle = styled.div`
  padding: 10px;
  background-color: #e7e7e7;
  color: #000;
  text-align:center;
  font-weight:700;
  font-size:14px;
`;

const AddressDestail = styled.div`
  background-color: #fff;
  min-height: 50px;
  color:#000;
  display: flex;
  font-size:14px;
  justify-content: center;
  align-items: center;
`;

export default ({ lat, lng, isInit, placeName, address, showPlaceName=true, showAddress=true }) => {

  useEffect(() => {
    const kakao = window.kakao;
    if (kakao !== undefined && lat !== 0 && lng !== 0 && document.getElementById("map")) {
      try {
        const marker = new kakao.maps.Marker({
          position: new kakao.maps.LatLng(lat, lng)
        });

        // const marker = {
        //   position: new kakao.maps.LatLng(lat, lng)
        // };
  
        const map = new kakao.maps.Map(document.getElementById("map"), {
          center: new kakao.maps.LatLng(lat, lng),
          level: 3,
          //marker
        });

        map.setDraggable(false); 
        map.setZoomable(false);
        marker.setMap(map);
        window.kakao.mymap = map;
      } catch(e) {
        console.log("다음 지도 API 에러!");
        console.log(e);
      }
    }
  }, [isInit, window.kakao, lat, lng]);

  return (
    <Container isInit={isInit} href={`https://map.kakao.com/link/map/${placeName.split(',')[0]},${lat},${lng}`}>
      <Map id="map" />
      {showPlaceName && <AddressTitle>{placeName}</AddressTitle>}
      {showAddress && <AddressDestail>{address}</AddressDestail>}
    </Container>
  );
};
