import { gql } from "apollo-boost";

export const GET_CHATS = gql`
  query seeChats($first:Int, $skip:Int) {
    seeChats(first:$first, skip:$skip) {
      id
      oppId {
        id
        avatar
        username
        level
      }
      lastUpdated
      unreadCount
      updatedAt
    }
    me {
      id
    }
  }
`;


export const SEARCH_CHAT = gql`
  query searchChat($term:String!) {
    searchChat(term:$term) {
      id
      avatar
      username
      nickname
      basket {
        id
        level
        grade
      }
      itsMe
    }
  }
`;

export const DELETE_CHAT = gql`
  mutation deleteChat($id:String!) {
    deleteChat(id:$id)
  }
`;