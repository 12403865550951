import React, { useEffect } from "react";
import styled from "styled-components";
import { Helmet } from "rl-react-helmet";
import { Link } from "react-router-dom";

import Avatar from "../../Components/Avatar";
import Loader from "../../Components/Loader";
import MaterialTable from "material-table";
import CupHeader from "../../Components/CupHeader";
import { BasketBall } from "../../Components/Icons";
import Gallery from "../../Components/Gallery";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";

export default ({ data, loading, cupId, users, setHost, setUsers }) => {
  if (loading) {
    return (
      <Wrapper>
        <Loader />
        <CupHeader id={cupId} active={"teams"} />
      </Wrapper>
    );
  } else if (!loading && data && data.seeFullTeam) {
    const {
      seeFullTeam: {
        teamName,
        bio,
        host: teamHost,
        users: teamUsers,
        profile,
        logo
      },
      seeCup: { playerCnt }
    } = data;
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down("xs"));
    const defaultStat = {
      score: 0,
      rebound: 0,
      assist: 0,
      blocking: 0,
      steal: 0,
      turnOver: 0,
      foul: 0,
      oneScore: 0,
      twoScore: 0,
      threeScore: 0,
      freeShot: 0,
      freeShotTotal: 0
    };

    let userColumn;

    if (playerCnt === 3) {
      userColumn = matches ? mobileThreeGameColumn : ThreeGameColumn;
    } else if (playerCnt === 5) {
      userColumn = FiveGameColumn;
    }
    //console.log(data);

    useEffect(() => {
      if (teamHost) {
        if (!teamHost.userStatAvg) {
          teamHost.userStatAvg = defaultStat;
        }

        setHost(teamHost);
      }

      const member = teamHost ? [teamHost].concat(teamUsers) : teamUsers;
      setUsers(
        member.map(user => {
          if (!user.userStatAvg) {
            user.userStatAvg = defaultStat;
          }
          return user;
        })
      );
    }, []);

    return (
      <Wrapper>
        <Helmet>
          <title>팀 상세보기 | WePlay</title>
        </Helmet>

        <CupHeader id={cupId} active={"teams"} />
        <Section>
          <BackArrow>
            <Link to={`/cup/${cupId}/teams`}>
              <span>{"< 뒤로"}</span>
            </Link>
          </BackArrow>
          <Header>
            <Avatar size={"lg"} url={logo} />
            <TeamName>{teamName}</TeamName>
          </Header>
          <Card>
            <Gallery items={profile} />
          </Card>
          <BioWrapper>
            <h4>
              <BasketBall fill={"#5e80ec"} size={18} /> 팀 소개
            </h4>
            <div>
              {bio.split("\n").map((line, i) => {
                return (
                  <span key={i}>
                    {line}
                    <br />
                  </span>
                );
              })}
            </div>
          </BioWrapper>
        </Section>
        <Section>
          <StatTitle>
            <BasketBall fill={"#5e80ec"} size={18} /> 대회 평균 기록
          </StatTitle>
          {matches ? (
            <MobileTable>
              {users.length > 0 &&
                users.map(user => (
                  <div key={user.id}>
                    <MobileTHeader>
                      <MobileRow>
                        <div>이름(No)</div>
                        <div>포지션</div>
                        <div>신체정보</div>
                        <div>득점</div>
                        <div>자유투</div>
                        <div>리바운드</div>
                      </MobileRow>
                    </MobileTHeader>

                    <MobileColumn>
                      <MobileRow>
                        <EmptyHeader></EmptyHeader>
                        <div>{user.basket.position}</div>
                        <div>
                          {user.basket.height}/{user.basket.weight}
                        </div>
                        <div>{user.userStatAvg.score}</div>
                        <div>
                          {user.userStatAvg.freeShot}/
                          {user.userStatAvg.freeShotTotal}
                        </div>
                        <div>{user.userStatAvg.rebound}</div>
                      </MobileRow>
                    </MobileColumn>
                    <MobileTHeader>
                      <MobileRow>
                        <EmptyHeader>{user.username}<br/><span>({user.basket.backNo})</span></EmptyHeader>
                        <div>어시스트</div>
                        <div>블로킹</div>
                        <div>스틸</div>
                        <div>파울</div>
                        <div>턴오버</div>
                      </MobileRow>
                    </MobileTHeader>
                    <MobileColumn>
                      <MobileRow>
                        <EmptyHeader></EmptyHeader>
                        <div>{user.userStatAvg.assist}</div>
                        <div>{user.userStatAvg.blocking}</div>
                        <div>{user.userStatAvg.steal}</div>
                        <div>{user.userStatAvg.foul}</div>
                        <div>{user.userStatAvg.turnOver}</div>
                      </MobileRow>
                    </MobileColumn>
                  </div>
                ))}
            </MobileTable>
          ) : (
            <MaterialTable
              columns={userColumn}
              data={users}
              style={{ border: "1px solid #000", boxShadow: "none" }}
              options={{
                toolbar: false,
                search: false,
                actionsColumnIndex: -1,
                paging: false,
                filtering: false,
                sorting: false,
                draggable: false,
                headerStyle: { padding: 5, backgroundColor: "#dddddd" }
              }}
              localization={{
                header: {
                  actions: ""
                },
                body: {
                  emptyDataSourceMessage: "해당 데이터가 없습니다.",
                  editRow: {
                    deleteText: "정말로 삭제하시겠습니까?"
                  }
                }
              }}
            />
          )}
        </Section>
      </Wrapper>
    );
  }
};

const FiveGameColumn = [
  {
    title: "이름(No)",
    field: "username",
    render: rowData => {
      return (
        rowData && (
          <span>
            {rowData.username}({rowData.basket.backNo})
          </span>
        )
      );
    }
  },
  { title: "포지션", field: "basket.position" },
  { title: "신장", field: "basket.height" },
  { title: "체중", field: "basket.weight" },
  {
    title: "득점",
    field: "userStatAvg.score",
    type: "numeric",
    emptyValue: 0,
    editable: "never"
  },
  {
    title: "리바운드",
    field: "userStatAvg.rebound",
    type: "numeric",
    emptyValue: 0,
    editable: "never"
  },
  {
    title: "어시스트",
    field: "userStatAvg.assist",
    type: "numeric",
    emptyValue: 0,
    editable: "never"
  },
  {
    title: "블로킹",
    field: "userStatAvg.blocking",
    type: "numeric",
    emptyValue: 0,
    editable: "never"
  },
  {
    title: "스틸",
    field: "userStatAvg.steal",
    type: "numeric",
    emptyValue: 0,
    editable: "never"
  },
  {
    title: "파울",
    field: "userStatAvg.foul",
    type: "numeric",
    emptyValue: 0,
    editable: "never"
  },
  {
    title: "턴오버",
    field: "userStatAvg.turnOver",
    type: "numeric",
    emptyValue: 0,
    editable: "never"
  }
];

const ThreeGameColumn = [
  {
    title: "이름(No)",
    field: "username",
    render: rowData => {
      return (
        rowData && (
          <span>
            {rowData.username}({rowData.basket.backNo})
          </span>
        )
      );
    }
  },
  { title: "포지션", field: "basket.position" },
  { title: "신장", field: "basket.height" },
  { title: "체중", field: "basket.weight" },
  {
    title: "득점",
    field: "userStatAvg.score",
    type: "numeric",
    emptyValue: 0,
    editable: "never"
  },
  {
    title: "리바운드",
    field: "userStatAvg.rebound",
    type: "numeric",
    emptyValue: 0,
    editable: "never"
  },
  {
    title: "어시스트",
    field: "userStatAvg.assist",
    type: "numeric",
    emptyValue: 0,
    editable: "never"
  },
  {
    title: "스틸",
    field: "userStatAvg.steal",
    type: "numeric",
    emptyValue: 0,
    editable: "never"
  },
  {
    title: "파울",
    field: "userStatAvg.foul",
    type: "numeric",
    emptyValue: 0,
    editable: "never"
  }
];

const mobileThreeGameColumn = [
  {
    title: "이름(No)",
    field: "username",
    render: rowData => {
      return (
        rowData && (
          <span>
            {rowData.username}({rowData.basket.backNo})
          </span>
        )
      );
    }
  },
  { title: "포지션", field: "basket.position" },
  {
    title: "득점",
    field: "userStatAvg.score",
    type: "numeric",
    emptyValue: 0,
    editable: "never"
  },
  {
    title: "자유투",
    field: "userStatAvg.freeShot",
    type: "numeric",
    editable: "never",
    render: rowData => {
      return (
        rowData && (
          <span>
            {rowData.thisUserStat.freeShot}/{rowData.thisUserStat.freeShotTotal}{" "}
          </span>
        )
      );
    },
    emptyValue: 0
  },
  {
    title: "리바운드",
    field: "userStatAvg.rebound",
    type: "numeric",
    emptyValue: 0,
    editable: "never"
  },
  {
    title: "어시스트",
    field: "userStatAvg.assist",
    type: "numeric",
    emptyValue: 0,
    editable: "never"
  },
  {
    title: "스틸",
    field: "userStatAvg.steal",
    type: "numeric",
    emptyValue: 0,
    editable: "never"
  },
  {
    title: "파울",
    field: "userStatAvg.foul",
    type: "numeric",
    emptyValue: 0,
    editable: "never"
  }
];
const Wrapper = styled.div``;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 700px;
  width: 100%;
  margin: 0 auto;
  padding-top: 30px;
  & > div {
    border: 1px solid;
  }
`;

const BackArrow = styled.div`
  font-size: 16px;
  /* padding: 0px 10px; */
`;

const TeamName = styled.span`
  font-size: 25px;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  position: relative;
  margin-top: 10px;
  @media (max-width: 768px) {
    font-size: 26px;
  }
  @media (max-width: 425px) {
    font-size: 20px;
  }
`;

const BioWrapper = styled.div`
  & > h4 {
    font-size: 18px;
    font-weight: 600;
    & > svg {
      vertical-align: middle;
    }
  }
  & > div {
    padding-left: 23px;
    margin-top: 17px;
    line-height: 1.5em;
    @media (max-width: 600px) {
      padding-left: 0px;
    }
  }
`;

const SubTitle = styled.h4`
  font-size: 18px;
  font-weight: 600;
  padding-bottom: 10px;
  & > svg {
    vertical-align: middle;
  }
  @media (max-width: 500px) {
    &:last-child {
      padding: 0px 20px 10px;
    }
  }
`;

const StatTitle = styled(SubTitle)`
  @media (max-width: 500px) {
    padding: 0px 10px 10px;
  }
`;

const Card = styled.div`
  border-radius: 0;
  background-color: transparent;
  overflow: hidden;
  max-width: 500px;
  margin: 0 auto;
  padding-top: 30px;
  padding-bottom: 40px;
`;

const Section = styled.div`
  overflow: hidden;
  max-width: 1024px;
  margin: 0 auto;
  width: 95%;
  padding: 20px 20px 20px;
  background-color: #fff;
  color: #000;
  & .MuiTableCell-root {
    text-align: center;
  }
  & .MuiInputBase-input {
    text-align: center !important;
    font-size: 13px;
  }

  @media (max-width: 500px) {
    padding: 20px 10px 20px;
    & .MuiTableCell-root {
      font-size: 12px;
      padding: 2px !important;
    }
  }

  &:last-child {
    padding: 40px 20px 20px;
  }

  @media (max-width: 500px) {
    &:last-child {
      padding: 40px 0px 20px;
    }
  }
`;

const MobileTable = styled.div`
  width: 100%;
  & > div {
    width: 100%;
    display: table;
    font-size: 13px;
    position: relative;
    margin-bottom: 5px;
    border: 1px solid #000;
  }
`;
const EmptyHeader = styled.div`
  background-color: #fff !important;
  width: 70px;
`;

const MobileTHeader = styled.div`
  display: table-header-group;
  /* background-color: rgb(221,221,221); */
  & div {
    font-weight: 500;
    background-color: rgb(221, 221, 221);
  }
`;

const MobileRow = styled.div`
  display: table-row;
  & > div {
    padding: 5px;
    display: table-cell;
    text-align: center;
  }
`;

const MobileColumn = styled.div`
  display: table-row-group;
`;
