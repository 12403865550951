import React from "react";
import {Link} from "react-router-dom";
import styled from "styled-components";
import { Helmet } from "rl-react-helmet";
import Loader from "../../Components/Loader";
import ListLabel from "../../Components/ListLabel";
import { Divider, CircularProgress } from "@material-ui/core";

import MainHeader from "../../Components/Header/MainHeader";
import BlankCard from "../../Components/BlankCard";
import { Recommend, Similar } from "../../Components/Icons";

import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import GameCard from "../../Components/GameCard";
//import RoomCard from "../../Components/RoomCard";

import {
  CarouselProvider,
  Slider as PSlider,
  Slide
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import Swipe from "react-easy-swipe";
import PullToRefresh from "rmc-pull-to-refresh";
import DedicateLoader from "../../Components/DedicateLoader";
import RefreshLoader from "../../Components/RefreshLoader";

const Wrapper = styled.div`
  min-height: 100vh;
  background-color: #081f40;
`;

const ContentWrapper = styled.div`
  /* padding-top: 76px; */
  /* margin: auto 10px; */
  background-color: #081f40;
  padding-bottom: 20px;
  /* & > div:nth-child(3n + 1) {
    margin-bottom: 10px;
    padding-left: 10px;
  } */

  @media (min-width: 500px) {
    max-width: 500px;
    margin: 0 auto;
  }
`;

const BannerWrapper = styled.div`
  padding: 0px 20px;
  margin-bottom: 40px;
  & .slick-dots li button:before {
    color:#fff!important;
  }
  & .slick-dots li.slick-active button:before {
    color:#227fff!important;
  }
`;

const BannerImage = styled.img`
  cursor: pointer;
  width:100%;
`;

const SliderWrapper = styled.div`
  position: relative;
  & > .MuiCircularProgress-colorPrimary {
    position: absolute !important;
    top: 100px;
    z-index: 999;
    width: 30px !important;
    height: 30px !important;
    color: #1ea9e1;
  }
`;

const MyDivider = styled(Divider)`
  background-color: rgba(255, 255, 255, 0.12) !important;
  margin: 20px 0px 25px !important;
`;

const MySlider = styled(Slider)`
  width: 100%;
  .slick-track {
    margin-left: 0px !important;
  }
  .slick-list {
    padding: 0px !important;
    overflow: hidden;
  }
  .slick-dots {
    bottom: -10px;
  }
  .slick-dots li.slick-active button:before,
  .slick-dots li button:before {
    color: #fff;
  }
`;

const defaultSettings = {
  dots: false,
  infinite: false,
  centerPadding: "15px",
  centerMode: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: false,
        arrows: false,
        centerMode: true,
        dots: true
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        swipeToSlide: true,
        initialSlide: 1,
        centerMode: true,
        arrows: false
      }
    },
    {
      breakpoint: 480,
      settings: {
        useTransform: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        swipeToSlide: true,
        centerMode: true,
        arrows: false,
        dot: false
      }
    }
  ]
};

const bannerSetting = {
  dots: true,
  arrows: false,
  infinite: true,
  speed: 1000,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay:true
};

const TeamListPresenter = ({
  bannersData,
  bannersLoading,
  reloading,
  onFetchMore,
  onReFetch,
  matchGamesData,
  matchGamesLoading,
  teams,
  teamsLoading,
  nearbyGames,
  nearbyGamesLoading,
  rooms,
  roomsLoading,
  newMatchGames,
  onRefresh,
  refreshing
}) => {
  const loading =
    teamsLoading || matchGamesLoading || nearbyGamesLoading || roomsLoading;
  // const dataGameLoading = matchGamesData && matchGamesData.seeGames;
  // const dataTeamLoading = teams && teams.seeLevelTeams;
  // const dataNearbyLoading = nearbyGames && nearbyGames.seeGamesNearby;
  // const dataRoomLoading = rooms && rooms.seeRooms;

 

  const GameSetting = {
    ...defaultSettings,
    onEdge: direction => {
      if (direction === "left") {
        onFetchMore("games");
      }
    }
  };

  const TeamSetting = {
    ...defaultSettings,
    onEdge: direction => {
      console.log(direction);
      if (direction === "left") {
        onFetchMore("teams");
      }
    }
  };

  const NearbySetting = {
    ...defaultSettings,
    onEdge: direction => {
      if (direction === "left") {
        onFetchMore("nearbys");
      }
    }
  };

  const RoomSetting = {
    ...defaultSettings,
    onEdge: direction => {
      console.log("reload!");
      if (direction === "left") {
        onFetchMore("rooms");
      }
    }
  };

  const onSwipeMove = type => (position, event) => {
    if (!reloading[type] && position.x < -30) {
      onFetchMore(type);
    }
  };


  if (loading) {
    return (
      <Wrapper>
        <MainHeader />
        <Helmet>
          <title>Team List | WePlay</title>
        </Helmet>
        <Loader />
        <ContentWrapper></ContentWrapper>
      </Wrapper>
    );
  } else if (!loading) {
    return (
      <Wrapper>
        <MainHeader />
        <Helmet>
          <title>Team List | WePlay</title>
        </Helmet>

        <PullToRefresh
          style={{
            overflow: "auto",
            paddingTop: 74,
            minHeight: "100vh",
            maxHeight: "100vh"
          }}
          direction="down"
          distanceToRefresh={50}
          damping={100}
          onRefresh={() => onRefresh()}
          refreshing={refreshing}
          indicator={{
            activate: <DedicateLoader top={"-50px"} />,
            deactivate: <></>,
            release: <RefreshLoader top={"-40px"} />,
            finish: <></>
          }}
        >
          <ContentWrapper>
            {!bannersLoading && bannersData && bannersData.seeBanners && (
              <BannerWrapper>
                <Slider {...bannerSetting}>
                  {bannersData.seeBanners.map(banner => {
                      if(banner.link.indexOf("https://play.weplaysports.net") !== -1) {
                        let link = banner.link.replace('https://play.weplaysports.net','');
                            link = link.replace('/#','');
                        return (
                          <Link to={link} key={banner.id}>
                            <BannerImage src={banner.url} />
                          </Link>
                        );
                      } else {
                        return (
                          <a href={banner.link} key={banner.id}>
                            <BannerImage src={banner.url} />
                          </a>
                        );
                      }
                    } 
                  )}

                  {/* <Link to={"/cups"}>
                    <BannerImage src={"https://d1jozj1mdbjtd9.cloudfront.net/banner01.png"} onClick={() => { }} />
                  </Link>
                  <a href={"http://www.weplaysports.net/"}>
                  <BannerImage src={"https://d1jozj1mdbjtd9.cloudfront.net/banner02.png"} onClick={() => { }} />
                  </a>
                  <a href={"http://www.weplaysports.net/"}>
                  <BannerImage src={"https://d1jozj1mdbjtd9.cloudfront.net/banner03.png"} onClick={() => { }} />
                  </a>
                  <a href={"http://www.weplaysports.net/"}>
                  <BannerImage src={"https://d1jozj1mdbjtd9.cloudfront.net/banner04.png"} onClick={() => { }} />
                  </a> */}
                </Slider>
              </BannerWrapper>
            )}
            



            <SliderWrapper>
              <ListLabel
                icon={<Recommend />}
                text={"매칭 대기중인"}
                focusText={"추천 팀"}
              />
              {reloading.games && <CircularProgress />}

              {matchGamesData &&
              matchGamesData.seeGames &&
              matchGamesData.seeGames.length > 0 ? (
                matchGamesData.seeGames.length === 1 ? (
                  <Swipe onSwipeMove={onSwipeMove("games")}>
                    <CarouselProvider
                      naturalSlideWidth={384}
                      naturalSlideHeight={204}
                      totalSlides={1}
                    >
                      <PSlider>
                        {matchGamesData.seeGames.map(item => (
                          <Slide key={item.id}>
                            <GameCard
                              key={item.id}
                              id={item.id}
                              level={item.homeTeam.level}
                              playerCnt={item.playerCnt}
                              shortAddress={
                                item.place
                                  ? item.place.shortAddress
                                  : "경기장 미등록"
                              }
                              gameDate={item.gameDate}
                              startTime={item.startTime}
                              gameStatus={item.gameStatus}
                              teamName={item.homeTeam.teamName}
                              logo={item.homeTeam.logo}
                            />
                          </Slide>
                        ))}
                      </PSlider>
                    </CarouselProvider>
                  </Swipe>
                ) : (
                  <MySlider {...GameSetting}>
                    {matchGamesData.seeGames.map(item => (
                      <div key={item.id}>
                        <GameCard
                          key={item.id}
                          id={item.id}
                          level={item.homeTeam.level}
                          playerCnt={item.playerCnt}
                          shortAddress={
                            item.place
                              ? item.place.shortAddress
                              : "경기장 미등록"
                          }
                          gameDate={item.gameDate}
                          startTime={item.startTime}
                          gameStatus={item.gameStatus}
                          teamName={item.homeTeam.teamName}
                          logo={item.homeTeam.logo}
                        />
                      </div>
                    ))}
                  </MySlider>
                )
              ) : (
                <BlankCard
                  text={"모집 중인 팀이 없습니다."}
                  onRefresh={() => onReFetch("games")}
                />
              )}

              <MyDivider />
            </SliderWrapper>

            <SliderWrapper>
              <ListLabel
                icon={<Recommend />}
                text={"선호지역 근처 "}
                focusText={"경기 매칭"}
              />
              {reloading.nearbys && <CircularProgress />}

              {/* {nearbyGames &&  nearbyGames.seeGamesNearby && <ItemListRenderer type={"nearbys"} items={nearbyGames.seeGamesNearby} />} */}
              {nearbyGames &&
              nearbyGames.seeGamesNearby &&
              nearbyGames.seeGamesNearby.length > 0 ? (
                nearbyGames.seeGamesNearby.length === 1 ? (
                  <Swipe onSwipeMove={onSwipeMove("nearbys")}>
                    <CarouselProvider
                      naturalSlideWidth={384}
                      naturalSlideHeight={204}
                      totalSlides={1}
                    >
                      <PSlider>
                        {nearbyGames.seeGamesNearby.map(item => (
                          <Slide key={item.id}>
                            <GameCard
                              key={item.id}
                              id={item.id}
                              level={item.homeTeam.level}
                              playerCnt={item.playerCnt}
                              shortAddress={
                                item.place
                                  ? item.place.shortAddress
                                  : "경기장 미등록"
                              }
                              gameDate={item.gameDate}
                              startTime={item.startTime}
                              gameStatus={item.gameStatus}
                              teamName={item.homeTeam.teamName}
                              logo={item.homeTeam.logo}
                            />
                          </Slide>
                        ))}
                      </PSlider>
                    </CarouselProvider>
                  </Swipe>
                ) : (
                  <MySlider {...NearbySetting}>
                    {nearbyGames.seeGamesNearby.map(item => (
                      <div key={item.id}>
                        <GameCard
                          key={item.id}
                          id={item.id}
                          level={item.homeTeam.level}
                          playerCnt={item.playerCnt}
                          shortAddress={
                            item.place
                              ? item.place.shortAddress
                              : "경기장 미등록"
                          }
                          gameDate={item.gameDate}
                          startTime={item.startTime}
                          gameStatus={item.gameStatus}
                          teamName={item.homeTeam.teamName}
                          logo={item.homeTeam.logo}
                        />
                      </div>
                    ))}
                  </MySlider>
                )
              ) : (
                <BlankCard
                  text={"선호지역 근처 경기가 없습니다."}
                  onRefresh={() => onReFetch("nearbys")}
                />
              )}
              <MyDivider />
            </SliderWrapper>

            <SliderWrapper>
              <ListLabel
                icon={<Similar />}
                text={"나와 유사 "}
                focusText={"레벨 팀"}
              />

              {reloading.teams && <CircularProgress />}

              {teams &&
              teams.seeLevelGames &&
              teams.seeLevelGames.length > 0 ? (
                teams.seeLevelGames.length === 1 ? (
                  <Swipe onSwipeMove={onSwipeMove("teams")}>
                    <CarouselProvider
                      naturalSlideWidth={384}
                      naturalSlideHeight={204}
                      totalSlides={1}
                    >
                      <PSlider>
                        {teams.seeLevelGames.map(item => (
                          <Slide key={item.id}>
                            <GameCard
                              key={item.id}
                              id={item.id}
                              level={item.homeTeam.level}
                              playerCnt={item.playerCnt}
                              shortAddress={
                                item.place
                                  ? item.place.shortAddress
                                  : "경기장 미등록"
                              }
                              gameDate={item.gameDate}
                              startTime={item.startTime}
                              gameStatus={item.gameStatus}
                              teamName={item.homeTeam.teamName}
                              logo={item.homeTeam.logo}
                            />
                          </Slide>
                        ))}
                      </PSlider>
                    </CarouselProvider>
                  </Swipe>
                ) : (
                  <MySlider {...TeamSetting}>
                    {teams.seeLevelGames.map(item => (
                      <div key={item.id}>
                        <GameCard
                          key={item.id}
                          id={item.id}
                          level={item.homeTeam.level}
                          playerCnt={item.playerCnt}
                          shortAddress={
                            item.place
                              ? item.place.shortAddress
                              : "경기장 미등록"
                          }
                          gameDate={item.gameDate}
                          startTime={item.startTime}
                          gameStatus={item.gameStatus}
                          teamName={item.homeTeam.teamName}
                          logo={item.homeTeam.logo}
                        />
                      </div>
                    ))}
                  </MySlider>
                )
              ) : (
                <BlankCard
                  text={"유사 레벨의 팀이 없습니다."}
                  onRefresh={() => onReFetch("teams")}
                />
              )}
              <MyDivider />
            </SliderWrapper>

            {/* <SliderWrapper>
            <ListLabel
              icon={<RoomList />}
              text={"모집 중인 "}
              focusText={"오픈방"}
            />
            {reloading.rooms && <CircularProgress />}
            {rooms && rooms.seeRooms && rooms.seeRooms.length > 0 ? (
              // <MainList type={"Room"} items={data.seeRooms} />
              <MySlider {...RoomSetting}>
                {rooms.seeRooms.map(item => (
                  <div key={item.id}>
                    <RoomCard
                      key={item.id}
                      id={item.id}
                      gameDate={item.gameDate}
                      startTime={item.startTime}
                      purpose={item.purpose}
                      price={item.price}
                      userCount={item.userCount}
                      requestCount={item.requestCount}
                      playerCnt={item.playerCnt}
                      shortAddress={
                        item.place ? item.place.shortAddress : "경기장 미등록"
                      }
                      gameTitle={item.gameTitle}
                      gameBio={item.gameBio}
                      host={item.host}
                      picture={item.picture}
                    />
                  </div>
                ))}
              </MySlider>
            ) : (
              <BlankCard text={"모집 중인 오픈방이 없습니다."} />
            )}
          </SliderWrapper> */}
          </ContentWrapper>
        </PullToRefresh>
      </Wrapper>
    );
  }
};

export default TeamListPresenter;
