import React, { useState } from "react";
import { useMutation } from "react-apollo-hooks";
import { LOCAL_LOG_IN, GET_TOKEN } from "../SharedQueries";
import { gql } from "apollo-boost";
import styled, { keyframes } from "styled-components";
import { withRouter } from "react-router-dom";

import useInput from "../Hooks/useInput";
import Button from "../Components/Button";
import BoldText from "../Components/BoldText";
import AddressSelect from "../Components/AddressSelect";
import { toast } from "../Components/Popup";


import { withStyles, makeStyles } from "@material-ui/core/styles";
import { blue } from "@material-ui/core/colors";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Loader from "../Components/Loader";
import AgreeText from "../Components/AgreeText";
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import MultipleSelect from "../Components/MultiSelect";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  header: {
    display: "flex",
    alignItems: "center",
    height: 50,
    paddingLeft: theme.spacing(4),
    backgroundColor: theme.palette.background.default
  },
  stepper: {
    position: "fixed",
    width: "100%",
    bottom: 0,
    maxWidth: "937px"
  },
  listItem: {
    paddingLeft:0,
    borderBottom:"1px solid #d5d5d5"
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "none",
    boxShadow: theme.shadows[5],
    padding: "16px 22px 24px",
    color: "#000",
    width: "80%",
    masWidth:"400px",
    fontSize: 18,
    lineHeight: "2em",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    outline:"none",
    "& > h2": {
      fontSize: 22,
      fontWeight: 500
    },
    "&:focus": {
      outline:"none"
    }
  },
  agreeDialog: {
    "& .MuiDialog-paper" : {
      margin:15
    }
  }
}));

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const scaleUp = keyframes`
  0% {
    transform: scale( 1.0 );
  }

  100% {
    transform: scale( 10.0 );
  }
`;

const Idle = keyframes`
  0% {
    transform: scale( 1.0 );
  }

  100% {
    transform: scale( 1.1 );
  }
`;

const moving = keyframes`
  from {
    right: 20px;
  }

  to {
    right: -30%;
  }
`;


const Container = styled.div`
  min-height: 100vh;
  flex-grow: 1;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(https://d1jozj1mdbjtd9.cloudfront.net/survey_bg.jpg);
  background-position: center;

  /* 
  background-color: #227fff;
  background: linear-gradient(150deg, #227fff 51%, transparent 50%),
    linear-gradient(30deg, #ebeff3 50%, #227fff 50%); */
`;

const Header = styled.div`
  padding: 40px 0px 30px 9vw;
  max-width: 700px;
  margin: 0 auto;
`;
const UserInfoHeader = styled(Header)`
  padding: 40px 9vw 30px;
`;

const HeaderStep = styled.span`
  font-size: 17px;
  padding: 5px 10px;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  font-family: 'NanumSqure';
`;

const HeaderText = styled.div`
  font-size: 26px;
  color: #fff;
  /* max-width: 80vw; */
  margin-top: 30px;
  line-height: 1.4em;
  word-break: keep-all;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 40vh;
  justify-content: space-evenly;
  animation: 1s ${fadeIn} ease-out;
`;

const InputBox = styled.div`
  background-color: #fff;
  display: flex;
  color: #000;
  padding: 20px;
  margin-bottom: 25px;
  border-radius: 15px;
  align-items: center;
  transition:width 2s, height 2s, transform 2s;
  width: 95%;
  max-width: 500px;
  justify-content: space-around;

`;

const InputColumn = styled.div`
  &:first-child {
    max-width: 50px;
    font-weight: 700;
    margin-right: 10px;
    font-size: 15px;
  }
  &:last-child {
    display:flex;
    flex-direction:column;
    width:230px;
  }
`;



const CheckListWrapper = styled.div`
  padding: 30px;
  color: #000;
  background-color: #fff;
  width: 95vw;
  max-width: 430px;
  margin: 0 auto;
  border-radius: 15px;
  padding: 10px 15px;
  animation: 1s ${fadeIn} ease-out;
  margin-bottom:80px;
`;

const YesNoWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  animation: 1s ${fadeIn} ease-out;
  height: 40vh;
  flex-direction: column;
`;

const FocusBox = styled.div`
  width: 90%;
  max-width: 450px;
  height: 85px;
  border-radius: 15px;
  background-color: rgba(64, 231, 72, 0.2);
  position: absolute;
  display: none;
`;

const YesNoBox = styled.button`
  border-radius: 15px;
  /* padding: 30px 20px; */
  font-size: 18px;
  font-weight: 600;
  color: #000;
  background-color: #fff;
  width: 90%;
  height: 85px;
  max-width: 450px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0px;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);

  ${props => props.focus && "border: 3px solid #40e748;"};

  & > ${FocusBox} {
    display: ${props => props.focus?"block":"none"}
  }
`;

const CheckContainer = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  z-index:1;
  padding: 15vh 0px 0px;
  align-items:center;
`;

const CheckTitle = styled.div`
  font-family:"Jalnan";
  font-size:40px;
  margin-bottom: 3vh;
`;

const CheckText = styled.div`
  font-size:3vh;
  margin:15px 0px 25px;
  text-align:center;
`;

const CheckBox = styled.div`
  background-color:#fff;
  border-radius:20px;
  width:92%;
  color: #000;
  max-width: 500px;
  padding: 20px 10px;
  line-height: 3em;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
`;

const CheckBoxCheck = styled.div`
`;

const CheckBoxSpan = styled.span`
  cursor:pointer;
`;

const CheckButton = styled.button`
  background-color: ${props=>props.disabled? "#ebebeb":props => props.theme.yellowColor};
  border: 1px solid ${props=>props.disabled? "#ebebeb":props => props.theme.yellowColor};
  color: ${props=>props.disabled? "#868282":"#000"};
  cursor: pointer;
  font-size: 20px;
  padding: 15px 0px;
  text-align: center;
  font-weight: 500;
  font-family: "NanumSqure";
  border-radius: 7px;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  position: relative;
  max-width: 600px;
  margin-top: 20px;
  width:88%;
  max-width: 500px;
  border-radius: 30px;
`;


const IntroContainer = styled.div`
  background-color:#fff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  z-index:1;
  padding: 15vh 0px 0px;
  align-items:center;
  color: #000;
  animation: 1s ${fadeIn} ease-out;
`;

const IntroText = styled(CheckText)`
  text-align:left;
  color:#000;
`;

const IntroBigText = styled.span`
  font-size:24px;
  font-weight:600;
  color:#000;
`;

const StartButton = styled.button`
  background-color:${props=>props.theme.blueColor};
  position:absolute;
  border-radius:50%;
  
  height: 300px;
  width: 300px;
  right: -150px;
  bottom: 3%;
  border: none;
  &.scale {
    animation: 1.5s ${scaleUp} ease-out;
  }
`;
const StartText = styled.span`
  color:#fff;
  position:absolute;
  bottom: calc(3% + 125px);
    font-size: 25px;
  animation: 1s ${Idle} linear;
  animation-iteration-count: infinite;
  font-family: 'Jalnan';
  right: 20px;
  &.moving {
    animation: 1.5s ${moving} ease-out;
  }
`;

const Logo = styled.img`
  width:50px;
`;

const ListHeader = styled.div`
  min-width: 60px;
  margin-top: 6px;
  margin-bottom: 6px;
  text-align: center;
  color:#227fff;
  font-weight: 600;
`;
const ListBody = styled.div`
  flex: 1 1 auto;
  color:#000;
  margin-top: 6px;
  margin-bottom: 6px;
`;

const Stepper = styled.div`
  width: 100%;
  bottom: 0;
  position: fixed;
  max-width: 937px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  padding: 8px 15px 20px 15px;;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
`;

const PrevButton = styled.button`
  color:#fff;
  background: none;
    border: none;
    font-family: 'NanumSqure';
`;

const SkipButton = styled.button`
  color:#fff;
  font-family: 'NanumSqure';
  background: none;
    border: none;
    min-width: 51.45px;
`;

const NextButton = styled.button`
  background-color:${props=>props.theme.yellowColor};
  border: none;
  padding: 15px 40px;
  font-size: 20px;
  font-weight: 600;
  font-family: 'NanumSqure';
  border-radius: 30px;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
`;

const FakeImg = styled.img`
  /* display:none; */
  position: fixed;
  z-index: -1;
  /* width: 100%; */
  top:0px;
`;

const UserInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  animation: 1s ${fadeIn} ease-out;
  padding-bottom:70px;
`;

const UserInfoBox = styled.div`
  background-color: #fff;
  display: flex;
  color: #000;
  padding: 20px 3vw;
  margin-bottom: 25px;
  border-radius: 15px;
  align-items: center;
  transition:width 2s, height 2s, transform 2s;
  width: 95%;
  max-width: 500px;
  justify-content: space-around;
`;

const UserInfoColumn = styled.div`
  position: relative;
  &:first-child {
    max-width: 50px;
    font-weight: 700;
    margin-right: 10px;
    font-size: 15px;
    & > span {
      color:red;
      font-size:11px;
      padding-right:10px;
      position: absolute;
      top: -15px;
      width: 100px;
      left: -10px;
    }
  }
  &:last-child {
    display:flex;
    flex-direction:column;
    width:215px;
    & .MuiFormControl-root {
      border:1px solid #999;
      border-radius:3px;
    }
    & > span {
      color:red;
      font-size:11px;
      font-weight: 700;
      padding-right:10px;
      position: absolute;
      top: -15px;
      width: 100px;
    }
  }
`;
const Input = styled.input`
  border-radius: 3px;
  padding: 7px;
  border: 1px solid #999;
  font-size: 18px;
`;


const InputAdornment = styled.div`
      position: absolute;
    right: 10px;
    top: 10px;
    font-weight: 600;
`;

const BlueCheckbox = withStyles({
  root: {
    color: blue[400],
    "&$checked": {
      color: blue[600]
    }
  },
  checked: {}
})(props => <Checkbox color="default" {...props} />);

const GreenRadio = withStyles({
  root: {
    color: "#ffbe21",
    "&$checked": {
      color: "ffbe21"
    }
  },
  checked: {}
})(props => <Radio  color="default" {...props} />);


export default withRouter(({ match: { params }, history }) => {
  const code = params.code;

  console.log("code === ", code);
  const classes = useStyles();

  //const [intro, setIntro] = useState(true);
  //const [agree, setAgree] = useState(true);

  const [mode, setMode] = useState("agree");

  const [anim_start, setAnimation] = useState(false);
  const [open, setOpen] = useState([false, false, false]);
  const [uploading, setUploading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const [activeStep, setActiveStep] = useState(0);
  const maxSteps = tutorialSteps.length;
  const [checked, setChecked] = useState([]);
  const [answer] = useState({});
  const [yesno, setYesno] = useState(null);


  // 사용자 입력 데이터 
  const usernameInput = useInput("");
  const contactInput = useInput("", newValue=> /^[0-9-]{1,20}$/g.test(newValue), false, true);
  const backNoInput = useInput("", newValue => !isNaN(newValue) && newValue.length < 4);
  const positionInput = useInput("" );
  const heightInput = useInput("", newValue => !isNaN(newValue) && newValue.length < 4);
  const weightInput = useInput("", newValue => !isNaN(newValue) && newValue.length < 4);
  const ageInput = useInput("", newValue => !isNaN(newValue) && newValue.length < 4);

  const [level, setLevel] = useState();
  const [grade, setGrade] = useState();
  const region01 = useInput("");
  const region02 = useInput("");

  const createRegisterMutation = useMutation(CREATE_REGISTER);
  const generateTokenMutation = useMutation(GET_TOKEN);
  const localLogInMutation = useMutation(LOCAL_LOG_IN);


  /* *******************
  * START / 사용자 동의 체크 관련 
    ******************* */ 

  const [allCheck, setAllCheck] = useState(false);
  const [checkeds, setCheckeds] = useState({
    first: false,
    second: false,
    third: false
  });

  const { first, second, third } = checkeds;
  const handleAllCheck = () => {
    setAllCheck(!allCheck);
    if(Object.values(checkeds).every(v => v === true)) {
      setCheckeds({ 
        first: false,
        second: false,
        third: false
      });
    } else {
      setCheckeds({ 
        first: true,
        second: true,
        third: true
      });
    }
  };

  const handleDialogOpen = index => () => {
    const data = [...open];
    data[index] = true;
    setOpen(data);
  };
  const handleDialogClose = index => () => {
    const data = [...open];
    data[index] = false;
    setOpen(data);
  };

  const handleCheck = name => event => {
    if(!event.target.checked) {
      setAllCheck(false);
    }
    setCheckeds({ ...checkeds, [name]: event.target.checked });

    const allCheckObj = {
      ...checkeds,
      [name]: event.target.checked
    };

    if (Object.values(allCheckObj).every(v => v === true)) {
      setAllCheck(true);
    }
  };
   /********************
   * END / 사용자 동의 체크 관련 종료 
   ********************/ 

  // 설문조사 농구레벨 관련
  const handleChange = event => {
    if(event.target.value) {
      const basket = event.target.value.split(',');
      setLevel(parseInt(basket[0]));
      setGrade(basket[1]);
    }    
  }; 

  // 설문조사 체크박스 관련
  const handleToggle = value => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  // 회원가입 완료창 팝업 종료
  const handleModalClose = async () => {
    setUploading(true);
    const {
      data: {  generateToken }
    } = await generateTokenMutation({
      variables: {
        snsId:code,
      }
    });
    localLogInMutation({ 
      variables: { token : generateToken } 
    });
    setUploading(false);
  }

  // 설문조사 Next 및 skip 버튼
  const handleNext = (behavior, yesno) => {
    console.log("handleNext!");

    if(behavior === 'skip') {
      if (activeStep + 1 < maxSteps) {
        setActiveStep(prevActiveStep => prevActiveStep + 1);
      }
    } else {
      if (activeStep + 1 <= maxSteps) {
        console.log(level, grade);
        if(tutorialSteps[activeStep].type === "radio") { 
          if(level === undefined || grade === undefined) {
            toast.info("필수 선택입니다.");
            return; 
          }       
        }
        else if(tutorialSteps[activeStep].type === "write") {
          console.log(region01.value, region02.value);      

          if(region01.value === "" || region02.value==="") {
            toast.info("모든 값을 입력해주세요.");
            return; 
          }

          let addressCheck = true;
          if(region01.value !== "세종" && region01.value.split(' ').length < 2) {
            addressCheck = false;
          }
          if(region02.value !== "세종" && region02.value.split(' ').length < 2) {
            addressCheck = false;
          }
          if(!addressCheck) {
            toast.info("모든 값을 입력해주세요.");
            return; 
          }
        
        } else if(tutorialSteps[activeStep].type === "check") {
          answer[activeStep] = tutorialSteps[activeStep].fullText;
        } else if(tutorialSteps[activeStep].type === "yesno" && parseInt(yesno) === 1) {
          answer[activeStep] = tutorialSteps[activeStep].text01;
        } else if(tutorialSteps[activeStep].type === "yesno" && parseInt(yesno) === 2) {
          answer[activeStep] = tutorialSteps[activeStep].text02;
        }
       

        setYesno(null);
        if (activeStep + 1 !== maxSteps)
          setActiveStep(prevActiveStep => prevActiveStep + 1);
      }
    }

    if (activeStep + 1 === maxSteps) {

      console.log(answer);

      setMode("input");

      // setUploading(true);
      // try {
      //   const regions = [];
      //   if(region01.value !== "") regions.push(region01.value);
      //   if(region02.value !== "") regions.push(region02.value);

      //   const { data: { createRegister } } = await createRegisterMutation({
      //     variables : {
      //       snsId:code,
      //       info : JSON.stringify(answer),
      //       regions,
      //       level,
      //       grade,
      //       isAgreeMarketing:checkeds.third
      //     }
      //   })

      //   if(createRegister) {
      //     setModalOpen(true); 
      //     setMode("input")
      //   } else {
      //     toast.error(<div>오류가 발생했습니다.<br />관리자에게 문의바랍니다.</div>);
      //   }
      // } catch (e) {
      //   console.log(e);
      //   toast.error("다시 시도해주세요.");
      // } finally {
      //   setUploading(false);
      // }
    }
  };

  const handleRegister = async () => {
     setUploading(true);
      try {
        const regions = [];
        if(region01.value !== "") regions.push(region01.value);
        if(region02.value !== "") regions.push(region02.value);


        if(contactInput.value ==="") {
          toast.error("매칭을 위해 연락처를 반드시 입력해주세요.");
          return;
        }

        if(positionInput.value === "" || heightInput.value === "" || weightInput.value === "" || backNoInput.value === "") {
          toast.error("필수값을 모두 입력해주세요.");
          return;
        }

        const { data: { createRegister } } = await createRegisterMutation({
          variables : {
            snsId:code,
            info : JSON.stringify(answer),
            contact:contactInput.value,
            regions,
            level,
            grade,
            age: ageInput.value,
            position: positionInput.value,
            height: parseInt(heightInput.value),
            weight: parseInt(weightInput.value),
            backNo: parseInt(backNoInput.value),
            isAgreeMarketing:checkeds.third
          }
        });

        console.log(createRegister);

        if(createRegister) {
          setModalOpen(true); 
        } else {
          toast.error(<div>오류가 발생했습니다.<br />관리자에게 문의바랍니다.</div>);
        }
      } catch (e) {
        console.log(e);
        toast.error("다시 시도해주세요.");
      } finally {
        setUploading(false);
      }
  }

  const handleYesNo = (value) => () => {
    delete answer[activeStep];
    setYesno(value);
    handleNext('next', value);
    // (function() {
     
    // })();
  }

  const handleBack = () => {
    console.log(answer);
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const onClickStart = () => {
    setAnimation(true);
  };

  const onAnimationEnd = () => {
    setMode("intro");
  }

  const onClickCheck = () => {
    if(code === null || code === undefined) {
      toast.error(<div>잘못된 접근입니다. <br/> SNS 로그인을 진행해주세요</div>);
    } else
      setMode("survey");
  };



  if(mode === "agree") {
    return (<>
      <CheckContainer>
        <FakeImg src="https://d1jozj1mdbjtd9.cloudfront.net/web_splash_bg.jpg" />
        <CheckTitle>오늘도 WePlay!</CheckTitle>
        <CheckText>
          더 정확하고 <br /> 빠른 경기 매칭을 위해
        </CheckText>
        <CheckText>
          <BoldText text={"#선택 약관"} />을 동의해주세요.
        </CheckText>
        <CheckBox>
          <CheckBoxCheck>
            <BlueCheckbox checked={allCheck} onChange={handleAllCheck}/>
            <CheckBoxSpan onClick={handleAllCheck}>
              전체 동의
            </CheckBoxSpan>
          </CheckBoxCheck>
          <CheckBoxCheck>
            <BlueCheckbox checked={first} onChange={handleCheck('first')}/>
            <CheckBoxSpan onClick={handleDialogOpen(0)}>
              [필수] 이용약관 동의
            </CheckBoxSpan>
           
          </CheckBoxCheck>
          <CheckBoxCheck>
           <BlueCheckbox checked={second} onChange={handleCheck('second')} />
            <CheckBoxSpan onClick={handleDialogOpen(1)}>
              [필수] 개인정보 수집 및 이용 동의
            </CheckBoxSpan>
          </CheckBoxCheck>
          <CheckBoxCheck>
            <BlueCheckbox checked={third} onChange={handleCheck('third')} />
            <CheckBoxSpan onClick={handleDialogOpen(2)}>
              [선택] 개인정보 제 3자 제공에 대한 동의
            </CheckBoxSpan>
          </CheckBoxCheck>
        </CheckBox>
        {first && second ? (
          <CheckButton onClick={onClickCheck} disabled={false}>위플레이 시작하기</CheckButton>
        ) : (
          <CheckButton onClick={onClickCheck} disabled={true}>위플레이 시작하기</CheckButton>
        )}
          
        <Dialog
          open={open[0]}
          onClose={handleDialogClose(0)}
          scroll={"paper"}
          className={classes.agreeDialog}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <DialogTitle id="scroll-dialog-title">서비스 이용약관</DialogTitle>
          <DialogContent dividers={true}>
            <AgreeText type={"service-usage"} />
          </DialogContent>
        </Dialog>

        <Dialog
          open={open[1]}
          onClose={handleDialogClose(1)}
          scroll={"paper"}
          className={classes.agreeDialog}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <DialogTitle id="scroll-dialog-title"> 개인정보 처리방침</DialogTitle>
          <DialogContent dividers={true}>
            <AgreeText type={"personal-info-usage"} />
          </DialogContent>
        </Dialog>

        <Dialog
          open={open[2]}
          onClose={handleDialogClose(2)}
          scroll={"paper"}
          className={classes.agreeDialog}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <DialogTitle id="scroll-dialog-title">제 3자 제공에 대한 동의</DialogTitle>
          <DialogContent dividers={true}>
            <AgreeText type={"personal-info-provide"} />
          </DialogContent>
        </Dialog>



      </CheckContainer>
      </>
    );
  }
  else if(mode === "intro") {
    return(
      <IntroContainer>
       
        <CheckTitle> 
          <Logo src="/imgs/weplay_symbol.png" alt="logo"></Logo>
          <br/>
          더 즐거운<br/> 플레이를 위해
        </CheckTitle>
        <IntroText>
          원하는 장소, 원하는 상대 정보를 <br/> 더 정확히 얻으려면, 조금 귀찮아도
        <IntroText><IntroBigText>#나의성향 #셀프진단</IntroBigText>을<br/> 작성해주세요.</IntroText>
        </IntroText>
        
        <StartButton className={anim_start ? 'scale' : ''} onClick={onClickStart} onAnimationEnd={onAnimationEnd}></StartButton>
        <StartText className={anim_start ? 'moving' : ''} onClick={onClickStart}>START {">"}</StartText>
      </IntroContainer>
    )
    
  } else if(mode === "survey") {

    const FormatSido = (region) => {
      let Sido = '';
      let Sigungu = '';
    
      if(region.value !== "") {
        Sido = region.value.split(' ')[0];
      
        if(region.value.split(' ').length > 2) {
          Sigungu = region.value.split(' ')[1] +' '+ region.value.split(' ')[2];
        } else {
          Sigungu = region.value.split(' ')[1];
        }
      } 
      return [Sido, Sigungu];
    }
   
    return (
      <Container className={classes.root}>
        {uploading && <Loader />}
        <Header>
          <HeaderStep>#나의성향#셀프진단</HeaderStep>
          <HeaderText>
            <BoldText text={tutorialSteps[activeStep].boldLabel} />
            {tutorialSteps[activeStep].label}
          </HeaderText>
        </Header>
  
        {tutorialSteps[activeStep].type === "write" && (
          <InputWrapper>
            <InputBox>
              <InputColumn>1순위</InputColumn>
              <InputColumn>
                <AddressSelect 
                  defaultSido={FormatSido(region01)[0]}
                  defaultSigungu={FormatSido(region01)[1]}
                  onChange={region01.setValue} />
              </InputColumn>
            </InputBox>
  
            <InputBox>
              <InputColumn>2순위</InputColumn>
              <InputColumn>
                <AddressSelect 
                  defaultSido={FormatSido(region02)[0]}
                  defaultSigungu={FormatSido(region02)[1]}
                  onChange={region02.setValue} />
              </InputColumn>
            </InputBox>
  
          </InputWrapper>
        )}

        {tutorialSteps[activeStep].type === "radio" && (
        <CheckListWrapper>
          <List className={classes.root}>
            {tutorialSteps[activeStep].answers.map((answer, index) => {
              const labelId = `checkbox-list-secondary-label-${index}`;
              return (
                <ListItem className={classes.listItem} key={index}>
                  <ListHeader>{answer.primary}</ListHeader>
                  <ListBody>{answer.secondary ? answer.secondary : null}</ListBody>

                  <ListItemSecondaryAction>
                    <RadioGroup aria-label="gender" name="gender1" value={`${level},${grade}`} onChange={handleChange}>
                      <GreenRadio
                        edge="end"
                        name={`radio-button-${activeStep}`}
                        value={answer.value? answer.value : index}
                        //onChange={handleToggle(index)}
                        //checked={checked.indexOf(index) !== -1}
                        inputProps={{ "aria-labelledby": labelId }}
                      />
                    </RadioGroup>
                  </ListItemSecondaryAction>
                </ListItem>
              );
            })}
          </List>
        </CheckListWrapper>
        )}
  
        {tutorialSteps[activeStep].type === "check" && (
          <CheckListWrapper>
            <List dense className={classes.root}>
              {tutorialSteps[activeStep].answers.map((answer, index) => {
                const labelId = `checkbox-list-secondary-label-${index}`;
                return (
                  <ListItem key={index} button>
                    <ListItemText
                      id={labelId}
                      primary={answer.primary}
                      secondary={answer.secondary ? answer.secondary : null}
                    />
                    <ListItemSecondaryAction>
                      <BlueCheckbox
                        edge="end"
                        onChange={handleToggle(index)}
                        checked={checked.indexOf(index) !== -1}
                        inputProps={{ "aria-labelledby": labelId }}
                      />
                    </ListItemSecondaryAction>
                  </ListItem>
                );
              })}
            </List>
          </CheckListWrapper>
        )}
  
        {tutorialSteps[activeStep].type === "yesno" && (
          <YesNoWrapper>
            <YesNoBox onClick={handleYesNo(1)} focus={yesno === 1 || answer[activeStep] === tutorialSteps[activeStep].text01}>
              <FocusBox />
              {tutorialSteps[activeStep].text01}
            </YesNoBox>
            <YesNoBox onClick={handleYesNo(2)} focus={yesno === 2 || answer[activeStep] === tutorialSteps[activeStep].text02}>
              <FocusBox />
              {tutorialSteps[activeStep].text02}
            </YesNoBox>
          </YesNoWrapper>
        )}

        <Stepper>
          <PrevButton onClick={handleBack} disabled={activeStep === 0}>{"< Prev"}</PrevButton>
          {tutorialSteps[activeStep].type !== "yesno" && (<NextButton onClick={() => handleNext('next')}>{"NEXT >"}</NextButton>)}
          <SkipButton disabled={!tutorialSteps[activeStep].skip} onClick={() => handleNext('skip')}>{tutorialSteps[activeStep].skip && "Skip >"}</SkipButton>
        </Stepper>


        
      </Container>
    );
  } else if(mode === "input") {
    return (
      <Container className={classes.root}>
        {uploading && <Loader />}
        <UserInfoHeader>
          <HeaderStep>#내정보</HeaderStep>
          <HeaderText>
            <BoldText text={"회원 정보"} />를 입력해주세요.
          </HeaderText>
        </UserInfoHeader>

        <UserInfoWrapper>
          {/* <UserInfoBox>
            <UserInfoColumn>이름</UserInfoColumn>
            <UserInfoColumn>
              <Input
                value={usernameInput.value}
                onChange={usernameInput.onChange}
              />
            </UserInfoColumn>
          </UserInfoBox> */}
          <UserInfoBox>
            <UserInfoColumn><span>*</span> 연락처</UserInfoColumn>
            <UserInfoColumn>
              <Input value={contactInput.value} onChange={contactInput.onChange} />
            </UserInfoColumn>
          </UserInfoBox>

          <UserInfoBox>
            <UserInfoColumn>나이</UserInfoColumn>
            <UserInfoColumn>
              <Input value={ageInput.value} onChange={ageInput.onChange} />
              <InputAdornment>세</InputAdornment>
            </UserInfoColumn>
          </UserInfoBox>
          <UserInfoBox>
            <UserInfoColumn><span>*</span> 포지션</UserInfoColumn>
            <UserInfoColumn>
              <span>※ 다중선택 가능</span>
              <MultipleSelect
                  value={
                    positionInput.value.length > 0
                      ? positionInput.value.split(",")
                      : []
                  }
                  onChange={positionInput.setValue}
                  datas={basketPositions}
                />
            </UserInfoColumn>
          </UserInfoBox>
          <UserInfoBox>
            <UserInfoColumn><span>*</span> 등번호</UserInfoColumn>
            <UserInfoColumn>
              <Input
                value={backNoInput.value}
                onChange={backNoInput.onChange}
              />
              <InputAdornment>번</InputAdornment>
            </UserInfoColumn>
          </UserInfoBox>
          <UserInfoBox>
            <UserInfoColumn><span>*</span> 신장</UserInfoColumn>
            <UserInfoColumn>
              <Input
                value={heightInput.value}
                onChange={heightInput.onChange}
              />
               <InputAdornment>cm</InputAdornment>
            </UserInfoColumn>
          </UserInfoBox>
          <UserInfoBox>
            <UserInfoColumn><span>*</span> 체중</UserInfoColumn>
            <UserInfoColumn>
              <Input
                value={weightInput.value}
                onChange={weightInput.onChange}
              />
               <InputAdornment>kg</InputAdornment>
            </UserInfoColumn>
          </UserInfoBox>

        </UserInfoWrapper>
        <Button
          text={"회원가입 완료"}
          color={"#000"}
          onClick={handleRegister}
        />
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={modalOpen}
          onClose={handleModalClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={modalOpen}>
            <div className={classes.paper}>
              <h2 id="transition-modal-title">회원가입 완료!</h2>
              <p id="transition-modal-description">이제 위플레이를 즐기시면 됩니다.</p>
              <CheckButton onClick={handleModalClose}>위플레이 시작하기</CheckButton>
            </div>
          </Fade>
        </Modal>
      </Container>
    );
  }

});





const CREATE_REGISTER = gql`
  mutation createRegister(
    $info: String!
    $regions: [String!]!
    $snsId: String!
    $contact: String!
    $level: Int!
    $grade: String!
    $age: String
    $position: String
    $height: Int
    $weight: Int
    $backNo: Int
    $isAgreeMarketing: Boolean!
  ) {
    createRegister(
      info: $info
      regions: $regions
      snsId: $snsId
      contact: $contact
      level: $level
      grade: $grade
      age: $age
      position:$position
      height:$height
      weight:$weight
      backNo:$backNo
      isAgreeMarketing: $isAgreeMarketing
    )
  }
`;

const tutorialSteps = [
  {
    type: "radio",
    skip:false,
    boldLabel: "나의 농구 레벨",
    label: "을 선택하세요",
    answers: [
      {
        primary: "왕초보",
        secondary:"이제 시작 단계의 비기너",
        value:"0,D"
      },
      {
        primary: "Lv.1",
        secondary:"3년 미만의 초보자 단계",
        value:"1,C1"
      },
      {
        primary: "Lv.2",
        secondary:"시작한지 3~5년 정도 되었지만 체력이나 기술이 조금 부족한 단계",
        value:"2,C2"
      },
      {
        primary: "Lv.3",
        secondary:"학창시절부터 꾸준히 농구를 해와서 경기 중 기본적인 역할을 수행할 수 있는 단계",
        value:"3,C3"
      },
      {
        primary: "Lv.4",
        secondary:"어느 경기에서도 BEST5 멤버로 나의 플레이에 자신있는 단계",
        value:"4,C4"
      },
      {
        primary: "Lv.5",
        secondary:"자타가 공인하는 에이스로 4쿼터 기준 평균 20점 이상의 에이스 단계",
        value:"5,C5"
      }
    ]
  },
  {
    type: "write",
    skip:false,
    boldLabel: "선호하는 지역",
    label: "을 선택하세요."
  },
  {
    type: "yesno",
    skip:true,
    boldLabel: "자신의 성향과 가까운 성향",
    label: "을 선택하세요",
    text01: "레벨이 높은 사람들과의 치열한 경기",
    text02: "승패보다는 즐겁게 땀 흘리는 경기",
  },
  {
    type: "yesno",
    skip:true,
    boldLabel: "자신의 성향과 가까운 성향",
    label: "을 선택하세요",
    text01: "새로운 사람들과의 스포츠 활동",
    text02: "손발이 잘맞는 기존 팀원들과의 경기",
  },
  {
    type: "yesno",
    skip:true,
    boldLabel: "자신의 성향과 가까운 성향",
    label: "을 선택하세요",
    text01: "치열한 경기 후 귀가하여 컨디션 조절",
    text02: "즐겁게 운동 후 사람들과의 친목도모",
  },
];

const basketPositions = [
  {
    value: "PG",
    text: "PG"
  },
  {
    value: "SG",
    text: "SG"
  },
  {
    value: "SF",
    text: "SF"
  },
  {
    value: "PF",
    text: "PF"
  },
  {
    value: "C",
    text: "C"
  }
];