import { gql } from "apollo-boost";

export const GET_GAME = gql`
  query seeFullGame($id: String!) {
    seeFullGame(id: $id) {
      id
      gameName
      playerCnt
      winner
      result {
        id
        homeTeam
        awayTeam
        homeTeamFoul
        awayTeamFoul
        playingTime
      }
      homeTeam {
        id
        teamName
        logo
        host {
          id
          username
          basket {
            id
            height
            weight
            position
            backNo
          }
          userStat {
            id
            game {
              id
            }
            score
            rebound
            assist
            blocking
            steal
            turnOver
            foul
            oneScore
            twoScore
            threeScore
            freeShot
            freeShotTotal
          }
        }
        users {
          id
          username
          basket {
            id
            height
            weight
            position
            backNo
          }
          userStat {
            id
            game {
              id
            }
            score
            rebound
            assist
            blocking
            steal
            turnOver
            foul
            oneScore
            twoScore
            threeScore
            freeShot
            freeShotTotal
          }
        }
      }
      awayTeam {
        id
        teamName
        logo
        host {
          id
          username
          basket {
            id
            height
            weight
            position
            backNo
          }
          userStat {
            id
            game {
              id
            }
            score
            rebound
            assist
            blocking
            steal
            turnOver
            foul
            oneScore
            twoScore
            threeScore
            freeShot
            freeShotTotal
          }
        }
        users {
          id
          username
          basket {
            id
            height
            weight
            position
            backNo
          }
          userStat {
            id
            game {
              id
            }
            score
            rebound
            assist
            blocking
            steal
            turnOver
            foul
            oneScore
            twoScore
            threeScore
            freeShot
            freeShotTotal
          }
        }
      }
    }
  }
`;
