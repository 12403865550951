import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Helmet } from "rl-react-helmet";
import Loader from "../../Components/Loader";
import Avatar from "../../Components/Avatar";
import BoldText from "../../Components/BoldText";
import SwipeableViews from "react-swipeable-views";
import BaseHeader from "../../Components/Header/BaseHeader";
import { makeStyles } from "@material-ui/core/styles";

import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Grid from "@material-ui/core/Grid";
import { Link } from "react-router-dom";
import PullToRefresh from "rmc-pull-to-refresh";
import RefreshLoader from "../../Components/RefreshLoader";
import DedicateLoader from "../../Components/DedicateLoader";

const Wrapper = styled.div`
  background-color: #081f40;
  min-height: 100vh;
`;

const ContentWrapper = styled.div`
  /* padding-top: 56px; */
  margin: auto 0px;
  background-color: ${props => props.theme.innerBgColor};
  /* min-height: 100vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Header = styled.header`
  display: flex;
  align-items: flex-end;
  justify-content: space-around;
  width: 80%;
  margin: 0 auto;
  margin-bottom: 20px;
  padding-top: 10px;
  @media (max-width: 768px) {
    width: 95%;
  }
`;

const HeaderColumn = styled.div`
  padding: 7px;
  font-size: 16px;
  text-align: center;
`;

const ColumnWrap = styled.div``;

const ColumnRow = styled.div`
  font-size: 14px;
  margin: 5px 0px;
  display: flex;
  justify-content: center;
`;

const BigColumnRow = styled(ColumnRow)`
  position: absolute;
  width: 120px;
  top: 0px;
`;

const HeaderTeamInfo = styled.div`
  background-color: #040f20;
  border-radius: 10px;
  color: #fff;
  padding: 5px;
  font-size: 16px;
  width: 80px;
  margin: 0 auto;
`;

const HeaderOrder = styled.div`
  font-size: 20px;
  font-weight: 800;
  color: #ffbe21;
`;

const HeaderAvatar = styled.div`
  border: 1px solid #fff;
  border-radius: 50%;
  padding: 4px;
`;

const HeaderBigAvatar = styled(HeaderAvatar)`
  border: 1px solid #227fff;
`;
const TeamOrder = styled.div`
  width: 30px;
  margin-left: 5px;
  font-size: 18px;
  font-weight: 500;
`;

const CrownImg = styled.img`
  width: 60px;
`;

const MyTabs = styled(Tabs)`
  color: #fff;
  padding: 15px 25px 15px;
  max-width: 500px;
  width: 100%;
  & button:not(.Mui-selected) {
    z-index: 0;
  }
  .MuiTabs-flexContainer {
    background-color: #040f20;
    border-radius: 20px;
  }
  .MuiTabs-indicator {
    top: 0px;
    height: 4px;
    border-radius: 20px;
    height: 38px;
    background-color: ${props => props.theme.blueColor}!important;
  }
`;

const MyTabPanel = styled(TabPanel)`
  .MuiListItemText-secondary {
    color: #fff;
  }
`;

const useStyles = makeStyles(theme => ({
  tabRoot: {
    fontSize: 16,
    fontFamily: "NanumSqure",
    zIndex: 1,
    minHeight: "38px"
  },
  bottomList: {
    maxWidth: 500,
    margin: "0 auto",
    "& > li:nth-child(2n+1)": {
      backgroundColor: "#1d4b8c"
    }
  }
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      <Box p={0}>{children}</Box>
    </Typography>
  );
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`
  };
}

export default ({
  loading,
  data,
  handleInfiniteScroll,
  top3Monthly,
  settop3Monthly,
  top3Total,
  settop3Total,
  onRefresh,
  refreshing
}) => {
  if (loading) {
    return (
      <Wrapper>
        <BaseHeader
          text={"랭킹"}
          isCenter={true}
          isBack={true}
          isClose={false}
          isMenu={false}
        />
        <Helmet>
          <title>랭킹 | WePlay</title>
        </Helmet>
        <Loader />
      </Wrapper>
    );
  } else if (!loading && data && data.seeTeamRank && data.seeTeamRankMonthly) {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    const handleChangeIndex = index => {
      setValue(index);
    };

    const { seeTeamRank, seeTeamRankMonthly } = data;

    const [isMonthlyHeader, setIsMonthlyHeader] = useState(false);
    const [isTotalHeader, setIsTotalHeader] = useState(false);

    const [totalRank, setTotalRank] = useState();
    const [monthRank, setMonthRank] = useState();

    useEffect(() => {

      const topTeamsMonthly = seeTeamRankMonthly
        .slice(0, 3)
        .filter(team => team.win + team.lose !== 0);
      const topTeams = seeTeamRank
        .slice(0, 3)
        .filter(team => team.win + team.lose !== 0);


      if (topTeams.length < 3) {
        setIsTotalHeader(false);
        setTotalRank(seeTeamRank);
      } else {
        setIsTotalHeader(true);
        settop3Total(topTeams);
        setTotalRank(seeTeamRank.slice(3,seeTeamRankMonthly.length));
      }

      if (topTeamsMonthly.length < 3) {
        setIsMonthlyHeader(false);
        setMonthRank(seeTeamRankMonthly);
      } else {
        setIsMonthlyHeader(true);
        settop3Monthly(topTeamsMonthly);
        setMonthRank(seeTeamRankMonthly.slice(3,seeTeamRankMonthly.length));
      }

    }, [data]);

    


    return (
      <Wrapper>
        <BaseHeader
          text={"랭킹"}
          isCenter={true}
          isBack={true}
          isClose={false}
          isMenu={false}
        />
        <Helmet>
          <title>랭킹 | WePlay</title>
        </Helmet>

        <PullToRefresh
          style={{ overflow: "auto", paddingTop:56, minHeight:"100vh", maxHeight:"100vh" }}
          direction="down"
          distanceToRefresh={50}
          damping={100}
          onRefresh={() => onRefresh()}
          refreshing={refreshing}
          onScroll={e => handleInfiniteScroll(e)}
          indicator={{
            activate: <DedicateLoader/>,
            deactivate: <></>,
            release: <RefreshLoader />,
            finish: <></>
          }}
        >

        <ContentWrapper>
          <MyTabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="inherit"
            variant="fullWidth"
            aria-label="tabs"
          >
            <Tab
              className={classes.tabRoot}
              label="월간 TOP 10"
              {...a11yProps(0)}
            />
            <Tab className={classes.tabRoot} label="전체" {...a11yProps(1)} />
          </MyTabs>

          <SwipeableViews
            style={{ flex: 1, width: "100%", maxWidth: "924px" }}
            axis={"x"}
            index={value}
            onChangeIndex={handleChangeIndex}
            disabled={true}
          >
            <MyTabPanel value={value} index={0}>
              {isMonthlyHeader && (
                <Header>
                  <HeaderColumn>
                    <ColumnWrap>
                      <ColumnRow>
                        <HeaderOrder>2</HeaderOrder>
                      </ColumnRow>
                      <ColumnRow>
                        <HeaderAvatar>
                          <Link to={top3Monthly[1].team.id}>
                            <Avatar url={top3Monthly[1].team.logo} size={"lmd"} />
                          </Link>
                        </HeaderAvatar>
                      </ColumnRow>
                      <ColumnRow>
                        <BoldText text={top3Monthly[1].team.teamName} />
                      </ColumnRow>
                      <ColumnRow>{`Lv.${top3Monthly[1].team.level}`}</ColumnRow>
                      <HeaderTeamInfo>{top3Monthly[1].win}승 {top3Monthly[1].lose}패</HeaderTeamInfo>
                    </ColumnWrap>
                  </HeaderColumn>
                  <HeaderColumn>
                    <ColumnWrap>
                      <BigColumnRow>
                        <HeaderOrder>
                          <CrownImg src="https://d1jozj1mdbjtd9.cloudfront.net/crown.png" />
                        </HeaderOrder>
                      </BigColumnRow>
                      <ColumnRow>
                        <HeaderBigAvatar>
                          <Link to={top3Monthly[0].team.id}>
                            <Avatar url={top3Monthly[0].team.logo} size={"lg"} />
                          </Link>
                        </HeaderBigAvatar>
                      </ColumnRow>
                      <ColumnRow>
                        <BoldText text={top3Monthly[0].team.teamName} />
                      </ColumnRow>
                      <ColumnRow>{`Lv.${top3Monthly[0].team.level}`}</ColumnRow>
                      <HeaderTeamInfo>{top3Monthly[0].win}승 {top3Monthly[0].lose}패</HeaderTeamInfo>
                    </ColumnWrap>
                  </HeaderColumn>
                  <HeaderColumn>
                    <ColumnWrap>
                      <ColumnRow>
                        <HeaderOrder>3</HeaderOrder>
                      </ColumnRow>
                      <ColumnRow>
                        <HeaderAvatar>
                          <Link to={top3Monthly[2].team.id}>
                            <Avatar url={top3Monthly[2].team.logo} size={"lmd"} />
                          </Link>
                        </HeaderAvatar>
                      </ColumnRow>
                      <ColumnRow>
                        <BoldText text={top3Monthly[2].team.teamName} />
                      </ColumnRow>
                      <ColumnRow>{`Lv.${top3Monthly[2].team.level}`}</ColumnRow>
                      <HeaderTeamInfo>  {top3Monthly[2].win}승 {top3Monthly[2].lose}패</HeaderTeamInfo>
                    </ColumnWrap>
                  </HeaderColumn>
                </Header>
              )}

              <Grid item xs={12} md={12}>
                <List className={classes.bottomList} dense>
                  {monthRank &&
                    monthRank.map((rank, index) => (
                      <Link key={rank.id} to={rank.team.id}>
                        <ListItem >
                          <TeamOrder>
                            {rank.win + rank.draw + rank.lose === 0
                              ? "-"
                              : index + top3Monthly.length + 1}
                          </TeamOrder>
                          <ListItemAvatar>
                            <Avatar size={"sm"} url={rank.team.logo} />
                          </ListItemAvatar>
                          <ListItemText
                            primary={rank.team.teamName}
                            secondary={`Lv.${rank.team.level}`}
                          />

                          <ListItemSecondaryAction>
                            {rank.win}승 {rank.lose}패
                          </ListItemSecondaryAction>
                        </ListItem>
                      </Link>
                    ))}
                </List>
              </Grid>
            </MyTabPanel>

            {/* 전체 순위 */}
            <MyTabPanel value={value} index={1}>
              {isTotalHeader && (
                <Header>
                  <HeaderColumn>
                    <ColumnWrap>
                      <ColumnRow>
                        <HeaderOrder>2</HeaderOrder>
                      </ColumnRow>
                      <ColumnRow>
                        <HeaderAvatar>
                          <Link to={top3Total[1].team.id}>
                            <Avatar url={top3Total[1].team.logo} size={"lmd"} />
                          </Link>
                        </HeaderAvatar>
                      </ColumnRow>
                      <ColumnRow>
                        <BoldText text={top3Total[1].team.teamName} />
                      </ColumnRow>
                      <ColumnRow>{`Lv.${top3Total[1].team.level}`}</ColumnRow>
                      <HeaderTeamInfo>{top3Total[1].win}승 {top3Total[1].lose}패</HeaderTeamInfo>
                    </ColumnWrap>
                  </HeaderColumn>
                  <HeaderColumn>
                    <ColumnWrap>
                      <BigColumnRow>
                        <HeaderOrder>
                          <CrownImg src="https://d1jozj1mdbjtd9.cloudfront.net/crown.png" />
                        </HeaderOrder>
                      </BigColumnRow>
                      <ColumnRow>
                        <HeaderBigAvatar>
                          <Link to={top3Total[0].team.id}>
                            <Avatar url={top3Total[0].team.logo} size={"lg"} />
                          </Link>
                        </HeaderBigAvatar>
                      </ColumnRow>
                      <ColumnRow>
                        <BoldText text={top3Total[0].team.teamName} />
                      </ColumnRow>
                      <ColumnRow>{`Lv.${top3Total[0].team.level}`}</ColumnRow>
                      <HeaderTeamInfo>{top3Total[0].win}승 {top3Total[0].lose}패</HeaderTeamInfo>
                    </ColumnWrap>
                  </HeaderColumn>
                  <HeaderColumn>
                    <ColumnWrap>
                      <ColumnRow>
                        <HeaderOrder>3</HeaderOrder>
                      </ColumnRow>
                      <ColumnRow>
                        <HeaderAvatar>
                          <Link to={top3Total[2].team.id}>
                            <Avatar url={top3Total[2].team.logo} size={"lmd"} />
                          </Link>

                        </HeaderAvatar>
                      </ColumnRow>
                      <ColumnRow>
                        <BoldText text={top3Total[2].team.teamName} />
                      </ColumnRow>
                      <ColumnRow>{`Lv.${top3Total[2].team.level}`}</ColumnRow>
                      <HeaderTeamInfo>{top3Total[2].win}승 {top3Total[2].lose}패</HeaderTeamInfo>
                    </ColumnWrap>
                  </HeaderColumn>
                </Header>
              )}

              <Grid item xs={12} md={12}>
                <List className={classes.bottomList} dense>
                  {totalRank &&
                    totalRank.map((rank, index) => (
                      <Link key={rank.id} to={rank.team.id}>
                        <ListItem key={rank.id}>
                          <TeamOrder>
                            {rank.win + rank.draw + rank.lose === 0
                              ? "-"
                              : index + top3Total.length + 1}
                          </TeamOrder>
                          <ListItemAvatar>
                            <Avatar size={"sm"} url={rank.team.logo} />
                          </ListItemAvatar>
                          <ListItemText
                            primary={rank.team.teamName}
                            secondary={`Lv.${rank.team.level}`}
                          />

                          <ListItemSecondaryAction>
                            {rank.win}승 {rank.lose}패
                          </ListItemSecondaryAction>
                        </ListItem>
                      </Link>
                    ))}
                </List>
              </Grid>
            </MyTabPanel>
          </SwipeableViews>
        </ContentWrapper>
        </PullToRefresh>
      </Wrapper>
    );
  }
  return null;
};
