import { gql } from "apollo-boost";

export const SEE_HISTORYS = gql`
  query seeRoomHistorys($first: Int, $skip: Int) {
    seeRoomHistorys(first:$first, skip:$skip) {
      id
      gameTitle
      host {
        id
        username
      }
      place {
        id
        placeName
      }
      price
      purpose
      isRequest
      userCount
      playerCnt
      gameStatus
      gameDate
      startTime
      endTime
    }
  }
`;



