import React, { useState, useEffect } from "react";
import styled from "styled-components";

import { Helmet } from "rl-react-helmet";

import Loader from "../../Components/Loader";
import Avatar from "../../Components/Avatar";
import RoundCheckBox from "../../Components/RoundCheckBox";
import { Close } from "../../Components/Icons";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Slide from "@material-ui/core/Slide";
import SwipeableViews from "react-swipeable-views";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import IconButton from "@material-ui/core/IconButton";
import { useTheme, withStyles } from "@material-ui/core/styles";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import { Dialog } from "@material-ui/core";
import MatchProfileContainer from "../MatchProfile";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import moment from "moment";
import "moment/locale/ko";
import PullToRefresh from "rmc-pull-to-refresh";
import RefreshLoader from "../../Components/RefreshLoader";
import DedicateLoader from "../../Components/DedicateLoader";
import Client from "../../Apollo/Client";

const Wrapper = styled.div`
  min-height: calc(100vh - 76px);
  background-color:#fff;
`;

const ContentWrapper = styled.div`
  /* padding-top: 134px; */
  margin: auto 10px;
  background-color: ${props => props.bgColor};
  /* min-height: 100vh; */
  min-height: calc(100vh - 124px);
  color: #000;
  max-width: 600px;
  margin: 0 auto;
`;

const LoadingWrapper = styled(ContentWrapper)`
  /* padding-top: 134px; */
  min-height: 100vh;
`;


const TitleWrap = styled.div`
  flex: 1 1 auto;
  min-width: 0;
  margin-top: 6px;
  margin-bottom: 6px;
`;

const TeamName = styled.div`
  font-size: 0.95rem;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  /* padding-bottom: 7px; */
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  & > span:first-child {
    font-weight: 600;
  }
  & > span:last-child {
    font-weight: 400;
    font-size:13px;
  }
`;

const HeaderTitle = styled.span`
  color: white;
  font-size: 24px;
  flex: 1;
  margin-left: -26px;
  text-align: center;
  font-weight: 600;
`;

const Title = styled.p`
  color: rgba(0, 0, 0, 0.54);
  font-size: 0.875rem;
  font-family: "NanumSqure";
  font-weight: 400;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: hidden;
  margin-bottom: 8px;
  max-width: 250px;
`;

const SmallDateInfo = styled.div`
  color: #000;
  font-size: 11px;
`;

const GameInfo = styled.p`
  font-family: "NanumSqure";
  font-weight: 400;
  font-size: 14px;
  line-height: 1.1em;
  &:nth-child(3) {
    padding-top: 7px;
  }
`;

const StatusSpan = styled.span`
  font-size: 14px;
  padding-right: 10px;
  font-weight: 500;
  position: absolute;
  left: -20px;
  top: 14px;
`;

const SendStatus = styled.p`
  display: flex;
  justify-content: flex-end;
  position: absolute;
  right:0px;
  top:13px;
  & > span {
    font-size: 14px;
    padding-right: 5px;
    font-weight: 500;
  }
`;

const EmptySearchResult = styled.div`
  margin: 0px 15px;
  padding: 15px 0px;
  color: #969696;
  font-size: 14px;
  border-bottom: 1px solid #d5d5d5;
`;


const MyTabs = styled(Tabs)`
  color: #000;
  padding: 15px 25px;
  max-width: 500px;
  width: 100%;
  font-weight: 600;
  margin: 0 auto;
  & button:not(.Mui-selected) {
    z-index: 0;
    color: #fff;
    font-weight: 400;
  }
  .MuiTabs-flexContainer {
    background-color: #65a6ff;
    border-radius: 20px;
  }
  .MuiTabs-indicator {
    top: 0px;
    height: 4px;
    border-radius: 20px;
    height: 38px;
    background-color: #fff;
  }
`;

const MyTabPanel = styled(TabPanel)`
  .MuiListItemText-secondary {
    color: #000;
  }
  & {
    /* padding-bottom: 47px; */
  }
`;

function TabPanel(props) {
  const { children, value, index, classes, ...other } = props;
  if (props.className.indexOf("mapWrapper") !== -1) {
    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        <Box className={classes.boxRoot} p={0}>
          {children}
        </Box>
      </Typography>
    );
  } else {
    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        // {...other}
        className={classes.panelRoot}
      >
        <Box className={classes.boxRoot} p={0}>
          {children}
        </Box>
      </Typography>
    );
  }
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`
  };
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const styles = {
  appBar: {
    position: "fixed",
    backgroundColor: "#227fff"
  },
  mainToolBar: {
    display: "flex"
  },
  tabToolBar: {
    maxWidth: 500,
    width: "100%",
    fontWeight: 600,
    margin: "0 auto"
  },
  tabRoot: {
    fontSize: 15,
    fontFamily: "NanumSqure",
    zIndex: 1,
    minHeight: "38px"
  },
  root: {
    width: "100%",
    "& .MuiListItem-root": {
      borderBottom: "1px solid #999",
      paddingLeft: 0,
      paddingRight: 0
    }
  },
  titleWrapper: {
    paddingRight: "60px"
  },
  inline: {
    display: "inline"
  },
  title: {
    marginLeft: 16,
    flex: 1
  },
  dialog: {
    color: "#fff",
    "& .MuiPaper-root": {
      backgroundColor: "#3A4149"
    }
  },
  listContainer: {
    lineHeight: "2.5em"
  },
  listIcon: {
    minWidth: 40
  },
  listText: {
    flex: "none",
    marginRight: "20px"
  },
  badge: {
    "& > .MuiBadge-badge": {
      height: "23px",
      padding: "0 7px",
      fontSize: "1rem",
      borderRadius: "5px"
    }
  },
  status: {
    marginTop:6
  },
  userWrap: {
    padding: "0px 15px",
    maxWidth: "600px",
    // overflowY: "auto",
    // maxHeight: "100vh"
  }
};

const GameManagePresenter = ({
  loading,
  data,
  teamId,
  dataSends,
  loadingSends,
  receives,
  setReceives,
  sends,
  setSends,
  history,
  location,
  onClickClose,
  onScrollReceives,
  onScrollSends,
  newMatchData,
  classes,
  onRefresh,
  refreshing
}) => {

  if (loading || loadingSends) {
    //const classes = useStyles();
    return (
      <Wrapper>
        {/* <BaseHeader text={"도전장 관리"} isBack={false} isClose={true} /> */}
        <AppBar className={classes.appBar}>
          <Toolbar className={classes.mainToolBar}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="close"
              onClick={onClickClose}
            >
              <Close />
            </IconButton>
            <HeaderTitle>{"도전장 관리"}</HeaderTitle>
          </Toolbar>
          <Toolbar className={classes.tabToolBar}>
            <MyTabs
              value={0}
              onChange={() => {}}
              indicatorColor="primary"
              textColor="inherit"
              variant="fullWidth"
              aria-label="tabs"
            >
              <Tab
                className={classes.tabRoot}
                label="수신 목록"
                {...a11yProps(0)}
              />
              <Tab
                className={classes.tabRoot}
                label="신청 목록"
                {...a11yProps(1)}
              />
            </MyTabs>
          </Toolbar>
        </AppBar>

        <Helmet>
          <title>도전장 관리 | WePlay</title>
        </Helmet>
        <Loader />
        <LoadingWrapper bgColor={"#fff"}></LoadingWrapper>
      </Wrapper>
    );
  } else if (
    !loading &&
    !loadingSends &&
    data &&
    data.seeMatchsReceiver &&
    dataSends &&
    dataSends.seeMatchsforSender
  ) {
    //console.log(location.hash);
    const paramString = new URLSearchParams(history.location.search);
  
    //const classes = useStyles();
    const theme = useTheme();
    const [open, setOpen] = useState(false);
    const [matchInfo, setMatchInfo] = useState();
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
    const handleChangeIndex = index => {
      setValue(index);
    };

    const handleOpen = (match, type) => () => {
      history.push(`/team/${teamId}/match?view=${match.id}&type=${type}`);
      setMatchInfo(match);
      setOpen(true);
    };
    //console.log(history.location);

    const handleClose = () => {
      setOpen(false);
      if(history.location.search) {
        //history.goBack();
        history.replace(`/team/${teamId}/match`);
      }
    };

    const handleAgree = id => {
      const data = [...receives];
      data[receives.findIndex(i => i.id === id)].game.gameStatus = "MATCH";
      data.forEach(i => {
        if(i.id === id) {
          i.status = "ACCEPT";
          i.game.gameStatus = "MATCH";
        } else {
          i.status = "DECLINE";
        }
      })
      setReceives(data);
    };
    const handleDecline = id => {
      const data = [...receives];
      data.splice(
        receives.findIndex(i => i.id === id),
        1
      );

      Client.writeData({
        id:`Match:${id}`,
        data: {
          status:"DECLINE"
        }
      });
      setReceives(data);
    };

    const handlePopState = () => {
      if(!window.location.hash.includes("?view")) {
        handleClose();
      }
    }

    useEffect(() => {
      setReceives(data.seeMatchsReceiver);
      setSends(dataSends.seeMatchsforSender);

      if(paramString.get('view')) {
        if(paramString.get('type') === 'receiver') { 
          setMatchInfo(data.seeMatchsReceiver.find(i => i.id === paramString.get('view')));
          setValue(0);
        } else if(paramString.get('type') === 'sender') {
          setMatchInfo(dataSends.seeMatchsforSender.find(i => i.id === paramString.get('view')));
          setValue(1);
        }
        setOpen(true);
      }
    }, [data, dataSends]);

    useEffect(() => {
      window.addEventListener('hashchange', handlePopState, false);
      
      return () => {
        window.removeEventListener('hashchange', handlePopState, false);
      };
    }, [])


    useEffect(() => {
      //console.log("newMatchData ..>" , newMatchData);
      if (newMatchData !== undefined) {
        const { newMatch } = newMatchData;

        setReceives(prev => [newMatch, ...prev]);
      }
    }, [newMatchData]);


   

    return (
      <Wrapper>
        {/* <BaseHeader text={"경기 관리"} isBack={true} isClose={false} /> */}
        <AppBar className={classes.appBar}>
          <Toolbar className={classes.mainToolBar}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="close"
              onClick={onClickClose}
            >
              <Close />
            </IconButton>
            <HeaderTitle>{"도전장 관리"}</HeaderTitle>
          </Toolbar>
          <Toolbar className={classes.tabToolBar}>
            <MyTabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="inherit"
              variant="fullWidth"
              aria-label="tabs"
            >
              <Tab
                className={classes.tabRoot}
                label="수신 목록"
                {...a11yProps(0)}
              />
              <Tab
                className={classes.tabRoot}
                label="신청 목록"
                {...a11yProps(1)}
              />
            </MyTabs>
          </Toolbar>
        </AppBar>

        <Helmet>
          <title>도전장 관리 | WePlay</title>
        </Helmet>

        <PullToRefresh
          style={{ overflow: "auto", paddingTop:124, minHeight:"100vh", maxHeight:"100vh" }}
          direction="down"
          distanceToRefresh={50}
          damping={100}
          onRefresh={onRefresh(value)}
          refreshing={refreshing}
          onScroll={e => {
            value===0?onScrollReceives(e):onScrollSends(e);
          }}
          indicator={{
            activate: <DedicateLoader />,
            deactivate: <></>,
            release: <RefreshLoader />,
            finish: <></>
          }}
        >

        <ContentWrapper bgColor={"#fff"}>
          <SwipeableViews
            className={classes.SwipeView}
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={value}
            onChangeIndex={handleChangeIndex}
            disabled={true}
          >
            <MyTabPanel value={value} index={0} classes={classes}>
              <Grid 
                className={classes.userWrap} 
                item xs={12} md={12} 
                // onScroll={e => onScrollReceives(e)} 
              >
                {/* <Input placeholder={"검색하세요"} /> */}
                <List className={classes.root}>

                  {receives && receives.length === 0 && (
                    <EmptySearchResult>수신한 도전장이 없습니다.</EmptySearchResult>
                  )}

                  {receives && receives.length > 0 && receives.map(match => (
                    <ListItem
                      key={match.id}
                      onClick={handleOpen(match, 'receiver')}
                      button
                      className={classes.titleWrapper}
                    >
                      <ListItemAvatar>
                        <Avatar size={"sm"} url={match.sender.logo} />
                      </ListItemAvatar>
                      <TitleWrap>
                        <SmallDateInfo>
                          {moment(match.createdAt).format("YYYY.MM.DD A hh:mm")}
                        </SmallDateInfo>
                        <TeamName>
                          <span>{`${match.sender.teamName}팀 `}</span>
                          
                          {match.senderUser && <span>{`/ 신청자:${match.senderUser.username}`}</span>}
                        </TeamName>
                        {/* <Title>{match.title}</Title> */}
                        <GameInfo>
                          {moment(match.game.gameDate).format("YYYY.MM.DD")} |{" "}
                          {`${match.game.startTime} ~ ${match.game.endTime}`}
                        </GameInfo>
                        <GameInfo>
                          {match.game.place.placeName !== ""
                            ? `${match.game.place.shortAddress}(${match.game.place.placeName})`
                            : match.game.place.shortAddress}
                        </GameInfo>

                        {match.status === "REQUEST" && (
                            <SendStatus>
                              <span>대기</span>
                              <RoundCheckBox checked={false} disabled={true} />
                            </SendStatus>
                          )}
                          {match.status === "ACCEPT" && (
                            <SendStatus>
                              <span>매칭</span>
                              <RoundCheckBox checked={true} disabled={true} />
                            </SendStatus>
                          )}
                          {match.status === "DECLINE" && (
                            <>
                              {/* <StatusSpan>거부</StatusSpan>
                              <RoundCheckBox checked={true} disabled={true} /> */}

                              <SendStatus>
                                <span>거부</span>
                                <RoundCheckBox
                                    type="x"
                                    checked={true}
                                    disabled={true}
                                  />
                              </SendStatus>
                            </>
                          )}
                      </TitleWrap>
                      {/* <ListItemSecondaryAction>
                        <IconButton
                          edge="end"
                          color="inherit"
                          className={classes.status}
                          aria-label="detail"
                        >
                          {match.status === "REQUEST" && (
                            <>
                              <StatusSpan>대기</StatusSpan>
                              <RoundCheckBox checked={false} disabled={true} />
                            </>
                          )}
                          {match.status === "ACCEPT" && (
                            <>
                              <StatusSpan>매칭</StatusSpan>
                              <RoundCheckBox checked={true} disabled={true} />
                            </>
                          )}
                          {match.status === "DECLINE" && (
                            <>
                              <StatusSpan>거부</StatusSpan>
                              <RoundCheckBox checked={true} disabled={true} />

                              <SendStatus>
                                <span>거부</span>
                                <RoundCheckBox
                                    type="x"
                                    checked={true}
                                    disabled={true}
                                  />
                              </SendStatus>
                            </>
                          )}
                        </IconButton>
                      </ListItemSecondaryAction> */}
                    </ListItem>
                  ))}
                </List>
              </Grid>
            </MyTabPanel>

            <MyTabPanel value={value} index={1} classes={classes}>
              <Grid className={classes.userWrap} item xs={12} md={12} 
              // onScroll={e => onScrollSends(e)} 
              >
                {/* <Input placeholder={"검색하세요"}/> */}
                <List className={classes.root}>
                  {sends && sends.length === 0 && (
                    <EmptySearchResult>매칭 신청한 도전장이 없습니다.</EmptySearchResult>
                  )}
                  {sends && sends.length > 0 && 
                    sends.map(match => (
                      <ListItem
                        onClick={match.status === "DECLINE"? handleOpen(match,'sender') : null}
                        button={match.status === "DECLINE"}
                        key={match.id}
                      >
                        <ListItemAvatar>
                          <Avatar size={"sm"} url={match.receiver.logo} />
                        </ListItemAvatar>
                        <TitleWrap>
                          <SmallDateInfo>
                            {moment(match.createdAt).format(
                              "YYYY.MM.DD A hh:mm"
                            )}
                          </SmallDateInfo>
                          {/* <TeamName>
                            <span>{`${match.receiver.teamName}팀`}</span>
                            에게 도전을 신청했습니다.
                          </TeamName> */}
                          <TeamName>
                            <span>{`${match.receiver.teamName}팀 `}</span>
                            
                            {match.senderUser && <span>{`/ 신청자:${match.senderUser.username}`}</span>}
                          </TeamName>
                          {/* <Title>{match.title}</Title> */}
                          <GameInfo>
                            {moment(match.game.gameDate).format("YYYY.MM.DD")} |{" "}
                            {`${match.game.startTime} ~ ${match.game.endTime}`}
                          </GameInfo>
                          <GameInfo>
                            {match.game.place.placeName !== ""
                              ? `${match.game.place.shortAddress}(${match.game.place.placeName})`
                              : match.game.place.shortAddress}
                          </GameInfo>

                          {match.status === "REQUEST" && (
                            <SendStatus>
                              <span>답변 대기</span>
                              <RoundCheckBox checked={false} disabled={true} />
                            </SendStatus>
                          )}
                          {match.status === "ACCEPT" && (
                            <SendStatus>
                              <span>수락</span>
                              <RoundCheckBox checked={true} disabled={true} />
                            </SendStatus>
                          )}
                          {match.status === "DECLINE" && (
                            <SendStatus>
                              <span>거부</span>
                              <RoundCheckBox
                                type="x"
                                checked={true}
                                disabled={true}
                              />
                            </SendStatus>
                          )}
                        </TitleWrap>
                      </ListItem>
                    ))}
                </List>
              </Grid>
            </MyTabPanel>
          </SwipeableViews>
        </ContentWrapper>
        </PullToRefresh>
        <Dialog
          className={classes.dialog}
          fullScreen
          open={open}
          onClose={handleClose}
          TransitionComponent={Transition}
        >
          <MatchProfileContainer
            handleAgree={handleAgree}
            handleDecline={handleDecline}
            matchInfo={matchInfo}
            teamId={teamId}
            onBack={handleClose}
          />
        </Dialog>
      </Wrapper>
    );
  }

  return null;
};

export default withStyles(styles)(GameManagePresenter);
