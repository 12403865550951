import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { SEE_RECEIVES, SEE_SENDS, NEW_MATCH } from "./GameManageQueries";
import GameManagePresenter from "./GameManagePresenter";
import { useQuery, useSubscription } from "react-apollo-hooks";
//import { toast } from "../../Components/Popup";
import useInput from "../../Hooks/useInput";

const GameManageContainer = ({ match: { params }, history, location }) => {
  const teamId = params.team;
  const [first] = useState(20);
  const [skip] = useState(0);
  const [loadMore, setLoadMore] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const [canLoad, setCanLoad] = useState({
    receive:true,
    send:true
  })

  const { data, loading, fetchMore, refetch } = useQuery(SEE_RECEIVES, {
    variables: {
      id: teamId,
      skip: skip,
      first: first
    }
  });

  const {
    data: dataSends,
    loading: loadingSends,
    fetchMore: fetchMoreSends,
    refetch:refetchSends
  } = useQuery(SEE_SENDS, {
    variables: {
      id: teamId,
      skip: skip,
      first: first
    }
  });

  const { data: newMatchData } = useSubscription(NEW_MATCH, {
    variables: {
      id: teamId
    }
  });

  const onRefresh = tabValue => async () => {

    try {
      setRefreshing(true);
      if(tabValue === 0) {
        await refetch();
      } else if(tabValue === 1) {
        await refetchSends();
      };
    } catch(e) {
      console.log(e);
    } finally {
      setRefreshing(false);
    }
  };


  const searchReceives = useInput("");
  const searchSends = useInput("");

  const [receives, setReceives] = useState([]);
  const [sends, setSends] = useState([]);

  const onScrollReceives = ({ currentTarget }) => {
    if (
      canLoad.receive && 
      !loadMore && 
      currentTarget.scrollTop + currentTarget.clientHeight >=
      currentTarget.scrollHeight -50
    ) {
      setLoadMore(true);
      fetchMore({
        variables: {
          skip: data.seeMatchsReceiver.length
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          setLoadMore(false);

          console.log(fetchMoreResult);
          if(fetchMoreResult.seeMatchsReceiver.length === 0)
            setCanLoad({...canLoad, receive:false})

          if (!fetchMoreResult) return prev;

          return Object.assign({}, prev, {
            seeMatchsReceiver: [
              ...prev.seeMatchsReceiver,
              ...fetchMoreResult.seeMatchsReceiver
            ]
          });
        }
      });
    }
  };

  const onScrollSends = ({ currentTarget }) => {
    if (
      canLoad.send && 
      !loadMore && 
      currentTarget.scrollTop + currentTarget.clientHeight >=
      currentTarget.scrollHeight - 50
    ) {
      setLoadMore(true);
      fetchMoreSends({
        variables: {
          skip: dataSends.seeMatchsforSender.length
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          setLoadMore(false);

          console.log(fetchMoreResult);
          //setReceives([...receives, ...fetchMoreResult.seeMatchsforSender]);
          if(fetchMoreResult.seeMatchsforSender.length === 0)
            setCanLoad({...canLoad, send:false})
          if (!fetchMoreResult) return prev;

          return Object.assign({}, prev, {
            seeMatchsforSender: [
              ...prev.seeMatchsforSender,
              ...fetchMoreResult.seeMatchsforSender
            ]
          });
        }
      });
    }
  };

  const onClickClose = () => {
    //history.push(`/team/${teamId}/history`);
    history.goBack();
  };

  return (
    <GameManagePresenter
    onClickClose = {onClickClose}
      loading={loading}
      data={data}
      teamId={teamId}
      dataSends={dataSends}
      loadingSends={loadingSends}
      receives={receives}
      setReceives={setReceives}
      sends={sends}
      setSends={setSends}
      loadMore={loadMore}
      onScrollReceives={onScrollReceives}
      onScrollSends={onScrollSends}
      history={history}
      searchReceives={searchReceives}
      searchSends={searchSends}
      newMatchData={newMatchData}
      location={location}

      onRefresh={onRefresh}
      refreshing={refreshing}
      //onSubmit={onSubmit}
    />
  );
};

export default withRouter(GameManageContainer);
