import { gql } from "apollo-boost";

export const SEE_NOTICE = gql`
  query seeFullNotice($id: String!) {
    seeFullNotice(id: $id) {
      id
      writer {
        id
        username
      }
      title
      text
      createdAt
    }
  }
`;

