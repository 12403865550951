import React from "react";
import reactDom from "react-dom";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import CloseIcon from "@material-ui/icons/Close";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import { PopupSucess, PopupWarning, PopupError } from "./Icons";
import styled from "styled-components";

const useStyles = makeStyles(theme => ({
  root: {
    margin: 0,
    padding: "10px 16px",
  },
  closeButton: {
    position: 'absolute',
    right: 0,
    top: 0,
    color: "#000",
  },
  dialog: {
    "& .MuiPaper-root": {
      width:210,
      minHeight:150,
      margin:0
    },
    "& .MuiDialogContent-root": {
      display: "flex",
      flexDirection:"column",
      alignItems: "center",
      justifyContent: "center",
      padding:"25px 12px",
      "& > span": {
        marginTop: 20,
        textAlign: "center",
        lineHeight: "1.5em",
        color:"#000",
        fontWeight:500,
        fontSize:15,
        wordBreak:"keep-all"
      },
    }
  }
}));

const ConfirmButton = styled.button`
  background-color: #ffbe21;
  border: 1px solid #ffbe21;
  cursor: "pointer";
  font-size: 12px;
  padding: 8px 0px;
  text-align: center;
  font-weight: 500;
  font-family: "NanumSqure";
  border-radius: 7px;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  z-index:997;
  margin-top: 20px;
  /* width: 95%; */
  width: calc(100% - 20px);
`;

const DialogTitle = (props => {
  const classes = useStyles();
  const { children, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <IconButton
        aria-label="close"
        className={classes.closeButton}
        onClick={onClose}
      >
        <CloseIcon />
      </IconButton>
    </MuiDialogTitle>
  );
});

const PopupOn = (text, options) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);

  const handleClose = () => {
    setOpen(false);
  };
  
  
  
  if(options === "success") {
    return (
      <Dialog
        open={open}
        className={classes.dialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
            <PopupSucess size={60} fill={"#2196f3"} /> 
            <span>{text}</span>
            <ConfirmButton onClick={handleClose}>확인</ConfirmButton>
        </DialogContent>
      </Dialog>
    );
  } else if(options === "warning") {
    return (
      <Dialog
        open={open}
        className={classes.dialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {/* <DialogTitle id="alert-dialog-title" onClose={handleClose}></DialogTitle> */}
        <DialogContent>
            <PopupWarning size={60} fill={"#ff9800"} />
            <span>{text}</span>
            <ConfirmButton onClick={handleClose}>확인</ConfirmButton>
        </DialogContent>
      </Dialog>
    );
  } else if(options === "info") {
    return (
      <Dialog
        open={open}
        className={classes.dialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
            <PopupError size={60} fill={"#4caf50"} />
            <span>{text}</span>
            <ConfirmButton onClick={handleClose}>확인</ConfirmButton>
        </DialogContent>
      </Dialog>
    );
  } else if(options === "error") {
    return (
      <Dialog
        open={open}
        className={classes.dialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
            <PopupError size={60} fill={"#f44336"} />
            <span>{text}</span>
            <ConfirmButton onClick={handleClose}>확인</ConfirmButton>
        </DialogContent>
      </Dialog>
    );
  }

  
};

function dispatchToast(content, options) {
  var canUseDom = !!(
    typeof window !== "undefined" &&
    window.document &&
    window.document.createElement
  );

  if (canUseDom) {
    const containerDomNode = document.createElement("div");
    //document.body.appendChild(containerDomNode);
    reactDom.render(
      React.createElement(() => PopupOn(content, options)),
      containerDomNode
    );
  }

  return options;
}

// export const toast = function toast(content, options) {
//   return dispatchToast(content);
// };

export const toast = {
  success:content => dispatchToast(content,"success"),
  warning:content => dispatchToast(content,"warning"),
  info:content => dispatchToast(content,"info"),
  error:content => dispatchToast(content,"error")
} 
