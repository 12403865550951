import { gql } from "apollo-boost";

export const GET_GAME = gql`
  query seeFullGame($id: String!) {
    seeFullGame(id: $id) {
      id
      gameDate
      startTime
      endTime
      gameStatus
      didVote
      place {
        id
        placeName
        address
        location {
          id
          latitude
          longitude
        }
      }
      awayTeam {
        id
        teamName
        level
        isMine
        logo
        host {
          id
          avatar
          username
          level
          basket {
            id
            level
            backNo
            position
          }
        }
        managers {
          id
          avatar
          username
          level
          basket {
            id
            level
            backNo
            position
          }
        }
        users {
          id
          avatar
          username
          level
          basket {
            id
            level
            backNo
            position
          }
        }
      }
      homeTeam {
        id
        level
        host {
          id
          avatar
          username
          level
          basket {
            id
            level
            backNo
            position
          }
        }
        managers {
          id
          avatar
          username
          level
          basket {
            id
            level
            backNo
            position
          }
        }
        users {
          id
          avatar
          username
          level
          basket {
            id
            level
            backNo
            position
          }
        }
        teamName
        isMine
        logo
      }
    }
    me {
      id
      avatar
      username
      level
    }
  }
`;

export const SEE_VOTES = gql`
  query seeVotes($id: String!, $teamId: String!) {
    seeVotes(gameId: $id, teamId: $teamId) {
      id
      user {
        id
        avatar
        username
        level
        basket {
          id
          level
        }
        itsMe
      }
      answer
    }
  }
`;

export const UPSERT_VOTE = gql`
  mutation upsertVote($gameId: String!, $teamId: String!, $answer: String!) {
    upsertVote(gameId: $gameId, teamId: $teamId, answer: $answer) {
      id
      user {
        id
        avatar
        username
        level
      }
      answer
    }
  }
`;

export const SEARCH_USER = gql`
  query searchUserForTeam($term: String!, $teamId: String!) {
    searchUserForTeam(term: $term, teamId:$teamId) {
      id
      avatar
      username
      nickname
      basket {
        id
        level
        backNo
      }
      itsMe
    }
  }
`;

export const INVITE_USER = gql`
  mutation inviteUser($id: String!, $userId: String!) {
    inviteUser(id: $id, userId: $userId)
  }
`;

export const UPDATE_GAME = gql`
  subscription updateGame($id: String!) {
    updateGame(id: $id) {
      id
      gameDate
      startTime
      endTime
      gameStatus
    }
  }
`;

export const UPDATE_VOTE = gql`
  subscription updateVote($id: String!) {
    updateVote(gameId: $id) {
      id
      user {
        id
        avatar
        username
        level
        basket {
          id
          level
        }
      }
      answer
    }
  }
`;
