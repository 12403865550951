import React, { useState, useEffect, useRef } from "react";
import { withRouter } from "react-router-dom";
import { GET_TEAM, EDIT_TEAM, SEARCH_PLACE, DELETE_PROFILE } from "./EditTeamProfileQueries";
import EditTeamProfilePresenter from "./EditTeamProfilePresenter";
import { useQuery, useMutation } from "react-apollo-hooks";
import useInput from "../../Hooks/useInput";
import { toast } from "../../Components/Popup";
import axios from "axios";
import loadImage from "blueimp-load-image";
import Client from "../../Apollo/Client";


const EditTeamProfileContainer = ({ match: { params }, history }) => {
  const teamId = params.team;
  const [uploading, setUploading] = useState(false);




  const searchPlace = useInput("");
  const [openSearch, setOpenSearch] = useState(false);
  const [searchingPlace, setSearchingPlace] = useState(false);

  const { data, loading, refetch } = useQuery(GET_TEAM, {
    variables: {
      id: teamId.toString()
    }
  });

  const { loading: loadingPlace, data: searchPlaceData } = useQuery(
    SEARCH_PLACE,
    {
      skip: searchPlace.value === "" && searchPlace.value.length <= 1,
      variables: {
        first: 30,
        skip: 0,
        term: searchPlace.value
      }
    }
  );

  const [logo, setLogo] = useState();
  const [isEditLogo, setIsEditLogo] = useState(false);
  const [profiles, setProfiles] = useState([]);
  const [oldProfiles, setOldProfiles] = useState([]);


  const teamNameInput = useInput("");
  const sport = useInput("1001");
  const contactInput = useInput("", newValue=> /^[0-9-]{1,20}$/g.test(newValue), false, true);;
  const bioInput = useInput("", newValue=> newValue.length < 300);

  const uniformColorInput = useInput("");
  const matchingInfoInput = useInput("");
  const matchingInfoDetailInput = useInput("");
  const matchingPositionInput = useInput("");

  const [place, setPlace] = useState();
  const [placeName, setPlaceName] = useState();
  const [address, setAddress] = useState();
  const [shortAddress, setShortAddress] = useState();
  const [lat, setLat] = useState(0);
  const [lng, setLng] = useState(0);
  
  const [openRemoveProfile, setOpenRemoveProfile] = useState(false);
  const [removeProfile, setRemoveProfile] = useState();

  const editTeamMutation = useMutation(EDIT_TEAM);
  const deleteProfileMutation = useMutation(DELETE_PROFILE);

  const teamNameRef = useRef();
  const contactRef = useRef();
  const bioRef = useRef();
  const uniformRef = useRef();
  const infoRef = useRef();
  const positionRef = useRef();

  const handleSearchOpen = () => {
    setOpenSearch(true);
  };

  const handleSearchClose = () => {
    setOpenSearch(false);
  };

  const handleSelectPlace = place => () => {
    setPlace(place.id);
    setAddress(place.address);
    handleSearchClose();
  };

  // 경기장 검색 값 변할 경우 리렌더링
  useEffect(() => {
    if (searchPlace.value.length > 1) {
      setSearchingPlace(true);
    } else {
      setSearchingPlace(false);
    }

    if (!loadingPlace && searchPlace) {
      setSearchingPlace(false);
    }
  }, [
    searchPlace.value,
    loadingPlace,
    searchPlaceData,
  ]);

  const handleLogoChange = e => {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];
    const fileType = file.type;

    if(file) {
      // reader.onloadend = () => {
      //   file['url'] = reader.result;
      //   setLogo(file);
      //   setIsEditLogo(true);
      // };

      loadImage(file, (img) => {
        img.toBlob(blob => {
          const createdFile = new File([blob], file.name);
          createdFile['url'] = img.toDataURL();
          setLogo(createdFile);
          setIsEditLogo(true);
        }, fileType);
      }, { 
        orientation:true, 
        maxWidth:200,
        maxHeight:200,
        contain:true,
        canvas:true,
        crop:true,
        aspectRatio:1/1,
      });
  
      reader.readAsDataURL(file);
    }
  };

  const handleProfileChange = e => {
    e.preventDefault();

    if(oldProfiles.length + profiles.length >= 5) {
      toast.error("최대 5장까지 가능합니다.");
      return;
    }

    let reader = new FileReader();
    let file = e.target.files[0];
    const fileType = file.type;

    if(file) {
      // reader.onloadend = () => {
      //   file['url'] = reader.result;
      //   const data = [...profiles];
      //   data.push(file);
      //   setProfiles(data);
      //   //setPreviewUrl(reader.result);
      // };
  
      loadImage(file, (img) => {
        setIsEditLogo(true);
        img.toBlob(blob => {
          const createdFile = new File([blob], file.name);
          const data = [...profiles];
          createdFile['url'] = img.toDataURL();
          data.push(createdFile);
          setProfiles(data);
        }, fileType);
      }, { 
        aspectRatio:16/9,
        orientation:true, 
        maxWidth:480,
        maxHeight:270,
        cover:true,
        canvas:true,
        crop:true
      });

      reader.readAsDataURL(file);
    }
  };

  // 드롭존 내에서 Remove file 할 경우 이벤트
  const onRemoveProfile = removeFile => e => {
    e.preventDefault();

    const data = [...profiles];
    data.splice(
      data.findIndex(i => i.name === removeFile.name),
      1
    );
    setProfiles(data);
  };

  // 기존 프로필 사진 삭제 확인 팝업
  const handleRemoveProfileOpen = (id) => () => {
    setOpenRemoveProfile(true);
    setRemoveProfile(id);
  };

  // 기존 프로필 사진 삭제 확인 팝업
  const handleRemoveProfileClose = () => {
    setOpenRemoveProfile(false);
    setRemoveProfile(null);
  };


  const setAddressInfo = (placeName, address, shortAddress, lat, lng) => {
    setLat(parseFloat(lat));
    setLng(parseFloat(lng));
    setPlaceName(placeName);
    setAddress(address);
    setShortAddress(shortAddress);
  };

  const handleSubmit = async e => {
    e.preventDefault();

    // if (teamNameInput.value === "" || contactInput.value === "" || bioInput.value === "") {
    //   toast.error("필수 항목을 입력해주세요.");
    //   return;
    // }

    if (teamNameInput.value === "" ) {
      toast.error("팀 이름을 입력해주세요.");
      teamNameRef.current.focus();
      return;
    }
    if (contactInput.value === "") {
      toast.error("연락처 정보를 입력해주세요.");
      contactRef.current.focus();
      return;
    }
    if (bioInput.value === "") {
      toast.error("팀 소개를 입력해주세요.");
      bioRef.current.focus();
      return;
    }

    
    if (uniformColorInput.value === "") {
      toast.error("유니폼 색상을 입력해주세요.");
      uniformRef.current.focus();
      return;
    }

    if (matchingInfoInput.value === "") {
      toast.error("매칭 요청사항을 입력해주세요.");
      infoRef.current.focus();
      return;
    }

    if (matchingPositionInput.value === "") {
      toast.error("매칭 포지션을 입력해주세요.");
      positionRef.current.focus();
      return;
    }

    try {
      setUploading(true);

      let LogoUrl;
      let ProfileUrls;
      if (isEditLogo && logo) {
        const formData = new FormData();
        formData.append("logo", logo);

        const {
          data: { location }
        } = await axios.post(
          "https://weplay2921.cafe24.com/api/team/upload",
          formData,
          {
            headers: {
              "content-type": "multipart/form-data"
            }
          }
        );
        LogoUrl = location;
      }

      if (profiles && profiles.length > 0) {
        const formData = new FormData();
        profiles.forEach(file => {
          formData.append("profile", file);
        });

        const { data } = await axios.post(
          "https://weplay2921.cafe24.com/api/team/profile/upload",
          formData,
          {
            headers: {
              "content-type": "multipart/form-data"
            }
          }
        );

        ProfileUrls = data;
      }

      console.log({
        id:teamId,
        logo: isEditLogo? LogoUrl: logo,
        profiles: ProfileUrls,
        teamName: teamNameInput.value,
        contact: contactInput.value,
        sportId: sport.value,
        uniformColor:uniformColorInput.value,
        matchingInfo:matchingInfoInput.value + "/" + matchingInfoDetailInput.value,
        matchingPosition: matchingPositionInput.value,
        bio: bioInput.value,
        placeId:place,
        placeName:placeName?placeName:"장소이름 없음",
        address,
        shortAddress,
        lat,
        lng,
        action:"EDIT"
      });
      const {
        data: { editTeam }
      } = await editTeamMutation({
        variables: {
          id:teamId,
          logo:  isEditLogo? LogoUrl: logo,
          profiles: ProfileUrls,
          uniformColor:uniformColorInput.value,
          matchingInfo:matchingInfoInput.value + "/" + matchingInfoDetailInput.value,
          matchingPosition: matchingPositionInput.value,
          teamName: teamNameInput.value,
          contact: contactInput.value,
          sportId: sport.value,
          bio: bioInput.value,
          placeId:place,
          placeName:placeName?placeName:"장소이름 없음",
          address,
          shortAddress,
          lat,
          lng,
          action:"EDIT"
        }
      });

      console.log();
      if (editTeam.id) {

        console.log(editTeam);
        Client.writeData({
          id: `Team:${editTeam.id}`,
          data: {
            teamName:editTeam.teamName,
            contact:editTeam.contact,
            uniformColor:editTeam.uniformColor,
            profile:editTeam.profile,
            logo:editTeam.logo,
            matchingInfo:editTeam.matchingInfo,
            matchingPosition:editTeam.matchingPosition,
            place:editTeam.place,
            location:editTeam.location
          }
        });

        setLogo(editTeam.logo);
        //setOldProfiles(editTeam.profile);
        //refetch();
        setUploading(false);
        toast.success("팀 정보가 수정되었습니다.");
        setTimeout(() => {
          history.push(`/team/${editTeam.id}`);
        }, 2000);
      }

    } catch (e) {
      toast.error("실패했습니다. 다시 시도해주세요.");
    } finally {
      setUploading(false);
    }
  };

  const onClickClose = () => {
    history.goBack();
  };

  // 기존 프로필 삭제 이벤트
  const handleProfileDelete = async () => {
    await deleteProfileMutation({
      variables: { id: removeProfile }
    });
    
    const data = oldProfiles;
    data.splice(data.findIndex(i => i.id === removeProfile), 1);
    setOldProfiles(data);
    handleRemoveProfileClose();
  }

  // 경기장 변경 시 변경값 설정
  const onChangePlace = (placeId, address) => {
    setPlace(placeId);
    setAddress(address);
    handleSearchClose();
  }

  return (
    <EditTeamProfilePresenter
      data={data}
      loading={loading}
      teamNameInput={teamNameInput}
      contactInput={contactInput}
      bioInput={bioInput}
      uniformColorInput={uniformColorInput}
      matchingInfoInput={matchingInfoInput}
      matchingInfoDetailInput={matchingInfoDetailInput}
      matchingPositionInput={matchingPositionInput}
      sport={sport}
      uploading={uploading}
      oldProfiles={oldProfiles}
      setOldProfiles={setOldProfiles}
      setLogo={setLogo}

      handleRemoveProfileOpen={handleRemoveProfileOpen}
      handleRemoveProfileClose={handleRemoveProfileClose}
      onChangePlace={onChangePlace}

      openRemoveProfile={openRemoveProfile}
      handleProfileDelete={handleProfileDelete}
    
      onSubmit={handleSubmit}
      onClickClose={onClickClose}
      setAddressInfo={setAddressInfo}
      address={address}

      logo={logo}
      profiles={profiles}
      handleLogoChange={handleLogoChange}
      handleProfileChange={handleProfileChange}

      uniformRef={uniformRef}
      infoRef={infoRef}
      positionRef={positionRef}
      bioRef={bioRef}
      teamNameRef={teamNameRef}
      contactRef={contactRef}

      openSearch={openSearch}
      handleSelectPlace={handleSelectPlace}
      searchPlace={searchPlace}
      searchPlaces={searchPlaceData}
      searchingPlace={searchingPlace}
      handleSearchOpen={handleSearchOpen}
      handleSearchClose={handleSearchClose}
      onRemoveProfile={onRemoveProfile}
    />
  );
};

export default withRouter(EditTeamProfileContainer);
