import { gql } from "apollo-boost";

export const ACCEPT_MATCH = gql`
  mutation acceptMatch($id: String!, $awayId: String!, $matchId:String! $action: String!) {
    acceptMatch(id: $id, awayId: $awayId, matchId:$matchId action: $action) {
      id
    }
  }
`;
export const DECLINE_MATCH = gql`
  mutation declineMatch($id: String!, $reply: String!) {
    declineMatch(id: $id, reply:$reply) {
      id
    }
  }
`;