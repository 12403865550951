import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Chip from '@material-ui/core/Chip';
import { Checkbox, ListItemText } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    minWidth: 120,
    width:"100%"
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function MultipleSelect({datas, value, onChange}) {
  const classes = useStyles();

  const [localValue, setLocalValue] = React.useState(value);

  const handleChange = event => {
    console.log(event.target.value);
    //event.target.value.includes
    setLocalValue(event.target.value.join(','));
    onChange(event.target.value.join(','));
  };

  return (
    <div className={classes.root}>
      <FormControl className={classes.formControl}>
        <Select
          multiple
          value={value}
          onChange={handleChange}
          input={<Input id="select-multiple-chip" />}
          renderValue={selected => (
            <div className={classes.chips}>
              {selected.map(value => (
                <Chip key={value} label={value} className={classes.chip} />
              ))}
            </div>
          )}
          MenuProps={MenuProps}
        >
          {datas.map(data => (
            <MenuItem key={data.value} value={data.value}>
              <Checkbox color="primary" checked={localValue.indexOf(data.value) > -1} /> 
              <ListItemText primary={data.text} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}