import React, { useEffect } from "react";

import Loader from "../../Components/Loader";

import CONFIRM from "../../Components/Room/CONFIRM";
import MATCH from "../../Components/Room/MATCH";
import RECRUIT from "../../Components/Room/RECRUIT";
import COMPLETE from "../../Components/Room/COMPLETE";
import PLAYING from "../../Components/Room/PLAYING";

export default ({
  loading,
  data,
  isSendRequest,
  setIsSendRequest,
  canMatchRequest,
  handleSendMatch,

  onRequest,
  onConfirm,
  onComplete,
  onMatch,
  onCancel,
  onRoomCancel,
  uploading,
  GAMESTATUS,
  setGAMESTATUS,

  inviteUser,
  openSearch,
  openInvite,
  handleInviteOpen,
  handleInviteClose,
  handleSearchOpen,
  handleSearchClose,
  handleInvite,
  searchUsers,
  search,
  searching
}) => {
  if (loading === true) {
    return (
      <div>
        <Loader />
      </div>
    );
  } else if (!loading && data && data.seeFullRoom) {
    const {
      seeFullRoom: { gameStatus, isRequest, isUser }
    } = data;

    useEffect(() => {
      setIsSendRequest(isRequest || isUser);
      setGAMESTATUS(gameStatus);
    }, []);

    if (GAMESTATUS === "COMPLETE") {
      return <COMPLETE data={data} />;
    } else if (GAMESTATUS === "CONFIRM") {
      return <CONFIRM data={data} />;
    } else if (GAMESTATUS === "PLAYING") {
      return (
        <PLAYING 
          uploading={uploading}
          data={data}
        />
      );
    } else if (GAMESTATUS === "MATCH") {
      return (
        <MATCH 
          uploading={uploading}
          data={data} 
          onCancel={onCancel}
          onRoomCancel={onRoomCancel}
        />
      );
    } else if (GAMESTATUS === "RECRUIT") {
      return (
        <RECRUIT
          uploading={uploading}
          data={data}
          onRequest={onRequest}
          onMatch={onMatch}
          onCancel={onCancel}
          onRoomCancel={onRoomCancel}
          isSendRequest={isSendRequest}
          inviteUser={inviteUser}
          openSearch={openSearch}
          openInvite={openInvite}
          handleInviteOpen={handleInviteOpen}
          handleInviteClose={handleInviteClose}
          handleSearchOpen={handleSearchOpen}
          handleSearchClose={handleSearchClose}
          handleInvite={handleInvite}
          searchUsers={searchUsers}
          search={search}
          searching={searching}
          handleSendMatch={handleSendMatch}
          canMatchRequest={canMatchRequest}
        />
      );
    }
  }

  return null;
};
