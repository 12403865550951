import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import styled from "styled-components";
import DatePicker from "react-mobile-datepicker";
import moment from "moment";
import { toast } from "./Popup";

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  textField: {
    maxWidth: 600,
    width: "100%",
    "& > .MuiInputBase-root.Mui-disabled": {
      color: "#000"
    }
  }
}));

const Header = styled.div`
  font-family: "NanumSqure";
  font-size: 23px;
  padding: 10px 0px;
  font-weight: 600;
  color: #000;
`;

const DateFormatter = styled.div`
  height: 80px;
  font-size: 1.895em;
  & > span {
    font-size: 16px;
  }
`;
const YearFormatter = styled.div`
  height: 80px;
  font-size: 1.595em;
`;

const monthMap = {
  "1": (
    <DateFormatter>
      1<span>월</span>
    </DateFormatter>
  ),
  "2": (
    <DateFormatter>
      2<span>월</span>
    </DateFormatter>
  ),
  "3": (
    <DateFormatter>
      3<span>월</span>
    </DateFormatter>
  ),
  "4": (
    <DateFormatter>
      4<span>월</span>
    </DateFormatter>
  ),
  "5": (
    <DateFormatter>
      5<span>월</span>
    </DateFormatter>
  ),
  "6": (
    <DateFormatter>
      6<span>월</span>
    </DateFormatter>
  ),
  "7": (
    <DateFormatter>
      7<span>월</span>
    </DateFormatter>
  ),
  "8": (
    <DateFormatter>
      8<span>월</span>
    </DateFormatter>
  ),
  "9": (
    <DateFormatter>
      9<span>월</span>
    </DateFormatter>
  ),
  "10": (
    <DateFormatter>
      10<span>월</span>
    </DateFormatter>
  ),
  "11": (
    <DateFormatter>
      11<span>월</span>
    </DateFormatter>
  ),
  "12": (
    <DateFormatter>
      12<span>월</span>
    </DateFormatter>
  )
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const dateConfig = {
  year: {
    format: value => (
      <YearFormatter>{moment(value).format("YYYY")}</YearFormatter>
    ),
    caption: "년",
    step: 1
  },
  month: {
    //format: 'MM',
    format: value => monthMap[value.getMonth() + 1],
    caption: "월",
    step: 1
  },
  date: {
    format: value => (
      <DateFormatter>
        {moment(value).format("DD")}
        <span>일</span>
      </DateFormatter>
    ),
    //format: 'DD',
    caption: "일",
    step: 1
  }
};

export default function SlideDatePicker({ required = true, gameDate }) {
  const classes = useStyles();
  const [time, setTime] = useState(new Date());
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    setIsOpen(true);
  };

  const handleCancel = () => {
    setIsOpen(false);
  };
  const handleSelect = time => {
    if (moment(time).format("YYYY-MM-DD") < moment().format("YYYY-MM-DD")) {
      toast.error("오늘보다 이전 날짜를 지정하실 수 없습니다.");
    } else if (moment(time).diff(moment(), "days") >= 60) {
      toast.error("최대 60일까지 예약 가능합니다.");
    } else {
      setTime(time);
      setIsOpen(false);
      gameDate.setValue(moment(time).format("YYYY-MM-DD"));
    }
  };

  useEffect(() => {
    //console.log(gameDate);
    
    setTime(new Date(gameDate.value));

    isOpen
      ? (document.body.style = "overflow-y:hidden")
      : (document.body.style = "");

    function handleTouchMove(event) {
      if (isOpen) {
        event.preventDefault(); // 여기가 핵심
      }
    }
    
    window.addEventListener("touchmove", handleTouchMove, {
      passive: false
    });
    return () => window.removeEventListener("touchmove", handleTouchMove);
  }, [isOpen]);

  return (
    <Container  onClick={handleClick}>
      <TextField
        id="date"
        type="text"
        value={gameDate.value}
       
        disabled={true}
        className={classes.textField}
        required={required}
        onChange={gameDate.onChange}
        InputLabelProps={{
          shrink: true
        }}
      />
      <DatePicker
        dateConfig={dateConfig}
        value={time}
        isOpen={isOpen}
        customHeader={<Header>날짜 설정</Header>}
        confirmText={"등록"}
        cancelText={"취소"}
        onSelect={handleSelect}
        onCancel={handleCancel}
      />
    </Container>
  );
}
