import React from "react";
import { withRouter } from "react-router-dom";
import {
  SEE_NOTICE
} from "./CupNoticeQueries";
import CupNoticePresenter from "./CupNoticePresenter";
import { useQuery } from "react-apollo-hooks";

const CupNoticeContainer = ({ match: { params } }) => {
  const cupId = params.cup;
  const noticeId = params.notice;
  const { data, loading } = useQuery(SEE_NOTICE, {
    variables: {
      id:noticeId
    }
  });

  return (
    <CupNoticePresenter
      loading={loading}
      data={data}
      cupId={cupId}
    />
  );
};

export default withRouter(CupNoticeContainer);
