import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const Wrapper = styled.div`
  width:calc(100% - 25px);
  border-radius: 7px;
  display: flex;
  align-items: center;
  max-width: 450px;
  left: 50%;
  position: ${props => props.position};
  bottom:15px;
  transform: translateX(-50%);
`;

const Container = styled.button`
  background-color: ${props => props.disabled? "#d5d5d5":props => props.theme.yellowColor};
  border: 1px solid ${props => props.disabled? "#d5d5d5":props => props.theme.yellowColor};
  cursor: ${props => props.disabled?"none":"pointer"};
  font-size: 20px;
  padding: 15px 0px;
  text-align: center;
  font-weight: 500;
  font-family: "NanumSqure";
  color: ${props => props.color};
  border-radius: 7px;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  position: ${props => props.position};
  bottom:10px;
  z-index:997;
  max-width: 600px;
  margin-top: 20px;
  /* width: 95%; */
  width: calc(100% - 30px);
  max-width: 450px;
  left: 50%;
  transform: translateX(-50%);
  & > span {
    font-family:"Jalnan";
  }
`;

const HalfContainer = styled(Container)`
  margin: 0 auto;
  width: 50%;
  left:unset;
  transform:unset;
  position:relative;
  bottom:0px;
  &:first-child {
    margin-right: 5px;
  }
  &:last-child {
    border-color:${props => props.rightBGColor};
    background-color:${props => props.rightBGColor};
    margin-left: 5px;
  }
`;

Button.propTypes = {
  text: PropTypes.any.isRequired,
  onClick:PropTypes.func,
  color:PropTypes.string,
  half:PropTypes.bool,
  position:PropTypes.string,
  rightText:PropTypes.any,
  rightOnClick:PropTypes.func,
  disabled:PropTypes.bool
};

export default function Button({
  text,
  onClick,
  rightBGColor = "#ffbe21",
  color = "#fff",
  half = false,
  position = "fixed",
  rightText,
  rightOnClick,
  disabled = false
}) {
  if (half) {
    return (
      <Wrapper position={position}>
        <HalfContainer
          onClick={onClick}
          half={half}
          color={color}
        >
          {text}
        </HalfContainer>
        <HalfContainer
          onClick={rightOnClick}
          half={half}
          color={color}
          rightBGColor={rightBGColor}
        >
          {rightText}
        </HalfContainer>
      </Wrapper>
    );
  } else {
    return (
      <Container
        onClick={onClick}
        half={half}
        color={color}
        position={position}
        disabled={disabled}
      >
        {text}
      </Container>
    );
  }
}
