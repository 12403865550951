import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import {
  SEE_RANK
} from "./TeamRankingQueries";
import TeamRankingPresenter from "./TeamRankingPresenter";
import { useQuery } from "react-apollo-hooks";

const TeamRankingContainer = () => {
  const [sportId] = useState(localStorage.getItem("sport"));

  const [top3Monthly, settop3Monthly] = useState([]);
  const [top3Total, settop3Total] = useState([]);
  const [refreshing, setRefreshing] = useState();

  const [loadMore, setLoadMore] = useState(false);
  const [canMore, setCanMore] = useState(true);

  const { data, loading, refetch, fetchMore } = useQuery(SEE_RANK, {
    variables: {
      sportId: sportId,
      first:30,
      skip:0
    }
  });

  const handleInfiniteScroll = ({currentTarget}) => {
    if (
      canMore && 
      !loadMore &&
      currentTarget.scrollTop + currentTarget.clientHeight >= currentTarget.scrollHeight - 50
    ) {
      console.log("fetchMore");
      setLoadMore(true);

      fetchMore({
        variables: {
          skip: data.seeTeamRank.length
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          setLoadMore(false);
         
          if (!fetchMoreResult) return prev;
  
          if(fetchMoreResult.seeTeamRank.length === 0) {
            setCanMore(false);
          }
  
          return Object.assign({}, prev, {
            seeTeamRank: [...prev.seeTeamRank, ...fetchMoreResult.seeTeamRank]
          });
        }
      });
    }
   
  };
 
  const onRefresh = async () => {
    //console.log(currentTarget);
    try {
      setRefreshing(true);
      await refetch();
    } catch(e) {
      console.log(e);
    } finally {
      setRefreshing(false);
    }
  };


  return (
    <TeamRankingPresenter
      loading={loading}
      data={data}
      loadMore={loadMore}
      canMore={canMore}
      handleInfiniteScroll={handleInfiniteScroll}
      top3Monthly={top3Monthly}
      settop3Monthly={settop3Monthly}
      top3Total={top3Total}
      settop3Total={settop3Total}
      refreshing={refreshing}
      onRefresh={onRefresh}
    />
  );
};

export default withRouter(TeamRankingContainer);
