import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Helmet } from "rl-react-helmet";
import Loader from "../../Components/Loader";
import Avatar from "../../Components/Avatar";
import SwipeableViews from "react-swipeable-views";
import Map from "../../Components/Map";
import BaseHeader from "../../Components/Header/BaseHeader";
import Button from "../../Components/Button";
import {
  Arrow,
  Trophy,
  Phone,
  Close,
  PopupWarning,
  Gear,
  PopupError
} from "../../Components/Icons";
import Gallery from "../../Components/Gallery";

import { Link } from "react-router-dom";
import Card from "@material-ui/core/Card";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Divider, Dialog, Slide, Collapse } from "@material-ui/core";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import CloseIcon from "@material-ui/icons/Close";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import { NotFound } from "../../Components/NotFound";

import PhoneNumber from "../../Components/PhoneNumber";
import PullToRefresh from "rmc-pull-to-refresh";
import RefreshLoader from "../../Components/RefreshLoader";
import DedicateLoader from "../../Components/DedicateLoader";
import Client from "../../Apollo/Client";

const Wrapper = styled.div`
  background-color: #081f40;
  min-height: 100vh;
`;

const ContentWrapper = styled.div`
  /* padding-top: 56px; */
  margin: auto 0px;
  background-color: ${props => props.theme.innerBgColor};
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 56px);
  overflow:auto;
  /* position: relative;
  min-height: calc(100vh - 56px); */
  @media (min-width: 500px) {
    max-width: ${props => props.theme.maxWidth};
    margin: 0 auto;

  }
`;

const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 80%;
  margin: 0 auto;
  @media (max-width: 768px) {
    width: 95%;
  }
  height: 56px;
`;

const LastHeaderBox = styled.span`
  border: 1px solid #fff;
  border-radius: 6px;
  padding: 5px;
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  & > svg {
    margin-right: 8px;
  }
`;

const EditBoxWrap = styled.div`
  /* margin: 10px 0px 8px 15px; */
  display: flex;
  position: relative;
  justify-content: 15px;
  padding-right: 15px;
`;

const UserEditBox = styled.span`
  border: 1px solid #000;
  border-radius: 6px;
  padding: 5px;
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0px 8px 15px;
  width: 80px;
  cursor: pointer;
`;

const WithDrawBox = styled(UserEditBox)`
  border-color: #fd2f2f;
  color: #fd2f2f;
`;

const TeamDeleteBox = styled(UserEditBox)`
  border-color: #fd2f2f;
  background-color: #fd2f2f;
  color: #fff;
`;

const HostEditBox = styled.span`
  border: 1px solid #227fff;
  border-radius: 6px;
  padding: 5px;
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0px 8px 15px;
  width: 85px;
  cursor: pointer;
  color: #227fff;
`;

const HostRemoveBox = styled(HostEditBox)`
  border-color: #227fff;
  background-color: #227fff;
  color: #fff;
  margin: 10px 0px 8px 5px;
`;

const KickBackBox = styled(HostEditBox)`
  border-color: #fd2f2f;
  color: #fd2f2f;
  margin: 10px 0px 8px 5px;
  width: 60px;
`;

const HeaderColumn = styled.div`
  padding: 20px;
  font-size: 16px;
  text-align: center;
  /* width: 50%; */
`;

const TeamInfoWrap = styled.div`
  padding: 8px 15px;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const TeamInfoRow = styled.div`
  &:first-child {
    /* word-break: keep-all; */
    font-size: 14px;
    max-height: 100px;
    overflow-y: auto;
  }
  &:nth-child(2) {
    margin-top: 15px;
    border-top: 1px solid rgba(255, 255, 255, 0.52);
    padding: 10px 0px;
    display: flex;
    align-items: center;
    position: relative;
  }
`;

const TeamInfoBottomWrap = styled.div`
  flex-grow: 1;
  /* margin: 0px 10px; */
  background-color: #fff;
  padding: 0px 15px;
  font-size: 14px;
  padding-bottom: ${props =>
    props.canrequest === "true" ? "74px!important" : "0px"};
`;

const TeamInfoBottomRow = styled.div`
  display: flex;
`;

const TeamInfoBottomColumn = styled.div`
  padding: 10px 10px;
  min-width: 50%;
  /* width: 100%; */
`;

const TeamInfoBottomTitle = styled.div`
  color: #7a7a7a;
  margin-bottom: 2px;
`;
const TeamInfoBottomText = styled.div`
  color: #000;
  font-size: 16px;
  font-weight: 600;
  padding: 5px 0px;
  /* text-align:center; */
`;

const ContactInfo = styled.span`
  vertical-align: text-bottom;
  font-size: 14px;
`;

const VerticalDivider = styled(Divider)`
  background-color: rgba(255, 255, 255, 0.12) !important;
  height: 50%;
`;

const UserEditWrapper = styled.div`
  background-color: #f2f2f2;
  border-radius: 5px;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const UserEditHeader = styled.div`
  text-align: right;
  padding: 15px;
`;

const UserEditContent = styled.div`
  text-align: center;
  font-size: 23px;
  color: #000;
  margin: 0px 30px 50px 30px;
  flex: 1;
  display: flex;
  font-weight: 500;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  line-height: 1.5em;
`;

const UserEditFooter = styled.div`
  padding: 0px 20px 20px;
`;

const TeamEditContent = styled(Collapse)`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  line-height: 1.5em;
  border-color: 10px;
  background-color: #fff;
  & > div.MuiCollapse-wrapper {
    width: 100%;
  }
`;

const TeamEditButton = styled.div`
  font-size: 20px;
  font-weight: 500;
  color: ${props => props.textColor};
  width: 100%;
  border-bottom: 1px solid #999;
  padding: 18px 0px;
  cursor: pointer;
`;

const TeamEditToggleButton = styled.div`
  position: fixed;
  z-index: 997;
  top: 16px;
  /* right: 60px; */
  right: 16px;
`;



const NoPlaceBox = styled.div`
  padding: 30px 0px;
  margin: 0 20px;
  border-style: dashed;
  text-align: center;
  border: 1px dashed #fff;
`;

const DeclineDialogTitle = styled.h4`
  color: #000;
  font-size: 18px;
  margin-top: 20px;
  text-align: center;
  word-break: keep-all;
  font-weight: 500;
  line-height: 1.5em;
`;

const DeclineDialogText = styled.div`
  color: #000;
  font-size: 15px;
  margin-top: 20px;
  width: 95%;
  word-break: keep-all;
  font-weight: 400;
  line-height: 1.5em;
  background-color: #d5d5d5;
  padding: 20px;
  border-radius: 8px;
`

const ConfirmButton = styled.button`
  background-color: #ffbe21;
  border: 1px solid #ffbe21;
  cursor: "pointer";
  font-size: 14px;
  padding: 10px 0px;
  text-align: center;
  font-weight: 500;
  font-family: "NanumSqure";
  border-radius: 7px;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  z-index:997;
  margin-top: 20px;
  /* width: 95%; */
  width: calc(100% - 20px);
`;

const MyTabPanel = styled(TabPanel)`
  .MuiListItemText-secondary {
    color: #000;
  }
  /* padding-bottom: 47px; */
`;

const MyList = styled(List)`
  padding: 0 !important;
  background-color: #fff;
  padding-bottom: ${props =>
    props.canrequest === "true" ? "74px!important" : "0px"};
`;

const MyTabs = styled(Tabs)`
  color: #fff;
  padding: 15px 25px;
  max-width: 500px;
  width: 100%;
  & button:not(.Mui-selected) {
    z-index: 0;
  }
  .MuiTabs-flexContainer {
    background-color: #040f20;
    border-radius: 20px;
  }
  .MuiTabs-indicator {
    top: 0px;
    height: 4px;
    border-radius: 20px;
    height: 38px;
    background-color: ${props => props.theme.blueColor}!important;
  }
`;

const useStyles = makeStyles(theme => ({
  card: {
    borderRadius: 0,
    backgroundColor: "transparent"
  },
  media: {
    height: 0,
    paddingTop: "56.25%" // 16:9
  },
  tabRoot: {
    fontFamily: "NanumSqure",
    zIndex: 1,
    minHeight: "38px",
    [theme.breakpoints.down("330")]: {
      fontSize: 13
    }
  },
  userWrap: {
    backgroundColor: "#fff",
    color: "#000",
    maxWidth: "600px",
    width: "100%",
    overflowY: "auto",
    flex: 1,
    "& ul.MuiList-padding": {
      padding: 0,
      marginBottom: 30,
      backgroundColor: "#fff"
    },
    "& div.MuiTypography-body2": {
      fontFamily: "NanumSqure",
      fontSize: 15
    }
  },
  hr: {
    height: "50%"
  },
  dialog: {
    "& .MuiDialog-paper": {
      backgroundColor: "#113f80"
    }
  },
  declineDialog : {
    "& .MuiPaper-root": {
      width:"90%",
      minHeight:300,
      margin:0
    },
    "& .MuiDialogContent-root": {
      display: "flex",
      flexDirection:"column",
      alignItems: "center",
      justifyContent: "center",
      padding:"25px 12px"
    }
  },
  teamEditDialog: {
    "& .MuiDialog-container": {
      alignItems: "flex-end"
    },
    "& .MuiDialog-paperWidthSm": {
      width: "calc(100% - 30px)",
      margin: 0,
      maxWidth: 480,
      marginBottom: 75,
      borderRadius: 13
    }
  },
  inviteDialog: {
    "& .MuiDialog-container": {
      alignItems: "flex-end"
    },
    "& .MuiDialog-paperWidthSm": {
      width: "100%",
      margin: 0,
      height: "50vh",
      maxWidth: 500,
      maxHeight: 300
    }
  },
  SwipeView: {
    flex: 1,
    width: "100%",
    maxWidth: "924px",
    display: "flex",
    flexDirection: "column",
    margin: "0 auto",
    "& > .react-swipeable-view-container": {
      flex: 1
    }
  },
  boxRoot: {
    height: "100%",
    display: "flex",
    flexDirection: "column"
  },
  panelRoot: {
    height: "100%"
  },

  hostDialogHeader: {
    margin: 0,
    padding: "10px 16px 0px",
    textAlign: "center"
  },
  closeButton: {
    position: "absolute",
    right: 0,
    top: 0,
    color: "#000"
  },
  hostDialog: {
    "& .MuiPaper-root": {
      width: 300,
      minHeight: 140
    },
    "& .MuiDialogContent-root": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "8px 12px",
      "& > .MuiDialogContentText-root": {
        color: "#000",
        display: "flex",
        "& > svg": {
          marginRight: 5
        },
        "& > span": {
          flex: 1
        }
      }
    }
  }
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  const classes = useStyles();
  if (props.className.indexOf("mapWrapper") !== -1) {
    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        <Box className={classes.boxRoot} p={0}>
          {children}
        </Box>
      </Typography>
    );
  } else {
    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        // {...other}
        className={classes.panelRoot}
      >
        <Box className={classes.boxRoot} p={0}>
          {children}
        </Box>
      </Typography>
    );
  }
}

const DialogTitle = props => {
  const classes = useStyles();
  const { children, onClose, ...other } = props;
  return (
    <MuiDialogTitle
      disableTypography
      className={classes.hostDialogHeader}
      {...other}
    >
      {children}
      <IconButton
        aria-label="close"
        className={classes.closeButton}
        onClick={onClose}
      >
        <CloseIcon />
      </IconButton>
    </MuiDialogTitle>
  );
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`
  };
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default ({
  loading,
  data,
  dataRequest,
  error,
  applying,
  isSend,
  handleApply,
  hostEditMode,
  handleEditMode,
  handleAddManager,
  handleRemoveManager,
  handleKickback,
  handleWithdraw,
  handleTeamDelete,

  tabValue,
  setTabValue,
  openKickBack,
  openWithDraw,
  handleOpenKickBack,
  handleOpenWithDraw,
  handleCloseKickBack,
  handleCloseWithDraw,
  openWithDrawForHost,
  handleOpenWithDrawForHost,
  handleCloseWithDrawForHost,
  openTeamDelete,
  handleOpenTeamDelete,
  handleCloseTeamDelete,
  handleReqInvitePage,
  handleTeamInfoEditPage,

  openTeamEdit,
  setOpenTeamEdit,
  handleOpenTeamEdit,
  handleCloseTeamEdit,

  selectUser,
  setMeUser,
  host,
  setHost,
  managers,
  setManagers,
  users,
  setUsers,
  newTeamInfo,
  history,
  isMineState,
  setIsMineState,
  // isHostState,
  // setIsHostState,
  // isManagerState,
  // setIsManagerState,
  // isInviteState,
  // setIsInviteState,
  // isRequestState,
  // setIsRequestState,

  canRequestState,
  setCanRequestState,

  refreshing,
  onRefresh
}) => {
  if (loading) {
    return (
      <Wrapper>
        <BaseHeader
          text={""}
          isBack={true}
          isClose={false}
          isCenter={true}
        />
        <Loader />
      </Wrapper>
    );
  } else if (!loading && data && data.seeFullTeam) {
    const classes = useStyles();
    const theme = useTheme();

    const [mapInit, setMapInit] = useState(false);
    const [openDecline, setOpenDecline] = useState(false);

    const {
      seeFullTeam: {
        id,
        teamName,
        contact,
        uniformColor,
        matchingInfo,
        matchingPosition,
        bio,
        avgLevel,
        avgAge,
        isInvited,
        isMine,
        isRequest,
        isHost,
        isManager,
        canRequest,
        userCount,
        place,
        profile
      },
      me
    } = data;
    //console.log(data.seeFullTeam);
    
    const handleChange = (event, newValue) => {
      setMapInit(true);
      setTabValue(newValue);
    };

    const handleCloseDecline = () => {
      setOpenDecline(false);
      history.replace(`/team/${id}`);
    };

    const handleChangeIndex = index => {
      setTabValue(index);
    };

    const RenderMatchingInfo = () => {
      if (matchingInfo) {
        if (
          matchingInfo.split("/").length > 0 &&
          matchingInfo.split("/")[1] === ""
        ) {
          return matchingInfo.split("/")[0];
        } else {
          return matchingInfo;
        }
      } else {
        return "미입력";
      }
    };

    useEffect(() => {
      setTabValue(0);

      setMeUser(me);
      setCanRequestState(canRequest);
      setIsMineState(isMine);
      // setIsHostState(isHost);
      // setIsManagerState(isManager);
      // setIsInviteState(isInvited);
      // setIsRequestState(isRequest);
      setHost(data.seeFullTeam.host);
      setManagers(data.seeFullTeam.managers);
      setUsers(data.seeFullTeam.users);
    }, []);

    useEffect(() => {
      if (newTeamInfo !== undefined) {
        //console.log("newTeamInfo");
        const { subscriptionTeam } = newTeamInfo;
        //setPhotos([...subscriptionTeam.profile]);

        if(subscriptionTeam.host) {
          setHost(subscriptionTeam.host);
          setManagers(subscriptionTeam.managers);
          setUsers(subscriptionTeam.users);
  
          if ( subscriptionTeam.host.id === me.id ||
              subscriptionTeam.users.findIndex(user => user.id === me.id) !== -1 ||
              subscriptionTeam.managers.findIndex(manager => manager.id === me.id) !== -1) {
            setCanRequestState(false);
            setIsMineState(true);
  
            Client.writeData({
              id: `Team:${id}`,
              data: {
                isInvited:false,
                isRequest:false
              }
            });
  
          } else {
            setIsMineState(false);
          }
        } 
      }
    }, [newTeamInfo]);


    useEffect(() => {
      if(dataRequest && dataRequest.seeUserRequest) {
        console.log(dataRequest.seeUserRequest);
        setOpenDecline(true);
        onRefresh();
      }
    }, [dataRequest])

    return (
      <Wrapper>
        <BaseHeader
          text={teamName}
          isBack={true}
          isClose={false}
          isCenter={true}
        />

        <Helmet>
          <title>{teamName} | WePlay</title>
        </Helmet>
        {(isHost || isManager) && (
          <TeamEditToggleButton onClick={handleOpenTeamEdit}>
            <Gear size={24} />
          </TeamEditToggleButton>
        )}

        {applying && <Loader />}
        <PullToRefresh
          style={{ overflow: "auto", paddingTop:56, minHeight:"100vh", maxHeight:"100vh" }}
          direction="down"
          distanceToRefresh={50}
          damping={100}
          onRefresh={() => onRefresh()}
          refreshing={refreshing}
          //onScroll={e => onScroll(e)}
          indicator={{
            activate: <DedicateLoader/>,
            deactivate: <></>,
            release: <RefreshLoader />,
            finish: <></>
          }}
        >
        <ContentWrapper>
          <Header>
            <HeaderColumn grow={1}>팀원 {userCount}명</HeaderColumn>
            <VerticalDivider className={classes.hr} orientation="vertical" />
            <HeaderColumn grow={1}>
              <Link to={`/team/${id}/history`}>
                <LastHeaderBox>
                  <Trophy />
                  전적 보기
                </LastHeaderBox>
              </Link>
            </HeaderColumn>
          </Header>

          <Card className={classes.card}>
            <Gallery items={profile} />
          </Card>

          <MyTabs
            value={tabValue}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="inherit"
            variant="fullWidth"
            aria-label="tabs"
          >
            <Tab
              className={classes.tabRoot}
              label="팀 정보"
              {...a11yProps(0)}
            />
            <Tab
              className={classes.tabRoot}
              label="팀원 정보"
              {...a11yProps(0)}
            />
            <Tab
              className={classes.tabRoot}
              label="경기장 정보"
              {...a11yProps(1)}
            />
          </MyTabs>

          <SwipeableViews
            className={classes.SwipeView}
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={tabValue}
            onChangeIndex={handleChangeIndex}
            disabled={true}
          >
            <MyTabPanel value={tabValue} index={0}>
              <TeamInfoWrap>
                <TeamInfoRow>
                  {bio.split("\n").map((line, i) => {
                    return (
                      <span key={i}>
                        {line}
                        <br />
                      </span>
                    );
                  })}
                </TeamInfoRow>

                <TeamInfoRow>
                  <Phone size={16} />
                  <ContactInfo>
                    대표전화 :{" "}
                    {contact ? <PhoneNumber contact={contact} /> : "미등록"}
                  </ContactInfo>
                </TeamInfoRow>

                {/* {(isHostState || isManagerState) && (
                  <Link to={`/team/${id}/user`}>
                    <Fab
                      type={"teamUser"}
                      top={"5px"}
                      right={"5px"}
                      //  onClick={handleSearchOpen}
                    />
                  </Link>
                )} */}
              </TeamInfoWrap>

              <TeamInfoBottomWrap
                canrequest={
                  !isMineState && ( isRequest || isInvited || canRequestState )? "true" : "false"
                }
              >
                <TeamInfoBottomRow>
                  <TeamInfoBottomColumn>
                    <TeamInfoBottomTitle>팀 평균 나이</TeamInfoBottomTitle>
                    <TeamInfoBottomText>{avgAge}세</TeamInfoBottomText>
                  </TeamInfoBottomColumn>

                  <TeamInfoBottomColumn>
                    <TeamInfoBottomTitle>팀 평균 레벨</TeamInfoBottomTitle>
                    <TeamInfoBottomText>Level {avgLevel}</TeamInfoBottomText>
                  </TeamInfoBottomColumn>
                </TeamInfoBottomRow>

                <TeamInfoBottomRow>
                  <TeamInfoBottomColumn>
                    <TeamInfoBottomTitle>유니폼 색상</TeamInfoBottomTitle>
                    <TeamInfoBottomText>
                      {uniformColor ? uniformColor : "미입력"}
                    </TeamInfoBottomText>
                  </TeamInfoBottomColumn>
                </TeamInfoBottomRow>

                <TeamInfoBottomRow>
                  <TeamInfoBottomColumn>
                    <TeamInfoBottomTitle>매칭 요청 사항</TeamInfoBottomTitle>
                    <TeamInfoBottomText>
                      {RenderMatchingInfo()}
                    </TeamInfoBottomText>
                  </TeamInfoBottomColumn>
                </TeamInfoBottomRow>

                <TeamInfoBottomRow>
                  <TeamInfoBottomColumn>
                    <TeamInfoBottomTitle>매칭 포지션</TeamInfoBottomTitle>
                    <TeamInfoBottomText>
                      {matchingPosition ? matchingPosition : "미입력"}
                    </TeamInfoBottomText>
                  </TeamInfoBottomColumn>
                </TeamInfoBottomRow>
              </TeamInfoBottomWrap>
            </MyTabPanel>

            <MyTabPanel value={tabValue} index={1}>
              <Grid className={classes.userWrap} item xs={12} md={6}>
                {isMineState && (
                  <EditBoxWrap>
                    {isHost && hostEditMode && (
                      <UserEditBox onClick={handleEditMode}>
                        편집 완료
                      </UserEditBox>
                    )}

                    {!isHost && !isManager && (
                      <WithDrawBox onClick={handleOpenWithDraw}>
                        탈퇴하기
                      </WithDrawBox>
                    )}

                    {/* {(isHostState || isManagerState) && (
                      <Link to={`/team/${id}/user`}>
                        <Fab
                          type={"teamUser"}
                          top={"5px"}
                          right={"5px"}
                          //  onClick={handleSearchOpen}
                        />
                      </Link>
                    )} */}
                  </EditBoxWrap>
                )}

                <MyList
                  dense
                  canrequest={
                    !isMineState && (isRequest || isInvited || canRequestState) ? "true" : "false"
                  }
                >
                  {host && (
                    <Link key={host.id} to={`/user/${host.id}`}>
                      <ListItem button>
                        <ListItemAvatar>
                          <Avatar size={"sm"} url={host.avatar} isHost={true} />
                        </ListItemAvatar>
                        {host.basket ? (
                          <ListItemText
                            primary={`${host.username}(${
                              host.basket.backNo ? host.basket.backNo : "미등록"
                            })`}
                            secondary={`Lv.${host.level} ｜ ${
                              host.basket.position
                                ? host.basket.position
                                : "미등록"
                            }`}
                          />
                        ) : (
                          <ListItemText
                            primary={host.username}
                            secondary={`Lv.${host.level}`}
                          />
                        )}

                        <ListItemSecondaryAction>
                          <IconButton
                            edge="end"
                            color="inherit"
                            aria-label="detail"
                          >
                            <Arrow />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                    </Link>
                  )}

                  {managers &&
                    managers.map(manager => (
                      <Link
                        key={manager.id}
                        onClick={hostEditMode ? e => e.preventDefault() : null}
                        to={`/user/${manager.id}`}
                      >
                        <ListItem button>
                          <ListItemAvatar>
                            <Avatar
                              size={"sm"}
                              url={manager.avatar}
                              isHost={true}
                            />
                          </ListItemAvatar>
                          {manager.basket ? (
                            <ListItemText
                              primary={`${manager.username}(${
                                manager.basket.backNo
                                  ? manager.basket.backNo
                                  : "미등록"
                              })`}
                              secondary={`Lv.${manager.level} ｜ ${
                                manager.basket.position
                                  ? manager.basket.position
                                  : "미등록"
                              }`}
                            />
                          ) : (
                            <ListItemText
                              primary={manager.username}
                              secondary={`Lv.${manager.level}`}
                            />
                          )}

                          {hostEditMode ? (
                            <>
                              <HostRemoveBox
                                onClick={handleRemoveManager(manager.id)}
                              >
                                강등
                              </HostRemoveBox>
                              <KickBackBox
                                onClick={handleOpenKickBack(manager)}
                              >
                                강퇴
                              </KickBackBox>
                            </>
                          ) : (
                            <ListItemSecondaryAction>
                              <IconButton
                                edge="end"
                                color="inherit"
                                aria-label="detail"
                              >
                                <Arrow />
                              </IconButton>
                            </ListItemSecondaryAction>
                          )}
                        </ListItem>
                      </Link>
                    ))}

                  {users &&
                    users.map(user => (
                      <Link
                        key={user.id}
                        onClick={hostEditMode ? e => e.preventDefault() : null}
                        to={`/user/${user.id}`}
                      >
                        <ListItem button={!hostEditMode}>
                          <ListItemAvatar>
                            <Avatar size={"sm"} url={user.avatar} />
                          </ListItemAvatar>
                          {user.basket ? (
                            <ListItemText
                              primary={`${user.username}(${
                                user.basket.backNo
                                  ? user.basket.backNo
                                  : "미등록"
                              })`}
                              secondary={`Lv.${user.level} ｜ ${
                                user.basket.position
                                  ? user.basket.position
                                  : "미등록"
                              }`}
                            />
                          ) : (
                            <ListItemText
                              primary={user.username}
                              secondary={`Lv.${user.level}`}
                            />
                          )}

                          {hostEditMode ? (
                            <>
                              <HostEditBox onClick={handleAddManager(user.id)}>
                                호스트 승급
                              </HostEditBox>

                              <KickBackBox onClick={handleOpenKickBack(user)}>
                                강퇴
                              </KickBackBox>
                            </>
                          ) : (
                            <ListItemSecondaryAction>
                              <IconButton
                                edge="end"
                                color="inherit"
                                aria-label="detail"
                              >
                                <Arrow />
                              </IconButton>
                            </ListItemSecondaryAction>
                          )}
                        </ListItem>
                      </Link>
                    ))}
                </MyList>
              </Grid>
            </MyTabPanel>
            <MyTabPanel className={"mapWrapper"} value={tabValue} index={2}>
              {tabValue === 2 && mapInit && place && (
                <Map
                  isInit={tabValue}
                  address={place.address}
                  placeName={place.placeName}
                  lat={place.location.latitude}
                  lng={place.location.longitude}
                ></Map>
              )}
              {!place && <NoPlaceBox>경기장을 등록하지 않았습니다.</NoPlaceBox>}
            </MyTabPanel>
          </SwipeableViews>

          {!isMineState && isRequest && (
            <Button text={"답변 대기 중"} 
            // position={"relative"}
            position={"absolute"}
            color={"#000"} disabled={true} />
          )}
          {!isMineState && isInvited && (
            <Button
              text={"초대장 확인하기"}
              color={"#000"}
              position={"absolute"}
              onClick={() => history.push("/invites")}
            />
          )}
          {!isInvited && !isRequest && canRequestState && (
            <Button
              text={"팀 소속 신청"}
              color={"#000"}
              position={"absolute"}
              onClick={handleApply}
            />
          )}

        </ContentWrapper>
        </PullToRefresh>

        {dataRequest && dataRequest.seeUserRequest && (
          <Dialog
            open={openDecline}
            className={classes.declineDialog}
            onClose={handleCloseDecline}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
                <PopupError size={40} fill={"#f44336"} />
                <DeclineDialogTitle>아래 사유로 가입 신청이 거부되었습니다.</DeclineDialogTitle>
                <DeclineDialogText>
                  {dataRequest.seeUserRequest.declineMessage.split("\n").map((line, i) => {
                    return (
                      <span key={i}>
                        {line}
                        <br />
                      </span>
                    );
                  })}
                </DeclineDialogText>
                <ConfirmButton onClick={handleCloseDecline}>확인</ConfirmButton>
            </DialogContent>
          </Dialog>
        )}
        
        
        <Dialog
          className={classes.teamEditDialog}
          open={openTeamEdit}
          onClose={handleCloseTeamEdit}
          TransitionComponent={Transition}
        >
          <UserEditWrapper>
            <TeamEditContent in={openTeamEdit}>
              {isHost && (
                <TeamEditButton
                  onClick={handleTeamInfoEditPage}
                  textColor={"#227fff"}
                >
                  팀 정보 수정
                </TeamEditButton>
              )}
              <TeamEditButton onClick={handleEditMode} textColor={"#227fff"}>
                팀원 편집
              </TeamEditButton>
              <TeamEditButton
                onClick={handleReqInvitePage}
                textColor={"#227fff"}
              >
                신청/초대 관리
              </TeamEditButton>
              <TeamEditButton
                onClick={
                  isHost ? handleOpenWithDrawForHost : handleOpenWithDraw
                }
                textColor={"#fd2f2f"}
              >
                탈퇴하기
              </TeamEditButton>
              {isHost && (
                <TeamEditButton
                  onClick={handleOpenTeamDelete}
                  textColor={"#fd2f2f"}
                >
                  팀 삭제
                </TeamEditButton>
              )}
            </TeamEditContent>

            <Button
              color={"#000"}
              text={"취소"}
              onClick={handleCloseTeamEdit}
            />
          </UserEditWrapper>
        </Dialog>

        <Dialog
          className={classes.inviteDialog}
          open={openKickBack}
          onClose={handleCloseKickBack}
          TransitionComponent={Transition}
        >
          <UserEditWrapper>
            <UserEditHeader>
              <IconButton
                edge="end"
                color="inherit"
                onClick={handleCloseKickBack}
                aria-label="close"
              >
                <Close fill={"#000"} />
              </IconButton>
            </UserEditHeader>
            <UserEditContent>
              <div>
                {selectUser && selectUser.username}님을
                <br />
                강퇴하시겠습니까?
              </div>
            </UserEditContent>
            <UserEditFooter>
              <Button
                color={"#000"}
                text={"확인"}
                onClick={handleKickback}
                half={true}
                rightText={"취소"}
                rightOnClick={handleCloseKickBack}
                rightBGColor={"#d5d5d5"}
              />
            </UserEditFooter>
          </UserEditWrapper>
        </Dialog>

        <Dialog
          className={classes.inviteDialog}
          open={openWithDraw}
          onClose={handleCloseWithDraw}
          TransitionComponent={Transition}
        >
          <UserEditWrapper>
            <UserEditHeader>
              <IconButton
                edge="end"
                color="inherit"
                onClick={handleCloseWithDraw}
                aria-label="close"
              >
                <Close fill={"#000"} />
              </IconButton>
            </UserEditHeader>
            <UserEditContent>
              <div>정말 팀을 탈퇴하시겠습니까?</div>
            </UserEditContent>
            <UserEditFooter>
              <Button
                color={"#000"}
                text={"확인"}
                onClick={handleWithdraw}
                half={true}
                rightText={"취소"}
                rightOnClick={handleCloseWithDraw}
                rightBGColor={"#d5d5d5"}
              />
            </UserEditFooter>
          </UserEditWrapper>
        </Dialog>

        <Dialog
          className={classes.inviteDialog}
          open={openTeamDelete}
          onClose={handleCloseTeamDelete}
          TransitionComponent={Transition}
        >
          <UserEditWrapper>
            <UserEditHeader>
              <IconButton
                edge="end"
                color="inherit"
                onClick={handleCloseTeamDelete}
                aria-label="close"
              >
                <Close fill={"#000"} />
              </IconButton>
            </UserEditHeader>
            <UserEditContent>
              <div>
                정말 팀을 삭제하시겠습니까?
                <br />
                모든 기록이 삭제됩니다.
              </div>
            </UserEditContent>
            <UserEditFooter>
              <Button
                color={"#000"}
                text={"확인"}
                onClick={handleTeamDelete}
                half={true}
                rightText={"취소"}
                rightOnClick={handleCloseTeamDelete}
                rightBGColor={"#d5d5d5"}
              />
            </UserEditFooter>
          </UserEditWrapper>
        </Dialog>

        <Dialog
          open={openWithDrawForHost}
          className={classes.hostDialog}
          onClose={handleCloseWithDrawForHost}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle
            id="alert-dialog-title"
            onClose={handleCloseWithDrawForHost}
          >
            <PopupWarning fill={"#ff9800"} />
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <span>
                {
                  "호스트가 탈퇴할 경우 반드시 다른 팀원에게 권한을 양도해주시기 바랍니다."
                }
              </span>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </Wrapper>
    );
  }
  else if(error) {
    return <NotFound text={"해당 팀을 찾을 수 없습니다."} />
  }
};
