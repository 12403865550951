import React, { useState, useEffect } from "react";
import styled from "styled-components";
import moment from 'moment';
import "moment/locale/ko";
import { Helmet } from "rl-react-helmet";
import { Link } from "react-router-dom";
import Loader from "../../Components/Loader";
//import Button from "../../Components/AdminButton";
import { RoundArrow } from "../../Components/Icons";
import Avatar from "../../Components/Avatar";
import CupHeader from "../../Components/CupHeader";

const Wrapper = styled.div`
  /* display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 90vh;
  overflow-x: scroll; */
  min-height: 100vh;
  background-color:#EEEEEE;
`;

const ContentWrapper = styled.div`
  color: #000;
`;

const TeamName = styled.span`
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  position: relative;
  margin-left: 10px;
  min-width: 50px;
  @media (max-width: 600px) {
    font-size:14px;
    margin-left: 0px;
  }
`;

const HistoryWrap = styled.ul`
  padding: 0;
  max-width: 824px;
  margin: 0 auto;
  width: 95%;
  padding: 20px 0 30px;
`;

const Index = styled.div`
  width: 100%;
  background-color: #25374d;
  border-bottom: 1px solid #25374d;
  color:#fff;
  line-height: 32px;
  text-align: left;
  font-size: 14px;
`;

const IndexMonth = styled.span`
  padding: 0 10px;
  font-weight: 600;
`;

const VSText = styled.span`
  font-size: 20px;
`;

const ListTitle = styled.div`
  background-color:#25374d;
  color:#fff;
  font-size:25px;
  padding:10px 20px;
  width:100%;
  margin-bottom:20px;
`;

const HistoryList = styled.li`
  list-style: none;
  width: 100%;
  background-color: ${props => props.background};
  border-bottom: 1px solid #ddd;
  padding: 24px 15px;
  overflow: auto;
  display: table;
  @media (max-width: 600px) {
    padding: 24px 8px;
  }
`;

const ScheduleDate = styled.div`
  display:block;
  color: #000;
  font-size: 13px;
  padding-left: 7px;
  & span:nth-child(2) {
    padding:5px 5px;
  }
`;

const Time = styled.span`
  font-weight:600;
`;


const GameInfoWrap = styled.div`
    display: flex;
    align-items: center;
    @media (max-width: 600px) {
      margin: 0 auto;
      width: 100%;
      justify-content: space-between;
    }
`;

const GameColumn = styled.div`
  padding: 7px;
  font-size: 16px;
  text-align: center;
  padding-top:20px;
  display:flex;
  align-items: center;
  min-width:80px;
  position:relative;
  /* flex: 1; */
  &:first-child, &:last-child {
    min-width: 130px;
   
  }
  @media (max-width: 600px) {
    padding: 1px;
    padding-top:20px;
    display: flex;
    flex-direction: column;
    &:first-child, &:last-child {
      min-width: auto;
      flex:1;
    }
  }
`;

const ColumnRow = styled.div`
  font-size: 15px;
  margin: 5px 0px;
  position:relative;
  padding: 0px 10px;
  width: 100%;
`;

const ScheduleInfo = styled.div`
  font-size: 13px;
  display: flex;
  justify-content:space-between;
  align-items: center;
  vertical-align: middle;
  & > p {
    color: #999;
    font-size: 12px;
  }

`;

const ScheduleResult = styled.div`
  text-align: center;
  vertical-align: middle;
  max-width: ${props => props.mobile ?"auto":"250px"};
  display:${props => props.mobile ? "block":"table-cell"};
  position:relative;
`;

const GameNameBox = styled.div`
  padding:10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  word-break: keep-all;
  margin-top:10px;
  & > svg {
    margin-left:10px;
  }
  @media (max-width: 600px) {
    justify-content: center;
  }
`;

const EmptyAvatar = styled.div`
  border: 1px solid #000;
  border-radius: 50%;
  padding: 6px;
  min-width: 42px;
  min-height: 42px;
`;

const GameComplteBadge = styled.span`
  background-color:#4b4b4b;
  color:#fff;
  padding: 5px 8px;
  border-radius: 3px;
  position: absolute;
  right: 10px;
  @media (max-width: 600px) {
    position:relative;
  }
`;

const WinText = styled.span`
  color: ${props => props.theme.yellowColor};
  font-size: 15px;
  font-family: 'Jalnan';
  left: 0px;
  right: 0px;
  top: 25px;
  @media (max-width: 600px) {
    top: 17px;
  }
  position: absolute;
  
`;

const CrownImg = styled.img`
  width: 30px;
  position: absolute;
  top: -18px;
  left: 6px;
  @media (max-width: 600px) {

  }
`;

const TeamAvatar = styled.div`
position: relative;
`;

const _gameDateText = gameDate => {
  return `${moment.utc(gameDate).format("YYYY. MM. DD")}`;
};

const _gameWeekText = gameDate => {
  let week = ["일", "월", "화", "수", "목", "금", "토"];
  let date = new Date(gameDate);
  return `(${week[date.getDay()]})`;
};

const _gameTimeText = time => {
  const hour = parseInt(time.split(":")[0]);
  const minute =time.split(":")[1];
  const ampm = hour >= 12 ? "오후":"오전";
  if (hour > 12) return `${ampm} ${hour - 12}:${minute}`;
  else return `${ampm} ${hour}:${minute}`;
};

export default function MaterialTableDemo({
  cupId,
  loading,
  data,
  games,
  setGames,
  onScroll,
  onCreatePage,
  matches,
}) {
  if (loading) {
    return (
      <Wrapper>
        <Helmet>
          <title>대회 일정 | WePlay</title>
        </Helmet>
        <ContentWrapper>
          <CupHeader id={cupId} active={"games"} />
        </ContentWrapper>
        <Loader />
      </Wrapper>
    );
  } else if (!loading && data && data.seeCupGames) {

    useEffect(() => {

      setGames(
        data.seeCupGames.reduce((acc, item) => {
          (acc[moment(item.gameDate).format("YYYY-MM")] =
            acc[moment(item.gameDate).format("YYYY-MM")] || []).push(item);
          return acc;
        }, {})
      );

      window.addEventListener('scroll', onScroll, true);
    }, [])


    console.log(data);

    return (
      <Wrapper>
        <Helmet>
          <title>대회 일정 | WePlay</title>
        </Helmet>

        <ContentWrapper>
        <CupHeader id={cupId} active={"games"} />
        <HistoryWrap>
          <ListTitle>일정/결과</ListTitle>
            {games &&
              Object.keys(games).map((key, i) => {
                const MONTH = key.split("-")[1];
                return (
                  <div key={i}>
                    <Index>
                      <IndexMonth>{MONTH}월</IndexMonth>
                    </Index>
                    {games[key].map(game => {
                      let routing = `/cup/${cupId}/game/${game.id}`;
                      
                      if(game.homeTeam && game.awayTeam) {
                        return (
                          <Link key={game.id} to={routing}>
                            <HistoryList 
                              key={game.id} 
                              background={(game.gameStatus === "COMPLETE")? "#f3f3f3":"#fff"}
                            >
                              <ScheduleDate>
                                  {_gameDateText(game.gameDate)}{" "}
                                  {_gameWeekText(game.gameDate)}{" "}
                                  <Time>{_gameTimeText(game.startTime)}</Time>
                                  <span>/</span>
                                  <span>{game.place.placeName}</span>
                              </ScheduleDate>
  
                              <ScheduleInfo>
                                <GameInfoWrap>
                                  <GameColumn>
                                    {game.homeTeam? (
                                      <>
                                        <TeamAvatar>
                                          {game.winner === game.homeTeam.id && <CrownImg src="https://d1jozj1mdbjtd9.cloudfront.net/crown.png"/>}
                                          <Avatar url={game.homeTeam.logo} size={"sm"}/>
                                        </TeamAvatar> 
                                        <TeamName>
                                          {game.homeTeam.teamName} 
                                          {game.winner === game.homeTeam.id && (<WinText left={true}>승</WinText>)}
                                        </TeamName>  
                                      </>  
                                    ):(
                                      <>
                                      <EmptyAvatar/>
                                      <TeamName>?</TeamName>
                                      </> 
                                    )}
                                  </GameColumn>
                                  <GameColumn>
                                    <ColumnRow>
                                    
                                      <VSText>VS</VSText>
                                     
                                    </ColumnRow>
                                  </GameColumn>
                                  <GameColumn>
                                   {game.awayTeam? (
                                      <>
                                      <TeamAvatar>
                                        {game.winner === game.awayTeam.id && <CrownImg src="https://d1jozj1mdbjtd9.cloudfront.net/crown.png"/>}
                                        <Avatar url={game.awayTeam.logo} size={"sm"} />
                                      </TeamAvatar>
                                    
                                        <TeamName>
                                          {game.awayTeam.teamName} 
                                          {game.winner === game.awayTeam.id && (<WinText right={true}>승</WinText>)}
                                        </TeamName>  
                                       
                                      </>  
                                    ):(
                                      <>
                                      <EmptyAvatar/>
                                      <TeamName>?</TeamName>
                                      </>  
                                    )}
                                  </GameColumn>
                                 
                                </GameInfoWrap>
                              </ScheduleInfo>
                              <ScheduleResult mobile={matches}>
                                <GameNameBox>{game.gameName} <RoundArrow size={20} fill={"#5e80ec"} /></GameNameBox>
                                {(game.gameStatus === "COMPLETE") && (
                                  <GameComplteBadge>경기 종료</GameComplteBadge>
                                )}
                              </ScheduleResult>
                            </HistoryList>
                          </Link>
                        );
                      } else {
                        return (
                            <HistoryList key={game.id} background={"#fff"}>
                              <ScheduleDate>
                                  {_gameDateText(game.gameDate)}{" "}
                                  {_gameWeekText(game.gameDate)}{" "}
                                  <Time>{_gameTimeText(game.startTime)}</Time>
                                  <span>/</span>
                                  <span>{game.place.placeName}</span>
                              </ScheduleDate>
  
                              <ScheduleInfo>
                                <GameInfoWrap>
                                  <GameColumn>
                                    {game.homeTeam? (
                                      <>
                                        <Avatar url={game.homeTeam.logo} size={"sm"} />
                                        <TeamName>
                                          {game.homeTeam.teamName}
                                        </TeamName>  
                                      </>  
                                    ):(
                                      <>
                                      <EmptyAvatar/>
                                      <TeamName>?</TeamName>
                                      </> 
                                    )}
                                  </GameColumn>
                                  <GameColumn>
                                    <ColumnRow>
                                      <VSText>VS</VSText>
                                    </ColumnRow>
                                  </GameColumn>
                                  <GameColumn>
                                   {game.awayTeam? (
                                      <>
                                        <Avatar url={game.awayTeam.logo} size={"sm"} />
                                        <TeamName>
                                          {game.awayTeam.teamName}
                                        </TeamName>  
                                      </>  
                                    ):(
                                      <>
                                      <EmptyAvatar/>
                                      <TeamName>?</TeamName>
                                      </>  
                                    )}
                                  </GameColumn>
                                 
                                </GameInfoWrap>
                              </ScheduleInfo>
                              <ScheduleResult mobile={matches}>
                                <GameNameBox>{game.gameName} <RoundArrow size={20} fill={"#5e80ec"} /></GameNameBox>
                              </ScheduleResult>
                            </HistoryList>
                        );
                      }

                      
                    })}
                  </div>
                );
              })}
          </HistoryWrap>
        </ContentWrapper>
      </Wrapper>
    );
  }
  
}
