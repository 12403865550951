import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { ADD_ROOM } from "./RoomCreateQueries";
import RoomCreatePresender from "./RoomCreatePresender";
import { useMutation } from "react-apollo-hooks";
import { toast } from "../../Components/Popup";
import moment from "moment";

import useInput from "../../Hooks/useInput";
import axios from "axios";


const RoomCreateContainer = ({ history }) => {
  const sportId = localStorage.getItem("sport");
  const [uploading, setUpLoading] = useState(false);

  // For Game Info
  /*=================================================== */

  const gameDate = useInput(moment().format("YYYY-MM-DD"));
  const startTime = useInput("06:30");
  const endTime = useInput("07:30");
  
  const title = useInput("");
  const message = useInput("", newValue => newValue.length < 300);
  const contact = useInput("", newValue=> /^[0-9-]{1,20}$/g.test(newValue), false, true);
  const purpose = useInput("코칭 + 게임 진행");
  const price = useInput("", (newValue) => !isNaN(newValue), true);
  const playerCnt = useInput("", (newValue) => !isNaN(newValue));

  const [latS, setLat] = useState();
  const [lngS, setLng] = useState();
  const [placeNameS, setPlaceName] = useState();
  const [addressS, setAddress] = useState();
  const [shortAddressS, setShortAddress] = useState();

  const [pictures, setPictures] = useState([]);
  /*=================================================== */

  const addRoomMutation = useMutation(ADD_ROOM);

  const handlePictureChange = e => {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    if(file) {
      reader.onloadend = () => {
        file['url'] = reader.result;
        const data = [...pictures];
        data.push(file);
        setPictures(data);
        //setPreviewUrl(reader.result);
      };
  
      reader.readAsDataURL(file);
    }
  };

  const onRemovePicture = removeFile => e => {
    e.preventDefault();

    const data = [...pictures];
    data.splice(
      data.findIndex(i => i.name === removeFile.name),
      1
    );
    setPictures(data);
  };


  const setAddressInfo = (placeName, address, shortAddress, lat, lng) => {
    setLat(parseFloat(lat));
    setLng(parseFloat(lng));
    setPlaceName(placeName);
    setAddress(address);
    setShortAddress(shortAddress);
  };

  const handleSubmit = async () => {

    if (title.value === "" || message.value === "") {
      toast.error("오픈방 소개를 자세히 입력해주세요.");
      return;
    } else if (pictures.length < 3) {
      toast.error("전체, 골대, 바닥 최소 3개의 사진을 업로드해주세요.");
      return;
    } else if (price === "") {
      toast.error("참가비를 입력해주세요.");
      return;
    } else if (playerCnt === "") {
      toast.error("모집 인원을 입력해주세요.");
      return;
    }

    try {
      setUpLoading(true);

      let PicturesURL;

      if (pictures && pictures.length > 0) {
        const formData = new FormData();
        pictures.forEach(file => {
          formData.append("room", file);
        });

        const { data } = await axios.post(
          "https://weplay-server.herokuapp.com/api/room/upload",
          formData,
          {
            headers: {
              "content-type": "multipart/form-data"
            }
          }
        );

        PicturesURL = data;
      }


      const {
        data: { addRoom }
      } = await addRoomMutation({
        variables: {
          sportId,
          pictures:PicturesURL,
          playerCnt:parseInt(playerCnt.value),
          gameDate:moment(gameDate.value).format("YYYY-MM-DDT00:00"),
          startTime:startTime.value,
          endTime:endTime.value,
          title:title.value,
          message:message.value,
          contact:contact.value,
          price:price.value,
          purpose:purpose.value,
          placeName:placeNameS?placeNameS:"장소이름 없음",
          address:addressS,
          shortAddress:shortAddressS,
          lat:latS,
          lng:lngS
        }
      });

      if (addRoom.id) {
        toast.success("오픈방을 생성하였습니다.");
        setTimeout(()=> {
          history.replace(`/room/${addRoom.id}`);
        }, 1000)
        
      }
    } catch (e) {
      toast.error("실패했습니다. 다시 시도해주세요.");
    } finally {
      setUpLoading(false);
    }

  };

  const onClickClose = () => {
    history.goBack();
  };

  return (
    <RoomCreatePresender
      uploading={uploading}

      gameDate={gameDate}
      startTime={startTime}
      endTime={endTime}
      title={title}
      message={message}
      contact={contact}
      purpose={purpose}
      price={price}
      playerCnt={playerCnt}

      onSubmit={handleSubmit}
      onClickClose={onClickClose}
      setAddressInfo={setAddressInfo}

      pictures={pictures}
      handlePictureChange={handlePictureChange}
      onRemovePicture={onRemovePicture}
    />
  );
};

export default withRouter(RoomCreateContainer);
