import React, { useEffect } from "react";
import styled from "styled-components";
import { Helmet } from "rl-react-helmet";
import Loader from "../../Components/Loader";
import Avatar from "../../Components/Avatar";

import { Arrow } from "../../Components/Icons";

import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";

import IconButton from "@material-ui/core/IconButton";
import Badge from "@material-ui/core/Badge";
import BaseHeader from "../../Components/Header/BaseHeader";
import moment from "moment";
import "moment/locale/ko";
import Client from "../../Apollo/Client";
import { Dialog } from "@material-ui/core";
//import Chat from "../Chat";
import PullToRefresh from "rmc-pull-to-refresh";
import RefreshLoader from "../../Components/RefreshLoader";
import DedicateLoader from "../../Components/DedicateLoader";

const Wrapper = styled.div`
  background-color: #fff;
  min-height: 100vh;
`;

const ContentWrapper = styled.div`
  /* padding-top: 64px; */
  margin: auto 10px;
  background-color: #fff;
  /* min-height: 100vh; */
  display: flex;
  flex-direction: column;
  color: #000;
  max-width: 768px;
  margin: 0 auto;
`;

const Container = styled.div`
  margin-bottom: 15px;
`;
const TitleWrap = styled.div`
  flex: 1 1 auto;
  min-width: 0;
  margin-top: 6px;
  margin-bottom: 6px;
`;

const TeamName = styled.span`
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  padding-bottom: 7px;
  position: relative;
`;

const Title = styled.p`
  color: rgba(0, 0, 0, 0.54);
  font-size: 0.775rem;
  font-family: "NanumSqure";
  font-weight: 400;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: hidden;
`;

const EmptySearchResult = styled.div`
  margin: 0px 15px;
  padding: 15px 0px;
  color: #969696;
  font-size: 14px;
  border-bottom: 1px solid #d5d5d5;
`;

const NotificationEditWrapper = styled.div`
  background-color: #f2f2f2;
  border-radius: 5px;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  height: 40px;
  align-items: center;
  width: 200px;
`;
const NotificationEditButton = styled.button`
  background-color: #f2f2f2;
  border-radius: 5px;
  border: 1px solid #f2f2f2;
  height: 100%;
  font-size: 16px;
  width: 100%;
  user-select: none;
`;

const useStyles = makeStyles(theme => ({
  badge: {
    "& > .MuiBadge-anchorOriginTopRightRectangle": {
      right: "-10px"
    }
  },
  titleWrapper: {
    paddingRight: 0,
    "& > .MuiListItem-gutters": {
      paddingRight: 0
    }
  }
}));

export default ({
  loading,
  data,
  chats,
  setChats,

  setSelectedChat,
  LongPress,
  openDelete,
  handleCloseDelete,
  handleDeleteChat,

  searchUsers,
  search,
  searching,

  onRefresh,
  refreshing,
  setRefreshing,
  loadMore,
  canMore,
  onScroll
}) => {
  if (loading) {
    return (
      <Wrapper>
        <BaseHeader text={"메시지"} isBack={true} isClose={false} />
        <Helmet>
          <title>메시지 | WePlay</title>
        </Helmet>
        <Loader />
        <ContentWrapper bgColor={"#fff"}>
          <List></List>
        </ContentWrapper>
      </Wrapper>
    );
  } else if (!loading && data && data.seeChats) {
    const classes = useStyles();

    useEffect(() => {
      setChats(data.seeChats);
    }, [data]);

    const handleEnter = chat => () => {
      //console.log(chat);
      const datas = [...chats];
      chat.unreadCount = 0;
      datas[chats.findIndex(i => i.id === chat.id)] = chat;
      setChats(datas);

      Client.writeData({
        id: `Chat:${chat.id}`,
        data: {
          unreadCount: 0
        }
      });
    };

    return (
      <Wrapper>
        <BaseHeader text={"메시지"} isBack={true} isClose={false} />

        <Helmet>
          <title>메시지 | WePlay</title>
        </Helmet>

        <PullToRefresh
          style={{ overflow: "auto", paddingTop:56, minHeight:"100vh", maxHeight:"100vh" }}
          direction="down"
          damping={100}
          onRefresh={() => onRefresh()}
          refreshing={refreshing}
          onScroll={e => onScroll(e)}
          indicator={{
            activate: <DedicateLoader/>,
            deactivate: <></>,
            release: <RefreshLoader />,
            finish: <></>
          }}
        >
          <ContentWrapper bgColor={"#fff"}>
            {chats.length === 0 && (
              <EmptySearchResult>대화 목록이 없습니다.</EmptySearchResult>
            )}

            <List>
              <Container>
                {chats.length > 0 &&
                  chats.map(chat => (
                    <Link
                      key={chat.id}
                      onClick={handleEnter(chat)}
                      to={{
                        pathname: `/chat/${chat.id}`,
                        state: {
                          id: chat.oppId.id
                          //messages: chat.messages
                        }
                      }}
                    >
                      <ListItem
                        key={chat.id}
                        button
                        onMouseDown={() => {
                          setSelectedChat(chat.id);
                          LongPress.onMouseDown();
                        }}
                        onMouseUp={() => {
                          LongPress.onMouseUp();
                        }}
                        onMouseLeave={() => {
                          LongPress.onMouseLeave();
                        }}
                        onTouchStart={() => {
                          setSelectedChat(chat.id);
                          LongPress.onTouchStart();
                        }}
                        onTouchEnd={() => {
                          LongPress.onTouchEnd();
                        }}
                        className={classes.titleWrapper}
                      >
                        <ListItemAvatar>
                          <Avatar size={"sm"} url={chat.oppId.avatar} />
                        </ListItemAvatar>

                        <TitleWrap>
                          <Badge
                            className={classes.badge}
                            badgeContent={chat.unreadCount}
                            max={10}
                            color="secondary"
                          >
                            <TeamName>
                              {`${chat.oppId.username}님과의 대화`}
                            </TeamName>
                          </Badge>
                          <Title>{`${moment(
                            chat.lastUpdated ? chat.lastUpdated : chat.updatedAt
                          ).format("YYYY.MM.DD Ahh:mm")}`}</Title>
                        </TitleWrap>

                        <ListItemSecondaryAction>
                          <IconButton
                            edge="end"
                            color="inherit"
                            aria-label="detail"
                          >
                            <Arrow />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                    </Link>
                  ))}
              </Container>
            </List>
          </ContentWrapper>
        </PullToRefresh>

        <Dialog
          className={classes.inviteDialog}
          open={openDelete}
          onClose={handleCloseDelete}
        >
          <NotificationEditWrapper>
            <NotificationEditButton onClick={handleDeleteChat}>
              삭제하기
            </NotificationEditButton>
          </NotificationEditWrapper>
        </Dialog>
      </Wrapper>
    );
  }
  return null;
};
