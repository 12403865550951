import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { fade, makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import { Backspace, Menu, Close } from "../Icons";
import MyDrawer from "../MyDrawer";
//import ArrowBackIosRoundedIcon from "@material-ui/icons/ArrowBackIosRounded";
import { withRouter, Link } from "react-router-dom";
import { ME } from "../../SharedQueries";
import { useQuery } from "react-apollo-hooks";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    zIndex: "99",
    position: "fixed",
    flexGrow: 1,
    top:0
    // maxWidth: "500px"
  },
  menuButton: {
    marginRight: theme.spacing(-2),
    marginLeft: theme.spacing(1) + 4
  },
  title: {
    flexGrow: 1,
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block"
    }
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25)
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto"
    }
  },
  searchIcon: {
    width: theme.spacing(7),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  inputRoot: {
    color: "inherit"
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: 120,
      "&:focus": {
        width: 200
      }
    }
  }
}));

const MyAppBar = styled(AppBar)`
  background-color: #227fff !important;
`;

const Title = styled.span`
  color: white;
  font-size: 24px;
  flex: 1;
  margin-left: ${props => (props.iconCount === 1? "-22px":"16px")};
  text-align: ${props => (props.isCenter ? "center" : "left")};
  font-weight: 600;
  /* position: absolute;
  width: 100%; */
`;

function BaseHeader({
  text,
  isCenter = true,
  isBack = false,
  isMenu = false,
  isClose = false,
  onClickBack,
  onClickClose,
  history
}) {
  const { data, loading } = useQuery(ME, {
    pollInterval:2000
  });

  //console.log(data);
  // console.log(history);

  // if(!loading && data && data.me && !data.me.isRegistered) {
  //   const logOut = useMutation(LOG_OUT);
  //   logOut();
  // }
  
  const classes = useStyles();
  const [state, setState] = useState({ right: false });
  const [passData, setPassData] = useState({});

  const iconCount = [isBack, isMenu, isClose].filter(x => x === true);

  const toggleDrawer = (side, open) => event => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [side]: open });
  };

  useEffect(() => {
    setPassData(data);

    if(window.Android && data && data.me) {
      console.log("ME : ", data.me.id);
      if(typeof window.Android.passUserId === "function") {
        window.Android.passUserId(data.me.id);
      }
      
      if(typeof window.Android.passMeInfo === "function") {
        const passInfo = {
          teamId:data.me.myTeam? data.me.myTeam.id : "",
          reservationCount:data.me.reservationCount,
          notificationCount:data.me.notificationCount
        };
        window.Android.passMeInfo(JSON.stringify(passInfo));
      }
    }

    if(window.webkit && data && data.me) {
      try {
        window.webkit.messageHandlers.callbackHandler.postMessage("MessageBODY");
        //window.webkit.messageHandlers.postUser.postMessage("userId");
        if(typeof window.webkit.messageHandlers.postUser.postMessage === "function") {
          window.webkit.messageHandlers.postUser.postMessage({
            userId:data.me.id,
            teamId:data.me.myTeam? data.me.myTeam.id : "",
          });
        }
      } catch(e) {
        alert(e);
        console.log(e);
      }
    }
  }, [loading, data]);

  const defaultHandleBack = () => {
    history.goBack();
  }

  return (
    <div className={classes.root}>
      <MyAppBar position="static">
        <Toolbar className={classes.toolBar}>
          {isMenu && (
            <MyDrawer
              data={passData}
              right={state.right}
              toggleDrawer={toggleDrawer}
            />
          )}
          {isBack &&
            (onClickBack ? (
              <IconButton color="inherit" edge="start" onClick={onClickBack}>
                <Backspace />
              </IconButton>
            ) : (
                <IconButton color="inherit" edge="start" onClick={defaultHandleBack}>
                  <Backspace />
                </IconButton>
            ))}

          {isClose &&
          (onClickClose ? (
            <IconButton onClick={onClickClose} edge="start" color="inherit">
              <Close />
            </IconButton>
          ) : (
            <Link to={"/"}>
              <IconButton edge="start" color="inherit">
                <Close />
              </IconButton>
            </Link>
          ))}

          <Title iconCount={iconCount.length} isCenter={isCenter}>{text}</Title>

          {isMenu && (
            <IconButton
              edge="end"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer("right", true)}
            >
              <Menu />
            </IconButton>
          )}
        </Toolbar>
      </MyAppBar>
    </div>
  );
  // if (loading) {
  //   return null;
  // } else if (!loading && data && data.me) {

  // }
}


export default withRouter(BaseHeader);