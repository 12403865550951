import { gql } from "apollo-boost";

export const GET_USER = gql`
  query seeUser($id: String!) {
    seeUser(id: $id) {
      id
      username
      avatar
      regions
      itsMe
      age
      contact
      isInviteToUser
      isSameTeam
      basket {
        id
        weight
        height
        position
        level
        grade
        regions
        backNo
      }
      isSomeHost
      myTeam {
        id
        teamName
      }
      ourChat {
        id
      }
    }
    me {
      id
      isSomeHost
      myHostTeam {
        id
        teamName
      }
    }
  }
`;

export const EDIT_PROFILE = gql`
  mutation editUser($username: String!, $avatar: String!, $age: String, $contact:String) {
    editUser(username: $username, avatar: $avatar, age: $age, contact: $contact) {
      id
      username
      age
      avatar
      contact
    }
  }
`;

export const UPSERT_BASKET = gql`
  mutation upsertBasket(
    $id: String!
    $userId: String!
    $position: String
    $height: Int
    $weight: Int
    $backNo: Int
    $level: Int
    $grade: String
    $regions: [String]
  ) {
    upsertBasket(
      id: $id
      userId: $userId
      position: $position
      height: $height
      weight: $weight
      backNo: $backNo
      level: $level
      grade: $grade
      regions: $regions
    ) {
      id
      position
      height
      weight
      backNo
      regions
    }
  }
`;

export const ACCEPT_TEAM = gql`
  mutation acceptTeam($id: String!, $reqUserId: String!) {
    acceptTeam(id: $id, reqUserId: $reqUserId)
  }
`;
export const DECLINE_TEAM = gql`
  mutation declineTeam($id: String!, $reqUserId: String!) {
    declineTeam(id: $id, reqUserId: $reqUserId)
  }
`;

export const INVITE_USER = gql`
  mutation inviteUser($id: String!, $userId: String!) {
    inviteUser(id: $id, userId: $userId)
  }
`;

export const FIND_OR_CREATE_CHAT = gql`
  query findOrCreateChat($userId: String!) {
    findOrCreateChat(userId: $userId) {
      id
    }
  }
`;
