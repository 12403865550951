import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import {
  SEE_TEAMS,
} from "./CupScheduleListQueries";
import CupTeamListPresenter from "./CupScheduleListPresenter";
import { useQuery } from "react-apollo-hooks";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { toast } from "../../Components/Popup";



export default withRouter(({ match: { params }, history }) => {
  const cupId = params.cup;
  
  const [first] = useState(10);
  const [skip] = useState(0);
  const [loadMore, setLoadMore] = useState(false);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("xs"));
  const { data, loading, fetchMore } = useQuery(SEE_TEAMS, {
    variables: {
      first: first,
      skip: skip,
      cupId
    }
  });

  const [games, setGames] = useState([]);

  const onChangePage = () => {
    setLoadMore(true);
    fetchMore({
      variables: {
        skip: data.seeCupGames.length
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        setLoadMore(false);
        if(fetchMoreResult.seeCupGames.length === 0)
          toast.error("조회할 데이터가 없습니다.");
        else
        setGames({...games, data:[...games.data, ...fetchMoreResult.seeCupGames]});

        if (!fetchMoreResult) return prev;

        return Object.assign({}, prev, {
          seeCupGames: [...prev.seeCupGames, ...fetchMoreResult.seeCupGames]
        });
      }
    });
  };


  const onCreatePage = () => {
    history.push(`/cup/${cupId}/game/create`);
  };

  const onUpdatePage = id => {
    history.push(`/game/${id}`);
  };

  return (
    <CupTeamListPresenter
      cupId={cupId}
      loading={loading}
      data={data}
      loadMore={loadMore}
      onCreatePage={onCreatePage}
      onChangePage={onChangePage}
      games={games}
      setGames={setGames}
      matches={matches}
      onUpdatePage={onUpdatePage}
    />
  );
});

