import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import {
  SEE_NOTIFICATIONS,
  DELETE_NOTIFICATION
} from "./NotificationsQueries";
import NotificationsPresenter from "./NotificationsPresenter";
import { useQuery, useMutation } from "react-apollo-hooks";
import useLongPress from '../../Hooks/useLongPress';


const NotificationsContainer = ({ history }) => {
  const [first] = useState(15);
  const [skip] = useState(0);
  const [notifications, setNotifications] = useState([]);
  const [loadMore, setLoadMore] = useState(false);
  const [canMore, setCanMore] = useState(true);
  const [openDelete, setOpenDelete] = useState(false);
  const [selectedNoti, setSelectedNoti] = useState();
  const [refreshing, setRefreshing] = useState();

  const deleteNotificationMutation = useMutation(DELETE_NOTIFICATION);

  const handleOpenDelete = () => {
    setOpenDelete(true);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
    setSelectedNoti(null);
  };

  const LongPress = useLongPress(handleOpenDelete, 1500);

  const { data, loading, fetchMore } = useQuery(SEE_NOTIFICATIONS, {
    //fetchPolicy: "network-only",
    //pollInterval:1000,
    variables: {
      skip: skip,
      first: first
    }
  });

  const handleDeleteNotification = async () => {
    const _data = [...notifications];
    _data.splice(
      _data.findIndex(i => i.id === selectedNoti),
      1
    );
    setNotifications(_data);
    handleCloseDelete();

    try {
      await deleteNotificationMutation({
        variables: {
          id: selectedNoti
        }
      });
    } catch (e) {
      console.log(e);
    }
  };

  const onRefresh = () => {
    //console.log(currentTarget);
    setRefreshing(true);
    fetchMore({
      variables: {
        skip: 0,
        first:notifications.length
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        setRefreshing(false);
        if (!fetchMoreResult) return prev;

        //console.log(fetchMoreResult.seeNotifications);
        
        setNotifications([...fetchMoreResult.seeNotifications])

        return Object.assign({}, prev, {
          seeNotifications: [...fetchMoreResult.seeNotifications]
        });
      }
    });
  };

  const onScroll = ({ currentTarget }) => {
    //console.log(currentTarget);
    if (
      canMore &&
      !loadMore &&
      currentTarget.scrollTop + currentTarget.clientHeight >=
        currentTarget.scrollHeight - 100
    ) {
      setLoadMore(true);
      fetchMore({
        variables: {
          skip: data.seeNotifications.length
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          setLoadMore(false);

          if (!fetchMoreResult) return prev;

          if (fetchMoreResult.seeNotifications.length === 0) {
            setCanMore(false);
          } else {
            
            const mergeData = Array.from(new Set([...prev.seeNotifications, ...fetchMoreResult.seeNotifications]));

            setNotifications(mergeData)
          }

          return Object.assign({}, prev, {
            seeNotifications: [
              ...prev.seeNotifications,
              ...fetchMoreResult.seeNotifications
            ]
          });
        }
      });
    }
  };

  const onGoHome = () => {
    history.replace('/');
  }

  return (
    <NotificationsPresenter
      loading={loading}
      data={data}
      onRefresh={onRefresh}
      refreshing={refreshing}
      setRefreshing={setRefreshing}
      onGoHome={onGoHome}
      notifications={notifications}
      setNotifications={setNotifications}
      loadMore={loadMore}
      canMore={canMore}
      onScroll={onScroll}
      setSelectedNoti={setSelectedNoti}
      LongPress={LongPress}
      openDelete={openDelete}
      handleCloseDelete={handleCloseDelete}
      handleDeleteNotification={handleDeleteNotification}
    />
  );
};

export default withRouter(NotificationsContainer);
