import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import {
  SEE_TEAMS
} from "./TeamSelectQueries";
import TeamSelectPresenter from "./TeamSelectPresenter";
import { useQuery } from "react-apollo-hooks";

const TeamSelectContainer = ({match: { params}, history }) => {
  const type = params.type;
  const { data, loading } = useQuery(SEE_TEAMS);

  const [teams, setTeams] = useState([]);
  const [voteGame, setVoteGame] = useState();
  const [voteTeam, setVoteTeam] = useState();

  const handleTeamSaveToLocal = (id, router)=> () => {
    localStorage.setItem('team',id);
    history.push(router);
  }

  return (
    <TeamSelectPresenter
      loading={loading}
      data={data}
      teams={teams}
      setTeams={setTeams}
      
      type={type}

      voteGame={voteGame}
      setVoteGame={setVoteGame}
      voteTeam={voteTeam}
      setVoteTeam={setVoteTeam}
      handleTeamSaveToLocal={handleTeamSaveToLocal}
      //onSubmit={onSubmit}
    />
  );
};

export default withRouter(TeamSelectContainer);
