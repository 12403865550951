import { gql } from "apollo-boost";

export const GET_BANNERS = gql`
  {
    seeBanners {
      id
      link
      url
    }
  }
`;


export const GAMELIST = gql`
  query seeGames($sport: String, $status: String, $lat: Float, $lng: Float) {
    seeGames(sport: $sport, status: $status, lat: $lat, lng: $lng) {
      id
      homeTeam {
        id
        level
        teamName
        logo
      }
      awayTeam {
        id
        teamName
      }
      place {
        id
        placeName
        shortAddress
      }
      playerCnt
      gameStatus
      gameDate
      startTime
      createdAt
    }

    seeLevelTeams {
      id
      userCount
      logo
      host {
        id
        username
      }
      teamName
      place {
        id
        shortAddress
      }
      userCount
      bio
      level
      createdAt
    }

    seeRooms {
      id
      picture {
        id
        url
      }
      host {
        id
        username
      }
      place {
        id
        shortAddress
      }
      price
      purpose
      requestCount
      userCount
      playerCnt
      gameDate
      startTime
      gameTitle
      gameBio
      createdAt
    }
  }
`;

export const MATCH_GAMELIST = gql`
  query seeGames(
    $sport: String
    $status: String
    $lat: Float
    $lng: Float
    $first: Int
    $skip: Int
    $order: String
  ) {
    seeGames(
      sport: $sport
      status: $status
      lat: $lat
      lng: $lng
      first: $first
      skip: $skip
      order: $order
    ) {
      id
      homeTeam {
        id
        level
        teamName
        logo
      }
      awayTeam {
        id
        teamName
      }
      place {
        id
        placeName
        shortAddress
      }
      playerCnt
      gameStatus
      gameDate
      startTime
      createdAt
    }
  }
`;

export const TEAMLIST = gql`
  query seeLevelGames($first: Int, $skip: Int, $status:String, $sport: String) {
    seeLevelGames( sport: $sport
      first: $first
      skip: $skip
      status:$status) {
        id
        homeTeam {
          id
          level
          teamName
          logo
        }
        place {
          id
          placeName
          shortAddress
        }
        distance
        playerCnt
        gameStatus
        gameDate
        startTime
        createdAt
    }
  }
`;

export const NEARBY_GAMELIST = gql`
  query seeGamesNearby(
    $sport: String
    $first: Int
    $skip: Int
    $status:String
  ) {
    seeGamesNearby(
      sport: $sport
      first: $first
      skip: $skip
      status:$status
    ) {
      id
      homeTeam {
        id
        level
        teamName
        logo
      }
      place {
        id
        placeName
        shortAddress
      }
      distance
      playerCnt
      gameStatus
      gameDate
      startTime
      createdAt
    }
  }
`;

export const ROOMLIST = gql`
  query seeRooms($status: String, $first: Int, $skip: Int) {
    seeRooms(status: $status, first: $first, skip: $skip) {
      id
      picture {
        id
        url
      }
      host {
        id
        username
      }
      place {
        id
        shortAddress
      }
      price
      purpose
      requestCount
      userCount
      playerCnt
      gameDate
      startTime
      gameTitle
      gameBio
      createdAt
    }
  }
`;


export const NEW_MATCH = gql`
  subscription {
    newMatchGame {
      id
      homeTeam {
        id
        level
        teamName
        logo
      }
      awayTeam {
        id
        teamName
      }
    }
  }
`;

export const NEW_GAME = gql`
  subscription {
    newGame {
      id
      homeTeam {
        id
        level
        teamName
        logo
      }
      place {
        id
        placeName
        shortAddress
      }
      playerCnt
      gameStatus
      gameDate
      startTime
    }
  }
`;