import React from "react";
import styled from "styled-components";
import { Helmet } from "rl-react-helmet";
import BaseHeader from "../../Components/Header/BaseHeader";

import Button from "../../Components/Button";
import Input from "../../Components/Input";
import CircularProgress from "@material-ui/core/CircularProgress";

const Wrapper = styled.div`
  width: 100%;
  flex-grow: 1;
  display: flex;
`;

const UserInfoRows = styled.div`
  flex-grow: 1;
  margin: 0px 10px;
  background-color: #fff;
  color:#000;
  min-height: 55vh;
  padding-top: 64px;
  padding-bottom: 70px;
`;

const UserInfoRow = styled.div`
  display: flex;
  font-size: 15px;

  flex-direction: column;
  padding: 15px 20px;
  text-align: center;
`;

const InputInfoColumn = styled.div`
  &:nth-child(1) {
    margin-bottom: 15px;
    font-weight:500;
    font-size:16px;
  }
`;

const TitleRow = styled.div`
   margin-bottom: 15px;
   font-weight:500;
    font-size:16px;
`;



const BioTextarea = styled.textarea`
  width:100%;
  max-width:500px;
  padding: 5px 15px;
  background-color: #EBEBEB;
  border: ${props => props.theme.boxBorder};
  border-radius: 10px;
  color : #000;
  resize:none;
  font-size:16px;
  font-family: 'NanumSqure';
  height: 300px;
  &:focus {
    background-color: #fff;
    border:1px solid #2b2b2b;
  }
`;


const Form = styled.div`
    display: flex;
    flex: 1;
`;

const BottomWrap = styled.div`
  display: flex;
  justify-content: center;
`;

export default ({
  uploading,
  isSendMatch,
  title,
  message,
  onSendMatch,
  onBack
}) => 
 (
      <Wrapper>
        <Helmet>
          <title>도전장 | WePlay</title>
        </Helmet>
        <BaseHeader
          isClose={false}
          isBack={true}
          text={"도전장 작성"}
          onClickBack={onBack}
        />
        <Form>
          <UserInfoRows>
            <UserInfoRow>
              {/* <InputInfoColumn><BoldText text={"경기 소개"} /><ProfileHint>*</ProfileHint></InputInfoColumn> */}
              <TitleRow>
                <Input value={title.value} defaultValue={""} onChange={title.onChange} placeholder={"제목을 입력하세요"} />
              </TitleRow>

              <InputInfoColumn>
                <BioTextarea placeholder="내용을 입력하세요" {...message} cols="4" rows="6"></BioTextarea>
              </InputInfoColumn>
            </UserInfoRow>

            {uploading && <CircularProgress />}
              {!isSendMatch && <Button color={"#000"} position={"relative"} onClick={onSendMatch} text={"보내기"} />}
            <BottomWrap>
             
            </BottomWrap>
          </UserInfoRows>
        </Form>
      </Wrapper>
  )

