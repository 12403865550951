import React from 'react';



export const Search = ({ size = 24, fill="#fff"}) => (
  <svg 
  width={size}
  height={size}
  viewBox="0 0 24 24"
  xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd">
    <path fill={fill} d="M15.853 16.56c-1.683 1.517-3.911 2.44-6.353 2.44-5.243 0-9.5-4.257-9.5-9.5s4.257-9.5 9.5-9.5 9.5 4.257 9.5 9.5c0 2.442-.923 4.67-2.44 6.353l7.44 7.44-.707.707-7.44-7.44zm-6.353-15.56c4.691 0 8.5 3.809 8.5 8.5s-3.809 8.5-8.5 8.5-8.5-3.809-8.5-8.5 3.809-8.5 8.5-8.5z"/></svg>
  );

export const Crown = ({ size = 24, fill="#fff" }) => (
  <svg 
  width={size}
  height={size}
  xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd">
    <path fill={fill} d="M12 1c-1.562 0-2.833 1.271-2.833 2.833 0 1.053.565 1.759 1.103 2.263-.489 2.814-1.849 3.154-2.853 3.154-1.14 0-1.82-.69-2.473-1.669.389-.522.732-1.13.732-1.941 0-1.548-1.275-2.807-2.843-2.807-1.562 0-2.833 1.271-2.833 2.834 0 1.239.734 2.236 2 2.773v14.56h20v-14.568c1.721-.746 2-2.019 2-2.765 0-1.563-1.271-2.834-2.833-2.834-1.567 0-2.843 1.259-2.843 2.807 0 .778.328 1.411.721 1.92-.707 1.178-1.465 1.69-2.462 1.69-.59 0-2.328 0-2.85-3.157.639-.607 1.341-1.513 1.029-2.885-.297-1.3-1.433-2.208-2.762-2.208zm9 21v-3h-18v3h18zm0-4v-10.273c1.098-.31 2-.925 2-2.06 0-1.012-.821-1.834-1.833-1.834-1.004 0-1.843.824-1.843 1.807 0 .711.433 1.273.934 1.787-.798 1.585-1.88 2.823-3.675 2.823-2.47 0-3.613-1.888-3.913-4.548.77-.664 1.345-1.272 1.117-2.271-.18-.785-.882-1.431-1.787-1.431-1.012 0-1.833.821-1.833 1.833 0 .824.541 1.336 1.171 1.87-.327 2.654-1.43 4.547-3.921 4.547-1.947 0-2.908-1.443-3.697-2.733.483-.6.956-1.13.956-1.877 0-.983-.839-1.807-1.843-1.807-1.012 0-1.833.822-1.833 1.834 0 1.115.909 1.785 2 2.052v10.281h18z"/></svg>
  );

export const Menu = ({ size = 24, fill="#fff"}) => (
  <svg 
  width={size}
  height={size}
  xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd">
    <path fill={fill} d="M24 18v1h-24v-1h24zm0-6v1h-24v-1h24zm0-6v1h-24v-1h24z" />
    <path  fill={fill} d="M24 19h-24v-1h24v1zm0-6h-24v-1h24v1zm0-6h-24v-1h24v1z"/></svg>
);

export const Refresh = ({ size = 24, fill="#fff"}) => (
<svg
  width={size}
  height={size}
   xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd" viewBox="0 0 24 24"><path fill={fill} d="M7 9h-7v-7h1v5.2c1.853-4.237 6.083-7.2 11-7.2 6.623 0 12 5.377 12 12s-5.377 12-12 12c-6.286 0-11.45-4.844-11.959-11h1.004c.506 5.603 5.221 10 10.955 10 6.071 0 11-4.929 11-11s-4.929-11-11-11c-4.66 0-8.647 2.904-10.249 7h5.249v1z"/></svg>
);

export const Alert = ({ size = 24, fill="#fff"}) => (
  <svg 
  width={size}
  height={size}
  xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd">
    <path fill={fill} d="M1 20v-2.946c1.993-.656 2.575-2.158 3.668-6.077.897-3.218 1.891-6.784 4.873-8.023-.027-.147-.041-.299-.041-.454 0-1.38 1.12-2.5 2.5-2.5s2.5 1.12 2.5 2.5c0 .156-.014.309-.042.458 2.987 1.244 3.984 4.813 4.884 8.033 1.103 3.95 1.697 5.423 3.658 6.062v2.947h-7c0 2.208-1.792 4-4 4s-4-1.792-4-4h-7zm14 0h-6c0 1.656 1.344 3 3 3s3-1.344 3-3zm-13-1h20v-1.241c-2.062-.918-2.82-3.633-3.621-6.498-1.066-3.814-2.167-7.758-6.379-7.761-4.21 0-5.308 3.937-6.369 7.745-.8 2.872-1.559 5.593-3.631 6.514v1.241zm11.492-16.345l.008-.155c0-.828-.672-1.5-1.5-1.5s-1.5.672-1.5 1.5l.008.152c.455-.099.949-.152 1.487-.152.542 0 1.039.054 1.497.155z"/></svg>
);



// export const GroupAdd = ({ size = 24, fill = "#fff" }) => (
//   <svg xmlns="http://www.w3.org/2000/svg" 

//   height="32px" 
//   id="svg2" 
//   version="1.1" 
//   viewBox="0 0 32 32"
//   width="32px" 
//   >
//     <g id="background">
//       <rect fill="none" height="32" width="32"/>
//       </g>
//       <g id="group_x5F_half_x5F_add">
//         <path fill={fill} d="M16,14c1.657,0,3-1.343,3-3s-1.343-3-3-3s-3,1.343-3,3S14.343,14,16,14z M24,8c1.657,0,3-1.343,3-3s-1.343-3-3-3   s-3,1.343-3,3S22.343,8,24,8z M12.023,14C11.39,13.162,11,12.131,11,11c0-0.343,0.035-0.677,0.101-1C8.968,10,4,10,4,10s-2,0-2,2   c0,0.778,0,12,0,12h6v-6c0-4,4-4,4-4H12.023z M8,8c1.657,0,3-1.343,3-3S9.657,2,8,2S5,3.343,5,5S6.343,8,8,8z M30,17.349V12   c0,0,0-2-2-2h-7.101C20.965,10.323,21,10.657,21,11c0,1.131-0.39,2.162-1.022,3H20c0.477,0,0.878,0.073,1.246,0.173   c-1.457,0.288-2.787,0.928-3.898,1.827C15.008,16,12,16,12,16s-2,0-2,2c0,0.778,0,12,0,12h7.349c1.545,1.248,3.51,1.999,5.651,2   c4.971-0.002,8.998-4.029,9-9C31.999,20.858,31.248,18.894,30,17.349z M23,29.883c-3.801-0.009-6.876-3.084-6.885-6.883   c0.009-3.801,3.084-6.876,6.885-6.885c3.799,0.009,6.874,3.084,6.883,6.885C29.874,26.799,26.799,29.874,23,29.883z"/><g>
//           <polygon points="28,22 24.002,22 24.002,18 22,18 22,22 18,22 18,24 22,24 22,28 24.002,28 24.002,24 28,24   "/>
//       </g>
//     </g>
//   </svg>
// );

export const Backspace = ({ size = 20, fill="#fff"}) => (
  <svg width={size} height={size} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd"><path fill={fill} d="M20 .755l-14.374 11.245 14.374 11.219-.619.781-15.381-12 15.391-12 .609.755z"/></svg>
);




export const AddImage = ({size = 24}) => (
  <svg xmlns="http://www.w3.org/2000/svg" 
    width={size} height={size} 
    viewBox="0 0 24 24"><path d="M19.5 12c-2.483 0-4.5 2.015-4.5 4.5s2.017 4.5 4.5 4.5 4.5-2.015 4.5-4.5-2.017-4.5-4.5-4.5zm2.5 5h-2v2h-1v-2h-2v-1h2v-2h1v2h2v1zm-18 0l4-5.96 2.48 1.96 2.52-4 1.853 2.964c-1.271 1.303-1.977 3.089-1.827 5.036h-9.026zm10.82 4h-14.82v-18h22v7.501c-.623-.261-1.297-.422-2-.476v-5.025h-18v14h11.502c.312.749.765 1.424 1.318 2zm-9.32-11c-.828 0-1.5-.671-1.5-1.5 0-.828.672-1.5 1.5-1.5s1.5.672 1.5 1.5c0 .829-.672 1.5-1.5 1.5z"/>
  </svg>
);

export const HeartEmpty = ({ size = 24, fill="#fff" }) => (
<svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
>
    <path fill={fill} d="M6.28 3c3.236.001 4.973 3.491 5.72 5.031.75-1.547 2.469-5.021 5.726-5.021 2.058 0 4.274 1.309 4.274 4.182 0 3.442-4.744 7.851-10 13-5.258-5.151-10-9.559-10-13 0-2.676 1.965-4.193 4.28-4.192zm.001-2c-3.183 0-6.281 2.187-6.281 6.192 0 4.661 5.57 9.427 12 15.808 6.43-6.381 12-11.147 12-15.808 0-4.011-3.097-6.182-6.274-6.182-2.204 0-4.446 1.042-5.726 3.238-1.285-2.206-3.522-3.248-5.719-3.248z" />
</svg>
);

export const HeartFull = ({ size = 24 }) => (
<svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="#ED4956"
>
    <path d="M12 4.248c-3.148-5.402-12-3.825-12 2.944 0 4.661 5.571 9.427 12 15.808 6.43-6.381 12-11.147 12-15.808 0-6.792-8.875-8.306-12-2.944z" />
</svg>
);

export const User = () => (
<svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
>
    <path d="M12 2c2.757 0 5 2.243 5 5.001 0 2.756-2.243 5-5 5s-5-2.244-5-5c0-2.758 2.243-5.001 5-5.001zm0-2c-3.866 0-7 3.134-7 7.001 0 3.865 3.134 7 7 7s7-3.135 7-7c0-3.867-3.134-7.001-7-7.001zm6.369 13.353c-.497.498-1.057.931-1.658 1.302 2.872 1.874 4.378 5.083 4.972 7.346h-19.387c.572-2.29 2.058-5.503 4.973-7.358-.603-.374-1.162-.811-1.658-1.312-4.258 3.072-5.611 8.506-5.611 10.669h24c0-2.142-1.44-7.557-5.631-10.647z" />
</svg>
);

export const CommentEmpty = () => (
<svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
>
    <path d="M12 3c5.514 0 10 3.476 10 7.747 0 4.272-4.48 7.748-9.986 7.748-.62 0-1.092-.046-1.759-.097-1 .776-1.774 1.403-3.485 1.962.26-1.383-.113-2.259-.514-3.259-2.383-1.505-4.256-3.411-4.256-6.354 0-4.271 4.486-7.747 10-7.747zm0-2c-6.627 0-12 4.363-12 9.747 0 3.13 1.816 5.916 4.641 7.699.867 2.167-1.084 4.008-3.143 4.502 3.085.266 6.776-.481 9.374-2.497 7.08.54 13.128-3.988 13.128-9.704 0-5.384-5.373-9.747-12-9.747z" />
</svg>
);

export const CommentFull = ({ size = 24 }) => (
<svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
>
    <path d="M12 1c-6.627 0-12 4.364-12 9.749 0 3.131 1.817 5.917 4.64 7.7.868 2.167-1.083 4.008-3.142 4.503 2.271.195 6.311-.121 9.374-2.498 7.095.538 13.128-3.997 13.128-9.705 0-5.385-5.373-9.749-12-9.749z" />
</svg>
);

export const Logo = ({ size = 24 }) => (
<svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    align="center"
    viewBox="0 0 24 24"
>
    <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
</svg>
);


export const Edit = ({size = 24, fill="#ffffff"}) => (
  <svg width={size} height={size} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd"><path fill={fill} d="M8.071 21.586l-7.071 1.414 1.414-7.071 14.929-14.929 5.657 5.657-14.929 14.929zm-.493-.921l-4.243-4.243-1.06 5.303 5.303-1.06zm9.765-18.251l-13.3 13.301 4.242 4.242 13.301-13.3-4.243-4.243z"/></svg>
);

export const Map = ({size = 24}) => (
  <svg xmlns="http://www.w3.org/2000/svg" 
  width={size}
  height={size}
  viewBox="0 0 24 24">
  <path d="M12 0c-3.148 0-6 2.553-6 5.702 0 4.682 4.783 5.177 6 12.298 1.217-7.121 6-7.616 6-12.298 0-3.149-2.851-5.702-6-5.702zm0 8c-1.105 0-2-.895-2-2s.895-2 2-2 2 .895 2 2-.895 2-2 2zm12 16l-6.707-2.427-5.293 2.427-5.581-2.427-6.419 2.427 4-9 3.96-1.584c.38.516.741 1.08 1.061 1.729l-3.523 1.41-1.725 3.88 2.672-1.01 1.506-2.687-.635 3.044 4.189 1.789.495-2.021.465 2.024 4.15-1.89-.618-3.033 1.572 2.896 2.732.989-1.739-3.978-3.581-1.415c.319-.65.681-1.215 1.062-1.731l4.021 1.588 3.936 9z"/>
  </svg>
);

export const Upload = ({size=24}) => (
  <svg 
  width={size}
  height={size}
  xmlns="http://www.w3.org/2000/svg" 
  >
  <path d="M11.5 0c6.347 0 11.5 5.153 11.5 11.5s-5.153 11.5-11.5 11.5-11.5-5.153-11.5-11.5 5.153-11.5 11.5-11.5zm0 1c5.795 0 10.5 4.705 10.5 10.5s-4.705 10.5-10.5 10.5-10.5-4.705-10.5-10.5 4.705-10.5 10.5-10.5zm.5 10h6v1h-6v6h-1v-6h-6v-1h6v-6h1v6z"/></svg>
);


export const Boxing = ({size = 24, fill="#fff"}) => (
  <svg xmlns="http://www.w3.org/2000/svg" 
  width={size}
  height={size}
  fillRule="evenodd" 
  clipRule="evenodd"
  viewBox="0 0 24 24">
    <path fill={fill} xmlns="http://www.w3.org/2000/svg" d="M20.554 9.583c-.729 1.291-1.823 2.096-3.092 3.027-1.483 1.091-3.142 2.319-4.647 4.567l-6.727-3.841c.919-2.241.7-4.73.518-6.78-.186-2.099-.259-3.417.434-3.651 1.123-.379 2.501 2.097 3.192 4.095 1.137-4.59 2.047-6.521 6.967-3.669 1.216.707 5.053 3.24 3.355 6.252m-10.173 11.692l-6.631-3.787 1.391-2.384 6.631 3.787-1.391 2.384zm7.82-19.68c-1.957-1.134-3.641-1.665-5.032-1.588-1.524.085-2.684.909-3.337 2.296-.924-1.13-2.122-1.793-3.496-1.275-2.158.814-1.937 3.302-1.723 5.706.191 2.155.408 4.598-.7 6.502l-2.913 4.991 10.11 5.773 2.907-4.984c1.429-2.435 3.055-3.631 4.63-4.789 1.068-.787 4.353-2.998 4.353-6.193 0-2.645-2.251-4.962-4.799-6.439"/>
  </svg>
);

export const Group = ({ size=24, fill="#fff"}) => (
  <svg xmlns="http://www.w3.org/2000/svg" 
  width={size}
  height={size}
  viewBox="0 0 24 24">
    <path fill={fill} d="M19.5 15c-2.483 0-4.5 2.015-4.5 4.5s2.017 4.5 4.5 4.5 4.5-2.015 4.5-4.5-2.017-4.5-4.5-4.5zm2.5 5h-2v2h-1v-2h-2v-1h2v-2h1v2h2v1zm-7.18 4h-14.815l-.005-1.241c0-2.52.199-3.975 3.178-4.663 3.365-.777 6.688-1.473 5.09-4.418-4.733-8.729-1.35-13.678 3.732-13.678 6.751 0 7.506 7.595 3.64 13.679-1.292 2.031-2.64 3.63-2.64 5.821 0 1.747.696 3.331 1.82 4.5z"/></svg>
)


export const Calender = ({ size=24, fill="#fff"}) => (
  <svg xmlns="http://www.w3.org/2000/svg" 
  width={size}
  height={size} 
  viewBox="0 0 24 24">
    <path fill={fill} d="M24 2v22h-24v-22h3v1c0 1.103.897 2 2 2s2-.897 2-2v-1h10v1c0 1.103.897 2 2 2s2-.897 2-2v-1h3zm-2 6h-20v14h20v-14zm-2-7c0-.552-.447-1-1-1s-1 .448-1 1v2c0 .552.447 1 1 1s1-.448 1-1v-2zm-14 2c0 .552-.447 1-1 1s-1-.448-1-1v-2c0-.552.447-1 1-1s1 .448 1 1v2zm1 11.729l.855-.791c1 .484 1.635.852 2.76 1.654 2.113-2.399 3.511-3.616 6.106-5.231l.279.64c-2.141 1.869-3.709 3.949-5.967 7.999-1.393-1.64-2.322-2.686-4.033-4.271z"/>
  </svg>
)


export const Message = ({ size=24, fill="#fff"}) => (
  <svg xmlns="http://www.w3.org/2000/svg" 
  width={size}
  height={size}
  viewBox="0 0 24 24">
    <path fill={fill} xmlns="http://www.w3.org/2000/svg" d="M0 3v18h24v-18h-24zm21.518 2l-9.518 7.713-9.518-7.713h19.036zm-19.518 14v-11.817l10 8.104 10-8.104v11.817h-20z"/>
  </svg>
);

export const MessageThin = ({ size=24, fill="#fff"}) => (
  <svg xmlns="http://www.w3.org/2000/svg" 
  width={size}
  height={size}
  viewBox="0 0 24 24">
    <path d="M24 21h-24v-18h24v18zm-23-16.477v15.477h22v-15.477l-10.999 10-11.001-10zm21.089-.523h-20.176l10.088 9.171 10.088-9.171z"/>
  </svg>
);

export const GroupEdit = ({ size=24, fill="#fff"}) => (
  <svg xmlns="http://www.w3.org/2000/svg" 
  // xmlns:cc="http://creativecommons.org/ns#" 
  // xmlns:dc="http://purl.org/dc/elements/1.1/" 
  // xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape" 
  // xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#" 
  // xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd" 
  // xmlns:svg="http://www.w3.org/2000/svg" 
  // xml:space="preserve"
  enableBackground="new 0 0 32 32" 
  width={size}
  height={size}
   id="svg2" 
  version="1.1" viewBox="0 0 32 32"  ><g id="background">
    <rect fill="none" height="32" width="32"/></g><g id="group_x5F_half_x5F_edit">
    <path fill={fill} d="M16,14c1.657,0,3-1.343,3-3s-1.343-3-3-3s-3,1.343-3,3S14.343,14,16,14z M24,8c1.657,0,3-1.343,3-3s-1.343-3-3-3   s-3,1.343-3,3S22.343,8,24,8z M12.023,14C11.39,13.162,11,12.131,11,11c0-0.343,0.035-0.677,0.101-1C8.968,10,4,10,4,10s-2,0-2,2   c0,0.778,0,12,0,12h6v-6c0-4,4-4,4-4H12.023z M8,8c1.657,0,3-1.343,3-3S9.657,2,8,2S5,3.343,5,5S6.343,8,8,8z M30,17.349V12   c0,0,0-2-2-2h-7.101C20.965,10.323,21,10.657,21,11c0,1.131-0.39,2.162-1.022,3H20c0.477,0,0.878,0.073,1.246,0.173   c-1.457,0.288-2.787,0.928-3.898,1.827C15.008,16,12,16,12,16s-2,0-2,2c0,0.778,0,12,0,12h7.349c1.545,1.248,3.51,1.999,5.651,2   c4.971-0.002,8.998-4.029,9-9C31.999,20.858,31.248,18.894,30,17.349z M23,29.883c-3.801-0.009-6.876-3.084-6.885-6.883   c0.009-3.801,3.084-6.876,6.885-6.885c3.799,0.009,6.874,3.084,6.883,6.885C29.874,26.799,26.799,29.874,23,29.883z"/><polygon fill={fill} points="22,27 19,27 19,24  "/><rect height="4.243" fill={fill} transform="matrix(-0.7071 0.7071 -0.7071 -0.7071 56.5269 20.5858)" width="7.071" x="20.464" y="19.879"/></g></svg>
)

export const Tools = ({ size=24, fill="#fff"}) => (
  <svg xmlns="http://www.w3.org/2000/svg" 
  width={size}
  height={size}
  viewBox="0 0 24 24">
    <path fill={fill} xmlns="http://www.w3.org/2000/svg" d="M23.269 19.743l-11.945-11.945c-.557-.557-.842-1.33-.783-2.115.115-1.485-.395-3.009-1.529-4.146-1.03-1.028-2.376-1.537-3.723-1.537-.507 0-1.015.072-1.505.216l3.17 3.17c.344 1.589-1.959 3.918-3.567 3.567l-3.169-3.17c-.145.492-.218 1-.218 1.509 0 1.347.51 2.691 1.538 3.721 1.135 1.136 2.66 1.645 4.146 1.53.783-.06 1.557.226 2.113.783l11.946 11.944c.468.468 1.102.73 1.763.73 1.368 0 2.494-1.108 2.494-2.494 0-.638-.244-1.276-.731-1.763zm-1.769 2.757c-.553 0-1-.448-1-1s.447-1 1-1c.553 0 1 .448 1 1s-.447 1-1 1zm-7.935-15.289l5.327-5.318c.584-.585 1.348-.878 2.113-.878.764 0 1.529.292 2.113.878.589.587.882 1.357.882 2.125 0 .764-.291 1.528-.873 2.11l-5.326 5.318-4.236-4.235zm-3.53 9.18l-5.227 5.185c-.227.23-.423.488-.574.774l-.301.58-2.1 1.07-.833-.834 1.025-2.146.58-.302c.286-.15.561-.329.79-.558l5.227-5.185 1.413 1.416z"/>
  </svg>
)

export const GroupAdd = ({ size=24, fill="#fff"}) => (
<svg xmlns="http://www.w3.org/2000/svg" 
// xmlns:cc="http://creativecommons.org/ns#" 
// xmlns:dc="http://purl.org/dc/elements/1.1/" 
// xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape" 
// xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#" 
// xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd" 
// xmlns:svg="http://www.w3.org/2000/svg" 
// xml:space="preserve"
enableBackground="new 0 0 32 32" 
width={size}
height={size}
id="svg2" 
version="1.1" 
viewBox="0 0 32 32" ><g id="background">
  <rect fill={"transparent"} height="32" width="32"/></g><g id="group_x5F_half_x5F_add">
  <path fill={fill} d="M16,14c1.657,0,3-1.343,3-3s-1.343-3-3-3s-3,1.343-3,3S14.343,14,16,14z M24,8c1.657,0,3-1.343,3-3s-1.343-3-3-3   s-3,1.343-3,3S22.343,8,24,8z M12.023,14C11.39,13.162,11,12.131,11,11c0-0.343,0.035-0.677,0.101-1C8.968,10,4,10,4,10s-2,0-2,2   c0,0.778,0,12,0,12h6v-6c0-4,4-4,4-4H12.023z M8,8c1.657,0,3-1.343,3-3S9.657,2,8,2S5,3.343,5,5S6.343,8,8,8z M30,17.349V12   c0,0,0-2-2-2h-7.101C20.965,10.323,21,10.657,21,11c0,1.131-0.39,2.162-1.022,3H20c0.477,0,0.878,0.073,1.246,0.173   c-1.457,0.288-2.787,0.928-3.898,1.827C15.008,16,12,16,12,16s-2,0-2,2c0,0.778,0,12,0,12h7.349c1.545,1.248,3.51,1.999,5.651,2   c4.971-0.002,8.998-4.029,9-9C31.999,20.858,31.248,18.894,30,17.349z M23,29.883c-3.801-0.009-6.876-3.084-6.885-6.883   c0.009-3.801,3.084-6.876,6.885-6.885c3.799,0.009,6.874,3.084,6.883,6.885C29.874,26.799,26.799,29.874,23,29.883z"/><g><polygon fill={fill} points="28,22 24.002,22 24.002,18 22,18 22,22 18,22 18,24 22,24 22,28 24.002,28 24.002,24 28,24   "/></g></g></svg>
)

export const Help = ({ size=24, fill="#fff"}) => (
  <svg xmlns="http://www.w3.org/2000/svg" 
  width={size}
  height={size}
  viewBox="0 0 24 24">
<path fill={fill} xmlns="http://www.w3.org/2000/svg" d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm1.25 17c0 .69-.559 1.25-1.25 1.25-.689 0-1.25-.56-1.25-1.25s.561-1.25 1.25-1.25c.691 0 1.25.56 1.25 1.25zm1.393-9.998c-.608-.616-1.515-.955-2.551-.955-2.18 0-3.59 1.55-3.59 3.95h2.011c0-1.486.829-2.013 1.538-2.013.634 0 1.307.421 1.364 1.226.062.847-.39 1.277-.962 1.821-1.412 1.343-1.438 1.993-1.432 3.468h2.005c-.013-.664.03-1.203.935-2.178.677-.73 1.519-1.638 1.536-3.022.011-.924-.284-1.719-.854-2.297z"/>
  </svg>
)

export const Info = ({ size=24, fill="#fff"}) => (
  <svg xmlns="http://www.w3.org/2000/svg" 
  width={size}
  height={size}
  viewBox="0 0 24 24">
    <path fill={fill} xmlns="http://www.w3.org/2000/svg" d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm1 18h-2v-8h2v8zm-1-12.25c.69 0 1.25.56 1.25 1.25s-.56 1.25-1.25 1.25-1.25-.56-1.25-1.25.56-1.25 1.25-1.25z"/>
  </svg>
)


export const Gear = ({ size=24, fill="#fff"}) => (
  <svg xmlns="http://www.w3.org/2000/svg" 
  width={size}
  height={size}
  viewBox="0 0 24 24">
    <path fill={fill} xmlns="http://www.w3.org/2000/svg" d="M24 14.187v-4.374c-2.148-.766-2.726-.802-3.027-1.529-.303-.729.083-1.169 1.059-3.223l-3.093-3.093c-2.026.963-2.488 1.364-3.224 1.059-.727-.302-.768-.889-1.527-3.027h-4.375c-.764 2.144-.8 2.725-1.529 3.027-.752.313-1.203-.1-3.223-1.059l-3.093 3.093c.977 2.055 1.362 2.493 1.059 3.224-.302.727-.881.764-3.027 1.528v4.375c2.139.76 2.725.8 3.027 1.528.304.734-.081 1.167-1.059 3.223l3.093 3.093c1.999-.95 2.47-1.373 3.223-1.059.728.302.764.88 1.529 3.027h4.374c.758-2.131.799-2.723 1.537-3.031.745-.308 1.186.099 3.215 1.062l3.093-3.093c-.975-2.05-1.362-2.492-1.059-3.223.3-.726.88-.763 3.027-1.528zm-4.875.764c-.577 1.394-.068 2.458.488 3.578l-1.084 1.084c-1.093-.543-2.161-1.076-3.573-.49-1.396.581-1.79 1.693-2.188 2.877h-1.534c-.398-1.185-.791-2.297-2.183-2.875-1.419-.588-2.507-.045-3.579.488l-1.083-1.084c.557-1.118 1.066-2.18.487-3.58-.579-1.391-1.691-1.784-2.876-2.182v-1.533c1.185-.398 2.297-.791 2.875-2.184.578-1.394.068-2.459-.488-3.579l1.084-1.084c1.082.538 2.162 1.077 3.58.488 1.392-.577 1.785-1.69 2.183-2.875h1.534c.398 1.185.792 2.297 2.184 2.875 1.419.588 2.506.045 3.579-.488l1.084 1.084c-.556 1.121-1.065 2.187-.488 3.58.577 1.391 1.689 1.784 2.875 2.183v1.534c-1.188.398-2.302.791-2.877 2.183zm-7.125-5.951c1.654 0 3 1.346 3 3s-1.346 3-3 3-3-1.346-3-3 1.346-3 3-3zm0-2c-2.762 0-5 2.238-5 5s2.238 5 5 5 5-2.238 5-5-2.238-5-5-5z"/>
  </svg>
);


export const Camera = ({ size=24, fill="#fff"}) => (
  <svg xmlns="http://www.w3.org/2000/svg"
  width={size}
  height={size}
  viewBox="0 0 24 24">
    <path fill={fill} d="M5 4h-3v-1h3v1zm8 6c-1.654 0-3 1.346-3 3s1.346 3 3 3 3-1.346 3-3-1.346-3-3-3zm11-5v17h-24v-17h5.93c.669 0 1.293-.334 1.664-.891l1.406-2.109h8l1.406 2.109c.371.557.995.891 1.664.891h3.93zm-19 4c0-.552-.447-1-1-1s-1 .448-1 1 .447 1 1 1 1-.448 1-1zm13 4c0-2.761-2.239-5-5-5s-5 2.239-5 5 2.239 5 5 5 5-2.239 5-5z"/>
    </svg>
);


export const Arrow = ({ size=14, fill="#000"}) => (
  <svg 
  width={size}
  height={size} xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" viewBox="0 0 32 32" clipRule="evenodd">
    <path fill={fill} d="M4 .755l14.374 11.245-14.374 11.219.619.781 15.381-12-15.391-12-.609.755z"/>
    </svg>
);

export const Party = ({ size=75, fill="#797979"}) => (
  <svg 
    width={size}
    height={size}
    xmlns="http://www.w3.org/2000/svg" 
    fillRule="evenodd" 
    clipRule="evenodd"
    viewBox="0 0 24 24">
    <path fill={fill} d="M1.999 1v7.269l-2 1.456v13.275h24.001v-13.275l-2.001-1.453v-7.272h-20zm1 11.234v-10.234h18v10.233l-9 5.766-9-5.765zm19 .54v-3.17l1.001.764v11.632h-22.001v-11.641l1-.707.063 3.134 9.937 6.413 10-6.425zm-14.305-6.752l-2.694.496 1.888 1.986-.361 2.716 2.472-1.182 2.473 1.182-.361-2.716 1.888-1.986-2.695-.496-1.305-2.41-1.305 2.41zm.248 2.139l-.945-.994 1.349-.249.653-1.206.654 1.206 1.349.249-.945.994.18 1.36-1.238-.591-1.238.591.181-1.36zm6.058-3.078h4.999v-1h-4.999v1zm0 2h4.999v-1h-4.999v1zm0 2h4.999v-1h-4.999v1zm0 2h3v-1h-3v1z"/></svg>
)

export const Close = ({ size=20, fill="#fff"}) => (
  <svg  
  width={size}
  height={size}
   xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd" viewBox="0 0 24 24">
    <path fill={fill} d="M12 11.293l10.293-10.293.707.707-10.293 10.293 10.293 10.293-.707.707-10.293-10.293-10.293 10.293-.707-.707 10.293-10.293-10.293-10.293.707-.707 10.293 10.293z"/></svg>
)

export const Share = ({ size=24, fill="#000"}) => (
  <svg width={size}
  height={size}
   xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd">
    <path fill={fill} d="M16.272 5.451c-.176-.45-.272-.939-.272-1.451 0-2.208 1.792-4 4-4s4 1.792 4 4-1.792 4-4 4c-1.339 0-2.525-.659-3.251-1.67l-7.131 3.751c.246.591.382 1.239.382 1.919 0 .681-.136 1.33-.384 1.922l7.131 3.751c.726-1.013 1.913-1.673 3.253-1.673 2.208 0 4 1.792 4 4s-1.792 4-4 4-4-1.792-4-4c0-.51.096-.999.27-1.447l-7.129-3.751c-.9 1.326-2.419 2.198-4.141 2.198-2.76 0-5-2.24-5-5s2.24-5 5-5c1.723 0 3.243.873 4.143 2.201l7.129-3.75zm3.728 11.549c1.656 0 3 1.344 3 3s-1.344 3-3 3-3-1.344-3-3 1.344-3 3-3zm-15-9c2.208 0 4 1.792 4 4s-1.792 4-4 4-4-1.792-4-4 1.792-4 4-4zm15-7c1.656 0 3 1.344 3 3s-1.344 3-3 3-3-1.344-3-3 1.344-3 3-3z"/></svg>
)

export const Plus = ({ size=24, fill="#000"}) => (
  <svg width={size}
  height={size} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd"><path fill={fill} d="M11 11v-11h1v11h11v1h-11v11h-1v-11h-11v-1h11z"/></svg>
)

export const Minus =  ({ size=24, fill="#000"}) => (
<svg xmlns="http://www.w3.org/2000/svg" 
  width={size}
  height={size} 
  viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm6 13h-12v-2h12v2z"/></svg>
);


export const BasketBall = ({ size=24, fill="#fff"}) => (
  <svg  
  width={size}
  height={size}  
  xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd" viewBox="0 0 24 24"><path fill={fill} d="M10.361.125c-2.504.344-4.758 1.457-6.523 3.099 3.332.159 5.773 1.521 8.351 1.836-.618-2.186-1.2-3.643-1.828-4.935m2.85 9.88c-4.936.474-9.392 2.043-12.988 4.279.463 2.396 1.634 4.541 3.295 6.201 3.802-2.97 5.168-8.005 9.853-9.176-.045-.437-.1-.871-.16-1.304m-.509-2.925c-3.857-.211-7.25-2.45-10.442-2.068-1.417 1.969-2.26 4.379-2.26 6.988l.007.158c3.347-2.259 8.54-3.758 12.868-4.184-.056-.298-.108-.598-.173-.894m11.236 3.703c-2.843-.707-5.834-1.005-8.716-.909.055.412.096.826.137 1.241 3.412.228 6.002 3.617 7.084 6.78.986-1.743 1.557-3.751 1.557-5.895 0-.411-.022-.817-.062-1.217m-3.539-7.343c-2.114.799-2.941 2.915-5.667 3.509l.16.889c2.507-.099 5.161.065 8.607.746-.587-1.975-1.666-3.737-3.1-5.144m-6.893 9.864c-3.361 1.011-5.057 5.821-8.444 8.473 1.961 1.395 4.351 2.223 6.938 2.223l.554-.028c.756-3.443 1.132-6.819.952-10.668m2-.124c.169 3.603-.16 7.068-.856 10.516 2.628-.594 4.932-2.043 6.594-4.05-1.005-2.781-2.837-5.958-5.738-6.466m3.161-11.154c-2.243 1.588-2.462 2.536-4.417 2.965-.444-1.67-1.025-3.375-1.74-4.965 2.271.096 4.382.811 6.157 2"/></svg>
);

export const Profile = ({ size=24, fill="#fff"}) => (
  <svg xmlns="http://www.w3.org/2000/svg"  width={size} height={size} viewBox="0 0 24 24"><path fill={fill} d="M12 24c6.627 0 12-5.373 12-12s-5.373-12-12-12-12 5.373-12 12 5.373 12 12 12zm1-6h-2v-8h2v8zm-1-12.25c.69 0 1.25.56 1.25 1.25s-.56 1.25-1.25 1.25-1.25-.56-1.25-1.25.56-1.25 1.25-1.25z"/></svg>
)

export const RoundArrow = ({ size=24, fill="#fff"}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24"><path fill={fill} d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.218 19l-1.782-1.75 5.25-5.25-5.25-5.25 1.782-1.75 6.968 7-6.968 7z"/></svg>
)

export const Trophy = ({ size=24, fill="#fff"}) => (
  <svg xmlns="http://www.w3.org/2000/svg"  width={size} height={size} viewBox="0 0 24 24"><path fill={fill} d="M24 3c-.372 4.105-2.808 8.091-6.873 9.438.297-.552.596-1.145.882-1.783 2.915-1.521 4.037-4.25 4.464-6.251h-2.688c.059-.45.103-.922.139-1.405h4.076zm-24 0c.372 4.105 2.808 8.091 6.873 9.438-.297-.552-.596-1.145-.882-1.783-2.915-1.521-4.037-4.25-4.464-6.251h2.688c-.058-.449-.102-.922-.138-1.404h-4.077zm16.921 0c-.368 4.506-1.953 7.23-3.372 9.669-.577.993-1.136 1.953-1.543 2.95-.408-.998-.969-1.959-1.548-2.953-1.422-2.437-3.011-5.161-3.379-9.666h9.842zm2.079-2h-14c0 9.803 5.105 13.053 5.604 17h2.805c.497-3.947 5.591-7.197 5.591-17zm-5.562 18h-2.866c-.202 1.187-.63 2.619-2.571 2.619v1.381h8v-1.381c-1.999 0-2.371-1.432-2.563-2.619zm-3.644-12.472l1.46-.202.643-1.326.643 1.326 1.46.202-1.063 1.021.26 1.451-1.3-.695-1.3.695.26-1.451-1.063-1.021z"/></svg>
)

export const Dots =({ size=24, fill="#fff"}) => (
  <svg width={size} height={size} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><defs></defs><title/><g data-name="Layer 60" id="Layer_60"><circle cx="16" cy="16" r="1"/><path d="M16,18a2,2,0,1,1,2-2A2,2,0,0,1,16,18Zm0-2Z"/><circle  cx="16" cy="5" r="1"/><path d="M16,7a2,2,0,1,1,2-2A2,2,0,0,1,16,7Zm0-2Z"/><circle  cx="16" cy="27" r="1"/><path  d="M16,29a2,2,0,1,1,2-2A2,2,0,0,1,16,29Zm0-2Z"/></g></svg>
)

export const Phone =({ size=24, fill="#fff"}) => (
  <svg width={size} height={size} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd"><path fill={fill} d="M8.26 1.289l-1.564.772c-5.793 3.02 2.798 20.944 9.31 20.944.46 0 .904-.094 1.317-.284l1.542-.755-2.898-5.594-1.54.754c-.181.087-.384.134-.597.134-2.561 0-6.841-8.204-4.241-9.596l1.546-.763-2.875-5.612zm7.746 22.711c-5.68 0-12.221-11.114-12.221-17.832 0-2.419.833-4.146 2.457-4.992l2.382-1.176 3.857 7.347-2.437 1.201c-1.439.772 2.409 8.424 3.956 7.68l2.399-1.179 3.816 7.36s-2.36 1.162-2.476 1.215c-.547.251-1.129.376-1.733.376"/></svg>
)

export const Facebook =({ size=24, fill="#fff"}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24"><path fill={fill} d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm3 8h-1.35c-.538 0-.65.221-.65.778v1.222h2l-.209 2h-1.791v7h-3v-7h-2v-2h2v-2.308c0-1.769.931-2.692 3.029-2.692h1.971v3z"/></svg>
)

// export const Instagram =({ size=24, fill="#fff"}) => (
//   <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24"><path fill={fill} d="M17.25 7.288v1.269c0 .297-.241.539-.538.539h-1.269c-.299 0-.538-.242-.538-.539v-1.269c0-.297.239-.538.538-.538h1.269c.297 0 .538.241.538.538zm-5.25 7.403c1.486 0 2.693-1.205 2.693-2.692s-1.207-2.69-2.693-2.69c-1.487 0-2.691 1.204-2.691 2.691s1.204 2.691 2.691 2.691zm4.261-3.291c.028.196.046.396.046.599 0 2.38-1.928 4.308-4.307 4.308s-4.307-1.928-4.307-4.307c0-.204.018-.403.046-.599.027-.194.066-.383.118-.567h-1.107v5.879c0 .297.241.538.538.538h9.424c.297 0 .538-.241.538-.538v-5.879h-1.107c.05.184.09.373.118.566zm7.739.6c0 6.627-5.373 12-12 12s-12-5.373-12-12 5.373-12 12-12 12 5.373 12 12zm-5-5.385c0-.892-.723-1.615-1.615-1.615h-10.77c-.892 0-1.615.723-1.615 1.615v10.769c0 .893.723 1.616 1.615 1.616h10.77c.892 0 1.615-.723 1.615-1.616v-10.769z"/></svg>
// )
export const Instagram =({ size=24, fill="#fff"}) => (
  <svg enableBackground="new 0 0 24 24" width={size} height={size} viewBox="0 0 24 24"  xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"><linearGradient id="SVGID_1_" gradientTransform="matrix(0 -1.982 -1.844 0 -132.522 -51.077)" gradientUnits="userSpaceOnUse" x1="-37.106" x2="-26.555" y1="-72.705" y2="-84.047"><stop offset="0" stopColor="#fd5"/><stop offset=".5" stopColor="#ff543e"/><stop offset="1" stopColor="#c837ab"/></linearGradient><path d="m1.5 1.633c-1.886 1.959-1.5 4.04-1.5 10.362 0 5.25-.916 10.513 3.878 11.752 1.497.385 14.761.385 16.256-.002 1.996-.515 3.62-2.134 3.842-4.957.031-.394.031-13.185-.001-13.587-.236-3.007-2.087-4.74-4.526-5.091-.559-.081-.671-.105-3.539-.11-10.173.005-12.403-.448-14.41 1.633z" fill="url(#SVGID_1_)"/><path d="m11.998 3.139c-3.631 0-7.079-.323-8.396 3.057-.544 1.396-.465 3.209-.465 5.805 0 2.278-.073 4.419.465 5.804 1.314 3.382 4.79 3.058 8.394 3.058 3.477 0 7.062.362 8.395-3.058.545-1.41.465-3.196.465-5.804 0-3.462.191-5.697-1.488-7.375-1.7-1.7-3.999-1.487-7.374-1.487zm-.794 1.597c7.574-.012 8.538-.854 8.006 10.843-.189 4.137-3.339 3.683-7.211 3.683-7.06 0-7.263-.202-7.263-7.265 0-7.145.56-7.257 6.468-7.263zm5.524 1.471c-.587 0-1.063.476-1.063 1.063s.476 1.063 1.063 1.063 1.063-.476 1.063-1.063-.476-1.063-1.063-1.063zm-4.73 1.243c-2.513 0-4.55 2.038-4.55 4.551s2.037 4.55 4.55 4.55 4.549-2.037 4.549-4.55-2.036-4.551-4.549-4.551zm0 1.597c3.905 0 3.91 5.908 0 5.908-3.904 0-3.91-5.908 0-5.908z" fill="#fff"/></svg>
)


// export const Youtube =({ size=24, fill="#fff"}) => (
//   <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24"><path fill={"#F80000"} d="M19.615 3.184c-3.604-.246-11.631-.245-15.23 0-3.897.266-4.356 2.62-4.385 8.816.029 6.185.484 8.549 4.385 8.816 3.6.245 11.626.246 15.23 0 3.897-.266 4.356-2.62 4.385-8.816-.029-6.185-.484-8.549-4.385-8.816zm-10.615 12.816v-8l8 3.993-8 4.007z"/></svg>
// )

export const Youtube =({ size=24, fill="#fff"}) => (
  <svg viewBox="0 .03 2498 2502.47" width={size} height={size} xmlns="http://www.w3.org/2000/svg"><path d="m0 1864.11v.11c1.5 55.5 2 111.32 11.32 166.47 9.92 58.35 24.1 115.25 51.15 168.21q54.86 107.52 150.21 181.66c45.52 35.5 95.25 63.69 150.3 81.47 80.26 25.89 163.07 35.81 247.09 36.3 52.42.33 104.81 1.64 157.25 1.42 380.82-1.6 761.65 2.75 1142.49-2.35 50.53-.68 100.24-6.85 149.84-15.92 95.06-17.4 179.07-58 250.95-122.09 83.77-74.71 140.29-166.16 165.81-276.52 16.69-72.14 20.87-145.32 21.58-218.77v-14.65c0-5.68-2.16-1247.91-2.36-1264.33-.55-45.1-3.88-89.87-12.33-134.25-10.29-54.08-24.82-106.78-50.71-155.7-27.35-51.7-61.6-98.17-104-138.79-64.89-62.23-139.78-106.23-227-129.51-78.74-21-159.07-25.68-240-25.6a2.45 2.45 0 0 1 -.45-1.24h-1224.74c0 .42 0 .83-.07 1.24-45.93.84-91.92.49-137.61 6.16-50.05 6.22-99.63 15.59-147 33.09-74.62 27.6-139.46 70.59-194.84 128-62.75 65-107 140.22-130.44 227.79-20.95 78.13-25.51 157.81-25.62 238.06" fill="#fff"/><path d="m0 .79h2498v2498h-2498z" fill="none"/><path d="m1293.24 1938.65-409.54-7.49c-132.6-2.61-265.53 2.6-395.53-24.44-197.76-40.4-211.77-238.49-226.43-404.65-20.2-233.6-12.38-471.44 25.74-703.09 21.52-129.98 106.21-207.54 237.18-215.98 442.12-30.63 887.18-27 1328.32-12.71 46.59 1.31 93.5 8.47 139.44 16.62 226.77 39.75 232.3 264.23 247 453.2 14.66 190.92 8.47 382.82-19.55 572.44-22.48 157-65.49 288.66-247 301.37-227.42 16.62-449.62 30-677.68 25.74.01-1.01-1.3-1.01-1.95-1.01zm-240.77-397.48c171.38-98.4 339.49-195.16 509.89-292.9-171.7-98.4-339.49-195.16-509.89-292.9z" fill="#f00"/></svg>
)

export const KakaoChannel = ({ size=24, fill="#fff"}) => (
  <svg version="1.1" id="Layer_4" width={size} height={size} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 60 60" enableBackground="new 0 0 60 60" xmlSpace="preserve">
  <g>
    <path fill="#010000" d="M30.2,0C14.4,0,1.5,11.9,1.5,26.6C1.5,35.3,6,43,12.9,47.9v11.7c0,0.2,0.1,0.3,0.2,0.4c0.1,0,0.1,0,0.2,0
      c0.1,0,0.2,0,0.3-0.1l10.3-7.3c2,0.4,4.1,0.6,6.3,0.6c15.8,0,28.6-11.9,28.6-26.6C58.8,11.9,46,0,30.2,0z"/>
    <g>
      <path fill="#F0D823" d="M48.1,23.5c-1-1.1-2.4-1.6-4.3-1.6c-1,0-2,0.2-2.8,0.7c-0.6,0.3-1.2,0.7-1.6,1.2v-8.6h-4.1V38h4v-8.5
        c0-0.7,0.1-1.3,0.3-1.9c0.2-0.6,0.5-1,0.8-1.4c0.3-0.4,0.7-0.7,1.1-0.9c0.4-0.2,0.9-0.3,1.4-0.3c0.8,0,1.5,0.2,1.9,0.7
        c0.4,0.4,0.7,1.3,0.7,2.5V38h4v-9.7C49.5,26.2,49,24.6,48.1,23.5z"/>
      <path fill="#F0D823" d="M21.6,19.9c2.8,0,5.2,1.8,6.2,4.4h4.3c-1.1-5-5.4-8.7-10.5-8.7c-6,0-10.8,5-10.8,11.2
        C10.8,33,15.7,38,21.6,38c5.1,0,9.4-3.7,10.5-8.7h-4.3c-1,2.6-3.4,4.4-6.2,4.4c-3.7,0-6.6-3.1-6.6-6.9C15,23,18,19.9,21.6,19.9z"
        />
    </g>
  </g>
  </svg>
)

export const Create =({ size=24, fill="#fff"}) => (
  <svg version="1.1" id="Layer_3" xmlns="http://www.w3.org/2000/svg" 
    xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    width={size} height={size}
	  viewBox="0 0 60 50" enableBackground="new 0 0 60 50" xmlSpace="preserve">
  <g>
    <path fill={fill} d="M14.9,20.2c1.7-2,3.8-3.6,6.1-4.7c2.7-1.3,5.6-2,8.6-2c3.4,0,6.7,0.9,9.6,2.5c-0.4-4.2-3-11.2-15.3-13.2
      c-0.5-0.1-1,0.4-0.8,1l1.2,3.4c0,0-9.5-9.3-22.2-2.9c-0.5,0.3-0.5,1,0,1.3c2,1.1,5.6,3.8,5.3,8.9H1.7C1.4,14.4,1,14.6,1,15
      c-0.3,3-1,15.6,9.5,21.2c-0.1-0.7-0.2-1.5-0.3-2.2c-0.2-2.5,0.2-5,1-7.4C12,24.3,13.3,22.1,14.9,20.2z"/>
    <polygon fill={fill} points="52.6,7.8 52.6,0.5 49.2,0.5 49.2,7.8 41.9,7.8 41.9,11.2 49.2,11.2 49.2,18.4 52.6,18.4 52.6,11.2 59.8,11.2 
      59.8,7.8 	"/>
    <path fill={fill} d="M25.8,31.2c-4,3.4-8.7,4.7-12.2,5c0.5,2.9,1.8,5.7,3.8,8l10.7-10.7L25.8,31.2z"/>
    <path fill={fill} d="M30,35.4L19.3,46.1c2.4,2,5.3,3.3,8.2,3.7c0.4-3.5,1.6-8,5-11.9L30,35.4z"/>
    <path fill={fill} d="M46.6,30.9c-0.4-3-1.7-5.8-3.7-8.3L31.9,33.5l2.5,2.5C38.4,32.3,43,31.1,46.6,30.9z"/>
    <path fill={fill} d="M27.8,29.4l2.3,2.3l11-11c-2.4-2-5.2-3.3-8.1-3.8C32.7,20.5,31.5,25.2,27.8,29.4z"/>
    <path fill={fill} d="M30.2,50c3.8,0,7.5-1.3,10.5-3.9l-6.3-6.3C31.6,43.2,30.6,47,30.2,50z"/>
    <path fill={fill} d="M25.9,27.5c3.2-3.6,4.2-7.7,4.4-10.9c-4-0.1-8,1.3-11.2,4.1L25.9,27.5z"/>
    <path fill={fill} d="M17.3,22.6c-2.6,3.2-3.9,7.1-3.9,11c3.1-0.3,7.1-1.3,10.6-4.2L17.3,22.6z"/>
    <path fill={fill} d="M42.7,44.3c2.7-3.1,4.1-6.9,4.1-10.8c-3.1,0.3-7,1.3-10.5,4.4L42.7,44.3z"/>
  </g>
  </svg>
)

export const People =({ size=24, fill="#fff"}) => (
  <svg 
  enableBackground="new 0 0 32 32" 
  width={size} 
  height={size} 
  viewBox="0 0 32 32" 

  xmlns="http://www.w3.org/2000/svg" 
  // xml:space="preserve" 
  // xmlns:cc="http://creativecommons.org/ns#" 
  // xmlns:dc="http://purl.org/dc/elements/1.1/" 
  // xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape" 
  // xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#" 
  // xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd" 
  // xmlns:svg="http://www.w3.org/2000/svg"
  ><g id="background"><rect fill="none" height="32" width="32"/></g><g id="group_x5F_half"><g><path fill={fill} d="M20,16c-0.75,0-8,0-8,0s-2,0-2,2c0,0.778,0,12,0,12h12V18C22,18,22,16,20,16z"/></g><g><circle fill={fill} cx="16" cy="11" r="3"/></g><g><circle fill={fill} cx="8" cy="5" r="3"/></g><g><path fill={fill} d="M12,14h0.023C11.39,13.162,11,12.131,11,11c0-0.343,0.035-0.677,0.101-1C8.968,10,4,10,4,10s-2,0-2,2c0,0.778,0,12,0,12h6    v-6C8,14,12,14,12,14z"/></g><g><path fill={fill} d="M30,12c0,0,0-2-2-2c-0.587,0-5.143,0-7.101,0C20.965,10.323,21,10.657,21,11c0,1.131-0.39,2.162-1.022,3H20c4,0,4,4,4,4v6    h6V12z"/></g><g><circle fill={fill} cx="24" cy="5" r="3"/></g></g></svg>
)

export const LinkCopy =({ size=24, fill="#fff"}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24"><path fill={fill} d="M22 6v16h-16v-16h16zm2-2h-20v20h20v-20zm-24 17v-21h21v2h-19v19h-2zm18-8h-3v-3h-2v3h-3v2h3v3h2v-3h3v-2z"/></svg>
);

export const Ranking =({ size=28, fill="#fff"}) => (
<svg version="1.1" id="Layer_1" width={24} height={32} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 44 60" enableBackground="new 0 0 44 60" xmlSpace="preserve">
<g id="Layer_2">
</g>
<g>
	<path fill="#FFF001" d="M37.6,5c-1.2,0-2.2,1-2.2,2.2c0,0.3,0.1,0.6,0.2,0.9l-8,3.2l-5-7c0.8-0.3,1.3-1.1,1.3-2c0-1.2-1-2.2-2.2-2.2
		s-2.2,1-2.2,2.2c0,0.9,0.5,1.7,1.3,2l-5,7l-8-3.2c0.1-0.3,0.2-0.6,0.2-0.9C7.9,6,6.9,5,5.7,5S3.4,6,3.4,7.2c0,1.2,1,2.2,2.2,2.2
		c0.2,0,0.3,0,0.4,0l1.4,10c3.7-3,9-4.9,14.2-4.9c5.1,0,10.5,1.8,14.2,4.9l1.4-10c0.1,0,0.3,0,0.4,0c1.2,0,2.2-1,2.2-2.2
		C39.9,6,38.9,5,37.6,5z"/>
	<path fill="#FFFFFF" d="M21.6,17.1c-11.8,0-21.4,9.6-21.4,21.4s9.6,21.4,21.4,21.4S43,50.2,43,38.4S33.4,17.1,21.6,17.1z M40.9,37.3
		c-3.3-3.6-7.7-6.2-12.8-7.5c0.2-0.3,0.5-0.7,0.8-0.9c1.8-1.5,5.3-1.8,9-0.8C39.6,30.8,40.7,34,40.9,37.3z M36,25.6
		c-3.5-0.5-6.6,0.1-8.5,1.8c-0.6,0.5-1.2,1.2-1.6,2.1c-1.1-0.2-2.1-0.3-3.2-0.3v-9.9C28,19.5,32.7,21.9,36,25.6z M33.3,53.7
		c-3,2.3-6.6,3.7-10.6,3.9V31.2c0.9,0,1.8,0.1,2.7,0.3c-0.1,0.8,0,1.7,0.1,2.7c0.3,1.5,1.4,3.1,2.6,5C30.4,42.6,33.4,47,33.3,53.7z
		 M15.1,39.2c1.2-1.8,2.3-3.4,2.6-5c0.2-1,0.2-1.9,0.1-2.7c0.9-0.1,1.8-0.2,2.7-0.3v26.4c-4-0.2-7.6-1.6-10.6-3.9
		C9.9,47,12.9,42.6,15.1,39.2z M20.6,19.2v9.9c-1.1,0-2.2,0.1-3.2,0.3c-0.4-0.9-1-1.6-1.6-2.1c-1.9-1.6-5-2.2-8.5-1.8
		C10.6,21.9,15.3,19.5,20.6,19.2z M15.2,29.9c-5.1,1.2-9.5,3.9-12.8,7.5c0.2-3.4,1.3-6.5,3-9.2c3.7-1,7.2-0.7,9,0.8
		C14.7,29.2,15,29.5,15.2,29.9z M2.5,40.5c3.1-4.2,7.8-7.3,13.3-8.6c0,0.6,0,1.2-0.1,1.9c-0.2,1.1-1.2,2.5-2.3,4.2
		c-2.1,3.1-5.1,7.5-5.5,13.9C5,48.9,3,45,2.5,40.5z M35.4,51.9C35,45.6,32,41.2,29.9,38c-1.1-1.6-2.1-3.1-2.3-4.2
		c-0.1-0.7-0.2-1.3-0.1-1.9c5.5,1.3,10.2,4.4,13.3,8.6C40.3,45,38.3,48.9,35.4,51.9z"/>
</g>
</svg>

);

export const HostBadge = ({size=24}) => (
<svg version="1.1" xmlns="http://www.w3.org/2000/svg" width={size} height={size} xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 60 60" enableBackground="new 0 0 60 60" xmlSpace="preserve">
  <g id="Layer_2">
  </g>
  <g id="Layer_3">
    <g>
      <circle fill="#FF503B" cx="30" cy="30" r="30"/>
      <path fill="#FFFFFF" d="M31.1,11.8l5.4,11l12.2,1.8c0.8,0.1,1.1,1.1,0.6,1.7l-8.8,8.6L42.5,47c0.1,0.8-0.7,1.4-1.5,1.1l-10.9-5.7
        l-10.9,5.7c-0.7,0.4-1.6-0.2-1.5-1.1L20,34.9l-8.8-8.6c-0.6-0.6-0.3-1.6,0.6-1.7l12.2-1.8l5.4-11C29.7,11.1,30.7,11.1,31.1,11.8z"
        />
    </g>
  </g>
  </svg>
);

export const OpenIcon = ({size=24,  fill="#fff"}) => (
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width={size} height={size}
    viewBox="0 0 55 60" enableBackground="new 0 0 55 60" xmlSpace="preserve">
  <g>
    <path fill={fill} d="M50,26.3c0.3-2.2,0.3-4.5,0-6.7c-5.3,0.3-10.3-1.3-14.1-4.6c-0.8,0.7-1.6,1.5-2.4,2.3c2.9,2.9,5.5,6,7.9,9h-3.2
      c-2-2.5-4.2-4.9-6.5-7.3c-0.8,0.8-1.5,1.6-2.2,2.4c1.4,1.5,2.6,3.1,3.5,4.9h-2.8c-0.6-1.1-1.4-2.2-2.3-3.1c-0.9,1-1.8,2.1-2.6,3.1
      h-3.2c1.2-1.6,2.6-3.2,3.9-4.7c-6-4.5-14.6-4.4-21.5,0.3c-0.1,1.5,0,3,0.2,4.4H0.1v4.3h2.7l10.6,28.9c0.1,0.4,0.5,0.6,0.9,0.6
      c0.4,0,0.7-0.2,0.9-0.6l3.5-9.4l3.5,9.4c0.1,0.4,0.5,0.6,0.9,0.6c0.4,0,0.7-0.2,0.9-0.6l3.5-9.4l3.5,9.4c0.1,0.4,0.5,0.6,0.9,0.6
      s0.7-0.2,0.9-0.6l3.5-9.4l3.5,9.4c0.1,0.4,0.5,0.6,0.9,0.6c0.4,0,0.7-0.2,0.9-0.6l10.6-28.9h2.7v-4.3H50z M40.3,30.5L36.1,42
      l-2.9-7.8l1.4-3.7H40.3z M34.6,46l-2.9,7.8L28.9,46l2.9-7.8L34.6,46z M25.9,30.5h3l1.4,3.7L27.4,42l-2.9-7.8L25.9,30.5z M25.9,46
      L23,53.8L20.2,46l2.9-7.8L25.9,46z M20.2,30.5l1.4,3.7L18.7,42l-4.2-11.5H20.2z M17.2,46l-2.9,7.8L5.8,30.5h5.7L17.2,46z M49,30.5
      l-8.6,23.2L37.5,46l5.7-15.5H49z"/>
    <path fill={fill} d="M42.8,6c-3.2-2.9-7-4.8-11-5.6c0,4.2,1.5,8.1,4.1,11.1C38.2,9.6,40.5,7.7,42.8,6z"/>
    <path fill={fill} d="M27.7,19.7c0.8-0.8,1.5-1.7,2.3-2.5c-5.1-5-10.5-9.4-16-12.9c-1,0.7-1.9,1.5-2.8,2.4c-3.4,3.4-5.5,7.6-6.3,12
      C12.4,14.4,21.3,14.8,27.7,19.7z"/>
    <path fill={fill} d="M34.1,13.2C31,9.7,29.3,5.1,29.4,0.1c-4.5-0.4-9.1,0.5-13.1,2.8c5.3,3.5,10.5,7.7,15.5,12.6C32.6,14.7,33.4,13.9,34.1,13.2
      z"/>
    <path fill={fill} d="M49.5,17.1c-0.9-3.4-2.5-6.6-4.9-9.3c-2.3,1.7-4.6,3.5-6.8,5.5C41,16,45.1,17.3,49.5,17.1z"/>
  </g>
  </svg>
)

export const OpenHistory = ({size=24,  fill="#fff"}) => (
  <svg 
    xmlns="http://www.w3.org/2000/svg"
    width={size} 
    height={size}  
    viewBox="0 0 24 24">
    <path fill={fill} d="M4 22h-4v-4h4v4zm0-12h-4v4h4v-4zm0-8h-4v4h4v-4zm3 0v4h17v-4h-17zm0 12h17v-4h-17v4zm0 8h17v-4h-17v4z"/>
    </svg>
)

export const OpenRoom = ({size=24,  fill="#fff"}) => (
  <svg xmlns="http://www.w3.org/2000/svg" 
  width={size} 
  height={size} 
  viewBox="0 0 24 24">
    <path fill={fill} d="M24 10h-10v-10h-4v10h-10v4h10v10h4v-10h10z"/>
  </svg>
)

export const Coin = ({size=24}) => (
  <svg version="1.1" id="Layer_2" xmlns="http://www.w3.org/2000/svg" 
  width={size} 
  height={size} 
  xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 60 60" enableBackground="new 0 0 60 60" xmlSpace="preserve">
  <g>
    <circle fill="#FFD716" cx="30" cy="30" r="30"/>
    <circle fill="#FBC200" cx="30" cy="30" r="24.9"/>
    <g>
      <path fill="#A36904" d="M36.6,35L40,20.2c0.1-0.5,0.3-0.9,0.5-1.2c0.3-0.3,0.6-0.6,0.9-0.8c0.4-0.2,0.7-0.3,1.2-0.4
        c0.4-0.1,0.8,0,1.3,0c0.4,0.1,0.8,0.2,1.1,0.4c0.4,0.2,0.7,0.5,0.9,0.8c0.2,0.3,0.4,0.7,0.5,1.1s0.1,0.9,0,1.3l-5.3,22.3h-7.3
        l-3.9-16l-3.9,16h-6.8l-5.5-22.3c-0.1-0.5-0.1-0.9,0-1.3c0.1-0.4,0.3-0.8,0.5-1.1c0.2-0.3,0.6-0.6,0.9-0.8
        c0.4-0.2,0.8-0.3,1.2-0.4c0.4-0.1,0.8-0.1,1.3,0c0.4,0.1,0.8,0.2,1.2,0.4c0.4,0.2,0.7,0.4,0.9,0.8c0.3,0.3,0.4,0.7,0.6,1.2l3.1,14
        L26.3,21c0.1-0.5,0.3-0.9,0.6-1.2c0.3-0.3,0.6-0.6,0.9-0.8c0.3-0.2,0.7-0.3,1-0.4c0.4-0.1,0.7-0.1,1-0.1c0.3,0,0.6,0,1,0.1
        c0.4,0.1,0.7,0.2,1,0.4c0.3,0.2,0.6,0.4,0.9,0.8c0.3,0.3,0.5,0.7,0.6,1.2L36.6,35z"/>
    </g>
  </g>
  </svg>
)

export const Recommend = ({ size = 15, fill="#fff"}) => (
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" 
    width={size} 
    height={size} 
    xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    viewBox="0 0 60 60" 
    enableBackground="new 0 0 60 60" 
    xmlSpace="preserve">
    <g id="Layer_3">
      <circle fill="#A6CE39" cx="30" cy="30" r="30"/>
    </g>
    <g id="Layer_4">
      <path fill="#FFFFFF" d="M30.9,15.1l4.4,9l9.9,1.4c0.7,0.1,0.9,0.9,0.5,1.4l-7.2,7l1.7,9.9c0.1,0.7-0.6,1.2-1.2,0.9L30.2,40
        l-8.9,4.7c-0.6,0.3-1.3-0.2-1.2-0.9l1.7-9.9l-7.2-7c-0.5-0.5-0.2-1.3,0.5-1.4l9.9-1.4l4.4-9C29.8,14.5,30.6,14.5,30.9,15.1z"/>
    </g>
  </svg>
);

export const Similar = ({ size = 15, fill="#fff"}) => (
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" 
    width={size} 
    height={size} 
    xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    viewBox="0 0 60 60" 
    enableBackground="new 0 0 60 60" 
    xmlSpace="preserve">
    <g id="Layer_2">
	<g>
		<circle fill="#FF9023" cx="30" cy="30" r="30"/>
	</g>
  </g>
  <g id="Layer_4">
    <g>
      <path fill="#FFFFFF" d="M42,40.8h-3.1c-0.6,0-1-0.5-1-1V21c0-0.6,0.5-1,1-1H42c0.6,0,1,0.5,1,1v18.7C43,40.3,42.5,40.8,42,40.8z"
        />
      <path fill="#FFFFFF" d="M21.2,40.8H18c-0.6,0-1-0.5-1-1v-8.3c0-0.6,0.5-1,1-1h3.1c0.6,0,1,0.5,1,1v8.3
        C22.2,40.3,21.7,40.8,21.2,40.8z"/>
      <path fill="#FFFFFF" d="M31.6,40.8h-3.1c-0.6,0-1-0.5-1-1V26.2c0-0.6,0.5-1,1-1h3.1c0.6,0,1,0.5,1,1v13.5
        C32.6,40.3,32.1,40.8,31.6,40.8z"/>
    </g>
  </g>
  </svg>
);

export const RoomList = ({ size = 15, fill="#fff"}) => (
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" 
    width={size} 
    height={size} 
    xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    viewBox="0 0 60 60" 
    enableBackground="new 0 0 60 60" 
    xmlSpace="preserve">
   <g id="Layer_2">
      <g>
        <circle fill="#1EA9E1" cx="30" cy="30" r="30"/>
      </g>
    </g>
    <g id="Layer_3">
      <path fill="#FFFFFF" d="M45.8,29.7L38.3,22l-7.5-7.7c-0.4-0.4-1-0.4-1.4,0L24,19.7l-5,5.1l-4.8,5c-0.3,0.3-0.4,0.7-0.2,1.1
        c0.2,0.4,0.5,0.6,0.9,0.6h3.7v13.7c0,0.6,0.4,1,1,1h6c0.6,0,1-0.4,1-1v-8.9c0-0.6,0.4-1,1-1h4.8c0.6,0,1,0.4,1,1v8.9
        c0,0.6,0.4,1,1,1h6c0.6,0,1-0.4,1-1V31.4h3.7c0.4,0,0.8-0.2,0.9-0.6C46.2,30.4,46.1,30,45.8,29.7z"/>
    </g>
  </svg>
);

export const Invite = ({ size = 15, fill="#fff"}) => (
<svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24"><path fill={fill} d="M0 12l11 3.1 7-8.1-8.156 5.672-4.312-1.202 15.362-7.68-3.974 14.57-3.75-3.339-2.17 2.925v-.769l-2-.56v7.383l4.473-6.031 4.527 4.031 6-22z"/></svg>
);

export const PopupSucess = ({ size = 24, fill="#fff"}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24"><path fill={fill} d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm6.25 8.891l-1.421-1.409-6.105 6.218-3.078-2.937-1.396 1.436 4.5 4.319 7.5-7.627z"/></svg>
)

export const PopupWarning = ({ size = 24, fill="#fff"}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24"><path fill={fill} d="M12 5.177l8.631 15.823h-17.262l8.631-15.823zm0-4.177l-12 22h24l-12-22zm-1 9h2v6h-2v-6zm1 9.75c-.689 0-1.25-.56-1.25-1.25s.561-1.25 1.25-1.25 1.25.56 1.25 1.25-.561 1.25-1.25 1.25z"/></svg>
)

export const PopupError = ({ size = 24, fill="#fff"}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24"><path fill={fill} d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.5 6h3l-1 8h-1l-1-8zm1.5 12.25c-.69 0-1.25-.56-1.25-1.25s.56-1.25 1.25-1.25 1.25.56 1.25 1.25-.56 1.25-1.25 1.25z"/></svg>
)

export const LogOut = ({ size = 24, fill="#fff"}) => (
  <svg width={size} height={size} version="1.1" viewBox="0 0 24 24"  xmlns="http://www.w3.org/2000/svg" xmlnsSketch="http://www.bohemiancoding.com/sketch/ns" xmlnsXlink="http://www.w3.org/1999/xlink"><title/><desc/><defs/><g fill="none" fill-rule="evenodd" id="miu" stroke="none" stroke-width="1"><g id="Artboard-1" transform="translate(-359.000000, -407.000000)"><g id="slice" transform="translate(215.000000, 119.000000)"/><path d="M360,408 L360,430 L375,430 L375,423.5 L374,423.5 L374,429 L361,429 L361,409 L374,409 L374,414.5 L375,414.5 L375,408 L360,408 Z M377.050253,415.464466 L377.757359,414.757359 L382,419 L377.757359,423.242641 L377.050253,422.535534 L380.087349,419.498437 L367.00472,419.498437 L367.00472,418.5 L380.085786,418.5 L377.050253,415.464466 Z" fill={fill} id="common-logout-signout-exit-outline-stroke"/></g></g></svg>
)