import { gql } from "apollo-boost";


export const GET_GAME = gql`
  query seeFullGame($id: String!) {
    seeFullGame(id: $id) {
      id
      gameDate
      startTime
      endTime
      gameStatus
      playerCnt
      title
      message
      homeTeam {
        id
        hasAuthority
      }
      place {
        id
        placeName
        address
        contact
        location {
          id
          latitude
          longitude
        }
      }
    }
  }
`;

export const GET_MY_TEAM = gql`
  query seeMyTeam($sportId:String!) {
    seeMyTeam(sportId:$sportId) {
      id
      teamName
      location {
        id
        latitude
        longitude
      }
      place {
        id
        placeName
        address
        shortAddress
      }
    }
  }
`;

export const EDIT_GAME = gql`
  mutation editGameInfo(
    $id:String!
    $homeId:String
    $placeId: String
    $lat:Float
    $lng:Float
    $placeName:String
    $address:String
    $shortAddress:String
    $playerCnt: Int!
    $gameDate: String!
    $startTime: String!
    $endTime: String!
    $title: String!
    $message: String!
  ) {
    editGameInfo(
      id:$id
      homeId:$homeId
      placeId: $placeId
      lat:$lat
      lng:$lng
      placeName:$placeName
      address:$address
      shortAddress:$shortAddress
      playerCnt: $playerCnt
      gameDate: $gameDate
      startTime: $startTime
      endTime: $endTime
      title: $title
      message: $message
    ) {
      id
      gameDate
      startTime
      endTime
      gameStatus
      playerCnt
      title
      message
      place {
        id
        placeName
        address
        contact
        location {
          id
          latitude
          longitude
        }
      }
    }
  }
`;

export const EDIT_PLACE = gql`
  mutation editPlace(
    $lat:Float!
    $lng: Float!
    $placeName: String!
    $address: String!
    $shortAddress: String!
  ) {
    editPlace(
      lat: $lat
      lng: $lng
      placeName: $placeName
      address: $address
      shortAddress: $shortAddress
    ) {
      id
      placeName
    }
  }
`;

