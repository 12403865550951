import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const Container = styled.div`
  font-size:15px!important;
  margin-bottom:10px;
  padding-left:10px;
  display:flex;
  align-items:center;
  & > svg {
    margin-right:10px;
  }
`;

const Icon = styled.span`
    max-width: 15px;
    vertical-align: bottom;
    margin-right:10px;
`;

const Text = styled.span`
  font-weight: 400;
`;

const FocusText = styled.span`
  font-weight: 600;
  color:#fff;
  margin-left: 3px;
`;

const ListLabel = ({ icon, text, focusText }) => (
  <Container>
    {icon}
    <Text>{text}</Text>
    <FocusText>{focusText}</FocusText>
  </Container>
  
);

ListLabel.propTypes = {
  //icon : PropTypes.string,
  text : PropTypes.string.isRequired,
  focusText : PropTypes.string
};

export default ListLabel;