import React from "react";
import Proptypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import NativeSelect from "@material-ui/core/NativeSelect";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

// const Container = styled.input`
//   width: 100%;
//   height: 35px;
//   padding: 5px 15px;
//   color : ${props => props.theme.whiteColor};
//   background-color: ${props => props.theme.bgColor};
//   border: ${props => props.theme.boxBorder};
//   border-radius: ${props => props.theme.borderRadius};
// `;

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap"
  },
  formControl: {
    minWidth: 120,
    width: "100%"
  },
  selectEmpty: {}
}));

const DefaultSelect = ({ items, onChange, value, selectRef }) => {
  const classes = useStyles();

  return (
    <FormControl className={classes.formControl}>
      {/* <NativeSelect
        value={state}
        onChange={handleChange}
        name="count"
        className={classes.selectEmpty}
        inputProps={{ 'aria-label': 'count' }}
      >
        {items.map(item => (
            <option key={item.value} value={item.value}>{item.text}</option>
          ))}
      </NativeSelect> */}
      <Select
        ref={selectRef}
        value={value}
        onChange={onChange}
        className={classes.selectEmpty}
      >
        {items.map(item => (
          <MenuItem key={item.value} value={item.value}>
            {item.text}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

DefaultSelect.propTypes = {
  items: Proptypes.array
};

export default DefaultSelect;
