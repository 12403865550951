import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { GET_GAME, EDIT_GAME, EDIT_PLACE } from "./EditGameQueries";
import EditGamePresender from "./EditGamePresender";
import { useQuery, useMutation } from "react-apollo-hooks";
import { toast } from "../../Components/Popup";

import moment from "moment";

import useInput from "../../Hooks/useInput";
import Client from "../../Apollo/Client";


const EditGameContainer = ({ match: { params }, history }) => {
  const gameId = params.game;
  const { data, loading } = useQuery(GET_GAME, {
    variables: {
      id:gameId
    }
  });

  const [uploading, setUpLoading] = useState(false);

  // For Game Info
  /*=================================================== */
  const [placeId, setPlaceId] = useState();

  const gameDateInput = useInput(moment().format("YYYY-MM-DD"));
  const startTimeInput = useInput("06:30");
  const endTimeInput = useInput("07:30");

  const titleInput = useInput("");
  const messageInput = useInput("", newValue => newValue.length < 300);
  const contactInput = useInput("", newValue=> /^[0-9-]{1,20}$/g.test(newValue), false, true);

  const playerCntInput = useInput(3);

  const [isAddressChange, setIsAddressChange] = useState(false);

  const [latS, setLat] = useState();
  const [lngS, setLng] = useState();
  const [placeNameS, setPlaceName] = useState();
  const [addressS, setAddress] = useState();
  const [shortAddressS, setShortAddress] = useState();

  /*=================================================== */

  const editGameMutation = useMutation(EDIT_GAME);

  const setAddressInfo = (placeName, address, shortAddress, lat, lng) => {
    if(addressS !== address) {
      setIsAddressChange(true);
    }
    setLat(parseFloat(lat));
    setLng(parseFloat(lng));
    setPlaceName(placeName);
    setAddress(address);
    setShortAddress(shortAddress);
  };

  const handleSubmit = async () => {
    try {


      if (titleInput.value === "") {
        toast.error("경기 제목을 입력해주세요.");
        return;
      }

      if (messageInput.value === "") {
        toast.error("경기 설명을 입력해주세요.");
        return;
      }

      if(data.seeFullGame.gameStatus !== "RECRUIT") {
        toast.error("해당 경기는 수정할 수 없습니다.");
        return;
      }

      if(!data.seeFullGame.homeTeam.hasAuthority) {
        toast.error("수정할 권한이 없습니다.");
        return;
      }

      const gameDateTime = moment(gameDateInput.value+" "+startTimeInput.value).format("YYYY-MM-DDTHH:mm");

      if(gameDateTime < moment().format("YYYY-MM-DDTHH:mm")) {
        toast.error(<span>현재 시간 이후로 설정할 수 있습니다.</span>);
        return;
      }

      setUpLoading(true);
      
      const {
        data: { editGameInfo }
      } = await editGameMutation({
        variables: {
          id:gameId,
          homeId:data.seeFullGame.homeTeam.id,
          placeId,
          lat:latS,
          lng:lngS,
          placeName:(placeNameS && placeNameS !== "")? placeNameS:"장소이름 없음",
          address:addressS,
          shortAddress:shortAddressS,
          playerCnt:playerCntInput.value,
          gameDate:gameDateTime,
          startTime:startTimeInput.value,
          endTime:endTimeInput.value,
          title:titleInput.value,
          message:messageInput.value
        }
      });

      // console.log(editGameInfo);

      if (editGameInfo.id) {
        Client.writeData({
          id:`Place:${editGameInfo.place.id}`,
          data: { 
            address: editGameInfo.place.address,
            location:editGameInfo.place.location,
            placeName:editGameInfo.place.placeName
          }
        });

        toast.success("경기 정보를 수정하였습니다.");
        setTimeout(()=> {
          history.push(`/game/${editGameInfo.id}`);
        }, 2000)
        
      }
    } catch (e) {
      console.log(e);
      toast.error("실패했습니다. 다시 시도해주세요.");
    } finally {
      setUpLoading(false);
    }

  };

  const onClickClose = () => {
    history.goBack();
  };

  return (
    <EditGamePresender
      uploading={uploading}

      gameDateInput={gameDateInput}
      startTimeInput={startTimeInput}
      endTimeInput={endTimeInput}
      titleInput={titleInput}
      messageInput={messageInput}
      contactInput={contactInput}
      playerCntInput={playerCntInput}
      setPlaceId={setPlaceId}

      onSubmit={handleSubmit}
      onClickClose={onClickClose}
      setAddressInfo={setAddressInfo}
      loading={loading}
      data={data}
    />
  );
};

export default withRouter(EditGameContainer);
