import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import {
  GET_TEAM,
  GET_REQUEST,
  APPLY_TEAM,
  ADD_MANAGER,
  REMOVE_MANAGER,
  WITHDRAW_TEAM,
  KICKBACK_USER,
  NEW_INFO,
  TEAM_DELETE
} from "./TeamProfileQueries";
import TeamProfilePresenter from "./TeamProfilePresenter";
import { useQuery, useMutation, useSubscription } from "react-apollo-hooks";
import { toast } from "../../Components/Popup";
import Client from "../../Apollo/Client";


const TeamProfileContainer = ({ match: { params }, history }) => {
  const teamId = params.team;
  const requestId = params.request;
  const [isSend, setIsSend] = useState(false);
  const [applying, setApplying] = useState(false);

  const [hostEditMode, setHostEditMode] = useState(false);

  const [host, setHost] = useState();
  const [managers, setManagers] = useState([]);
  const [users, setUsers] = useState([]);

  const [selectUser, setSelectUser] = useState();
  const [meUser, setMeUser] = useState();

  const [isMineState, setIsMineState] = useState(false);
  const [isHostState, setIsHostState] = useState(false);
  const [isInviteState, setIsInviteState] = useState(false);
  const [isRequestState, setIsRequestState] = useState(false);
  const [isManagerState, setIsManagerState] = useState(false);
  const [canRequestState, setCanRequestState] = useState(false);


  const [refreshing, setRefreshing] = useState();


  // 다이얼로그 관련
  const {data:dataRequest} = useQuery(GET_REQUEST,{
    skip:requestId === undefined,
    variables: {
      id:requestId
    }
  })


  const { data, loading, refetch, error } = useQuery(GET_TEAM, {
    variables: {
      id: teamId.toString(),
      sportId:"1001"
    },
  });

  const { data: newTeamInfo } = useSubscription(NEW_INFO, {
    variables: {
      id: teamId
    }
  });

  const onRefresh = async () => {
    //console.log(currentTarget);
    try {
      setRefreshing(true);
      await refetch();
    } catch(e) {
      console.log(e);
    } finally {
      setRefreshing(false);
    }
  };



  const [tabValue, setTabValue] = useState(0);
  const [openTeamEdit, setOpenTeamEdit] = useState(false);
  const [openKickBack, setOpenKickBack] = useState(false);
  const [openWithDraw, setOpenWithDraw] = useState(false);
  const [openTeamDelete, setOpenTeamDelete] = useState(false);
  const [openWithDrawForHost, setOpenWithDrawForHost] = useState(false);

  const handleEditMode = () => {
    setTabValue(1);
    setHostEditMode(!hostEditMode);
    handleCloseTeamEdit();
  }

  const handleOpenTeamEdit = () => {
    setOpenTeamEdit(true);
  }

  const handleCloseTeamEdit = () => {
    setOpenTeamEdit(false);
  } 

  const handleOpenKickBack = user => () => {
    setSelectUser(user);
    setOpenKickBack(true);
  }

  const handleOpenWithDraw = () => {
    handleCloseTeamEdit();
    setOpenWithDraw(true);
  } 

  const handleOpenTeamDelete = () => {
    handleCloseTeamEdit();
    setOpenTeamDelete(true);
  } 

  const handleOpenWithDrawForHost = () => {
    handleCloseTeamEdit();
    setOpenWithDrawForHost(true);
  } 

  const handleCloseKickBack = () => {
    setOpenKickBack(false);
  }

  const handleCloseTeamDelete = () => {
    setOpenTeamDelete(false);
  }

  const handleCloseWithDraw = () => {
    setOpenWithDraw(false);
  }

  const handleCloseWithDrawForHost = () => {
    handleOpenWithDraw();
    setOpenWithDrawForHost(false);
  }

  const handleReqInvitePage = () => {
    handleCloseTeamEdit();
    history.push(`/team/${teamId}/user`);
  }

  const handleTeamInfoEditPage = () => {
    handleCloseTeamEdit();
    history.push(`/team/${teamId}/edit`);
  }
  
  const applyMutation = useMutation(APPLY_TEAM);
  const addManagerMutation = useMutation(ADD_MANAGER);
  const removeManagerMutation = useMutation(REMOVE_MANAGER);
  const widthdrawMutation = useMutation(WITHDRAW_TEAM);
  const kickbackMutation = useMutation(KICKBACK_USER);
  const teamDeleteMutation = useMutation(TEAM_DELETE);
  

  const handleAddManager = id => async (e) => {
    
    e.preventDefault();
    const userListData = [...users];
    const userData = userListData.splice(users.findIndex(i => i.id === id), 1);
    setUsers(userListData);
    setManagers([...managers, ...userData]);
    try {
      const {
        data: { addManager }
      } = await addManagerMutation({
        variables: {
          id: teamId,
          oppId:id
        }
      });

      if (addManager) {
        refetch();
      }
    } catch (e) {
      // toast.error("요청에 실패하였습니다.");
    } 
  }


  const handleRemoveManager = id => async (e) => {
    console.log(id, e);
    e.preventDefault();

    const userListData = [...managers];
    const userData = userListData.splice(users.findIndex(i => i.id === id), 1);
    setManagers(userListData);
    setUsers([...users, ...userData]);

    try {
      const {
        data: { removeManager }
      } = await removeManagerMutation({
        variables: {
          id: teamId,
          oppId:id
        }
      });

      if (removeManager) {
        console.log("refetch!!");
        refetch();
      }
    } catch (e) {
      //toast.error("요청에 실패하였습니다.");
    } 
  }

  const handleKickback = async (e) => {

    e.preventDefault();
    setApplying(true);
    handleCloseKickBack();
    try {
      const {
        data: { kickbackUser }
      } = await kickbackMutation({
        variables: {
          id: teamId,
          oppId:selectUser.id
        }
      });

      if (kickbackUser) {

        if(managers.findIndex(i => i.id === selectUser.id) !== -1) {
     
          const managerListData = [...managers];
          console.log(selectUser);
          managerListData.splice(managers.findIndex(i => i.id === selectUser.id), 1);
          setManagers(managerListData);
        } else if(users.findIndex(i => i.id === selectUser.id) !== -1) {

          const userListData = [...users];
          userListData.splice(users.findIndex(i => i.id === selectUser.id), 1);
          setUsers(userListData);
        }

        refetch();
        toast.success("해당 회원을 강퇴하였습니다.");
      } else {
        toast.error("오류가 발생했습니다.");
      }
    } catch (e) {
      if(e.message.indexOf("Found") !== -1) {
        toast.error("해당 회원은 팀원이 아닙니다.");
      } else {
        toast.error("오류가 발생했습니다.");
      }
    } finally {
      setApplying(false);
      handleCloseKickBack();
    }
  }


  const handleWithdraw = async (e) => {
    e.preventDefault();
    setApplying(true);
    handleCloseWithDraw();
    if(host.id === meUser.id && managers.length === 0) {
      setApplying(false);
      toast.error("최소한 한 명 이상의 다른 호스트가 필요합니다.");
      handleCloseWithDraw();
      return;
    }

    try {
      const {
        data: { withdrawTeam }
      } = await widthdrawMutation({
        variables: {
          id: teamId
        }
      });

      if (withdrawTeam) {
        if(host.id === meUser.id) {
          setHost(null);
        }
        else if(managers.findIndex(i => i.id === meUser.id) !== -1) {
          const managerListData = [...managers];
          managerListData.splice(managers.findIndex(i => i.id === meUser.id), 1);
          setManagers(managerListData);
        } else if(users.findIndex(i => i.id === meUser.id) !== -1) {
          const userListData = [...users];
          userListData.splice(users.findIndex(i => i.id === meUser.id), 1);
          setUsers(userListData);
        }
        
        setCanRequestState(true);
        setIsMineState(false);
        setIsInviteState(false);
        setIsRequestState(false);
        
        toast.success("탈퇴되었습니다.");
    
      } else {
        toast.error("요청에 실패하였습니다.");
      }
    } catch (e) {
      //toast.error(e.message)
      console.log(e.message);
      toast.error("오류가 발생했습니다.");
    } finally {
      setApplying(false);
    }
  }

  const handleTeamDelete = async () => {
    try {
      setApplying(true);
      setIsSend(true);

      const {
        data: { editTeam }
      } = await teamDeleteMutation({
        variables: {
          id: teamId,
          action:"DELETE"
        }
      });

      if (editTeam.id) {
        toast.success("팀이 삭제되었습니다.");
        history.push('/');
      }
    } catch (e) {
      setIsSend(false);
      toast.error("요청에 실패하였습니다.");
    } finally {
      setApplying(false);
    }
  };
  

  const handleApply = async () => {
    try {
      setApplying(true);
      setIsSend(true);

      const {
        data: { reqTeam }
      } = await applyMutation({
        variables: {
          id: teamId
        }
      });

      if (reqTeam) {
        toast.success("팀 가입을 요청하였습니다.");
        await refetch();

      }
    } catch (e) {
      if(e.message.indexOf("already") !== -1) {
        toast.error("이미 가입했거나 요청 중입니다.");
      } else {
        toast.error("요청에 실패하였습니다.");
        setIsSend(false);
      }
      
    } finally {
      setApplying(false);
    }
  };
  return (
    <TeamProfilePresenter
      loading={loading}
      data={data}
      dataRequest={dataRequest}
      error={error}
      applying={applying}
      isSend={isSend}
      handleApply={handleApply}
      hostEditMode={hostEditMode}
      handleEditMode={handleEditMode}
      handleAddManager={handleAddManager}
      handleRemoveManager={handleRemoveManager}
      handleKickback={handleKickback}
      handleWithdraw={handleWithdraw}
      handleTeamDelete={handleTeamDelete}

      tabValue={tabValue}
      setTabValue={setTabValue}
      openKickBack={openKickBack}
      setOpenKickBack={setOpenKickBack}
      openWithDraw={openWithDraw}
      openTeamEdit={openTeamEdit}
      setOpenTeamEdit={setOpenTeamEdit}
      handleOpenTeamEdit={handleOpenTeamEdit}
      handleCloseTeamEdit={handleCloseTeamEdit}
      setOpenWithDraw={setOpenWithDraw}
      handleOpenKickBack={handleOpenKickBack}
      handleOpenWithDraw={handleOpenWithDraw}
      handleCloseKickBack={handleCloseKickBack}
      handleCloseWithDraw={handleCloseWithDraw}
      openWithDrawForHost={openWithDrawForHost}
      handleOpenWithDrawForHost={handleOpenWithDrawForHost}
      handleCloseWithDrawForHost={handleCloseWithDrawForHost}
      openTeamDelete={openTeamDelete}
      handleOpenTeamDelete={handleOpenTeamDelete}
      handleCloseTeamDelete={handleCloseTeamDelete}
      handleReqInvitePage={handleReqInvitePage}
      handleTeamInfoEditPage={handleTeamInfoEditPage}

      selectUser={selectUser}
      setMeUser={setMeUser}
      host={host}
      setHost={setHost}
      managers={managers}
      setManagers={setManagers}
      users={users}
      setUsers={setUsers}
      history={history}
      newTeamInfo={newTeamInfo}

      isMineState={isMineState}
      setIsMineState={setIsMineState}
      isHostState={isHostState}
      setIsHostState={setIsHostState}
      isManagerState={isManagerState}
      setIsManagerState={setIsManagerState}
      isInviteState={isInviteState}
      setIsInviteState={setIsInviteState}
      isRequestState={isRequestState}
      setIsRequestState={setIsRequestState}
      canRequestState={canRequestState}
      setCanRequestState={setCanRequestState}

      refreshing={refreshing}
      onRefresh={onRefresh}
    />
  );
};

export default withRouter(TeamProfileContainer);
