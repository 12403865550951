import React, { useEffect } from "react";
import styled from "styled-components";
import moment from "moment";
import "moment/locale/ko";
import { Helmet } from "rl-react-helmet";

import Loader from "../../Components/Loader";
import BaseHeader from "../../Components/Header/BaseHeader";

import Card from "@material-ui/core/Card";
import { Link } from "react-router-dom";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { CardMedia, CircularProgress, Dialog, Slide, Collapse } from "@material-ui/core";
import Avatar from "../../Components/Avatar";
import GameResultText from "../../Components/GameResultText";
import Button from "../../Components/Button";
import { Gear } from "../../Components/Icons";
import PullToRefresh from "rmc-pull-to-refresh";
import DedicateLoader from "../../Components/DedicateLoader";
import RefreshLoader from "../../Components/RefreshLoader";

const Wrapper = styled.div`
  min-height: calc(100vh - 76px);
  background-color:#fff;
`;

const ContentWrapper = styled.div`
  /* padding-top: 56px; */
  /* margin: auto 10px; */
  background-color: ${props => props.bgColor};
  /* min-height: 100vh;
  max-height: 100vh; */
  overflow: auto;
  position: relative;
  /* & > div {
    margin-bottom: 5px;
  } */
  color: #000;
  @media (min-width: 500px) {
    max-width: ${props => props.theme.maxWidth};
    margin: 0 auto;
  }
`;

const LoadingWrapper = styled(ContentWrapper)`
  padding-top: 56px;
  min-height: 100vh;
  position: relative;
`;

const TeamName = styled.span`
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  padding-bottom: 7px;
  position: relative;
`;

const HistoryWrap = styled.ul`
  padding: 0;
`;

const Index = styled.div`
  width: 100%;
  background-color: #f2f2f2;
  border-bottom: 1px solid #ddd;
  line-height: 32px;
  text-align: left;
  color: #333;
  font-size: 14px;
`;

const IndexMonth = styled.span`
  padding: 0 10px;
  font-weight: 600;
`;

const IndexResult = styled.span`
  float: right;
  font-size: 13px;
  color: #666;
  text-align: right;
  padding: 0;
  padding-right: 10px;
  & > span {
    font-weight: 600;
    margin-left:5px;
  }
`;

const HistoryList = styled.li`
  list-style: none;
  width: 100%;
  background-color: #fff;
  border-bottom: 1px solid #ddd;
  padding: 24px 0;
  cursor: pointer;
  overflow: auto;
  display: table;
`;

const LoaderWapeer = styled.div`
  width: 100%;
  min-height: 60px;
  background-color: #fff;
  & > .MuiCircularProgress-colorPrimary {
    position: relative;
    top: 5px;
    z-index: 999;
    width: 35px !important;
    height: 35px !important;
    color: #1ea9e1;
  }
`;

const ScheduleDate = styled.div`
  text-align: center;
  color: #2277ff;
  width: 20%;
  font-family: "exo 2", sans-serif;
  display: table-cell;
  vertical-align: middle;
`;

const ScheduleDay = styled.h3`
  background-color: #f2f2f2;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  font-size: 21px;
  font-weight: 800;
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ScheduleInfo = styled.div`
  font-size: 13px;
  display: table-cell;
  vertical-align: middle;
  & > p {
    color: #999;
    font-size: 12px;
  }
`;

const ScheduleResult = styled.div`
  text-align: center;
  width: 20%;
  font-family: "exo 2", sans-serif;
  border-left: 1px solid #ddd;
  display: table-cell;
  vertical-align: middle;
`;

const ScoreText = styled.h3`
  color: #333;
  font-size: 16px;
  font-weight: 800;
  margin-bottom: 7px;
`;

const CancelList = styled(HistoryList)`
  cursor: none;
  background-color: #eee;
  & ${TeamName} {
    color: #999;
  }
  & ${ScoreText} {
    color: #999;
  }
`;

const HeaderAvatar = styled.div`
  border: 1px solid #fff;
  border-radius: 50%;
  padding: 6px;
  position: absolute;
  top: ${props => props.loading? "115px":"60px"};
  left: 50%;
  transform: translateX(-50%);
`;

const StatusSpan = styled.span`
  background-color: ${props => props.bgColor};
  /* background-color:#f50057; */
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  border-radius: 6px;
  padding: 3px;
  margin-left: 5px;
  position: relative;
  top: -3px;
  /* position: absolute; */
`;

const MVPSpan = styled.span`
  /* background-color: ${props => props.bgColor}; */
  background: linear-gradient(110deg, #ffd421 0%, #ffd421 50%, transparent 32%), linear-gradient(-70deg, #ffbe21 0%, #ffbe21 50%, transparent 27%);
  border-radius: 1px;
  color: #000;
  font-size: 12px;
  font-weight: 600;
  padding: 3px;
  margin-left: 5px;
  position: absolute;
`;

// const NoHistory = styled.div`
//     width: 95%;
//     border-radius: 10px;
//     font-size: 19px;
//     font-weight: 500;
//     text-align: center;
//     /* padding: 20px; */
//     height: 70px;
//     display: flex;
//     margin: 0 auto;
//     box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
//     justify-content: center;
//     align-items: center;
// `;

const EmptySearchResult = styled.div`
  margin: 0px 15px;
  padding: 15px 0px;
  color: #969696;
  font-size: 14px;
  border-bottom: 1px solid #d5d5d5;
`;

const _gameWeekText = gameDate => {
  let week = ["일", "월", "화", "수", "목", "금", "토"];
  let date = new Date(gameDate);
  return `(${week[date.getDay()]})`;
};

const _gameTimeText = time => {
  const hour = parseInt(time.split(":")[0]);
  const minute = time.split(":")[1];
  const ampm = hour >= 12 ? "오후" : "오전";
  if (hour > 12) return `${ampm} ${hour - 12}:${minute}`;
  else return `${ampm} ${hour}:${minute}`;
};


const styles = {
  card: {
    borderRadius: 0,
    backgroundColor: "transparent"
  },
  media: {
    height: 0,
    backgroundColor: "#c9c9c9",
    paddingTop: "56.25%" // 16:9
  },
  teamEditDialog: {
    "& .MuiDialog-container": {
      alignItems: "flex-end"
    },
    "& .MuiDialog-paperWidthSm": {
      width: "calc(100% - 30px)",
      margin: 0,
      maxWidth: 480,
      marginBottom: 75,
      borderRadius: 13
    }
  },
};

const HistoryPresenter = ({
  loading,
  data,
  dataTeam,
  loadingTeam,
  games,
  setGames,
  onScroll,
  loadMore,
  canMore,
  teamId,
  updateHistoryData,
  classes,
  openGameManage,
  handleOpenGameManage,
  handleCloseGameManage,

  refreshing,
  onRefresh,
  MoveToGameManage,
  MoveToGameCreate
}) => {

  if (loading) {
    return (
      <Wrapper>
        <BaseHeader text={"경기 일정/결과"} isBack={true} isClose={false} />
        <Helmet>
          <title>경기 일정/결과 | WePlay</title>
        </Helmet>
        {/* <Loader background={true} text={"데이터를 불러오는 중입니다."} /> */}
        <LoadingWrapper bgColor={"#fff"}>
          {!loadingTeam && dataTeam && dataTeam.seeFullTeam && (
            <>
              <Card className={classes.card}>
                <CardMedia
                  className={classes.media}
                  image={dataTeam.seeFullTeam.profile && dataTeam.seeFullTeam.profile.length > 0 ? dataTeam.seeFullTeam.profile[0].url : ""}
                  title="Paella dish"
                />
                <HeaderAvatar loading={true}>
                  <Avatar url={dataTeam.seeFullTeam.logo} size={"lmd"} />
                </HeaderAvatar>
              </Card>
            </>
          )}
          <Loader background={true} text={"데이터를 불러오는 중입니다."} />
          {/* {loadingTeam && <Loader background={true} />} */}
          <HistoryWrap>
          </HistoryWrap>
        </LoadingWrapper>
        
      </Wrapper>
    );
  } 
  else if(data && data.seeGameHistorys && dataTeam && dataTeam.seeFullTeam) {
    const {
      seeFullTeam: { logo, profile, isHost, isManager }
    } = dataTeam;

    useEffect(() => {
      const _historys = data.seeGameHistorys;

      setGames(
        _historys.reduce((acc, item) => {
          (acc[moment(item.gameDate).format("YYYY-MM")] =
            acc[moment(item.gameDate).format("YYYY-MM")] || []).push(item);
          return acc;
        }, {})
      );

      //window.addEventListener('scroll', onScroll, true);
    }, [data, dataTeam]);

    useEffect(() => {
      if (updateHistoryData !== undefined) {
        const { updateHistorys } = updateHistoryData;
        const _historys = data.seeGameHistorys;
        _historys[_historys.findIndex(i => i.id === updateHistorys.id)] = updateHistorys;
        setGames(
          _historys.reduce((acc, item) => {
            (acc[moment(item.gameDate).format("YYYY-MM")] =
              acc[moment(item.gameDate).format("YYYY-MM")] || []).push(item);
            return acc;
          }, {})
        );
  
      }
    }, [updateHistoryData]);

    //console.log(historys);
    //console.log(games);

    return (
      <Wrapper>
        <BaseHeader text={"경기 일정/결과"} isBack={true} isClose={false} />
        <Helmet>
          <title>경기 일정/결과 | WePlay</title>
        </Helmet>
        {(isHost || isManager) && (
          <TeamEditToggleButton onClick={handleOpenGameManage}>
            <Gear size={24} />
          </TeamEditToggleButton>
        )}

        <PullToRefresh
          style={{ overflow: "auto", paddingTop:56, minHeight:"100vh", maxHeight:"100vh" }}
          direction="down"
          distanceToRefresh={50}
          damping={100}
          onRefresh={() => onRefresh()}
          refreshing={refreshing}
          onScroll={e => onScroll(e)}
          indicator={{
            activate: <DedicateLoader top={"-50px"} />,
            deactivate: <></>,
            release: <RefreshLoader top={"-30px"} />,
            finish: <></>
          }}
        >

        <ContentWrapper bgColor={"#fff"} >
          <Card className={classes.card}>
            <CardMedia
              className={classes.media}
              image={profile && profile.length > 0 ? profile[0].url : ""}
              title="Paella dish"
            />
            <HeaderAvatar loading={false}>
              <Avatar url={logo} size={"lmd"} />
            </HeaderAvatar>
          </Card>

          <HistoryWrap>
            {data.seeGameHistorys.length === 0 && (
              <EmptySearchResult>경기 이력이 없습니다.</EmptySearchResult>
            )}
            {games &&
              Object.keys(games).map((key, i) => {
                const MONTH = key.split("-")[1];
                const WIN_COUNT = games[key].filter(g => g.winner === teamId)
                  .length;
                const LOSE_COUNT = games[key].filter(
                  g => g.winner && g.winner !== "draw" && g.winner !== teamId
                ).length;

                return (
                  <div key={i}>
                    <Index>
                      <IndexMonth>{MONTH}월</IndexMonth>
                      <IndexResult>
                        <span>{WIN_COUNT}</span>승
                        <span>{LOSE_COUNT}</span>패
                      </IndexResult>
                    </Index>
                    {games[key].map(game => {
                      let routing = `/game/${game.id}`;
                      if (game.gameStatus === "MATCH") {
                        routing = `/vote/t/${teamId}/g/${game.id}`;
                      }
                        

                      if (game.gameStatus === "CANCEL") {
                        return (
                          <CancelList key={game.id}>
                            <ScheduleDate>
                              <ScheduleDay>
                                {moment(game.gameDate).format("DD")}
                              </ScheduleDay>
                            </ScheduleDate>
                            <ScheduleInfo>
                              <TeamName>모집된 팀이 없습니다.</TeamName>
                              <p>
                                {moment(game.gameDate).format("YYYY. MM. DD")}
                                {_gameWeekText(game.gameDate)}{" "}
                                {_gameTimeText(game.startTime)}
                              </p>
                              <p>{game.place.placeName}</p>
                            </ScheduleInfo>

                            <ScheduleResult>
                              <ScoreText>경기취소</ScoreText>
                            </ScheduleResult>
                          </CancelList>
                        );
                      } else {
                        return (
                          <Link key={game.id} to={routing}>
                            <HistoryList key={game.id}>
                              <ScheduleDate>
                                <ScheduleDay>
                                  {moment(game.gameDate).format("DD")}
                                </ScheduleDay>
                              </ScheduleDate>
                              <ScheduleInfo>
                                {game.gameStatus === "RECRUIT" && (
                                  <TeamName>?</TeamName>
                                )}
                                {game.awayTeam && (
                                  <>
                                    <TeamName>
                                      {game.homeTeam.id === teamId
                                        ? game.awayTeam.teamName
                                        : game.homeTeam.teamName}
                                    </TeamName>
                                    {game.gameStatus === "MATCH" && (
                                      game.didVote? (
                                        <StatusSpan bgColor={"#227fff"}>
                                            투표 완료
                                        </StatusSpan>
                                      ):(
                                        <StatusSpan bgColor={"#ff005b"}>
                                          출전 확인 중
                                        </StatusSpan>
                                      )
                                    )}

                                    {game.gameStatus === "FINISH" && (
                                      <StatusSpan bgColor={"#B3003E"}>
                                        승점확인 필요
                                      </StatusSpan>
                                    )}

                                    {game.gameStatus === "COMPLETE" &&
                                      game.usr_MVP && (
                                        <MVPSpan>
                                          MVP:{game.usr_MVP.username}(
                                          {game.usr_MVP.basket.backNo})
                                        </MVPSpan>
                                      )}
                                  </>
                                )}
                                {/* <TeamName>
                                  {game.homeTeam.id === teamId
                                    ? game.awayTeam.teamName
                                    : game.homeTeam.teamName}
                                </TeamName> */}
                                <p>
                                  {moment(game.gameDate).format("YYYY. MM. DD")}
                                  {_gameWeekText(game.gameDate)}{" "}
                                  {_gameTimeText(game.startTime)}
                                </p>
                                <p>{game.place.placeName}</p>
                              </ScheduleInfo>

                              {game.gameStatus === "MATCH" && (
                                <ScheduleResult>
                                  <ScoreText>매칭 완료</ScoreText>
                                </ScheduleResult>
                              )}
                              {game.gameStatus === "PLAYING" && (
                                <ScheduleResult>
                                  <ScoreText>경기중</ScoreText>
                                </ScheduleResult>
                              )}
                              {game.gameStatus === "FINISH" && (
                                <ScheduleResult>
                                  <ScoreText>경기종료</ScoreText>
                                </ScheduleResult>
                              )}

                              {game.gameStatus === "RECRUIT" && (
                                <ScheduleResult>
                                  <ScoreText>모집중</ScoreText>
                                </ScheduleResult>
                              )}

                              {/* {(game.gameStatus === "CONFIRM" ||
                                game.gameStatus === "COMPLETE") &&
                                game.result && (
                                  <ScheduleResult>
                                    <ScoreText>
                                      {game.homeTeam.id === teamId
                                        ? `${game.result.homeScore}:${game.result.awayScore}`
                                        : `${game.result.awayScore}:${game.result.homeScore}`}
                                    </ScoreText>

                                    {game.winner && game.winner === "draw" ? (
                                      <GameResultText state={game.winner} />
                                    ) : (
                                      <GameResultText
                                        state={
                                          game.winner === teamId
                                            ? "win"
                                            : "lose"
                                        }
                                      />
                                    )}
                                  </ScheduleResult>
                                )} */}


                              {(game.gameStatus === "CONFIRM" ||
                                game.gameStatus === "COMPLETE") &&
                                game.result && (
                                  <ScheduleResult>
                                    {game.result.homeScore === 0 && game.result.awayScore === 0 ? (
                                      <ScoreText>
                                        미입력
                                      </ScoreText>
                                    ):(
                                      <>
                                        <ScoreText>
                                          {game.homeTeam.id === teamId
                                            ? `${game.result.homeScore}:${game.result.awayScore}`
                                            : `${game.result.awayScore}:${game.result.homeScore}`}
                                        </ScoreText>
                                        {game.winner && game.winner === "draw" ? (
                                          <GameResultText state={game.winner} />
                                        ) : (
                                          <GameResultText
                                            state={
                                              game.winner === teamId
                                                ? "win"
                                                : "lose"
                                            }
                                          />
                                        )}
                                      </>
                                    )}
                                   

                                    
                                  </ScheduleResult>
                                )}
                            </HistoryList>
                          </Link>
                        );
                      }
                    })}
                  </div>
                );
              })}
          </HistoryWrap>
          {canMore && (
            <LoaderWapeer>{loadMore && <CircularProgress />}</LoaderWapeer>
          )}
        </ContentWrapper>

        </PullToRefresh>

        <Dialog
          className={classes.teamEditDialog}
          open={openGameManage}
          onClose={handleCloseGameManage}
          TransitionComponent={Transition}
        >
          <UserEditWrapper>
            <TeamEditContent in={openGameManage}>
              <TeamEditButton
                onClick={MoveToGameManage}
                textColor={"#227fff"}
              >
                도전장 관리
              </TeamEditButton>
              <TeamEditButton
                onClick={MoveToGameCreate}
                textColor={"#227fff"}
              >
                경기 생성
              </TeamEditButton>

            </TeamEditContent>

            <Button
              color={"#000"}
              text={"취소"}
              onClick={handleCloseGameManage}
            />
          </UserEditWrapper>
        </Dialog>
      </Wrapper>
    );
  }

  return null;
};

const UserEditWrapper = styled.div`
  background-color: #f2f2f2;
  border-radius: 5px;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const TeamEditContent = styled(Collapse)`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  line-height: 1.5em;
  border-color: 10px;
  background-color: #fff;
  & > div.MuiCollapse-wrapper {
    width: 100%;
  }
`;

const TeamEditButton = styled.div`
  font-size: 20px;
  font-weight: 500;
  color: ${props => props.textColor};
  width: 100%;
  border-bottom: 1px solid #999;
  padding: 18px 0px;
  cursor: pointer;
`;

const TeamEditToggleButton = styled.div`
  position: fixed;
  z-index: 997;
  top: 16px;
  right: 16px;
`;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default withStyles(styles)(HistoryPresenter);
