import { gql } from "apollo-boost";

export const GET_ME = gql`
  {
    me {
      id
      username
    }
  }
`;

export const ADD_ROOM = gql`
  mutation addRoom(
    $pictures: [String!]!
    $sportId: String!
    $playerCnt: Int!
    $gameDate: String!
    $startTime: String!
    $endTime: String!
    $title: String!
    $message: String!
    $contact: String
    $price:String!
    $purpose:String!
    $placeName:String!
    $address:String!
    $shortAddress:String!
    $lat:Float!
    $lng:Float!
  ) {
    addRoom(
      pictures:$pictures
      sportId: $sportId
      playerCnt: $playerCnt
      gameDate: $gameDate
      startTime: $startTime
      endTime: $endTime
      title: $title
      message: $message
      contact: $contact
      price:$price
      purpose:$purpose
      placeName:$placeName
      address:$address
      shortAddress:$shortAddress
      lat:$lat
      lng:$lng
    ) {
      id
    }
  }
`;

export const EDIT_PLACE = gql`
  mutation editPlace(
    $id: String!
    $locationId: String!
    $lat:String!
    $lng: String!
    $placeName: String!
    $address: String!
    $shortAddress: String!
  ) {
    editPlace(
      id : $id
      locationId: $locationId
      lat: $lat
      lng: $lng
      placeName: $placeName
      address: $address
      shortAddress: $shortAddress
    ) {
      id
      placeName
    }
  }
`;

