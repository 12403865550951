import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import {
  GET_USER,
  EDIT_PROFILE,
  UPSERT_BASKET,
  DECLINE_TEAM,
  ACCEPT_TEAM,
  FIND_OR_CREATE_CHAT,
  INVITE_USER
} from "./ProfileQueries";
import ProfilePresenter from "./ProfilePresenter";
import { useQuery, useMutation } from "react-apollo-hooks";
import useInput from "../../Hooks/useInput";
import { toast } from "../../Components/Popup";

import axios from "axios";
import loadImage from "blueimp-load-image";
import Client from "../../Apollo/Client";
import { GET_CHATS } from "../ChatList/ChatListQueries";

const ProfileContainer = ({ match: { params }, history }) => {
  const id = params.id;
  const [editMode, setEditMode] = useState(false);
  const { data, loading, refetch } = useQuery(GET_USER, {
    variables: {
      id
    },
    //fetchPolicy: "network-only"
  });
  const [uploading, setUploading] = useState(false);

  // 농구 관련 정보
  const [basketId, setBasketId] = useState("");
  //const sportId = localStorage.getItem("sport");

  const usernameInput = useInput("", newValue => newValue.length < 15);
  const contactInput = useInput("", newValue=> /^[0-9-]{1,20}$/g.test(newValue), false, true);
  const positionInput = useInput("");

  const backNoInput = useInput(0, newValue => !isNaN(newValue) && newValue.length < 4 );
  const heightInput = useInput(0, newValue => !isNaN(newValue) && parseInt(newValue) <= 300 );
  const weightInput = useInput(0, newValue => !isNaN(newValue) && parseInt(newValue) <= 300 );
  const ageInput = useInput(0, newValue => !isNaN(newValue) && parseInt(newValue) <= 100 );

  const region1Input = useInput("");
  const region2Input = useInput("");
  const [level, setLevel] = useState();
  const [grade, setGrade] = useState();

  const [previewUrl, setPreviewUrl] = useState();
  const [previewImg, setPreviewImg] = useState();
  const [avatarUrl, setAvatarUrl] = useState();
  const [avatar, setAvatar] = useState();

  const [isSend, setIsSend] = useState(false);
  const [isInvite, setIsInvite] = useState(false);

  const [myTeam, setMyTeam] = useState();
  const editProfileMutation = useMutation(EDIT_PROFILE);
  const upsertBasketMutation = useMutation(UPSERT_BASKET);
  const acceptTeamMutation = useMutation(ACCEPT_TEAM);
  const declineTeamMutation = useMutation(DECLINE_TEAM);

  const findOrCreateChatMutation = useMutation(FIND_OR_CREATE_CHAT, {
    refetchQueries:[{query:GET_CHATS}]
  });
  const inviteMutation = useMutation(INVITE_USER);

  const [isRegistered, setRegistered] = useState(false);
  const [refreshing, setRefreshing] = useState();


  const onRefresh = async () => {
    //console.log(currentTarget);
    try {
      setRefreshing(true);
      await refetch();
    } catch(e) {
      console.log(e);
    } finally {
      setRefreshing(false);
    }
  };


  const onClickClose = () => {
    history.goBack();
  };


  const handleEditMode = () => {

    const {
      seeUser: {
        username,
        age,
        avatar,
        basket,
      }
    } = data;

    usernameInput.setValue(username);
    ageInput.setValue(age ? age : "0");
    setAvatarUrl(avatar);
    setAvatar(null);
    setPreviewUrl(null);
    setPreviewImg(false);

    if (basket) {
      if (basket.position) positionInput.setValue(basket.position);

      heightInput.setValue(basket.height ? basket.height : 0);
      weightInput.setValue(basket.weight ? basket.weight : 0);
      backNoInput.setValue(basket.backNo ? basket.backNo : 0);

      region1Input.setValue(
        basket.regions.length > 0 ? basket.regions[0] : ""
      );
      region2Input.setValue(
        basket.regions.length > 1 ? basket.regions[1] : ""
      );

      setLevel(basket.level ? basket.level : 0);
      setGrade(basket.grade ? basket.grade : "D");
      setBasketId(basket.id);
    }


    setEditMode(!editMode);
  };

  const handleImageChange = e => {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];
    if(file && file !== undefined) {
      const fileType = file.type;
      loadImage(file, (img) => {
  
        setPreviewImg(true);
        document.getElementById("avatar").innerHTML="";
        document.getElementById("avatar").prepend(img);
  
        img.toBlob(blob => {
          const createdFile = new File([blob], file.name);
          setAvatar(createdFile);
        }, fileType);
      }, { 
        orientation:true, 
        maxWidth:300,
        maxHeight:300,
        cover:true,
        canvas:false,
        crop:true
      });
  
      reader.readAsDataURL(file);
    }
    
  };

  const onSubmit = async e => {
    e.preventDefault();

    if (uploading) return false;

    if(usernameInput.value === "") {
      toast.info("사용자 이름을 입력해주세요.");
      return; 
    }

    if(contactInput.value === "") {
      toast.info("연락처를 입력해주세요.");
      return; 
    }

    if(backNoInput.value === "") {
      toast.info("등번호를 입력해주세요.");
      return; 
    }

    if(ageInput.value === "") {
      toast.info("나이를 입력해주세요.");
      return; 
    }

    if(heightInput.value === "") {
      toast.info("신장을 입력해주세요.");
      return; 
    }

    if(weightInput.value === "") {
      toast.info("몸무게를 입력해주세요.");
      return; 
    }

    let addressCheck = true;
    if(region1Input.value !== "세종" && region1Input.value.split(' ').length < 2) {
      addressCheck = false;
    }
    if(region2Input.value !== "세종" && region2Input.value.split(' ').length < 2) {
      addressCheck = false;
    }
    
    if(!addressCheck) {
      toast.info("선호 지역 값을 모두 선택해주세요.");
      return; 
    }



    try {

      setUploading(true);

      let newAvatarUrl;
      if (avatar) {
        const formData = new FormData();
        formData.append("avatar", avatar);

        const {
          data: { location }
        } = await axios.post(
          "https://weplay2921.cafe24.com/api/user/upload",
          //"http://localhost:4000/api/user/upload",
          formData,
          {
            headers: {
              "content-type": "multipart/form-data"
            }
          }
        );
        newAvatarUrl = location;
      }

      // console.log(newAvatarUrl);

      const {
        data: { editUser: user }
      } = await editProfileMutation({
        variables: {
          username: usernameInput.value,
          avatar: newAvatarUrl ? newAvatarUrl : avatarUrl,
          age: ageInput.value,
          contact: contactInput.value
        }
      });

      if (user.id) {
        const {
          data: { upsertBasket: basket }
        } = await upsertBasketMutation({
          variables: {
            id: basketId,
            userId: user.id,
            position: positionInput.value,
            height: parseFloat(heightInput.value),
            weight: parseFloat(weightInput.value),
            backNo: parseInt(backNoInput.value),
            level: parseInt(level),
            grade,
            regions: [region1Input.value, region2Input.value]
          }
        });

        usernameInput.setValue(user.username);
        ageInput.setValue(user.age);
        setAvatarUrl(user.avatar);

        if (basket.position) positionInput.setValue(basket.position);
        heightInput.setValue(basket.height ? basket.height : 0);
        weightInput.setValue(basket.weight ? basket.weight : 0);
        backNoInput.setValue(basket.backNo ? basket.backNo : 0);

        region1Input.setValue(
          basket.regions.length > 0 ? basket.regions[0] : ""
        );
        region2Input.setValue(
          basket.regions.length > 1 ? basket.regions[1] : ""
        );

        toast.success("프로필 정보가 수정되었습니다.");
      }
    } catch (e) {
      console.log(e);
      toast.error("다시 시도해주세요.");
    } finally {
      setEditMode(false);
      setUploading(false);
    }
  };

  const onAcceptTeam = async e => {
    try {
      e.preventDefault();
      setIsSend(true);
      setUploading(true);
      const { data: { acceptTeam } } = await acceptTeamMutation({
        variables: {
          id:data.me.myHostTeam.id,
          reqUserId: id
        }
      });
      refetch();
      setUploading(false);
      console.log(acceptTeam);
      toast.success("가입 요청을 수락했습니다.");
    } catch (e) {
      setIsSend(false);
      toast.error("잘못된 접근입니다.");
    }
  }

  const onDeclineTeam = async e => {
    try {
      e.preventDefault();
      setIsSend(true);
      setUploading(true);
      const { data: {declineTeam } } = await declineTeamMutation({
        variables: {
          id : data.me.myHostTeam.id,
          reqUserId: id
        }
      });
      refetch();
      setUploading(false);
      console.log(declineTeam);
      toast.success("가입 요청을 취소했습니다.");
    } catch (e) {
      setIsSend(false);
      toast.error("잘못된 접근입니다.");
    }
  }

  const onMoveChat = async e => {
    if(isRegistered) {
      try {
        e.preventDefault();
        setUploading(true);
        const { data: { findOrCreateChat } } = await findOrCreateChatMutation({
          variables: {
            userId: id
          }
        });
  
        if(findOrCreateChat.id) 
          history.push(`/chat/${findOrCreateChat.id}`);
  
      } catch (e) {
        toast.error("잘못된 접근입니다.");
      } finally {
        setUploading(false);
      }
    } else {
      toast.error(<div>메세지를 보낼 수 없습니다. <br/>해당 회원은 셀프진단을<br/>진행하지 않았습니다.</div>);
    }
  }

  const onOurChat = id => () => {
    history.push(`/chat/${id}`);
  };


  const handleInvite = async () => {
    
    try {
      //setUploading(true);
      setIsInvite(true);
      
      const {
        data: { inviteUser }
      } = await inviteMutation({
        variables: {
          id: data.me.myHostTeam.id,
          userId: id
        }
      });

      if (inviteUser) {
        Client.writeData({
          id:`User:${id}`,
          data: {
            isInviteToUser:true
          }
        })
      }
    } catch (e) {
      setIsInvite(false);
      toast.error("오류가 발생했습니다.");
    } finally {
      //setUploading(false);
    }
  };

  return (
    <ProfilePresenter

      loading={loading}
      editMode={editMode}
      handleEditMode={handleEditMode}
      previewUrl={previewUrl}
      previewImg={previewImg}
      data={data}
      setBasketId={setBasketId}
      setRegistered={setRegistered}
      setAvatarUrl={setAvatarUrl}
      level={level}
      setLevel={setLevel}
      setGrade={setGrade}
      handleImageChange={handleImageChange}
      usernameInput={usernameInput}
      contactInput={contactInput}
      positionInput={positionInput}
      heightInput={heightInput}
      weightInput={weightInput}
      ageInput={ageInput}
      backNoInput={backNoInput}
      region1Input={region1Input}
      region2Input={region2Input}
      onClickClose={onClickClose}
      onSubmit={onSubmit}
      uploading={uploading}
      onAcceptTeam={onAcceptTeam}
      onDeclineTeam={onDeclineTeam}
      myTeam={myTeam}
      setMyTeam={setMyTeam}
      isSend={isSend}
      onRefresh={onRefresh}
      refreshing={refreshing}

      isInvite={isInvite}
      setIsInvite={setIsInvite}
      handleInvite={handleInvite}

      onOurChat={onOurChat}
      onMoveChat={onMoveChat}
    />
  );
};

export default withRouter(ProfileContainer);
