import React, { useEffect } from "react";
import styled from "styled-components";
import { Helmet } from "rl-react-helmet";
import { makeStyles } from "@material-ui/core/styles";
import Loader from "../../Components/Loader";
import BoldText from "../../Components/BoldText";
import PostCode from "../../Components/PostCode";
import Button from "../../Components/Button";
//import Input from "../../Components/Input";
import { Close, Minus } from "../../Components/Icons";
import BaseHeader from "../../Components/Header/BaseHeader";
import Select from "../../Components/Select";

import Slide from "@material-ui/core/Slide";
import InputBase from "@material-ui/core/InputBase";
import SearchIcon from "@material-ui/icons/Search";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";


const Wrapper = styled.div`
  width: 100%;
  flex-grow: 1;
  & .MuiInputBase-input {
    background-color: #EBEBEB!important;
    border-radius: 8px!important;
    padding: 10px 10px!important;
  }

`;

const UserInfoRows = styled.div`
  flex-grow: 1;
  /* margin: 0px 10px; */
  background-color: #fff;
  color:#000;
  padding-top: 70px;
  padding-bottom:100px;
  min-height: 100vh;
  @media (min-width: 500px) {
    max-width: ${props => props.theme.maxWidth};
    margin: 0 auto;
  }
`;

const UserInfoRow = styled.div`
  /* display: flex;
  font-size: 15px;
  justify-content: space-between;
  align-items: center; */
  font-size:16px;
  padding: 15px 20px;
`;

const SearchWrapper = styled.div`
  margin: auto 10px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color:#fff;
  padding-top: 206px;
  overflow-y: scroll;
`;

const InputInfoColumn  = styled.div`
position: relative;
  &:first-child {
    margin-bottom:7px;
  }
  &:nth-child(2) {
    display: flex;
    flex-direction: column;
    & > input.detail {
      margin-top:10px;
    }
  }
`;

const ProfileColumn  = styled.div`
  width: auto;
  white-space: nowrap;
  overflow-x: auto;
`;

const LogoUploadWapper = styled.div`
  position: relative;
  overflow: hidden;
  display: inline-block;
  width: 110px;
  height: 110px;
  & > input[type=file] {
    position: absolute;
    height:100%;
    left: 0;
    top: 0;
    opacity: 0;
  }
`;

const ProfileUploadWapper = styled(LogoUploadWapper)`
  width: 195px;
  height: 110px;
  margin-right: 15px;
`;

const RemoveBtn = styled.button`
  border: none;
  /* color: gray; */
  background-color: #fff;
  padding: 0px;
  border-radius: 50%;
  font-size: 25px;
  font-weight: bold;
  width: 30px;
  height: 30px;
  position: absolute;
  right: 0;
  bottom: 0;
`;

const PreviewImg = styled.img`
  border: 1px solid #000;
  background-color: white;
  border-radius: 8px;
  width: 100%;
  height: 100%;
`;

const ProfileHint = styled.span`
  font-size:14px;
  padding-left:10px;
`;

const UploadBtn = styled.button`
    border: 1px solid #000;
    color: #000;
    background-color: white;
    padding: 5px;
    border-radius: 8px;
    font-size: 15px;
    width: 100%;
    height: 100%;
    cursor: pointer;
`;

const BioTextarea = styled.textarea`
  width:100%;
  max-width:500px;
  padding: 5px 15px;
  background-color: #EBEBEB;
  border: ${props => props.theme.boxBorder};
  border-radius: 10px;
  color : #000;
  resize:none;
  font-size:16px;
  font-family: 'NanumSqure';
  &:focus {
    background-color: #fff;
    border:1px solid #2b2b2b;
  }
`;

const BottomWrap = styled.div`
  display:flex;
  justify-content:center;
`;

const InviteListButton = styled.button`
    padding: 5px 11px;
    background-color: #fff;
    color: #fff;
    border: 1px solid #888;
    color: #000;
    border-radius: 5px;
`;

const Input = styled.input`
  width: 100%;
  height: 40px;
  max-width: 500px;
  padding: 5px 15px;
  font-size: 14px;
  color : #000;
  background-color:#EBEBEB;
  border: ${props => props.theme.boxBorder};
  border-radius: 10px;
  &:active, &:focus {
    background-color: #fff;
    border:1px solid #2b2b2b;
  }
`;

const InputAdornment = styled.div`
      position: absolute;
    right: 10px;
    top: 10px;
    font-weight: 600;
`;


const useStyles = makeStyles(theme => ({
  toolBar: {
    height: "200px",
    display: "block",
    textAlign:"right",
    paddingTop:"5px"
  },
  appBar: {
    position: "fixed",
    backgroundColor: "#227fff"
  },
  inputSearch: {
    position: "relative",
    top: "25%",
    width:"100%"
  },
  inputSearchIcon: {
    width: theme.spacing(5),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    right:10
  },
  search: {
    position: "relative",
    top: "25%",
    borderBottom: "1px solid",
  },
  searchIcon: {
    width: theme.spacing(5),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  inputRoot: {
    width: "100%",
    color: "inherit",
    fontSize: "14px"
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 5),
    transition: theme.transitions.create("width"),
    width: "100%"
  },
  dropzone : {
    width: "100%",
    height: "100%",
    padding: "5px",
    fontSize: 14,
    color: "#000",
    backgroundColor: "#fff",
    border:"1px solid #000",
    borderRadius: "8px"
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default ({
  uploading,
  onSubmit,
  teamName,
  contact,
  bio,
  uniformColor,
  matchingInfo,
  matchingInfoDetail,
  matchingPosition,
  onClickClose,
  setAddressInfo,
  logo,
  profiles,
  handleLogoChange,
  handleProfileChange,
  handleSelectPlace,
  openSearch,
  handleSearchClose,
  searchPlace,
  searchPlaces,
  searchingPlace,
  onRemoveProfile,

  uniformRef,
  infoRef,
  positionRef,
  bioRef,
  teamNameRef,
  contactRef
}) => {
  const classes = useStyles();

  useEffect(() => {
    matchingInfoDetail.setValue("");
  }, [matchingInfo.value])

  return (
    <Wrapper>
      <Helmet>
        <title>팀 생성 | WePlay</title>
      </Helmet>
      {uploading && <Loader />}
      <BaseHeader isClose={true} text={"팀 생성"} onClickClose={onClickClose} />

      <UserInfoRows>
        <UserInfoRow>
          <InputInfoColumn>
            <BoldText text={"팀명"} />
            <ProfileHint>*</ProfileHint>
          </InputInfoColumn>
          <InputInfoColumn>
            <Input ref={teamNameRef} placeholder={""} {...teamName} />
          </InputInfoColumn>
        </UserInfoRow>

        <UserInfoRow>
          <InputInfoColumn>
            <BoldText text={"대표 연락처"} />
            <ProfileHint>*</ProfileHint>
          </InputInfoColumn>
          <InputInfoColumn>
            <Input ref={contactRef} placeholder={""} {...contact} />
          </InputInfoColumn>
        </UserInfoRow>

        <UserInfoRow>
          <InputInfoColumn>
            <BoldText text={"유니폼 색상"} />
            <ProfileHint>*</ProfileHint>
          </InputInfoColumn>
          <InputInfoColumn>
            <Input ref={uniformRef} placeholder={"ex> 화이트, 레드 ..."} {...uniformColor} />
          </InputInfoColumn>
        </UserInfoRow>

        <UserInfoRow>
          <InputInfoColumn>
            <BoldText text={"매칭 요청사항"} />
            <ProfileHint>*</ProfileHint>
          </InputInfoColumn>
          <InputInfoColumn>
            <Select
              ref={infoRef}
              items={[
                { text: "대관비 보조금", value: "대관비 보조금" },
                { text: "음료수 보조", value: "음료수 보조" },
                { text: "기타", value: "기타" },
                { text: "없음", value: "없음" }
              ]}
              value={matchingInfo.value}
              onChange={matchingInfo.onChange}
            />
            {matchingInfo.value !== "" && matchingInfo.value !== "없음" && (
              <Input placeholder={"상세내용"} className={"detail"} {...matchingInfoDetail} />
            )}
            
          </InputInfoColumn>
        </UserInfoRow>

        <UserInfoRow>
          <InputInfoColumn>
            <BoldText text={"매칭 포지션"} />
            <ProfileHint>*</ProfileHint>
          </InputInfoColumn>
          <InputInfoColumn>
            <Select
              ref={positionRef}
              items={[
                { text: "홈 코트 보유. 홈경기만 가능", value: "홈 코트 보유. 홈경기만 가능" },
                { text: "홈 코트 미 보유 어웨이 경기만 가능", value: "홈 코트 미 보유 어웨이 경기만 가능" },
                { text: "홈 & 어웨이 경기 모두 가능", value: "홈 & 어웨이 경기 모두 가능" }
              ]}
              value={matchingPosition.value}
              onChange={matchingPosition.onChange}
            />
          </InputInfoColumn>
        </UserInfoRow>

        <UserInfoRow>
          <InputInfoColumn>
            <BoldText text={"간략한 소개"} />
            <ProfileHint>* 최대 300자 제한</ProfileHint>
          </InputInfoColumn>
          <InputInfoColumn>
            <BioTextarea ref={bioRef} {...bio} cols="4" rows="5"></BioTextarea>
          </InputInfoColumn>
        </UserInfoRow>

        <UserInfoRow>
          <InputInfoColumn>
            <BoldText text={"로고"} />
          </InputInfoColumn>
          <InputInfoColumn>
            <LogoUploadWapper>
              {logo ? (
                <PreviewImg src={logo.url} />
              ) : (
                <UploadBtn>+ 등록하기</UploadBtn>
              )}
              <Input
                onChange={handleLogoChange}
                placeholder={""}
                type={"file"}
                name={"logo"}
              />
            </LogoUploadWapper>
          </InputInfoColumn>
        </UserInfoRow>

        <UserInfoRow>
          <InputInfoColumn>
            <BoldText text={"팀 프로필 사진"} />
            <ProfileHint>최대 5장</ProfileHint>
          </InputInfoColumn>
          <ProfileColumn>
            {profiles &&
              profiles.length > 0 &&
              profiles.map(profile => (
                <ProfileUploadWapper key={profile.name}>
                  <PreviewImg key={profile.name} src={profile.url} />
                  <RemoveBtn onClick={onRemoveProfile(profile)}>
                    <Minus size={30} />
                  </RemoveBtn>
                </ProfileUploadWapper>
              ))}

            <ProfileUploadWapper>
              <UploadBtn>+ 등록하기</UploadBtn>
              <Input
                onChange={handleProfileChange}
                placeholder={""}
                type={"file"}
                name={"profile"}
              />
            </ProfileUploadWapper>
          </ProfileColumn>
        </UserInfoRow>

        <UserInfoRow>
          <InputInfoColumn>
            <BoldText text={"경기장"} />
          </InputInfoColumn>

          <InputInfoColumn>
            <PostCode setAddressInfo={setAddressInfo} />
          </InputInfoColumn>
        </UserInfoRow>
        <Button color={"#000"} onClick={onSubmit} text={"팀 생성"} />
       
      </UserInfoRows>

      <Dialog
        className={classes.dialog}
        fullScreen
        open={openSearch}
        onClose={handleSearchClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar className={classes.toolBar}>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleSearchClose}
              aria-label="close"
            >
              <Close />
            </IconButton>
            <div className={classes.search}>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                placeholder="장소를 검색하세요"
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput
                }}
                inputProps={{ "aria-label": "search" }}
                value={searchPlace.value}
                onChange={searchPlace.onChange}
              />
            </div>
          </Toolbar>
        </AppBar>

        <SearchWrapper>
          <List>
            {searchingPlace && <CircularProgress />}
            {searchPlaces &&
              searchPlaces.seePlaces &&
              searchPlaces.seePlaces.length > 0 &&
              searchPlaces.seePlaces.map(place => (
                <ListItem key={place.id}>
                  <ListItemText
                    primary={place.placeName}
                    secondary={place.address}
                  />
                  <InviteListButton onClick={handleSelectPlace(place)}>
                    + 선택
                  </InviteListButton>
                </ListItem>
              ))}
          </List>
        </SearchWrapper>
      </Dialog>
    </Wrapper>
  );
};
