import React from "react";
import styled from "styled-components";
import { Helmet } from "rl-react-helmet";
import Loader from "../../Components/Loader";
import CupHeader from "../../Components/CupHeader";
import moment from "moment";
import "moment/locale/ko";

const Wrapper = styled.div``;

const Section = styled.div`
  overflow: hidden;
  max-width: 1024px;
  margin: 0 auto;
  width: 95%;
  padding: 40px 20px 20px;
  background-color: #fff;
  color: #000;
  @media (max-width: 600px) {
    padding: 40px 10px 20px;
  }
`;

const RowWrap = styled.div`
  max-width: 824px;
  margin: 0 auto;
`;

const Row = styled.div`
  border-bottom: 1px solid;
  display: flex;
  font-size: 16px;
  /* text-align: center; */
  padding: 40px 0px;
  @media (max-width: 500px) {
    padding: 20px 0px;
    font-size: 14px;
  }
`;

const Column = styled.div`
  &:first-child {
    flex-basis: 150px;
    padding: 0px 30px;
    font-weight: 700;
    word-break: keep-all;
    @media (max-width: 600px) {
      flex-basis: 95px;
      padding-left: 10px;
    }
  }
  &:last-child {
    flex: 1;
    padding: 0px 15px;
    line-height: 1.8em;
    @media (max-width: 600px) {
      padding: 0px;
      line-height: 1.8em;
    }
  }
`;

const Logo = styled.img`
  max-height: 100px;
  max-width: 150px;
  margin-right: 30px;
  margin-bottom: 30px;
  vertical-align: middle;
  @media (max-width: 500px) {
    max-width: 100px;
    margin-right: 10px;
  }
`;

const Time = styled.span`
      font-size: 0.95em;
    font-weight: 500;
    
`;

const YoutubeLink = styled.p`
  margin-top: 5px;
  & > a {
    color: -webkit-link;
  }
`;

const DateText = styled.span`
  margin-right:10px;
  &:last-child {
    margin-left:10px;
  }
  @media (max-width: 600px) {
    display:block;
  }
`;


const DateInfo = styled.p`
  font-size: 14px;
  margin-top: 6px;
  @media (max-width: 600px) {
    font-size: 12px;
  }
`;

const _gameWeekText = gameDate => {
  let week = ["일", "월", "화", "수", "목", "금", "토"];
  let date = new Date(gameDate);
  return `(${week[date.getDay()]})`;
};

export default ({ loading, data, cupId, onUpdatePage }) => {
  if (loading) {
    return (
      <Wrapper>
        <Helmet>
          <title>대회 정보 | WePlay</title>
        </Helmet>
        <Loader />
        <CupHeader id={cupId} active={"main"} />
      </Wrapper>
    );
  } else if (!loading && data && data.seeCup) {

    const isDay =
      moment.utc(data.seeCup.cupStartDate).format("YYYY-MM-DD") ===
      moment.utc(data.seeCup.cupEndDate).format("YYYY-MM-DD");
    return (
      <Wrapper>
        <Helmet>
          <title>대회 정보 | WePlay</title>
        </Helmet>

        <CupHeader id={cupId} active={"main"} />

        <Section>
          <RowWrap>
            <Row>
              <Column>일시</Column>
              <Column>
                {isDay ? (
                  <>
                    <DateText>
                      {moment(data.seeCup.cupStartDate).format(
                        "YYYY년 MM월 DD일"
                      )}
                      {_gameWeekText(data.seeCup.cupStartDate)}
                    </DateText>
                    <Time>
                      {moment.utc(data.seeCup.cupStartDate).format("A hh:mm")}{" "}
                    </Time>{" "}
                    -
                    <Time>
                      {" "}
                      {moment.utc(data.seeCup.cupEndDate).format("A hh:mm")}
                    </Time>
                  </>
                ) : (
                  <>
                    <DateText>
                      {" "}
                      {moment(data.seeCup.cupStartDate).format(
                        "YYYY년 MM월 DD일"
                      )}
                      {_gameWeekText(data.seeCup.cupStartDate)}
                    </DateText>
                    ~
                    <DateText>
                      {moment(data.seeCup.cupEndDate).format(
                        "YYYY년 MM월 DD일"
                      )}
                      {_gameWeekText(data.seeCup.cupEndDate)}
                    </DateText>
                  </>
                )}

                {/* <Time>
                  {id === "ck3qz5s0d01ip0719kfxbrju4" && "09:00-17:00"}
                </Time> */}
                {data.seeCup.cupDateInfo && (
                  <DateInfo>※ {data.seeCup.cupDateInfo}</DateInfo>
                )}
              </Column>
            </Row>
          </RowWrap>
          <RowWrap>
            <Row>
              <Column>장소</Column>
              <Column>
                {data.seeCup.place.placeName}
                <p>({data.seeCup.place.address})</p>
              </Column>
            </Row>
          </RowWrap>
          <RowWrap>
            <Row>
              <Column>리그 진행</Column>
              <Column>
                {data.seeCup.cupProcess}
                {data.seeCup.youtubeUrl && (
                  <YoutubeLink>
                    (
                    <a
                      href={data.seeCup.youtubeUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {data.seeCup.youtubeUrl}
                    </a>
                    )
                  </YoutubeLink>
                )}
              </Column>
            </Row>
          </RowWrap>
          <RowWrap>
            <Row>
              <Column>대상</Column>
              <Column>
                {data.seeCup.target.split("\n").map((line,i) => {
                  return (
                    <span key={i}>
                      {line}
                      <br />
                    </span>
                  );
                })}
              </Column>
            </Row>
          </RowWrap>

          {data.seeCup.host && data.seeCup.host.length > 0 && (
            <RowWrap>
              <Row>
                <Column>주최</Column>
                <Column>
                  {data.seeCup.host.map(item => (
                    <Logo key={item} src={item}></Logo>
                  ))}
                </Column>
              </Row>
            </RowWrap>
          )}

          {data.seeCup.organizer && data.seeCup.organizer.length > 0 && (
            <RowWrap>
              <Row>
                <Column>주관</Column>
                <Column>
                  {data.seeCup.organizer.map(item => (
                    <Logo key={item} src={item}></Logo>
                  ))}
                </Column>
              </Row>
            </RowWrap>
          )}

          {data.seeCup.support && data.seeCup.support.length > 0 && (
            <RowWrap>
              <Row>
                <Column>후원</Column>
                <Column>
                  {data.seeCup.support.map(item => (
                    <Logo key={item} src={item}></Logo>
                  ))}
                </Column>
              </Row>
            </RowWrap>
          )}

          {data.seeCup.sponsor && data.seeCup.sponsor.length > 0 && (
            <RowWrap>
              <Row>
                <Column>협찬</Column>
                <Column>
                  {data.seeCup.sponsor.map(item => (
                    <Logo key={item} src={item}></Logo>
                  ))}
                </Column>
              </Row>
            </RowWrap>
          )}

          {data.seeCup.contact && (
            <RowWrap>
              <Row>
                <Column>문의전화</Column>
                <Column>
                  {data.seeCup.contact.split("\n").map((line,i) => {
                    return (
                      <span key={i}>
                        {line}
                        <br />
                      </span>
                    );
                  })}
                </Column>
              </Row>
            </RowWrap>
          )}

          {data.seeCup.joinPrice && (
            <RowWrap>
              <Row>
                <Column>참가비</Column>
                <Column>
                  {data.seeCup.joinPrice.split("\n").map((line,i) => {
                    return (
                      <span key={i}>
                        {line}
                        <br />
                      </span>
                    );
                  })}
                </Column>
              </Row>
            </RowWrap>
          )}

          {data.seeCup.DepositAccount && (
            <RowWrap>
              <Row>
                <Column>입금계좌</Column>
                <Column>
                  {data.seeCup.DepositAccount.split("\n").map((line,i) => {
                    return (
                      <span key={i}>
                        {line}
                        <br />
                      </span>
                    );
                  })}
                </Column>
              </Row>
            </RowWrap>
          )}
        </Section>
      </Wrapper>
    );
  }
};
