import React, { useState, useEffect, useRef } from "react";
import { withRouter } from "react-router-dom";
import { CREATE_TEAM, SEARCH_PLACE } from "./TeamCreateQueries";
import TeamCreatePresenter from "./TeamCreatePresenter";
import { useQuery, useMutation } from "react-apollo-hooks";
import useInput from "../../Hooks/useInput";
import { toast } from "../../Components/Popup";
import axios from "axios";


const TeamCreateContainer = ({ history }) => {
  const [uploading, setUploading] = useState(false);

  const teamName = useInput("");
  const contact = useInput("", newValue=> /^[0-9-]{1,20}$/g.test(newValue), false, true);
  const area = useInput("");
  const sport = useInput("1001");
  const bio = useInput("", newValue=> newValue.length < 300);

  // +
  const uniformColor = useInput("");
  const matchingInfo = useInput("");
  const matchingInfoDetail = useInput("");
  const matchingPosition = useInput("");

  //const [place, setPlace] = useState("");
  //const [addressS, setAddress] = useState("");

  const searchPlace = useInput("");
  const [openSearch, setOpenSearch] = useState(false);
  const [searchingPlace, setSearchingPlace] = useState(false);

  const { loading: loadingPlace, data: searchPlaceData } = useQuery(
    SEARCH_PLACE,
    {
      skip: searchPlace.value === "" && searchPlace.value.length <= 1,
      variables: {
        first: 30,
        skip: 0,
        term: searchPlace.value
      }
    }
  );

  const [placeName, setPlaceName] = useState();
  const [address, setAddress] = useState();
  const [shortAddress, setShortAddress] = useState();
  const [lat, setLat] = useState(0);
  const [lng, setLng] = useState(0);

  
  const [logo, setLogo] = useState();
  const [profiles, setProfiles] = useState([]);
  
  const teamNameRef = useRef();
  const contactRef = useRef();
  const bioRef = useRef();
  const uniformRef = useRef();
  const infoRef = useRef();
  const positionRef = useRef();

  const createTeamMutation = useMutation(CREATE_TEAM);



  const handleSearchOpen = () => {
    setOpenSearch(true);
  };

  const handleSearchClose = () => {
    setOpenSearch(false);
  };

  const handleSelectPlace = place => () => {
    setAddress(place.address);
    handleSearchClose();
  };

  // 경기장 검색 값 변할 경우 리렌더링
  useEffect(() => {
    if (searchPlace.value.length > 1) {
      setSearchingPlace(true);
    } else {
      setSearchingPlace(false);
    }

    if (!loadingPlace && searchPlace) {
      setSearchingPlace(false);
    }
  }, [
    searchPlace.value,
    loadingPlace,
    searchPlaceData,
  ]);

  const handleLogoChange = e => {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    if(file) {
      reader.onloadend = () => {
        file['url'] = reader.result;
        setLogo(file);
      };
  
      reader.readAsDataURL(file);
    }
  };

  const handleProfileChange = e => {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    if(file) {
      reader.onloadend = () => {
        file['url'] = reader.result;
        const data = [...profiles];
        data.push(file);
        setProfiles(data);
        //setPreviewUrl(reader.result);
      };
  
      reader.readAsDataURL(file);
    }
  };

  // 드롭존 내에서 Remove file 할 경우 이벤트
  const onRemoveProfile = removeFile => e => {
    e.preventDefault();

    const data = [...profiles];
    data.splice(
      data.findIndex(i => i.name === removeFile.name),
      1
    );
    setProfiles(data);
  };

  const setAddressInfo = (placeName, address, shortAddress, lat, lng) => {
    setLat(parseFloat(lat));
    setLng(parseFloat(lng));
    setPlaceName(placeName);
    setAddress(address);
    setShortAddress(shortAddress);
  };

  const handleSubmit = async e => {
    e.preventDefault();


    // console.log({
    //   teamName: teamName.value,
    //   area: area.value,
    //   contact: contact.value,
    //   uniformColor:uniformColor.value,
    //   matchingInfo:matchingInfo.value + "/" + matchingInfoDetail.value,
    //   matchingPosition: matchingPosition.value,
    //   sportId: sport.value,
    //   bio: bio.value,
    //   level:1,
    //   placeName:placeName?placeName:"장소이름 없음",
    //   address,
    //   shortAddress,
    //   lat,
    //   lng
    // });

    if (teamName.value === "" ) {
      toast.error("팀 이름을 입력해주세요.");
      teamNameRef.current.focus();
      return;
    }
    if (contact.value === "") {
      toast.error("연락처 정보를 입력해주세요.");
      contactRef.current.focus();
      return;
    }
    if (bio.value === "") {
      toast.error("팀 소개를 입력해주세요.");
      bioRef.current.focus();
      return;
    }

    if (uniformColor.value === "") {
      toast.error("유니폼 색상을 입력해주세요.");
      uniformRef.current.focus();
      return;
    }

    if (matchingInfo.value === "") {
      toast.error("매칭 요청사항을 입력해주세요.");
      infoRef.current.focus();
      return;
    }

    if (matchingPosition.value === "") {
      toast.error("매칭 포지션을 입력해주세요.");
      positionRef.current.focus();
      return;
    }


    try {
      setUploading(true);

      let LogoUrl;
      let ProfileUrls;
      if (logo) {
        const formData = new FormData();
        formData.append("logo", logo);

        const {
          data: { location }
        } = await axios.post(
          "https://weplay2921.cafe24.com/api/team/upload",
          formData,
          {
            headers: {
              "content-type": "multipart/form-data"
            }
          }
        );
        LogoUrl = location;
      }

      if (profiles && profiles.length > 0) {
        const formData = new FormData();
        profiles.forEach(file => {
          formData.append("profile", file);
        });

        const { data } = await axios.post(
          "https://weplay2921.cafe24.com/api/team/profile/upload",
          formData,
          {
            headers: {
              "content-type": "multipart/form-data"
            }
          }
        );

        ProfileUrls = data;
      }

      const {
        data: { createTeam }
      } = await createTeamMutation({
        variables: {
          logo: LogoUrl,
          profiles: ProfileUrls,
          teamName: teamName.value,
          area: area.value,
          contact: contact.value,
          uniformColor:uniformColor.value,
          matchingInfo:matchingInfo.value + "/" + matchingInfoDetail.value,
          matchingPosition: matchingPosition.value,
          sportId: sport.value,
          bio: bio.value,
          level:1,
          placeName:placeName?placeName:"장소이름 없음",
          address,
          shortAddress,
          lat,
          lng
        }
      });

      if (createTeam.id) {
        toast.success("팀 생성 완료!");
        setTimeout(() => {
          history.replace(`/team/${createTeam.id}`);
        }, 1500);
      }

    } catch (e) {
      if(e.message.indexOf("create") !== -1) {
        toast.error("이미 팀에 가입되어 있습니다.");
      } else {
        toast.error("오류가 발생했습니다. 다시 시도해주세요.");
      }
      
    } finally {
      setUploading(false);
    }
  };

  const onClickClose = () => {
    history.goBack();
  };

  return (
    <TeamCreatePresenter
      teamName={teamName}
      area={area}
      contact={contact}
      sport={sport}
      bio={bio}
      uniformColor={uniformColor}
      matchingInfo={matchingInfo}
      matchingInfoDetail={matchingInfoDetail}
      matchingPosition={matchingPosition}
      uploading={uploading}
    
      onSubmit={handleSubmit}
      onClickClose={onClickClose}
      setAddressInfo={setAddressInfo}
      
      uniformRef={uniformRef}
      infoRef={infoRef}
      positionRef={positionRef}
      bioRef={bioRef}
      teamNameRef={teamNameRef}
      contactRef={contactRef}



      address={address}
      logo={logo}
      profiles={profiles}
      handleLogoChange={handleLogoChange}
      handleProfileChange={handleProfileChange}
      openSearch={openSearch}
      handleSelectPlace={handleSelectPlace}
      searchPlace={searchPlace}
      searchPlaces={searchPlaceData}
      searchingPlace={searchingPlace}
      handleSearchOpen={handleSearchOpen}
      handleSearchClose={handleSearchClose}
      onRemoveProfile={onRemoveProfile}
    />
  );
};

export default withRouter(TeamCreateContainer);
