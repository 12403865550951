import React, { useEffect } from "react";
import Loader from "../../Components/Loader";
import CONFIRM from "../../Components/Game/CONFIRM";
import MATCH from "../../Components/Game/MATCH";
import RECRUIT from "../../Components/Game/RECRUIT";
import COMPLETE from "../../Components/Game/COMPLETE";
import PLAYING from "../../Components/Game/PLAYING";
import CANCEL from "../../Components/Game/CANCEL";
import { NotFound } from "../../Components/NotFound";



export default ({
  loading,
  data,
  error,
  canMatchRequest,
  setCanMatchRequest,
  handleSendMatch,
  onSendResult,
  onConfirm,
  handleDeleteGame,
  uploading,
  isSendResult,
  setResultId,
  setHomeScore,
  setAwayScore,
  homeScore,
  awayScore,
  GAMESTATUS,
  setGAMESTATUS,
  myTeamSended,
  setMyTeamSended,
  updateGameData,
  updateGameResultData,
  history
}) => {
  if (loading) {
    return (
      <div>
        <Loader />
      </div>
    );
  } else if (!loading && data && data.seeFullGame) {
    const {
      seeFullGame: {
        isSendMatch,
        gameStatus,
        result,
        homeTeam: { isMine, hasAuthority },
        awayTeam
      },
      me: { isSomeHost, myHostTeam }
    } = data;
    

    useEffect(() => {
      setGAMESTATUS(gameStatus);
      setCanMatchRequest(!isSendMatch && !isMine && isSomeHost);
      if (result) {
        setMyTeamSended(
          myHostTeam && result.sendTeam && result.sendTeam.id === myHostTeam.id
        );
        setResultId(result.id);
        setHomeScore(result.homeTeam);
        setAwayScore(result.awayTeam);
      }
    }, []);

    useEffect(() => {
      console.log("updateGameData ===> ", updateGameData);

      if (updateGameData !== undefined) {
        const { updateGame } = updateGameData;

        if (updateGame.gameStatus === "MATCH") {
          history.replace(
            `/vote/t/${
              isMine ? updateGame.homeTeam.id : updateGame.awayTeam.id
            }/g/${updateGame.id}`
          );
        } else {
          setGAMESTATUS(updateGame.gameStatus);
          
          if (updateGame.result) {
            setMyTeamSended(
              myHostTeam &&
                updateGame.result.sendTeam &&
                updateGame.result.sendTeam.id === myHostTeam.id
            );
            setResultId(updateGame.result.id);
            setHomeScore(updateGame.result.homeTeam);
            setAwayScore(updateGame.result.awayTeam);
          }
        }
      }

      console.log("updateGameResultData ===> ", updateGameResultData);
      if (updateGameResultData !== undefined) {
        const { updateGameResult } = updateGameResultData;

          setMyTeamSended(
            myHostTeam &&
            updateGameResult.sendTeam &&
            updateGameResult.sendTeam.id === myHostTeam.id
          );
          setResultId(updateGameResult.id);
          setHomeScore(updateGameResult.homeTeam);
          setAwayScore(updateGameResult.awayTeam);


      }
    }, [updateGameData, updateGameResultData]);

    if(GAMESTATUS) {
      if (GAMESTATUS === "COMPLETE") {
        return (
          <>
            {uploading && <Loader />}
            <COMPLETE
              data={data}
              homeScore={homeScore}
              awayScore={awayScore}
              setHomeScore={setHomeScore}
              setAwayScore={setAwayScore}
            />
          </>
        );
      } else if (GAMESTATUS === "CONFIRM") {
        const canEnterScore = hasAuthority || awayTeam.hasAuthority;
  
        return (
          <>
            {uploading && <Loader />}
            <CONFIRM
              data={data}
              homeScore={homeScore}
              awayScore={awayScore}
              setHomeScore={setHomeScore}
              setAwayScore={setAwayScore}
              isSendResult={isSendResult}
              canEnterScore={canEnterScore}
            />
          </>
        );
      } else if (GAMESTATUS === "PLAYING" || GAMESTATUS === "FINISH") {
        const canEnterScore = hasAuthority || awayTeam.hasAuthority;
  
        return (
          <>
            {uploading && <Loader />}
            <PLAYING
              history={history}
              data={data}
              isSendResult={isSendResult}
              homeScore={homeScore}
              awayScore={awayScore}
              setHomeScore={setHomeScore}
              setAwayScore={setAwayScore}
              canEnterScore={canEnterScore}
              myTeamSended={myTeamSended}
              onSendResult={onSendResult}
              onConfirm={onConfirm}
            />
          </>
        );
      } else if (GAMESTATUS === "MATCH") {
        return (
          <>
            {uploading && <Loader />}
            <MATCH data={data} />
          </>
        );
      } else if (GAMESTATUS === "RECRUIT") {
        return (
          <>
            {uploading && <Loader />}
            <RECRUIT
              history={history}
              data={data}
              handleDeleteGame={handleDeleteGame}
              handleSendMatch={handleSendMatch}
              canMatchRequest={canMatchRequest}
            />
          </>
        );
      } else if (GAMESTATUS === "CANCEL") {
        return (
          <>
            {uploading && <Loader />}
            <CANCEL
              data={data}
              handleSendMatch={handleSendMatch}
              canMatchRequest={canMatchRequest}
            />
          </>
        );
      }
    } else {
      return <Loader />
    }
    
  } else if(error) {
    return <NotFound text={"해당 경기를 찾을 수 없습니다."} />
  }
};
