import React, { useEffect } from "react";
import styled from "styled-components";
import { Helmet } from "rl-react-helmet";
import { makeStyles } from "@material-ui/core/styles";
import Loader from "../../Components/Loader";
import BoldText from "../../Components/BoldText";
import PostCode from "../../Components/PostCode";
import Button from "../../Components/Button";
import Select from "../../Components/Select";
//import Input from "../../Components/Input";
import { Close, Minus } from "../../Components/Icons";
import BaseHeader from "../../Components/Header/BaseHeader";

import Slide from "@material-ui/core/Slide";
import InputBase from "@material-ui/core/InputBase";
import SearchIcon from "@material-ui/icons/Search";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import {
  DialogActions,
  DialogTitle,
  Button as MUIButton
} from "@material-ui/core";

const Wrapper = styled.div`
  width: 100%;
  flex-grow: 1;
  & .MuiInputBase-input {
    background-color: #ebebeb !important;
    border-radius: 8px !important;
    padding: 10px 10px !important;
  }
`;

const UserInfoRows = styled.div`
  flex-grow: 1;
  margin: 0px 10px;
  background-color: #fff;
  color: #000;
  padding-top: 70px;
  padding-bottom: 40px;
  min-height: 100vh;
  max-width: 500px;
  margin: 0 auto;
`;

const UserInfoRow = styled.div`
  /* display: flex;
  font-size: 15px;
  justify-content: space-between;
  align-items: center; */
  font-size: 16px;
  padding: 15px 20px;
`;

const SearchWrapper = styled.div`
  margin: auto 10px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding-top: 206px;
  overflow-y: scroll;
`;

const InputInfoColumn = styled.div`
  &:first-child {
    margin-bottom: 7px;
  }
  &:nth-child(2) {
    display: flex;
    flex-direction: column;
    & > input.detail {
      margin-top: 10px;
    }
  }
`;

const ProfileColumn = styled.div`
  width: auto;
  white-space: nowrap;
  overflow-x: auto;
`;

const LogoUploadWapper = styled.div`
  position: relative;
  overflow: hidden;
  display: inline-block;
  width: 110px;
  height: 110px;
  & img,
  & canvas {
    width: 110px;
    height: 110px;
    border: 1px solid #000;
    background-color: white;
    border-radius: 8px;
  }
  & > input[type="file"] {
    position: absolute;
    height: 100%;
    left: 0;
    top: 0;
    opacity: 0;
  }
`;

const ProfileUploadWapper = styled(LogoUploadWapper)`
  & img,
  & canvas {
    width: 195px;
    height: 110px;
  }
  width: 195px;
  height: 110px;
  margin-right: 15px;
`;

const RemoveBtn = styled.button`
  border: none;
  /* color: gray; */
  background-color: #fff;
  padding: 0px;
  border-radius: 50%;
  font-size: 25px;
  font-weight: bold;
  width: 30px;
  height: 30px;
  position: absolute;
  right: 0;
  bottom: 0;
`;

const PreviewImg = styled.img`
  border: 1px solid #000;
  background-color: white;
  border-radius: 8px;
  width: 100%;
  height: 100%;
`;

const ProfileHint = styled.span`
  font-size: 14px;
  padding-left: 10px;
`;

const UploadBtn = styled.button`
  border: 1px solid #000;
  color: #000;
  background-color: white;
  padding: 5px;
  border-radius: 8px;
  font-size: 15px;
  width: 100%;
  height: 100%;
`;

const BioTextarea = styled.textarea`
  width: 100%;
  max-width: 500px;
  padding: 5px 15px;
  background-color: #ebebeb;
  border: ${props => props.theme.boxBorder};
  border-radius: 10px;
  color: #000;
  resize: none;
  font-size: 16px;
  font-family: "NanumSqure";
  &:focus {
    background-color: #fff;
    border: 1px solid #2b2b2b;
  }
`;

const BottomWrap = styled.div`
  display: flex;
  justify-content: center;
`;

const InviteListButton = styled.button`
  padding: 5px 11px;
  background-color: #fff;
  color: #fff;
  border: 1px solid #888;
  color: #000;
  border-radius: 5px;
`;

const Input = styled.input`
  width: 100%;
  height: 40px;
  max-width: 500px;
  padding: 5px 15px;
  font-size: 14px;
  color: #000;
  background-color: #ebebeb;
  border: ${props => props.theme.boxBorder};
  border-radius: 10px;
  &:active,
  &:focus {
    background-color: #fff;
    border: 1px solid #2b2b2b;
  }
`;

const useStyles = makeStyles(theme => ({
  toolBar: {
    height: "200px",
    display: "block",
    textAlign: "right",
    paddingTop: "5px"
  },
  appBar: {
    position: "fixed",
    backgroundColor: "#227fff"
  },
  inputSearch: {
    position: "relative",
    top: "25%",
    width: "100%"
  },
  inputSearchIcon: {
    width: theme.spacing(5),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    right: 10
  },
  search: {
    position: "relative",
    top: "25%",
    borderBottom: "1px solid"
  },
  searchIcon: {
    width: theme.spacing(5),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  inputRoot: {
    width: "100%",
    color: "inherit",
    fontSize: "14px"
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 5),
    transition: theme.transitions.create("width"),
    width: "100%"
  },
  removeDialog: {
    "& .MuiTypography-h6": {
      fontSize: "1rem"
    },
    "& .MuiDialog-paper": {
      margin: 30
    }
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default ({
  data,
  loading,
  uploading,
  onSubmit,
  teamNameInput,
  contactInput,
  uniformColorInput,
  matchingInfoInput,
  matchingInfoDetailInput,
  matchingPositionInput,
  bioInput,

  uniformRef,
  infoRef,
  positionRef,
  bioRef,
  teamNameRef,
  contactRef,

  setOldProfiles,
  setLogo,
  oldProfiles,
  address,

  handleRemoveProfileOpen,
  handleRemoveProfileClose,
  openRemoveProfile,
  handleProfileDelete,
  onChangePlace,

  onClickClose,
  setAddressInfo,
  logo,
  profiles,
  handleLogoChange,
  handleProfileChange,
  handleSelectPlace,
  openSearch,
  handleSearchClose,
  searchPlace,
  searchPlaces,
  searchingPlace,
  onRemoveProfile,
}) => {
  if (loading) {
    return (
      <Wrapper>
        <Loader />
        <Helmet>
          <title>팀 정보 수정 | WePlay</title>
        </Helmet>
        <BaseHeader
          isClose={true}
          text={"팀 정보 수정"}
          onClickClose={()=>{}}
        />
         <UserInfoRows></UserInfoRows>
      </Wrapper>
    );
  } else if (!loading && data && data.seeFullTeam) {
    const classes = useStyles();

    const {
      seeFullTeam: {
        teamName,
        contact,
        uniformColor,
        matchingInfo,
        matchingPosition,
        bio,
        place,
        profile,
        logo: prevLogo
      }
    } = data;

    useEffect(() => {
      teamNameInput.setValue(teamName);
      contactInput.setValue(contact);
      bioInput.setValue(bio);

      if (matchingInfo) {
        if (matchingInfo.split("/").length > 1) {
          matchingInfoInput.setValue(matchingInfo.split("/")[0]);
          matchingInfoDetailInput.setValue(matchingInfo.split("/")[1]);
        } else {
          matchingInfoInput.setValue(matchingInfo);
        }
      }

      if (uniformColor) uniformColorInput.setValue(uniformColor);
      if (matchingPosition) matchingPositionInput.setValue(matchingPosition);

      setOldProfiles(profile);
      setLogo(prevLogo);

      if (place) onChangePlace(place.id, place.address);
    }, [loading]);

    return (
      <Wrapper>
        <Helmet>
          <title>팀 정보 수정 | WePlay</title>
        </Helmet>
        {uploading && <Loader />}
        <BaseHeader
          isClose={true}
          text={"팀 정보 수정"}
          onClickClose={onClickClose}
        />

        <UserInfoRows>
          <UserInfoRow>
            <InputInfoColumn>
              <BoldText text={"팀명"} />
              <ProfileHint>*</ProfileHint>
            </InputInfoColumn>
            <InputInfoColumn>
              <Input
                ref={teamNameRef}
                placeholder={""}
                value={teamNameInput.value}
                onChange={teamNameInput.onChange}
              />
            </InputInfoColumn>
          </UserInfoRow>

          <UserInfoRow>
            <InputInfoColumn>
              <BoldText text={"대표 연락처"} />
              <ProfileHint>*</ProfileHint>
            </InputInfoColumn>
            <InputInfoColumn>
              <Input ref={contactRef} placeholder={""} {...contactInput} />
            </InputInfoColumn>
          </UserInfoRow>

          <UserInfoRow>
            <InputInfoColumn>
              <BoldText text={"유니폼 색상"} />
              <ProfileHint>*</ProfileHint>
            </InputInfoColumn>
            <InputInfoColumn>
              <Input
                ref={uniformRef}
                placeholder={"ex> 화이트, 레드 ..."}
                {...uniformColorInput}
              />
            </InputInfoColumn>
          </UserInfoRow>

          <UserInfoRow>
            <InputInfoColumn>
              <BoldText text={"매칭 요청사항"} />
              <ProfileHint>*</ProfileHint>
            </InputInfoColumn>
            <InputInfoColumn>
              <Select
                selectRef={infoRef}
                items={[
                  { text: "대관비 보조금", value: "대관비 보조금" },
                  { text: "음료수 보조", value: "음료수 보조" },
                  { text: "없음", value: "없음" }
                ]}
                value={matchingInfoInput.value}
                onChange={matchingInfoInput.onChange}
              />
              {matchingInfoInput.value !== "" &&
                matchingInfoInput.value !== "없음" && (
                  <Input
                    placeholder={"상세내용"}
                    className={"detail"}
                    {...matchingInfoDetailInput}
                  />
                )}
            </InputInfoColumn>
          </UserInfoRow>

          <UserInfoRow>
            <InputInfoColumn>
              <BoldText text={"매칭 포지션"} />
              <ProfileHint>*</ProfileHint>
            </InputInfoColumn>
            <InputInfoColumn>
              <Select
                selectRef={positionRef}
                items={[
                  {
                    text: "홈 코트 보유. 홈경기만 가능",
                    value: "홈 코트 보유. 홈경기만 가능"
                  },
                  {
                    text: "홈 코트 미 보유 어웨이 경기만 가능",
                    value: "홈 코트 미 보유 어웨이 경기만 가능"
                  },
                  {
                    text: "홈 & 어웨이 경기 모두 가능",
                    value: "홈 & 어웨이 경기 모두 가능"
                  }
                ]}
                value={matchingPositionInput.value}
                onChange={matchingPositionInput.onChange}
              />
            </InputInfoColumn>
          </UserInfoRow>

          <UserInfoRow>
            <InputInfoColumn>
              <BoldText text={"간략한 소개"} />
              <ProfileHint>* 최대 300자 제한</ProfileHint>
            </InputInfoColumn>
            <InputInfoColumn>
              <BioTextarea
                ref={bioRef}
                {...bioInput}
                cols="4"
                rows="5"
              ></BioTextarea>
            </InputInfoColumn>
          </UserInfoRow>

          <UserInfoRow>
            <InputInfoColumn>
              <BoldText text={"로고"} />
            </InputInfoColumn>
            <InputInfoColumn>
              <LogoUploadWapper>
                <Input
                  onChange={handleLogoChange}
                  placeholder={""}
                  type={"file"}
                  name={"logo"}
                  accept="image/*"
                />
                {logo ? (
                  <PreviewImg src={logo.url?logo.url:logo} />
                ) : (
                  <UploadBtn>+ 등록하기</UploadBtn>
                )}
                {/* {logo ? (
                  !previewLogo ? (
                    <PreviewImg src={logo.url ? logo.url : logo} />
                  ) : (
                    <div id={"logo"}></div>
                  )
                ) : (
                  <UploadBtn>+ 등록하기</UploadBtn>
                )} */}
              </LogoUploadWapper>
            </InputInfoColumn>
          </UserInfoRow>

          <UserInfoRow>
            <InputInfoColumn>
              <BoldText text={"팀 프로필 사진"} />
              <ProfileHint>최대 5장</ProfileHint>
            </InputInfoColumn>
            <ProfileColumn>
              {oldProfiles &&
                oldProfiles.length > 0 &&
                oldProfiles.map(profile => (
                  <ProfileUploadWapper key={profile.id}>
                    <PreviewImg key={profile.name} src={profile.url} />
                    <RemoveBtn onClick={handleRemoveProfileOpen(profile.id)}>
                      <Minus size={30} />
                    </RemoveBtn>
                  </ProfileUploadWapper>
                ))}
              {profiles &&
                profiles.length > 0 &&
                profiles.map((profile,i) => (
                  <ProfileUploadWapper key={i}>
                    <PreviewImg src={profile.url} />
                    <RemoveBtn onClick={onRemoveProfile(profile)}>
                      <Minus size={30} />
                    </RemoveBtn>
                  </ProfileUploadWapper>
                ))}


              <ProfileUploadWapper>
                <UploadBtn>+ 등록하기</UploadBtn>
                <Input
                  onChange={handleProfileChange}
                  placeholder={""}
                  type={"file"}
                  name={"profile"}
                  accept="image/*"
                />
              </ProfileUploadWapper>
            </ProfileColumn>
          </UserInfoRow>

          <UserInfoRow>
            <InputInfoColumn>
              <BoldText text={"경기장"} />
            </InputInfoColumn>

            <InputInfoColumn>
              <PostCode
                defaultValue={address}
                setAddressInfo={setAddressInfo}
              />
            </InputInfoColumn>
          </UserInfoRow>
          <Button color={"#000"} position={"relative"} onClick={onSubmit} text={"팀 정보 수정"} />
         
        </UserInfoRows>

        <Dialog
          className={classes.dialog}
          fullScreen
          open={openSearch}
          onClose={handleSearchClose}
          TransitionComponent={Transition}
        >
          <AppBar className={classes.appBar}>
            <Toolbar className={classes.toolBar}>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleSearchClose}
                aria-label="close"
              >
                <Close />
              </IconButton>
              <div className={classes.search}>
                <div className={classes.searchIcon}>
                  <SearchIcon />
                </div>
                <InputBase
                  placeholder="장소를 검색하세요"
                  classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput
                  }}
                  inputProps={{ "aria-label": "search" }}
                  value={searchPlace.value}
                  onChange={searchPlace.onChange}
                />
              </div>
            </Toolbar>
          </AppBar>

          <SearchWrapper>
            <List>
              {searchingPlace && <CircularProgress />}
              {searchPlaces &&
                searchPlaces.seePlaces &&
                searchPlaces.seePlaces.length > 0 &&
                searchPlaces.seePlaces.map(place => (
                  <ListItem key={place.id}>

                    <ListItemText
                      primary={place.placeName}
                      secondary={place.address}
                    />
                    <InviteListButton onClick={handleSelectPlace(place)}>
                      + 선택
                    </InviteListButton>
                  </ListItem>
                ))}
            </List>
          </SearchWrapper>
        </Dialog>

        <Dialog
          open={openRemoveProfile}
          onClose={handleRemoveProfileClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className={classes.removeDialog}
        >
          <DialogTitle id="alert-dialog-title">
            {"기존 프로필을 삭제하시겠습니까?"}
          </DialogTitle>
          <DialogActions>
            <MUIButton onClick={handleRemoveProfileClose} color="primary">
              아니오
            </MUIButton>
            <MUIButton onClick={handleProfileDelete} color="primary" autoFocus>
              예
            </MUIButton>
          </DialogActions>
        </Dialog>
      </Wrapper>
    );
  }
};
