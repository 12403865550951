import { gql } from "apollo-boost";

export const GET_GAME = gql`
  query seeFullGame($id: String!) {
    seeFullGame(id: $id) {
      id
      gameDate
      startTime
      endTime
      gameStatus
      usr_MVP {
        id
        avatar
        username
        level
        basket {
          id
          position
          backNo
        }
      }
      mvpVotes {
        id
        user {
          id
          avatar
          username
          level
        }
        selectUser {
          id
          avatar
          username
          level
        }
      }
      winner
      participants {
        id
        user {
          id
          avatar
          username
          level
          basket {
            id
            backNo
            position
          }
        }
        team {
          id
          teamName
        }
        answer
      }
      isParticipant
      isSendMatch
      isReSend
      playerCnt
      title
      message
      result {
        id
        homeTeam
        awayTeam
        status
        sendTeam {
          id
        }
      }
      place {
        id
        placeName
        address
        location {
          id
          latitude
          longitude
        }
      }
      homeTeam {
        id
        level
        userCount
        teamName
        logo
        bio
        contact
        isMine
        avgAge
        avgLevel
        uniformColor
        matchingPosition
        matchingInfo
        hasAuthority
        host {
          id
          avatar
          level
          username
        }
        managers {
          id
          avatar
          level
          username
        }
        users {
          id
          avatar
          level
          username
        }
      }

      awayTeam {
        id
        teamName
        level
        isMine
        hasAuthority
        host {
          id
          avatar
          username
        }
        managers {
          id
          avatar
          username
        }
        users {
          id
          avatar
          username
        }
        logo
      }
    }
    me {
      id
      isSomeHost
      myHostTeam {
        id
        teamName
        bio
      }
    }
  }
`;

export const SEND_MATCH = gql`
  mutation sendMatch($id: String!, $oppTeamId: String!) {
    sendMatch(id: $id, oppTeamId: $oppTeamId)
  }
`;

export const SEND_GAMERESULT = gql`
  mutation sendGameResult(
    $id: String!
    $homeScore: [Int!]!
    $awayScore: [Int!]!
    $resultId: String
    $sendTeam: String!
    $sendUser: String!
  ) {
    sendGameResult(
      id: $id
      homeScore: $homeScore
      awayScore: $awayScore
      resultId: $resultId
      sendTeam: $sendTeam
      sendUser: $sendUser
    )
  }
`;

export const CONFIRM_GAMERESULT = gql`
  mutation confirmGameResult(
    $gameId: String!
    $resultId: String!
    $sendTeam: String!
    $sendUser: String!
  ) {
    confirmGameResult(
      gameId: $gameId
      resultId: $resultId
      sendTeam: $sendTeam
      sendUser: $sendUser
    )
  }
`;

export const COMPLETE_GAME = gql`
  mutation completeGame($id: String!, $mvpId: String) {
    completeGame(id: $id, mvpId: $mvpId)
  }
`;

export const UPSERT_VOTE = gql`
  mutation upsertVote($gameId: String!, $teamId: String!, $answer: String!) {
    upsertVote(gameId: $gameId, teamId: $teamId, answer: $answer) {
      id
      user {
        id
        avatar
        username
        level
      }
      answer
    }
  }
`;

export const UPSERT_MVP_VOTE = gql`
  mutation upsertMvpVote(
    $gameId: String!
    $teamId: String!
    $selectUser: String!
  ) {
    upsertMvpVote(gameId: $gameId, teamId: $teamId, selectUser: $selectUser) {
      id
      user {
        id
        avatar
        username
        level
      }
      selectUser {
        id
        avatar
        username
        level
      }
    }
  }
`;

export const UPDATE_GAME = gql`
  subscription updateGame($id:String!){
    updateGame(id:$id) {
      id
      homeTeam {
        id
      }
      awayTeam {
        id
      }
      usr_MVP {
        id
        avatar
        username
        level
        basket {
          id
          position
          backNo
        }
      }
      gameStatus
      result {
        id
        homeTeam
        awayTeam
        status
        sendTeam {
          id
        }
      }
    }
  }
`;

export const DELETE_GAME = gql`
  mutation editGame($id: String!, $action: String!, $homeId:String) {
    editGame(id: $id, action: $action, homeId:$homeId) {
      id
    }
  }
`;

export const UPDATE_GAME_RESULT = gql`
  subscription updateGameResult($id:String!){
    updateGameResult(id:$id) {
      id
      homeTeam 
      awayTeam 
      status
      sendTeam {
        id
      }
    }
  }
`;
