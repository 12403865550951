import React, { useState } from "react";
import styled from "styled-components";

import { Helmet } from "react-helmet";

const Wrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  z-index: 1;
  padding: 15vh 0px 0px;
  /* background: url(/imgs/web_splash_bg.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; */
  align-items: center;
  justify-content: space-between;
`;

const BackImg = styled.img`
  position: fixed;
  top: 0;
  left: 50%;
  z-index: -1;
  transform: translateX(-50%);
  width: 100%;
  height: 100%;
`;

const Logo = styled.img`
  margin-bottom: 12px;
  /* margin-left: 12vw; */
  width: 80%;
  max-width: 390px;
  z-index: 999;
`;

const LoginWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-bottom: 40px;
`;

const LoginButton = styled.a`
  background-color: #1877f2;
  border-radius: 5px;
  width: 90%;
  max-width: 500px;
  color: #fff;
  margin-bottom: 20px;
  padding: 15px 0px;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
`;

const FacebookButton = styled(LoginButton)`
  background-color: #1877f2;
  border: 1px solid #1877f2;
`;

const NaverButton = styled(LoginButton)`
  background-color: #00cd53;
  border: 1px solid #00cd53;
`;

const KakaoButton = styled(LoginButton)`
  background-color: #ffcd00;
  border: 1px solid #ffcd00;
  color: #000;
`;

export default ({ onTesterLogin }) => {
  const HOST =
    process.env.NODE_ENV === "development"
      ? "http://localhost:5000"
      : "https://weplay2921.cafe24.com";

  console.log("HOST : ", HOST);

  const [testCount, setTestCount] = useState(0);
  return (
    <Wrapper>
      <BackImg
        src={
          window.innerWidth < 500
            ? "https://d1jozj1mdbjtd9.cloudfront.net/mobile_splash_bg.jpg"
            : "https://d1jozj1mdbjtd9.cloudfront.net/web_splash_bg.jpg"
        }
      />
      <Helmet>
        <title>WePlay</title>
      </Helmet>
      <Logo
        onClick={testCount === 3 ? onTesterLogin("host") : () => setTestCount(testCount + 1)}
        src={"https://d1jozj1mdbjtd9.cloudfront.net/splash_logo.png"}
        alt="위플레이 로고"
      />

      <LoginWrapper>
        <FacebookButton href={`${HOST}/api/auth/facebook`}>
          페이스북 계정으로 시작하기
        </FacebookButton>
        <NaverButton href={`${HOST}/api/auth/naver`}>
          네이버 계정으로 시작하기
        </NaverButton>
        <KakaoButton href={`${HOST}/api/auth/kakao`}>
          카카오 계정으로 시작하기
        </KakaoButton>
      </LoginWrapper>
    </Wrapper>
  );
};
