import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import {
  SEE_NOTICES
} from "./CupNoticeListQueries";
import CupNoticeListPresenter from "./CupNoticeListPresenter";
import { useQuery } from "react-apollo-hooks";

const CupNoticeListContainer = ({ match: { params }, history }) => {
  const cupId = params.cup;

  const { data, loading, fetchMore } = useQuery(SEE_NOTICES, {
    variables: {
      cupId
    }
  });

  const [notices, setNotices] = useState([]);
  
  const handleInfiniteScroll = () => {
    fetchMore({
      variables: {
        skip: data.seeRanking.length
      },
      updateQuery: (prev, { fetchMoreResult }) => {

        if (!fetchMoreResult) return prev;

        return Object.assign({}, prev, {
          seeRanking: [...prev.seeUsers, ...fetchMoreResult.seeUsers]
        });
      }
    });
  };
  const onCreatePage = () => {
    history.push(`/cup/${cupId}/notice/create`);
  };

  return (
    <CupNoticeListPresenter
      loading={loading}
      data={data}
      notices={notices}
      cupId={cupId}
      onCreatePage={onCreatePage}
      setNotices={setNotices}
      handleInfiniteScroll={handleInfiniteScroll}
    />
  );
};

export default withRouter(CupNoticeListContainer);
