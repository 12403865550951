import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { GET_TEAM } from "./CupTeamQueries";
import CreateCupTeamPresenter from "./CupTeamPresenter";
import { useQuery } from "react-apollo-hooks";

const CupTeamContainer = ({ match: { params } , history }) => {
  const cupId = params.cup;
  const teamId = params.team;
  const { data, loading } = useQuery(GET_TEAM, {
    variables: {
      teamId: teamId.toString(),
      cupId
    }
  });

  const [host, setHost] = useState();
  const [users, setUsers] = useState([]);


  console.log(data);

  return (
    <CreateCupTeamPresenter
      data={data}
      loading={loading}
      cupId={cupId}
      host={host}
      users={users}
      setHost={setHost}
      setUsers={setUsers}
    />
  );
};

export default withRouter(CupTeamContainer);
