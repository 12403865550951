import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const Round = styled.span`
  position: relative;
`;

const Input = styled.input`
  visibility: hidden;
  &:checked + label {
    background-color: ${props => props.bgColor};
    border-color: ${props => props.bgColor};
  }
  &:checked + label:after, &:checked + label:before {
    opacity: 1;
  }
`;

const Label = styled.label`
  background-color: #d5d5d5;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: ${props => props.disabled?"none":"pointer"};
  height: 20px;
  width: 20px;
  left: 0;
  position: absolute;
  /* top: 4px; */

  &:after {
    border: 2px solid #fff;
    border-top: none;
    border-right: none;
    content: "";
    height: 4px;
    left: 4px;
    opacity: 0;
    position: absolute;
    top: 5px;
    transform: rotate(-45deg);
    width: 8px;
  }
`;

const XLabel = styled.label`
  background-color: #d5d5d5;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: ${props => props.disabled?"none":"pointer"};
  height: 20px;
  width: 20px;
  left: 0;
  position: absolute;
  /* top: 4px; */
  &:before {
    border: 2px solid #fff;
    border-top: none;
    border-right: none;
    content: "";
    height: 6px;
    left: 5px;
    opacity: 0;
    position: absolute;
    top: 8px;
    transform: rotate(135deg);
    width: 6px;
  }

  &:after {
    border: 2px solid #fff;
    border-top: none;
    border-right: none;
    content: "";
    height: 6px;
    left: 5px;
    opacity: 0;
    position: absolute;
    top: 0px;
    transform: rotate(-45deg);
    width: 6px;
  }
`;

const RoundCheckBox = ({type="checked", checked = true, disabled = false, onChange }) => {

  return (
  <Round>
    <Input
      id="checkbox"
      checked={checked}
      disabled={disabled}
      onChange={onChange}
      bgColor={type === "checked" ? "#ffbe21":"#fd2f2f"}
      type="checkbox"
    />
    {type === "checked" ? (
      <Label disabled={disabled} />
    ):(
      <XLabel disabled={disabled} />
    )}
    
  </Round>
)};

RoundCheckBox.propTypes = {
  checked: PropTypes.bool,
  disabled: PropTypes.bool
};

export default RoundCheckBox;
