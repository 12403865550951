import React, { useEffect } from "react";
import AuthPresenter from "./AuthPresenter";
import {
  LOCAL_LOG_IN,
  //GET_TOKEN
} from "../../SharedQueries";
import { useMutation } from "react-apollo-hooks";
import useInput from "../../Hooks/useInput";
import axios from "axios";
import { toast } from "../../Components/Popup";

export default ({match: { params }, location}) => {

  //const generateTokenMutation = useMutation(GET_TOKEN);
  const localLogInMutation = useMutation(LOCAL_LOG_IN);

  const loginID = useInput("");
  const loginPW = useInput("");

  const paramString = new URLSearchParams(location.search);

  useEffect(() => {
    const token = params.token? params.token:"";

    if(token) {
      localLogInMutation({ 
        variables: { token } 
      });
    }

    if(location.search.indexOf("cup") !== -1) {
      toast.error(<span>대회 기록을 위해 회원가입 후 진행해주세요.</span>)
    }

    if(paramString.get('already') === 'facebook') {
      toast.error(<span>같은 이메일로 가입된<br/> <strong>페이스북</strong> 계정이 있습니다.</span>)
    }

    if(paramString.get('already') === 'kakao') {
      toast.error(<span>같은 이메일로 가입된<br/> <strong>카카오</strong> 계정이 있습니다.</span>)
    }

    if(paramString.get('already') === 'naver') {
      toast.error(<span>같은 이메일로 가입된<br/> <strong>네이버</strong> 계정이 있습니다.</span>)
    }

    if(location.search.indexOf('close') !== -1) {
      toast.error(<span>정보 제공 비공개로는 가입이 불가합니다.<br/>해당 SNS <strong>연결 서비스 관리 해제</strong> 후 다시 시도해주세요.</span>)
    }

    // if(token) {
    //   localLogInMutation({ 
    //     variables: { token } 
    //   });
    // }
    
    // if(params.get('code') && params.get('code')!=null) {
    //   async function fecthToken() {
    //     const {
    //       data: {  generateToken }
    //     } = await generateTokenMutation({
    //       variables: {
    //         snsId:params.get('code'),
    //       }
    //     });
  
    //     localLogInMutation({ 
    //       variables: { token : generateToken } 
    //     });
    //   }
    //   fecthToken();
    // }
  }, []);


  const onTesterLogin = (type) => () => {
    console.log(type)
    console.log(window.location.host);
    let serverUrl = "";
    if(window.location.host === "localhost:3000") {
      serverUrl = "http://localhost:4000"
    } else {
      serverUrl = "https://weplay2921.cafe24.com"
    }

    
    axios.post(serverUrl+'/api/test/login/'+type)
    .then(function (response) {
      console.log(response);

      localLogInMutation({ 
        variables: { token : response.data } 
      });
    })
    .catch(function (error) {
      console.log(error);
    });
  }

  const onTesterIDPWLogin = () => {

    console.log(window.location.host);
    let serverUrl = "";
    if(window.location.host === "localhost:3000") {
      serverUrl = "http://localhost:4000"
    } else {
      serverUrl = "https://weplay2921.cafe24.com"
    }

    
    axios.post(serverUrl+'/api/user/login/',{
      LoginID: loginID.value,
      LoginPW: loginPW.value
    })
    .then(function (response) {
      console.log(response);
      if(response) {
        localLogInMutation({ 
          variables: { token : response.data } 
        });
      }
     
    })
    .catch(function (error) {
      console.log(error);
    });
  }


  const onSNSLogin = async e => {
    e.preventDefault();

    let serverUrl = "";
    if(window.location.host === "localhost:3000") {
      serverUrl = "http://localhost:4000"
    } else {
      serverUrl = "https://weplay2921.cafe24.com"
    }

    axios.get(serverUrl+'/api/auth/kakao')
    .then(function (response) {
      console.log(response);

      // localLogInMutation({ 
      //   variables: { token : response.data } 
      // });
    })
    .catch(function (error) {
      console.log(error);
    });
  };

  return (
    <AuthPresenter
      onSNSLogin={onSNSLogin}
      onTesterLogin={onTesterLogin}
      onTesterIDPWLogin={onTesterIDPWLogin}
      loginID={loginID}
      loginPW={loginPW}
    />
  );
};
