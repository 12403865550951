import React, { useEffect } from "react";
import styled from "styled-components";

import { Helmet } from "rl-react-helmet";

import Loader from "../../Components/Loader";
import BaseHeader from "../../Components/Header/BaseHeader";
import { Arrow } from "../../Components/Icons";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
//import { Link } from "react-router-dom";
//import Dialog from "@material-ui/core/Dialog";
import { makeStyles } from "@material-ui/core/styles";
import { ListItemAvatar } from "@material-ui/core";
//import GameVoteContainer from "../GameVote/GameVoteContainer";
import Avatar from "../../Components/Avatar";

const Wrapper = styled.div`
  min-height: calc(100vh - 76px);
`;

const ContentWrapper = styled.div`
  padding-top: 56px;
  margin: auto 10px;
  background-color: ${props => props.bgColor};
  min-height: 100vh;
  & > div {
    margin-bottom: 5px;
  }
  color: #000;
  max-width: 768px;
    margin: 0 auto;
`;

const TitleWrap = styled.div`
  min-width: 0;
  margin-top: 6px;
  margin-bottom: 6px;
`;

const TeamName = styled.span`
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  padding-bottom: 7px;
  position: relative;
`;

const ArrowIcon = styled.span`
  position: absolute;
  top: 40%;
  right: 10px;
`;

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
  dialog: {
    color: "#fff",
    "& .MuiPaper-root": {
      color: "#fff",
      backgroundColor: "#113f80"
    }
  }
}));

const StatusSpan = styled.span`
  background-color: ${props => props.theme.yellowColor};
  font-size: 12px;
  font-weight: 600;
  border-radius: 6px;
  padding: 3px;
  margin-left: 5px;
  position:absolute;
`;

// const ListItem = styled.li`
//   position: relative;
//   padding: 15px 20px;
//   border-bottom: 1px solid #d5d5d5;
// `;
const NoTeam = styled.div`
    width: 95%;
    border-radius: 10px;
    font-size: 19px;
    font-weight: 500;
    text-align: center;
    /* padding: 20px; */
    height: 70px;
    display: flex;
    margin: 0 auto;
    box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
    justify-content: center;
    align-items: center;
`;


const TeamSelectPresenter = ({
  loading,
  data,
  type,
  teams,
  setTeams,
  handleTeamSaveToLocal
}) => {
  if (loading) {
    return (
      <Wrapper>
        <BaseHeader text={"팀 선택"} isBack={true} isClose={false} />
        <Helmet>
          <title>{"팀 선택"} | WePlay</title>
        </Helmet>
        <Loader />
      </Wrapper>
    );
  } else if (!loading && data && data.me) {
    const classes = useStyles();

    console.log(data);

    useEffect(() => {
      setTeams(data.me.userTeams);
    }, []);

    return (
      <Wrapper>
        <BaseHeader text={"팀 선택"} isBack={true} isClose={false} />
        <Helmet>
          <title>{"팀 선택"} | WePlay</title>
        </Helmet>
        <ContentWrapper bgColor={"#fff"}>
          <List className={classes.root}>
            {teams &&
              teams.length > 0 ? (
                teams.map(team => {
                  let router = "";
                  switch(type) {
                    case "history":
                        router=`/team/${team.id}/history`;
                        break;
                    default:
                      break;
                  }
  
                  return (
                    // <Link
                    //   key={team.id}
                    //   to={`/vote/t/${
                    //     game.homeTeam.isMine ? game.homeTeam.id : game.awayTeam.id
                    //   }/g/${game.id}`}
                    // >
                    // <Link key={team.id} to={router}>
                      <ListItem
                        key={team.id}
                        button
                        onClick={handleTeamSaveToLocal(team.id, router)}
                      >
                        <ListItemAvatar>
                          <Avatar size={"sm"} url={team.logo} />
                        </ListItemAvatar>
                        <TitleWrap>
                          <TeamName> {`${team.teamName}`}</TeamName>
                          {team.hasAuthority && <StatusSpan>호스트</StatusSpan>}
                        </TitleWrap>
                        
                        <ArrowIcon>
                          <Arrow size={20} />
                        </ArrowIcon>
                      </ListItem>
                    // </Link>
                  );
                })
              ) : (
                <NoTeam>
                  현재 가입한 팀이 없습니다.
                </NoTeam>
              )
              }
          </List>
        </ContentWrapper>

        {/* <Dialog
          className={classes.dialog}
          fullScreen
          open={open}
          onClose={handleClose}
          TransitionComponent={Transition}
        >
          <GameVoteContainer 
            game={voteGame} 
            onBack={handleClose} 
          />
        </Dialog> */}
      </Wrapper>
    );
  }

  return null;
};

export default TeamSelectPresenter;
