import { gql } from "apollo-boost";

export const SEE_NOTIFICATIONS = gql`
  query seeNotifications($first:Int, $skip:Int) {
    seeNotifications(first:$first, skip:$skip) {
      id
      team {
        id
        logo
        teamName
      }
      game {
        id

      }
      oppUser {
        id
        avatar
      }
      title
      message
      link
      read
      createdAt
    }
  }
`;

export const NEW_NOTIFICATION = gql`
  subscription newNotification {
    newNotification {
      id
      title
      message
      link
      read
      createdAt
    }
  }
`;

export const DELETE_NOTIFICATION = gql`
  mutation deleteNotification($id:String!) {
    deleteNotification(id:$id)
  }
`;