import { gql } from "apollo-boost";

export const CREATE_TEAM = gql`
  mutation createCupTeam(
    $cupId: String!
    $teamId: String
    $agent: String
    $logo: String
    $profiles: [String]
    $sportId:String!
    $teamName: String!
    $contact: String!
    $bio: String
    $host: String
    $users: String!
    ) {
      createCupTeam(
        cupId:$cupId
        teamId:$teamId
        agent:$agent
        logo:$logo
        profiles:$profiles
        teamName:$teamName
        sportId:$sportId
        contact:$contact
        bio:$bio
        host:$host
        users:$users
      ) {
        id
      }
    }
`;

export const GET_CUP = gql`
  query seeCup($id: String!, $isLoggedIn:Boolean!) {
    seeCup(id: $id) {
      id
      cupName
      playerCnt
    }
    me @include(if: $isLoggedIn) {
      id
      myHostTeam {
        id
        teamName
        logo
        bio
        contact
        host {
          id
          avatar
          username
          email
          contact
          age
          position
          backNo
          height
          weight
        }
        managers {
          id
          avatar
          username
          email
          contact
          age
          position
          backNo
          height
          weight
        }
        users {
          id
          avatar
          username
          email
          contact
          age
          position
          backNo
          height
          weight
        }
      }
    }
  }
`;