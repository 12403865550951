import { gql } from "apollo-boost";

export const GET_ROOM = gql`
  query seeFullRoom($id: String!) {
    seeFullRoom(id: $id) {
      id
      isHost
      reqUsers {
        id
        avatar
        username
        level
      }
      inviteUsers {
        id
        avatar
        username
        level
      }
    }
  }
`;


export const SEARCH_USER = gql`
  query searchUser($term:String!) {
    searchUser(term:$term) {
      id
      avatar
      username
      nickname
      basket {
        id
        level
        grade
      }
      itsMe
    }
  }
`;


export const ACCEPT_ROOM = gql`
  mutation acceptRoom($id: String!, $reqUserId: String!) {
    acceptRoom(id: $id, reqUserId: $reqUserId)
  }
`;
export const DECLINE_ROOM = gql`
  mutation declineRoom($id: String!, $reqUserId: String!) {
    declineRoom(id: $id, reqUserId: $reqUserId)
  }
`;

export const INVITE_USER = gql`
  mutation inviteUserToRoom($id: String!, $userId: String!) {
    inviteUserToRoom(id: $id, userId: $userId)
  }
`;
