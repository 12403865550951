import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Helmet } from "rl-react-helmet";
import Loader from "../../Components/Loader";
import Avatar from "../../Components/Avatar";

import { Dots, Close } from "../../Components/Icons";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import IconButton from "@material-ui/core/IconButton";
import BaseHeader from "../../Components/Header/BaseHeader";
import { Zoom } from "@material-ui/core";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import SwipeableViews from "react-swipeable-views";
import Grid from "@material-ui/core/Grid";
import Input from "../../Components/Input";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import moment from "moment";
import "moment/locale/ko";

import PullToRefresh from "rmc-pull-to-refresh";
import RefreshLoader from "../../Components/RefreshLoader";
import DedicateLoader from "../../Components/DedicateLoader";

const Wrapper = styled.div`
  background-color: #fff;
  min-height: 100vh;
`;

const ContentWrapper = styled.div`
  /* padding-top: 56px; */
  margin: auto 10px;
  background-color: #fff;
  /* min-height: 100vh; */
  display: flex;
  flex-direction: column;
  color: #000;
  max-width: 768px;
  margin: 0 auto;
`;

const HeaderTitle = styled.span`
  color: white;
  font-size: 24px;
  flex: 1;
  margin-left: -26px;
  text-align: center;
  font-weight: 600;
`;

const SmallDateInfo = styled.div`
  color: #000;
  font-size: 11px;
  position:absolute;
  top: 10px;
`;

const Container = styled.div`
  margin-bottom: 15px;
`;
const TitleWrap = styled.div`
  flex: 1 1 auto;
  min-width: 0;
  margin-top: 6px;
  margin-bottom: 6px;
`;

const TeamName = styled.div`
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  padding-top: 7px;
  position: relative;
`;

const Title = styled.p`
  color: rgba(0, 0, 0, 0.54);
  font-size: 0.875rem;
  font-family: "NanumSqure";
  font-weight: 400;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: hidden;
`;

const TeamHeader = styled.div`
  font-size: 18px;
  font-weight: 600;
  padding: 10px 15px;
  background-color: #f2f2f2;
`;

const EmptySearchResult = styled.div`
  margin: 0px 15px;
  padding: 15px 0px;
  color: #969696;
  font-size: 14px;
  border-bottom: 1px solid #d5d5d5;
`;

const SliderButton = styled.div`
  display: flex;
  font-size: 14px;
`;

const ActionButton = styled.span`
  height: 100%;
  background-color: rgb(16, 142, 233);
  color: white;
  display: flex;
  align-items: center;
  padding: 10px;
  height: 60px;
  min-width: 60px;
  justify-content: center;
  &:first-child {
    background-color: ${props => props.theme.yellowColor};
    color: #000;
  }
  &:nth-child(2) {
    background-color: #227fff;
    color: #fff;
  }
  &:last-child {
    background-color: #d5d5d5;
    color: #000;
  }
`;

const DotsWrap = styled.div`
  cursor: pointer;
  padding-right: 15px;
`;

const useStyles = makeStyles(theme => ({
  badge: {
    "& > .MuiBadge-anchorOriginTopRightRectangle": {
      right: "-10px"
    }
  },
  titleWrapper: {
    paddingRight: 0,
    "& > .MuiListItem-gutters": {
      paddingRight: 0
    }
  },
  appBar: {
    position: "fixed",
    backgroundColor: "#227fff"
  },
  mainToolBar: {
    display: "flex"
  },
  tabToolBar: {
    maxWidth: 500,
    width: "100%",
    fontWeight: 600,
    margin: "0 auto"
  },
  tabRoot: {
    fontSize: 15,
    fontFamily: "NanumSqure",
    zIndex: 1,
    minHeight: "38px"
  }
}));

const MyTabs = styled(Tabs)`
  color: #000;
  padding: 15px 25px;
  max-width: 500px;
  width: 100%;
  font-weight: 600;
  margin: 0 auto;
  & button:not(.Mui-selected) {
    z-index: 0;
    color: #fff;
    font-weight: 400;
  }
  .MuiTabs-flexContainer {
    background-color: #65a6ff;
    border-radius: 20px;
  }
  .MuiTabs-indicator {
    top: 0px;
    height: 4px;
    border-radius: 20px;
    height: 38px;
    background-color: #fff;
  }
`;

const MyTabPanel = styled(TabPanel)`
  .MuiListItemText-secondary {
    color: #000;
  }
  & {
    /* padding-bottom: 47px; */
  }
`;

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  const classes = useStyles();
  if (props.className.indexOf("mapWrapper") !== -1) {
    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        <Box className={classes.boxRoot} p={0}>
          {children}
        </Box>
      </Typography>
    );
  } else {
    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        // {...other}
        className={classes.panelRoot}
      >
        <Box className={classes.boxRoot} p={0}>
          {children}
        </Box>
      </Typography>
    );
  }
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`
  };
}

export default ({
  loading,
  data,
  invites,
  setMe,
  applying,
  setInvites,
  newInviteData,
  searchUsers,
  search,
  searching,
  checked,
  handleToggleChecked,
  handleProfileButton,
  onAcceptInvite,
  onDeclineInvite,
  history,

  loadMore,
  canMore,
  onScroll,
  onRefresh,
  refreshing,
  setRefreshing
}) => {
  if (loading) {
    return (
      <Wrapper>
        <BaseHeader text={"초대장 관리"} isBack={true} isClose={false} />
        <Helmet>
          <title>초대장 | WePlay</title>
        </Helmet>
        <ContentWrapper bgColor={"#fff"}>
          
        </ContentWrapper>
        <Loader />
      </Wrapper>
    );
  } else if (!loading && data && data.seeInvites) {
    console.log(data);

    const classes = useStyles();
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
    const handleChangeIndex = index => {
      setValue(index);
    };

    useEffect(() => {
      setMe(data.me);
      setInvites(data.seeInvites);
    }, [data]);

    useEffect(() => {
      console.log("newInviteData ..>" , newInviteData);
      if (newInviteData !== undefined) {
        const { newInvite } = newInviteData;
        const filter = invites.filter(i => i.id === newInvite.id);
        console.log(filter);
        if(filter.length === 0) {
          console.log("not includes ..>");
          setInvites(prev => [newInvite, ...prev]);
        } else {
          console.log("includes ..>");
        }
        
      }
    }, [newInviteData]);

    return (
      <Wrapper>
        <BaseHeader text={"초대장 관리"} isBack={true} isClose={false} />
        {/* <AppBar className={classes.appBar}>
          <Toolbar className={classes.mainToolBar}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="close"
              onClick={() => history.push("/")}
            >
              <Close />
            </IconButton>
            <HeaderTitle>{"초대장 관리"}</HeaderTitle>
          </Toolbar>
          <Toolbar className={classes.tabToolBar}>
            <MyTabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="inherit"
              variant="fullWidth"
              aria-label="tabs"
            >
              <Tab
                className={classes.tabRoot}
                label="팀 가입 초대"
                {...a11yProps(0)}
              />
              <Tab
                className={classes.tabRoot}
                label="용병 초대"
                {...a11yProps(1)}
              />
            </MyTabs>
          </Toolbar>
        </AppBar> */}

        <Helmet>
          <title>초대장 | WePlay</title>
        </Helmet>

        {applying && <Loader />}
        <PullToRefresh
          style={{ overflow: "auto", paddingTop:56, minHeight:"100vh", maxHeight:"100vh" }}
          direction="down"
          damping={100}
          onRefresh={() => onRefresh()}
          refreshing={refreshing}
          onScroll={e => onScroll(e)}
          indicator={{
            activate: <DedicateLoader/>,
            deactivate: <></>,
            release: <RefreshLoader />,
            finish: <></>
          }}
        >
        <ContentWrapper bgColor={"#fff"}>
          {/* <SwipeableViews
            className={classes.SwipeView}
            axis={"x"}
            index={value}
            onChangeIndex={handleChangeIndex}
            disabled={true}
          > */}
            <MyTabPanel value={value} index={0}>
              
              {invites.length === 0 ? (
                <EmptySearchResult>회원님께 도착한 초대장이 없습니다.</EmptySearchResult>
              ) : (
               
                <Grid className={classes.userWrap} item xs={12} md={6}>
                <List>
                  <Container>
                    {invites.length > 0 &&
                      invites.map((invite, idx) => (
                        <ListItem
                          key={invite.id}
                          button
                          className={classes.titleWrapper}
                          onClick={handleToggleChecked(idx)}
                        >
                          <ListItemAvatar>
                            <Avatar size={"sm"} url={invite.logo} />
                          </ListItemAvatar>

                          <TitleWrap>
                            <SmallDateInfo>
                              {moment(invite.createdAt).format("YYYY.MM.DD Ahh:mm")}
                            </SmallDateInfo>
                            <TeamName>{`${invite.teamName}팀`}</TeamName>
                            <Title>{`Lv.${invite.level}`}</Title>
                          </TitleWrap>

                          {checked[idx] ? (
                            <SliderButton elevation={4}>
                              <Zoom in={checked[idx]}>
                                <ActionButton
                                  onClick={handleProfileButton(invite.id)}
                                >
                                  프로필
                                </ActionButton>
                              </Zoom>
                              <Zoom
                                in={checked[idx]}
                                style={{
                                  transitionDelay: checked[idx]
                                    ? "100ms"
                                    : "0ms"
                                }}
                              >
                                <ActionButton
                                  onClick={onAcceptInvite(invite.id)}
                                >
                                  수락
                                </ActionButton>
                              </Zoom>
                              <Zoom
                                in={checked[idx]}
                                style={{
                                  transitionDelay: checked[idx]
                                    ? "200ms"
                                    : "0ms"
                                }}
                              >
                                <ActionButton
                                  onClick={onDeclineInvite(invite.id)}
                                >
                                  거부
                                </ActionButton>
                              </Zoom>
                            </SliderButton>
                          ) : (
                            <DotsWrap>
                              <Dots />
                            </DotsWrap>
                          )}
                        </ListItem>
                      ))}
                  </Container>
                </List>
              </Grid>
             
              )}
            
              
            </MyTabPanel>
            <MyTabPanel value={value} index={1}>
              <Grid className={classes.userWrap} item xs={12} md={6}></Grid>
            </MyTabPanel>
          {/* </SwipeableViews> */}
          
        </ContentWrapper>
        </PullToRefresh>
      </Wrapper>
    );
  }
};
