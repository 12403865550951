import React, { useEffect } from "react";
import styled from "styled-components";
import { Helmet } from "rl-react-helmet";
import Loader from "../../Components/Loader";
import CupHeader from "../../Components/CupHeader";
import moment from "moment";
import { Link } from "react-router-dom";
const Wrapper = styled.div``;

const ContentWrapper = styled.div`
  color: #000;
`;


// const CrownImg = styled.img`
//   width: 60px;
// `;

const HistoryWrap = styled.ul`
  max-width: 924px;
  margin: 0 auto;
  width: 95%;
  padding: 50px 30px 30px;

  background-color: #fff;
  @media (max-width: 600px) {
    padding: 50px 10px 30px;
  }
`;


const ListTitle = styled.div`
  background-color:#25374d;
  color:#fff;
  font-size:25px;
  padding:10px 20px;
  width:100%;
  margin-bottom:20px;
`;

const HistoryList = styled.li`
  list-style: none;
  width: 100%;
  background-color: #fff;
  border-bottom: 1px solid #000;
  padding: 15px 15px;
  cursor: pointer;
  overflow: auto;
  display: flex;
  align-self:center;
  justify-content: space-between;
  @media (max-width: 768px) {
    flex-direction: column;
  }

  & > .MuiDivider-vertical {
    height: 25px!important;
    margin: 0px 25px!important;
    background-color: rgba(0, 0, 0, 0.92)!important;
  }
  
`;

const ListHeader = styled.div`
  display: flex;
  align-items: center;
`;

const TeamName = styled.span`
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  position: relative;
  display: flex;
  align-items: center;
`;

const TeamInfo = styled.span`
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  display: flex;
    align-items: center;
`;

const AllGrade = styled(TeamInfo)`
  font-size: 15px;
  font-weight: 200;
`

export default ({ 
  loading, 
  data, 
  cupId,
  notices,
  setNotices,
  onCreatePage,
  handleInfiniteScroll 
  }) => {
  if (loading) {
    return (
      <Wrapper>
        <CupHeader id={cupId} active={"notice"} />
        <Loader />
      </Wrapper>
    );
  } else if (!loading && data && data.seeNotices) {

    const {
      seeNotices
    } = data;

    useEffect(() => {
      setNotices(seeNotices);
    }, [])

    return (
      <Wrapper>
        <Helmet>
          <title>공지사항 | WePlay</title>
        </Helmet>
        <CupHeader id={cupId} active={"notice"} />
        <ContentWrapper>
          <HistoryWrap>
            <ListTitle>공지</ListTitle>

            {notices && notices.length > 0 && notices.map((notice, index) => (
              <Link to={`/cup/${cupId}/notice/${notice.id}`} key={notice.id}>
                <HistoryList>
                  <ListHeader>
                    <TeamName>
                      {notice.title}
                    </TeamName>   
                  </ListHeader>

                  <AllGrade>
                    {notice.writer.username} │
                    {moment(notice.createdAt).format("YYYY년 MM월 DD일")}
                  </AllGrade>
                </HistoryList>
              </Link>
              
            ))}
          </HistoryWrap>
        </ContentWrapper>
      </Wrapper>
    );
  }
  return null;
};
