import React, { useState, useEffect } from "react";
import Proptypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import Address from "../assets/js/address";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap"
  },
  formControl: {
    width: "100%"
  },
  selectEmpty: {
    backgroundColor: "#ebebeb",
    border: 0,
    borderRadius: "5px",
    padding: "4px",
    fontSize: "16px",
    marginBottom: "5px"
  }
}));

const AddressSelect = ({
  onChange,
  defaultSido,
  defaultSigungu
}) => {
  const classes = useStyles();
  const items = Address.sido;

  const [selectedSido, setSelectedSido] = useState(defaultSido);
  const [selectedSigungu, setSelectedSigungu] = useState(defaultSigungu?defaultSigungu:"");
  const [SigunguList, setSigunguList] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const SidoList = Object.keys(items).map(sido => { return {value:sido, text:sido}});

  useEffect(() => {
    if(defaultSido) {
      if(defaultSido === "세종") {
        setDisabled(true);
      }
      if(defaultSido)
        setSigunguList(items[defaultSido].map(sigungu => { return {value:sigungu, text:sigungu}}));
    }
  }, [])

  const handleSelect = type => e => {
    console.log(e.target.value);

    if(type === "sido") {
      setSelectedSido(e.target.value);
      onChange(e.target.value);
      if(e.target.value === "세종") {
        setDisabled(true);
      }
      else {
        setDisabled(false);
        setSigunguList(items[e.target.value].map(sigungu => { return {value:sigungu, text:sigungu}}));
      }
      
    } else {
      setSelectedSigungu(e.target.value);
      onChange(selectedSido + " " + e.target.value);
    }  
  };

  return (
    <>
    <FormControl className={classes.formControl}>
      <Select
        value={selectedSido}
        onChange={handleSelect('sido')}
        className={classes.selectEmpty}
      >
        {SidoList.map(item => (
          <MenuItem key={item.value} value={item.value}>
            {item.text}
          </MenuItem>
        ))}
      </Select>
      
    </FormControl>
    {!disabled && <FormControl className={classes.formControl}>
      <Select
        value={selectedSigungu}
        onChange={handleSelect('sigungu')}
        className={classes.selectEmpty}
      >
        {SigunguList.length > 0 && SigunguList.map(item => (
          <MenuItem key={item.value} value={item.value}>
            {item.text}
          </MenuItem>
        ))}
      </Select>
    </FormControl>}
    </>
  );
};

AddressSelect.propTypes = {
  defaultSido:Proptypes.string,
  defaultSigungu:Proptypes.string
};

export default AddressSelect;
