import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import {
  SEE_HISTORYS
} from "./RoomHistoryQueries";
import HistoryPresenter from "./RoomHistoryPresenter";
import { useQuery } from "react-apollo-hooks";
import { toast } from "../../Components/Popup";

const RoomHistoryContainer = () => {
  const [first] = useState(10);
  const [skip] = useState(0);
  const [loadMore, setLoadMore] = useState(false);
  
  const { data, loading, fetchMore } = useQuery(SEE_HISTORYS, {
    variables: {
      skip: skip,
      first: first
    }
  });

  const [rooms, setRooms] = useState([]);

  const onScroll = () => {

    let scrollheight = Math.max(document.documentElement.scrollHeight, document.body.scrollHeight);
    let scrollTop = Math.max(document.documentElement.scrollTop, document.body.scrollTop);
    let clientHeight = document.documentElement.clientHeight;

    if(scrollTop + clientHeight === scrollheight) {
      setLoadMore(true);
      fetchMore({
        variables: {
          skip: data.seeRoomHistorys.length
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          
          setLoadMore(false);
          if (!fetchMoreResult) return prev;

          return Object.assign({}, prev, {
            seeRoomHistorys: [...prev.seeRoomHistorys, ...fetchMoreResult.seeRoomHistorys]
          });
        }
      });
    }
    
  };



  return (
    <HistoryPresenter
      loading={loading}
      data={data}
      rooms={rooms}
      setRooms={setRooms}
      loadMore={loadMore}
      onScroll={onScroll}
      //onSubmit={onSubmit}
    />
  );
};

export default withRouter(RoomHistoryContainer);
