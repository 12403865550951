import { gql } from "apollo-boost";

export const SEND_MATCH = gql`
  mutation sendMatch(
    $id: String!
    $oppTeamId: String!
    $title: String
    $message: String
  ) {
    sendMatch(id: $id, oppTeamId: $oppTeamId, title: $title, message: $message)
  }
`;
