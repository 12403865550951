import React, { useEffect } from "react";
import styled, { ThemeProvider } from "styled-components";
import { HashRouter as Router, BrowserRouter as BRouter } from "react-router-dom";
import { useQuery } from "react-apollo-hooks";
import { gql } from "apollo-boost";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Theme from "../Styles/Theme";
import GlobalStyles from "../Styles/GlobalStyles";
import Routes from "./Routes";
import { Helmet } from "rl-react-helmet";
import ErrorBoundary from "./ErrorBoundary";
// import BottomNavigation from './BottomNavigation';
import { toast } from "./Popup";

const QUERY = gql`
  {
    isLoggedIn @client
  }
`;

const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  min-height:100vh;
  position:fixed;
  height:100%;
  overflow:auto;
  -webkit-overflow-scrolling: touch; 
  /* iOS 관성스크롤 관련 코드 추가 */
  /* position:fixed;
  width:100%;
  height:100%;
  overflow:auto;
  -webkit-overflow-scrolling: touch; */
`;



export default () => {
  const {
    data: { isLoggedIn }
  } = useQuery(QUERY);

  localStorage.setItem("sport","1001");

  console.log("KKI // App.js route => ", window.location);
  if(window.location.pathname.indexOf("cup") !== -1) {
    console.log("KKI // App.js route => ", window.location.pathname);

    window.location.replace('/#/cups');
  }

  // useEffect(() => {
  //   if( window.location.href.indexOf("cup") !== -1 && !isLoggedIn) {
  //     toast.error("대회 기록을 위해 회원가입 후 진행해주세요.");
  //     setTimeout(() => {
  //       window.location.replace('/');
  //     }, 2000);
  //   }
  //   return () => {
      
  //   }
  // }, [window.location])


    return (
      <ThemeProvider theme={Theme}>
        <>
          <Helmet>
          <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0" />
          <meta charSet="utf-8" />
          </Helmet>
          <GlobalStyles isCup={window.location.hash.indexOf("cup") !== -1} />
         
          <Router>
            <Container>
              <ErrorBoundary>
                <Routes isLoggedIn={isLoggedIn} />
                <ToastContainer/>
              </ErrorBoundary>
            </Container>
          </Router>
        </>
      </ThemeProvider>
    );
};
