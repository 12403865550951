import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { gql } from "apollo-boost";
import { useQuery } from "react-apollo-hooks";


const getBG = (active, data, loading) => {
  let url;

  if (loading) {

  } else if (!loading && data && data.seeCup) {
    if (active === "main") {
      url = data.seeCup.bannerMain? data.seeCup.bannerMain: "https://d1jozj1mdbjtd9.cloudfront.net/weplay_event_bg_main.jpg";
    } else if (active === "notice") {
      url = data.seeCup.bannerNotice? data.seeCup.bannerNotice: "https://d1jozj1mdbjtd9.cloudfront.net/weplay_event_bg_main.jpg";
    } else if (active === "games") {
      url = data.seeCup.bannerGame? data.seeCup.bannerGame: "https://d1jozj1mdbjtd9.cloudfront.net/weplay_event_bg_main.jpg";
    } else if (active === "rank") {
      url = data.seeCup.bannerRank? data.seeCup.bannerRank: "https://d1jozj1mdbjtd9.cloudfront.net/weplay_event_bg_main.jpg";
    } else if (active === "teams") {
      url = data.seeCup.bannerTeam? data.seeCup.bannerTeam: "https://d1jozj1mdbjtd9.cloudfront.net/weplay_event_bg_main.jpg";
    }
  }
  return `
    background-image:url(${url})
  `;
};

const TopMenu = styled.div`
  ${props => getBG(props.active, props.banner, props.beforeLoaded)};
  /* ${props => props.banner ? props.banner : "https://d1jozj1mdbjtd9.cloudfront.net/weplay_event_bg_main.jpg"}; */
  height: 360px;
  background-size: cover;
  position: relative;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const CupTitle = styled.h4`
  color:#fff;
  font-size:35px;
  font-weight: 700;
  margin-bottom: 60px;
  text-align:center;
  word-break: keep-all;
  width: 90%;
  @media (max-width: 600px) {
    font-size:30px;
  }
`;

const CupButton = styled.button`
  background-color: #5E80EC;
  border: 1px solid #5E80EC;
  height: 60px;
  /* padding: 30px 50px; */
  width: 300px;
  color: #fff;
  margin-bottom: 20px;
  font-size: 24px;
  display: block;
  margin: 0 auto 20px;
  border-radius: 60px;
  font-family: "NanumSqure";
  font-weight: 500;
  cursor: pointer;

  @media (max-width: 600px) {
    font-size: 20px;
    height: 50px;
  }
`;

const TabMenu = styled.div`
    display: flex;
    width: 100%;
    border-collapse: separate;
    table-layout: fixed;
    position: absolute;
    bottom: 0;
    align-items: center;
    justify-content: center;
    & > a {
      padding: 18px 25px;
      @media (max-width: 500px) {  
        padding: 18px 10px;
        font-size:13px;
      }
    }
   
`;

const Tab = styled.span`
  color:#fff;
  font-size:16px;
  /* padding: 18px 25px; */
  cursor: pointer;
  &.active {
    font-weight:700;
    border-top: 4px solid #5e80ec;
    padding-top: 7px;
  }
  @media (max-width: 500px) {
    font-size:13px;
  }
`;

const GET_BANNER = gql`
  query seeCup($id: String!) {
    seeCup(id: $id) {
      id
      cupName
      bannerMain
      bannerNotice
      bannerGames
      bannerRank
      bannerTeams
    }
  }
`;

function CupHeader({ id, active }) {
  const { data, loading } = useQuery(GET_BANNER, {
    variables: {
      id
    },
  });

  return (
    <TopMenu id={id} active={active} banner={data} beforeLoaded={loading}>
      <CupTitle>{!loading && data && data.seeCup && data.seeCup.cupName}</CupTitle>
      <Link to={`/cup/${id}/request`}><CupButton>대회 신청</CupButton></Link>
      <TabMenu>
        <Link to={`/cups`}><Tab >대회목록</Tab></Link>
        <Link to={`/cup/${id}`}><Tab className={active === "main"?"active":""}>메인</Tab></Link>
        <Link to={`/cup/${id}/notices`}><Tab className={active === "notice"?"active":""}>공지</Tab></Link>
        <Link to={`/cup/${id}/games`}> <Tab className={active === "games"?"active":""}>경기</Tab></Link>
        <Link to={`/cup/${id}/rank`}><Tab className={active === "rank"?"active":""}>랭킹</Tab></Link>
        <Link to={`/cup/${id}/teams`}><Tab className={active === "teams"?"active":""}>출전팀 리스트</Tab></Link>
      </TabMenu>
    </TopMenu>
  );
}

export default CupHeader;
