import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import {
  GET_CHATS,
  DELETE_CHAT
} from "./ChatListQueries";
import TeamProfilePresenter from "./ChatListPresenter";
import { useQuery, useMutation } from "react-apollo-hooks";
import useLongPress from "../../Hooks/useLongPress";

const ChatListContainer = ({history}) => {
  const [chats, setChats] = useState([]);
  const [openDelete, setOpenDelete] = useState(false);
  const [selectedChat, setSelectedChat] = useState();
  const [refreshing, setRefreshing] = useState();
  const [loadMore, setLoadMore] = useState(false);
  const [canMore, setCanMore] = useState(true);

  const deleteChatMutation = useMutation(DELETE_CHAT);

  const handleOpenDelete = () => {
    setOpenDelete(true);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
    setSelectedChat(null);
  };

  const LongPress = useLongPress(handleOpenDelete, 1500);

  const { data, loading, fetchMore, refetch } = useQuery(GET_CHATS, {
    variables: {
      first:30,
      skip:0
    },
 
  });

  const handleDeleteChat = async () => {
    const _data = [...chats];
    _data.splice(
      _data.findIndex(i => i.id === selectedChat),
      1
    );
    setChats(_data);
    handleCloseDelete();

    try {
      const { data : { deleteChat }} = await deleteChatMutation({
        variables: {
          id: selectedChat
        }
      });
      await refetch();
      console.log(deleteChat);
    } catch (e) {
      console.log(e);
    }
  };

  // const { loading:loadingSearch, data: searchData, refetch: refetchUser } = useQuery(SEARCH_USER, {
  //   skip: search.value === "",
  //   variables: {
  //     term: search.value
  //   }
  // });
  // const search = useInput("");
  // const [searchUsers, setSearchUsers] = useState();
  // const [searching, setSearching] = useState(false);



  // useEffect(() => {
  //   if(search.value.length > 1) {
  //     setSearching(true);
  //   } else {
  //     setSearching(false);
  //   }
  
  //   // if(!loadingSearch && searchData && searchData.searchUser) {
  //   //   setSearchUsers(searchData.searchUser);
  //   //   setSearching(false);
  //   // }
  // }, [search.value]);

  const onRefresh = () => {
    //console.log(currentTarget);
    setRefreshing(true);
    fetchMore({
      variables: {
        skip: 0,
        first:chats.length
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        setRefreshing(false);
        if (!fetchMoreResult) return prev;

        setChats([...fetchMoreResult.seeChats])

        return Object.assign({}, prev, {
          seeChats: [...fetchMoreResult.seeChats]
        });
      }
    });
  };

  const onScroll = ({ currentTarget }) => {
    //console.log(currentTarget);
    if (
      canMore &&
      !loadMore &&
      currentTarget.scrollTop + currentTarget.clientHeight >=
        currentTarget.scrollHeight
    ) {
      setLoadMore(true);
      fetchMore({
        variables: {
          skip: data.seeChats.length
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          setLoadMore(false);

          if (!fetchMoreResult) return prev;

          if (fetchMoreResult.seeChats.length === 0) {
            setCanMore(false);
          } else {
            const mergeData = Array.from(new Set([...prev.seeChats, ...fetchMoreResult.seeChats]));

            setChats(mergeData)
            //setChats([...prev.seeChats, ...fetchMoreResult.seeChats])
          }

          return Object.assign({}, prev, {
            seeChats: [
              ...prev.seeChats,
              ...fetchMoreResult.seeChats
            ]
          });
        }
      });
    }
  };

  return (
    <TeamProfilePresenter
      loading={loading}
      data={data}
      chats={chats}
      setChats={setChats}

      setSelectedChat={setSelectedChat}
      LongPress={LongPress}
      openDelete={openDelete}
      handleCloseDelete={handleCloseDelete}
      handleDeleteChat={handleDeleteChat}
      // searchUsers={searchUsers}
      // search={search}
      // searching={searching}
      onRefresh={onRefresh}
      refreshing={refreshing}
      setRefreshing={setRefreshing}
      loadMore={loadMore}
      canMore={canMore}
      onScroll={onScroll}
    />
  );
};

export default withRouter(ChatListContainer);
