import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Helmet } from "rl-react-helmet";
import Loader from "../../Components/Loader";
//import Button from "../../Components/AdminButton";
import moment from 'moment';

const Wrapper = styled.div`
  min-height: 100vh;
  background-repeat: no-repeat;
  display: flex;
  background-size: cover;
  background-image: url(/imgs/weplay_event_bg_01_main.jpg);
  flex-direction: column;
  /* justify-content: center; */
  background-position: center;
  overflow: auto;
  height:100%;
`;

const Section = styled.div`
  /* overflow: hidden; */
  max-width: 1024px;
  margin: 0 auto;
  width: 90%;
  padding: 20px 0 40px;
  text-align: center;
`;

const LogoImg = styled.img`
  width:500px;
  @media (max-width: 500px) {
    width:100%;
  }
`;

const LeagueButton = styled.button`
    background-color: #5E80EC;
    border: 1px solid #5E80EC;
    min-height: 80px;
    width: 630px;
    padding: 0px 35px;
    color: #fff;
    margin-bottom: 20px;
    font-size: 24px;
    display: block;
    margin: 0 auto 20px;
    border-radius: 60px;
    font-family: "NanumSqure";
    font-weight: 500;
    cursor: pointer;
    word-break: keep-all;

    @media (max-width: 620px) {
      width:100%;
      font-size: 18px;
    }
`;


const ListSection = styled(Section)`
  max-width: 1024px;
  margin: 0 auto;
  width: 90%;
  padding: 0px;
  & > a {
    width: 630px;
    display: flex;
    margin: 0 auto 20px;
    @media (max-width: 600px) {
      width: 100%;
      font-size: 18px;
    }
  }
`;

const DateText = styled.span`
  font-size: 18px;
  @media (max-width: 600px) {
    display: block;
    font-size: 14px;
  }
`;


export default function CupListPresenter({
  loading,
  data,
  onCreatePage,
  onUpdatePage,
  cups,
  setCups
}) {
  if (loading) {
    return (
      <Wrapper>
        <Helmet>
          <title>대회관리 | WePlay</title>
        </Helmet>
        <Loader />
      </Wrapper>
    );
  } else if (!loading && data && data.seeCups) {

    //console.log(data);
    useEffect(() => {
      setCups(data.seeCups);

    }, [])

    return (
      <Wrapper>
        <Helmet>
          <title>대회관리 | WePlay</title>
        </Helmet>
        <Section>
          <LogoImg src={"https://d1jozj1mdbjtd9.cloudfront.net/splash_logo.png"} />
        </Section>
        <ListSection>
        {cups && cups.length > 0 && cups.map((cup,idx) => {
          // if(idx !== 0)
          //   return null;

          return (
            <Link key={cup.id} to={`/cup/${cup.id}`}>
              <LeagueButton> {cup.cupName} <DateText> /  {moment(cup.cupStartDate).format("YYYY년 MM월 DD일")}</DateText></LeagueButton>
            </Link>
          )
        }
        )}
        </ListSection>
       
      </Wrapper>
    );
  } 
}
