import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { GET_MY_TEAM , ADD_GAME, EDIT_PLACE } from "./GameCreateQueries";
import GameCreatePresender from "./GameCreatePresender";
import { useQuery, useMutation } from "react-apollo-hooks";
import { toast } from "../../Components/Popup";
import moment from "moment";

import useInput from "../../Hooks/useInput";


const GameCreateContainer = ({ history }) => {
  const sportId = localStorage.getItem("sport");
  const { data, loading } = useQuery(GET_MY_TEAM, {
    variables: {
      sportId
    }
  });

  const [uploading, setUpLoading] = useState(false);

  // For Game Info
  /*=================================================== */
  const [teamId, setTeamId] = useState();
  const [placeId, setPlaceId] = useState();
  const [locationId, setLocationId] = useState();
  const gameDate = useInput(moment().format("YYYY-MM-DD"));
  const startTime = useInput(moment().format("HH:mm"));
  const endTime = useInput(moment().add(1, 'hours').format("HH:mm"));
  const bio = useInput("");
  const title = useInput("");
  const message = useInput("", newValue => newValue.length < 300);
  const contact = useInput("", newValue=> /^[0-9-]{1,20}$/g.test(newValue), false, true);

  const playerCnt = useInput(3);

  const [isAddressChange, setIsAddressChange] = useState(false);

  const [latS, setLat] = useState();
  const [lngS, setLng] = useState();
  const [placeNameS, setPlaceName] = useState();
  const [addressS, setAddress] = useState();
  const [shortAddressS, setShortAddress] = useState();

  /*=================================================== */

  const addGameMutation = useMutation(ADD_GAME);
  const editPlaceMutation = useMutation(EDIT_PLACE);

  useEffect(() => {
    if(!loading) {
      const {
        id,
        location,
        place
      } = data.seeMyTeam;

      if(place && location) {
        const {
          id:placeId, 
          placeName, 
          address, 
          shortAddress 
        } = place;
        const {
          id:locationId, 
          latitude, 
          longitude,
        } = place;
        setLocationId(locationId);
        setPlaceId(placeId);
        setPlaceName(placeName);
        setAddress(address);
        setShortAddress(shortAddress);

        setLat(latitude);
        setLng(longitude);
      }
      setTeamId(id);

    }
  }, [data, loading]);

  const setAddressInfo = (placeName, address, shortAddress, lat, lng) => {
    if(addressS !== address) {
      setIsAddressChange(true);
    }
    setLat(parseFloat(lat));
    setLng(parseFloat(lng));
    setPlaceName(placeName);
    setAddress(address);
    setShortAddress(shortAddress);
  };

  //console.log(moment(gameDate.value).format("YYYY-MM-DDT00:00"));

  const handleSubmit = async () => {
    try {

      if (title.value === "") {
        toast.error("경기 제목을 입력해주세요.");
        return;
      }

      if (message.value === "") {
        toast.error("경기 설명을 입력해주세요.");
        return;
      }

      const gameDateTime = moment(new Date(gameDate.value+" "+startTime.value)).format("YYYY-MM-DDTHH:mm");
      if(gameDateTime < moment().format("YYYY-MM-DDTHH:mm")) {
        toast.error(<span>현재 시간 이후로 설정할 수 있습니다.</span>);
        return;
      }

      setUpLoading(true);

      
      let sendPlaceId = placeId;
      if((placeId && locationId) && isAddressChange) {
        const {
          data: { editPlace }
        } = await editPlaceMutation({
          variables: {
            lat : latS,
            lng : lngS,
            placeName : placeNameS,
            address : addressS,
            shortAddress : shortAddressS
          }
        });

        sendPlaceId = editPlace.id;
      } 

      console.log({
        homeId : teamId,
        placeId:sendPlaceId,
        lat:latS,
        lng:lngS,
        placeName:(placeNameS && placeNameS !== "")? placeNameS:"장소이름 없음",
        address:addressS,
        shortAddress:shortAddressS,

        playerCnt:playerCnt.value,
        gameDate:gameDateTime,
        startTime:startTime.value,
        endTime:endTime.value,
        title:title.value,
        message:message.value
      })

      const {
        data: { addGame }
      } = await addGameMutation({
        variables: {
          sportId,
          homeId : teamId,
          placeId:sendPlaceId,
          lat:latS,
          lng:lngS,
          placeName:(placeNameS && placeNameS !== "")? placeNameS:"장소이름 없음",
          address:addressS,
          shortAddress:shortAddressS,

          playerCnt:playerCnt.value,
          gameDate:moment(new Date(gameDate.value+" "+startTime.value)).format("YYYY-MM-DDTHH:mm"),
          startTime:startTime.value,
          endTime:endTime.value,
          title:title.value,
          message:message.value
        }
      });

      if (addGame.id) {
        toast.success("게임 생성 완료!");
        setTimeout(()=> {
          history.replace(`/game/${addGame.id}`);
        }, 1000)
        
      }
    } catch (e) {
      if(e.message.indexOf("EMPTY") !== -1 ) {
        toast.error("팀원을 모집 후 경기를 생성해주시기 바랍니다.");
      } else {
        toast.error("실패했습니다. 다시 시도해주세요.");
      }
      
    } finally {
      setUpLoading(false);
    }

  };

  const onClickClose = () => {
    history.goBack();
  };

  return (
    <GameCreatePresender
      uploading={uploading}

      gameDate={gameDate}
      startTime={startTime}
      endTime={endTime}
      bio={bio}
      title={title}
      message={message}
      contact={contact}
      playerCnt={playerCnt}

      onSubmit={handleSubmit}
      onClickClose={onClickClose}
      setAddressInfo={setAddressInfo}
      loading={loading}
      data={data}
    />
  );
};

export default withRouter(GameCreateContainer);
