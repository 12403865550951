import React, { useEffect } from "react";
import styled from "styled-components";
import { Helmet } from "rl-react-helmet";
import Loader from "../../Components/Loader";
import BoldText from "../../Components/BoldText";
import BaseHeader from "../../Components/Header/BaseHeader";
import PostCode from "../../Components/PostCode";
import Button from "../../Components/Button";
import Input from "../../Components/Input";
import Select from "../../Components/Select";
import DatePicker from "../../Components/DatePicker";
import TimePicker from "../../Components/TimePicker";
import CircularProgress from "@material-ui/core/CircularProgress";
import { NotFound } from "../../Components/NotFound";

const Wrapper = styled.div`
  width: 100%;
  flex-grow: 1;
  /* display: flex; */
  @media (min-width: 500px) {
    margin: 0 auto;
  }
  & .MuiInputBase-input {
    background-color: #ebebeb !important;
    border-radius: 8px !important;
    padding: 10px 10px !important;
  }
`;

const UserInfoRows = styled.div`
  flex-grow: 1;
  /* margin: 0px 10px; */
  background-color: #fff;
  color: #000;
  min-height: 55vh;
  padding-top: 10vh;
  padding-bottom: 30px;
`;

const UserInfoRow = styled.div`
  display: flex;
  font-size: 15px;

  flex-direction: column;
  padding: 15px 20px;
`;

const InputInfoColumn = styled.div`
  &:nth-child(1) {
    margin-bottom: 15px;
    font-weight: 500;
    font-size: 16px;
  }
  &:nth-child(2) {
    display: flex;
    flex-direction: column;
  }
`;

const TitleRow = styled.div`
  margin-bottom: 15px;
  font-weight: 500;
  font-size: 16px;
`;

const ProfileHint = styled.span`
  font-size: 14px;
  padding-left: 10px;
`;

const BioTextarea = styled.textarea`
  width: 100%;
  max-width: 500px;
  padding: 5px 15px;
  background-color: #ebebeb;
  border: ${props => props.theme.boxBorder};
  border-radius: 10px;
  color: #000;
  resize: none;
  font-size: 16px;
  font-family: "NanumSqure";
  &:focus {
    background-color: #fff;
    border: 1px solid #2b2b2b;
  }
`;

const ComboBox = styled.select``;

const Form = styled.div`
  display: flex;
  flex: 1;
  margin: 0 auto;
  max-width: ${props => props.theme.maxWidth};
`;

const BottomWrap = styled.div`
  display: flex;
  justify-content: center;
`;

export default ({
  uploading,
  gameDate,
  startTime,
  endTime,
  title,
  message,
  contact,
  playerCnt,

  loading,
  onSubmit,
  onClickClose,
  setAddressInfo,
  data
}) => {
  if (loading === true) {
    return (
      <Wrapper>
        <Loader />
      </Wrapper>
    );
  } else if (!loading && data && data.seeMyTeam) {
    //console.log(data);

    useEffect(() => {
      message.setValue(data.seeMyTeam.bio)
    },[]);
    return (
      <Wrapper>
        <Helmet>
          <title>경기 생성 | WePlay</title>
        </Helmet>
        {uploading && <Loader />}
        <BaseHeader
          isClose={true}
          text={"경기 생성"}
          onClickClose={onClickClose}
        />
        <Form>
          <UserInfoRows>
            <UserInfoRow>
              <InputInfoColumn>
                경기 일정<ProfileHint>*</ProfileHint>
              </InputInfoColumn>
              <InputInfoColumn>
                <DatePicker gameDate={gameDate} />
              </InputInfoColumn>
            </UserInfoRow>

            <UserInfoRow>
              <InputInfoColumn>
                경기 시간<ProfileHint>*</ProfileHint>
              </InputInfoColumn>
              <InputInfoColumn>
                <TimePicker startTime={startTime} endTime={endTime} />
              </InputInfoColumn>
            </UserInfoRow>

            <UserInfoRow>
              <InputInfoColumn>
                경기장<ProfileHint>*</ProfileHint>
              </InputInfoColumn>
              {data.seeMyTeam.place ? (
                <InputInfoColumn>
                  <PostCode
                    setAddressInfo={setAddressInfo}
                    defaultValue={data.seeMyTeam.place.address}
                    text={"경기장 변경"}
                  />
                </InputInfoColumn>
              ) : (
                <InputInfoColumn>
                  <PostCode
                    setAddressInfo={setAddressInfo}
                    text={"경기장 검색"}
                  />
                </InputInfoColumn>
              )}
            </UserInfoRow>

            <UserInfoRow>
              <InputInfoColumn>
                <BoldText text={"경기장 연락처"} />
              </InputInfoColumn>
              <InputInfoColumn>
                <Input
                  value={contact.value}
                  defaultValue={""}
                  onChange={contact.onChange}
                  placeholder={"'-'를 제외하고 입력해주세요"}
                />
              </InputInfoColumn>
            </UserInfoRow>

            <UserInfoRow>
              <InputInfoColumn>
                <BoldText text={"플레이어 수"} />
                <ProfileHint>*</ProfileHint>
              </InputInfoColumn>
              <InputInfoColumn>
                <Select
                  items={[
                    { text: "3 vs 3", value: 3 },
                    { text: "5 vs 5", value: 5 }
                  ]}
                  value={playerCnt.value}
                  onChange={playerCnt.onChange}
                />
              </InputInfoColumn>
            </UserInfoRow>

            <UserInfoRow>
              <InputInfoColumn>
                <BoldText text={"경기 소개"} />
                <ProfileHint>* 최대 300자 제한</ProfileHint>
              </InputInfoColumn>
              <TitleRow>
                <Input
                  value={title.value}
                  defaultValue={""}
                  onChange={title.onChange}
                  placeholder={"제목을 입력하세요"}
                />
              </TitleRow>

              <InputInfoColumn>
                <BioTextarea 
                  value={message.value}
                  onChange={message.onChange}
                  cols="4" rows="6">
                  
                </BioTextarea>
              </InputInfoColumn>
            </UserInfoRow>
            {uploading ? (
                <CircularProgress />
              ) : (
                <Button color={"#000"} position={"relative"} onClick={onSubmit} text={"경기 생성"} />
              )}
            
          </UserInfoRows>
        </Form>
      </Wrapper>
    );
  } else {
    return <NotFound text={"호스트만 경기를 생성할 수 있습니다."} />
  }
};
