import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import {
  GET_TEAM,
  INVITE_USER,
  SEARCH_USER,
  NEW_INFO,
  ACCEPT_TEAM,
  DECLINE_TEAM
} from "./TeamUserProfileQueries";
import TeamProfilePresenter from "./TeamUserProfilePresenter";
import { useQuery, useMutation, useSubscription } from "react-apollo-hooks";
import { toast } from "../../Components/Popup";

import useInput from "../../Hooks/useInput";
import Client from "../../Apollo/Client";

const TeamProfileContainer = ({ history, match: { params } }) => {
  const teamId = params.team;
  const [applying, setApplying] = useState(false);
  const [searching, setSearching] = useState(false);
  const [refreshing, setRefreshing] = useState(false);

  const searchRequest = useInput("");
  const searchInvite = useInput("");

  // 다이얼로그 관련
  const declineMessage = useInput("죄송합니다.\n해당 포지션에 다른 선수가 있습니다.");
  const [selectRequest, setSelectRequest] = useState();

  const [inviteUser, setInviteUser] = useState();
  const [openSearch, setOpenSearch] = useState(false);
  const [openInvite, setOpenInvite] = useState(false);
  const [openShare, setOpenShare] = useState(false);


  const [requestUsers, setRequestUsers] = useState([]);
  const [invitedUsers, setInvitedUsers] = useState([]);


  const search = useInput("");
  const [searchUsers, setSearchUsers] = useState();

  const handleSearchOpen = () => {
    setOpenSearch(true);
  };

  const handleSearchClose = () => {
    setOpenSearch(false);
  };

  const handleInviteOpen = (user) => () => {
    setInviteUser(user);
    setOpenInvite(true);
  };

  const handleInviteClose = () => {
    setInviteUser(null);
    setOpenInvite(false);
  };

  const handleShareOpen = () => {
    setOpenShare(true);
  };

  const handleShareClose = () => {
    setOpenShare(false);
  };

  const defaultHandleBack = () => {
    history.goBack();
  }

  
  const handleProfileButton = id => () => {
    history.push(`/user/${id}`);
  }

  const { data, loading, refetch } = useQuery(GET_TEAM, {
    pollInterval:1000,
    variables: {
      id: teamId.toString(),
      sportId:"1001"
    }
  });

  const onRefresh = async () => {
    //console.log(currentTarget);
    try {
      setRefreshing(true);
      await refetch();
    } catch(e) {
      console.log(e);
    } finally {
      setRefreshing(false);
    }
  };

  const { 
    loading:loadingSearch, 
    data: searchData, 
    //fetchMore:fetchMoreSearch 
  } = useQuery(SEARCH_USER, {
    skip: search.value === "",
    variables: {
      term: search.value,
      teamId
    },
    fetchPolicy: "network-only"
  });

  const { data: newTeamInfo } = useSubscription(NEW_INFO, {
    variables: {
      id: teamId
    }
  });
  const inviteMutation = useMutation(INVITE_USER);
  const acceptTeamMutation = useMutation(ACCEPT_TEAM);
  const declineTeamMutation = useMutation(DECLINE_TEAM);


  //console.log("newTeamInfo ===== ", newTeamInfo);
  const handleNewInfo = () => {
    if(newTeamInfo !== undefined) {
      
      const { subscriptionTeam } = newTeamInfo;
      
      setRequestUsers(subscriptionTeam.requestUsers);
      setInvitedUsers(subscriptionTeam.inviteUsers)
    }
  }

  useEffect(()=> {
    handleNewInfo();
  },[newTeamInfo]);

  useEffect(() => {
    if(search.value.length > 0) {
      setSearching(true);
    } else {
      setSearching(false);
    }

    if(!loadingSearch && searchData && searchData.searchUserForTeam) {
      setSearchUsers(searchData.searchUserForTeam);
      setSearching(false);
    }
  }, [search.value, loadingSearch, searchData]);

  const handleInvite = async () => {

    const data = [...searchUsers];
    data.splice(data.findIndex(i => i.id === inviteUser.id), 1);
    setSearchUsers(data);

    
    try {
      setApplying(true);
      handleSearchClose();
      handleInviteClose();
      const {
        data: { inviteUser:result }
      } = await inviteMutation({
        variables: {
          id: teamId,
          userId: inviteUser.id
        }
      });

      if (result) {
        toast.success("팀 초대장을 전송했습니다.");
        Client.writeData({
          id:`User:${inviteUser.id}`,
          data: {
            isInviteToUser:true
          }
        })
        refetch();
      }
    } catch (e) {
      toast.error("오류가 발생했습니다.");
    } finally {
      
      setApplying(false);
    }
  };



  const onAcceptTeam = req => async e => {
    try {
      e.preventDefault();
      setApplying(true);
      const { data: { acceptTeam } } = await acceptTeamMutation({
        variables: {
          id:teamId,
          requestId: req.id,
          reqUserId: req.user.id
        }
      });
      refetch();
      setApplying(false);
      console.log(acceptTeam);
      if(acceptTeam) {
        const reqUsers = [...requestUsers];
        reqUsers.splice(reqUsers.findIndex(i => i.id === req.id), 1);
        setSearchUsers(reqUsers);

        toast.success("가입 요청을 수락했습니다.");
      }
      
    } catch (e) {
      if(e.message.indexOf("already")!==-1) {
        toast.error("해당 회원은 이미 다른 팀에 가입했습니다.");
      } else if(e.message.indexOf("exist")!==-1) {
        toast.error("존재하지 않는 신청입니다.");
      }else {
        toast.error("오류가 발생했습니다.");
      }
      setApplying(false);
      
    }
  }

  const onDeclineTeam = async (message) => {
    try {

      setApplying(true);
      const { data: { declineTeam } } = await declineTeamMutation({
        variables: {
          id : teamId,
          requestId: selectRequest.id,
          reqUserId: selectRequest.user.id,
          declineMessage:message
        }
      });
      refetch();
      console.log(declineTeam);
      if(declineTeam) {
        const reqUsers = [...requestUsers];

        console.log(reqUsers.findIndex(i => i.id === selectRequest.id));
        reqUsers.splice(reqUsers.findIndex(i => i.id === selectRequest.id), 1);
        setSearchUsers(reqUsers);

        toast.success("가입 요청을 거부했습니다.");
      }
      
    } catch (e) {
      console.log(e.message);
      if(e.message.indexOf("already")!==-1) {
        toast.error("해당 회원은 이미 다른 팀에 가입했습니다.");
      } else if(e.message.indexOf("exist")!==-1) {
        toast.error("존재하지 않는 신청입니다.");
      }else {
        toast.error("오류가 발생했습니다.");
      }
      
    } finally {
      setApplying(false);
    }
  }

  // const onFetchMore = (type) => {
  //   if(type === "search") {
  //     fetchMoreSearch({
  //       variables: {
  //         skip: searchUsers.length
  //       },
  //       updateQuery: (prev, { fetchMoreResult }) => {
        
 
  //         console.log(fetchMoreResult);
  //         if (!fetchMoreResult) return prev;

  //         return Object.assign({}, prev, {
  //           searchUserNoInvite: [...prev.searchUserNoInvite, ...fetchMoreResult.searchUserNoInvite]
  //         });
  //       }
  //     });
  //   }
  // };

  return (
    <TeamProfilePresenter
      loading={loading}
      data={data}
      applying={applying}

      requestUsers={requestUsers}
      setRequestUsers={setRequestUsers}
      invitedUsers={invitedUsers}
      setInvitedUsers={setInvitedUsers}

      inviteUser={inviteUser}
      openSearch={openSearch}
      openInvite={openInvite}
      openShare={openShare}
      handleInviteOpen={handleInviteOpen}
      handleInviteClose={handleInviteClose}
      handleShareOpen={handleShareOpen}
      handleShareClose={handleShareClose}
      handleProfileButton={handleProfileButton}

      onAcceptTeam={onAcceptTeam}
      onDeclineTeam={onDeclineTeam}

      defaultHandleBack={defaultHandleBack}
      handleSearchOpen={handleSearchOpen}
      handleSearchClose={handleSearchClose}
      handleInvite={handleInvite}
      searchUsers={searchUsers}
      search={search}
      searching={searching}
      searchRequest={searchRequest}
      searchInvite={searchInvite}

      selectRequest={selectRequest}
      setSelectRequest={setSelectRequest}
      declineMessage={declineMessage}
      onRefresh={onRefresh}
      refreshing={refreshing}
    />
  );
};

export default withRouter(TeamProfileContainer);
