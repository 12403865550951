import React from 'react';
import { CircularProgress } from '@material-ui/core';
import styled from 'styled-components';

const Container = styled.div`
  position: fixed;
  /* position: relative; */
  top: ${props => props.top};
  left:50%;
  transform:translateX(-50%);
  & > .MuiCircularProgress-colorPrimary {
    position: relative;
    top: 0px;
    /* position: absolute;
    top: -50px; */
    z-index: 999;
    width: 35px !important;
    height: 35px !important;
    color: #1ea9e1;
  }
`;

const DedicateLoader = ({top="-30px"}) => {
  return (
    <Container top={top}>
      <CircularProgress variant="static" value={100}/>
    </Container>
  )
}

export default DedicateLoader;