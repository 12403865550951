import React, { useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import moment from "moment";
import "moment/locale/ko";
import { Helmet } from "rl-react-helmet";

import Loader from "../../Components/Loader";
import BaseHeader from "../../Components/Header/BaseHeader";
import PriceNumber from "../../Components/PriceNumber";


const Wrapper = styled.div`
  min-height: calc(100vh - 76px);
`;

const ContentWrapper = styled.div`
  padding-top: 56px;
  /* margin: auto 10px; */
  background-color: ${props => props.bgColor};
  min-height: 100vh;
  & > div {
    margin-bottom: 5px;
  }
  color: #000;
  @media (min-width: 500px) {
    max-width: ${props => props.theme.maxWidth};
    margin: 0 auto;
  }
`;

const TeamName = styled.span`
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  padding-bottom: 7px;
  position: relative;
`;

const HistoryWrap = styled.ul`
  padding: 0;
`;

const Index = styled.div`
  width: 100%;
  background-color: #f2f2f2;
  border-bottom: 1px solid #ddd;
  line-height: 32px;
  text-align: left;
  color: #333;
  font-size: 14px;
`;

const IndexMonth = styled.span`
  padding: 0 10px;
  font-weight: 600;
`;

const IndexResult = styled.span`
  float: right;
  font-size: 13px;
  color: #666;
  text-align: right;
  padding: 0;
  padding-right: 10px;
  & > span {
    font-weight: 600;
  }
`;

const HistoryList = styled.li`
  list-style: none;
  width: 100%;
  background-color: #fff;
  border-bottom: 1px solid #ddd;
  padding: 16px 0;
  cursor: pointer;
  overflow: auto;
  display: table;
`;

const ScheduleDate = styled.div`
  text-align: center;
  color: #2277ff;
  width: 20%;
  font-family: "exo 2", sans-serif;
  display: table-cell;
  vertical-align: middle;
`;

const ScheduleDay = styled.h3`
  background-color: #f2f2f2;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  font-size: 21px;
  font-weight: 800;
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ScheduleInfo = styled.div`
  font-size: 13px;
  display: table-cell;
  vertical-align: middle;
  & > p {
    /* color: #999; */
    font-size: 12px;
    line-height: 1.3em;
  }
`;

const ScheduleResult = styled.div`
  text-align: center;
  width: 20%;
  font-family: "exo 2", sans-serif;
  border-left: 1px solid #ddd;
  display: table-cell;
  vertical-align: middle;
`;

const ScoreText = styled.h3`
  color: #333;
  font-size: 16px;
  font-weight: 800;
  margin-bottom: 7px;
`;

const HeaderAvatar = styled.div`
  border: 1px solid #fff;
  border-radius: 50%;
  padding: 6px;
  position: absolute;
  top: 100px;
  left: 50%;
  transform: translateX(-50%);
`;

const StatusSpan = styled.span`
  /* background-color: ${props => props.bgColor}; */
  background-color:#f50057;
  color:#fff;
  font-size: 12px;
  font-weight: 600;
  border-radius: 6px;
  padding: 3px;
  margin-left: 5px;
  position: absolute;
`;

const MVPSpan = styled.span`
  /* background-color: ${props => props.bgColor}; */
  background: linear-gradient(110deg, #ffd421 0%, #ffd421 50%, transparent 32%), linear-gradient(-70deg, #ffbe21 0%, #ffbe21 50%, transparent 27%);
  border-radius: 1px;
  color: #000;
  font-size: 12px;
  font-weight: 600;
  padding: 3px;
  margin-left: 5px;
  position: absolute;
`;

const _gameWeekText = gameDate => {
  let week = ["일", "월", "화", "수", "목", "금", "토"];
  let date = new Date(gameDate);
  return `(${week[date.getDay()]})`;
};

const _gameTimeText = time => {
  const hour = parseInt(time.split(":")[0]);
  const minute = time.split(":")[1];
  const ampm = hour >= 12 ? "오후" : "오전";
  if (hour > 12) return `${ampm} ${hour - 12}:${minute}`;
  else return `${ampm} ${hour}:${minute}`;
};

const RoomHistoryPresenter = ({
  loading,
  data,
  rooms,
  setRooms,
  onScroll,
  loadMore
}) => {
  if (loading) {
    return (
      <Wrapper>
        <BaseHeader text={"오픈방 이력"} isBack={true} isClose={false} />
        <Helmet>
          <title>오픈방 이력 | WePlay</title>
        </Helmet>
        <Loader />
      </Wrapper>
    );
  } else if (!loading && data && data.seeRoomHistorys) {
    console.log(data);

    useEffect(() => {
      const historys = data.seeRoomHistorys;
      setRooms(
        historys.reduce((acc, item) => {
          (acc[moment(item.gameDate).format("YYYY-MM")] =
            acc[moment(item.gameDate).format("YYYY-MM")] || []).push(item);
          return acc;
        }, {})
      );
    }, []);

    return (
      <Wrapper>
        <BaseHeader text={"오픈방 이력"} isBack={true} isClose={false} />
        <Helmet>
          <title>오픈방 이력 | WePlay</title>
        </Helmet>
        <ContentWrapper bgColor={"#fff"}>
          <HistoryWrap>
            {rooms &&
              Object.keys(rooms).map((key, i) => {
                const MONTH = key.split("-")[1];

                return (
                  <div key={i}>
                    <Index>
                      <IndexMonth>{MONTH}월</IndexMonth>
                    </Index>
                    {rooms[key].map(room => {
                      let routing = `/room/${room.id}`;

                      return (
                        <Link key={room.id} to={routing}>
                          <HistoryList key={room.id}>
                            <ScheduleDate>
                              <ScheduleDay>
                                {moment(room.gameDate).format("DD")}
                              </ScheduleDay>
                            </ScheduleDate>
                            <ScheduleInfo>
                              {room.host && (
                                <>
                                  <TeamName>{`호스트 ${room.host.username}`}</TeamName>

                                  {room.gameStatus === "RECRUIT" &&
                                  room.isUser && (
                                    <StatusSpan bgColor={"#ffbe21"}>
                                      승인 완료
                                    </StatusSpan>
                                  )}
                                </>
                              )}

                              <p>
                                {moment(room.gameDate).format("YYYY. MM. DD")}
                                {_gameWeekText(room.gameDate)}{" "}
                                {`${_gameTimeText(room.startTime)} - ${room.endTime}`}
                              </p>
                              <p>{room.place.placeName}</p>
                              <p>참가비 <PriceNumber price={room.price}/>원 | {room.purpose}</p>
                            </ScheduleInfo>

                            {room.gameStatus === "CANCEL" && (
                              <ScheduleResult>
                                <ScoreText>취소</ScoreText>
                              </ScheduleResult>
                            )}

                            {room.gameStatus === "MATCH" && (
                              <ScheduleResult>
                                <ScoreText>진행 확정</ScoreText>
                              </ScheduleResult>
                            )}
                            {room.gameStatus === "PLAYING" && (
                              <ScheduleResult>
                                <ScoreText>진행 중</ScoreText>
                              </ScheduleResult>
                            )}
                            {(room.gameStatus === "CONFIRM" || room.gameStatus === "FINISH") && (
                              <ScheduleResult>
                                <ScoreText>완료</ScoreText>
                              </ScheduleResult>
                            )}

                            {room.gameStatus === "RECRUIT" && (
                              <ScheduleResult>
                                <ScoreText>모집중</ScoreText>
                              </ScheduleResult>
                            )}

                            {/* {(game.gameStatus === "CONFIRM" ||
                                game.gameStatus === "COMPLETE") &&
                                game.result && (
                                  <ScheduleResult>
                                    <ScoreText>
                                      {game.homeTeam.id === teamId
                                        ? `${game.result.homeScore}:${game.result.awayScore}`
                                        : `${game.result.awayScore}:${game.result.homeScore}`}
                                    </ScoreText>

                                    {game.winner && game.winner === "draw" ? (
                                      <GameResultText state={game.winner} />
                                    ) : (
                                      <GameResultText
                                        state={
                                          game.winner === teamId
                                            ? "win"
                                            : "lose"
                                        }
                                      />
                                    )}
                                  </ScheduleResult>
                                )} */}
                          </HistoryList>
                        </Link>
                      );
                    })}
                  </div>
                );
              })}
          </HistoryWrap>
        </ContentWrapper>
      </Wrapper>
    );
  }

  return null;
};

export default RoomHistoryPresenter;
