import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const WinText = styled.div`
  font-size: 11px;
  color: #227fff;
`;

const LoseText = styled.div`
  font-size: 11px;
  color: #ea4849;
`;

const DrawText = styled.div`
  font-size: 11px;
  color: #000000;
`;

export default function GameResultText({ state }) {
  switch(state) {
    case 'win':
      return <WinText>WIN</WinText>;
    case 'lose':
      return <LoseText>LOSE</LoseText>;
    case 'draw':
      return <DrawText>DRAW</DrawText>;
    default:
      return null;
  }
}

GameResultText.propTypes = {
  state:PropTypes.oneOf(['win','lose','draw'])
}