import React, { useEffect } from "react";
import styled from "styled-components";
import { Helmet } from "rl-react-helmet";
import { Link } from "react-router-dom";
import Loader from "../../Components/Loader";
import Avatar from "../../Components/Avatar";

import MaterialTable from "material-table";
import CupHeader from "../../Components/CupHeader";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";

const Wrapper = styled.div``;

const Section = styled.div`
  overflow: hidden;
  max-width: 1024px;
  margin: 0 auto;
  width: 95%;
  padding: 20px 20px 20px;
  background-color: #fff;
  color: #000;
  & .MuiTableCell-root {
    text-align: center;
  }
  & .MuiInputBase-input {
    text-align: center !important;
  }
  &:last-child {
    padding-top: 10px;
    min-height: 50vh;
  }
  @media (max-width: 500px) {
    & .MuiTableCell-root {
      font-size: 12px;
      padding: 2px !important;
    }
    padding: 10px 10px 20px;
  }
`;

const BackArrow = styled.div`
  font-size: 16px;
  padding: 10px 10px;
`;

const GameHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0 auto;
  background-color: #25374d;
  color: #fff;
  padding: 10px 0px;
`;

const GameHeaderColumn = styled.div`
  padding: 7px;
  font-size: 16px;
  text-align: center;
  display: flex;
  align-items: center;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const VSText = styled.span`
  font-size: 35px;
  padding: 0px 30px;

  @media (max-width: 768px) {
    font-size: 24px;
    padding: 0px 15px;
  }
  @media (max-width: 425px) {
    font-size: 18px;
    padding: 0px 0px;
  }
`;

const HeaderAvatar = styled.div`
  border: 1px solid #fff;
  border-radius: 50%;
  padding: 6px;
  min-width: 99px;
  min-height: 99px;
`;

const TeamName = styled.span`
  font-size: 33px;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  position: relative;
  margin-left: 10px;
  word-break: keep-all;
  @media (max-width: 768px) {
    font-size: 25px;
  }
  @media (max-width: 600px) {
    font-size: 18px;
    margin-left: 0px;
  }
`;

const WinText = styled.span`
  color: #227fff;
  font-size: 15px;
  font-family: "Jalnan";
  padding-left: 10px;
  position: absolute;
  top: 14px;
  font-size: 18px;
  @media (max-width: 768px) {
    top: 8px;
  }
  @media (max-width: 425px) {
    top: 0px;
  }
`;

const ScoreTable = styled.table`
  border: 1px solid #000;
  text-align: center;
  font-size: 16px;
  width: 100%;
  margin-top: 15px;
  @media (max-width: 768px) {
    font-size: 14px;
  }
  @media (max-width: 425px) {
    font-size: 13px;
  }
`;

const ScoreThead = styled.thead`
  border-bottom: 1px solid #000;
  & > tr {
    border-bottom: 1px solid #000;
    & > th:first-child {
      font-weight: 600;
      word-break: keep-all;
    }
  }
  & th {
    padding: 15px 0px;
    width: 1px;
  }
`;

const ScoreTbody = styled.tbody`
  & > tr {
    border-top: 1px solid #dddddd;
    & > td:first-child {
      font-weight: 600;
    }
  }
  & td {
    padding: 15px 0px;
  }
`;

const TeamTitle = styled.h4`
  font-size: 20px;
  margin-top: 20px;
  margin-bottom: 5px;
`;

const ThreeGameWrapper = styled.div`
  display: flex;
`;

const TableWrapper = styled.div`
  flex: 1;
`;

const PlayTime = styled.div`
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  border-right: 1px solid #000;
  text-align: center;
  font-size: 16px;
  margin-top: 15px;
  flex: 0.3;
  display: flex;
  flex-direction: column;
  @media (max-width: 768px) {
    font-size: 14px;
  }
  @media (max-width: 425px) {
    font-size: 11px;
  }
`;

const PlayTimeTitle = styled.div`
  padding: 15px 0px;
  border-bottom: 1px solid #000;
`;

const PlayTimeInputWrap = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const MobileTable = styled.div`
  width: 100%;
  & > div {
    width: 100%;
    display: table;
    font-size: 13px;
    position: relative;
    margin-bottom: 5px;
    border: 1px solid #000;
  }
`;
const EmptyHeader = styled.div`
  background-color: #fff !important;
  width: 70px;
`;

const MobileTHeader = styled.div`
  display: table-header-group;
  /* background-color: rgb(221,221,221); */
  & div {
    font-weight: 500;
    background-color: rgb(221, 221, 221);
  }
`;

const MobileRow = styled.div`
  display: table-row;
  & > div {
    padding: 5px;
    display: table-cell;
    text-align: center;
  }
`;

const BoldTh = styled.th`
  font-weight: 600;
`;
const BoldTd = styled.td`
  font-weight: 600;
`;

const MobileColumn = styled.div`
  display: table-row-group;
`;

export default ({
  loading,
  data,
  cupId,
  gameId,
  homeUsers,
  setHomeUsers,
  awayUsers,
  setAwayUsers,
  homeScore,
  setHomeScore,
  awayScore,
  setAwayScore,
  userColumn,
  playTime,
  setPlayTime,
  homeTeamFoul,
  setHomeTeamFoul,
  awayTeamFoul,
  setAwayTeamFoul,
}) => {
  if (loading) {
    return (
      <Wrapper>
        <Loader />
        <CupHeader id={cupId} active={"games"} />
      </Wrapper>
    );
  } else if (!loading && data && data.seeFullGame) {
    const {
      seeFullGame: { winner, gameName, homeTeam, awayTeam, result, playerCnt },
    } = data;
    console.log(data);
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down("xs"));

    let userColumn;
    if (playerCnt === 3) {
      userColumn = matches ? mobileThreeGameColumn : ThreeGameColumn;
    } else if (playerCnt === 5) {
      userColumn = matches ? mobileFiveGameColumn : FiveGameColumn;
    }

    useEffect(() => {
      if (result) {
        setHomeScore(result.homeTeam);
        setAwayScore(result.awayTeam);
        setHomeTeamFoul(result.homeTeamFoul);
        setAwayTeamFoul(result.awayTeamFoul);
        setPlayTime(result.playingTime);
      }

      const homeMember = homeTeam.host
        ? [homeTeam.host].concat(homeTeam.users)
        : homeTeam.users;
      setHomeUsers(
        homeMember.map((user) => {
          if (user.userStat.length > 0) {
            user.thisUserStat = user.userStat.filter(
              (stat) => stat.game.id === gameId
            )[0];
            if(!user.thisUserStat) {
              user.thisUserStat = {
                score: 0,
                rebound: 0,
                assist: 0,
                blocking: 0,
                steal: 0,
                turnOver: 0,
                foul: 0,
                oneScore: 0,
                twoScore: 0,
                threeScore: 0,
                freeShot: 0,
                freeShotTotal: 0,
              };
            }
          } else {
            user.thisUserStat = {
              score: 0,
              rebound: 0,
              assist: 0,
              blocking: 0,
              steal: 0,
              turnOver: 0,
              foul: 0,
              oneScore: 0,
              twoScore: 0,
              threeScore: 0,
              freeShot: 0,
              freeShotTotal: 0,
            };
          }
          return user;
        })
      );
      const awayMember = awayTeam.host
        ? [awayTeam.host].concat(awayTeam.users)
        : awayTeam.users;
      setAwayUsers(
        awayMember.map((user) => {
          if (user.userStat.length > 0) {
            user.thisUserStat = user.userStat.filter(
              (stat) => stat.game.id === gameId
            )[0];
            if(!user.thisUserStat) {
              user.thisUserStat = {
                score: 0,
                rebound: 0,
                assist: 0,
                blocking: 0,
                steal: 0,
                turnOver: 0,
                foul: 0,
                oneScore: 0,
                twoScore: 0,
                threeScore: 0,
                freeShot: 0,
                freeShotTotal: 0,
              };
            }
          } else {
            user.thisUserStat = {
              score: 0,
              rebound: 0,
              assist: 0,
              blocking: 0,
              steal: 0,
              turnOver: 0,
              foul: 0,
              oneScore: 0,
              twoScore: 0,
              threeScore: 0,
              freeShot: 0,
              freeShotTotal: 0,
            };
          }
          return user;
        })
      );
    }, []);

    return (
      <Wrapper>
        <Helmet>
          <title>경기 정보 | WePlay</title>
        </Helmet>
        <CupHeader id={cupId} active={"games"} />
        <Section>
          <BackArrow>
            <Link to={`/cup/${cupId}/games`}>
              <span>{"< 뒤로"}</span>
            </Link>
          </BackArrow>
          <GameHeader>
            <GameHeaderColumn>
              <HeaderAvatar>
                <Link to={`/cup/${cupId}/team/${homeTeam.id}`}>
                  <Avatar url={homeTeam.logo} size={"lmd"} />
                </Link>
              </HeaderAvatar>
              <TeamName>
                {homeTeam.teamName}{" "}
                {winner === homeTeam.id && <WinText>승</WinText>}
              </TeamName>
            </GameHeaderColumn>
            <GameHeaderColumn>
              <VSText>VS</VSText>
            </GameHeaderColumn>
            <GameHeaderColumn>
              <HeaderAvatar>
                <Link to={`/cup/${cupId}/team/${awayTeam.id}`}>
                  <Avatar url={awayTeam.logo} size={"lmd"} />
                </Link>
              </HeaderAvatar>
              <TeamName>
                {awayTeam.teamName}{" "}
                {winner === awayTeam.id && <WinText>승</WinText>}
              </TeamName>
            </GameHeaderColumn>
          </GameHeader>

          {playerCnt === 5 ? (
            <ScoreTable>
              <ScoreThead>
                <tr>
                  <th>{gameName}</th>
                  <th>1쿼</th>
                  <th>2쿼</th>
                  <th>3쿼</th>
                  <th>4쿼</th>
                  <BoldTh>최종</BoldTh>
                </tr>
              </ScoreThead>
              <ScoreTbody>
                <tr>
                  <td>{homeTeam.teamName}</td>
                  {homeScore.map((score, i) => (
                    <td key={i}>{score}</td>
                  ))}
                  <BoldTd>
                    {homeScore.reduce(
                      (acc, cur) =>
                        parseInt(acc) + (cur === "" ? 0 : parseInt(cur))
                    )}
                  </BoldTd>
                </tr>
                <tr>
                  <td>{awayTeam.teamName}</td>
                  {awayScore.map((score, i) => (
                    <td key={i}>{score}</td>
                  ))}
                  <BoldTd>
                    {awayScore.reduce(
                      (acc, cur) =>
                        parseInt(acc) + (cur === "" ? 0 : parseInt(cur))
                    )}
                  </BoldTd>
                </tr>
              </ScoreTbody>
            </ScoreTable>
          ) : (
            <ThreeGameWrapper>
              <TableWrapper>
                <ScoreTable>
                  <ScoreThead>
                    <tr>
                      <th>{gameName}</th>
                      <th>최종</th>
                      <th>팀파울</th>
                    </tr>
                  </ScoreThead>
                  <ScoreTbody>
                    <tr>
                      <td>{homeTeam.teamName}</td>
                      <td>{homeScore[0]}</td>
                      <td>{homeTeamFoul}</td>
                    </tr>

                    <tr>
                      <td>{awayTeam.teamName}</td>
                      <td>{awayScore[0]}</td>
                      <td>{awayTeamFoul}</td>
                    </tr>
                  </ScoreTbody>
                </ScoreTable>
              </TableWrapper>
              <PlayTime>
                <PlayTimeTitle>플레이 시간</PlayTimeTitle>
                <PlayTimeInputWrap>{playTime}</PlayTimeInputWrap>
              </PlayTime>
            </ThreeGameWrapper>
          )}
        </Section>

        <Section>
          <TeamTitle>{homeTeam.teamName}</TeamTitle>
          {matches ? (
            playerCnt === 5 ? (
              <MobileTable>
                {homeUsers.length > 0 &&
                  homeUsers.map((user) => (
                    <div key={user.id}>
                      <MobileTHeader>
                        <MobileRow>
                          <div>이름</div>
                          <div>포지션</div>
                          <div>신체정보</div>
                          <div>득점</div>
                          <div>자유투</div>
                          <div>리바운드</div>
                        </MobileRow>
                      </MobileTHeader>
                      <MobileColumn>
                        <MobileRow>
                          <EmptyHeader></EmptyHeader>
                          <div>
                            {user.basket.position.split(",").length === 5
                              ? "ALL"
                              : user.basket.position}
                          </div>
                          <div>
                            {user.basket.height}/{user.basket.weight}
                          </div>
                          <div>{user.thisUserStat.score}</div>
                          <div>
                            {user.thisUserStat.freeShot}/
                            {user.thisUserStat.freeShotTotal}
                          </div>
                          <div>{user.thisUserStat.rebound}</div>
                        </MobileRow>
                      </MobileColumn>
                      <MobileTHeader>
                        <MobileRow>
                          <EmptyHeader>{user.username}</EmptyHeader>
                          <div>어시스트</div>
                          <div>블로킹</div>
                          <div>스틸</div>
                          <div>파울</div>
                          <div>턴오버</div>
                        </MobileRow>
                      </MobileTHeader>
                      <MobileColumn>
                        <MobileRow>
                          <EmptyHeader></EmptyHeader>
                          <div>{user.thisUserStat.assist}</div>
                          <div>{user.thisUserStat.blocking}</div>
                          <div>{user.thisUserStat.steal}</div>
                          <div>{user.thisUserStat.foul}</div>
                          <div>{user.thisUserStat.turnOver}</div>
                        </MobileRow>
                      </MobileColumn>
                    </div>
                  ))}
              </MobileTable>
            ) : (
              <MobileTable>
                {homeUsers.length > 0 &&
                  homeUsers.map((user) => (
                    <div key={user.id}>
                      <MobileTHeader>
                        <MobileRow>
                          <div>이름</div>
                          <div>포지션</div>
                          <div>신체정보</div>
                          <div>1점</div>
                          <div>2점</div>
                          <div>자유투</div>
                        </MobileRow>
                      </MobileTHeader>
                      <MobileColumn>
                        <MobileRow>
                          <EmptyHeader></EmptyHeader>
                          <div>
                            {user.basket.position.split(",").length === 5
                              ? "ALL"
                              : user.basket.position}
                          </div>
                          <div>
                            {user.basket.height}/{user.basket.weight}
                          </div>
                          <div>{user.thisUserStat.oneScore}</div>
                          <div>{user.thisUserStat.twoScore}</div>
                          <div>
                            {user.thisUserStat.freeShot}/
                            {user.thisUserStat.freeShotTotal}
                          </div>
                        </MobileRow>
                      </MobileColumn>
                      <MobileTHeader>
                        <MobileRow>
                          <EmptyHeader>{user.username}</EmptyHeader>
                          <div>리바운드</div>
                          <div>어시스트</div>
                          <div>스틸</div>
                          <div>파울</div>
                          <div></div>
                        </MobileRow>
                      </MobileTHeader>
                      <MobileColumn>
                        <MobileRow>
                          <EmptyHeader></EmptyHeader>
                          <div>{user.thisUserStat.rebound}</div>
                          <div>{user.thisUserStat.assist}</div>
                          <div>{user.thisUserStat.steal}</div>
                          <div>{user.thisUserStat.foul}</div>
                          <div></div>
                        </MobileRow>
                      </MobileColumn>
                    </div>
                  ))}
              </MobileTable>
            )
          ) : (
            <MaterialTable
              columns={userColumn}
              data={homeUsers}
              style={{ border: "1px solid #000", boxShadow: "none" }}
              options={{
                toolbar: false,
                search: false,
                actionsColumnIndex: -1,
                paging: false,
                filtering: false,
                sorting: false,
                draggable: false,
                headerStyle: { padding: 5, backgroundColor: "#dddddd" },
              }}
              localization={{
                header: {
                  actions: "",
                },
              }}
            />
          )}

          <TeamTitle>{awayTeam.teamName}</TeamTitle>
          {matches ? (
            playerCnt === 5 ? (
              <MobileTable>
                {awayUsers.length > 0 &&
                  awayUsers.map((user) => (
                    <div key={user.id}>
                      <MobileTHeader>
                        <MobileRow>
                          <div>이름</div>
                          <div>포지션</div>
                          <div>신체정보</div>
                          <div>득점</div>
                          <div>자유투</div>
                          <div>리바운드</div>
                        </MobileRow>
                      </MobileTHeader>

                      <MobileColumn>
                        <MobileRow>
                          <EmptyHeader></EmptyHeader>
                          <div>
                            {user.basket.position.split(",").length === 5
                              ? "ALL"
                              : user.basket.position}
                          </div>
                          <div>
                            {user.basket.height}/{user.basket.weight}
                          </div>
                          <div>{user.thisUserStat.score}</div>
                          <div>
                            {user.thisUserStat.freeShot}/
                            {user.thisUserStat.freeShotTotal}
                          </div>
                          <div>{user.thisUserStat.rebound}</div>
                        </MobileRow>
                      </MobileColumn>
                      <MobileTHeader>
                        <MobileRow>
                          <EmptyHeader>{user.username}</EmptyHeader>
                          <div>어시스트</div>
                          <div>블로킹</div>
                          <div>스틸</div>
                          <div>파울</div>
                          <div>턴오버</div>
                        </MobileRow>
                      </MobileTHeader>
                      <MobileColumn>
                        <MobileRow>
                          <EmptyHeader></EmptyHeader>
                          <div>{user.thisUserStat.assist}</div>
                          <div>{user.thisUserStat.blocking}</div>
                          <div>{user.thisUserStat.steal}</div>
                          <div>{user.thisUserStat.foul}</div>
                          <div>{user.thisUserStat.turnOver}</div>
                        </MobileRow>
                      </MobileColumn>
                    </div>
                  ))}
              </MobileTable>
            ) : (
              <MobileTable>
                {awayUsers.length > 0 &&
                  awayUsers.map((user) => (
                    <div key={user.id}>
                      <MobileTHeader>
                        <MobileRow>
                          <div>이름</div>
                          <div>포지션</div>
                          <div>신체정보</div>
                          <div>1점</div>
                          <div>2점</div>
                          <div>자유투</div>
                        </MobileRow>
                      </MobileTHeader>
                      <MobileColumn>
                        <MobileRow>
                          <EmptyHeader></EmptyHeader>
                          <div>
                            {user.basket.position.split(",").length === 5
                              ? "ALL"
                              : user.basket.position}
                          </div>
                          <div>
                            {user.basket.height}/{user.basket.weight}
                          </div>
                          <div>{user.thisUserStat.oneScore}</div>
                          <div>{user.thisUserStat.twoScore}</div>
                          <div>
                            {user.thisUserStat.freeShot}/
                            {user.thisUserStat.freeShotTotal}
                          </div>
                        </MobileRow>
                      </MobileColumn>
                      <MobileTHeader>
                        <MobileRow>
                          <EmptyHeader>{user.username}</EmptyHeader>
                          <div>리바운드</div>
                          <div>어시스트</div>
                          <div>스틸</div>
                          <div>파울</div>
                          <div></div>
                        </MobileRow>
                      </MobileTHeader>
                      <MobileColumn>
                        <MobileRow>
                          <EmptyHeader></EmptyHeader>
                          <div>{user.thisUserStat.rebound}</div>
                          <div>{user.thisUserStat.assist}</div>
                          <div>{user.thisUserStat.steal}</div>
                          <div>{user.thisUserStat.foul}</div>
                          <div></div>
                        </MobileRow>
                      </MobileColumn>
                    </div>
                  ))}
              </MobileTable>
            )
          ) : (
            <MaterialTable
              columns={userColumn}
              data={awayUsers}
              style={{
                border: "1px solid #000",
                boxShadow: "none",
              }}
              options={{
                toolbar: false,
                search: false,
                pageSize: 5,
                actionsColumnIndex: -1,
                paging: false,
                filtering: false,
                sorting: false,
                draggable: false,
                headerStyle: { padding: 5, backgroundColor: "#dddddd" },
              }}
              localization={{
                header: {
                  actions: "",
                },
              }}
            />
          )}
        </Section>
      </Wrapper>
    );
  }
};

const FiveGameColumn = [
  { title: "이름", field: "username", editable: "never" },
  { title: "포지션", field: "basket.position", editable: "never" },
  { title: "신장", field: "basket.height", editable: "never" },
  { title: "체중", field: "basket.weight", editable: "never" },
  {
    title: "득점",
    field: "thisUserStat.score",
    type: "numeric",
    emptyValue: 0,
  },
  {
    title: "리바운드",
    field: "thisUserStat.rebound",
    type: "numeric",
    emptyValue: 0,
  },
  {
    title: "어시스트",
    field: "thisUserStat.assist",
    type: "numeric",
    emptyValue: 0,
  },
  {
    title: "블로킹",
    field: "thisUserStat.blocking",
    type: "numeric",
    emptyValue: 0,
  },
  {
    title: "스틸",
    field: "thisUserStat.steal",
    type: "numeric",
    emptyValue: 0,
  },
  { title: "파울", field: "thisUserStat.foul", type: "numeric", emptyValue: 0 },
  {
    title: "턴오버",
    field: "thisUserStat.turnOver",
    type: "numeric",
    emptyValue: 0,
  },
];

const mobileFiveGameColumn = [
  { title: "이름", field: "username", editable: "never" },
  { title: "포지션", field: "basket.position", editable: "never" },
  {
    title: "득점",
    field: "thisUserStat.score",
    type: "numeric",
    emptyValue: 0,
  },
  {
    title: "자유투",
    field: "thisUserStat.freeShot",
    type: "numeric",
    render: (rowData) => {
      return (
        rowData && (
          <span>
            {rowData.thisUserStat.freeShot}/{rowData.thisUserStat.freeShotTotal}{" "}
          </span>
        )
      );
    },
    emptyValue: 0,
  },
  {
    title: "리바운드",
    field: "thisUserStat.rebound",
    type: "numeric",
    emptyValue: 0,
  },
  {
    title: "어시스트",
    field: "thisUserStat.assist",
    type: "numeric",
    emptyValue: 0,
  },
  {
    title: "블로킹",
    field: "thisUserStat.blocking",
    type: "numeric",
    emptyValue: 0,
  },
  {
    title: "스틸",
    field: "thisUserStat.steal",
    type: "numeric",
    emptyValue: 0,
  },
  { title: "파울", field: "thisUserStat.foul", type: "numeric", emptyValue: 0 },
  {
    title: "턴오버",
    field: "thisUserStat.turnOver",
    type: "numeric",
    emptyValue: 0,
  },
];

const mobileThreeGameColumn = [
  { title: "이름", field: "username", editable: "never" },
  { title: "포지션", field: "basket.position", editable: "never" },
  {
    title: "1점",
    field: "thisUserStat.oneScore",
    type: "numeric",
    emptyValue: 0,
  },
  {
    title: "2점",
    field: "thisUserStat.twoScore",
    type: "numeric",
    emptyValue: 0,
  },
  {
    title: "자유투",
    field: "thisUserStat.freeShot",
    type: "numeric",
    render: (rowData) => {
      return (
        rowData && (
          <span>
            {rowData.thisUserStat.freeShot}/{rowData.thisUserStat.freeShotTotal}{" "}
          </span>
        )
      );
    },
    emptyValue: 0,
  },
  {
    title: "리바운드",
    field: "thisUserStat.rebound",
    type: "numeric",
    emptyValue: 0,
  },
  {
    title: "어시스트",
    field: "thisUserStat.assist",
    type: "numeric",
    emptyValue: 0,
  },
  {
    title: "스틸",
    field: "thisUserStat.steal",
    type: "numeric",
    emptyValue: 0,
  },
  { title: "파울", field: "thisUserStat.foul", type: "numeric", emptyValue: 0 },
];

const ThreeGameColumn = [
  { title: "이름", field: "username", editable: "never" },
  { title: "포지션", field: "basket.position", editable: "never" },
  { title: "신장", field: "basket.height", editable: "never" },
  { title: "체중", field: "basket.weight", editable: "never" },
  {
    title: "1점",
    field: "thisUserStat.oneScore",
    type: "numeric",
    emptyValue: 0,
  },
  {
    title: "2점",
    field: "thisUserStat.twoScore",
    type: "numeric",
    emptyValue: 0,
  },
  {
    title: "자유투",
    field: "thisUserStat.freeShot",
    type: "numeric",
    render: (rowData) => {
      return (
        rowData && (
          <span>
            {rowData.thisUserStat.freeShot}/{rowData.thisUserStat.freeShotTotal}{" "}
          </span>
        )
      );
    },
    emptyValue: 0,
  },
  {
    title: "리바운드",
    field: "thisUserStat.rebound",
    type: "numeric",
    emptyValue: 0,
  },
  {
    title: "어시스트",
    field: "thisUserStat.assist",
    type: "numeric",
    emptyValue: 0,
  },
  {
    title: "스틸",
    field: "thisUserStat.steal",
    type: "numeric",
    emptyValue: 0,
  },
  { title: "파울", field: "thisUserStat.foul", type: "numeric", emptyValue: 0 },
];
