import {gql} from "apollo-boost";

export const SEARCH = gql`
  query search($term:String!) {
    searchTeam(term:$term) {
      id
      logo
      teamName
      level
      isMine
    }
    searchUser(term:$term) {
      id
      avatar
      username
      basket {
        id
        level
        grade
        position
        backNo
      }
      itsMe
    }
  }
`;

export const SEARCH_PLACE = gql`
  query searchPlace($term:String!, $first:Int, $skip:Int) {
    searchPlace(term:$term, first:$first, skip:$skip) {
      id
      placeName
      address
    }
  }
`;

export const SEARCH_TEAM = gql`
  query searchTeam($term:String!, $first:Int, $skip:Int) {
    searchTeam(term:$term, first:$first, skip:$skip) {
      id
      logo
      teamName
      level
      isMine
    }
  }
`;

export const SEARCH_USER = gql`
  query searchUser($term:String!, $first:Int, $skip:Int) {
    searchUser(term:$term, first:$first, skip:$skip) {
      id
      avatar
      username
      basket {
        id
        level
        grade
        position
        backNo
      }
      itsMe
    }
  }
`;
