import React from "react";
import { withRouter } from "react-router-dom";
import { GET_CUP } from "./CupMainQueries";
import CupMainPresenter from "./CupMainPresenter";
import { useQuery } from "react-apollo-hooks";



const CupMainContainer = ({ match: { params },history }) => {
  const cupId = params.cup;

  const { data, loading } = useQuery(GET_CUP, {
    variables: {
      id: cupId.toString()
    }
  });
  const onUpdatePage = () => {
    history.push(`/cup/${cupId}/edit`);
  };
  return (
    <CupMainPresenter
      loading={loading}
      data={data}
      cupId={cupId}
      onUpdatePage={onUpdatePage}
    />
  );
};

export default withRouter(CupMainContainer);
