import { useState } from "react";

export default (defaultValue, validator, price, phone) => {
  const [value, setValue] = useState(defaultValue);

  function removeComma(str) {
    str = String(str);
    return str.replace(/[^\d]+/g, "");
}

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const inputPhoneNumber = (e) => {
    const number = e.target.value.replace(/[^0-9]/g, "");

    let phone = "";

    if(number.length < 4) {
        return number;
    } else if(number.length < 7) {
        phone += number.substr(0, 3);
        phone += "-";
        phone += number.substr(3);
    } else if(number.length < 11) {
        phone += number.substr(0, 3);
        phone += "-";
        phone += number.substr(3, 3);
        phone += "-";
        phone += number.substr(6);
    } else {
        phone += number.substr(0, 3);
        phone += "-";
        phone += number.substr(3, 4);
        phone += "-";
        phone += number.substr(7);
    }
    e.target.value = phone;
  }


  const onChange = e => {
    if(phone)
      inputPhoneNumber(e);

    const {
      target: { value }
    } = e;
    let willUpdate = true;
 
    if (value !== "" && typeof validator === "function") {
      willUpdate = validator(price?removeComma(value):value);
    }

 
    if (willUpdate) {
      setValue(price?numberWithCommas(removeComma(value)):value);
      
      //setValue(value);
    }
  }

  return { value, onChange, setValue };
};
