import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import {
  GET_INVITES,
  ACCEPT_INVITE,
  DECLINE_INVITE,
  NEW_INVITE
} from "./InviteListQueries";
import InviteListPresenter from "./InviteListPresenter";
import { useQuery, useMutation, useSubscription } from "react-apollo-hooks";
import { toast } from "../../Components/Popup";

const InviteListContainer = ({ history }) => {
  const [me, setMe] = useState();
  const [chats, setChats] = useState([]);
  const [invites, setInvites] = useState([]);
  const [checked, setChecked] = useState(invites.map(i => false));
  const [loadMore, setLoadMore] = useState(false);
  const [canMore, setCanMore] = useState(true);
  const { data, loading, refetch, fetchMore } = useQuery(GET_INVITES, {
    //fetchPolicy:"network-only",
    pollInterval:1000
  });

  const [ applying, setApplying ] = useState(false);
  const [refreshing, setRefreshing] = useState();

  const acceptInviteMutation = useMutation(ACCEPT_INVITE);
  const declineInviteMutation = useMutation(DECLINE_INVITE);

  const { data: newInviteData } = useSubscription(NEW_INVITE, {
    variables: {
      id: me ? me.id : ""
    }
  });

  // const { loading:loadingSearch, data: searchData, refetch: refetchUser } = useQuery(SEARCH_USER, {
  //   skip: search.value === "",
  //   variables: {
  //     term: search.value
  //   }
  // });
  // const search = useInput("");
  // const [searchUsers, setSearchUsers] = useState();
  // const [searching, setSearching] = useState(false);

  const handleToggleChecked = index => () => {
    const newChecked = [...checked];
    newChecked[index] = !newChecked[index];
    setChecked(newChecked);
  };

  const handleProfileButton = id => () => {
    history.push(`/team/${id}`);
  };
  const onAcceptInvite = id => async e => {
    try {
      e.preventDefault();
      setApplying(true);

      const newInvites = [...invites];
      newInvites.splice(
        newInvites.findIndex(i => i.id === id),
        1
      );
      setInvites(newInvites);

      const {
        data: { acceptInvite }
      } = await acceptInviteMutation({
        variables: {
          id
        }
      });
      refetch();



      if (acceptInvite) toast.success("초대를 수락했습니다.");
      else toast.error("오류가 발생했습니다.");
    } catch (e) {
      console.log(e);
      console.log(e.message);
      if (e.message.indexOf("이미") !== -1) {
        toast.error("이미 가입된 팀이 있습니다.");
      } else if(e.message.indexOf("유효한") !== -1) {
        toast.error("유효한 초대장이 아닙니다.");
      } else {
        toast.error("오류가 발생했습니다.");
      }
    } finally {
      setApplying(false);
    }
  };
  const onDeclineInvite = id => async e => {
    try {
      setApplying(true);

      const newInvites = [...invites];
      newInvites.splice(
        newInvites.findIndex(i => i.id === id),
        1
      );
      setInvites(newInvites);

      e.preventDefault();
      const {
        data: { declineInvite }
      } = await declineInviteMutation({
        variables: {
          id
        }
      });

      refetch();
      console.log(declineInvite);



      if (declineInvite) toast.info("초대를 거부했습니다.");
      else toast.error("오류가 발생했습니다.");
    } catch (e) {
      console.log(e);
      if (e.message.indexOf("found") !== -1) {
        toast.error("유효한 초대장이 아닙니다.");
      } else {
        toast.error("오류가 발생했습니다.");
      }
    } finally {
      setApplying(false);
    }
  };

  // useEffect(() => {
  //   if(search.value.length > 1) {
  //     setSearching(true);
  //   } else {
  //     setSearching(false);
  //   }

  //   // if(!loadingSearch && searchData && searchData.searchUser) {
  //   //   setSearchUsers(searchData.searchUser);
  //   //   setSearching(false);
  //   // }
  // }, [search.value]);

  const onRefresh = () => {
    //console.log(currentTarget);
    setRefreshing(true);
    fetchMore({
      variables: {
        skip: 0,
        first:invites.length
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        setRefreshing(false);
        if (!fetchMoreResult) return prev;

        setInvites([...fetchMoreResult.seeInvites])

        return Object.assign({}, prev, {
          seeInvites: [...fetchMoreResult.seeInvites]
        });
      }
    });
  };

  const onScroll = ({ currentTarget }) => {
    //console.log(currentTarget);
    if (
      canMore &&
      !loadMore &&
      currentTarget.scrollTop + currentTarget.clientHeight >=
        currentTarget.scrollHeight
    ) {
      setLoadMore(true);
      fetchMore({
        variables: {
          skip: data.seeInvites.length
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          setLoadMore(false);

          if (!fetchMoreResult) return prev;

          if (fetchMoreResult.seeInvites.length === 0) {
            setCanMore(false);
          } else {
            const mergeData = Array.from(new Set([...prev.seeInvites, ...fetchMoreResult.seeInvites]));
            setInvites(mergeData)
          }

          return Object.assign({}, prev, {
            seeInvites: [
              ...prev.seeInvites,
              ...fetchMoreResult.seeInvites
            ]
          });
        }
      });
    }
  };

  return (
    <InviteListPresenter
      loading={loading}
      data={data}
      chats={chats}
      setChats={setChats}
      invites={invites}
      setInvites={setInvites}
      setMe={setMe}
      applying={applying}
      // searchUsers={searchUsers}
      // search={search}
      // searching={searching}
      newInviteData={newInviteData}
      checked={checked}
      setChecked={setChecked}
      handleToggleChecked={handleToggleChecked}
      handleProfileButton={handleProfileButton}
      onAcceptInvite={onAcceptInvite}
      onDeclineInvite={onDeclineInvite}
      history={history}

      onRefresh={onRefresh}
      refreshing={refreshing}
      setRefreshing={setRefreshing}
      loadMore={loadMore}
      canMore={canMore}
      onScroll={onScroll}
    />
  );
};

export default withRouter(InviteListContainer);
