import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { SEND_MATCH } from "./GameRequestQueries";
import GameRequestPresender from "./GameRequestPresender";
import { useMutation } from "react-apollo-hooks";
import { toast } from "../../Components/Popup";

import useInput from "../../Hooks/useInput";


const GameRequestContainer = ({ teamName, bio, gameId, oppTeamId, onBack, handleSendMatch }) => {
  const [uploading, setUploading] = useState(false);
  const [isSendMatch, setIsSendMatch] = useState(false);
  // For Game Info
  /*=================================================== */
  const title = useInput(teamName?`저희 ${teamName}팀이랑 경기하실래요?`:"");
  const message = useInput(bio? bio :"");
  const sendMatchMutation = useMutation(SEND_MATCH);
  /*=================================================== */


  const onSendMatch = async () => {
    try {
      if (title.value === "") {
        toast.error("제목을 입력해주세요.");
        return;
      }

      if (message.value === "") {
        toast.error("신청 내용을 입력해주세요.");
        return;
      }

      setUploading(true);
      
      setIsSendMatch(true);

      const {
        data: { sendMatch }
      } = await sendMatchMutation({
        variables: {
          id: gameId,
          oppTeamId,
          title:title.value,
          message:message.value
        }
      });

      console.log(sendMatch);

      if (sendMatch) {
        handleSendMatch();
        toast.success("매칭을 요청하였습니다.");
        onBack();
      } else {
        toast.error("모집 중인 경기가 아닙니다.");
        setIsSendMatch(false);
      }
    } catch (e) {
      
      if (e.message.indexOf("MATCH") !== -1) {
        toast.error("이미 다른 팀과 매칭되었습니다.");
      } else if (e.message.indexOf("CANCEL") !== -1) {
        toast.error("해당 경기가 취소되었습니다.");
      } else if (e.message.indexOf("ALREADY") !== -1) {
        toast.error("이미 신청하셨습니다.");
      } else {
        toast.error("오류가 발생했습니다.");
        console.log(e);
        setIsSendMatch(false);
      }
    } finally {
      setUploading(false);
    }
  };

  return (
    <GameRequestPresender
      uploading={uploading}
      isSendMatch={isSendMatch}
      title={title}
      message={message}
      onSendMatch={onSendMatch}
      onBack={onBack}
    />
  );
};

export default withRouter(GameRequestContainer);
