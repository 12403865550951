import React from 'react';
import { MessageThin, Share, Plus } from "./Icons";
import styled from "styled-components";

const FabButton = styled.button`
    border: 0;
    cursor: pointer;
    margin: 0;
    display: inline-flex;
    outline: 0;
    align-items: center;
    user-select: none;
    border-radius: 0;
    vertical-align: middle;
    justify-content: center;
    text-decoration: none;
    -webkit-tap-highlight-color: transparent;

    z-index:99;
    color: rgba(0, 0, 0, 0.87);
    width: ${props=>props.width};
    height: ${props=>props.width};
    padding: 0;
    font-size: 0.875rem;
    min-width: 0;
    box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12);
    box-sizing: border-box;
    min-height: 36px;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
    line-height: 1.75;
    border-radius: 50%;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
    background-color: #ffbe21;
    position:absolute;
    top:${props=>props.top};
    right:${props=>props.right};
    &:active {
      box-shadow: 0px 7px 8px -4px rgba(0,0,0,0.2), 0px 12px 17px 2px rgba(0,0,0,0.14), 0px 5px 22px 4px rgba(0,0,0,0.12);
    }
`;

const TextSpan = styled.span`
  font-family:"NanumSqure";
  font-weight:600;
  line-height: 1.1em;
  font-size: 13px;
`;

const MVPText = styled.span`
  font-weight: 600;
`;

export default function FloatingActionButtons({ type, top, right, width="65px", onClick }) {

  return (
      <FabButton top={top} right={right} width={width} onClick={onClick}>
        {type === "message" && <MessageThin fill={"#000"} />}
        {type === "teamUser" && <TextSpan>신청/초대 관리</TextSpan>}
        {type === "invite" && <Plus fill={"#000"}/>}
        {type === "inviteText" && <><Plus fill={"#000"} size={12}/>초대</>}
        {type === "share" && <Share fill={"#000"} />}
        {type === "mvp" && <MVPText>☆MVP</MVPText>}
      </FabButton>
  );
}