import React, { useEffect } from "react";
import styled from "styled-components";
import { Helmet } from "rl-react-helmet";
import Loader from "../../Components/Loader";
import CupHeader from "../../Components/CupHeader";
import Avatar from "../../Components/Avatar";
import { Divider } from "@material-ui/core";
import { Link } from "react-router-dom";



const Wrapper = styled.div`
  min-height: calc(100vh - 76px);
`;

const ContentWrapper = styled.div`
  color: #000;
`;

const HistoryWrap = styled.ul`
  max-width: 924px;
  margin: 0 auto;
  width: 95%;
  padding: 50px 50px 30px;
  background-color: #fff;
  min-height: calc(100vh - 360px);
  @media (max-width: 600px) {
    padding: 50px 10px 30px;
  }
`;

const ListTitle = styled.div`
  background-color:#25374d;
  color:#fff;
  font-size:25px;
  padding:10px 20px;
  width:100%;
  margin-bottom:20px;
`;

const HistoryList = styled.li`
  list-style: none;
  width: 100%;
  background-color: #fff;
  border-bottom: 1px solid #000;
  padding: 15px 15px;
  cursor: pointer;
  overflow: auto;
  display: flex;
  align-self:center;

`;

const TeamName = styled.span`
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  position: relative;
  display: flex;
    align-items: center;
`;

const TeamInfoWrap = styled.div`
  margin-left:15px;
`;

const TeamInfo = styled.span`
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  display: flex;
    align-items: center;
`;

const AllGrade = styled(TeamInfo)`
  font-size:14px;
  font-weight:500;
  & > .MuiDivider-vertical {
    height: 15px!important;
    margin: 0px 10px!important;
    background-color: rgba(0, 0, 0, 0.92)!important;
  }
`

export default function MaterialTableDemo({
  loading,
  cupId,
  data,
  teams,
  setTeams,
}) {
  if (loading) {
    return (
      <Wrapper>
        <Helmet>
          <title>팀관리 | WePlay</title>
        </Helmet>
        <ContentWrapper>
          <CupHeader id={cupId} active={"teams"} />
        </ContentWrapper>
        <Loader />
      </Wrapper>
    );
  } else if (!loading && data && data.seeCupTeams) {
    useEffect(() => {
      setTeams(data.seeCupTeams);
    }, []);

    return (
      <Wrapper>
        <Helmet>
          <title>출전팀 | WePlay</title>
        </Helmet>
        <ContentWrapper>
          <CupHeader id={cupId} active={"teams"} />
          <HistoryWrap>
            <ListTitle>출전팀 리스트</ListTitle>

            {teams && teams.length > 0 && teams.map(team => (
              <Link key={team.id} to={`/cup/${cupId}/team/${team.id}`}>
                <HistoryList >
                  <Avatar url={team.logo} size={"sm"} />
                  <TeamInfoWrap>
                    <TeamName>
                      {team.teamName}
                    </TeamName>  
                    <AllGrade>
                      {team.host && (  
                        <> 
                        {team.host.username}  
                        <Divider orientation="vertical" /> 
                        </>
                      )}
                      {!team.host && team.agent && (  
                        <> 
                        {team.agent}  
                        <Divider orientation="vertical" /> 
                        </>
                      )}
                      {team.win}승 {team.draw}무 {team.lose}패
                    </AllGrade>   
                  </TeamInfoWrap>
                    
                    {/* <Divider orientation="vertical" />
                    <TeamInfo>
                      {"경기도 성남시"}
                    </TeamInfo> */}
                  
                </HistoryList>
              </Link>
              
            ))}
          </HistoryWrap>
        </ContentWrapper>
      </Wrapper>
    );
  } 
}
