import React from "react";
import styled, { keyframes } from "styled-components";

const Dot1Move = keyframes`
  20% {transform: scale(1)}
  45% {transform: translate(16px, 12px) scale(.45)}
  60% {transform: translate(80px, 60px) scale(.45)}
  80% {transform: translate(80px, 60px) scale(.45)}
  100% {transform: translateY(0px) scale(1)}
`;

const Dot2Move = keyframes`
  20% {transform: scale(1)}
  45% {transform: translate(-16px, 12px) scale(.45)}
  60% {transform: translate(-80px, 60px) scale(.45)}
  80% {transform: translate(-80px, 60px) scale(.45)}
  100% {transform: translateY(0px) scale(1)}
`;

const Dot3Move = keyframes`
  20% {transform: scale(1)}
  45% {transform: translateY(-18px) scale(.45)}
  60% {transform: translateY(-90px) scale(.45)}
  80% {transform: translateY(-90px) scale(.45)}
  100% {transform: translateY(0px) scale(1)}
`;

const RotateMove = keyframes`
  55% {transform: translate(-50%, -50%) rotate(0deg)}
  80% {transform: translate(-50%, -50%) rotate(360deg)}
  100% {transform: translate(-50%, -50%) rotate(360deg)}
`;

const Index = keyframes`
  0%, 100% {z-index: 3}
  33.3% {z-index: 2}
  66.6% {z-index: 1}
`;

const TextAnimate = keyframes`
  0% {
    background-position: -500%;
  }
  100% {
    background-position: 500%;
  }
`;
const Container = styled.div`
  width: 200px;
  height: 200px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: auto;
  /* filter: url('#goo'); */
  -webkit-animation: ${RotateMove} 2s ease-in-out infinite;
  -moz-animation:${RotateMove} 2s ease-in-out infinite;
  animation: ${RotateMove} 2s ease-in-out infinite;
`;

const Dot = styled.div`
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-color: #000;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  &:first-child {
    background-color: #1ea9e1;
    -webkit-animation:${Dot1Move} 2s ease infinite, ${Index} 6s -2s ease infinite;
    -moz-animation:${Dot1Move} 2s ease infinite, ${Index} 6s -2s ease infinite;
    animation: ${Dot1Move} 2s ease infinite, ${Index} 6s -2s ease infinite;

  }
  &:nth-child(2) {
    background-color: #a6ce39;
    -webkit-animation:${Dot2Move} 2s ease infinite, ${Index} 6s -4s ease infinite;
    -moz-animation:${Dot2Move} 2s ease infinite, ${Index} 6s -4s ease infinite;
    animation: ${Dot2Move} 2s ease infinite, ${Index} 6s -4s ease infinite;

  }
  &:last-child {
    background-color: #4c4d4f;
    -webkit-animation:${Dot3Move} 2s ease infinite, ${Index} 6s ease infinite;
    -moz-animation:${Dot3Move} 2s ease infinite, ${Index} 6s ease infinite;
    animation: ${Dot3Move} 2s ease infinite, ${Index} 6s ease infinite;

  }
`;

const Progress = styled.div`
  position: fixed;
  z-index: 9999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  &::before {
    content: "";
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background:${props=>props.background?`radial-gradient(rgba(20, 20, 20, 0.6), rgba(0, 0, 0, 0.6))`:`unset`}
    /* background: radial-gradient(rgba(20, 20, 20, 0.6), rgba(0, 0, 0, 0.6)); */
  }
`;

const LoaderText = styled.div`
  width: 500px;
  color: #fff;
  font-size: 16px;
  font-weight:600;
  position: absolute;
  text-align: center;
  left: 50%;
  transform: translateX(-50%);
  background: linear-gradient(90deg, #fff, #fff, #fff);
  background-repeat: no-repeat;
  letter-spacing: 4px;
  overflow: hidden;
  background-size: 80%;
  animation: ${TextAnimate} 6s linear infinite;
  -webkit-background-clip: text;
  -webkit-text-fill-color: rgba(255, 255, 255, 0);
`;

export default ({background = true, text = null}) => (
  <Progress background={background}>
    <Container>
      <Dot></Dot>
      <Dot></Dot>
      <Dot></Dot>
    </Container>
    {text && <LoaderText>{text}</LoaderText>}
  </Progress>
  
);