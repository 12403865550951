import React, { useEffect } from "react";
import styled from "styled-components";
import { Helmet } from "rl-react-helmet";

import { Minus, Close } from "../../Components/Icons";
import BoldText from "../../Components/BoldText";
import Loader from "../../Components/Loader";
import CupUserForm from "../../Components/CupUserForm";
import MyButton from "../../Components/Button";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  CircularProgress,
  Slide
} from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import RoundCheckBox from "../../Components/RoundCheckBox";
import Avatar from "../../Components/Avatar";
import { makeStyles } from "@material-ui/core/styles";


const Wrapper = styled.div``;

const ContentWrapper = styled.div`
  color: #000;
`;

const HistoryWrap = styled.ul`
  max-width: 924px;
  margin: 0 auto;
  width: 95%;
  padding: 50px 30px 30px;
  position:relative;
  background-color: #fff;
  @media (max-width: 500px) {
    padding: 50px 10px 30px;
  }
`;

const ListTitle = styled.div`
  background-color: #25374d;
  color: #fff;
  font-size: 25px;
  padding: 10px 20px;
  width: 100%;
  margin-bottom: 20px;
`;

const TopMenu = styled.div`
  background-image: url(/imgs/weplay_event_bg_korea33winterleague.jpg);
  height: 360px;
  background-size: cover;
  position: relative;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (max-width: 600px) {
    height: 280px;
  }
`;

const CupTitle = styled.h4`
  color: #fff;
  font-size: 35px;
  font-weight: 700;
  text-align: center;
  word-break: keep-all;
`;

const InputContainer = styled.div`
  flex-grow: 1;
  margin: 0px 10px;
  background-color: #fff;
  color: #000;
  margin: 0 auto;
  max-width: 500px;
  @media (max-width: 500px) {
  }
`;

const UserInfoRow = styled.div`
  font-size: 16px;
  padding: 10px 5px;
  &:last-child {
    padding-top: 0px;
  }
`;

const InputInfoColumn = styled.div`
  &:first-child {
    margin-bottom: 7px;
  }
  &:nth-child(2) {
    display: flex;
    flex-direction: column;
  }

  position:relative;
`;

const ProfileColumn = styled.div`
  width: auto;
  white-space: nowrap;
  overflow-x: auto;
`;

const LogoUploadWapper = styled.div`
  position: relative;
  overflow: hidden;
  display: inline-block;
  width: 110px;
  height: 110px;
  & > input[type="file"] {
    position: absolute;
    height: 100%;
    left: 0;
    top: 0;
    opacity: 0;
  }
`;

const ProfileUploadWapper = styled(LogoUploadWapper)`
  width: 195px;
  height: 110px;
  margin-right: 15px;
`;

const RemoveBtn = styled.button`
  border: none;
  background-color: #fff;
  padding: 0px;
  border-radius: 50%;
  font-size: 25px;
  font-weight: bold;
  width: 30px;
  height: 30px;
  position: absolute;
  right: 0;
  bottom: 0;
  cursor: pointer;
`;

const RemoveUserBtn = styled(RemoveBtn)`
  top: 3px;
  right: 5px;
`;

const PreviewImg = styled.img`
  border: 1px solid #000;
  background-color: white;
  border-radius: 8px;
  width: 100%;
  height: 100%;
`;

const ProfileHint = styled.span`
  font-size: 14px;
  padding-left: 10px;
`;

const UploadBtn = styled.button`
  border: 1px solid #000;
  color: #000;
  background-color: white;
  padding: 5px;
  border-radius: 8px;
  font-size: 15px;
  width: 100%;
  height: 100%;
  cursor: pointer;
`;

const BioTextarea = styled.textarea`
  width: 100%;
  max-width: 500px;
  padding: 5px 15px;
  background-color: #ebebeb;
  border: ${props => props.theme.boxBorder};
  border-radius: 10px;
  color: #000;
  resize: none;
  font-size: 16px;
  font-family: "NanumSqure";
  &:focus {
    background-color: #fff;
    border: 1px solid #2b2b2b;
  }
`;

const AgreeContainer = styled.div``;

const AgreeBox = styled.div`
  width: 100%;
  max-width: 800px;
  border: 1px solid #000;
  min-height: 300px;
  padding: 15px;
  font-size: 14px;
  margin: 50px auto 20px;
  line-height: 1.9em;
  & > p {
    font-size:18px;
    font-weight:600;
  }
  & > div {
    font-weight:500;
  }
`;

const UserAddButton = styled.div`
  width: 100%;
  cursor: pointer;
  border: 1px solid #000;
  padding: 15px;
  font-size: 16px;
  text-align: center;
`;

const Input = styled.input`
  width: 100%;
  height: 40px;
  max-width: 500px;
  padding: 5px 15px;
  font-size: 14px;
  color: #000;
  background-color: #ebebeb;
  border: ${props => props.theme.boxBorder};
  border-radius: 10px;
  &:active,
  &:focus {
    background-color: #fff;
    border: 1px solid #2b2b2b;
  }
`;
const RequestButton = styled.button`
  background-color: #5e80ec;
  border: 1px solid #5e80ec;
  cursor: pointer;
  font-size: 20px;
  padding: 15px 0px;
  text-align: center;
  font-weight: 500;
  font-family: "NanumSqure";
  color: #fff;
  border-radius: 20px;
  position: relative;
  bottom: 10px;
  z-index: 997;
  max-width: 430px;
  margin-top: 20px;
  /* width: 95%; */
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
  & > span {
    font-family: "Jalnan";
  }
`;

const SearchWrap = styled.div`
  padding:10px 0px;
  & > button {
    margin-right:10px;
  }
`;

const CloseBtn = styled.span`
  position:absolute;
  top:-340px;
  right:15px;
  cursor: pointer;
  @media (max-width: 600px) {
    top: -270px;
  }
`; 

const SelectButton = styled.button`
  width: 50%;
  background-color: ${props => props.theme.yellowColor};
  border: 1px solid ${props => props.theme.yellowColor};
  cursor: "pointer";
  font-size: 16px;
  padding: 10px 0px;
  font-weight: 500;
  font-family: "NanumSqure";
`;

const Title = styled.div`
  font-size:20px;
  margin-bottom:20px;
  font-weight: 900;
  text-align: center;
`;

const Content = styled.div`
  font-size:18px;
  text-align: center;
  font-weight: 500;
  padding-top: 10px;
  line-height: 1.3em;
  word-break: keep-all;
`;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  dialog: {
    "& .MuiDialog-paper": {
      margin: "10px!important",
      minWidth:350,
    },
    "& .MuiDialogContent-dividers": {
      minHeight: 150
    }
  }
}));

export default ({
  data,
  loading,
  handleSubmit,
  IsAgree,
  isAppInfo,
  IsHost,
  setIsHost,
  setTeamId,

  handleToggleAppInfo,
  handleAppInfo,
  handleAddUser,
  handleRemoveUser,
  users,
  setUsers,
  onClickClose,
  handleLogoChange,
  handleProfileChange,

  teamNameInput,
  agentNameInput,
  contactInput,
  bioInput,
  logo,
  profiles,
  onRemoveProfile,
  uploading,

  handleToggle,
  setCupPlayerCnt,
  members,
  setMembers,
  selectedMember,
  openDialog,
  handleDialogOpen,
  handleDialogClose,
}) => {
  if (loading) {
    return (
      <Wrapper>
        <Helmet>
          <title>대회 정보 | WePlay</title>
        </Helmet>
        <Loader />
        <TopMenu>
          <CupTitle></CupTitle>
        </TopMenu>
      </Wrapper>
    );
  } else if (!loading && data && data.seeCup) {
    const classes = useStyles();
    const { cupName, playerCnt } = data.seeCup;

    //console.log(data);

    useEffect(() => {
      setCupPlayerCnt(playerCnt);
      if(data.me && data.me.myHostTeam) {
        setIsHost(true);
        setMembers([data.me.myHostTeam.host, ...data.me.myHostTeam.managers, ...data.me.myHostTeam.users]);
      }
    }, []);

    useEffect(() => {

      if(isAppInfo) {
        setTeamId(data.me.myHostTeam.id);
        teamNameInput.setValue(data.me.myHostTeam.teamName);
        bioInput.setValue(data.me.myHostTeam.bio);
        contactInput.setValue(data.me.myHostTeam.contact);
      } else {
        setTeamId(null);
        teamNameInput.setValue("");
        bioInput.setValue("");
        contactInput.setValue("");
      }
    }, [isAppInfo])
    // console.log(users);


    return (
      <Wrapper>
        <Helmet>
          <title>대회 신청 | WePlay</title>
        </Helmet>
        {uploading && <Loader />}
        <TopMenu>
          <CupTitle>{cupName}</CupTitle>
        </TopMenu>
        <ContentWrapper>
          <HistoryWrap>
            <CloseBtn onClick={onClickClose}>
              <Close fill={"#fff"} size={30} />
            </CloseBtn>
            <ListTitle>대회 신청서 작성</ListTitle>
            {IsAgree ? (
              <InputContainer>
                {!isAppInfo && (
                  <UserInfoRow>
                    <InputInfoColumn>
                      <BoldText text={"팀명"} />
                      <ProfileHint>*</ProfileHint>
                    </InputInfoColumn>
                    <InputInfoColumn>
                      <Input placeholder={""} {...teamNameInput} />
                    </InputInfoColumn>
                  </UserInfoRow>
                )}

                {playerCnt === 5 && (
                  <UserInfoRow>
                    <InputInfoColumn>
                      <BoldText text={"대표자명(인솔자)"} />
                    </InputInfoColumn>
                    <InputInfoColumn>
                      <Input placeholder={""} {...agentNameInput} />
                    </InputInfoColumn>
                  </UserInfoRow>
                )}

                {!isAppInfo && (
                  <UserInfoRow>
                    <InputInfoColumn>
                      <BoldText text={"대표 연락처"} />
                      <ProfileHint>*</ProfileHint>
                    </InputInfoColumn>
                    <InputInfoColumn>
                      <Input placeholder={""} {...contactInput} />
                    </InputInfoColumn>
                  </UserInfoRow>
                )}

                {!isAppInfo && (
                  <UserInfoRow>
                    <InputInfoColumn>
                      <BoldText text={"로고"} />
                    </InputInfoColumn>
                    <InputInfoColumn>
                      <LogoUploadWapper>
                        {logo ? (
                          <PreviewImg src={logo.url} />
                        ) : (
                          <UploadBtn>+ 등록하기</UploadBtn>
                        )}
                        <Input
                          onChange={handleLogoChange}
                          placeholder={""}
                          type={"file"}
                          name={"logo"}
                        />
                      </LogoUploadWapper>
                    </InputInfoColumn>
                  </UserInfoRow>
                )}

                {!isAppInfo && (
                  <UserInfoRow>
                    <InputInfoColumn>
                      <BoldText text={"팀 프로필 사진"} />
                      <ProfileHint>*최대 5장</ProfileHint>
                    </InputInfoColumn>
                    <ProfileColumn>
                      {profiles &&
                        profiles.length > 0 &&
                        profiles.map((profile) => (
                          <ProfileUploadWapper key={profile.name}>
                            <PreviewImg key={profile.name} src={profile.url} />
                            <RemoveBtn onClick={onRemoveProfile(profile)}>
                              <Minus size={30} />
                            </RemoveBtn>
                          </ProfileUploadWapper>
                        ))}
                      {profiles.length < 5 && (
                        <ProfileUploadWapper>
                          <UploadBtn>+ 등록하기</UploadBtn>
                          <Input
                            onChange={handleProfileChange}
                            placeholder={""}
                            type={"file"}
                            name={"profile"}
                          />
                        </ProfileUploadWapper>
                      )}
                    </ProfileColumn>
                  </UserInfoRow>
                )}

                {!isAppInfo && (
                  <UserInfoRow>
                    <InputInfoColumn>
                      <BoldText text={"팀 소개"} />
                      <ProfileHint>*</ProfileHint>
                    </InputInfoColumn>
                    <InputInfoColumn>
                      <BioTextarea
                        {...bioInput}
                        cols="4"
                        rows="5"
                      ></BioTextarea>
                    </InputInfoColumn>
                  </UserInfoRow>
                )}

                <UserInfoRow>
                  <InputInfoColumn>
                    <BoldText text={`팀 로스터(${playerCnt}:${playerCnt})`} />
                    <ProfileHint>
                      {playerCnt === 3 ? "*최대 5명" : "*최대 12명"}
                    </ProfileHint>
                  </InputInfoColumn>
                  <SearchWrap>
                    
                    {IsHost && (
                      isAppInfo ? (
                        <>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={handleDialogOpen("search")}
                          >
                            회원 검색
                          </Button>
                          <Button variant="contained" onClick={handleToggleAppInfo}>
                            새로운 팀 생성
                          </Button>
                        </>
                      ) : (
                        <Button variant="contained" onClick={handleToggleAppInfo}>
                          기존 팀으로 등록
                        </Button>
                      )
                    )}
                  </SearchWrap>

                  {users.length > 0 &&
                    users.map((user, key) => (
                      <InputInfoColumn key={key}>
                        <CupUserForm
                          disabled={user.id ? true : false}
                          user={user}
                          users={users}
                          index={key}
                          setUsers={setUsers}
                        />
                        <RemoveUserBtn onClick={handleRemoveUser(key)}>
                          <Minus size={30} />
                        </RemoveUserBtn>
                      </InputInfoColumn>
                    ))}
                </UserInfoRow>

                {!isAppInfo && (
                  <UserInfoRow>
                    <UserAddButton onClick={handleAddUser(null)}>
                      + 선수 추가
                    </UserAddButton>
                  </UserInfoRow>
                )}

                <form>
                  <RequestButton onClick={handleSubmit}>신청하기</RequestButton>
                </form>
              </InputContainer>
            ) : (
              <AgreeContainer>
                <AgreeBox>
                  <p>필수 확인사항</p>
                  -참가자는 반드시 본인이 신청한 대회를 참가할 수 있는 건강
                  상태인지를 확인해야 합니다.
                  <br />
                  -대회 도중 신체적 이상이 생길 경우, 즉시 경기를 중단하고
                  의료팀 및 대회운영요원에게 도움을 요청하시기 바랍니다.
                  <br />
                  -대회 도중 본인의 건강상의 이유로 발생한 부상, 사고 등에 대해
                  주최측은 응급조치 외에 어떠한 책임도 지지 않습니다.
                  <br />
                  추가적으로 보상하는 것은 없습니다.
                  <br />
                  -대회 중이라 함은(대회에 참가하여 대회를 마치고 해산하기
                  전까지)를 말합니다.
                  <br />
                  -상해란 급격하고도 우연한 외래의 충격으로 정의되며
                  외부로부터의 충격에 의해 신체가 상하는 경우입니다.
                  <br />
                  -질병이란 몸 내부에서 원인이 된 것으로 마라톤 경기 중에는
                  특별히 심근경색, 뇌출혈, 호흡곤란, 구토, 근육통 등이 빈번히
                  발생되는 질병사고라 볼 수 있습니다.
                  <br />
                  -염좌, 골절, 자상 등을 제외한 거의 대부분의 사고가 질병이라 볼
                  수 있습니다.
                  <br />
                  -상해 후유장해라 함은 대회 중에 급격하고도 우연한 외래의
                  사고(상해)로 인해 치유된 후에도 신체에 남아있는 영구적인
                  훼손상태를 말하며 장해율에 따라 지급됩니다. 반드시 상해와
                  관련된 후유장해만 해당되며 기존에 앓고 있던 질병이나 장해와
                  관련되어서는 지급되지 않습니다.
                  <br />
                  -대회를 마친 뒤에 과도한 음주 등으로 인한 사망사고는
                  사고조사가 있을 수 있습니다. 대회와 무관한 내용에 대해서는
                  보상되지 않습니다. 때문에 대회 직후 음주 등 운동 후 신체회복에
                  어려움을 주는 행위는 피해야 합니다.
                  <br />
                  -현재 금융감독원의 규정에 의해 치료비를 적용하지 못합니다.
                  (보험업감독규정 제7-50조 2항 2목)
                  <br />
                  -위의 이유로 인하여 주최측에서는 상해치료비 및 질병치료비의
                  지급이 불가하오니 의료비 부분은 참가자 개별적으로 가입하셔야
                  함을 안내 드립니다.
                  <br />
                  <br />
                  <div>
                    본인은 상기사항을 충분히 숙지하여, 경기규칙을 엄수하고
                    진행원 및 심판의 지시에 순응하며 경기 중에 발생한 본인의
                    신체상의 사고에 대해서는 일체 본인이 그 책임을 질것에
                    동의합니다.
                    <br />
                    <br />
                    * 대회요강의 모든 사항을 상세히 읽었으며,대회 당일 본인의
                    신체상에 어떠한 사고가 발생해도 모든 책임은 본인에게 있으며
                    민형사상 책임을 묻지 않음을 동의합니다.
                    <br />
                    <br />
                    * 과거 또는 현재에 심장, 뇌,혈관계 질환(뇌졸중, 뇌경색),
                    폐질환, 고혈압, 운동성고혈압, 고혈압전단계, 고지혈증,
                    당뇨전단계, 당뇨병 등이 없으며, 만약에 접수 후 경기 전에
                    관련질환에 노출되어 치료를 필요로 할 경우 질환의 경중을
                    막론하고 대회에 스스로 참여하지 않을 것을 약속합니다.
                    <br />
                    <br />
                    * 과로, 야근, 장기 여행을 하거나 참가자 본인의 건강상태가
                    좋지 않은 경우 대회 참가하지 않겠습니다. 또한 대회 도중
                    심신에 이상을 느낄 경우 즉각 중단하고 근처의 운영요원 등에게
                    도움을 요청하고 구급차나 회송차를 이용하겠습니다.
                    <br />
                    * 접수 마감후 불참 시 환불은 되지 않습니다. 따라서 참가비를
                    입금하시기 전에 출전여부를 신중히 검토한 후 입금할 것이며
                    입금후 어떠한 경우라도 환불에 대한 이의를 제기하지 않을
                    것입니다.
                    <br />
                    <br />
                    * 접수 후 접수기간 내에 참가비를 납부하지 않으면 자동
                    취소됨을 확인합니다.
                    <br />
                    <br />
                    * 신청자와 입금자가 다를 경우, 또는 한 명이 여러 명의
                    참가비를 일괄 납부하는 경우에 입금한 후 반드시 대회 사무국에
                    전화를 통해 통지하겠습니다.
                    <br />
                    <br />
                    * 천재지변으로 인하여 대회의 전부 혹은 일부가 진행될 수 없을
                    경우에도 이의를 제기하지 않겠습니다.
                    <br />
                    <br />
                    * 단체 신청시 단체를 대신해서 신청을 하신 분은 소속 단체
                    구성원 전원에게 참가신청 동의서의 모든 내용을 반드시
                    전달하고 동의를 받아야 합니다.
                    <br />
                    <br />
                    * 마감이후에는 종목변경, 기념품 변경, 취소 등을 어떠한
                    방법이든 요청하지 않겠습니다.
                    <br />
                    <br />
                  </div>
                  ※ 본인은 상기 모든 동의 사항에 동의합니다.
                </AgreeBox>
                <RequestButton onClick={handleDialogOpen("select")}>
                  동의합니다
                </RequestButton>
              </AgreeContainer>
            )}
          </HistoryWrap>
        </ContentWrapper>

        <Dialog
          className={classes.dialog}
          open={openDialog.search}
          onClose={handleDialogClose("search")}
          TransitionComponent={Transition}
        >
          <DialogTitle id="form-dialog-title">팀원 선택</DialogTitle>
          <DialogContent dividers>
            <List>
              {!members && <CircularProgress />}
              {members &&
                members.length > 0 &&
                members.map((member) => (
                  <ListItem
                    key={member.id}
                    button={users.findIndex((u) => u.id === member.id) === -1}
                    onClick={
                      users.findIndex((u) => u.id === member.id) === -1
                        ? handleToggle(member)
                        : null
                    }
                  >
                    <ListItemAvatar>
                      <Avatar url={member.avatar} />
                    </ListItemAvatar>
                    <ListItemText id={member.id} primary={member.username} />
                    <ListItemSecondaryAction onClick={handleToggle(member)}>
                      {users.findIndex((u) => u.id === member.id) !== -1 ? (
                        <div>추가완료</div>
                      ) : (
                        <RoundCheckBox
                          checked={
                            selectedMember
                              ? selectedMember.id === member.id
                              : false
                          }
                          onChange={handleToggle(member)}
                        />
                      )}
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
            </List>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose("search")} color="primary">
              닫기
            </Button>
            <Button onClick={handleAddUser(selectedMember)} color="primary">
              추가
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          className={classes.dialog}
          open={openDialog.select}
          onClose={handleDialogClose("select")}
          TransitionComponent={Transition}
          disableBackdropClick={true}
        >
          <DialogTitle id="form-dialog-title">신청 정보 선택</DialogTitle>
          <DialogContent dividers>
            <Title>앱의 기존 정보로 신청하시겠습니까?</Title>
            <Content>
              이 경우에는 앱에 등록된 정보로 등록이 되며 팀원 변경 시 어려움이
              있을 수도 있습니다.
            </Content>
          </DialogContent>
          <DialogActions>
            <SelectButton onClick={handleAppInfo}>
              네, 기존 정보 등록
            </SelectButton>
            <SelectButton onClick={handleDialogClose("select")}>
              아니요, 새로 등록
            </SelectButton>
            {/* <MyButton
              onClick={() => setIsAppInfo(true)}
              rightOnClick={handleDialogClose}
              rightText={"새로 등록"}
              text={"네"}
              position={"relative"}
              half={true}
              color={"#000"}
            /> */}
          </DialogActions>
        </Dialog>
      </Wrapper>
    );
  }
};
