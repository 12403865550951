import React, { useEffect } from "react";
import styled from "styled-components";
import { Helmet } from "rl-react-helmet";
import Loader from "../../Components/Loader";
import Button from "../../Components/Button";
import Avatar from "../../Components/Avatar";
import UserGrade from "../../Components/UserGrade";
import { Edit, Camera, LogOut } from "../../Components/Icons";
import Fab from "../../Components/Fab";
import { Link } from "react-router-dom";

import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import MultipleSelect from "../../Components/MultiSelect";
import AddressSelect from "../../Components/AddressSelect";
import PullToRefresh from "rmc-pull-to-refresh";
import RefreshLoader from "../../Components/RefreshLoader";
import DedicateLoader from "../../Components/DedicateLoader";

const basketPositions = [
  {
    value: "PG",
    text: "PG"
  },
  {
    value: "SG",
    text: "SG"
  },
  {
    value: "SF",
    text: "SF"
  },
  {
    value: "PF",
    text: "PF"
  },
  {
    value: "C",
    text: "C"
  }
];

const Wrapper = styled.div`
  width: 100%;
  flex-grow: 1;
  background-color: #fff;
  @media (min-width: 500px) {
    position: relative;
    max-width: ${props => props.theme.maxWidth};
    margin: 0 auto;
  }
`;

const Header = styled.header`
  display: flex;
  align-items: center;
  min-height: 240px;
  /* min-height: 36vh; */
  color: #fff;
  /* margin: 0px 10px; */
  background-color: ${props => props.theme.blueColor};
  flex-direction: column;
  position:relative;
`;

const HeaderRow = styled.div`
  justify-content: ${props => props.justify};
  flex-grow: ${props => props.grow};

  &:first-child {
    width: 100%;
    min-height: 56px;
    display: flex;
    position: relative;
    align-items: center;
  }
  &:nth-child(2) {
    width: 100%;
    min-height: 56px;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    padding-top:10px;
    /* flex-direction: column; */
  }
`;

const UsernameColumn = styled.div`
  line-height: 1.8em;
  /* text-align: center; */
  &:first-child {
    /* padding-right: 40px; */
    position: relative;
    &  img, &  canvas {
      width: 85px;
      height: 85px;
      border-radius: 50%;
    }
  }
  &:last-child {
    margin-left:50px;
  }
`;

const ButtonWrap = styled.div`
  position:absolute;
  bottom:-30px;
  width: 100%;
  padding: 15px; 
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

const UserInfoRows = styled.div`
  flex-grow: 1;
  /* margin: 0px 10px; */
  background-color: "#fff";
  padding: 0px 15px;
  min-height: 64vh;
  padding-bottom: 50px;
  padding-top:40px;
`;

const UserInfoSection = styled.div`
  background-color: #fff;
  border-radius: 10px;
  padding: 10px;
  /* margin-bottom: 15px; */
  color: #000;
  display: flex;
  flex-direction: column;
  max-width: 500px;
  box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12);
  
  &:first-child {
    justify-content: space-evenly;
    align-items: center;
    flex-direction: row;
    margin: 0px auto 15px;
    min-height:120px;
    padding:0px;
  }
  &:last-child {
    min-height:200px;
  }
`;


const InputAdornment = styled.div`
      position: absolute;
    right: 10px;
    top: 10px;
    font-weight: 600;
`;



const UserInfoColumn = styled.div`
  padding: 10px 10px;
  /* width: 100%; */
`;

const UserInfoRow = styled.div`
  /* width: 50%; */
  display: flex;
  /* justify-content: space-around; */
  & > ${UserInfoColumn} {
    width:100%;
  }
`;

const UserInfoTitle = styled.div`
  color: #7a7a7a;
  margin-bottom: 2px;
`;
const UserInfoText = styled.div`
  color: #000;
  font-size: 18px;
  font-weight: 600;
  padding: 5px 0px;
  /* text-align:center; */
`;

const RegionsText = styled(UserInfoText)`
  font-size: 15px;
  &:first-child {
    margin-bottom: 5px;
  }
`;

const RateText = styled(UserInfoText)`
  margin-top:10px;
`;

const ButtonBox = styled.button`
  background-color: ${props => props.disabled? "#d5d5d5":props => props.theme.yellowColor};
  border: 1px solid ${props => props.disabled? "#d5d5d5":props => props.theme.yellowColor};
  cursor: ${props => props.disabled?"none":"pointer"};
  font-size: 15px;
  padding: 10px 0px;
  text-align: center;
  font-weight: 500;
  font-family: "NanumSqure";
  color: ${props => props.color};
  border-radius: 7px;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
    flex: 1;
    margin: 0px 10px;
`;

const UserName = styled.span`
  font-size: 18px;
  display: block;
  margin: 10px 0px;
  font-weight: 600;
`;

const TeamName = styled.span`
  font-size: 16px;
  display: block;
  & > span {
    margin-left:5px;
  }
`;

const UserLevel = styled.span`
  font-size: 15px;
`;

const UploadWapper = styled.div`
  position: absolute;
  right: 40px;
  bottom: 0px;
  & > input[type="file"] {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
  }
`;

const UploadBtn = styled.button`
  border: none;
  color: gray;
  background-color: white;
  padding: 10px;
  border-radius: 50%;
  font-size: 15px;
  font-weight: bold;
  width: 48px;
  height: 48px;
`;

const InputBox = styled.div`
  position: relative;
`;
const Input = styled.input`
  width: 100%;
  background-color: #ebebeb;
  border: 0;
  border-radius: 5px;
  padding: 10px;
  font-size: 16px;
  margin-bottom: 5px;
`;

const UserNameInput = styled.input`
  font-size: 18px;
  width: auto;
  max-width: 150px;
  color: #fff;
  border: 1px solid;
  background-color: transparent;
  border-radius: 5px;
  padding: 5px;
  font-weight: 600;
  margin: 10px 0px;
`;


const Form = styled.form`
  display: flex;
  justify-content: ${props => props.justify};
  width: 100%;
  padding: 0px 5px;
`;

const EditCompleteSpan = styled.span`
  font-family: "NanumSqure";
  font-size: 20px;
`;

const SelfCheckText = styled.div`
  font-size:14px;
  color:#ff005b;
`;




const FormatSido = (region) => {
  //console.log(region);

  let Sido = '';
  let Sigungu = '';

  if(region.value !== "") {
    Sido = region.value.split(' ')[0];
  
    if(region.value.split(' ').length > 2) {
      Sigungu = region.value.split(' ')[1] +' '+ region.value.split(' ')[2];
    } else {
      Sigungu = region.value.split(' ')[1];
    }
  } 
  return [Sido, Sigungu];
}

export default ({
  loading,
  data,
  onClickClose,
  handleEditMode,
  handleImageChange,
  editMode,
  previewImg,
  setAvatarUrl,
  setBasketId,
  level,
  setLevel,
  setGrade,
  usernameInput,
  contactInput,
  positionInput,
  heightInput,
  weightInput,
  ageInput,
  region1Input,
  region2Input,
  backNoInput,
  onSubmit,
  uploading,
  setRegistered,
  isInvite,
  setIsInvite,
  handleInvite,

  onRefresh,
  refreshing,
  onOurChat,
  onMoveChat
}) => {
  if (loading === true) {
    return (
      <Wrapper>
        <Loader />
        <Header>
          <Form justify={"flex-start"}>
            <IconButton
              onClick={() => {}}
              //onClick={onClickClose}
              edge="end"
              color="inherit"
            >
              <CloseIcon />
            </IconButton>
          </Form>
        </Header>
        <UserInfoRows>
          <UserInfoSection></UserInfoSection>
          <UserInfoSection></UserInfoSection>
        </UserInfoRows>
      </Wrapper>
    );
  } else if (!loading && data && data.seeUser) {
    const {
      seeUser: {
        age,
        avatar,
        contact,
        isSomeHost,
        username,
        itsMe,
        basket,
        myTeam,
        ourChat,
        isSameTeam
      },
      me : {
        isSomeHost : me_Host,
      }
    } = data;

    useEffect(() => {
      usernameInput.setValue(username);
      ageInput.setValue(age ? age : "0");
      contactInput.setValue(contact?contact:"");
      setAvatarUrl(avatar);
      setIsInvite(data.seeUser.isInviteToUser);
      if (basket) {
        setRegistered(true);
        if (basket.position) positionInput.setValue(basket.position);

        heightInput.setValue(basket.height ? basket.height : 0);
        weightInput.setValue(basket.weight ? basket.weight : 0);
        backNoInput.setValue(basket.backNo ? basket.backNo : 0);

        region1Input.setValue(
          basket.regions.length > 0 ? basket.regions[0] : ""
        );
        region2Input.setValue(
          basket.regions.length > 1 ? basket.regions[1] : ""
        );

        setLevel(basket.level ? basket.level : 0);
        setGrade(basket.grade ? basket.grade : "D");
        setBasketId(basket.id);
      }
    }, [data]);




    return (
      <Wrapper>
        {uploading && <Loader />}
        <Helmet>
          <title>{username} | WePlay</title>
        </Helmet>

        <PullToRefresh
          style={{ overflow: "auto", minHeight: "100vh", maxHeight: "100vh" }}
          direction="down"
          distanceToRefresh={50}
          damping={100}
          onRefresh={() => onRefresh()}
          refreshing={refreshing}
          //onScroll={e => onScroll(e)}
          indicator={{
            activate: <DedicateLoader  top={"-50px"} />,
            deactivate: <></>,
            release: <RefreshLoader  top={"-30px"}/>,
            finish: <></>
          }}
        >
          <Header>
            <HeaderRow>
              <Form justify={itsMe ? "space-between" : "flex-start"}>
                <IconButton
                  onClick={editMode ? handleEditMode : onClickClose}
                  //onClick={onClickClose}
                  edge="end"
                  color="inherit"
                >
                  <CloseIcon />
                </IconButton>

                {itsMe &&
                  (editMode ? (
                    <IconButton onClick={onSubmit} edge="start" color="inherit">
                      <EditCompleteSpan>완료</EditCompleteSpan>
                    </IconButton>
                  ) : (
                    <IconButton
                      onClick={handleEditMode}
                      edge="start"
                      color="inherit"
                    >
                      <Edit />
                    </IconButton>
                  ))}
              </Form>
            </HeaderRow>
            <HeaderRow>
              <UsernameColumn>
                {!previewImg ? (
                  <Avatar size="lg" url={avatar} />
                ) : (
                  <div id={"avatar"}></div>
                )}

                {editMode && (
                  <UploadWapper>
                    <UploadBtn>
                      <Camera fill={"#000"} />
                    </UploadBtn>
                    <Input
                      onChange={handleImageChange}
                      placeholder={""}
                      type={"file"}
                      name={"avatar"}
                      accept="image/*"
                    />
                  </UploadWapper>
                )}
              </UsernameColumn>
              <UsernameColumn>
                {editMode ? (
                  <InputBox>
                    <UserNameInput
                      value={usernameInput.value}
                      onChange={usernameInput.onChange}
                      type="text"
                      placeholder={""}
                    />
                  </InputBox>
                ) : (
                  <UserName>{username}</UserName>
                )}
                {myTeam && (
                  <TeamName>
                    {myTeam.teamName}
                    {isSomeHost && (
                      <>
                        <UserLevel>{"|"}</UserLevel>
                        <UserLevel>{"호스트"}</UserLevel>
                      </>
                    )}
                  </TeamName>
                )}
                <div>
                  {level !== null && <UserLevel>{`Lv.${level}`}</UserLevel>}
                </div>
              </UsernameColumn>
              
            </HeaderRow>
            <ButtonWrap>
              { (!itsMe && me_Host && !isSameTeam) && (
                isInvite ? (
                  <ButtonBox disabled={true}>
                    팀 초대 완료
                  </ButtonBox>
                ): (
                  <ButtonBox disabled={false} onClick={handleInvite}>
                    초대하기
                  </ButtonBox>
                )
              )}
                
              {!itsMe &&
                (ourChat ? (
                  <ButtonBox onClick={onOurChat(ourChat.id)}>메시지</ButtonBox>
                ) : (
                  <ButtonBox onClick={onMoveChat}>메시지</ButtonBox>
                ))}
            </ButtonWrap>
          </Header>

          <UserInfoRows>
            <UserInfoSection>
              <UserInfoColumn>
                {basket && basket.grade ? (
                  <UserGrade grade={basket.grade} />
                ) : (
                  <SelfCheckText>셀프진단을 진행하지 않았습니다.</SelfCheckText>
                )}
                {/* <UserGrade grade={basket && basket.grade ? basket.grade : null} /> */}
              </UserInfoColumn>
              {/* <UserInfoColumn>
              <UserInfoTitle>평점</UserInfoTitle>
              <RateText>0</RateText>
            </UserInfoColumn> */}
            </UserInfoSection>

            <UserInfoSection>
              <UserInfoRow>
                <UserInfoColumn>
                  <UserInfoTitle>연락처</UserInfoTitle>
                  {editMode ? (
                    <InputBox>
                      <Input
                        value={contactInput.value}
                        onChange={contactInput.onChange}
                        type="text"
                        placeholder={""}
                      />
                    </InputBox>
                  ) : (
                    <UserInfoText>
                      {contactInput.value.length !== 0 ? contactInput.value : "미등록"}
                    </UserInfoText>
                  )}
                </UserInfoColumn>
              </UserInfoRow>
              <UserInfoRow>
                <UserInfoColumn>
                  <UserInfoTitle>포지션</UserInfoTitle>

                  {editMode ? (
                    <MultipleSelect
                      value={
                        positionInput.value.length > 0
                          ? positionInput.value.split(",")
                          : []
                      }
                      onChange={positionInput.setValue}
                      datas={basketPositions}
                    />
                  ) : (
                    <UserInfoText>
                      {basket && basket.position
                        ? basket.position.split(",").length > 4
                          ? "ALL"
                          : basket.position.replace(/,/g, " | ")
                        : "미등록"}
                    </UserInfoText>
                  )}
                </UserInfoColumn>

                <UserInfoColumn>
                  <UserInfoTitle>등번호</UserInfoTitle>
                  {editMode ? (
                    <InputBox>
                      <Input
                        value={backNoInput.value}
                        onChange={backNoInput.onChange}
                        type="text"
                        placeholder={""}
                      />
                      <InputAdornment>번</InputAdornment>
                    </InputBox>
                  ) : (
                    <UserInfoText>
                      {backNoInput.value !== 0 ? backNoInput.value : "미등록"}
                    </UserInfoText>
                  )}
                </UserInfoColumn>
              </UserInfoRow>

              <UserInfoRow>
                <UserInfoColumn>
                  <UserInfoTitle>나이</UserInfoTitle>
                  {editMode ? (
                    <InputBox>
                      <Input
                        value={ageInput.value}
                        onChange={ageInput.onChange}
                        type="text"
                        placeholder={""}
                      />
                      <InputAdornment>세</InputAdornment>
                    </InputBox>
                  ) : (
                    <UserInfoText>
                      {ageInput.value !== "0"
                        ? ageInput.value + "세"
                        : "미등록"}
                      {/* {`${ageInput.value}세`} */}
                    </UserInfoText>
                  )}
                </UserInfoColumn>
                <UserInfoColumn>
                  <UserInfoTitle>신장</UserInfoTitle>
                  {editMode ? (
                    <InputBox>
                      <Input
                        value={heightInput.value}
                        onChange={heightInput.onChange}
                        type="text"
                        placeholder={""}
                      />
                      <InputAdornment>cm</InputAdornment>
                    </InputBox>
                  ) : (
                    <UserInfoText>
                      {basket && basket.height
                        ? basket.height + "cm"
                        : "미등록"}
                    </UserInfoText>
                  )}
                </UserInfoColumn>
                <UserInfoColumn>
                  <UserInfoTitle>몸무게</UserInfoTitle>

                  {editMode ? (
                    <InputBox>
                      <Input
                        value={weightInput.value}
                        onChange={weightInput.onChange}
                        type="text"
                        placeholder={""}
                      />
                      <InputAdornment>kg</InputAdornment>
                    </InputBox>
                  ) : (
                    <UserInfoText>
                      {basket && basket.weight
                        ? basket.weight + "kg"
                        : "미등록"}
                    </UserInfoText>
                  )}
                </UserInfoColumn>
              </UserInfoRow>

              <UserInfoRow>
                <UserInfoColumn>
                  <UserInfoTitle>선호 지역 1</UserInfoTitle>
                  {editMode ? (
                    <InputBox>
                      {/* <Input
                      value={region1Input.value}
                      onChange={region1Input.onChange}
                      type="text"
                      placeholder={"선호지역 1"}
                    /> */}
                      <AddressSelect
                        defaultSido={FormatSido(region1Input)[0]}
                        defaultSigungu={FormatSido(region1Input)[1]}
                        onChange={region1Input.setValue}
                      />
                    </InputBox>
                  ) : (
                    <RegionsText>
                      {basket &&
                      basket.regions.length > 0 &&
                      basket.regions[0] !== ""
                        ? basket.regions[0]
                        : "미입력"}
                    </RegionsText>
                  )}
                </UserInfoColumn>

                <UserInfoColumn>
                  <UserInfoTitle>선호 지역 2</UserInfoTitle>
                  {editMode ? (
                    <InputBox>
                      <AddressSelect
                        defaultSido={FormatSido(region2Input)[0]}
                        defaultSigungu={FormatSido(region2Input)[1]}
                        onChange={region2Input.setValue}
                      />
                    </InputBox>
                  ) : (
                    <RegionsText>
                      {basket &&
                      basket.regions.length > 1 &&
                      basket.regions[1] !== ""
                        ? basket.regions[1]
                        : "미입력"}
                    </RegionsText>
                  )}
                </UserInfoColumn>
              </UserInfoRow>
            </UserInfoSection>
            {/* <form>
            {isRequestToMe && !isSend && (
              <Button
                text={"수락"}
                rightText={"거부"}
                color={"#000"}
                half={true}
                onClick={onAcceptTeam}
                rightOnClick={onDeclineTeam}
              />
            )}
          </form> */}
          </UserInfoRows>
        </PullToRefresh>
      </Wrapper>
    );
  }
  return null;
};
