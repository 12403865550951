import { gql } from "apollo-boost";


export const ME = gql`
query ME {
  me {
    id
    username
    avatar
    cash
    isRegistered
    isSomeHost
    level
    reservationCount
    messageCount
    inviteCount
    notificationCount
    isOpenManager
    myTeam {
      id
      teamName
      matchersCount
    }
    myHostTeam {
      id
      teamName
      matchersCount
    }
  }
}
`;

export const CHECK_SURVEY = gql`
  {
      me {
        id
        isRegistered
        register {
          id
        }
        basket {
          id
        }
      }
  }
`;

export const GET_GAME_COUNT = gql`
  {
    me {
      id
      reservationCount
      myTeam {
        id
        reqUserCount
        matchersCount
      }
    }
  }
`;

export const CREATE_BASKET = gql`
  mutation createBasket(
    $position: String
    $height: String
    $weight: String
    $grade: String
    $backNo: Int
    $level: Int
  ) {
    createBasket(
      position:$position
      height:$height
      weight:$weight
      grade:$grade
      backNo:$backNo
      level:$level
    )
  }
`;


export const LOCAL_LOG_IN = gql`
  mutation logUserIn($token: String!) {
    logUserIn(token: $token) @client
  }
`;

export const LOG_OUT = gql`
  mutation logUserOut {
    logUserOut @client
  }
`;

export const GET_TOKEN = gql`
  mutation generateToken($snsId: String!) {
    generateToken(snsId:$snsId)
  }
`;