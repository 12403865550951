import React, { useEffect } from "react";
import styled from "styled-components";
import { Helmet } from "rl-react-helmet";
import Loader from "../../Components/Loader";
import Avatar from "../../Components/Avatar";
import { Refresh, Close, Party, LinkCopy } from "../../Components/Icons";
import SwipeableViews from "react-swipeable-views";
import BaseHeader from "../../Components/Header/BaseHeader";
import BoldText from "../../Components/BoldText";
import VoteList from "../../Components/VoteList";
import Map from "../../Components/Map";
import Button from "../../Components/Button";

import { Link } from "react-router-dom";
import { Divider } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import List from "@material-ui/core/List";
import Grid from "@material-ui/core/Grid";
import Slide from "@material-ui/core/Slide";
import { Dialog } from "@material-ui/core";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import InputBase from "@material-ui/core/InputBase";
import SearchIcon from "@material-ui/icons/Search";
import Fab from "../../Components/Fab";
import moment from "moment";
import "moment/locale/ko";
import PullToRefresh from "rmc-pull-to-refresh";
import DedicateLoader from "../../Components/DedicateLoader";
import RefreshLoader from "../../Components/RefreshLoader";

const Wrapper = styled.div`
  background-color: #081f40;
  min-height: 100vh;
`;

const ContentWrapper = styled.div`
  /* padding-top: 56px; */
  margin: auto 0px;
  background-color: ${props => props.theme.innerBgColor};
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

const GameHeader = styled.header`
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  width: 60%;
  margin: 0 auto;
  @media (max-width: 768px) {
    width: 95%;
  }
`;

const GameHeaderColumn = styled.div`
  padding: 7px;
  font-size: 16px;
  text-align: center;
  padding-top: 20px;
  &:nth-child(2) {
    padding-top: 0px;
    align-self: center;
  }
`;

const ColumnWrap = styled.div``;

const ColumnRow = styled.div`
  font-size: 15px;
  margin: 5px 0px;
  position: relative;
  padding: 0px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const VSText = styled.span`
  font-size: 20px;
`;

const HeaderAvatar = styled.div`
  border: 1px solid #fff;
  border-radius: 50%;
  padding: 6px;
  min-width: 99px;
  min-height: 99px;
`;

const GameInfoWrap = styled.div`
  padding: 8px 16px;
  display: flex;
  align-items: center;
  min-height: 70px;
  justify-content: space-evenly;
`;
const GameInfoColumn = styled.div``;

const ProfileIcon = styled.span`
  position: relative;
  right: -5px;
  top: 3px;
`;

const MyTabs = styled(Tabs)`
  color: #fff;
  padding: 5px 25px 0px;
  max-width: 500px;
  width: 100%;
  margin: 0 auto;
  & button:not(.Mui-selected) {
    z-index: 0;
  }
  .MuiTabs-flexContainer {
    background-color: #040f20;
    border-radius: 20px;
  }
  .MuiTabs-indicator {
    top: 0px;
    height: 4px;
    border-radius: 20px;
    height: 38px;
    background-color: ${props => props.theme.blueColor}!important;
  }
`;

const SearchWrapper = styled(ContentWrapper)`
  background-color: #fff;
  padding-top: 206px;
  margin: auto 10px;
  overflow-y: auto;
  & > ul > li:last-child {
    margin-bottom: 90px;
  }
`;

const InviteListButton = styled.button`
  padding: 5px 11px;
  background-color: #fff;
  color: #fff;
  border: 1px solid #888;
  color: #000;
  border-radius: 5px;
`;

const InviteWrapper = styled.div`
  background-color: #f2f2f2;
  border-radius: 5px;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const InviteHeader = styled.div`
  text-align: right;
  padding: 15px;
`;

const InviteContent = styled.div`
  text-align: center;
  font-size: 23px;
  color: #000;
  margin: 0px 30px 50px 30px;
  flex: 1;
  display: flex;
  font-weight: 500;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  line-height: 1.5em;
`;

const InviteFooter = styled.div`
  padding: 0px 20px 20px;
`;

const ShareIconsWrap = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  &:last-child {
    padding: 15px 0px;
  }
`;

const ShareIconBox = styled.a`
  font-size: 13px;
  display: flex;
  flex-direction: column;
  & > img {
    max-width: 50px;
  }
`;

const SNSIcon = styled.img``;

const WhiteIcon = styled.div`
  background-color: #fff;
  border-radius: 10px;
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const HiddenInput = styled.input`
  opacity: 0;
  position: absolute;
  border: 0;
  z-index:-1;
`;

const VoteButton = styled.div`
  cursor: pointer;
  border:1px solid #fff;
  font-size:13px;
  padding:5px 8px;
  border-radius:6px;
`;

const RefreshWrap = styled.div`
  display:flex;
  align-items:center;
  background-color:#fff;
  height:40px;
  font-size:12px;
  padding-left:15px;

  & > .MuiCircularProgress-colorPrimary {
    position: absolute !important;
    top: 200px;
    z-index: 999;
    width: 30px !important;
    height: 30px !important;
    color: #1ea9e1;
  }

  & span {
    margin-left: 6px;
    margin-top: 1px;
  }
`;

const RefreshIcon  = styled.div`
  display:flex;
  align-items:center;
`;

const MyTabPanel = styled(TabPanel)`
  .MuiListItemText-secondary {
    color: #000;
  }
  & {
    /* padding-bottom: 47px; */
  }
`;

const useStyles = makeStyles(theme => ({
  card: {
    borderRadius: 0
  },
  tabRoot: {
    fontSize: 16,
    fontFamily: "NanumSqure",
    zIndex: 1,
    minHeight: "38px"
  },
  media: {
    height: 0,
    backgroundSize: "contain",
    paddingTop: "56.25%" // 16:9
  },
  userWrap: {
    // maxHeight: "400px",
    maxWidth: "600px",
    margin: "0 auto",
    width: "100%",
    color: "#000",
    overflowY: "auto",
    backgroundColor: "#fff",
    "& ul.MuiList-padding": {
      padding: 0,
      paddingBottom: 74,
      backgroundColor: "#fff"
    }
  },
  SwipeView: {
    flex: 1,
    width: "100%",
    maxWidth: "924px",
    display: "flex",
    flexDirection: "column",
    margin: "0 auto",
    "& > .react-swipeable-view-container": {
      flex: 1
    }
  },
  boxRoot: {
    height: "100%",
    display: "flex",
    flexDirection: "column"
  },
  panelRoot: {
    height: "100%"
  },
  hr: {
    height: "50%"
  },
  appBar: {
    position: "fixed",
    backgroundColor: "#227fff"
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1
  },
  invite: {
    marginLeft: "auto"
  },
  dialog: {
    "& .MuiDialog-paper": {
      backgroundColor: "transparent"
    }
  },
  search: {
    position: "relative",
    top: "25%",
    borderBottom: "1px solid"
  },
  searchIcon: {
    width: theme.spacing(5),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  inputRoot: {
    width: "100%",
    color: "inherit",
    fontSize: "14px"
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 5),
    transition: theme.transitions.create("width"),
    width: "100%"
  },
  inviteDialog: {
    "& .MuiDialog-container": {
      alignItems: "flex-end"
    },
    "& .MuiDialog-paperWidthSm": {
      width: "100%",
      margin: 0,
      height: "50vh",
      maxWidth: 500,
      maxHeight: 400
    }
  },
  toolBar: {
    height: "200px",
    display: "block",
    textAlign: "right",
    paddingTop: "5px"
  }
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  const classes = useStyles();
  if (props.className.indexOf("mapWrapper") !== -1) {
    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        <Box className={classes.boxRoot} p={0}>
          {children}
        </Box>
      </Typography>
    );
  } else {
    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        // {...other}
        className={classes.panelRoot}
      >
        <Box className={classes.boxRoot} p={0}>
          {children}
        </Box>
      </Typography>
    );
  }
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`
  };
}

const _gameWeekText = gameDate => {
  let week = ["일", "월", "화", "수", "목", "금", "토"];
  let date = new Date(gameDate);
  return `(${week[date.getDay()]})`;
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default ({
  loading,
  data,
  dataVotes,
  loadingVotes,
  onBack,
  onClickVote,
  //uploading,
  votes,
  setVotes,
  currentVotes,
  setCurrentVotes,
  currentTeam,
  setCurrentTeam,
  isVote,
  setIsVote,
  GAMESTATUS,
  setGAMESTATUS,

  refreshing,
  onRefresh,

  selectedUser,
  openShare,
  openSearch,
  openInvite,
  handleShareOpen,
  handleShareClose,
  handleInviteOpen,
  handleInviteClose,
  handleSearchOpen,
  handleSearchClose,
  handleInvite,
  searchUsers,
  search,
  searching
}) => {
  if (loading === true) {
    return (
      <Wrapper>
        <Loader />
        <BaseHeader
          text={`경기 투표`}
          isBack={true}
          onClickBack={onBack}
          isClose={false}
          isMenu={false}
          isCenter={true}
        />
        <Helmet>
          <title>경기 투표 | WePlay</title>
        </Helmet>
        <ContentWrapper></ContentWrapper>
      </Wrapper>
    );
  } else if (!loading && data && data.seeFullGame && dataVotes && dataVotes.seeVotes) {
    const classes = useStyles();
    const theme = useTheme();
    const [value, setValue] = React.useState(0);
    const [mapInit, setMapInit] = React.useState(false);
    //const [place, setPlace] = useState();


    const handleChange = (event, newValue) => {
      setMapInit(true);
      setValue(newValue);
    };

    const handleChangeIndex = index => {
      setValue(index);
    };

    const CopyUrlToClipboard = () => {
      const obShareUrl = document.getElementById("ShareUrl");
      obShareUrl.value = "https://play.weplaysports.net";
      obShareUrl.select();
      document.execCommand("copy");
      obShareUrl.blur();
      if(window.webkit && data && data.me) {
        try {
          if(typeof window.webkit.messageHandlers.showAlert.postMessage === "function") {
            window.webkit.messageHandlers.showAlert.postMessage("클립보드에 복사되었습니다.");
          }
        } catch(e) {
          alert(e);
          console.log(e);
        }
      }
    };



    useEffect(() => {
      console.log(data.seeFullGame);
      setIsVote(data.seeFullGame.didVote);
      setGAMESTATUS(data.seeFullGame.gameStatus);
      setVotes(dataVotes.seeVotes);
    }, [data, dataVotes]);

    useEffect(()=>{
      console.log("Refresh Votes");
      if (data.seeFullGame.homeTeam.id === currentTeam) {
        if(data.seeFullGame.homeTeam.host)
          data.seeFullGame.homeTeam.host['isHost'] = true;
        if(data.seeFullGame.homeTeam.managers.length > 0) {
          data.seeFullGame.homeTeam.managers.forEach(user => user.isHost = true)
        }
        const HomeUsers = [
          data.seeFullGame.homeTeam.host,
          ...data.seeFullGame.homeTeam.managers,
          ...data.seeFullGame.homeTeam.users
        ];

        HomeUsers.map(u => {
          let _data = votes ? votes.find(v => v.user.id === u.id) : dataVotes.seeVotes.find(v => v.user.id === u.id);
          if (_data) u.answer = _data.answer;
          return u;
        });

        setCurrentVotes(HomeUsers);   
      } else if (data.seeFullGame.awayTeam.id === currentTeam) {
        if(data.seeFullGame.awayTeam.host)
          data.seeFullGame.awayTeam.host['isHost'] = true;
        if(data.seeFullGame.awayTeam.managers.length > 0) {
          data.seeFullGame.awayTeam.managers.forEach(user => user.isHost = true)
        }
        const AwayUsers = [
          data.seeFullGame.awayTeam.host,
          ...data.seeFullGame.awayTeam.managers,
          ...data.seeFullGame.awayTeam.users
        ];

        AwayUsers.map(u => {
          let _data = votes ? votes.find(v => v.user.id === u.id) : dataVotes.seeVotes.find(v => v.user.id === u.id);
          if (_data) u.answer = _data.answer;
          return u;
        });

        setCurrentVotes(AwayUsers);
      }
    },[votes, currentTeam])


    return (
      <Wrapper>
        <BaseHeader
          text={`경기 투표`}
          isBack={true}
          onClickBack={onBack}
          isClose={false}
          isMenu={false}
          isCenter={true}
        />
        <Helmet>
          <title>경기 투표 | WePlay</title>
        </Helmet>

        <PullToRefresh
          style={{ overflow: "auto", paddingTop:56, minHeight:"100vh", maxHeight:"100vh" }}
          direction="down"
          distanceToRefresh={50}
          damping={100}
          onRefresh={() => onRefresh()}
          refreshing={refreshing}
          //onScroll={e => onScroll(e)}
          indicator={{
            activate: <DedicateLoader/>,
            deactivate: <></>,
            release: <RefreshLoader />,
            finish: <></>
          }}
        >

        <ContentWrapper>
          <GameHeader>
            <GameHeaderColumn>
              <ColumnWrap>
                <ColumnRow>
                  <HeaderAvatar>
                    <Link to={`/team/${data.seeFullGame.homeTeam.id}`}>
                      <Avatar url={data.seeFullGame.homeTeam.logo} size={"lmd"} />
                    </Link>
                  </HeaderAvatar>
                </ColumnRow>
                <ColumnRow>
                  <BoldText text={data.seeFullGame.homeTeam.teamName} />
                </ColumnRow>
                <ColumnRow>{`Lv.${data.seeFullGame.homeTeam.level}`}</ColumnRow>
                {data.seeFullGame.awayTeam.id === currentTeam && (
                  <ColumnRow onClick={() => setCurrentTeam(data.seeFullGame.homeTeam.id)}>
                    <VoteButton>
                      투표 현황 보기
                    </VoteButton>
                  </ColumnRow>
                )}
                
              </ColumnWrap>
            </GameHeaderColumn>
            <GameHeaderColumn>
              <ColumnWrap>
                <ColumnRow>
                  <VSText>VS</VSText>
                </ColumnRow>
              </ColumnWrap>
            </GameHeaderColumn>
            <GameHeaderColumn>
              <ColumnWrap>
                <ColumnRow>
                  <HeaderAvatar>
                    <Link to={`/team/${data.seeFullGame.awayTeam.id}`}>
                      <Avatar url={data.seeFullGame.awayTeam.logo} size={"lmd"} />
                    </Link>
                 
                  </HeaderAvatar>
                </ColumnRow>
                <ColumnRow>
                  <BoldText text={data.seeFullGame.awayTeam.teamName} />
                </ColumnRow>
                <ColumnRow>{`Lv.${data.seeFullGame.awayTeam.level}`}</ColumnRow>
                {data.seeFullGame.homeTeam.id === currentTeam && (
                  <ColumnRow onClick={() => setCurrentTeam(data.seeFullGame.awayTeam.id)}>
                    <VoteButton>
                      투표 현황 보기
                    </VoteButton>
                  </ColumnRow>
                )}
              </ColumnWrap>
            </GameHeaderColumn>
          </GameHeader>

          <MyTabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="inherit"
            variant="fullWidth"
            aria-label="full width tabs example"
          >
            <Tab
              label="경기 시간"
              className={classes.tabRoot}
              {...a11yProps(0)}
            />
            <Tab
              label="경기장 정보"
              className={classes.tabRoot}
              {...a11yProps(1)}
            />
          </MyTabs>

          <SwipeableViews
            className={classes.SwipeView}
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={value}
            onChangeIndex={handleChangeIndex}
            disabled={true}
          >
            <MyTabPanel value={value} index={0}>
              {/* 
                * 용병 초대 기능 완성 전까지 막아두기.
              <Fab
                type={"inviteText"}
                top={"50px"}
                right={"3px"}
                onClick={handleSearchOpen}
              />  */}

              <GameInfoWrap>
                <GameInfoColumn>
                  <BoldText text={moment(data.seeFullGame.gameDate).format("YYYY. MM. DD")} />
                  {_gameWeekText(data.seeFullGame.gameDate)}
                </GameInfoColumn>
                <GameInfoColumn>
                  <BoldText text={`${data.seeFullGame.startTime} ~ ${data.seeFullGame.endTime}`} />
                </GameInfoColumn>
              </GameInfoWrap>

              <Grid className={classes.userWrap} item xs={12}>
                <List>
                  {currentVotes &&
                    currentVotes.map(vote => {
                      return (
                        <Link key={vote.id} to={`/user/${vote.id}`}>
                          <VoteList
                            userId={vote.id}
                            url={vote.avatar}
                            isHost={vote.isHost}
                            primary={`${vote.username}(${
                              vote.basket && vote.basket.backNo ? vote.basket.backNo : "미등록"
                            })`}
                            secondary={`Lv.${vote.level} | ${
                              vote.basket && vote.basket.position
                                ? vote.basket.position
                                : "포지션 미등록"
                            }`}
                            answer={vote.answer}
                          />
                        </Link>
                      );
                    })}
                </List>
              </Grid>
            </MyTabPanel>

            <MyTabPanel
              className={"mapWrapper"}
              value={value}
              index={1}
            >
              {value === 1 && mapInit && data.seeFullGame.place && (
                <Map
                  isInit={mapInit}
                  address={data.seeFullGame.place.address}
                  placeName={data.seeFullGame.place.placeName}
                  lat={data.seeFullGame.place.location.latitude}
                  lng={data.seeFullGame.place.location.longitude}
                ></Map>
              )}
            </MyTabPanel>
          </SwipeableViews>



          { GAMESTATUS === "MATCH" &&
          data.seeFullGame.homeTeam.id === currentTeam &&
          data.seeFullGame.homeTeam.isMine &&
              (isVote ? (
                <Button
                  text={"다시 투표하기"}
                  onClick={() => setIsVote(false)}
                  color={"#000"}
                  half={false}
                />
              ) : (
                <Button
                  rightText={"불참"}
                  rightOnClick={onClickVote(1)}
                  color={"#000"}
                  half={true}
                  text={"참여"}
                  onClick={onClickVote(0)}
                />
              ))}

          {GAMESTATUS === "MATCH" &&
          data.seeFullGame.awayTeam.id === currentTeam &&
          data.seeFullGame.awayTeam.isMine &&
              (isVote ? (
                <Button
                  text={"다시 투표하기"}
                  onClick={() => setIsVote(false)}
                  color={"#000"}
                  half={false}
                />
              ) : (
                <Button
                  rightText={"불참"}
                  rightOnClick={onClickVote(1)}
                  color={"#000"}
                  half={true}
                  text={"참여"}
                  onClick={onClickVote(0)}
                />
              ))}

          {GAMESTATUS === "PLAYING" && (
            <Button
              text={"경기 진행 중"}
              disabled={true}
              color={"#000"}
              half={false}
            />
          )}

          {(GAMESTATUS === "FINISH" || GAMESTATUS === "CONFIRM" || GAMESTATUS === "COMPLETE") && (
            <Button
              text={"경기 종료"}
              disabled={true}
              color={"#000"}
              half={false}
            />
          )}
        </ContentWrapper>
        </PullToRefresh>


        <Dialog
          className={classes.dialog}
          fullScreen
          open={openSearch}
          onClose={handleSearchClose}
          TransitionComponent={Transition}
        >
          <AppBar className={classes.appBar}>
            <Toolbar className={classes.toolBar}>
              <IconButton
                edge="end"
                color="inherit"
                onClick={handleSearchClose}
                aria-label="close"
              >
                <Close />
              </IconButton>
              <div className={classes.search}>
                <div className={classes.searchIcon}>
                  <SearchIcon />
                </div>
                <InputBase
                  placeholder="회원을 검색하세요"
                  classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput
                  }}
                  inputProps={{ "aria-label": "search" }}
                  value={search.value}
                  onChange={search.onChange}
                />
              </div>
            </Toolbar>
          </AppBar>

          <SearchWrapper>
            <List dense>
              {searching && <CircularProgress />}
              {searchUsers &&
                searchUsers.length > 0 &&
                searchUsers.map(user => (
                  <ListItem key={user.id}>
                    <ListItemAvatar>
                      <Avatar size={"sm"} url={user.avatar} />
                    </ListItemAvatar>
                    <ListItemText
                      primary={user.username}
                      secondary={`Lv. ${user.basket ? user.basket.level : "0"}`}
                    />
                    <InviteListButton onClick={handleInviteOpen(user)}>
                      + 초대
                    </InviteListButton>
                  </ListItem>
                ))}
            </List>
          </SearchWrapper>
          <Fab
            type={"share"}
            top={"85%"}
            right={"20px"}
            onClick={handleShareOpen}
          />
        </Dialog>

        <Dialog
          className={classes.inviteDialog}
          open={openInvite}
          onClose={handleInviteClose}
          TransitionComponent={Transition}
        >
          <InviteWrapper>
            <InviteHeader>
              <IconButton
                edge="end"
                color="inherit"
                onClick={handleInviteClose}
                aria-label="close"
              >
                <Close fill={"#000"} />
              </IconButton>
            </InviteHeader>
            <InviteContent>
              <Party />
              <div>
                {selectedUser && selectedUser.username}님에게 초대장을
                <br />
                보내시겠습니까?
              </div>
            </InviteContent>
            <InviteFooter>
              <Button color={"#000"} text={"보내기"} onClick={handleInvite} />
            </InviteFooter>
          </InviteWrapper>
        </Dialog>

        <Dialog
          className={classes.inviteDialog}
          open={openShare}
          onClose={handleShareClose}
          TransitionComponent={Transition}
        >
          <InviteWrapper>
            <InviteHeader>
              <IconButton
                edge="end"
                color="inherit"
                onClick={handleShareClose}
                aria-label="close"
              >
                <Close fill={"#000"} />
              </IconButton>
            </InviteHeader>
            <InviteContent>
              <ShareIconsWrap>
                <ShareIconBox href="https://www.facebook.com/sharer.php?u=play.weplaysports.net">
                  <SNSIcon src={"/imgs/share_btn_kakao.png"} />
                  <p>카카오톡</p>
                </ShareIconBox>
                <ShareIconBox href="https://www.facebook.com/sharer.php?u=play.weplaysports.net">
                  <SNSIcon src={"/imgs/share_btn_facebook.png"} />
                  <p>페이스북</p>
                </ShareIconBox>
                <ShareIconBox
                  href={`https://lineit.line.me/share/ui?url=play.weplaysports.net&text=위플레이로초대합니다`}
                >
                  <SNSIcon src={"/imgs/share_btn_line.png"} />
                  <p>라인</p>
                </ShareIconBox>
                <ShareIconBox
                  href={`https://twitter.com/intent/tweet?url=play.weplaysports.net&text=위플레이로초대합니다`}
                >
                  <SNSIcon src={"/imgs/share_btn_twitter.png"} />
                  <p>트위터</p>
                </ShareIconBox>
              </ShareIconsWrap>
              <Divider />
              <ShareIconsWrap>
                <ShareIconBox>
                  <WhiteIcon onClick={CopyUrlToClipboard}>
                    <LinkCopy fill={"#000"} />
                  </WhiteIcon>
                  <p>링크복사</p>
                </ShareIconBox>
                <HiddenInput id="ShareUrl" type="text" />
              </ShareIconsWrap>
            </InviteContent>
          </InviteWrapper>
        </Dialog>
      </Wrapper>
    );
  }
  return null;
};
