import React, {useState} from "react";
import styled from "styled-components";
import { Helmet } from "rl-react-helmet";
import Avatar from "../../Components/Avatar";
import SwipeableViews from "react-swipeable-views";
import Button from "../../Components/Button";
import BoldText from "../../Components/BoldText";
import Map from "../../Components/Map";
import BaseHeader from "../../Components/Header/BaseHeader";
import {  Party, Close, Arrow } from "../../Components/Icons";

import { Link } from "react-router-dom";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Slide from "@material-ui/core/Slide";
import { Dialog, Card, Divider, CardMedia, Grid, ListItemSecondaryAction } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import InputBase from "@material-ui/core/InputBase";
import SearchIcon from "@material-ui/icons/Search";
import Fab from "../../Components/Fab";
import Gallery from "../Gallery";
import moment from "moment";
import "moment/locale/ko";
import Loader from "../Loader";

const Wrapper = styled.div``;

const ContentWrapper = styled.div`
  padding-top: 56px;
  /* margin: auto 10px; */
  background-color: ${props => props.theme.innerBgColor};
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  @media (min-width: 500px) {
    max-width: ${props => props.theme.maxWidth};
    margin: 0 auto;
  }
`;

const SearchWrapper = styled(ContentWrapper)`
  background-color:#fff;
  padding-top: 206px;
  overflow-y: scroll;
`;

const InviteListButton = styled.button`
    padding: 5px 11px;
    background-color: #fff;
    color: #fff;
    border: 1px solid #888;
    color: #000;
    border-radius: 5px;
`;


const GameInfoWrap = styled.div`
  padding: 8px 16px;
  display: flex;
  align-items: center;
  min-height: 60px;
  justify-content: space-evenly;
`;
const GameInfoColumn = styled.div``;


const VerticalDivider = styled(Divider)`
  background-color: rgba(255, 255, 255, 0.12) !important;
  height: 50%;
`;

const LastHeaderBox = styled.span`
  border: 1px solid #000;
  border-radius: 6px;
  padding: 5px;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0px 8px 20px;
  width: 100px;
  cursor:pointer;
  @media (max-width: 600px) {
    font-size: 13px;
  }

`;

const InviteWrapper = styled.div`
   background-color:#f2f2f2;
   border-radius:5px;
   max-width: 600px;
   display:flex;
   flex-direction:column;
   height:100%;
`;

const InviteHeader = styled.div`
  text-align:right;
  padding: 15px;
`;

const InviteContent = styled.div`
  text-align:center;
  font-size:23px;
  color:#000;
  margin:0px 30px 50px 30px;
  flex:1;
  display: flex;
  font-weight: 500;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  line-height: 1.5em;
`;

const InviteFooter = styled.div`
  padding: 0px 20px 20px;
`;

const MyTabs = styled(Tabs)`
  color: #fff;
  padding: 5px 25px 0px;
  max-width: 500px;
  width: 100%;
  margin: 0 auto;
  & button:not(.Mui-selected) {
    z-index: 0;
  }
  .MuiTabs-flexContainer {
    background-color: #040f20;
    border-radius: 20px;
  }
  .MuiTabs-indicator {
    top: 0px;
    height: 4px;
    border-radius: 20px;
    height: 38px;
    background-color: ${props => props.theme.blueColor}!important;
  }
`;

const Title = styled.div`
  background-color: #fff;
  padding: 10px 20px;
  color: #000;
  height: 56px;
  display: flex;
  align-items: center;
`;

const Message = styled.div`
  background-color: #f2f2f2;
  padding: 10px 20px;
  color: #000;
  flex: 1;
  padding-bottom: 85px;
`;

const HostSpan = styled.span`
    font-weight: 600;
    font-size: 16px;
`;

const MyTabPanel = styled(TabPanel)`
  .MuiListItemText-secondary {
    color: #000;
  }
  & {
    padding-bottom: 67px;
  }
`;





const useStyles = makeStyles(theme => ({
  card: {
    borderRadius: 0,
    backgroundColor: "transparent"
  },
  media: {
    height: 0,
    paddingTop: "56.25%" // 16:9
  },
  tabRoot: {
    fontSize: 16,
    fontFamily: "NanumSqure",
    zIndex: 1,
    minHeight: "38px"
  },
  userWrap: {
    // maxHeight: "400px",
    maxWidth: "600px",
    margin: "0 auto",
    width:"100%",
    color: "#000",
    overflowY:"auto",
    backgroundColor:"#fff",
    "& ul.MuiList-padding": {
      padding: 0,
      paddingBottom:74,
      backgroundColor:"#fff"
    }
  },
  scoreWrap: {
    padding: "0px 15px;"
  },
  SwipeView: {
    flex: 1,
    width: "100%",
    maxWidth: "924px",
    display: "flex",
    flexDirection: "column",
    margin: "0 auto",
    position:"relative",
    "& > .react-swipeable-view-container": {
      flex: 1
    }
  },
  boxRoot: {
    height: "100%",
    display: "flex",
    flexDirection: "column"
  },
  panelRoot: {
    height: "100%"
  },
  appBar: {
    position: "fixed",
    backgroundColor: "#227fff"
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1
  },
  invite: {
    marginLeft: "auto"
  },
  dialog: {
    "& .MuiDialog-paper": {
      backgroundColor: "#113f80"
    }
  },
  search: {
    position: "relative",
    top: "25%",
    borderBottom: "1px solid",
  },
  searchIcon: {
    width: theme.spacing(5),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  inputRoot: {
    width: "100%",
    color: "inherit",
    fontSize: "14px"
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 5),
    transition: theme.transitions.create("width"),
    width: "100%"
  },
  inviteDialog: {
    "& .MuiDialog-container": {
      alignItems: "flex-end"
    },
    "& .MuiDialog-paperWidthSm":{
      width: "100%",
      margin: 0,
      height: "50vh",
      maxWidth:500,
      maxHeight:400
    }
  },
  toolBar: {
    height: "200px",
    display: "block",
    textAlign:"right",
    paddingTop:"5px"
  },
  hostList: {
    backgroundColor: "#fff",
    color:"#000",
    flex:0,
    borderBottom: "1px solid #c8c8c8",
    "& ul.MuiList-padding": {
      paddingTop:4,
      paddingBottom:4,
      backgroundColor: "#fff"
    },
    "& .MuiTypography-body2": {
      fontSize:16,
      fontWeight: 600
    }
  }
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  const classes = useStyles();
  if (props.className.indexOf("mapWrapper") !== -1) {
    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        <Box className={classes.boxRoot} p={0}>
          {children}
        </Box>
      </Typography>
    );
  } else {
    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        // {...other}
        className={classes.panelRoot}
      >
        <Box className={classes.boxRoot} p={0}>
          {children}
        </Box>
      </Typography>
    );
  }
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`
  };
}

const _gameWeekText = gameDate => {
  let week = ["일", "월", "화", "수", "목", "금", "토"];
  let date = new Date(gameDate);
  return `(${week[date.getDay()]})`;
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function RECRUIT(props) {

  console.log(props);

  const {
    onRequest,
    inviteUser,
    openSearch,
    openInvite,
    handleInviteOpen,
    handleInviteClose,
    handleSearchOpen,
    handleSearchClose,
    handleInvite,
    searchUsers,
    search,
    searching,
    onMatch,
    onCancel,
    onRoomCancel,
    isSendRequest,
    uploading,
    data : {
      seeFullRoom: {
        id,
        picture,
        gameDate,
        startTime,
        endTime,
        gameTitle,
        gameBio,
        host,
        users,
        place
      },
      me : {
        id:myId
      }
    }
  } = props;

  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = useState(0);
  const [mapInit, setMapInit] = useState(false);

  const [openCancel, setOpenCancel] = useState(false);

  const handleCancelOpen = () => {
    setOpenCancel(true);
  }

  const handleCancelClose = () => {
    setOpenCancel(false);
  }

  const handleChange = (newValue) => {

    setMapInit(true);
    setValue(newValue);
  };

  const handleChangeIndex = index => {
    setValue(index);
  };

  if(host.id === myId) {
    return (
      <Wrapper>
        <BaseHeader
          text={"모집 중"}
          isBack={true}
          isClose={false}
          isCenter={true}
          isMenu={true}
        />
        <Helmet>
          <title>모집 중 | {host.username}의 오픈방</title>
        </Helmet>
        {uploading && <Loader />}


        <ContentWrapper>
          {/* <Header>
              <HeaderColumn grow={1}>Lv.{level}</HeaderColumn>
              <VerticalDivider className={classes.hr} orientation="vertical" />
              <HeaderColumn grow={1}>회원 {userCount}명</HeaderColumn>
              <VerticalDivider className={classes.hr} orientation="vertical" />
            </Header> */}

          <Card className={classes.card}>
              {picture.length > 0 ? (
                 <Gallery items={picture} />
              ):(
                <CardMedia
                  className={classes.media}
                  image={"/imgs/openroom_basic_thumbnail.jpg"}
                  title="Paella dish"
                />
              )}
          </Card>

          <MyTabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="inherit"
            variant="fullWidth"
            aria-label="tabs"
          >
            <Tab
              className={classes.tabRoot}
              label="오픈방 정보"
              {...a11yProps(0)}
            />
            <Tab
              className={classes.tabRoot}
              label="경기장 정보"
              {...a11yProps(1)}
            />
          </MyTabs>

          <SwipeableViews
            className={classes.SwipeView}
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={value}
            onChangeIndex={handleChangeIndex}
            disabled={true}
          >
            <MyTabPanel value={value} index={0}>
              <Link to={`/room/${id}/user`}>
                <Fab
                  type={"inviteText"}
                  top={"50px"}
                  right={"3px"}
                  //onClick={handleSearchOpen}
                />
              </Link>

              <GameInfoWrap>
                <GameInfoColumn>
                  <BoldText text={moment(gameDate).format("YYYY. MM. DD")} />
                  {_gameWeekText(gameDate)}
                </GameInfoColumn>
                <GameInfoColumn>
                  <BoldText text={`${startTime} ~ ${endTime}`} />
                </GameInfoColumn>
              </GameInfoWrap>


              <Grid className={classes.userWrap} item xs={12}>
                <LastHeaderBox onClick={()=>alert("준비 중입니다.")}>
                  전체 메시지
                </LastHeaderBox>
                <List dense>
                  <Link key={host.id} to={`/user/${host.id}`}>
                    <ListItem button>
                      <ListItemAvatar>
                        <Avatar size={"sm"} url={host.avatar} isHost={true}/>
                      </ListItemAvatar>
                      <ListItemText
                        primary={host.username}
                        secondary={`Lv.${host.level}`}
                      />
                      <ListItemSecondaryAction>
                        <IconButton
                          edge="end"
                          color="inherit"
                          aria-label="detail"
                        >
                          <Arrow />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  </Link>
                  { users && users.length > 0 && users.map(user => (
                    <Link key={user.id} to={`/user/${user.id}`}>
                      <ListItem button>
                        <ListItemAvatar>
                          <Avatar size={"sm"} url={user.avatar} />
                        </ListItemAvatar>
                        <ListItemText
                          primary={user.username}
                          secondary={`Lv.${user.level}`}
                        />
                        <ListItemSecondaryAction>
                          <IconButton
                            edge="end"
                            color="inherit"
                            aria-label="detail"
                          >
                            <Arrow />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                    </Link>
                  ))}
                  
                </List>
              </Grid>
            </MyTabPanel>

            <MyTabPanel
              className={"mapWrapper"}
              value={value}
              index={1}
            >
              {value === 1 && mapInit && place && (
                <Map
                  isInit={value}
                  address={place.address}
                  placeName={place.placeName}
                  lat={place.location.latitude}
                  lng={place.location.longitude}
                ></Map>
              )}
            </MyTabPanel>
          </SwipeableViews>

          <Button
            text={"확정"}
            rightText={"취소"}
            color={"#000"}
            half={true}
            onClick={onMatch}
            rightOnClick={handleCancelOpen}
          />
        </ContentWrapper>

        <Dialog
          className={classes.inviteDialog}
          open={openCancel}
          onClose={handleCancelClose}
          TransitionComponent={Transition}
        >
          <InviteWrapper>
            <InviteHeader>
              <IconButton
                edge="end"
                color="inherit"
                onClick={handleCancelClose}
                aria-label="close"
              >
                <Close fill={"#000"} />
              </IconButton>
            </InviteHeader>
            <InviteContent>
              <div>
                경기를 취소하시겠습니까?
                <br />
                패널티를 받을 수 있습니다.
              </div>
            </InviteContent>
            <InviteFooter>
              <Button
                color={"#000"}
                text={"경기 취소하기"}
                onClick={onRoomCancel}
              />
            </InviteFooter>
          </InviteWrapper>
        </Dialog>

        <Dialog
          className={classes.dialog}
          fullScreen
          open={openSearch}
          onClose={handleSearchClose}
          TransitionComponent={Transition}
        >
          <AppBar className={classes.appBar}>
            <Toolbar className={classes.toolBar}>
              <IconButton
                edge="end"
                color="inherit"
                onClick={handleSearchClose}
                aria-label="close"
              >
                <Close />
              </IconButton>
              <div className={classes.search}>
                <div className={classes.searchIcon}>
                  <SearchIcon />
                </div>
                <InputBase
                  placeholder="회원을 검색하세요"
                  classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput
                  }}
                  inputProps={{ "aria-label": "search" }}
                  value={search.value}
                  onChange={search.onChange}
                />
              </div>
            </Toolbar>
          </AppBar>

          <SearchWrapper>
            <List dense>
              {searching && <CircularProgress />}
              {searchUsers &&
                searchUsers.length > 0 &&
                searchUsers.map(user => (
                  <ListItem key={user.id}>
                    <ListItemAvatar>
                      <Avatar size={"sm"} url={user.avatar} />
                    </ListItemAvatar>
                    <ListItemText
                      primary={user.username}
                      secondary={`Lv. ${user.basket ? user.basket.level : "0"}`}
                    />
                    <InviteListButton onClick={handleInviteOpen(user)}>
                      + 초대
                    </InviteListButton>
                  </ListItem>
                ))}
            </List>
          </SearchWrapper>
        </Dialog>

        <Dialog
          className={classes.inviteDialog}
          open={openInvite}
          onClose={handleInviteClose}
          TransitionComponent={Transition}
        >
          <InviteWrapper>
            <InviteHeader>
              <IconButton
                edge="end"
                color="inherit"
                onClick={handleInviteClose}
                aria-label="close"
              >
                <Close fill={"#000"} />
              </IconButton>
            </InviteHeader>
            <InviteContent>
              <Party />
              <div>
                {inviteUser && inviteUser.username}님에게 초대장을
                <br />
                보내시겠습니까?
              </div>
            </InviteContent>
            <InviteFooter>
              <Button color={"#000"} text={"보내기"} onClick={handleInvite} />
            </InviteFooter>
          </InviteWrapper>
        </Dialog>
      </Wrapper>
    );
  } else {
    return (
      <Wrapper>
        <BaseHeader
          text={"모집 중"}
          isBack={true}
          isClose={false}
          isCenter={true}
          isMenu={true}
        />
        <Helmet>
          <title>모집 중 | {host.username}의 오픈방</title>
        </Helmet>
        {uploading && <Loader />}
  

        <ContentWrapper>
          {/* <Header>
              <HeaderColumn grow={1}>Lv.{level}</HeaderColumn>
              <VerticalDivider className={classes.hr} orientation="vertical" />
              <HeaderColumn grow={1}>회원 {userCount}명</HeaderColumn>
              <VerticalDivider className={classes.hr} orientation="vertical" />
            </Header> */}
          <Card className={classes.card}>
            {picture.length > 0 ? (
              <Gallery items={picture} />
            ) : (
              <CardMedia
                className={classes.media}
                image={
                  "/imgs/openroom_basic_thumbnail.jpg"
                }
                title="Paella dish"
              />
            )}
          </Card>

          <MyTabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="inherit"
            variant="fullWidth"
            aria-label="tabs"
          >
            <Tab
              className={classes.tabRoot}
              label="오픈방 정보"
              {...a11yProps(0)}
            />
            <Tab
              className={classes.tabRoot}
              label="경기장 정보"
              {...a11yProps(1)}
            />
          </MyTabs>

          <SwipeableViews
            className={classes.SwipeView}
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={value}
            onChangeIndex={handleChangeIndex}
            disabled={true}
          >
            <MyTabPanel value={value} index={0}>
              <Fab
                type={"inviteText"}
                top={"50px"}
                right={"3px"}
                onClick={handleSearchOpen}
              />

              <GameInfoWrap>
                <GameInfoColumn>
                  <BoldText text={moment(gameDate).format("YYYY. MM. DD")} />
                  {_gameWeekText(gameDate)}
                </GameInfoColumn>
                <GameInfoColumn>
                  <BoldText text={`${startTime} ~ ${endTime}`} />
                </GameInfoColumn>
              </GameInfoWrap>
              <Grid className={classes.hostList} item xs={12}>
                <List dense>
                  <Link key={host.id} to={`/user/${host.id}`}>
                    <ListItem button>
                      <ListItemAvatar>
                        <Avatar size={"sm"} url={host.avatar} isHost={true} />
                      </ListItemAvatar>
                      <ListItemText primary={<HostSpan>호스트 {host.username}</HostSpan>} />
                      <ListItemSecondaryAction>
                        <IconButton
                          edge="end"
                          color="inherit"
                          aria-label="detail"
                        >
                          <Arrow />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  </Link>
                </List>
              </Grid>

              <Title>{gameTitle}</Title>
              <Message>
                {gameBio.split("\n").map((line, i) => {
                  return (
                    <span key={i}>
                      {line}
                      <br />
                    </span>
                  );
                })}
              </Message>
            </MyTabPanel>

            <MyTabPanel
              className={"mapWrapper"}
              value={value}
              index={1}
            >
              {value === 1 && mapInit && place && (
                <Map
                  isInit={value}
                  address={place.address}
                  placeName={place.placeName}
                  lat={place.location.latitude}
                  lng={place.location.longitude}
                ></Map>
              )}
            </MyTabPanel>
          </SwipeableViews>

          {!isSendRequest ? (
            <Button text={"참가 신청"} color={"#000"} onClick={onRequest} />
          ) : (
            <Button
              text={"참가 취소"}
              color={"#000"}
              onClick={handleCancelOpen}
            />
          )}
        </ContentWrapper>

        <Dialog
          className={classes.inviteDialog}
          open={openCancel}
          onClose={handleCancelClose}
          TransitionComponent={Transition}
        >
          <InviteWrapper>
            <InviteHeader>
              <IconButton
                edge="end"
                color="inherit"
                onClick={handleCancelClose}
                aria-label="close"
              >
                <Close fill={"#000"} />
              </IconButton>
            </InviteHeader>
            <InviteContent>
              <div>
                참가 신청을 취소하시겠습니까?
                <br />
                결제가 진행된 경우 패널티를 받을 수 있습니다.
              </div>
            </InviteContent>
            <InviteFooter>
              <Button
                color={"#000"}
                text={"참가 취소하기"}
                onClick={onCancel}
              />
            </InviteFooter>
          </InviteWrapper>
        </Dialog>

        <Dialog
          className={classes.dialog}
          fullScreen
          open={openSearch}
          onClose={handleSearchClose}
          TransitionComponent={Transition}
        >
          <AppBar className={classes.appBar}>
            <Toolbar className={classes.toolBar}>
              <IconButton
                edge="end"
                color="inherit"
                onClick={handleSearchClose}
                aria-label="close"
              >
                <Close />
              </IconButton>
              <div className={classes.search}>
                <div className={classes.searchIcon}>
                  <SearchIcon />
                </div>
                <InputBase
                  placeholder="회원을 검색하세요"
                  classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput
                  }}
                  inputProps={{ "aria-label": "search" }}
                  value={search.value}
                  onChange={search.onChange}
                />
              </div>
            </Toolbar>
          </AppBar>

          <SearchWrapper>
            <List dense>
              {searching && <CircularProgress />}
              {searchUsers &&
                searchUsers.length > 0 &&
                searchUsers.map(user => (
                  <ListItem key={user.id}>
                    <ListItemAvatar>
                      <Avatar size={"sm"} url={user.avatar} />
                    </ListItemAvatar>
                    <ListItemText
                      primary={user.username}
                      secondary={`Lv. ${user.basket ? user.basket.level : "0"}`}
                    />
                    <InviteListButton onClick={handleInviteOpen(user)}>
                      + 초대
                    </InviteListButton>
                  </ListItem>
                ))}
            </List>
          </SearchWrapper>
        </Dialog>

        <Dialog
          className={classes.inviteDialog}
          open={openInvite}
          onClose={handleInviteClose}
          TransitionComponent={Transition}
        >
          <InviteWrapper>
            <InviteHeader>
              <IconButton
                edge="end"
                color="inherit"
                onClick={handleInviteClose}
                aria-label="close"
              >
                <Close fill={"#000"} />
              </IconButton>
            </InviteHeader>
            <InviteContent>
              <Party />
              <div>
                {inviteUser && inviteUser.username}님에게 초대장을
                <br />
                보내시겠습니까?
              </div>
            </InviteContent>
            <InviteFooter>
              <Button color={"#000"} text={"보내기"} onClick={handleInvite} />
            </InviteFooter>
          </InviteWrapper>
        </Dialog>
      </Wrapper>
    );
  }

  
}



export default RECRUIT;
