import { gql } from "apollo-boost";

export const SEE_TEAMS = gql`
  query me {
    me {
      id
      userTeams {
        id
        teamName
        logo
        hasAuthority
        level
      }
    }
  }
`;



