import { gql } from "apollo-boost";

export const GET_ROOM = gql`
  query seeFullRoom($id: String!) {
    seeFullRoom(id: $id) {
      id
      gameDate
      startTime
      endTime
      gameStatus
      playerCnt
      gameTitle
      gameBio
      price
      purpose
      picture {
        id
        url
      }
      host {
        id
        avatar
        username
        level
        basket {
          id
          position
          backNo
        }
      }
      users {
        id
        avatar
        username
        level
        basket {
          id
          position
          backNo
        }
      }
      reqUsers {
        id
        avatar
        username
        level
        basket {
          id
          position
          backNo
        }
      }
      inviteUsers {
        id
        avatar
        username
        level
        basket {
          id
          position
          backNo
        }
      }
      isRequest
      isUser
      isHost
      place {
        id
        placeName
        address
        location {
          id
          latitude
          longitude
        }
      }
    }
    me {
      id
    }
  }
`;

export const EDIT_ROOM = gql`
  mutation editRoom($id: String!, $action: String!) {
    editRoom(id: $id, action: $action) {
      id
    }
  }
`;


export const UPSERT_VOTE = gql`
  mutation upsertVote($gameId:String!, $teamId:String!, $answer:String!) {
    upsertVote(gameId:$gameId, teamId:$teamId, answer:$answer) {
      id
      user {
        id
        avatar
        username
        level
      }
      answer
    }
  }
`;

export const SEARCH_USER = gql`
  query searchUser($term:String!) {
    searchUser(term:$term) {
      id
      avatar
      username
      nickname
      basket {
        id
        level
        grade
      }
      itsMe
    }
  }
`;

export const REQUEST_ROOM = gql`
  mutation reqRoom($id: String!) {
    reqRoom(id: $id)
  }
`;

export const CANCEL_ROOM = gql`
  mutation cancelUserRoom($id: String!) {
    cancelUserRoom(id: $id)
  }
`;

export const INVITE_USER = gql`
  mutation inviteUserToRoom($id: String!, $userId: String!) {
    inviteUserToRoom(id: $id, userId: $userId)
  }
`;
