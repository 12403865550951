import { gql } from "apollo-boost";

export const GET_TEAM = gql`
  query seeFullTeam($id: String!) {
    seeFullTeam(id: $id) {
      id
      teamName
      isMine
      isHost
      isManager
      requestUsers {
        id
        user {
          id
          avatar
          username
          level
          basket {
            id
            backNo
            position
          }
        }
      }
      inviteUsers {
        id
        avatar
        username
        level
        basket {
          id
          backNo
          position
        }
      }
    }
    me {
      id
      isSomeHost
    }
  }
`;

export const SEARCH_USER = gql`
  query searchUserForTeam($term:String!, $teamId:String!) {
    searchUserForTeam(term:$term, teamId:$teamId) {
      id
      avatar
      username
      basket {
        id
        level
        backNo
        position
      }
      itsMe
    }
  }
`;

export const INVITE_USER = gql`
  mutation inviteUser($id: String!, $userId: String!) {
    inviteUser(id: $id, userId: $userId)
  }
`;

export const ACCEPT_TEAM = gql`
  mutation acceptTeam($id: String!,$requestId:String!, $reqUserId: String!) {
    acceptTeam(id: $id, requestId:$requestId, reqUserId: $reqUserId)
  }
`;
export const DECLINE_TEAM = gql`
  mutation declineTeam($id: String!, $requestId:String!, $reqUserId: String!, $declineMessage: String!) {
    declineTeam(id: $id, requestId:$requestId, reqUserId: $reqUserId, declineMessage: $declineMessage)
  }
`;


export const NEW_INFO = gql`
  subscription subscriptionTeam($id:String!) {
    subscriptionTeam(id:$id) {
      id
      requestUsers {
        id
        user {
          id
          avatar
          username
          level
          basket {
            id
            backNo
            position
          }
        }
      }
      inviteUsers {
        id
        avatar
        username
        level
        basket {
          id
          backNo
          position
        }
      }
    }
  }
`;