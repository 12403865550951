import React, { useState } from "react";
import {
  ACCEPT_MATCH,
  DECLINE_MATCH
} from "./MatchProfileQueries";
import MatchProfilePresenter from "./MatchProfilePresenter";
import { useMutation } from "react-apollo-hooks";
import { toast } from "../../Components/Popup";
import { withRouter } from "react-router-dom";
import useInput from "../../Hooks/useInput";
import { SEE_RECEIVES } from "../GameManage/GameManageQueries";

const MatchProfileContainer = ({ matchInfo ,teamId, onBack, handleAgree, handleDecline, history }) => {
  const [isSend, setIsSend] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const replyInput = useInput("죄송합니다.\n다른 팀과 매칭이 잡혔습니다.")

  const acceptMatchMutation = useMutation(ACCEPT_MATCH);
  const declineMatchMutation = useMutation(DECLINE_MATCH);

  const onClickAgree = async () => {
    setIsSend(true);
    setIsLoading(true);
    try {
      const { data : { acceptMatch } } = await acceptMatchMutation({
        variables: {
          id:matchInfo.game.id,
          matchId: matchInfo.id,
          awayId: matchInfo.sender.id,
          action:"MATCH"
        }
      });

      if(acceptMatch.id) {
        handleAgree(matchInfo.id);
        toast.success("해당 도전장을 수락했습니다.");
      } else {
        toast.error("오류가 발생했습니다.");
        setIsSend(false);
      }

    } catch (e) {
      if(e.message.indexOf("MATCH") !== -1) {
        toast.error("이미 매칭된 경기입니다.");
      } else if(e.message.indexOf("CANCEL") !== -1) {
        toast.error("해당 경기는 취소되었습니다.");
      } else {
        toast.error("오류가 발생했습니다.");
      }
      
      setIsSend(false);
    } finally {
      setIsLoading(false);
    }
  };

  const onClickDecline = async (message) => {
    if(message === "") {
      toast.error("거부 사유를 작성해주세요.");
      return;
    }

    setIsSend(true);
    setIsLoading(true);

    try {
      const { data : { declineMatch } } = await declineMatchMutation({
        variables: {
          id: matchInfo.id,
          reply: message
        }
      });

      if(declineMatch.id) {
        handleDecline(matchInfo.id);
        toast.info("해당 도전장을 거부했습니다.");
        history.replace(`/team/${teamId}/match?view=${matchInfo.id}&type=receiver`);
      } else {
        toast.error("오류가 발생했습니다.");
        setIsSend(false);
      }
      
    } catch (e) {
      toast.error("오류가 발생했습니다.");
      setIsSend(false);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <MatchProfilePresenter
      history= {history}
      matchInfo={matchInfo}
      onBack={onBack}
      isSend={isSend}
      teamId={teamId}
      isLoading={isLoading}
      replyInput={replyInput}
      onClickAgree={onClickAgree}
      onClickDecline={onClickDecline}
    />
  );
};

export default withRouter(MatchProfileContainer);
