import React, { useState } from "react";
import styled from "styled-components";
import { Helmet } from "rl-react-helmet";
import Avatar from "../../Components/Avatar";
import SwipeableViews from "react-swipeable-views";
import BoldText from "../../Components/BoldText";
import Map from "../../Components/Map";
import BaseHeader from "../../Components/Header/BaseHeader";
import { Info, Coin } from "../../Components/Icons";
import ScoreInput from "../../Components/ScoreInput";

import { Link } from "react-router-dom";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import moment from "moment";
import "moment/locale/ko";

const Wrapper = styled.div``;

const ContentWrapper = styled.div`
  padding-top: 56px;
  /* margin: auto 10px; */
  background-color: ${props => props.theme.innerBgColor};
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
`;

const GameHeader = styled.header`
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  width: 60%;
  margin: 0 auto;
  padding-top: 10px;
  @media (max-width: 768px) {
    width: 95%;
  }
`;

const GameHeaderColumn = styled.div`
  padding: 7px;
  font-size: 16px;
  text-align: center;
  padding-top: 20px;
  &:nth-child(2) {
    padding-top: 0px;
    align-self: center;
  }
`;

const ColumnWrap = styled.div`
  position: relative;
`;

const ColumnRow = styled.div`
  font-size: 15px;
  margin: 5px 0px;
  position: relative;
  padding: 0px 10px;
  max-width: 130px;
`;

const VSText = styled.span`
  font-size: 20px;
`;

const CrownImg = styled.img`
  width: 40px;
`;

const HeaderOrder = styled.div`
  font-size: 16px;
  font-weight: 800;
  color: #ffe221;
  /* font-family: "Jalnan"; */
  display: flex;
  justify-content: center;
  align-items: center;
  & > svg {
    margin-right: 7px;
  }
`;

const HeaderAvatar = styled.div`
  border: 1px solid #fff;
  border-radius: 50%;
  padding: 6px;
  min-width: 99px;
  min-height: 99px;
  width: 99px;
`;

const WinnerHeaderAvatar = styled(HeaderAvatar)`
  border: 1px solid #227fff;
`;

const GameInfoWrap = styled.div`
  padding: 8px 16px 0px;
  display: flex;
  align-items: center;
  min-height: 50px;
  justify-content: space-evenly;
`;
const GameInfoColumn = styled.div``;

const ResultWrap = styled.div`
  height: ${props => (props.canEnterScore ? "calc(100% - 80px)" : "100%")};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  max-height: 400px;
  max-width: 400px;
  margin: 0 auto;
  /* padding-bottom: 60px; */
`;

const ResultRow = styled.div`
  margin-bottom: 5px;
  width: 100%;
`;

const MVPRow = styled.div`
  background: linear-gradient(110deg, #ffd421 0%, #ffd421 50%, transparent 32%),
    linear-gradient(-70deg, #ffbe21 0%, #ffbe21 50%, transparent 27%);
  border-radius: 10px;
  color: #000;
  width: 100%;
`;

const MVPRowContent = styled.div`
  font-size: 15px;
  color: #000;
  height: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const MVPUserInfo = styled.div`
  width: 49%;
  padding-left: 23px;
  font-weight: 500;
  & > span:nth-child(3) {
    font-size: 13px;
    margin-left: 15px;
    padding-right: 5px;
  }
`;

const MVPUserName = styled.div`
  width: 49%;
  padding-left: 5px;
  font-size: 21px;
  display: flex;
  align-items: center;

  & > img {
    width: 60px;
    margin-right: 15px;
  }
  & > span {
    font-weight: 800;
    padding-right: 15px;
  }
`;

const ProfileIcon = styled.span`
  position: relative;
  right: -5px;
  top: 3px;
`;

const BigColumnRow = styled(ColumnRow)`
  position: absolute;
  width: 120px;
  top: -22px;
  z-index: 999;
`;

const MyTabs = styled(Tabs)`
  color: #fff;
  padding: 5px 25px 0px;
  max-width: 500px;
  width: 100%;
  margin: 0 auto;
  & button:not(.Mui-selected) {
    z-index: 0;
  }
  .MuiTabs-flexContainer {
    background-color: #040f20;
    border-radius: 20px;
  }
  .MuiTabs-indicator {
    top: 0px;
    height: 4px;
    border-radius: 20px;
    height: 38px;
    background-color: ${props => props.theme.blueColor}!important;
  }
`;

const MyTabPanel = styled(TabPanel)`
  .MuiListItemText-secondary {
    color: #000;
  }
  & {
    /* padding-bottom: 47px; */
  }
`;

const useStyles = makeStyles(theme => ({
  card: {
    borderRadius: 0,
    backgroundColor: "transparent"
  },
  media: {
    height: 0,
    paddingTop: "56.25%" // 16:9
  },
  tabRoot: {
    fontSize: 16,
    fontFamily: "NanumSqure",
    zIndex: 1,
    minHeight: "38px"
  },
  userWrap: {
    // maxHeight: "400px",
    maxWidth: "600px",
    margin: "0 auto",
    width: "100%",
    color: "#000",
    overflowY: "auto",
    backgroundColor: "#fff",
    "& ul.MuiList-padding": {
      padding: 0,
      paddingBottom: 74,
      backgroundColor: "#fff"
    }
  },
  scoreWrap: {
    padding: "0px 15px;"
  },
  SwipeView: {
    flex: 1,
    width: "100%",
    maxWidth: "924px",
    display: "flex",
    flexDirection: "column",
    margin: "0 auto",
    "& > .react-swipeable-view-container": {
      flex: 1
    }
  },
  boxRoot: {
    height: "100%",
    display: "flex",
    flexDirection: "column"
  },
  panelRoot: {
    height: "100%"
  }
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  const classes = useStyles();

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      // {...other}
      className={classes.panelRoot}
    >
      <Box className={classes.boxRoot} p={0}>
        {children}
      </Box>
    </Typography>
  );
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`
  };
}

const _gameWeekText = gameDate => {
  let week = ["일", "월", "화", "수", "목", "금", "토"];
  let date = new Date(gameDate);
  return `(${week[date.getDay()]})`;
};

function COMPLETE(props) {
  const {
    homeScore,
    awayScore,
    setHomeScore,
    setAwayScore,
    data: {
      seeFullGame: {
        gameDate,
        startTime,
        endTime,
        homeTeam,
        awayTeam,
        winner,
        place,
        usr_MVP,
        participants
      }
    }
  } = props;

  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = useState(0);
  const [mapInit, setMapInit] = useState(false);

  const MVPTeam = usr_MVP
    ? participants.filter(i => i.user.id === usr_MVP.id)
    : null;

  const handleChange = (event, newValue) => {
    // if (!mapInit && window.kakao.mymap && document.getElementById("map")) {
    //   document
    //     .getElementsByClassName("mapWrapper")[0]
    //     .appendChild(document.getElementById("map").parentElement);
    // }
    setMapInit(true);
    setValue(newValue);
  };

  const handleChangeIndex = index => {
    setValue(index);
  };

  return (
    <Wrapper>
      <BaseHeader
        text={"경기 종료"}
        isBack={true}
        isClose={false}
        isMenu={true}
      />
      <Helmet>
        <title>경기 종료 | WePlay</title>
      </Helmet>

      <ContentWrapper>
        <GameHeader>
          <GameHeaderColumn>
            <ColumnWrap>
              {winner && winner === homeTeam.id && (
                <BigColumnRow>
                  <CrownImg src="https://d1jozj1mdbjtd9.cloudfront.net/crown.png" />
                </BigColumnRow>
              )}
              <ColumnRow>
                <Link to={`/team/${homeTeam.id}`}>
                  {winner && winner === homeTeam.id ? (
                    <WinnerHeaderAvatar>
                      <Avatar url={homeTeam.logo} size={"lmd"} />
                    </WinnerHeaderAvatar>
                  ) : (
                    <HeaderAvatar>
                      <Avatar url={homeTeam.logo} size={"lmd"} />
                    </HeaderAvatar>
                  )}
                </Link>
              </ColumnRow>
              <ColumnRow>
                <Link to={`/team/${homeTeam.id}`}>
                  <BoldText text={homeTeam.teamName} />
                  <ProfileIcon>
                    <Info size={16} />
                  </ProfileIcon>
                </Link>
              </ColumnRow>
              <ColumnRow>
                <HeaderOrder>
                  <Coin size={22} />
                  {winner && winner === homeTeam.id ? 10 : 5}
                </HeaderOrder>
              </ColumnRow>
            </ColumnWrap>
          </GameHeaderColumn>
          <GameHeaderColumn>
            <ColumnWrap>
              <ColumnRow>
                <VSText>VS</VSText>
              </ColumnRow>
            </ColumnWrap>
          </GameHeaderColumn>
          <GameHeaderColumn>
            <ColumnWrap>
              {winner && winner === awayTeam.id && (
                <BigColumnRow>
                  <CrownImg src="https://d1jozj1mdbjtd9.cloudfront.net/crown.png" />
                </BigColumnRow>
              )}
              <ColumnRow>
                <Link to={`/team/${awayTeam.id}`}>
                  {winner && winner === awayTeam.id ? (
                    <WinnerHeaderAvatar>
                      <Avatar url={awayTeam.logo} size={"lmd"} />
                    </WinnerHeaderAvatar>
                  ) : (
                    <HeaderAvatar>
                      <Avatar url={awayTeam.logo} size={"lmd"} />
                    </HeaderAvatar>
                  )}
                </Link>
              </ColumnRow>
              <ColumnRow>
                <Link to={`/team/${awayTeam.id}`}>
                  <BoldText text={awayTeam.teamName} />
                  <ProfileIcon>
                    <Info size={16} />
                  </ProfileIcon>
                </Link>
              </ColumnRow>
              <ColumnRow>
                <HeaderOrder>
                  <Coin size={20} />
                  {winner && winner === awayTeam.id ? 10 : 5}
                </HeaderOrder>
              </ColumnRow>
            </ColumnWrap>
          </GameHeaderColumn>
        </GameHeader>

        <MyTabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="inherit"
          variant="fullWidth"
          aria-label="tabs"
        >
          <Tab
            className={classes.tabRoot}
            label="경기 정보"
            {...a11yProps(0)}
          />
          <Tab
            className={classes.tabRoot}
            label="경기장 정보"
            {...a11yProps(1)}
          />
        </MyTabs>

        <SwipeableViews
          className={classes.SwipeView}
          axis={"x"}
          index={value}
          onChangeIndex={handleChangeIndex}
          disabled={true}
        >
          <MyTabPanel value={value} index={0}>
            <GameInfoWrap>
              <GameInfoColumn>
                <BoldText text={moment(gameDate).format("YYYY. MM. DD")} />
                {_gameWeekText(gameDate)}
              </GameInfoColumn>
              <GameInfoColumn>
                <BoldText text={`${startTime} ~ ${endTime}`} />
              </GameInfoColumn>
            </GameInfoWrap>
            <Grid className={classes.scoreWrap} item xs={12}>
              <ResultWrap canEnterScore={false}>
                {usr_MVP && (
                  <MVPRow>
                    <Link to={`/user/${usr_MVP.id}`}>
                      <MVPRowContent>
                        <MVPUserName>
                          <img src="https://d1jozj1mdbjtd9.cloudfront.net/mvp_icon.png" alt="MVP Icon" />
                          <span>{usr_MVP.username}</span>
                        </MVPUserName>
                        <MVPUserInfo>
                          <p>팀:{MVPTeam[0].team.teamName}</p>
                          <span>{usr_MVP.basket.position}</span>
                          <span>등번호</span>
                          <span>{usr_MVP.basket.backNo}</span>
                        </MVPUserInfo>
                      </MVPRowContent>
                    </Link>
                  </MVPRow>
                )}

                <ResultRow>
                  <ScoreInput
                    index={0}
                    homeScore={homeScore}
                    awayScore={awayScore}
                    setHomeScore={setHomeScore}
                    setAwayScore={setAwayScore}
                    endGame={true}
                  />
                </ResultRow>
                <ResultRow>
                  <ScoreInput
                    index={1}
                    homeScore={homeScore}
                    awayScore={awayScore}
                    setHomeScore={setHomeScore}
                    setAwayScore={setAwayScore}
                    endGame={true}
                  />
                </ResultRow>
                <ResultRow>
                  <ScoreInput
                    index={2}
                    homeScore={homeScore}
                    awayScore={awayScore}
                    setHomeScore={setHomeScore}
                    setAwayScore={setAwayScore}
                    endGame={true}
                  />
                </ResultRow>
              </ResultWrap>
            </Grid>
          </MyTabPanel>

          <MyTabPanel
            className={"mapWrapper"}
            value={value}
            index={1}
          >
            {value === 1 && mapInit && place && (
              <Map
                isInit={value}
                address={place.address}
                placeName={place.placeName}
                lat={place.location.latitude}
                lng={place.location.longitude}
              ></Map>
            )}
          </MyTabPanel>
        </SwipeableViews>
      </ContentWrapper>
    </Wrapper>
  );
}

export default COMPLETE;
