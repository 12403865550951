import { gql } from "apollo-boost";

export const SEE_TEAMS = gql`
  query seeCupTeams($first: Int, $skip: Int, $cupId: String!) {
    seeCupTeams(first: $first, skip: $skip, cupId: $cupId) {
      id
      logo
      agent
      teamName
      win
      draw
      lose
      host {
        id
        username
        email
      }
      managers {
        id
        username
        email
      }
      users {
        id
        username
        email
      }
      place {
        id
        placeName
        address
      }
      level
      sport {
        id
        code
        description
      }
      contact
    }
  }
`;