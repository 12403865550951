import React from "react";
import styled from "styled-components";
import { Helmet } from "rl-react-helmet";
import Loader from "../../Components/Loader";
import CupHeader from "../../Components/CupHeader";
import moment from "moment";
import { Link } from "react-router-dom";
const Wrapper = styled.div``;

const ContentWrapper = styled.div`
  color: #000;
`;

// const CrownImg = styled.img`
//   width: 60px;
// `;

const Section = styled.div`
  overflow: hidden;
  max-width: 1024px;
  margin: 0 auto;
  width: 95%;
  padding: 20px 50px 20px;
  background-color: #fff;
  color: #000;
  @media (max-width: 768px) {
    padding: 20px 5px 20px;
  }
  min-height: calc(100vh - 360px);
`;

const ListTitle = styled.div`
  background-color: #25374d;
  color: #fff;
  font-size: 25px;
  padding: 10px 20px;
  width: 100%;
  margin-bottom: 20px;
  margin-top: 20px;
`;

const HistoryList = styled.li`
  list-style: none;
  width: 100%;
  background-color: #fff;
  border-bottom: 1px solid #000;
  padding: 15px 15px;
  cursor: pointer;
  overflow: auto;
  display: flex;
  align-self: center;
  justify-content: space-between;
  @media (max-width: 768px) {
    flex-direction: column;
  }
  & > .MuiDivider-vertical {
    height: 25px !important;
    margin: 0px 25px !important;
    background-color: rgba(0, 0, 0, 0.92) !important;
  }
`;

const ListHeader = styled.div`
  display: flex;
  align-items: center;
`;

const TeamName = styled.span`
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  position: relative;
  display: flex;
  align-items: center;
`;

const TeamInfo = styled.span`
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  display: flex;
  align-items: center;
`;

const AllGrade = styled(TeamInfo)`
  font-size: 15px;
  font-weight: 200;
`;
const BackArrow = styled.div`
  font-size: 16px;
  padding: 0px 10px;
`;

const TextWrap = styled.div`
  padding: 40px 30px 80px;
  line-height: 2em;
  & > img {
    max-width:100%;
    height:auto!important;
  }
  @media (max-width: 768px) {
    padding: 20px 10px 50px;
  }
`;

export default ({ loading, data, cupId }) => {
  if (loading) {
    return (
      <Wrapper>
        <CupHeader id={cupId} active={"notice"} />
        <Loader />
      </Wrapper>
    );
  } else if (!loading && data && data.seeFullNotice) {
    const {
      seeFullNotice: { title, text, createdAt }
    } = data;

    return (
      <Wrapper>
        <Helmet>
          <title>공지사항 | WePlay</title>
        </Helmet>
        <CupHeader id={cupId} active={"notice"} />
        <ContentWrapper>
          <Section>
            <BackArrow>
              <Link to={`/cup/${cupId}/notices`}>
                <span>{"< 뒤로"}</span>
              </Link>
            </BackArrow>
            <ListTitle>공지</ListTitle>
            <HistoryList>
              <ListHeader>
                <TeamName>{title}</TeamName>
              </ListHeader>

              <AllGrade>
                {moment(createdAt).format("YYYY년 MM월 DD일")}
              </AllGrade>
            </HistoryList>
            <TextWrap dangerouslySetInnerHTML={{ __html: text }}></TextWrap>
          </Section>
        </ContentWrapper>
      </Wrapper>
    );
  }
  return null;
};
