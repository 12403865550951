import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import {
  GET_GAME,
  UPDATE_GAME,
  SEE_VOTES,
  UPSERT_VOTE,
  SEARCH_USER,
  INVITE_USER,
  UPDATE_VOTE
} from "./GameVoteQueries";
import GameProfilePresenter from "./GameVotePresenter";
import { useQuery, useMutation, useSubscription } from "react-apollo-hooks";
import { toast } from "../../Components/Popup";
import useInput from "../../Hooks/useInput";
import Client from "../../Apollo/Client";

//const GameVoteContainer = ({ match: { params } }) => {
const GameVoteContainer = ({ onBack, match: { params } }) => {
  const gameId = params.game;
  const teamId = params.team;

  const ANSWER = ["AGREE", "DISAGREE"];
  const [GAMESTATUS, setGAMESTATUS] = useState("");
  const [refreshing, setRefreshing] = useState(false);
  const { data, loading, refetch:refetchGame } = useQuery(GET_GAME, {
    skip: false,
    variables: {
      id: gameId,
      teamId
    }
  });

  const { data: dataVotes, loading: loadingVotes, fetchMore, refetch } = useQuery(
    SEE_VOTES,
    {
      skip: false,
      variables: {
        id: gameId,
        teamId
      }
    }
  );

  const { data: updateGameData } = useSubscription(UPDATE_GAME, {
    variables: {
      id: gameId
    }
  });

  const { data: updateVoteData } = useSubscription(UPDATE_VOTE, {
    variables: {
      id: gameId
    }
  });

  const handleUpdateGame = () => {
    if (updateGameData !== undefined) {
      const { updateGame } = updateGameData;
      if (updateGame.gameStatus === "PLAYING") {
        toast.success("해당 경기가 시작되었습니다.");
      }
      setGAMESTATUS(updateGame.gameStatus);
    }
  };

  const onRefresh = () => {
    console.log("Refresh!!");
    setRefreshing(true);
    refetchGame();
    fetchMore({
      variables: {
        id: gameId,
        teamId
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        setRefreshing(false);

        console.log(fetchMoreResult.seeVotes);

        if (!fetchMoreResult) return prev;

        setVotes(fetchMoreResult.seeVotes);
        return Object.assign({}, prev, {
          seeVotes: [...fetchMoreResult.seeVotes]
        });
      }
    });
  };

  useEffect(() => {
    console.log("updateGameData ===> ", updateGameData);
    handleUpdateGame();
  }, [updateGameData]);

  useEffect(() => {
    console.log("updateVoteData ===> ", updateVoteData);
    if (updateVoteData !== undefined) {
      const { updateVote } = updateVoteData;
      if (votes.findIndex(i => i.id === updateVote.id) !== -1) {
        const data = [...votes];
        data[votes.findIndex(i => i.id === updateVote.id)].answer =
          updateVote.answer;
        setVotes(data);
      }

      Client.writeData({
        id: `Vote:${updateVote.id}`,
        data: {
          answer: updateVote.answer
        }
      });
    }
    //handleUpdateGame();
  }, [updateVoteData]);

  //////////////// 초대 및 검색 기능 추가 ///////////////////////

  const [applying, setApplying] = useState(false);
  const [searching, setSearching] = useState(false);
  const [selectedUser, setInviteUser] = useState();
  const [openSearch, setOpenSearch] = useState(false);
  const [openInvite, setOpenInvite] = useState(false);
  const [openShare, setOpenShare] = useState(false);

  const search = useInput("");
  const [searchUsers, setSearchUsers] = useState();
  const {
    loading: loadingSearch,
    data: searchData,
    refetch: refetchUser
  } = useQuery(SEARCH_USER, {
    skip: true,
    variables: {
      term: search.value,
      teamId
    }
  });

  const inviteMutation = useMutation(INVITE_USER);

  const handleInviteOpen = user => () => {
    setInviteUser(user);
    setOpenInvite(true);
  };

  const handleInviteClose = () => {
    setInviteUser(null);
    setOpenInvite(false);
  };

  const handleSearchOpen = () => {
    setOpenSearch(true);
  };

  const handleSearchClose = () => {
    setOpenSearch(false);
  };

  const handleShareOpen = () => {
    setOpenShare(true);
  };

  const handleShareClose = () => {
    setOpenShare(false);
  };

  useEffect(() => {
    if (search.value.length > 1) {
      setSearching(true);
    } else {
      setSearching(false);
    }
    refetchUser();

    if (!loadingSearch && searchData && searchData.searchUserForTeam) {
      setSearchUsers(searchData.searchUserForTeam);
      setSearching(false);
    }
  }, [search.value, loadingSearch, searchData]);

  const handleInvite = async () => {
    const data = [...searchUsers];
    data.splice(
      data.findIndex(i => i.id === selectedUser.id),
      1
    );
    setSearchUsers(data);

    handleInviteClose();

    try {
      setApplying(true);

      const {
        data: { inviteUser }
      } = await inviteMutation({
        variables: {
          id: teamId,
          userId: selectedUser.id
        }
      });

      console.log(inviteUser);

      if (inviteUser) {
        handleSearchClose();
        toast.success("초대를 전송했습니다.");
      }
    } catch (e) {
      //toast.error("오류가 발생했습니다.");
    } finally {
      setApplying(false);
    }
  };

  ///////////////////////////////////////////////////////////////////

  //const [uploading, setUploading] = useState(false);
  const [votes, setVotes] = useState();
  const [currentVotes, setCurrentVotes] = useState();
  const [currentTeam, setCurrentTeam] = useState(teamId);
  const [isVote, setIsVote] = useState(false);

  const voteMutation = useMutation(UPSERT_VOTE);

  // useEffect(() => {
  //   setSelfVote(data.seeVote);
  // }, [data, loading])

  const onClickVote = answer => async () => {
    try {
      setIsVote(true);

      if (
        votes.length > 0 &&
        votes.findIndex(i => i.user.id === data.me.id) !== -1
      ) {
        const prevVotes = [...votes];
        prevVotes[
          votes.findIndex(i => i.user.id === data.me.id)
        ].answer = ANSWER[answer].toString();
        setVotes(prevVotes);
      } else {
        const prevVotes = [...votes];
        const _vote = {
          user: data.me,
          answer: ANSWER[answer].toString()
        };
        prevVotes.push(_vote);
        setVotes(prevVotes);
      }
      //toast.success("투표 완료!");

      const {
        data: { upsertVote }
      } = await voteMutation({
        variables: {
          gameId,
          teamId,
          answer: ANSWER[answer].toString()
        }
      });

      if (upsertVote.id) {
        refetch();
      }
    } catch (e) {
      console.log(e);
      if (e.message.indexOf("match") !== -1) {
        toast.error(
          <div>
            해당 경기가 시작되었습니다.
            <br />
            새로고침해주세요
          </div>
        );
      } else {
        //toast.error("오류 발생! 다시 시도해주세요.");
      }
    } finally {
    }
  };

  return (
    <GameProfilePresenter
      dataVotes={dataVotes}
      loadingVotes={loadingVotes}
      loading={loading}
      onBack={onBack}
      onClickVote={onClickVote}
      data={data}
      teamId={teamId}

      refreshing={refreshing}
      onRefresh={onRefresh}

      votes={votes}
      setVotes={setVotes}
      currentVotes={currentVotes}
      setCurrentVotes={setCurrentVotes}
      currentTeam={currentTeam}
      setCurrentTeam={setCurrentTeam}
      isVote={isVote}
      setIsVote={setIsVote}
      GAMESTATUS={GAMESTATUS}
      setGAMESTATUS={setGAMESTATUS}
      selectedUser={selectedUser}
      openSearch={openSearch}
      openInvite={openInvite}
      openShare={openShare}
      handleShareOpen={handleShareOpen}
      handleShareClose={handleShareClose}
      handleInviteOpen={handleInviteOpen}
      handleInviteClose={handleInviteClose}
      handleSearchOpen={handleSearchOpen}
      handleSearchClose={handleSearchClose}
      handleInvite={handleInvite}
      searchUsers={searchUsers}
      search={search}
      searching={searching}
      applying={applying}
    />
  );
};

export default withRouter(GameVoteContainer);
