import React, { useEffect } from "react";
import styled from "styled-components";
import { Helmet } from "rl-react-helmet";
import Loader from "../../Components/Loader";
import Avatar from "../../Components/Avatar";

import { Arrow, Close } from "../../Components/Icons";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import BaseHeader from "../../Components/Header/BaseHeader";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";

import IconButton from "@material-ui/core/IconButton";

import { Badge, CircularProgress, Dialog, Slide } from "@material-ui/core";

import Grid from "@material-ui/core/Grid";
import moment from "moment";
import "moment/locale/ko";
import { Link } from "react-router-dom";

import PullToRefresh from "rmc-pull-to-refresh";
import RefreshLoader from "../../Components/RefreshLoader";
import Client from "../../Apollo/Client";
import DedicateLoader from "../../Components/DedicateLoader";

const useStyles = makeStyles(theme => ({
  badge: {
    right: 0,
    top: -20
  }
}));

const Wrapper = styled.div`
  background-color: #fff;
  min-height: 100vh;
`;

const ContentWrapper = styled.div`
  /* padding-top: 56px; */
  margin: auto 10px;
  background-color: #fff;
  /* min-height: 100vh;
  max-height: 100vh; */
  /* overflow: auto; */
  display: flex;
  flex-direction: column;
  color: #000;
  max-width: 768px;
  margin: 0 auto;
`;

const Container = styled.div`
  margin-bottom: 15px;
  & li.MuiListItem-container {
    margin: 0 10px;
    border-bottom: 1px solid #999;
    & > .MuiListItem-gutters {
      padding-left: 8px !important;
    }
  }
`;
const TitleWrap = styled.div`
  flex: 1 1 auto;
  min-width: 0;
  margin-top: 6px;
  margin-bottom: 6px;
`;

const TeamName = styled.span`
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  padding-bottom: 7px;
  position: relative;
  & > span {
    /* word-break: keep-all; */
    word-break: break-all;
  }
`;

const LoaderWapeer = styled.div`
  width: 100%;
  min-height: 60px;
  background-color: #fff;

  & > .MuiCircularProgress-colorPrimary {
    position: relative;
    top: 5px;
    z-index: 999;
    width: 35px !important;
    height: 35px !important;
    color: #1ea9e1;
  }
`;

const EmptySearchResult = styled.div`
  margin: 0px 15px;
  padding: 15px 0px;
  color: #969696;
  font-size: 14px;
  border-bottom: 1px solid #d5d5d5;
`;

const NotificationEditWrapper = styled.div`
  background-color: #f2f2f2;
  border-radius: 5px;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  height: 40px;
  align-items: center;
  width: 200px;
`;
const NotificationEditButton = styled.button`
  background-color: #f2f2f2;
  border-radius: 5px;
  border: 1px solid #f2f2f2;
  height: 100%;
  font-size: 16px;
  width: 100%;
  user-select: none;
`;

// const Title = styled.p`
//   color: rgba(0, 0, 0);
//   font-size: 0.875rem;
//   font-family: "NanumSqure";
//   font-weight: 400;
//   line-height: 1.43;
//   letter-spacing: 0.01071em;
//   white-space: nowrap;
//   text-overflow: ellipsis;
//   overflow-x: hidden;
// `;

const SmallDateInfo = styled.div`
  color: #000;
  font-size: 11px;
`;

export default ({
  loading,
  data,
  onGoHome,
  notifications,
  setNotifications,
  loadMore,
  canMore,
  onScroll,
  LongPress,
  openDelete,
  handleCloseDelete,
  handleDeleteNotification,
  setSelectedNoti,

  onRefresh,
  refreshing,
  setRefreshing
}) => {
  if (loading) {
    return (
      <Wrapper>
        <BaseHeader text={"알림"} isBack={true} isClose={false} />
        <Helmet>
          <title>알림 | WePlay</title>
        </Helmet>
        <ContentWrapper bgColor={"#fff"}></ContentWrapper>
        <Loader />
      </Wrapper>
    );
  } else if (!loading && data && data.seeNotifications) {
    const classes = useStyles();

    useEffect(() => {
      setNotifications(data.seeNotifications);
    }, []);


    const handleEnter = noti => () => {
      //console.log(chat);
      const datas = [...notifications];
      noti.read = true;
      datas[notifications.findIndex(i => i.id === noti.id)] = noti;
      setNotifications(datas);

      Client.writeData({
        id:`Notification:${noti.id}`,
        data : {
          read:true
        }
      })
    };

    return (
      <Wrapper>
        <BaseHeader
          text={"알림"}
          isBack={true}
          isClose={false}
          onClickBack={onGoHome}
        />

        <Helmet>
          <title>알림 | WePlay</title>
        </Helmet>


        <PullToRefresh
          style={{ overflow: "auto", paddingTop:56, minHeight:"100vh", maxHeight:"100vh"}}
          direction="down"
          //distanceToRefresh={50}
          damping={100}
          onRefresh={() => onRefresh()}
          refreshing={refreshing}
          onScroll={e => onScroll(e)}
          indicator={{
            activate: <DedicateLoader />,
            deactivate: <></>,
            release: <RefreshLoader />,
            finish: <></>
          }}
        >
        <ContentWrapper bgColor={"#fff"} >
          <Grid item xs={12} md={12}>

              <List>
                <Container>
                  {notifications.length === 0 ? (
                    <EmptySearchResult>
                      도착한 알림이 없습니다.
                    </EmptySearchResult>
                  ) : (
                    notifications.length > 0 &&
                    notifications.map(noti => (
                      <Link
                        key={noti.id}
                        to={noti.link}
                        onClick={handleEnter(noti)}
                      >
                        <ListItem
                          key={noti.id}
                          button
                          // onMouseDown={() => {
                          //   setSelectedNoti(noti.id);
                          //   LongPress.onMouseDown();
                          // }}
                          // onMouseUp={() => {
                          //   LongPress.onMouseUp();
                          // }}
                          // onMouseLeave={() => {
                          //   LongPress.onMouseLeave();
                          // }}
                          // onTouchStart={() => {
                          //   setSelectedNoti(noti.id);
                          //   LongPress.onTouchStart();
                          // }}
                          // onTouchEnd={() => {
                          //   LongPress.onTouchEnd();
                          // }}

                          // {...LongPress}
                          //className={classes.titleWrapper}
                        >
                          {noti.team && (
                            <ListItemAvatar>
                              <Avatar size={"sm"} url={noti.team.logo} />
                            </ListItemAvatar>
                          )}

                          {noti.oppUser && (
                            <ListItemAvatar>
                              <Avatar size={"sm"} url={noti.oppUser.avatar} />
                            </ListItemAvatar>
                          )}

                          <TitleWrap>
                            <TeamName>
                              {noti.message.split("/&/").map((line, i) => {
                                return (
                                  <span key={i}>
                                    {line}
                                    <br />
                                  </span>
                                );
                              })}
                            </TeamName>
                            <SmallDateInfo>
                              {moment(noti.createdAt).format(
                                "YYYY.MM.DD Ahh:mm"
                              )}
                            </SmallDateInfo>
                          </TitleWrap>

                          <ListItemSecondaryAction>
                            {noti.read === false && (
                              <Badge
                                className={classes.badge}
                                badgeContent={"New"}
                                max={10}
                                color="secondary"
                              ></Badge>
                            )}

                            <IconButton
                              edge="end"
                              color="inherit"
                              aria-label="detail"
                            >
                              <Arrow />
                            </IconButton>
                          </ListItemSecondaryAction>
                        </ListItem>
                      </Link>
                    ))
                  )}
                </Container>
              </List>
            
          </Grid>
          {canMore && (
            <LoaderWapeer>{loadMore && <CircularProgress />}</LoaderWapeer>
          )}
        </ContentWrapper>
        </PullToRefresh>

        <Dialog
          className={classes.inviteDialog}
          open={openDelete}
          onClose={handleCloseDelete}
        >
          <NotificationEditWrapper>
            <NotificationEditButton onClick={handleDeleteNotification}>
              삭제하기
            </NotificationEditButton>
          </NotificationEditWrapper>
        </Dialog>
      </Wrapper>
    );
  }
};
