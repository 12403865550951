import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import {
  SEE_TEAMS
} from "./CupTeamListQueries";
import CupTeamListPresenter from "./CupTeamListPresenter";
import { useQuery } from "react-apollo-hooks";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { toast } from "../../Components/Popup";

const desktopColumn = [
  { title: "팀이름", field: "teamName", headerStyle:{ fontWeight:"800"} },
  { title: "대표자", field: "host.username", headerStyle:{ fontWeight:"800"} },
  { title: "승패", editable: "never", headerStyle:{ fontWeight:"800"}, render: rowData => {
    return (
      rowData && (
        <span>{rowData.win} / {rowData.lose}</span>
      )
    );
  } 
  },
  
  { title: "연락처", field: "contact", initialEditValue: "", headerStyle:{ fontWeight:"800"} }
];

const mobileColumn = [
  { title: "팀이름", field: "teamName" },
  { title: "레벨", field: "level" },
  { title: "연락처", field: "contact" },
];


export default withRouter(({ match: { params }, history }) => {
  const cupId = params.cup;
  
  const [first] = useState(10);
  const [skip] = useState(0);
  const [loadMore, setLoadMore] = useState(false);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("xs"));
  const { data, loading, fetchMore } = useQuery(SEE_TEAMS, {
    variables: {
      first: first,
      skip: skip,
      cupId
    }
  });

  const [teams, setTeams] = useState([]);

  useEffect(() => {
    if(loading)
      setTeams({columns : matches ? mobileColumn : desktopColumn, ...teams});
    else if(!loading && data && data.seeTeams)
      setTeams({columns : matches ? mobileColumn : desktopColumn, data:data.seeTeams});
  }, [matches, data, loading])


  const onChangePage = () => {

    setLoadMore(true);
    fetchMore({
      variables: {
        skip: data.setTeams.length
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        setLoadMore(false);
        if(fetchMoreResult.setTeams.length === 0)
          toast.error("조회할 데이터가 없습니다.");
        else
          setTeams({...teams, data:[...teams.data, ...fetchMoreResult.setTeams]});

        if (!fetchMoreResult) return prev;

        return Object.assign({}, prev, {
          setTeams: [...prev.setTeams, ...fetchMoreResult.setTeams]
        });
      }
    });
  };


  return (
    <CupTeamListPresenter
      loading={loading}
      data={data}
      cupId={cupId}
      loadMore={loadMore}

      onChangePage={onChangePage}
      teams={teams}
      setTeams={setTeams}
      matches={matches}
    />
  );
});

