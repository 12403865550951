import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import PropTypes from "prop-types";


import Auth from "../Routes/Auth";
import TeamList from "../Routes/TeamList";
import TeamCreate from "../Routes/TeamCreate";
import TeamSelect from "../Routes/TeamSelect";
import Search from "../Routes/Search";
import Profile from "../Routes/Profile";
import GameProfile from "../Routes/GameProfile";
import GameVote from "../Routes/GameVote";
import TeamProfile from "../Routes/TeamProfile";
import TeamUserProfile from "../Routes/TeamUserProfile";
import TeamRank from "../Routes/TeamRanking";

import EditGame from "../Routes/EditGame";
import EditTeamProfile from "../Routes/EditTeamProfile";
import GameManage from "../Routes/GameManage";
import GameCreate from "../Routes/GameCreate";
import Register from "../Routes/Register";


import Notifications from "../Routes/Notifications";
import InviteList from "../Routes/InviteList";
import ChatList from "../Routes/ChatList";
import Chat from "../Routes/Chat";
import History from "../Routes/History";
import RoomHistory from "../Routes/RoomHistory";
import RoomCreate from "../Routes/RoomCreate";
import RoomProfile from "../Routes/RoomProfile";
import RoomUserManage from "../Routes/RoomUserManage";


import CupList from "../Routes/CupList";
import CupTeamList from "../Routes/CupTeamList";
import CupTeam from "../Routes/CupTeam";
import CupTeamRanking from "../Routes/CupTeamRanking";
import CupScheduleList from "../Routes/CupScheduleList";
import CupGame from "../Routes/CupGame";
import CupMain from "../Routes/CupMain";
import CupNotice from "../Routes/CupNotice";
import CupNoticeList from "../Routes/CupNoticeList";
import CupRequestForm from "../Routes/CupRequestForm";

import InternalServer from "./InternalServer";


const LoggedInRoutes = () => (
    <Switch>
        <Route exact path="/" component={TeamList} />


        <Route path="/cups" component={CupList} />
        <Route path="/cup/:cup/notices" component={CupNoticeList} />
        <Route path="/cup/:cup/notice/:notice" component={CupNotice} />
        <Route path="/cup/:cup/team/:team" component={CupTeam} />
        <Route path="/cup/:cup/teams" component={CupTeamList} />
        <Route path="/cup/:cup/games" component={CupScheduleList} />
        <Route path="/cup/:cup/rank" component={CupTeamRanking} />
        <Route path="/cup/:cup/game/:game" component={CupGame} />
        <Route path="/cup/:cup/request" component={CupRequestForm} />
        <Route path="/cup/:cup" component={CupMain} />
       
        

        {/* <Route path="/register/:code" component={Register} /> */}

        <Route path="/search" component={Search} />
        <Route path="/user/:id" component={Profile} />

        <Route path="/chats" component={ChatList} />
        <Route path="/invites" component={InviteList} />
        <Route path="/notifications" component={Notifications} />

        <Route path="/teams/:type" component={TeamSelect} />

        <Route path="/chat/:chat" component={Chat} />

        <Route path="/team/create" component={TeamCreate} />
        <Route path="/team/rank" component={TeamRank} />
        <Route path="/team/:team/edit" component={EditTeamProfile} />
        <Route path="/team/:team/user" component={TeamUserProfile} />
        <Route path="/team/:team/history" component={History} />
        <Route path="/team/:team/match" component={GameManage} />
        <Route path="/team/:team/request/:request" component={TeamProfile} />
        <Route path="/team/:team/" component={TeamProfile} />
 


        <Route path="/vote/t/:team/g/:game" component={GameVote} /> 
        <Route path="/game/:game/edit" component={EditGame} /> 
        <Route path="/game/create" component={GameCreate} />
     
        <Route path="/room/history" component={RoomHistory} />
        <Route path="/room/create" component={RoomCreate} />
        <Route path="/room/:room/user" component={RoomUserManage} />
        <Route path="/room/:room" component={RoomProfile} />
        

        
        <Route path="/game/:game" component={GameProfile} />
        
        <Route path="/500" component={InternalServer} />
        <Redirect from="*" to="/" />
    </Switch>
);

const LoggedOutRoutes = () => {
  return (
    <Switch>
        <Route exact path="/" component={Auth} />
        
        {/* <Route path="/register" component={Register} /> */}
        <Route path="/register/:code" component={Register} />
        <Route path="/register" component={Register} />

        {/*<Route path="/cups" component={CupList} />
         <Route path="/cup/:cup/notices" component={CupNoticeList} />
        <Route path="/cup/:cup/notice/:notice" component={CupNotice} />
        <Route path="/cup/:cup/teams" component={CupTeamList} />
        <Route path="/cup/:cup/team/:team" component={CupTeam} />
        <Route path="/cup/:cup/games" component={CupScheduleList} />
        <Route path="/cup/:cup/rank" component={CupTeamRanking} />
        <Route path="/cup/:cup/game/:game" component={CupGame} />
        <Route path="/cup/:cup/request" component={CupRequestForm} />
        <Route path="/cup/:cup" component={CupMain} /> */}
        
        <Route path="/t/:token" component={Auth} />
        <Route path="/t/:token/u/:uid" component={Auth} />

        <Redirect from="/cup" exact={false} to="/?cup=redirect" />
        <Redirect from="/cups" to="/?cup=redirect" />
        <Redirect from="*" to="/" />
    </Switch>
);
}

const AppRouter = ({ isLoggedIn }) =>  {
  console.log("KKI // route => ", window.location.pathname);
  
  if(isLoggedIn) {
    if(window.Android) {
      window.Android.passIsLoggedIn("true");
    }
    if(window.webkit) {
      window.webkit.messageHandlers.passIsLoggedIn.postMessage("true");
    }
    return <LoggedInRoutes />;
  } else {
    if(window.Android) {
      window.Android.passIsLoggedIn("false");
    }
    if(window.webkit) {
      window.webkit.messageHandlers.passIsLoggedIn.postMessage("false");
    }
  
    return <LoggedOutRoutes />;
  }
}

AppRouter.propTypes = {
    isLoggedIn: PropTypes.bool.isRequired
};

export default AppRouter;