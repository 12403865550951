import React, {useState} from "react";
import styled from "styled-components";
import { Helmet } from "rl-react-helmet";

import Avatar from "../../Components/Avatar";
import SwipeableViews from "react-swipeable-views";

import BoldText from "../../Components/BoldText";
import Map from "../../Components/Map";
import BaseHeader from "../../Components/Header/BaseHeader";
import { Info, Arrow } from "../../Components/Icons";

import { Link } from "react-router-dom";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import moment from "moment";
import "moment/locale/ko";

const Wrapper = styled.div``;

const ContentWrapper = styled.div`
  padding-top: 56px;
  /* margin: auto 10px; */
  background-color: ${props => props.theme.innerBgColor};
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
`;


const GameHeader = styled.header`
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  width: 60%;
  margin: 0 auto;
  padding-top: 10px;
  @media (max-width: 768px) {
    width: 95%;
  }
`;

const GameHeaderColumn = styled.div`
  padding: 7px;
  font-size: 16px;
  text-align: center;
  padding-top:20px;
  &:nth-child(2) {
    padding-top:0px;
    align-self:center;
  }
`;

const ColumnWrap = styled.div``;

const ColumnRow = styled.div`
  font-size: 15px;
  margin: 5px 0px;
  position:relative;
  padding: 0px 10px;
  max-width: 130px;
`;

const VSText = styled.span`
  font-size: 20px;
`;

const HeaderOrder = styled.div`
  font-size: 16px;
  font-weight: 800;
  color: #ffbe21;
`;

const HeaderAvatar = styled.div`
  border: 1px solid #fff;
  border-radius: 50%;
  padding: 6px;
  min-width:99px;
  min-height:99px;
  width: 99px;
`;

const GameInfoWrap = styled.div`
  padding: 8px 16px;
  display: flex;
  align-items: center;
  min-height: 70px;
  justify-content: space-evenly;
`;
const GameInfoColumn = styled.div``;



const ProfileIcon = styled.span`
  position: relative;
  right: -5px;
  top: 3px;
`;

const MyTabs = styled(Tabs)`
  color: #fff;
  padding: 5px 25px 0px;
  max-width: 500px;
  width: 100%;
  margin: 0 auto;
  & button:not(.Mui-selected) {
    z-index: 0;
  }
  .MuiTabs-flexContainer {
    background-color: #040f20;
    border-radius: 20px;
  }
  .MuiTabs-indicator {
    top: 0px;
    height: 4px;
    border-radius: 20px;
    height: 38px;
    background-color: ${props => props.theme.blueColor}!important;
  }
`;

const MyTabPanel = styled(TabPanel)`
  .MuiListItemText-secondary {
    color: #000;
  }
  & {
    /* padding-bottom: 47px; */
  }
`;

const useStyles = makeStyles(theme => ({
  card: {
    borderRadius: 0,
    backgroundColor: "transparent"
  },
  media: {
    height: 0,
    paddingTop: "56.25%" // 16:9
  },
  tabRoot: {
    fontSize: 16,
    fontFamily: "NanumSqure",
    zIndex: 1,
    minHeight: "38px"
  },
  userWrap: {
    // maxHeight: "400px",
    maxWidth: "600px",
    margin: "0 auto",
    width:"100%",
    color: "#000",
    overflowY:"auto",
    backgroundColor:"#fff",
    "& ul.MuiList-padding": {
      padding: 0,
      paddingBottom:74,
      backgroundColor:"#fff"
    }
  },
  scoreWrap: {
    padding: "0px 15px;"
  },
  SwipeView: {
    flex: 1,
    width: "100%",
    maxWidth: "924px",
    display: "flex",
    flexDirection: "column",
    margin: "0 auto",
    "& > .react-swipeable-view-container": {
      flex: 1
    }
  },
  boxRoot: {
    height: "100%",
    display: "flex",
    flexDirection: "column"
  },
  panelRoot: {
    height: "100%"
  }
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  const classes = useStyles();
  if (props.className.indexOf("mapWrapper") !== -1) {
    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        <Box className={classes.boxRoot} p={0}>
          {children}
        </Box>
      </Typography>
    );
  } else {
    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        // {...other}
        className={classes.panelRoot}
      >
        <Box className={classes.boxRoot} p={0}>
          {children}
        </Box>
      </Typography>
    );
  }
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`
  };
}


const _gameWeekText = gameDate => {
  let week = ["일", "월", "화", "수", "목", "금", "토"];
  let date = new Date(gameDate);
  return `(${week[date.getDay()]})`;
};


function MATCH(props) {

  console.log(props);

  const {
    data : {
      seeFullGame: {
        gameDate,
        startTime,
        endTime,
        homeTeam,
        awayTeam,
        place
      }
    }
  } = props;

  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = useState(0);
  const [mapInit, setMapInit] = useState(false);

  const handleChange = (event, newValue) => {
    setMapInit(true);
    setValue(newValue);
  };

  const handleChangeIndex = index => {
    setValue(index);
  };


  return (
    <Wrapper>
          <BaseHeader
            text={"매칭 준비"}
            isBack={true}
            isClose={false}
            isMenu={true}
          />
          <Helmet>
            <title>매칭 | WePlay</title>
          </Helmet>

          <ContentWrapper>
            <GameHeader>
              <GameHeaderColumn>
                <ColumnWrap>
                  <ColumnRow>
                    <HeaderOrder>홈</HeaderOrder>
                  </ColumnRow>
                  <ColumnRow>
                    <HeaderAvatar>
                      <Link to={`/team/${homeTeam.id}`}>
                        <Avatar url={homeTeam.logo} size={"lmd"} />
                      </Link>
                    </HeaderAvatar>
                  </ColumnRow>
                  <ColumnRow>
                    <Link to={`/team/${homeTeam.id}`}>
                      <BoldText text={homeTeam.teamName} />
                      <ProfileIcon>
                        <Info size={16} />
                      </ProfileIcon>
                    </Link>
                  </ColumnRow>
                  <ColumnRow>{`Lv.${homeTeam.level}`}</ColumnRow>
                </ColumnWrap>
              </GameHeaderColumn>
              <GameHeaderColumn>
                <ColumnWrap>
                  <ColumnRow>
                    <VSText>VS</VSText>
                  </ColumnRow>
                </ColumnWrap>
              </GameHeaderColumn>
              <GameHeaderColumn>
                <ColumnWrap>
                  <ColumnRow>
                    <HeaderOrder>원정</HeaderOrder>
                  </ColumnRow>
                  <ColumnRow>
                    <HeaderAvatar>
                      <Link to={`/team/${awayTeam.id}`}>
                        <Avatar url={awayTeam.logo} size={"lmd"} />
                      </Link>
                    </HeaderAvatar>
                  </ColumnRow>
                  <ColumnRow>
                    <Link to={`/team/${awayTeam.id}`}>
                      <BoldText text={awayTeam.teamName} />
                      <ProfileIcon>
                        <Info size={16} />
                      </ProfileIcon>
                    </Link>
                  </ColumnRow>
                  <ColumnRow>{`Lv.${awayTeam.level}`}</ColumnRow>
                </ColumnWrap>
              </GameHeaderColumn>
            </GameHeader>

            <MyTabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="inherit"
              variant="fullWidth"
              aria-label="tabs"
            >
              <Tab
                className={classes.tabRoot}
                label="경기 정보"
                {...a11yProps(0)}
              />
              <Tab
                className={classes.tabRoot}
                label="경기장 정보"
                {...a11yProps(1)}
              />
            </MyTabs>

            <SwipeableViews
              className={classes.SwipeView}
              axis={theme.direction === "rtl" ? "x-reverse" : "x"}
              index={value}
              onChangeIndex={handleChangeIndex}
              disabled={true}
            >
              <MyTabPanel value={value} index={0}>
                <GameInfoWrap>
                  <GameInfoColumn>
                    <BoldText text={moment(gameDate).format("YYYY. MM. DD")} />
                    {_gameWeekText(gameDate)}
                  </GameInfoColumn>
                  <GameInfoColumn>
                    <BoldText text={`${startTime} ~ ${endTime}`} />
                  </GameInfoColumn>
                </GameInfoWrap>
                <Grid className={classes.userWrap} item xs={12}>
                  <List dense>
                    {homeTeam.host && 
                      <Link key={homeTeam.host.id} to={`/user/${homeTeam.host.id}`}>
                        <ListItem button>
                          <ListItemAvatar>
                            <Avatar size={"sm"} url={homeTeam.host.avatar} />
                          </ListItemAvatar>
                          <ListItemText
                            primary={homeTeam.host.username}
                            secondary={`Lv.${homeTeam.host.level}`}
                          />
                          <ListItemSecondaryAction>
                            <IconButton
                              edge="end"
                              color="inherit"
                              aria-label="detail"
                            >
                              <Arrow />
                            </IconButton>
                          </ListItemSecondaryAction>
                        </ListItem>
                      </Link> }

                    {homeTeam.managers &&
                      homeTeam.managers.map(manager => (
                        <Link key={manager.id} to={`/user/${manager.id}`}>
                          <ListItem button>
                            <ListItemAvatar>
                              <Avatar size={"sm"} url={manager.avatar} />
                            </ListItemAvatar>
                            <ListItemText
                              primary={manager.username}
                              secondary={`Lv.${manager.level}`}
                            />
                            <ListItemSecondaryAction>
                              <IconButton
                                edge="end"
                                color="inherit"
                                aria-label="detail"
                              >
                                {/* <ArrowForwardIosIcon fontSize="small" /> */}
                                <Arrow />
                              </IconButton>
                            </ListItemSecondaryAction>
                          </ListItem>
                        </Link>
                      ))}

                    {homeTeam.users &&
                      homeTeam.users.map(user => (
                        <Link key={user.id} to={`/user/${user.id}`}>
                          <ListItem button>
                            <ListItemAvatar>
                              <Avatar size={"sm"} url={user.avatar} />
                            </ListItemAvatar>
                            <ListItemText
                              primary={user.username}
                              secondary={`Lv.${user.level}`}
                            />
                            <ListItemSecondaryAction>
                              <IconButton
                                edge="end"
                                color="inherit"
                                aria-label="detail"
                              >
                                {/* <ArrowForwardIosIcon fontSize="small" /> */}
                                <Arrow />
                              </IconButton>
                            </ListItemSecondaryAction>
                          </ListItem>
                        </Link>
                      ))}
                  </List>
                </Grid>
              </MyTabPanel>

              <MyTabPanel
                className={"mapWrapper"}
                value={value}
                index={1}
              >
                {value === 1 && mapInit && place && (
                  <Map
                    isInit={value}
                    address={place.address}
                    placeName={place.placeName}
                    lat={place.location.latitude}
                    lng={place.location.longitude}
                  ></Map>
                )}
              </MyTabPanel>
            </SwipeableViews>
          </ContentWrapper>
        </Wrapper>
  );
}



export default MATCH;
