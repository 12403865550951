import { gql } from "apollo-boost";

export const GET_INVITES = gql`
  query seeInvites($first:Int, $skip:Int, $term:String) {
    seeInvites(first:$first, skip:$skip, term:$term) {
      id
      logo
      teamName
      level
      createdAt
    }
    me {
      id
    }
  }
`;

export const ACCEPT_INVITE = gql`
  mutation acceptInvite($id: String!) {
    acceptInvite(id: $id)
  }
`;
export const DECLINE_INVITE = gql`
  mutation declineInvite($id: String!) {
    declineInvite(id: $id)
  }
`;


export const NEW_INVITE = gql`
  subscription newInvite($id: String!) {
    newInvite(id: $id) {
      id
      logo
      teamName
      level
      createdAt
    }
  }
`;