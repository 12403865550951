import React,{ use} from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import {HostBadge} from "./Icons"

const getSize = size => {
  let number;
  if (size === "sm") {
    number = 42;
  } else if (size === "md") {
    number = 54;
  } else if (size === "lmd") {
    number = 85;
  } else if (size === "lg") {
    number = 110;
  } else if (size === "xlg") {
    number = 130;
  }

  return `
    width:${number}px;
    height:${number}px;
  `;
};

const HostBadgeWrapper = styled.span`
  position: absolute;
  top: 5px;
  left: 15px;
  & > img {
    width: 15px;
  }
`;

const Container = styled.div`
  ${props => getSize(props.size)};
  background-image: url(${props => props.url}), url(/imgs/user_icon_list.png); 
  /* sns 내의 cdn 만료일 경우 기본 이미지로 처리 */
  background-size: cover;
  background-position: center;
  background-repeat:no-repeat;
  border-radius: 50%;
  flex-basis: ${props => props.size};
  flex-shrink: 0;
`;

function Avatar({ avatar, size = "sm", url, className, isHost=false }) {
  return (
    <Container id={avatar} className={className} size={size} url={url}>
      {isHost && <HostBadgeWrapper>
        <HostBadge size={15} />
      </HostBadgeWrapper>}
    </Container>
  );
}

Avatar.propTypes = {
  size: PropTypes.oneOf(["sm", "md", "lmd", "lg", "xlg"]),
  url: PropTypes.string.isRequired
};

export default Avatar;
