import React, { useState } from 'react';
import clsx from 'clsx';
import { Dialog, CircularProgress, IconButton, makeStyles, Checkbox } from '@material-ui/core';
import styled from 'styled-components';
import Button from './Button';
import { Close, Party } from './Icons';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Input from "./Input";
import useInput from "../Hooks/useInput";
import { toast } from './Popup';

const TitleRow = styled.div`
  margin-bottom: 15px;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.4em;
`;

const DialogWrapper = styled.div`
  background-color: #f2f2f2;
  border-radius: 5px;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const DialogHeader = styled.div`
  text-align: right;
  padding: 3px 15px;
`;

const DialogContent = styled.div`
  text-align: center;
  font-size: 23px;
  color: #000;
  margin: 0px 5px 10px 5px;
  flex: 1;
  display: flex;
  font-weight: 500;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  line-height: 1.5em;
  @media (max-width: 500px) {
    & span.MuiTypography-body1 {
      font-size:14px!important;
      text-align: left;
      word-break: keep-all;
    }
  }
  @media (max-width: 380px) {
    & span.MuiTypography-body1 {
      font-size:13px!important;
      text-align: left;
      word-break: keep-all;
    }
  }
`;

const PreviewContent = styled.div`
  text-align: center;
  font-size: 23px;
  color: #000;
  margin: 0px 30px 50px 30px;
  flex: 1;
  display: flex;
  font-weight: 500;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  line-height: 1.5em;
`;


const DialogFooter = styled.div`
  padding: 0px 20px 20px;
`;

const useStyles = makeStyles(theme => ({
  preDialog: {
    "& .MuiDialog-container": {
      alignItems: "flex-end"
    },
    "& .MuiDialog-paperWidthSm": {
      width: "100%",
      margin: 0,
      height: "50vh",
      maxWidth: 500,
      maxHeight: 400
    }
  },
  sendDialog: {
    "& .MuiDialog-container": {
      alignItems: "flex-end"
    },
    "& .MuiDialog-paperWidthSm": {
      width: "99%",
      margin: 0,
      maxWidth: 500,
    }
  },
  dm: {
    fontSize:14
  },
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    borderRadius: '50%',
    width: 16,
    height: 16,
    boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    backgroundColor: '#137cbd',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#106ba3',
    },
  },
}));

function StyledRadio(props) {
  const classes = useStyles();

  return (
    <Radio
      className={classes.root}
      disableRipple
      color="default"
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      {...props}
    />
  );
}

const Match = [
  "현재 저희 팀은 다른 팀과 매칭이 예정되어 있습니다.",
  "저희 팀과 레벨이 맞지 않아서 매칭이 어렵습니다.",
  "저희 팀 참석인원이 부족하여 매칭이 어렵습니다.",
  "직접입력 메세지"
];
const TeamJoin = [
  "현재 저희 팀은 신규회원을 받지 않습니다.",
  "현재 동일한 포지션에 많은 선수가 있어서 가입이 힘듭니다.",
  "저희 팀과 매칭이 맞지 않아 가입이 힘듭니다.",
  "기타. 직접 입력"
];


const DeclineDialog = ({
  title,
  type = "match",
  open,
  handleClose,
  handleSend,
  Transition,
  messages,
  isLoading
}) => {
  const classes = useStyles();
  const [isDM, setDM] = useState(false);
  const [openSend, setOpenSend] = useState(false);
  const directMessage = useInput("");

  const handleOpenSend = () => {
    setOpenSend(true);
    handleClose();
  }

  const handleCloseSend = () => {
    setOpenSend(false);
    handleClose();
  }

  const onChangeMessage = e => {
    const {
      target: { value }
    } = e;

    if(value.includes("직접")) {
      setDM(true);
    } else {
      setDM(false);
    }
    messages.setValue(value);
  }

  const handleSubmit = () => {
    if(isDM) {
      if(directMessage.value !== "") {
        handleSend(directMessage.value);
        handleCloseSend();
      } else {
        toast.error("거부 사유를 작성해주세요.")
      }
    } else {
      handleSend(messages.value);
      handleCloseSend()
    }
  }

  return (
    <>
      <Dialog
        className={classes.preDialog}
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <DialogWrapper>
          <DialogHeader>
            <IconButton
              edge="end"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <Close fill={"#000"} />
            </IconButton>
          </DialogHeader>
          <PreviewContent>
            <Party />
            {type === "match" && (
              <div>
                {title}팀에게 온 도전장을 <br />
                거부 하시겠습니까?
              </div>
            )}
            {type === "team" && (
              <div>
                {title}님에게 온 가입 신청을 <br />
                거부 하시겠습니까?
              </div>
            )}
          </PreviewContent>
          <DialogFooter>
            <Button color={"#000"} text={"보내기"} onClick={handleOpenSend} />
          </DialogFooter>
        </DialogWrapper>
      </Dialog>

      <Dialog
        open={openSend}
        className={classes.sendDialog}
        onClose={handleCloseSend}
        TransitionComponent={Transition}
      >
        <DialogWrapper>
          <DialogHeader>
            <IconButton
              edge="end"
              color="inherit"
              onClick={handleCloseSend}
              aria-label="close"
            >
              <Close fill={"#000"} />
            </IconButton>
          </DialogHeader>
          <DialogContent>
            <TitleRow>
              ※ 상대방을 위해 거부 사유를 작성해주세요.
              <br />
              건강한 서비스 이용에 도움이 됩니다.
            </TitleRow>

            <RadioGroup
              aria-label="gender"
              name="gender1"
              value={messages.value}
              onChange={onChangeMessage}
            >
              {type === "match" &&
                Match.map(item => (
                  <FormControlLabel
                    key={item}
                    value={item}
                    control={<Radio color="primary" />}
                    label={item}
                  />
                ))}
              {type === "team" &&
                TeamJoin.map(item => (
                  <FormControlLabel
                    key={item}
                    value={item}
                    control={<Radio color="primary" />}
                    label={item}
                  />
                ))}

              {isDM && (
                <Input
                  placeholder={"거부 사유를 작성해주세요."}
                  className={classes.dm}
                  value={directMessage.value}
                  onChange={directMessage.onChange}
                />
              )}
            </RadioGroup>
          </DialogContent>
          <DialogFooter>
            {isLoading ? (
              <CircularProgress />
            ) : (
              <Button
                color={"#000"}
                position={"relative"}
                onClick={handleSubmit}
                text={"보내기"}
              />
            )}
          </DialogFooter>
        </DialogWrapper>
      </Dialog>
    </>
  );


  // return (
  //   <Dialog
  //     open={open}
  //     className={classes.inviteDialog}
  //     onClose={handleClose}
  //     TransitionComponent={Transition}
  //   >
  //     <DialogWrapper>
  //       <DialogHeader>
  //         <IconButton
  //           edge="end"
  //           color="inherit"
  //           onClick={handleClose}
  //           aria-label="close"
  //         >
  //           <Close fill={"#000"} />
  //         </IconButton>
  //       </DialogHeader>
  //       <DialogContent>
  //         <TitleRow>
  //           ※ 상대방을 위해 거부 사유를 작성해주세요.
  //           <br />
  //           건강한 서비스 이용에 도움이 됩니다.
  //         </TitleRow>

  //         <RadioGroup aria-label="gender" name="gender1" value={messages.value} onChange={onChangeMessage}>
  //           {type === "match" && Match.map(item => <FormControlLabel key={item} value={item} control={<Radio color="primary" />} label={item} />)}
  //           {type === "team" && TeamJoin.map(item => <FormControlLabel key={item} value={item} control={<Radio color="primary" />} label={item} />)}

  //           {isDM && (
  //             <Input
  //               className={classes.dm}
  //               value={directMessage.value}
  //               onChange={directMessage.onChange}
  //             />
  //           )}
            
  //         </RadioGroup>
  //         {/* <BioTextarea
  //           placeholder="거부 사유를 입력해주세요"
  //           {...messages}
  //           cols="4"
  //           rows="6"
  //         ></BioTextarea> */}
  //       </DialogContent>
  //       <DialogFooter>
  //         {isLoading ? (
  //           <CircularProgress />
  //         ) : (
  //           <Button
  //             color={"#000"}
  //             position={"relative"}
  //             onClick={handleSubmit}
  //             text={"보내기"}
  //           />
  //         )}
  //       </DialogFooter>
  //     </DialogWrapper>
  //   </Dialog>
  // );

  // return (
  //   <Dialog
  //       fullScreen
  //       open={open}
  //       onClose={handleClose}
  //       TransitionComponent={Transition}
  //     >
  //       <BaseHeader
  //         isClose={false}
  //         isBack={true}
  //         text={title}
  //         onClickBack={handleClose}
  //       />
  //       <Form>
  //         <UserInfoRows>
  //           <UserInfoRow>
  //             <InputInfoColumn>
  //               <TitleRow>
  //                 ※ 상대방을 위해 거부 사유를 작성해주세요.
  //                 <br/>건강한 서비스 이용에 도움이 됩니다.
  //               </TitleRow>
  //             </InputInfoColumn>
  //             <InputInfoColumn>
  //               <BioTextarea
  //                 placeholder="거부 사유를 입력해주세요"
  //                 {...messages}
  //                 cols="4"
  //                 rows="6"
  //               ></BioTextarea>
  //             </InputInfoColumn>
  //           </UserInfoRow>
  //           {isLoading ? (
  //             <CircularProgress />
  //           ) : (
  //             <Button
  //               color={"#000"}
  //               position={"relative"}
  //               onClick={() => {handleSend(); handleClose();}}
  //               text={"보내기"}
  //             />
  //           )}
  //         </UserInfoRows>
  //       </Form>
  //     </Dialog>
  // )
};

export default DeclineDialog;