import React, { useState, useRef, useEffect } from "react";
import { withRouter } from "react-router-dom";
import {
  GET_CHAT,
  GET_MESSAGES,
  SEND_MESSAGE,
  NEW_MESSAGE
} from "./ChatQueries";
import ChatPresenter from "./ChatPresenter";
import { useQuery, useMutation, useSubscription, useApolloClient } from "react-apollo-hooks";
import { toast } from "../../Components/Popup";
import client from "../../Apollo/Client";
import useInput from "../../Hooks/useInput";

const ChatContainer = ({ match: { params } }) => {
  const id = params.chat? params.chat:"";
  const [last] = useState(50);
  const [skip] = useState(0);
  const [loadMore, setLoadMore] = useState(false);
  const [canMore, setCanMore] = useState(true);

  const wrapRef = useRef(null);
  const textAreaRef = useRef(null);
  const messageInput = useInput("");
  const [chatId, setChatId] = useState(undefined);
  const [toId, setToId] = useState();
  const [oldMessages, setOldMessages] = useState([]);
  const [newMessages, setNewMessages] = useState([]);

  const [Today, setToday] = useState(false);
  const sendMessageMutation = useMutation(SEND_MESSAGE);


  
  const { data, loading } = useQuery(GET_CHAT, {
    variables: {
      id
    }
  });

  const { data:dataMessages, loading:loadingMessages, fetchMore, startPolling, stopPolling } = useQuery(GET_MESSAGES, {
    fetchPolicy:"network-only",
    //pollInterval:500,
    variables: {
      id,
      last,
      skip
    }
  });

  const { data: newMessageData } = useSubscription(NEW_MESSAGE, {
    variables: {
      chatId: id
    }
  });

  const handleNewMessage = () => {
    if(newMessageData !== undefined) {
      const { newMessage } = newMessageData;

      if(newMessage.from.id !== data.me.id)
        setNewMessages(prev => [...prev, newMessage]);
    }
  }

  useEffect(()=> {
    console.log("newMessageData ===> ", newMessageData)
    handleNewMessage();
    //scrollToRef();
  },[newMessageData])

  useEffect(()=> {
    scrollToRef();
  },[oldMessages, newMessages]);




  const onScroll = ({currentTarget}) => {
    if (
      canMore && !loadMore && currentTarget.scrollTop <= 100
    ) {
      console.log("fetchMore");
      // setLoadMore(true);
      // fetchMore({
      //   variables: {
      //     skip: dataMessages.seeMessages.length
      //   },
      //   updateQuery: (prev, { fetchMoreResult }) => {
      //     setLoadMore(false);
         
      //     if (!fetchMoreResult) return prev;

      //     console.log(fetchMoreResult.seeMessages);

      //     if(fetchMoreResult.seeMessages.length === 0) {
      //       setCanMore(false);
      //       return;
      //     }

      //     return Object.assign({}, prev, {
      //       seeMessages: [...fetchMoreResult.seeMessages, ...prev.seeMessages]
      //     });
      //   }
      // });
    }   
  };


  const onSubmit = async e => {
    e.preventDefault();

    if(messageInput.value === "") {
      return ;
    }

    const messages = [...newMessages];
    messages.push({
      id:new Date().toISOString(),
      from: {
        id : data.me.id,
        username: data.me.username,
        avatar: data.me.avatar,
        itsMe:true,
        __typename: 'User'
      },
      to: {
        id : toId,
        username:  data.seeChat.participants.filter(p => p.id !== data.me.id)[0]
        .username,
        avatar:  data.seeChat.participants.filter(p => p.id !== data.me.id)[0]
        .avatar,
        itsMe:false,
        __typename: 'User'
      },
      text:messageInput.value,
      createdAt:new Date().toISOString(),
      __typename: 'Message'
    });
    setNewMessages(messages);

    client.writeQuery({
      query : GET_MESSAGES,
      variables: {
        id
      },
      data: { 
        seeMessages: [...dataMessages.seeMessages, ...messages] 
      }
    });

    messageInput.setValue("");
    //scrollToRef();

    try {
      const { data : { sendMessage } } = await sendMessageMutation({
        variables: {
          chatId:chatId,
          message: messageInput.value,
          toId:toId
        }
      });
      
      //setSelfMessages([...selfMessages, sendMessage]);
      
      console.log(sendMessage);
    } catch (e) {
      toast.error("오류가 발생했습니다.")
    }
  }

  const onKeyPress = async event => {
    const { which } = event;
    if (which === 13) {
      event.preventDefault();

      if(messageInput.value === "") {
        return ;
      }

      const messages = [...newMessages];
      messages.push({
        id:new Date().toISOString(),
        from: {
          id : data.me.id,
          username: data.me.username,
          avatar: data.me.avatar,
          itsMe:true,
          __typename: 'User'
        },
        to: {
          id : toId,
          username:  data.seeChat.participants.filter(p => p.id !== data.me.id)[0]
          .username,
          avatar:  data.seeChat.participants.filter(p => p.id !== data.me.id)[0]
          .avatar,
          itsMe:false,
          __typename: 'User'
        },
        text:messageInput.value,
        createdAt:new Date().toISOString(),
        __typename: 'Message'
      });
      setNewMessages(messages);
      //console.log(oldMessages);

      client.writeQuery({
        query : GET_MESSAGES,
        variables: {
          id
        },
        data: { 
          seeMessages: [...dataMessages.seeMessages, ...messages] 
        }
      });

      messageInput.setValue("");
      //scrollToRef();
      try {
        const { data : { sendMessage } } = await sendMessageMutation({
          variables: {
            chatId:chatId,
            message: messageInput.value,
            toId:toId
          },
          //update(store, { })
        });
       
        
        //setNewMessages([...newMessages, sendMessage]);
        console.log(sendMessage);
      } catch (e) {
        console.log(e);
        toast.error("오류가 발생했습니다.")
      }
    }
  }


  const scrollToRef = () =>  {
    console.log("scrollToRef");
    if(wrapRef.current) {
      wrapRef.current.scrollTo({
        behavior: "auto",
        top: wrapRef.current.scrollHeight
      })
    } 
  };

  const onFocus = (e) => {
    e.preventDefault();
    e.stopPropagation();
    
    
    // setTimeout(()=>{
    //   window.scrollTo(0,0);
    //   scrollToRef();
    // }, 100)
  }
  return (
    <ChatPresenter
      canMore={canMore}
      loadMore={loadMore}
      loading={loading}
      onFocus={onFocus}
      data={data}
      dataMessages={dataMessages}
      loadingMessages={loadingMessages}
      setChatId={setChatId}
      setToId={setToId}
      oldMessages={oldMessages}
      setOldMessages={setOldMessages}
      newMessages={newMessages}
      setNewMessages={setNewMessages}
      onSubmit={onSubmit}
      textAreaRef={textAreaRef}
      messageInput={messageInput}
      onKeyPress={onKeyPress}
      wrapRef={wrapRef}
      scrollToRef={scrollToRef}
      Today={Today}
      setToday={setToday}
      onScroll={onScroll}
    />
  );
};

export default withRouter(ChatContainer);
