import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import { Plus } from "./Icons";
import useInput from "../Hooks/useInput";

const Wrapper = styled.div`
  background-color: #fff;
  border-radius: 10px;
  cursor: ${props => (props.endGame ? "unset" : "pointer")};
  /* height: 8vh; */
  min-height: 57px;
  max-height: 70px;
  display: flex;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
  font-size: 20px;
`;

const InactiveWrapper = styled(Wrapper)`
  background-color: rgba(255, 255, 255, 0.3);
`;

const InputBox = styled.div`
  /* padding: 10px 15px; */
  font-size: 30px;
  font-weight: 500;
  width: 100px;
  text-align: center;
`;

const Input = styled.input`
  text-align: center;
  /* border:none; */
  width: 100%;
  font-size: 30px;
  border-radius: 10px;
  padding: 5px;
  border: none;
  &:focus {
    border: 1px solid;
  }
  &:disabled {
    color: #000;
    cursor: default;
    background-color: #fff;
  }
`;

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
  paper: {
    width: "80%",
    maxHeight: 435,
    backgroundColor: "#f2f2f2",
    borderRadius: 10
  }
}));

export default function ScoreInput({
  index,
  homeScore,
  awayScore,
  setHomeScore,
  setAwayScore,
  myTeamSended = false,
  endGame
}) {
  console.log(homeScore[index], awayScore[index], index);

  const classes = useStyles();
  const [active, setActive] = useState(homeScore[index] !== undefined && awayScore[index] !== undefined);
  const hScore = useInput(
    homeScore[index] ? homeScore[index] : 0,
    newValue => !isNaN(newValue)
  );
  const aScore = useInput(
    awayScore[index] ? awayScore[index] : 0,
    newValue => !isNaN(newValue)
  );


  useEffect(() => {
    //console.log(hScore.value, aScore.value);
    hScore.setValue(homeScore[index] !== undefined ? homeScore[index] : "");
    aScore.setValue(awayScore[index] !== undefined ? awayScore[index] : "");
  }, [homeScore, awayScore]);

  const handleClickListItem = (event) => {
    event.preventDefault();
    event.stopPropagation()
    if(!endGame) {
      if(active) {
        homeScore[index] = undefined;
        awayScore[index] = undefined;
  
        setActive(false);
      } else {
        homeScore[index] = hScore.value;
        awayScore[index] = aScore.value;
        setActive(true);
      }
    }
  };

  const onChange = type => e => {
    const {
      target: { value }
    } = e;

    let willUpdate = true;

    if (type === "home") {
      if(value !== "")
        willUpdate = !isNaN(value) && parseInt(value) <= 300;


      if (willUpdate) {
        hScore.setValue(value);
        const hData = [...homeScore];
        hData.splice(index, 1, value);
        setHomeScore(hData);
      }
     
    } else if (type === "away") {
      if(value !== "")
        willUpdate = !isNaN(value) && parseInt(value) <= 300;

      if (willUpdate) {
        aScore.setValue(value);
        const aData = [...awayScore];
        aData.splice(index, 1, value);
        setAwayScore(aData);
      }
    }
  };

  if (active) {
    return (
      <div className={classes.root}>
        <Wrapper endGame={myTeamSended === true ? myTeamSended: endGame}>
          <InputBox>
            <Input
              value={hScore.value}
              placeholder={0}
              disabled={myTeamSended === true ? myTeamSended : endGame}
              onChange={onChange("home")}
            />
          </InputBox>
          <InputBox onClick={handleClickListItem}>:</InputBox>
          <InputBox>
            <Input
              value={aScore.value}
              placeholder={0}
              disabled={myTeamSended === true ? myTeamSended :endGame}
              onChange={onChange("away")}
            />
          </InputBox>
        </Wrapper>
      </div>
    );
  } else {
    return (
      <div className={classes.root}>
        {endGame ? (
          <Wrapper endGame={endGame}>미등록</Wrapper>
        ) : (
          <InactiveWrapper onClick={handleClickListItem}>
            <Plus />
          </InactiveWrapper>
        )}
      </div>
    );
  }

}
