import React, { useState, useEffect } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { gql } from "apollo-boost";
import Avatar from "./Avatar";
import {
  GroupAdd,
  GroupEdit,
  Help,
  Info,
  Calender,
  Message,
  Boxing,
  Tools,
  KakaoChannel,
  BasketBall,
  Create,
  People,
  Coin,
  Invite,
  Phone,
  Instagram,
  Youtube
} from "./Icons";
import { useMutation } from "react-apollo-hooks";
import Grid from "@material-ui/core/Grid";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import IconButton from "@material-ui/core/IconButton";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  nested: {
    paddingLeft: theme.spacing(4)
  }
}));

const DrawerContainer = styled(Drawer)`
  .MuiPaper-root {
    background-color: #227fff;
    color: #fff;
    display: flex;
    justify-content: space-between;
  }
  svg {
    color: #fff;
  }
  .MuiListItem-root {
    padding-top: 10px !important;
    padding-bottom: 11px !important;
  }
  .MuiListItemIcon-root {
    padding-left: 10px;
  }
`;

const DrawerWrap = styled.div`
  width: 250px;
`;

const BottomWrap = styled.div`
  position: relative;
  bottom: 20px;
  align-self: flex-end;
  margin-top: 50px;
`;

const SNSIconBox = styled.a`
  cursor: pointer;
  margin-left:30px;
`;

const Telephone = styled.div`
    display: flex;
    align-items: center;
    margin-top: 15px;
    justify-content: flex-end;
    margin-right: 30px;
    & > svg {
      margin-right:5px;
    }
`;

const MyDivider = styled(Divider)`
  background-color: rgba(255, 255, 255, 0.12) !important;
`;

const DrawerHeader = styled(Grid)`
  min-height: 15vh;
  background-color: ${props => props.theme.blueColor};
  display: flex;
`;

const UserInfoRow = styled.div`
  display: flex;
  font-size: 15px;
  justify-content: space-between;
  & > a {
    flex:1;
  }
`;

const UserInfoColumn = styled.div`
  padding-left: 16px;
  padding-right: 16px;
  display: flex;
  position: relative;
  box-sizing: border-box;
  text-align: left;
  align-items: center;
  padding-top: 8px;
  padding-bottom: 8px;
  justify-content: flex-start;
  text-decoration: none;
`;

const CountWrap = styled.span`
  background-color: #113f80;
  color: #fff;
  border-radius: 30px;
  text-align: center;
  padding: 5px 10px;
`;


const MyExpandLess = styled(ExpandLess)`
  margin-left: auto;
`;

const MyExpandMore = styled(ExpandMore)`
  margin-left: auto;
`;

const HeaderColumn = styled.div`
  &:first-child {
    text-align: left;
    margin: 0px 15px;
  }
  &:nth-child(2) {
    margin-right: auto;
    font-size: 16px;
    * {
      margin-bottom: 5px;
    }
    p {
      font-size: 14px;
    }
  }
  &:last-child {
    flex: 0 0 auto;
    align-self: flex-start;
  }
`;

const FileDownload = styled.a`
  display:flex;
  align-items:center;
  width:100%;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 10px;
  padding-bottom: 11px;
`;

const LOG_OUT = gql`
  mutation logUserOut {
    logUserOut @client
  }
`;

function MyDrawer({ data, right, toggleDrawer }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles();

  const [openTeam, setOpenTeam] = useState(false);
  const [openGame, setOpenGame] = useState(false);
  const [openRoom, setOpenRoom] = useState(false);
  const [inviteCount, setInviteCount] = useState(0);

  const handleOpenClick = type => () => {
    if (type === "team") {
      setOpenTeam(!openTeam);
    } else if (type === "game") {
      setOpenGame(!openGame);
    } else if (type === "room") {
      setOpenRoom(!openRoom);
    }
  };

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logOut = useMutation(LOG_OUT);

  useEffect(() => {
    if (data && data.me) setInviteCount(data.me.inviteCount);
  }, [data]);

  return (
    <DrawerContainer
      anchor="right"
      open={right}
      onClose={toggleDrawer("right", false)}
    >
      {data.me && (
        <DrawerWrap
          role="presentation"
          // onClick={toggleDrawer("right", false)}
          // onKeyDown={toggleDrawer("right", false)}
        >
          <DrawerHeader container justify="center" alignItems="center">
            <HeaderColumn>
              <Link to={`/user/${data.me.id}`}>
                <Avatar size="md" url={data.me.avatar} />
              </Link>
            </HeaderColumn>
            <HeaderColumn>
              <Link to={`/user/${data.me.id}`}>
                <div>{data.me.username}</div>
                <p>Lv.{data.me.level}</p>
              </Link>
            </HeaderColumn>
            <HeaderColumn>
              <IconButton
                aria-label="settings"
                aria-haspopup="true"
                onClick={handleClick}
              >
                <MoreVertIcon />
              </IconButton>
            </HeaderColumn>
          </DrawerHeader>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <Link to={`/user/${data.me.id}`}>
              <MenuItem>프로필</MenuItem>
            </Link>
            {/* <Link to={`/teams/main`}>
                <MenuItem>팀 변경</MenuItem>
              </Link> */}
            <MenuItem onClick={logOut}>로그아웃</MenuItem>
          </Menu>

          <List>
            <UserInfoRow>
              <UserInfoColumn>
                <ListItemIcon>
                  {/* <SvgIcon
                      size={22}
                      src={"/imgs/weplaycoin.png"}
                    /> */}
                  <Coin size={22} />
                </ListItemIcon>
                <ListItemText primary={"나의 캐시"} />
              </UserInfoColumn>
              <UserInfoColumn>
                <CountWrap>{data.me.cash}</CountWrap>
              </UserInfoColumn>
            </UserInfoRow>

            <UserInfoRow>
              <Link to={`/chats`}>
                <UserInfoColumn>
                  <ListItemIcon>
                    <Message size={20} />
                  </ListItemIcon>
                  <ListItemText primary={"메시지"} />
                </UserInfoColumn>
              </Link>
              <UserInfoColumn>
                <Link to={`/chats`}>
                  <CountWrap>{data.me.messageCount}</CountWrap>
                </Link>
              </UserInfoColumn>
            </UserInfoRow>

            <UserInfoRow>
              <Link to={`/invites`}>
                <UserInfoColumn>
                  <ListItemIcon>
                    <Invite size={20} />
                  </ListItemIcon>
                  <ListItemText primary={"초대장"} />
                </UserInfoColumn>
              </Link>
              <UserInfoColumn>
                <Link to={`/invites`}>
                  <CountWrap>{inviteCount}</CountWrap>
                </Link>
              </UserInfoColumn>
            </UserInfoRow>
          </List>

          <MyDivider />

          <List>
            {data.me.myTeam && (
              <>
                <ListItem button onClick={handleOpenClick("team")}>
                  <ListItemIcon>
                    <People size={20} />
                  </ListItemIcon>
                  <ListItemText primary={"팀 관리"} />
                  {openTeam ? <MyExpandLess /> : <MyExpandMore />}
                </ListItem>
                <Collapse in={openTeam} timeout="auto" unmountOnExit>
                  <Link to={`/team/${data.me.myTeam.id}`}>
                    <ListItem button className={classes.nested}>
                      <ListItemIcon>
                        <GroupEdit size={20} />
                      </ListItemIcon>
                      <ListItemText primary={"팀 정보"} />
                    </ListItem>
                  </Link>
                </Collapse>
              </>
            )}

            {data.me.isSomeHost && data.me.myHostTeam && (
              <Collapse in={openTeam} timeout="auto" unmountOnExit>
                <Link to={`/team/${data.me.myHostTeam.id}/user`}>
                  <ListItem button className={classes.nested}>
                    <ListItemIcon>
                      <Tools size={20} />
                    </ListItemIcon>
                    <ListItemText primary={"신청/초대 관리"} />
                  </ListItem>
                </Link>
              </Collapse>
            )}

            {data.me.myTeam && (
              <ListItem button onClick={handleOpenClick("game")}>
                <ListItemIcon>
                  <BasketBall size={20} />
                </ListItemIcon>
                <ListItemText primary={"경기 관리"} />

                {openGame ? <MyExpandLess /> : <MyExpandMore />}
              </ListItem>
            )}

            {data.me.myTeam && (
              <Collapse in={openGame} timeout="auto" unmountOnExit>
                <Link to={`/team/${data.me.myTeam.id}/history`}>
                  <ListItem button className={classes.nested}>
                    <ListItemIcon>
                      <Calender size={20} />
                    </ListItemIcon>
                    <ListItemText primary={"일정/결과"} />
                  </ListItem>
                </Link>
              </Collapse>
            )}

            {data.me.isSomeHost && data.me.myHostTeam && (
              <>
                <Collapse in={openGame} timeout="auto" unmountOnExit>
                  <Link to={`/team/${data.me.myHostTeam.id}/match`}>
                    <ListItem button className={classes.nested}>
                      <ListItemIcon>
                        <Boxing size={20} />
                      </ListItemIcon>
                      <ListItemText primary={"도전장 관리"} />
                    </ListItem>
                  </Link>
                </Collapse>

                <Collapse in={openGame} timeout="auto" unmountOnExit>
                  <Link to={"/game/create"}>
                    <ListItem button className={classes.nested}>
                      <ListItemIcon>
                        <Create size={26} />
                      </ListItemIcon>
                      <ListItemText primary={"경기 생성"} />
                    </ListItem>
                  </Link>
                </Collapse>
              </>
            )}

            {/* <ListItem button onClick={handleOpenClick("room")}>
                <ListItemIcon>
                  <OpenIcon size={24} />
                </ListItemIcon>
                <ListItemText primary={"오픈방 관리"} />
                {openRoom ? <MyExpandLess /> : <MyExpandMore />}
              </ListItem>

              <Collapse in={openRoom} timeout="auto" unmountOnExit>
                <Link to={`/room/history`}>
                  <ListItem button className={classes.nested}>
                    <ListItemIcon>
                      <OpenHistory size={18} />
                    </ListItemIcon>
                    <Badge badgeContent={0} max={10} color="secondary">
                      <ListItemText primary={"오픈방 이력"} />
                    </Badge>
                  </ListItem>
                </Link>
              </Collapse>
              {data.me.isOpenManager && (
                <Collapse in={openRoom} timeout="auto" unmountOnExit>
                  <Link to={"/room/create"}>
                    <ListItem button className={classes.nested}>
                      <ListItemIcon>
                        <OpenRoom size={18} />
                      </ListItemIcon>
                      <ListItemText primary={"오픈방 생성"} />
                    </ListItem>
                  </Link>
                </Collapse>
              )} */}

            {!data.me.isSomeHost && !data.me.myTeam && (
              <Link to={"/team/create"}>
                <ListItem button>
                  <ListItemIcon>
                    <GroupAdd />
                  </ListItemIcon>
                  <ListItemText primary={"팀 생성"} />
                </ListItem>
              </Link>
            )}

            <FileDownload href="http://weplaysports.net/books/QAbook.pdf" download>
              <ListItemIcon>
                <Help size={20} />
              </ListItemIcon>
              <ListItemText primary={"FAQ / 문의하기"} />
            </FileDownload>

            <FileDownload href="http://weplaysports.net/books/guidebook.pdf" download>
              <ListItemIcon>
                <Info size={20} />
              </ListItemIcon>
              <ListItemText primary={"이용방법"} />
            </FileDownload>

            {/* <Link to={"/book-guide"}>
              <ListItem button>
                <ListItemIcon>
                  <GroupAdd />
                </ListItemIcon>
                <ListItemText primary={"이용방법z"} />
              </ListItem>
            </Link> */}

            {/* <ListItem button>
              <FileDownload href="http://weplaysports.net/books/guidebook.pdf" download>
                <ListItemIcon>
                  <Info size={20} />
                </ListItemIcon>
                <ListItemText primary={"이용방법"} />
              </FileDownload>
            </ListItem> */}

          </List>
        </DrawerWrap>
      )}
      <BottomWrap>
        <SNSIconBox href="https://www.instagram.com/weplay_official/">
          <Instagram size={30} />
        </SNSIconBox>
        <SNSIconBox href="https://www.youtube.com/channel/UCqfSVzWnWEr1sT3KwN2ck0A">
          <Youtube size={30} />
        </SNSIconBox>
        <SNSIconBox href="https://pf.kakao.com/_wHuJxb">
          <KakaoChannel size={30} />
        </SNSIconBox>
        <Telephone>
          <Phone size={15} />
          <a href="tel:031-698-4900">고객센터 : 031-698-4900</a>
        </Telephone>
      </BottomWrap>
    </DrawerContainer>
  );
}

MyDrawer.propTypes = {
  right: PropTypes.bool.isRequired,
  toggleDrawer: PropTypes.func.isRequired
};

export default MyDrawer;
