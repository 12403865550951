import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import { Helmet } from "rl-react-helmet";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";

import BaseHeader from "./Header/BaseHeader";
import Avatar from "./Avatar";
import RoundCheckBox from "./RoundCheckBox";
import Button from "./Button";

import { useMutation } from "react-apollo-hooks";
import { toast } from "./Popup";
import { gql } from "apollo-boost";
import Loader from "./Loader";

const ContentWrapper = styled.div`
  padding-top: 60px;
  margin: auto 10px;
  background-color: #fff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  color: #000;
`;
const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    "& span.MuiTypography-body1": {
      fontSize: 15,
      fontWeight:600,
      fontFamily:"NanumSqure"
    },
    "& .MuiTypography-colorTextSecondary": {
      fontSize:13
    }
  },
  awayRoot: {
    width: "100%",
    marginBottom: 90,
    "& span.MuiTypography-body1": {
      fontSize: 15,
      fontWeight:600,
      fontFamily:"NanumSqure"
    },
    "& .MuiTypography-colorTextSecondary": {
      fontSize:13
    }
  }
}));

const TeamHeader = styled.div`
  font-size: 17px;
  padding: 10px 15px;
  background-color: #f2f2f2;
`;

const MVP_VOTE = gql`
  mutation upsertMvpVote(
    $gameId: String!
    $teamId: String!
    $selectUser: String!
  ) {
    upsertMvpVote(gameId: $gameId, teamId: $teamId, selectUser: $selectUser) {
      id
      user {
        id
        avatar
        username
        level
      }
      selectUser {
        id
        avatar
        username
        level
      }
    }
  }
`;

export default ({ gameId, teamId, votes, homeTeam, awayteam, onBack, myVote }) => {
  useEffect(() => {}, []);

  const classes = useStyles();

  const [checked, setChecked] = useState(myVote? myVote.selectUser.id:null);
  const [uploading, setUploading] = useState(false);
  const homeVotes = votes.filter(vote => vote.team.id === homeTeam.id);
  const awayVotes = votes.filter(vote => vote.team.id === awayteam.id);

  const voteMutation = useMutation(MVP_VOTE);

  const handleToggle = value => e => {
    const newChecked = checked;

    if (newChecked === value) {
      setChecked(null);
    } else {
      setChecked(value);
    }
  };

  const onClickVote = async () => {
    try {
      setUploading(true);

      const {
        data: { upsertMvpVote }
      } = await voteMutation({
        variables: {
          gameId,
          teamId,
          selectUser: checked
        }
      });

      if (upsertMvpVote.id) {
        toast.success("투표 완료!");
        onBack();
      }
    } catch (e) {
      console.log(e);
      toast.error("오류 발생! 다시 시도해주세요.");
    } finally {
      setUploading(false);
    }
  };

  return (
    <>
      <BaseHeader
        text={"MVP 선정"}
        isBack={true}
        isClose={false}
        isMenu={false}
        isCenter={true}
        onClickBack={onBack}
      />
      <Helmet>
        <title>MVP 선정 | WePlay</title>
      </Helmet>
      {uploading && <Loader />}

      <ContentWrapper>
        <TeamHeader>{homeTeam.teamName}</TeamHeader>
        <List className={classes.root}>
          {homeVotes.length > 0 &&
            homeVotes.map(vote => {
              const labelId = `checkbox-list-${vote.id}`;
              return (
                <ListItem
                  key={vote.id}
                  button
                  onClick={handleToggle(vote.user.id)}
                >
                  <ListItemAvatar>
                    <Avatar url={vote.user.avatar} />
                  </ListItemAvatar>
                  <ListItemText
                    id={labelId}
                    primary={`${vote.user.username}(${
                      vote.user.basket && vote.user.basket.backNo
                        ? vote.user.basket.backNo
                        : "없음"
                    })`}
                    secondary={`Lv.${vote.user.level} | ${
                      vote.user.basket && vote.user.basket.position
                        ? vote.user.basket.position
                        : "포지션 미등록"
                    }`}
                  />
                  <ListItemSecondaryAction onClick={handleToggle(vote.user.id)}>
                    <RoundCheckBox
                      // onClick={handleToggle(vote.id)}
                      checked={checked === vote.user.id}
                      onChange={handleToggle(vote.user.id)}
                    />
                    {/* <Checkbox
                    edge="end"
                    onChange={handleToggle(vote.id)}
                    checked={checked.indexOf(vote.id) !== -1}
                    inputProps={{ "aria-labelledby": labelId }}
                  /> */}
                  </ListItemSecondaryAction>
                </ListItem>
              );
            })}
        </List>
        <TeamHeader>{awayteam.teamName}</TeamHeader>
        <List className={classes.awayRoot}>
          {awayVotes.length > 0 &&
            awayVotes.map(vote => {
              const labelId = `checkbox-list-${vote.id}`;
              return (
                <ListItem
                  key={vote.id}
                  button
                  onClick={handleToggle(vote.user.id)}
                >
                  <ListItemAvatar>
                    <Avatar url={vote.user.avatar} />
                  </ListItemAvatar>
                  <ListItemText
                    id={labelId}
                    primary={`${vote.user.username}(${
                      vote.user.basket && vote.user.basket.backNo
                        ? vote.user.basket.backNo
                        : "없음"
                    })`}
                    secondary={`Lv.${vote.user.level} | ${
                      vote.user.basket && vote.user.basket.position
                        ? vote.user.basket.position
                        : "포지션 미등록"
                    }`}
                  />
                  <ListItemSecondaryAction onClick={handleToggle(vote.user.id)}>
                    <RoundCheckBox
                      checked={checked === vote.user.id}
                      onChange={handleToggle(vote.user.id)}
                    />
                  </ListItemSecondaryAction>
                </ListItem>
              );
            })}
        </List>
        <Button
          text={"선택하기"}
          color={"#000"}
          onClick={onClickVote}
        />
      </ContentWrapper>
    </>
  );
};
