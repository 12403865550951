import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import {
  GET_BANNERS,
  MATCH_GAMELIST,
  TEAMLIST,
  NEARBY_GAMELIST,
  ROOMLIST,
  NEW_MATCH,
  NEW_GAME
} from "./TeamListQueries";
import TeamListPresenter from "./TeamListPresenter";
import { useQuery, useSubscription } from "react-apollo-hooks";

const TeamListContainer = () => {
  const sportId = localStorage.getItem("sport");
  const FIRST_NUMBER = 5;

  const [reloading, setReloading] = useState({
    games: false,
    teams: false,
    nearbys: false,
    rooms: false
  });

  const [dataLength, setDataLength] = useState({
    games: FIRST_NUMBER,
    teams: FIRST_NUMBER,
    nearbys: FIRST_NUMBER,
    rooms: FIRST_NUMBER
  });

  const [newMatchGames, setNewMatchGames] = useState([]);
  const [newGames, setNewGames] = useState([]);
  const [refreshing, setRefreshing] = useState();

  const [matchGames, setMatchGames] = useState([]);

  const { data:bannersData, loading:bannersLoading, refetch:refetchBanners } = useQuery(GET_BANNERS);

  const {
    data: matchGamesData,
    loading: matchGamesLoading,
    fetchMore: fetchMoreGames
  } = useQuery(MATCH_GAMELIST, {
    variables: {
      sport: sportId,
      status: "RECRUIT",
      first: FIRST_NUMBER,
      skip: 0,
      order: "gameDate_ASC"
    }
  });

  const {
    data: teams,
    loading: teamsLoading,
    fetchMore: fetchMoreTeams
  } = useQuery(TEAMLIST, {
    variables: {
      sport: sportId,
      status: "RECRUIT",
      first: FIRST_NUMBER,
      skip: 0
    }
  });

  const {
    data: nearbyGames,
    loading: nearbyGamesLoading,
    fetchMore: fetchMoreNearbys
  } = useQuery(NEARBY_GAMELIST, {
    variables: {
      sport: sportId,
      status: "RECRUIT",
      first: FIRST_NUMBER,
      skip: 0
    }
  });

  const {
    data: rooms,
    loading: roomsLoading,
    fetchMore: fetchMoreRooms
  } = useQuery(ROOMLIST, {
    variables: {
      sport: sportId,
      status: "RECRUIT",
      first: FIRST_NUMBER,
      skip: 0
    }
  });

  const { data: newMatchData } = useSubscription(NEW_MATCH);
  const { data: newGameData } = useSubscription(NEW_GAME);

  const handleNewGame = () => {
    if (newGameData !== undefined) {
      console.log("newGameData ===== ", newGameData);
      const { newGame } = newGameData;
      setNewGames(prev => [newGame, ...prev]);
    }
  };

  const handleNewMatch = () => {
    if (newMatchData !== undefined) {
      console.log("newMatchData ===== ", newMatchData);
      const { newMatchGame } = newMatchData;
      setNewMatchGames(prev => [...prev, newMatchGame]);
    }
  };

  useEffect(() => {
    handleNewMatch();
    handleNewGame();
  }, [newMatchData, newGameData]);

  const onRefresh = async () => {
    try {
      setRefreshing(true);
      refetchBanners();
      await Promise.all([
          fetchMoreGames({
            variables: {
              skip: 0,
              first: dataLength.games
            },
            updateQuery: (prev, { fetchMoreResult }) => {
              setReloading({
                games: false,
                teams: false,
                nearbys: false,
                rooms: false
              });

              if (!fetchMoreResult) return prev;
              console.log("seeGames:", fetchMoreResult.seeGames);
              return Object.assign({}, prev, {
                seeGames: [...fetchMoreResult.seeGames]
              });
            }
          }),
          fetchMoreTeams({
            variables: {
              skip: 0,
              first: dataLength.teams
            },
            updateQuery: (prev, { fetchMoreResult }) => {
              //setReloading({ ...reloading, [type]:false});
              setReloading({
                games: false,
                teams: false,
                nearbys: false,
                rooms: false
              });

              if (!fetchMoreResult) return prev;
              console.log("seeLevelGames:", fetchMoreResult.seeLevelGames);
              return Object.assign({}, prev, {
                seeLevelGames: [...fetchMoreResult.seeLevelGames]
              });
            }
          })
        ,
          fetchMoreNearbys({
            variables: {
              skip: 0,
              first: dataLength.nearbys
            },
            updateQuery: (prev, { fetchMoreResult }) => {
              //setReloading({ ...reloading, [type]:false});
              setReloading({
                games: false,
                teams: false,
                nearbys: false,
                rooms: false
              });

              if (!fetchMoreResult) return prev;
              console.log("seeGamesNearby:", fetchMoreResult.seeGamesNearby);
              return Object.assign({}, prev, {
                seeGamesNearby: [...fetchMoreResult.seeGamesNearby]
              });
            }
          }),
          fetchMoreRooms({
            variables: {
              skip: 0,
              first: dataLength.rooms
            },
            updateQuery: (prev, { fetchMoreResult }) => {
              setReloading({
                games: false,
                teams: false,
                nearbys: false,
                rooms: false
              });

              if (!fetchMoreResult) return prev;
              console.log("Room:", fetchMoreResult.seeRooms);
              return Object.assign({}, prev, {
                seeRooms: [...fetchMoreResult.seeRooms]
              });
            }
          })
      ]);

      setRefreshing(false);
    } catch (e) {
      console.log(e);
    } finally {
      
    }
  };

  const onReFetch = type => {
    setReloading({ ...reloading, [type]: true });
    if (type === "games") {
      fetchMoreGames({
        variables: {
          skip: 0,
          first: dataLength.games
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          setReloading({
            games: false,
            teams: false,
            nearbys: false,
            rooms: false
          });

          if (!fetchMoreResult) return prev;

          return Object.assign({}, prev, {
            seeGames: [...fetchMoreResult.seeGames]
          });
        }
      });
    } else if (type === "teams") {
      fetchMoreTeams({
        variables: {
          skip: 0,
          first: dataLength.teams
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          //setReloading({ ...reloading, [type]:false});
          setReloading({
            games: false,
            teams: false,
            nearbys: false,
            rooms: false
          });

          if (!fetchMoreResult) return prev;

          return Object.assign({}, prev, {
            seeLevelGames: [...fetchMoreResult.seeLevelGames]
          });
        }
      });
    } else if (type === "nearbys") {
      fetchMoreNearbys({
        variables: {
          skip: 0,
          first: dataLength.nearbys
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          //setReloading({ ...reloading, [type]:false});
          setReloading({
            games: false,
            teams: false,
            nearbys: false,
            rooms: false
          });

          if (!fetchMoreResult) return prev;

          return Object.assign({}, prev, {
            seeGamesNearby: [...fetchMoreResult.seeGamesNearby]
          });
        }
      });
    } else if (type === "rooms") {
      fetchMoreRooms({
        variables: {
          skip: 0,
          first: dataLength.rooms
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          //setReloading({ ...reloading, [type]:false});
          setReloading({
            games: false,
            teams: false,
            nearbys: false,
            rooms: false
          });

          if (!fetchMoreResult) return prev;

          return Object.assign({}, prev, {
            seeRooms: [...fetchMoreResult.seeRooms]
          });
        }
      });
    }
  };

  const onFetchMore = type => {
    console.log("Fetch More");

    if (type === "games" && reloading.games !== 0) {
      setReloading({ ...reloading, [type]: true });
      fetchMoreGames({
        variables: {
          skip: matchGamesData.seeGames.length
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          //setReloading({ ...reloading, [type]:false});
          setReloading({
            games: false,
            teams: false,
            nearbys: false,
            rooms: false
          });

          if (!fetchMoreResult) return prev;

          setDataLength({
            ...dataLength,
            [type]: prev.seeGames.length + fetchMoreResult.seeGames.length
          });

          return Object.assign({}, prev, {
            seeGames: [...prev.seeGames, ...fetchMoreResult.seeGames]
          });
        }
      });
    } else if (type === "teams" && reloading.teams !== 0) {
      setReloading({ ...reloading, [type]: true });
      //UpdateTeams();
      fetchMoreTeams({
        variables: {
          skip: teams.seeLevelGames.length
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          //setReloading({ ...reloading, [type]:false});

          setReloading({
            games: false,
            teams: false,
            nearbys: false,
            rooms: false
          });

          if (!fetchMoreResult) return prev;

          setDataLength({
            ...dataLength,
            [type]:
              prev.seeLevelGames.length + fetchMoreResult.seeLevelGames.length
          });

          return Object.assign({}, prev, {
            seeLevelGames: [
              ...prev.seeLevelGames,
              ...fetchMoreResult.seeLevelGames
            ]
          });
        }
      });
    } else if (type === "nearbys" && reloading.nearbys !== 0) {
      setReloading({ ...reloading, [type]: true });
      fetchMoreNearbys({
        variables: {
          skip: nearbyGames.seeGamesNearby.length
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          //setReloading({ ...reloading, [type]:false});

          setReloading({
            games: false,
            teams: false,
            nearbys: false,
            rooms: false
          });
          // if(fetchMoreResult.seeCupGames.length === 0)
          //   toast.error("조회할 데이터가 없습니다.");
          // else
          // setGames({...games, data:[...games.data, ...fetchMoreResult.seeCupGames]});

          if (!fetchMoreResult) return prev;

          setDataLength({
            ...dataLength,
            [type]:
              prev.seeGamesNearby.length + fetchMoreResult.seeGamesNearby.length
          });

          return Object.assign({}, prev, {
            seeGamesNearby: [
              ...prev.seeGamesNearby,
              ...fetchMoreResult.seeGamesNearby
            ]
          });
        }
      });
    } else if (type === "rooms" && reloading.rooms !== 0) {
      setReloading({ ...reloading, [type]: true });
      fetchMoreRooms({
        variables: {
          skip: rooms.seeRooms.length
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          setReloading({ ...reloading, [type]: false });

          // if(fetchMoreResult.seeCupGames.length === 0)
          //   toast.error("조회할 데이터가 없습니다.");
          // else
          // setGames({...games, data:[...games.data, ...fetchMoreResult.seeCupGames]});

          if (!fetchMoreResult) return prev;

          setDataLength({
            ...dataLength,
            [type]: prev.seeRooms.length + fetchMoreResult.seeRooms.length
          });

          return Object.assign({}, prev, {
            seeRooms: [...prev.seeRooms, ...fetchMoreResult.seeRooms]
          });
        }
      });
    }
  };

  return (
    <TeamListPresenter
      // loading={loading}
      // data={data}
      bannersData={bannersData}
      bannersLoading={bannersLoading}
      reloading={reloading}
      onFetchMore={onFetchMore}
      onReFetch={onReFetch}
      matchGames={matchGames}
      setMatchGames={setMatchGames}
      matchGamesData={matchGamesData}
      matchGamesLoading={matchGamesLoading}
      teams={teams}
      teamsLoading={teamsLoading}
      nearbyGames={nearbyGames}
      nearbyGamesLoading={nearbyGamesLoading}
      rooms={rooms}
      roomsLoading={roomsLoading}
      newMatchGames={newMatchGames}
      newGames={newGames}
      refreshing={refreshing}
      onRefresh={onRefresh}
    />
  );
};

export default withRouter(TeamListContainer);
