export const defaults = {
  isLoggedIn: Boolean(localStorage.getItem("token")) || false,
  curSports: Boolean(localStorage.getItem("sport")) || "1001"
};

export const resolvers = {
  Mutation: {
    logUserIn: (_, { token, uid }, { cache }) => {
      localStorage.setItem("token", token);
      if(uid)
        localStorage.setItem("uid", uid);
      cache.writeData({
        data: {
          isLoggedIn: true
        }
      });
      window.location = "/";
      return null;
    },
    logUserOut: (_, __) => {
      if(window.Android) {
        window.Android.passIsLoggedIn("false");
      }
      localStorage.removeItem("token");
      localStorage.removeItem("sport");
      localStorage.removeItem("uid");
      window.location = "/";
      return null;
    }
  }
};
