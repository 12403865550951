import React from "react";
import Slider from "react-slick";
import PropTypes from "prop-types";
import styled from "styled-components";
import CardMedia from "@material-ui/core/CardMedia";
import { makeStyles } from "@material-ui/core/styles";

const MySlider = styled(Slider)`
  & > .slick-dots {
    bottom:0px;
  }
`;


const useStyles = makeStyles(theme => ({
  card: {
    borderRadius: 0
  },
  media: {
    height: 0,
    backgroundColor:"#000",
    paddingTop: "56.25%" // 16:9

  },
  slickDots : {
    bottom:0
  }
}));

export default function Gallery({ type, items }) {
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerPadding: "0px",
    centerMode: true,
    arrows: false
  };
  const classes = useStyles();
  //console.log(items);
  return (
    <MySlider {...settings}>
      {items.length > 0 ? items.map(item => (
        <CardMedia
          key={item.id}
          className={classes.media}
          image={item.url}
          title="Paella dish"
        />
      )) : (
        <CardMedia
          className={classes.media}
          title="No Profile"
          image={"https://d1jozj1mdbjtd9.cloudfront.net/team_basic_thumbnail.jpg"}
        />
      )}
    </MySlider>
  );
}
Gallery.propTypes = {
  type: PropTypes.string,
  items: PropTypes.array.isRequired
};
