import { gql } from "apollo-boost";

export const SEE_RANK = gql`
  query seeRank($first: Int, $skip: Int) {
    seeTeamRank(first: $first, skip: $skip) {
      id
      team {
        id
        teamName
        level
        logo
      }
      winningRate
      win
      draw
      lose
    }
    seeTeamRankMonthly {
      id
      team {
        id
        teamName
        level
        logo
      }
      winningRate
      win
      draw
      lose
    }
  }
`;
