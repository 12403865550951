import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Helmet } from "rl-react-helmet";
import Loader from "../../Components/Loader";
import SwipeableViews from "react-swipeable-views";
import Button from "../../Components/Button";
import BaseHeader from "../../Components/Header/BaseHeader";
import Gallery from "../../Components/Gallery";
import DialogContent from "@material-ui/core/DialogContent";
import Card from "@material-ui/core/Card";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Divider, Slide, Dialog } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { Link } from "react-router-dom";
import DeclineDialog from "../../Components/DeclineDialog";
import { PopupError } from "../../Components/Icons";
import { NotFound } from "../../Components/NotFound";

const ContentWrapper = styled.div`
  padding-top: 56px;
  margin: auto 0px;
  background-color: ${props => props.theme.innerBgColor};
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  color: #fff;
  @media (min-width: 600px) {
    max-width: 600px;
    margin: 0 auto;
  }
`;

const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 80%;
  margin: 0 auto;
  @media (max-width: 768px) {
    width: 95%;
  }
  height: 56px;
`;

const LastHeaderBox = styled.span`
  border: 1px solid #fff;
  border-radius: 6px;
  padding: 5px;
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  & > svg {
    margin-right: 8px;
  }
`;

const HeaderColumn = styled.div`
  padding: 20px;
  font-size: 16px;
  text-align: center;
`;

const VerticalDivider = styled(Divider)`
  background-color: rgba(255, 255, 255, 0.12) !important;
  height: 50%;
`;

const Title = styled.div`
  background-color: #fff;
  padding: 10px 20px;
  color: #000;
  height: 56px;
  display: flex;
  align-items: center;
`;

const Message = styled.div`
  background-color: #f2f2f2;
  padding: 10px 20px;
  color: #000;
  flex: 1;
  & > span:last-child {
    margin-bottom: 70px;
    display: block;
  }
`;

const DeclineDialogTitle = styled.h4`
  color: #000;
  font-size: 18px;
  margin-top: 20px;
  text-align: center;
  word-break: keep-all;
  font-weight: 500;
  line-height: 1.5em;
`;

const DeclineDialogText = styled.div`
  color: #000;
  font-size: 15px;
  margin-top: 20px;
  width: 95%;
  word-break: keep-all;
  font-weight: 400;
  line-height: 1.5em;
  background-color: #d5d5d5;
  padding: 20px;
  border-radius: 8px;
`

const ConfirmButton = styled.button`
  background-color: #ffbe21;
  border: 1px solid #ffbe21;
  cursor: "pointer";
  font-size: 14px;
  padding: 10px 0px;
  text-align: center;
  font-weight: 500;
  font-family: "NanumSqure";
  border-radius: 7px;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  z-index:997;
  margin-top: 20px;
  /* width: 95%; */
  width: calc(100% - 20px);
`;


const MyTabPanel = styled(TabPanel)`
  .MuiListItemText-secondary {
    color: #000;
  }
  & {
    /* padding-bottom: 47px; */
  }
`;

const useStyles = makeStyles(theme => ({
  card: {
    borderRadius: 0,
    backgroundColor: "transparent",
    maxHeight: "300px"
  },
  media: {
    height: 0,
    paddingTop: "56.25%" // 16:9
  },
  tabRoot: {
    fontSize: 16,
    fontFamily: "NanumSqure",
    zIndex: 1,
    minHeight: "38px"
  },
  userWrap: {
    backgroundColor: "#fff",
    color: "#000",
    "& ul.MuiList-padding": {
      padding: 0
    }
  },
  hr: {
    height: "50%"
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1
  },
  SwipeView: {
    flex: 1,
    width: "100%",
    maxWidth: "924px",
    display: "flex",
    flexDirection: "column",
    margin: "0 auto",
    "& > .react-swipeable-view-container": {
      flex: 1
    }
  },
  boxRoot: {
    height: "100%",
    display: "flex",
    flexDirection: "column"
  },
  panelRoot: {
    height: "100%"
  },
  declineDialog : {
    "& .MuiPaper-root": {
      width:"90%",
      minHeight:300,
      margin:0
    },
    "& .MuiDialogContent-root": {
      display: "flex",
      flexDirection:"column",
      alignItems: "center",
      justifyContent: "center",
      padding:"25px 12px"
    }
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  const classes = useStyles();

  if (props.className.indexOf("mapWrapper") !== -1) {
    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        <Box className={classes.boxRoot} p={0}>
          {children}
        </Box>
      </Typography>
    );
  } else {
    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        // {...other}
        className={classes.panelRoot}
      >
        <Box className={classes.boxRoot} p={0}>
          {children}
        </Box>
      </Typography>
    );
  }
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default ({
  history,
  teamId,
  matchInfo,
  onBack,
  isSend,
  isLoading,
  replyInput,
  onClickAgree,
  onClickDecline
}) => {
  if (matchInfo === undefined) {
    return <NotFound text={"이미 시작했거나 취소된 경기의 도전장입니다."} />
  } else {
    const classes = useStyles();
    const theme = useTheme();
    const [value, setValue] = useState(0);
    const [photos, setPhotos] = useState([]);
    const [openDecline, setOpenDecline] = useState(false);
    const [openReply, setOpenReply] = useState(true);

    const handleChangeIndex = index => {
      setValue(index);
    };

    const handleOpenDecline = () => {
      history.push(`/team/${teamId}/match?view=${matchInfo.id}&type=receiver&&decline=true`);
      setOpenDecline(true);
    };

    const handleCloseDecline = () => {
      if (history.location.search)
        history.replace(`/team/${teamId}/match${history.location.search}`);
      setOpenDecline(false);
    };

    const handleCloseReply = () => {
        setOpenReply(false);
    };

    const handlePopState = () => {
      if (!window.location.hash.includes("decline")) {
        handleCloseDecline();
      } else {
        handleOpenDecline();
      }
    };
    const { sender, receiver, game, title, message, reply } = matchInfo;

    useEffect(() => {
      window.addEventListener("hashchange", handlePopState, false);

      if (sender) {
        setPhotos([...sender.profile]);
      } else if (receiver) {
        setPhotos([...receiver.profile]);
      }

      return () => {
        window.removeEventListener("hashchange", handlePopState, false);
      };
    }, []);

    if (sender) {
      const { id, teamName, userCount, level } = sender;

      const goProfile = () => {
        history.replace(`/team/${id}/`);
      };

      return (
        <>
          <BaseHeader
            text={teamName}
            isBack={true}
            isClose={false}
            isMenu={false}
            isCenter={true}
            onClickBack={onBack}
          />
          <Helmet>
            <title>{teamName} | WePlay</title>
          </Helmet>
          {isLoading && <Loader />}

          <ContentWrapper>
            <Header>
              <HeaderColumn grow={1}>Lv.{level}</HeaderColumn>
              <VerticalDivider className={classes.hr} orientation="vertical" />
              <HeaderColumn grow={1}>팀원 {userCount}명</HeaderColumn>
              <HeaderColumn grow={1}>
                <LastHeaderBox onClick={goProfile}>팀 프로필</LastHeaderBox>
              </HeaderColumn>
            </Header>
            <Card className={classes.card}>
              <Gallery items={photos} />
            </Card>

            <SwipeableViews
              className={classes.SwipeView}
              axis={theme.direction === "rtl" ? "x-reverse" : "x"}
              index={value}
              onChangeIndex={handleChangeIndex}
              disabled={true}
            >
              <MyTabPanel value={value} index={0}>
                <Title>{title}</Title>
                <Message>
                  {message.split("\n").map((line, i) => {
                    return (
                      <span key={i}>
                        {line}
                        <br />
                      </span>
                    );
                  })}
                </Message>
              </MyTabPanel>

              <MyTabPanel
                className={"mapWrapper"}
                value={value}
                index={1}
              ></MyTabPanel>
            </SwipeableViews>
            {game.gameStatus === "RECRUIT" && !isSend && (
              <Button
                rightText={"거부"}
                rightOnClick={handleOpenDecline}
                color={"#000"}
                half={true}
                text={"수락"}
                onClick={onClickAgree}
              />
            )}

            <DeclineDialog 
              title={teamName}
              type={"match"}
              open={openDecline}
              handleClose={handleCloseDecline}
              handleSend={onClickDecline}
              Transition={Transition}
              messages={replyInput}
              isLoading={isLoading}
            />
          </ContentWrapper>
        </>
      );
    } else if (receiver) {
      const { id, teamName, userCount, level } = receiver;
      return (
        <>
          <BaseHeader
            text={teamName}
            isBack={true}
            isClose={false}
            isMenu={false}
            isCenter={true}
            onClickBack={onBack}
          />
          <Helmet>
            <title>{teamName} | WePlay</title>
          </Helmet>
          {isLoading && <Loader />}

          <ContentWrapper>
            <Header>
              <HeaderColumn grow={1}>Lv.{level}</HeaderColumn>
              <VerticalDivider className={classes.hr} orientation="vertical" />
              <HeaderColumn grow={1}>팀원 {userCount}명</HeaderColumn>
              <HeaderColumn grow={1}>
                <Link to={`/team/${id}/`}>
                  <LastHeaderBox>팀 프로필</LastHeaderBox>
                </Link>
              </HeaderColumn>
            </Header>
            <Card className={classes.card}>
              <Gallery items={photos} />
            </Card>

            <SwipeableViews
              className={classes.SwipeView}
              axis={theme.direction === "rtl" ? "x-reverse" : "x"}
              index={value}
              onChangeIndex={handleChangeIndex}
              disabled={true}
            >
              <MyTabPanel value={value} index={0}>
                <Title>{title}</Title>
                <Message>
                  {message.split("\n").map((line, i) => {
                    return (
                      <span key={i}>
                        {line}
                        <br />
                      </span>
                    );
                  })}
                </Message>
              </MyTabPanel>

              <MyTabPanel
                className={"mapWrapper"}
                value={value}
                index={1}
              ></MyTabPanel>
            </SwipeableViews>
          </ContentWrapper>
            {reply && (
            <Dialog
              open={openReply}
              className={classes.declineDialog}
              onClose={handleCloseReply}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogContent>
                  <PopupError size={40} fill={"#f44336"} />
                  <DeclineDialogTitle>아래 사유로 도전장이 거부되었습니다.</DeclineDialogTitle>
                  <DeclineDialogText>
                    {reply.split("\n").map((line, i) => {
                      return (
                        <span key={i}>
                          {line}
                          <br />
                        </span>
                      );
                    })}
                  </DeclineDialogText>
                  <ConfirmButton onClick={handleCloseReply}>확인</ConfirmButton>
              </DialogContent>
            </Dialog>
          )}
        </>
      );
    }
  }
};
