import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { GET_ROOM, EDIT_ROOM, SEARCH_USER, REQUEST_ROOM, INVITE_USER, CANCEL_ROOM } from "./RoomProfileQueries";
import RoomProfilePresenter from "./RoomProfilePresenter";
import { useQuery, useMutation } from "react-apollo-hooks";
import { toast } from "../../Components/Popup";

import useInput from "../../Hooks/useInput";

const RoomProfileContainer = ({ match: { params } }) => {
  const roomId = params.room;
  const { data, loading, refetch } = useQuery(GET_ROOM, {
    variables: {
      id: roomId
    }
  }); 

  const [uploading, setUploading] = useState(false);
  const [searching, setSearching] = useState(false);
  const [GAMESTATUS, setGAMESTATUS] = useState();
  
  const [inviteUser, setInviteUser] = useState();
  const [openSearch, setOpenSearch] = useState(false);
  const [openInvite, setOpenInvite] = useState(false);
  const [isSendResult, setIsSendResult] = useState(false);
  const [isSendRequest, setIsSendRequest] = useState(false);


  const search = useInput("");
  const [searchUsers, setSearchUsers] = useState();


  const { loading:loadingSearch, data: searchData, refetch: refetchUser } = useQuery(SEARCH_USER, {
    skip: search.value === "",
    variables: {
      term: search.value
    }
  });

  useEffect(() => {
    if(search.value.length > 1) {
      setSearching(true);
    } else {
      setSearching(false);
    }
    refetchUser();

    if(!loadingSearch && searchData && searchData.searchUser) {
      setSearchUsers(searchData.searchUser);
      setSearching(false);
    }
  }, [search.value, loadingSearch, searchData]);


  const handleInviteOpen = (user) => () => {
    setInviteUser(user);
    setOpenInvite(true);
  };

  const handleInviteClose = () => {
    setInviteUser(null);
    setOpenInvite(false);
  };

  const handleSearchOpen = () => {
    setOpenSearch(true);
  };

  const handleSearchClose = () => {
    setOpenSearch(false);
  };

  const editRoomMutation = useMutation(EDIT_ROOM);

  const requestMutation = useMutation(REQUEST_ROOM);
  const cancelMutation = useMutation(CANCEL_ROOM);
  const inviteMutation = useMutation(INVITE_USER);
 
  
  
  // 매칭 신청 관련
  const [canMatchRequest, setCanMatchRequest] = useState(true);
    
  // const sendMatchMutation = useMutation(SEND_MATCH);
  const onRequest = async () => {
    try {
      setUploading(true);
      setIsSendRequest(true);
      toast.success("참가 신청 완료!");
      await requestMutation({
        variables: {
          id: roomId
        }
      });
      refetch();

    } catch (e) {
      console.log(e);
      setIsSendRequest(false);
      toast.error("오류가 발생했습니다. 다시 시도해주세요.");
    } finally {
      setUploading(false);
    }
  };

  const handleInvite = async () => {
    const data = [...searchUsers];
    data.splice(data.findIndex(i => i.id === inviteUser.id), 1);
    setSearchUsers(data);

    handleInviteClose();

    try {
      //setApplying(true);
      handleSearchClose();
      const {
        data: { inviteUserToRoom }
      } = await inviteMutation({
        variables: {
          id: roomId,
          userId: inviteUser.id
        }
      });

      if (inviteUserToRoom.id) {
        toast.success("초대를 전송했습니다.");
        refetch();
      }
    } catch (e) {
      toast.error("오류가 발생했습니다.");
    } finally {
      //setApplying(false);
    }
  };

  const handleSendMatch = () => {
    setCanMatchRequest(false);
    refetch();
  }

  const onMatch = async (e) => {
    e.preventDefault();
    try {
      setUploading(true);

      const {
        data: { editRoom }
      } = await editRoomMutation({
        variables: {
          id: roomId,
          action:"MATCH"
        }
      });
      console.log(editRoom);
      if (editRoom) {
        toast.success("오픈방 진행이 확정되었습니다.");
        refetch();
      } else {
        toast.error("오류가 발생했습니다. 다시 시도해주세요.");
      }
    } catch (e) {
      console.log(e);
      toast.error("오류가 발생했습니다. 다시 시도해주세요.");
    } finally {
      setUploading(false);
    }
  };


  const onCancel = async (e) => {
    
    e.preventDefault();
    try {
      setUploading(true);

      const {
        data: { cancelUserRoom }
      } = await cancelMutation({
        variables: {
          id: roomId
        }
      });
      console.log(cancelUserRoom);
      if (cancelUserRoom) {
        toast.success("참가 신청이 취소되었습니다.");
        refetch();
      } else {
        toast.error("오류가 발생했습니다. 다시 시도해주세요.");
      }
    } catch (e) {
      console.log(e);
      toast.error("오류가 발생했습니다. 다시 시도해주세요.");
    } finally {
      setUploading(false);
    }
  };


  const onRoomCancel = async (e) => {
    console.log(e);
    e.preventDefault();
    try {
      setUploading(true);

      const {
        data: { editRoom }
      } = await editRoomMutation({
        variables: {
          id: roomId,
          action:"CANCEL"
        }
      });
      console.log(editRoom);
      if (editRoom) {
        toast.success("오픈방을 취소했습니다.");
        refetch();
      } else {
        toast.error("오류가 발생했습니다. 다시 시도해주세요.");
      }
    } catch (e) {
      console.log(e);
      toast.error("오류가 발생했습니다. 다시 시도해주세요.");
    } finally {
      setUploading(false);
    }
  };

  const onConfirm = async (e) => {
    e.preventDefault();
    try {
      setUploading(true);
      setIsSendResult(true);

      const {
        data: { editRoom }
      } = await editRoomMutation({
        variables: {
          id: roomId,
          action:"CONFIRM"
        }
      });

      if (editRoom) {
        toast.success("오픈방 진행이 확정되었습니다.");
        refetch();
        setGAMESTATUS("CONFIRM");
      } else {
        setIsSendResult(false);
        toast.error("오류가 발생했습니다. 다시 시도해주세요.");
      }
    } catch (e) {
      console.log(e);
      setIsSendResult(false);
      toast.error("오류가 발생했습니다. 다시 시도해주세요.");
    } finally {
      setUploading(false);
    }  
  }


  const onComplete = async () => {

    try {
      setUploading(true);
      setIsSendResult(true);

      const {
        data: { completeGame }
      } = await editRoomMutation({
        variables: {
          id: roomId,
          action:"COMPLETE"
        }
      });
      console.log(completeGame);
      if (completeGame) {
        toast.success("최종 확인되었습니다.");
        refetch();
        setGAMESTATUS("COMPLETE");
      } else {
        setIsSendResult(false);
        toast.error("전달에 실패했습니다.");
      }
    } catch (e) {
      console.log(e);
      setIsSendResult(false);
      toast.error("전달에 실패했습니다. 다시 시도해주세요.");
    } finally {
   
      setUploading(false);
    }  
  }

  return (
    <RoomProfilePresenter
      loading={loading}
      canMatchRequest={canMatchRequest}
      setCanMatchRequest={setCanMatchRequest}
      handleSendMatch={handleSendMatch}

      onRequest={onRequest}
      onMatch={onMatch}
      onConfirm={onConfirm}
      onComplete={onComplete}
      onCancel={onCancel}
      onRoomCancel={onRoomCancel}
      data={data}
      uploading={uploading}

      inviteUser={inviteUser}
      openSearch={openSearch}
      openInvite={openInvite}
      handleInviteOpen={handleInviteOpen}
      handleInviteClose={handleInviteClose}
      handleSearchOpen={handleSearchOpen}
      handleSearchClose={handleSearchClose}
      handleInvite={handleInvite}
      searchUsers={searchUsers}
      search={search}
      searching={searching}


      isSendResult={isSendResult}
      isSendRequest={isSendRequest}
      setIsSendRequest={setIsSendRequest}
      GAMESTATUS={GAMESTATUS}
      setGAMESTATUS={setGAMESTATUS}
    />
  );
};

export default withRouter(RoomProfileContainer);
