import { gql } from "apollo-boost";

export const GET_TEAM = gql`
  query seeFullTeam($teamId: String!, $cupId: String!) {
    seeFullTeam(id: $teamId) {
      id
      teamName
      logo
      profile {
        id
        url
      }
      contact
      bio
      host {
        id
        username
        userStatAvg {
          id
          score
          rebound
          assist
          blocking
          steal
          turnOver
          foul
          freeShot
          freeShotTotal
        }
        basket {
          id
          height
          weight
          backNo
          position
        }
      }
      users {
        id
        username
        basket {
          id
          height
          weight
          position
          backNo
        }
        userStatAvg {
          id
          score
          rebound
          assist
          blocking
          steal
          turnOver
          foul
          freeShot
          freeShotTotal
        }
      }
    }
    seeCup(id: $cupId) {
      id
      playerCnt
    }
  }
`;
