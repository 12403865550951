import React from "react";
import styled from "styled-components";
import Avatar from "../../Components/Avatar";
import { Close } from "../../Components/Icons";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { Arrow } from "../../Components/Icons";

import InputBase from "@material-ui/core/InputBase";
import SearchIcon from "@material-ui/icons/Search";
import IconButton from "@material-ui/core/IconButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import SwipeableViews from "react-swipeable-views";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

const Wrapper = styled.div``;

const ContentWrapper = styled.div`
  margin: auto 10px;
  min-height: 100vh;
  background-color: #fff;
  padding-top: 146px;
  overflow-y: auto;
  max-width: 700px;
  margin: 0 auto;
`;

const EmptySearchResult = styled.div`
  margin:0px 15px;
  padding:15px 0px;
  color:#969696;
  font-size:14px;
  border-bottom:1px solid #d5d5d5;
`;

const WrapBox = styled(Box)`
  min-height:400px;
`;

const CustomCircularProgress = styled(CircularProgress)`
  top:50%;
`;

const useStyles = makeStyles(theme => ({
  toolBar: {
    height: "90px",
    display: "block",
    textAlign: "right",
    paddingTop: "5px",
    maxWidth: 700,
    margin: "0 auto",
    width: "100%"
  },
  hr: {
    height: "50%"
  },
  appBar: {
    position: "fixed",
    backgroundColor: "#227fff"
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1
  },
  invite: {
    marginLeft: "auto"
  },
  dialog: {
    "& .MuiDialog-paper": {
      backgroundColor: "#3A4149"
    }
  },
  search: {
    position: "relative",
    // top: "25%",
    borderBottom: "1px solid"
  },
  searchIcon: {
    width: theme.spacing(5),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  inputRoot: {
    width: "100%",
    color: "inherit",
    fontSize: "14px"
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 5),
    transition: theme.transitions.create("width"),
    width: "100%"
  },
  listItem: {
    // maxHeight : "calc(100vh - 146px)",
    color: "#000",
    "& > .MuiTypography-colorTextSecondary": {
      color: "#000"
    }
  },

  tabRoot: {
    maxWidth: 700,
    margin: "0 auto",
    width: "100%",
    "& .MuiTab-textColorPrimary": {
      color: "#d5d5d5",
      fontSize:15
    },
    "& .MuiTab-textColorPrimary.Mui-selected": {
      color: "#fff"
    },
    "& .MuiTabs-indicator": {
      backgroundColor:"#fff!important"
    }
  }
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <WrapBox p={0}>{children}</WrapBox>}
    </Typography>
  );
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`
  };
}

const SearchPresenter = ({
  activeTab,
  setActiveTab,
  search,
  data,
  dataTeam,
  dataPlace,
  onClickClose,
  searching,

  onScrollUser,
  onScrollTeam,
  onScrollPlace
}) => {
  const classes = useStyles();

  //console.log("activeTab:",activeTab)

  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleChangeIndex = index => {
    console.log("=== handleChangeIndex ===");
    console.log(index);
    setActiveTab(index);
  };

  return (
    <Wrapper>
      <AppBar className={classes.appBar}>
        <Toolbar className={classes.toolBar}>
          <IconButton
            edge="end"
            color="inherit"
            onClick={onClickClose}
            aria-label="close"
          >
            <Close />
          </IconButton>
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder="언제, 어디서, 누구랑 경기할까요?"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput
              }}
              inputProps={{ "aria-label": "search" }}
              value={search.value}
              onChange={search.onChange}
            />
          </div>
        </Toolbar>
        <Tabs
          className={classes.tabRoot}
          value={activeTab}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          <Tab label="이름" {...a11yProps(0)} />
          <Tab label="팀" {...a11yProps(1)} />
          <Tab label="경기장" {...a11yProps(2)} />
        </Tabs>
      </AppBar>

      <ContentWrapper>
        {searching && <CustomCircularProgress />}
        <SwipeableViews
          axis={"x"}
          index={activeTab}
          onChangeIndex={handleChangeIndex}
          disabled={true}
        >
          <TabPanel value={activeTab} index={0}>
              <List className={classes.listItem} dense>

                {search.value !== "" && data && data.searchUser && data.searchUser.length === 0 && <EmptySearchResult>사용자를 찾을 수 없음</EmptySearchResult>}

                {data &&
                  data.searchUser &&
                  data.searchUser.length > 0 &&
                  data.searchUser.map(user => (
                    <Link key={user.id} to={`/user/${user.id}`}>
                      <ListItem key={user.id}>
                        <ListItemAvatar>
                          <Avatar size={"sm"} url={user.avatar} />
                        </ListItemAvatar>
                        <ListItemText
                          primary={user.username}
                          secondary={`Lv. ${
                            user.basket ? user.basket.level : "0"
                          }`}
                        />
                        <ListItemSecondaryAction>
                          <IconButton
                            edge="end"
                            color="inherit"
                            aria-label="detail"
                          >
                            <Arrow />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                    </Link>
                  ))}
              </List>
            )}
            
          </TabPanel>
          <TabPanel value={activeTab} index={1}>
            <List className={classes.listItem} dense>
              {search.value !== "" && dataTeam && dataTeam.searchTeam && dataTeam.searchTeam.length === 0 && <EmptySearchResult>팀을 찾을 수 없음</EmptySearchResult>}

              {dataTeam &&
                dataTeam.searchTeam &&
                dataTeam.searchTeam.length > 0 &&
                dataTeam.searchTeam.map(team => (
                  <Link key={team.id} to={`/team/${team.id}`}>
                    <ListItem key={team.id}>
                      <ListItemAvatar>
                        <Avatar size={"sm"} url={team.logo} />
                      </ListItemAvatar>
                      <ListItemText
                        primary={team.teamName}
                        secondary={`Lv. ${team.level}`}
                      />
                      <ListItemSecondaryAction>
                        <IconButton
                          edge="end"
                          color="inherit"
                          aria-label="detail"
                        >
                          <Arrow />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  </Link>
                ))}
            </List>
          </TabPanel>
          <TabPanel value={activeTab} index={2}>
          <List className={classes.listItem} dense>
          <CircularProgress />
              {search.value !== "" && dataPlace && dataPlace.searchPlace && dataPlace.searchPlace.length === 0 && <EmptySearchResult>경기장을 찾을 수 없음</EmptySearchResult>}

              {dataPlace &&
                dataPlace.searchPlace &&
                dataPlace.searchPlace.length > 0 &&
                dataPlace.searchPlace.map(place => (
                  <ListItem key={place.id}>
                    {/* <ListItemAvatar>
                      <Avatar size={"sm"} url={team.logo} />
                    </ListItemAvatar> */}
                    <ListItemText
                      primary={place.placeName}
                      secondary={place.address}
                    />
                    <ListItemSecondaryAction>
                      {/* <IconButton
                        edge="end"
                        color="inherit"
                        aria-label="detail"
                      >
                        <Arrow />
                      </IconButton> */}
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
            </List>
          </TabPanel>
        </SwipeableViews>
      </ContentWrapper>
    </Wrapper>
  );
};

export default SearchPresenter;
