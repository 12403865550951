import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import {
  SEE_RANK
} from "./CupTeamRankingQueries";
import CupTeamRankingPresenter from "./CupTeamRankingPresenter";
import { useQuery } from "react-apollo-hooks";

const CupTeamRankingContainer = ({ match: { params }}) => {
  const cupId = params.cup;

  const { data, loading, fetchMore } = useQuery(SEE_RANK, {
    variables: {
      cupId,
      first:30,
      skip:0
    }
  });

  const [ranks, setRanks] = useState([]);
  
  const handleInfiniteScroll = () => {
    fetchMore({
      variables: {
        skip: data.seeCupRanks.length
      },
      updateQuery: (prev, { fetchMoreResult }) => {

        // if(fetchMoreResult.seeUsers.length === 0)
        //   toast.error("조회할 데이터가 없습니다.");
        // else
        //   setUsers({...users, data:[...users.data, ...fetchMoreResult.seeUsers]});

        if (!fetchMoreResult) return prev;

        return Object.assign({}, prev, {
          seeCupRanks: [...prev.seeCupRanks, ...fetchMoreResult.seeCupRanks]
        });
      }
    });
  };
 

  return (
    <CupTeamRankingPresenter
      loading={loading}
      data={data}
      cupId={cupId}
      ranks={ranks}
      setRanks={setRanks}
      handleInfiniteScroll={handleInfiniteScroll}
    />
  );
};

export default withRouter(CupTeamRankingContainer);
