import { gql } from "apollo-boost";

export const SEE_RANK = gql`
  query seeCupRanks($first: Int, $skip: Int, $cupId: String!) {
    seeCupRanks(first: $first, skip: $skip, cupId: $cupId) {
      id
      team {
        id
        teamName
        logo
      }
      winningRate
      win
      lose
      draw
    }
    seeCup(id:$cupId) {
      id
      games {
        id
        winner
      }
    }
  }
`;
