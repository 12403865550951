import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import {
  GET_ROOM,
  INVITE_USER,
  SEARCH_USER,
  ACCEPT_ROOM,
  DECLINE_ROOM
} from "./RoomUserManageQueries";
import RoomUserManagePresenter from "./RoomUserManagePresenter";
import { useQuery, useMutation } from "react-apollo-hooks";
import { toast } from "../../Components/Popup";

import useInput from "../../Hooks/useInput";

const RoomUserManageContainer = ({ history, match: { params } }) => {
  const roomId = params.room;
  const [applying, setApplying] = useState(false);
  const [searching, setSearching] = useState(false);




  // 다이얼로그 관련

  const [inviteUser, setInviteUser] = useState();
  const [openSearch, setOpenSearch] = useState(false);
  const [openInvite, setOpenInvite] = useState(false);
  const [openShare, setOpenShare] = useState(false);


  const [requestUsers, setRequestUsers] = useState([]);
  const [invitedUsers, setInvitedUsers] = useState([]);


  const search = useInput("");
  const [searchUsers, setSearchUsers] = useState();

  const handleSearchOpen = () => {
    setOpenSearch(true);
  };

  const handleSearchClose = () => {
    setOpenSearch(false);
  };

  const handleInviteOpen = (user) => () => {
    setInviteUser(user);
    setOpenInvite(true);
  };

  const handleInviteClose = () => {
    setInviteUser(null);
    setOpenInvite(false);
  };

  const handleShareOpen = () => {
    setOpenShare(true);
  };

  const handleShareClose = () => {
    setOpenShare(false);
  };

  const defaultHandleBack = () => {
    history.goBack();
  }




  const { data, loading, refetch } = useQuery(GET_ROOM, {
    variables: {
      id: roomId.toString(),
      sportId:"1001"
    }
  });

  const { loading:loadingSearch, data: searchData, refetch: refetchUser } = useQuery(SEARCH_USER, {
    skip: search.value === "",
    variables: {
      term: search.value
    }
  });

  const inviteMutation = useMutation(INVITE_USER);
  const acceptRoomMutation = useMutation(ACCEPT_ROOM);
  const declineRoomMutation = useMutation(DECLINE_ROOM);

  useEffect(() => {
    if(search.value.length > 1) {
      setSearching(true);
    } else {
      setSearching(false);
    }
    refetchUser();

    if(!loadingSearch && searchData && searchData.searchUser) {
      setSearchUsers(searchData.searchUser);
      setSearching(false);
    }
  }, [search.value, loadingSearch, searchData]);


  const handleProfileButton = id => () => {
    history.push(`/user/${id}`);
  }


  const onAcceptRoom = id => async e => {
    try {
      e.preventDefault();

      const newReqUsers = [...requestUsers];
      newReqUsers.splice(newReqUsers.findIndex(i => i.id === id),1);
      setRequestUsers(newReqUsers);

      const { data : { acceptRoom } } = await acceptRoomMutation({
        variables: {
          id:roomId,
          reqUserId: id
        }
      });
      if(acceptRoom)
        toast.success("요청을 수락했습니다.");
      else
        toast.error("오류가 발생했습니다.");
    
    } catch (e) {
      toast.error("오류가 발생했습니다.");
    }
  }

  const onDeclineRoom = id => async e => {
    try {
      console.log(id);
      const newReqUsers = [...requestUsers];
      newReqUsers.splice(newReqUsers.findIndex(i => i.id === id),1);
      setRequestUsers(newReqUsers);

      e.preventDefault();
      const { data : { declineRoom }  } = await declineRoomMutation({
        variables: {
          id : roomId,
          reqUserId: id
        }
      });
      console.log(declineRoom);

      if(declineRoom)
        toast.info("요청을 거부했습니다.");
      else
        toast.error("오류가 발생했습니다.");
    } catch (e) {
      toast.error("오류가 발생했습니다.");
    }
  }

  const handleInvite = async () => {

    const data = [...searchUsers];
    data.splice(data.findIndex(i => i.id === inviteUser.id), 1);
    setSearchUsers(data);


    
    handleInviteClose();

    try {
      setApplying(true);
      handleSearchClose();
      const {
        data: { inviteUserToRoom:result }
      } = await inviteMutation({
        variables: {
          id: roomId,
          userId: inviteUser.id
        }
      });

      if (result) {
        toast.success("팀 초대를 전송했습니다.");
        refetch();
      }
    } catch (e) {
      toast.error("오류가 발생했습니다.");
    } finally {
      setApplying(false);
    }
  };

  return (
    <RoomUserManagePresenter
      loading={loading}
      data={data}
      applying={applying}

      handleProfileButton={handleProfileButton}
      onAcceptRoom={onAcceptRoom}
      onDeclineRoom={onDeclineRoom}

      requestUsers={requestUsers}
      setRequestUsers={setRequestUsers}
      invitedUsers={invitedUsers}
      setInvitedUsers={setInvitedUsers}

      inviteUser={inviteUser}
      openSearch={openSearch}
      openInvite={openInvite}
      openShare={openShare}
      handleInviteOpen={handleInviteOpen}
      handleInviteClose={handleInviteClose}
      handleShareOpen={handleShareOpen}
      handleShareClose={handleShareClose}

      defaultHandleBack={defaultHandleBack}
      handleSearchOpen={handleSearchOpen}
      handleSearchClose={handleSearchClose}
      handleInvite={handleInvite}
      searchUsers={searchUsers}
      search={search}
      searching={searching}
    />
  );
};

export default withRouter(RoomUserManageContainer);
