import { gql } from "apollo-boost";

export const SEE_RECEIVES = gql`
  query seeMatchsReceiver($id:String!, $skip: Int, $first: Int) {
    seeMatchsReceiver(id:$id, skip: $skip, first: $first) {
      id
      createdAt
      game {
        id
        gameStatus
        gameDate
        startTime
        endTime
        place {
          id
          placeName
          shortAddress
        }
      }
      senderUser {
        id
        username
      }
      title
      status
      message
      reply
      sender {
        id
        logo
        profile {
          id
          url
        }
        place {
          id
          placeName
          address
        }
        location {
          id
          latitude
          longitude
        }
        teamName
        bio
        level
        userCount
      }
    }
  }
`;

export const SEE_SENDS = gql`
  query seeMatchsforSender($id:String!, $skip: Int, $first: Int) {
    seeMatchsforSender(id:$id, skip: $skip, first: $first) {
      id
      createdAt
      game {
        id
        gameStatus
        gameDate
        startTime
        endTime
        place {
          id
          placeName
          shortAddress
        }
      }
      status
      title
      message
      reply
      senderUser {
        id
        username
      }
      receiver {
        id
        logo
        profile {
          id
          url
        }
        place {
          id
          placeName
          address
        }
        location {
          id
          latitude
          longitude
        }
        teamName
        bio
        level
        userCount
      }
    }
  }
`;

export const NEW_MATCH = gql`
  subscription newMatch($id:String!) {
    newMatch(id:$id) {
      id
      createdAt
      game {
        id
        gameStatus
        gameDate
        startTime
        endTime
        place {
          id
          placeName
          shortAddress
        }
      }
      title
      status
      message
      sender {
        id
        logo
        profile {
          id
          url
        }
        place {
          id
          placeName
          address
        }
        location {
          id
          latitude
          longitude
        }
        teamName
        bio
        level
        userCount
      }
    }
  }
`;
