import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { fade, makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";

import IconButton from "@material-ui/core/IconButton";
import Badge from "@material-ui/core/Badge";
import Logo from "../../assets/img/header_logo.png";
import MyDrawer from "../MyDrawer";
import { Search, Menu, Alert, Ranking } from "../Icons";

import { ME, LOG_OUT } from "../../SharedQueries";
import { useQuery, useMutation } from "react-apollo-hooks";
import Client from "../../Apollo/Client";
import { gql } from "apollo-boost";


const READ_NOTIFICATION = gql`
  mutation readNotifications
  {
    readNotifications
  }
`;

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    position: "fixed",
    width: "100%",
    zIndex: "99",
    top:0
    // maxWidth: "500px"
  },
  menuButton: {
    marginRight: '-2px'
  },
  title: {
    flexGrow: 1,
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block"
    }
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25)
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto"
    }
  },
  searchIcon: {
    width: theme.spacing(7),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  inputRoot: {
    color: "inherit"
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: 120,
      "&:focus": {
        width: 200
      }
    }
  },
  toolBar: {
    justifyContent: "space-between"
  },
  appbar: {
    backgroundColor: "#227fff"
  }
}));

const MyAppBar = styled(AppBar)`
`;

const ButtonWrap = styled.div`
  & > a:nth-child(2) {
    & > button {
      margin-top: -5px;
    }
  }
`;

const HeaderLogo = styled.img`
  max-width: 120px;
  @media (max-width: 325px) {
    max-width: 100px;
  }
`;

export default function MainHeader() {
  const { data, loading } = useQuery(ME, { 
    pollInterval:2000
  });

  const readNotificationMutation = useMutation(READ_NOTIFICATION);

  const classes = useStyles();

  const [state, setState] = useState({
    right: false
  });

  const toggleDrawer = (side, open) => event => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [side]: open });
  };

  useEffect(() => {
    if(window.Android && data && data.me) {
      console.log("ME : ", data.me.id);
      if(typeof window.Android.passUserId === "function") {
        window.Android.passUserId(data.me.id);
      }
      
      if(typeof window.Android.passMeInfo === "function") {
        const passInfo = {
          teamId:data.me.myTeam? data.me.myTeam.id : "",
          reservationCount:data.me.reservationCount,
          notificationCount:data.me.notificationCount
        };
        window.Android.passMeInfo(JSON.stringify(passInfo));
      }
    }

    if(window.webkit && data && data.me) {
      try {
        //window.webkit.messageHandlers.postUser.postMessage("userId");
        
        if(typeof window.webkit.messageHandlers.postUser.postMessage === "function") {
          window.webkit.messageHandlers.postUser.postMessage({
            userId:data.me.id,
            teamId:data.me.myTeam? data.me.myTeam.id : "",
          });
        }
      } catch(e) {
        alert(e);
        console.log(e);
      }
    }
  }, [data]);

  if (loading) {
    return null;
  } else if (!loading && data && data.me) {

    const handleEnter = async () => {
      await readNotificationMutation()
      Client.writeData({
        id:`User:${data.me.id}`,
        data : {
          notificationCount:0
        }
      });
    };


    return (
      <div className={classes.root}>
        <MyAppBar className={classes.appbar} position="static">
          <Toolbar className={classes.toolBar}>
            <MyDrawer
              data={data}
              right={state.right}
              toggleDrawer={toggleDrawer}
            />

            <a href="/">
              <HeaderLogo src={Logo} alt="Logo" />
            </a>

            <ButtonWrap>
              <Link to="/search">
                <IconButton
                  color="inherit"
                  edge="end"
                  className={classes.menuButton}
                >
                  <Search />
                </IconButton>
              </Link>

              <Link to="/team/rank">
                <IconButton
                  color="inherit"
                  edge="end"
                  className={classes.menuButton}
                >
                  <Ranking /> 
                </IconButton>
              </Link>

              <Link onClick={handleEnter} to={`/notifications`}>
                <IconButton
                  color="inherit"
                  edge="end"
                  className={classes.menuButton}
                >
                  <Badge badgeContent={data.me.notificationCount} max={10} color="secondary">
                    <Alert />
                  </Badge>
                </IconButton>
              </Link>

             

              <IconButton
                edge="end"
                color="inherit"
                aria-label="open drawer"
                className={classes.menuButton}
                onClick={toggleDrawer("right", true)}
              >
                <Menu />
              </IconButton>
            </ButtonWrap>
          </Toolbar>
        </MyAppBar>
      </div>
    );
  } else {
    return null;
  }
}
