import { gql } from "apollo-boost";

export const GET_CHAT = gql`
  query seeChat($id:String!) {
    seeChat(id:$id) {
      id
      participants {
        id
        avatar
        itsMe
        username
      }
    }
    me {
      id
      avatar
      username
    }
  }
`;

export const GET_MESSAGES = gql`
  query seeMessages($last:Int, $skip:Int, $id:String!) {
    seeMessages(last:$last, skip:$skip, id:$id) {
      id
      text
      from {
        id
        avatar
        itsMe
        username
      }
      to {
        id
        avatar
        itsMe
        username
      }
      createdAt
    }
  }
`;


export const NEW_MESSAGE = gql`
  subscription newMessage($chatId: String!) {
    newMessage(chatId: $chatId) {
      id
      text
      from {
        id
        avatar
        username
      }
      to {
        id
        avatar
        username
      }
      createdAt
    }
  }
`;



export const SEND_MESSAGE = gql`
  mutation sendMessage($chatId:String, $message:String!, $toId:String) {
    sendMessage(chatId:$chatId, message:$message, toId:$toId) {
      id
      text
      from {
        id
        avatar
        itsMe
        username
      }
      to {
        id
        avatar
        itsMe
        username
      }
      createdAt
    }
  }
`;
