import React, { useEffect } from "react";
import styled from "styled-components";
import { Helmet } from "rl-react-helmet";
import { Link } from "react-router-dom";
import Loader from "../../Components/Loader";
import Avatar from "../../Components/Avatar";
import BaseHeader from "../../Components/Header/BaseHeader";
import moment from "moment";
import "moment/locale/ko";

const Wrapper = styled.div``;

const ContentWrapper = styled.div`
  padding-top: 56px;
  /* margin: auto 10px; */
  background-color: #fff;
  /* min-height: 100vh; */
  /* display: flex;
  flex-direction: column;
  overflow: auto; */
  color: #000;
  max-width: 500px;
  margin: 0 auto;
`;

const Content = styled.div`
  padding: 11px 11px 0px;
  position: relative;
  margin-bottom: 64px;
  max-width: 500px;
  min-height: calc(100% - 120px);
  /* max-height: calc(100vh - 120px); */
  overflow-y: auto;
  display: flex;
  flex-direction: column;
 
  position: absolute;
  width: 100%;
  background-color: #fff;
  max-height: calc(100% - 120px);
`;

const Circle = styled.div`
  height: 43px;
  width: 43px;
  border-radius: 50%;
  float: right;
`;

const MessageWrapper = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  /* margin: 16px 0; */
  padding: 11px 0;
`;

const NewMessageWrapper = styled.div`
  position: relative;
  width: 100%;
  /* margin: 16px 0; */
  padding: 11px 0;
`;

const TextWrapper = styled.div`
  min-height: 42px;
  width: 60%;
  margin: 0 10px;
  font-weight: 400;
  position: relative;
  word-break: break-all;
  color: #000;
  border-radius: 20px;
  display: flex;
  align-items: center;
  line-height: 1.5em;
  padding: 10px 15px;
`;

const CircleME = styled(Circle)`
  height: 43px;
  width: 43px;
  border-radius: 50%;
  float: right;
`;

const CircleTHEM = styled(Circle)`
  height: 43px;
  width: 43px;
  border-radius: 50%;
  float: left;
  color: #333333;
`;

const TextME = styled(TextWrapper)`
  float: right;
  background: #ebebeb;
  color: #333333;
  &::before {
    border-width: 15px 15px 10px 0;
    border-color: #ebebeb transparent transparent transparent;
    position: absolute;
    top: 10px;
    right: -9px;
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
  }
`;

const TextTHEM = styled(TextWrapper)`
  background: #fff;
  float: left;
  color: #000;
  border: 1px solid #999;
`;

const Bottom = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 64px;
  border-top: 1px solid;
  background: #ffffff;
  left: 50%;
  width: 100%;
  transform: translateX(-50%);
  max-width: 500px;
`;

const BottomTextInput = styled.textarea`
  height: calc(100% - 5px);
  /* height: 100%; */
  background: #ffffff;
  border: none;
  width: calc(100% - 64px);
  position: absolute;
  left: 0;
  padding: 0 5%;
  resize: none;
  padding-top: 20px;
  font-weight: 300;
  font-size: 16px;
  margin-top:2px;
`;

const BottomSendButton = styled.button`
  position: absolute;
  height: 43px;
  width: 43px;
  border-radius: 50%;
  border: 0;
  background: #f44336;
  color: #ffffff;
  bottom: 10px;
  right: 10px;
  &::before {
    content: "";
    background: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/104946/ic_send_white_48dp.png)
      no-repeat center center;
    background-size: 25.6px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
`;

const TimeMEText = styled.div`
  font-size: 12px;
  display: flex;
  min-height: 42px;
  float: right;
  align-items: center;
`;

const TimeTHEMText = styled.div`
  font-size: 12px;
  display: flex;
  min-height: 42px;
  float: left;
  align-items: center;
`;

const DateContainer = styled.div``;

const DateText = styled.div`
  color: #000;
  font-size: 12px;
  text-align: center;
  margin: 10px 0px 20px;
  display: flex;
  flex-basis: 100%;
  align-items: center;

  &::before,
  &::after {
    content: "";
    flex-grow: 1;
    background: rgba(0, 0, 0, 0.35);
    height: 1px;
    font-size: 0px;
    line-height: 0px;
    margin: 0px 5px;
  }
`;

export default ({
  canMore,
  loadMore,
  onFocus,
  loading,
  data,
  dataMessages,
  loadingMessages,
  setChatId,
  setToId,
  newMessages,
  setOldMessages,
  oldMessages,
  onSubmit,
  textAreaRef,
  wrapRef,
  scrollToRef,
  messageInput,
  onKeyPress,
  Today,
  setToday,
  onScroll
}) => {
  if (loading || loadingMessages) {
    return (
      <Wrapper>
        {loadingMessages && (
          <>
          <BaseHeader text={"로딩 중"} isBack={true} isClose={false} />

          <Helmet>
            <title>{"메세지"} | WePlay</title>
          </Helmet>
          <Loader background={true} text={"메시지를 불러오고 있습니다."}/>
          </>
        )}
        {!loading && data && data.seeChat &&  (
          <>
          <BaseHeader text={
            data.seeChat.participants.filter(p => p.id !== data.me.id)[0]
              .username
          } isBack={true} isClose={false} />

          <Helmet>
            <title>
              {
                data.seeChat.participants.filter(p => p.id !== data.me.id)[0]
                  .username
              }{" "}
              | WePlay
            </title>
          </Helmet>
          </>
        )}

        <ContentWrapper bgColor={"#fff"}>
          <Content>
            
          </Content>
          <Bottom>
            <BottomTextInput
              onKeyPress={onKeyPress}
              value={messageInput.value}
              onChange={messageInput.onChange}
              placeholder={"메시지를 작성해주세요."}
            ></BottomTextInput>
            <BottomSendButton onClick={onSubmit}></BottomSendButton>
          </Bottom>
        </ContentWrapper>
      </Wrapper>
    );
  } else if (!loading && data && data.seeChat && dataMessages && dataMessages.seeMessages) {

    useEffect(() => {
      setChatId(data.seeChat.id);
      setToId(data.seeChat.participants.filter(p => p.id !== data.me.id)[0].id);

      setToday(moment().format("YYYY년 MM월 DD일 (ddd)"));

      if (wrapRef.current) {
        scrollToRef();
      }
    }, []);

    useEffect(() => {
      setOldMessages(
        dataMessages.seeMessages.reduce((acc, item) => {
          (acc[moment(item.createdAt).format("YYYY년 MM월 DD일 (ddd)")] =
            acc[moment(item.createdAt).format("YYYY년 MM월 DD일 (ddd)")] || []).push(
            item
          );
          return acc;
        }, {})
      );
    }, [dataMessages])

    //console.log(data.seeChat.messages);
    

    return (
      <Wrapper>
        <BaseHeader
          text={
            data.seeChat.participants.filter(p => p.id !== data.me.id)[0]
              .username
          }
          isBack={true}
          isClose={false}
        />
        {/* <Loader background={false} /> */}
        <Helmet>
          <title>
            {
              data.seeChat.participants.filter(p => p.id !== data.me.id)[0]
                .username
            }{" "}
            | WePlay
          </title>
        </Helmet>

        <ContentWrapper bgColor={"#fff"} >
          <form>
            <Content ref={wrapRef} onScroll={e => onScroll(e)}>
              {loading && <Loader background={false} />}

              {oldMessages &&
                Object.keys(oldMessages).map((key, i) => {
                  return (
                    <DateContainer key={key}>
                      <DateText>{key}</DateText>

                      {oldMessages[key].length > 0 &&
                        oldMessages[key].map((message, index) =>
                          message.from.itsMe ? (
                            <MessageWrapper key={index}>
                              <CircleME>
                                <Avatar size={"sm"} url={message.from.avatar} />
                              </CircleME>
                              <TextME>{message.text}</TextME>
                              <TimeMEText>
                                {moment(message.createdAt).format("A hh:mm")}
                              </TimeMEText>
                            </MessageWrapper>
                          ) : (
                            <MessageWrapper key={index}>
                              <CircleTHEM>
                                <Link to={`/user/${message.from.id}`}>
                                  <Avatar
                                    size={"sm"}
                                    url={message.from.avatar}
                                  />
                                </Link>
                              </CircleTHEM>
                              <TextTHEM>{message.text}</TextTHEM>
                              <TimeTHEMText>
                                {moment(message.createdAt).format("A hh:mm")}
                              </TimeTHEMText>
                            </MessageWrapper>
                          )
                        )}
                    </DateContainer>
                  );
                })}

              {/* {oldMessages &&
                oldMessages.length > 0 &&
                oldMessages.map((message, index) =>
                  message.from.itsMe ? (
                    <MessageWrapper key={index}>
                      <CircleME>
                        <Avatar size={"sm"} url={message.from.avatar} />
                      </CircleME>
                      <TextME>{message.text}</TextME>
                      <TimeMEText>
                        {moment(message.createdAt).format("A hh:mm")}
                      </TimeMEText>
                    </MessageWrapper>
                  ) : (
                    <MessageWrapper key={index}>
                      <CircleTHEM>
                        <Link to={`/user/${message.from.id}`}>
                          <Avatar size={"sm"} url={message.from.avatar} />
                        </Link>
                      </CircleTHEM>
                      <TextTHEM>{message.text}</TextTHEM>
                      <TimeTHEMText>
                        {moment(message.createdAt).format("A hh:mm")}
                      </TimeTHEMText>
                    </MessageWrapper>
                  )
                )} */}
              {newMessages && newMessages.length > 0 && 
                Today !== Object.keys(oldMessages)[Object.keys(oldMessages).length - 1] && (
                 <DateText>{Today}</DateText>
              )}

              {newMessages &&
                newMessages.length > 0 &&
                newMessages.map((message, index) => {
                  return message.from.id === data.me.id ? (
                    <NewMessageWrapper key={index}>
                      <CircleME>
                        <Avatar size={"sm"} url={data.me.avatar} />
                      </CircleME>
                      <TextME>{message.text}</TextME>
                      <TimeMEText>
                        {moment(message.createdAt).format("A hh:mm")}
                      </TimeMEText>
                    </NewMessageWrapper>
                  ) : (
                    <NewMessageWrapper key={index}>
                      <CircleTHEM>
                        <Link to={`/user/${message.from.id}`}>
                          <Avatar size={"sm"} url={message.from.avatar} />
                        </Link>
                      </CircleTHEM>
                      <TextTHEM>{message.text}</TextTHEM>
                      <TimeTHEMText>
                        {moment(message.createdAt).format("A hh:mm")}
                      </TimeTHEMText>
                    </NewMessageWrapper>
                  );
                })}
            </Content>

            <Bottom>
              <BottomTextInput
                onKeyPress={onKeyPress}
                ref={textAreaRef}
                value={messageInput.value}
                onChange={messageInput.onChange}
                placeholder={"메시지를 작성해주세요."}
                onFocus={onFocus}
              ></BottomTextInput>
              <BottomSendButton onClick={onSubmit}></BottomSendButton>
            </Bottom>
          </form>
        </ContentWrapper>
      </Wrapper>
    );
  }
};
