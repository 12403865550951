import { gql } from "apollo-boost";

export const SEE_HISTORYS = gql`
  query seeGameHistorys($sportId: String!, $teamId:String!, $first: Int, $skip: Int) {
    seeGameHistorys(sportId: $sportId, teamId:$teamId, first: $first, skip: $skip) {
      id
      homeTeam {
        id
        teamName
        isMine
      }
      awayTeam {
        id
        teamName
        isMine
      }
      result {
        id
        homeScore
        awayScore
        sendTeam {
          id
        }
      }
      usr_MVP {
        id
        username
        basket {
          id
          backNo
        }
      }
      didVote
      winner
      gameDate
      gameStatus
      startTime
      endTime
      place {
        id
        placeName
      }
    }
  }
`;

export const SEE_TEAM = gql`
 query seeFullTeam($teamId:String!) {
  seeFullTeam(id: $teamId) {
    id
    logo
    teamName
    isHost
    isManager
    profile {
      id
      url
    }
  }
 }
`;

export const UPDATE_HISTORY = gql`
  subscription updateHistorys($teamId: String!) {
    updateHistorys(teamId: $teamId) {
      id
      homeTeam {
        id
        teamName
      }
      awayTeam {
        id
        teamName
      }
      result {
        id
        homeScore
        awayScore
        sendTeam {
          id
        }
      }
      usr_MVP {
        id
        username
        basket {
          id
          backNo
        }
      }
      winner
      gameDate
      gameStatus
      startTime
      endTime
      place {
        id
        placeName
      }
    }
  }
`;