import React from "react";
import styled from "styled-components";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "./Avatar";
import { Arrow } from "./Icons";
import { IconButton } from "@material-ui/core";

const NoneText = styled.span`
  color: #000;
  font-size: 15px;
  margin-right: 30px;
`;

const BlueText = styled.span`
  color: #227fff;
  font-size: 15px;
  margin-right: 30px;
`;

const RedText = styled.span`
  color: #ff503b;
  font-size: 15px;
  margin-right: 30px;
`;

export default function VoteList({
  url,
  primary,
  secondary,
  isButton = true,
  answer,
  isHost
}) {
  return (
    <ListItem button={isButton}>
      <ListItemAvatar>
        <Avatar size={"sm"} url={url} isHost={isHost} />
      </ListItemAvatar>
      <ListItemText primary={primary} secondary={secondary} />

      {answer ? (
        answer === "AGREE" ? (
          <BlueText>참가</BlueText>
        ) : (
          <RedText>불참</RedText>
        )
      ) : (
        <NoneText>미정</NoneText>
      )}
      <ListItemSecondaryAction>
        <IconButton edge="end" color="inherit" aria-label="detail">
          <Arrow />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );
}
