import { gql } from "apollo-boost";

export const GET_TEAM = gql`
  query seeFullTeam($id: String!) {
    seeFullTeam(id: $id) {
      id
      teamName
      profile {
        id
        url
      }
      place {
        id
        placeName
        address
        location {
          id
          latitude
          longitude
        }
      }
      logo 
      avgAge
      avgLevel
      uniformColor
      matchingInfo
      matchingPosition
      contact
      bio
      level 
      canRequest
      isInvited
      isRequest
      isMine
      isHost
      isManager
      userCount
      host {
        id
        avatar
        username  
        level 
        basket {
          id
          position
          backNo
        }
      }
      managers {
        id
        avatar
        username
        level 
        basket {
          id
          position
          backNo
        }
      }
      users {
        id
        avatar
        username
        level 
        basket {
          id
          position
          backNo
        }
      }
    }
    me {
      id
      isSomeHost
    }
  }
`;

export const GET_REQUEST = gql`
  query seeUserRequest($id:String!) {
    seeUserRequest(id:$id) {
      id
      declineMessage
    }
  }
`;

export const APPLY_TEAM = gql`
  mutation reqTeam($id: String!) {
    reqTeam(id: $id)
  }
`;

export const ADD_MANAGER = gql`
  mutation addManager($id:String!, $oppId:String!) {
    addManager(id: $id, oppId:$oppId) 
  }
`;

export const REMOVE_MANAGER = gql`
  mutation removeManager($id:String!, $oppId:String!) {
    removeManager(id: $id, oppId:$oppId) 
  }
`;

export const WITHDRAW_TEAM = gql`
  mutation withdrawTeam($id:String!) {
    withdrawTeam(id: $id) 
  }
`;

export const KICKBACK_USER = gql`
  mutation kickbackUser($id:String!, $oppId:String!) {
    kickbackUser(id: $id, oppId:$oppId) 
  }
`;

export const TEAM_DELETE = gql`
  mutation editTeam($id: String!,$action: String!) {
    editTeam(id: $id, action: $action) {
      id
    }
  }
`;


export const NEW_INFO = gql`
  subscription subscriptionTeam($id:String!) {
    subscriptionTeam(id:$id) {
      id
      teamName
      profile {
        id
        url
      }
      logo 
      avgAge
      avgLevel
      uniformColor
      matchingInfo
      matchingPosition
      contact
      bio
      level 
      userCount
      host {
        id
        avatar
        username  
        level 
        basket {
          id
          position
          backNo
        }
      }
      managers {
        id
        avatar
        username
        level 
        basket {
          id
          position
          backNo
        }
      }
      users {
        id
        avatar
        username
        level 
        basket {
          id
          position
          backNo
        }
      }
    }
  }
`;