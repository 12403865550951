import React from "react";
import styled from "styled-components";
import { Helmet } from "rl-react-helmet";
import Loader from "../../Components/Loader";
import BoldText from "../../Components/BoldText";
import BaseHeader from "../../Components/Header/BaseHeader";
import PostCode from "../../Components/PostCode";
import Button from "../../Components/Button";
import Input from "../../Components/Input";
import Select from "../../Components/Select";
import DatePicker from "../../Components/DatePicker";
import TimePicker from "../../Components/TimePicker";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Minus } from "../../Components/Icons";

const Wrapper = styled.div`
  width: 100%;
  flex-grow: 1;
  @media (min-width: 500px) {
    margin: 0 auto;
  }
  & .MuiInputBase-input {
    background-color: #ebebeb !important;
    border-radius: 8px !important;
    padding: 10px 10px !important;
  }
`;

const UserInfoRows = styled.div`
  flex-grow: 1;
  /* margin: 0px 10px; */
  background-color: #fff;
  color: #000;
  min-height: 55vh;
  padding-top: 64px;
  padding-bottom: 70px;
  @media (min-width: 500px) {
    margin: 0 auto;
    max-width:500px;
  }
`;

const UserInfoRow = styled.div`
  display: flex;
  font-size: 15px;

  flex-direction: column;
  padding: 15px 20px;
`;

const InputInfoColumn = styled.div`
  position: relative;
  &:nth-child(1) {
    margin-bottom: 15px;
    font-weight: 500;
    font-size: 16px;
  }
  &:nth-child(2) {
    display: flex;
    flex-direction: column;
  }
`;

const TitleRow = styled.div`
  margin-bottom: 15px;
  font-weight: 500;
  font-size: 16px;
`;

const ProfileHint = styled.span`
  font-size: 14px;
  padding-left: 10px;
`;

const BioTextarea = styled.textarea`
  width: 100%;
  max-width: 500px;
  padding: 5px 15px;
  background-color: #ebebeb;
  border: ${props => props.theme.boxBorder};
  border-radius: 10px;
  color: #000;
  resize: none;
  font-size: 16px;
  font-family: "NanumSqure";
  line-height:1.5em;
  &:focus {
    background-color: #fff;
    border: 1px solid #2b2b2b;
  }
`;

const InputAdornment = styled.div`
      position: absolute;
    right: 10px;
    top: 10px;
    font-weight: 600;
`;

const LogoUploadWapper = styled.div`
  position: relative;
  overflow: hidden;
  display: inline-block;

  width: 195px;
  height: 110px;
  margin-right: 15px;
  & > input[type="file"] {
    position: absolute;
    height: 100%;
    left: 0;
    top: 0;
    opacity: 0;
  }
`;

const RemoveBtn = styled.button`
  border: none;
  /* color: gray; */
  background-color: #fff;
  padding: 0px;
  border-radius: 50%;
  font-size: 25px;
  font-weight: bold;
  width: 30px;
  height: 30px;
  position: absolute;
  right: 0;
  bottom: 0;
`;

const BottomWrap = styled.div`
  display: flex;
  justify-content: center;
`;

const PreviewImg = styled.img`
  border: 1px solid #000;
  background-color: white;
  border-radius: 8px;
  width: 100%;
  height: 100%;
`;

const ProfileColumn = styled.div`
  width: auto;
  white-space: nowrap;
  overflow-x: auto;
`;

const ProfileUploadWapper = styled(LogoUploadWapper)`
  width: 195px;
  height: 110px;
  margin-right: 15px;
`;

const UploadBtn = styled.button`
  border: 1px solid #000;
  color: #000;
  background-color: white;
  padding: 5px;
  border-radius: 8px;
  font-size: 15px;
  width: 100%;
  height: 100%;
  cursor: pointer;
`;

export default ({
  uploading,
  gameDate,
  startTime,
  endTime,
  title,
  message,
  contact,
  purpose,
  price,
  playerCnt,

  onSubmit,
  onClickClose,
  setAddressInfo,

  pictures,
  handlePictureChange,
  onRemovePicture
}) => {
  return (
    <Wrapper>
      <Helmet>
        <title>오픈방 생성 | WePlay</title>
      </Helmet>
      {uploading && <Loader />}
      <BaseHeader
        isClose={true}
        text={"오픈방 생성"}
        onClickClose={onClickClose}
      />
        <UserInfoRows>
          <UserInfoRow>
            <InputInfoColumn>
              <BoldText text={"오픈방 소개"} />
              <ProfileHint>* 소개는 최대 300자 제한</ProfileHint>
            </InputInfoColumn>
            <TitleRow>
              <Input
                value={title.value}
                defaultValue={""}
                onChange={title.onChange}
                placeholder={"제목을 입력하세요"}
              />
            </TitleRow>

            <InputInfoColumn>
              <BioTextarea {...message} cols="4" rows="6"></BioTextarea>
            </InputInfoColumn>
          </UserInfoRow>

          <UserInfoRow>
            <InputInfoColumn>
              <BoldText text={"오픈방 일정"} />
              <ProfileHint>*</ProfileHint>
            </InputInfoColumn>
            <InputInfoColumn>
              <DatePicker gameDate={gameDate} />
            </InputInfoColumn>
          </UserInfoRow>

          <UserInfoRow>
            <InputInfoColumn>
            <BoldText text={"오픈방 시간"} />
              <ProfileHint>*</ProfileHint>
            </InputInfoColumn>
            <InputInfoColumn>
              <TimePicker startTime={startTime} endTime={endTime} />
            </InputInfoColumn>
          </UserInfoRow>

          <UserInfoRow>
            <InputInfoColumn>
              <BoldText text={"경기장 사진"} />
              <ProfileHint>*전체, 골대, 바닥 3가지 사진은 필수 / 최대 5장</ProfileHint>
            </InputInfoColumn>
            <ProfileColumn>
              {pictures &&
                pictures.length > 0 &&
                pictures.map(profile => (
                  <ProfileUploadWapper key={profile.name}>
                    <PreviewImg key={profile.name} src={profile.url} />
                    <RemoveBtn onClick={onRemovePicture(profile)}>
                      <Minus size={30} />
                    </RemoveBtn>
                  </ProfileUploadWapper>
                ))}

              <ProfileUploadWapper>
                <UploadBtn>+ 등록하기</UploadBtn>
                <Input
                  onChange={handlePictureChange}
                  placeholder={""}
                  type={"file"}
                  name={"profile"}
                />
              </ProfileUploadWapper>
            </ProfileColumn>
          </UserInfoRow>
          <UserInfoRow>
            <InputInfoColumn>
              <BoldText text={"경기장"} />
              <ProfileHint>*</ProfileHint>
            </InputInfoColumn>
            <InputInfoColumn>
              <PostCode
                setAddressInfo={setAddressInfo}
                defaultValue={""}
                text={"경기장 변경"}
              />
            </InputInfoColumn>
          </UserInfoRow>

          <UserInfoRow>
            <InputInfoColumn>
              <BoldText text={"경기장 연락처"} />
            </InputInfoColumn>
            <InputInfoColumn>
              <Input
                value={contact.value}
                defaultValue={""}
                onChange={contact.onChange}
                placeholder={"'-'를 제외하고 입력해주세요"}
              />
            </InputInfoColumn>
          </UserInfoRow>

          <UserInfoRow>
            <InputInfoColumn>
              <BoldText text={"오픈방 목적"} />
              <ProfileHint>*</ProfileHint>
            </InputInfoColumn>
            <InputInfoColumn>
              <Select
                items={[
                  { text: "코칭 + 게임 진행", value: "코칭 + 게임 진행" },
                  { text: "게임만 진행", value: "게임만 진행" },
                  { text: "코칭만 진행", value: "코칭만 진행" }
                ]}
                value={purpose.value}
                onChange={purpose.onChange}
              />
            </InputInfoColumn>
          </UserInfoRow>

          <UserInfoRow>
            <InputInfoColumn>
              <BoldText text={"모집 인원"} />
              <ProfileHint>*</ProfileHint>
            </InputInfoColumn>
            <InputInfoColumn>
              <Input
                value={playerCnt.value}
                onChange={playerCnt.onChange}
                placeholder={"모집 인원을 입력해주세요."}
              />
              <InputAdornment>명</InputAdornment>
              {/* <Select
                items={[
                  { text: "3 vs 3", value: 3 },
                  { text: "5 vs 5", value: 5 }
                ]}
                value={playerCnt.value}
                onChange={playerCnt.onChange}
              /> */}
            </InputInfoColumn>
          </UserInfoRow>

          <UserInfoRow>
            <InputInfoColumn>
              <BoldText text={"오픈방 참가비"} />
              <ProfileHint>*</ProfileHint>
            </InputInfoColumn>
            <InputInfoColumn>
              <Input
                value={price.value}
                onChange={price.onChange}
                placeholder={"참가 비용을 입력해주세요."}
              />
              <InputAdornment>원</InputAdornment>
            </InputInfoColumn>
          </UserInfoRow>

          <BottomWrap>
            {uploading ? (
              <CircularProgress />
            ) : (
              <Button color={"#000"} onClick={onSubmit} text={"오픈방 생성"} />
            )}
          </BottomWrap>
        </UserInfoRows>
    </Wrapper>
  );
};
