import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";

import DetailIcon from "../assets/img/detail_view_btn.png";
import RecruitBadge from "../assets/img/recruitment_badge.png";
import { Badge } from "@material-ui/core";

import moment from "moment";
import "moment/locale/ko";

const useStyles = makeStyles(theme => ({
  cardWrap: {
    borderRadius: 10,
    margin:15
  },
  card: {
    display: "flex",
    minHeight:"120px",
    justifyContent:"space-between"
  },
  details: {
    display: "flex",
    flexDirection: "column"
  },
  content: {
    padding:13,
    '& > .MuiBadge-root' : {
      position:"absolute"
    }
  },
  cover: {
    width: 110,
    height:110,
    borderRadius:"50%",
    margin:5,
    flex: "0 0 auto"
  },
  controls: {
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  playIcon: {
    height: 38,
    width: 38
  },
  title: {
    fontSize: 20,
    fontWeight: 800,
    marginTop:15,
    fontFamily: "unset",
    paddingLeft:'10px'
  },
  subtitle: {
    fontSize: 18,
    fontWeight: 800,
    fontFamily: "unset",
    paddingLeft:'10px'
  },
  cardBadge: {
    "& > .MuiBadge-badge": {
      left: "11px",
      top: "11px"
    },
    "& img": {
      width: "50px"
    }
  }
}));

const CardTop = styled.div``;

const CardBottom = styled.div`
  background-color: #bad2f4;
  height: 50px;
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const BottomColumn = styled.div`
  font-size: 13px;
  & img {
    max-width: 65px;
  }
`;

const LevelSpan = styled.span`
  padding-left: 4px;
`;

const PlayerCountSpan = styled.span`
  margin-left: 20px;
`;

const TimeInfo = styled.div`
    padding-top: 3px;
    font-weight: 600;
`;

const _playerCountText = count => {
  return `${count}x${count}`;
};


export default function GameCard({
  id,
  teamName,
  level = 3,
  playerCnt,
  shortAddress,
  gameDate,
  startTime,
  gameStatus,
  logo
}) {
  const classes = useStyles();
  const startDateTime = moment(gameDate).format("YYYY-MM-DD")+" "+startTime;
  return (
    <Link to={`/game/${id}`}>
    <Card className={classes.cardWrap}>
      <CardTop className={classes.card}>
        <CardContent className={classes.content}>
          {gameStatus === "RECRUIT" && (
            <Badge
              overlap="rectangle"
              className={classes.cardBadge}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left"
              }}
              badgeContent={<img alt="Recruit" src={RecruitBadge} />}
            ></Badge>
          )}

          <Typography className={classes.title} component="h5" variant="h5">
            {teamName}
          </Typography>
          <Typography className={classes.subtitle} variant="subtitle1">
            Level.<LevelSpan>{level}</LevelSpan>
            <PlayerCountSpan>{_playerCountText(playerCnt)}</PlayerCountSpan>
          </Typography>
        </CardContent>
        <CardMedia
          image={logo ? logo : "/assets/test.png"}
          className={classes.cover}
          title="Live from space album cover"
        />
      </CardTop>

      <CardBottom>
        <BottomColumn>
          {shortAddress}
          <TimeInfo>
            {moment(startDateTime).format("MM월 DD일")} {moment(startDateTime).format("A hh시 mm분")}
          </TimeInfo>
          
        </BottomColumn>

        <BottomColumn>
          
            <img src={DetailIcon} alt="상세 보기" />
          
        </BottomColumn>
      </CardBottom>
    </Card>
    </Link>
  );
}
GameCard.propTypes = {
  id: PropTypes.string.isRequired,
  teamName: PropTypes.string.isRequired,
  level: PropTypes.number.isRequired,
  shortAddress: PropTypes.string.isRequired,
  gameDate: PropTypes.string.isRequired,
  logo: PropTypes.string
};
