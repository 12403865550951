import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const DialogContentText = styled.div`
  margin-bottom: 12px;
  font-size: 15px;
  line-height: 1.8em;
  & > p {
    font-weight: 600;
    margin-top: 15px;
  }
`;

const AgreeText = ({ type }) => {
  if (type === "service-usage") {
    return (
      <DialogContentText>
        <p>제 1 조 목적</p>
        이 약관은 주식회사 위플레이(이하 "회사")에서 제공하는 "위플레이"에서
        제공하는 제반 서비스(이하 "서비스")에 접속과 사용자에 의해서 업로드 및
        다운로드 되어 표시되는 모든 정보, 텍스트, 이미지 및 기타 자료를 이용하는
        이용자(이하 "회원")와 서비스 이용에 관한 권리 및 의무와 책임사항, 기타
        필요한 사항을 규정하는 것을 목적으로 합니다.
        <br />
        <p>제2조 약관의 게시와 효력, 개정</p>
        1. 회사는 서비스의 메인 페이지에 본 약관을 게시합니다.  
        <br />
        2. 회사는 관련법에 위배되지 않는 범위에서 본 약관을 변경할 수 있습니다. 
        약관이 변경될 경우 회사는 회원에게 7일 전에 서비스 페이지에 이를 공지합니다.
        <br />
        3. 회원은 회사가 전항에 따라 변경하는 약관에 동의하지 않을 권리가
        있으며, 이 경우 회원은 회사에서 제공하는 서비스 이용 중단 및 탈퇴
        페이지에서 의사를 표시하고 서비스 이용종료를 요청할 수 있습니다. 다만,
        회사가 회원에게 변경된 약관의 내용을 통보하면서 회원에게 7일 이내
        의사표시를 하지 않을 경우 의사표시가 표명된 것으로 본다는 뜻을 명확히
        통지하였음에도 불구하고 거부의 의사표시를 하지 아니한 경우 회원이 변경된
        약관에 동의하는 것으로 봅니다.
        <br />
        <p>제3조 약관의 해석과 예외 준칙</p>
        1. 회사는 제공하는 개별 서비스에 대해서 별도의 이용약관 및 정책을 둘 수
        있으며, 해당 내용이 이 약관과 상충할 경우 개별 서비스의 이용약관을
        우선하여 적용합니다.
        <br />
        2. 본 약관에 명시되지 않은 사항이 관계법령에 규정되어 있을 경우에는 그
        규정에 따릅니다.
        <br />
        <p>제4조 용어의 정의</p>
        1. 서비스: 개인용 컴퓨터 (PC), TV, 휴대형 단말기, 전기통신설비 등 포함
        각종 유무선 장치와 같이 구현되는 단말기와 상관없이 회원이 이용할 수 있는
        "위플레이" 관련 제반 서비스를 의미합니다.
        <br />
        2. 회원: 회사와 서비스 이용계약을 체결하고 회사가 제공하는 서비스를
        이용하는 모든 사용자를 의미합니다.
        <br />
        3. 아이디: 회원이 가입할 때 입력한 회원 본인의 이메일 주소를 의미합니다.
        <br />
        4. 비밀번호: 회원의 개인 정보 및 확인을 위해서 회원이 정한 문자 또는
        숫자의 조합을 의미합니다.
        <br />
        5. 도메인: 회원의 서비스 이용을 위하여 회사가 부여한 고유한 인터넷
        주소를 의미합니다. 회사는 제공하는 제반 서비스를 위해서 서비스에 따라
        별도의 도메인 주소를 추가적으로 제공합니다.
        <br />
        6. 게시물: 회원이 서비스를 이용함에 있어 회원이 서비스에 게시한 문자,
        문서, 그림, 음성, 링크, 파일 혹은 이들의 조합으로 이루어진 정보 등 모든
        정보나 자료를 의미합니다.
        <br />
        7. 유료서비스: 회사가 유료로 제공하는 각종 온라인디지털콘텐츠 및 제반
        서비스를 의미합니다.
        <br />
        8. 위플코인: 서비스를 이용 또는 구매하기 위해 사용되는 가상의 화폐
        단위로서 회원이 대금을 지급하고 구입하는 것을 의미합니다.
        <br />
        9. 포인트: 회원이 서비스를 이용하는 행위에 대한 보상으로 회원에게
        지급되는 무료 화폐단위를 의미합니다.
        <br />
        <p>제5조 이용계약의 체결</p>
        1. 이용계약은 회원이 "위플레이" 서비스 및 제반 서비스에서 제공하는
        회원가입 페이지에서 서비스 이용약관에 동의한 후 이용신청을 하고 신청한
        내용에 대해서 회사가 승낙함으로써 체결됩니다.
        <br />
        2. 회사는 이용약관에 동의한 후 이용신청한 사용자에 대해서 원칙적으로
        기존회원들의 의한 심사통과 후 서비스 이용을 승낙함을 원칙으로 합니다.
        다만 업무 수행상 또는 기술상 지장이 있을 경우 일정시간 가입승인을 유보할
        수 있습니다.
        <br />
        3. 회사는 다음 각 호에 해당하는 신청에 대해서 승낙하지 않거나 사후에
        이용계약을 해지할 수 있습니다.
        <br />
        4. 가입신청자가 이 약관에 의하여 이전에 회원자격을 상실한 적이 있는 경우
        <br />
        5. 제3자의 전자우편 주소를 이용하여 신청한 경우
        <br />
        6. 허위의 정보를 기재하거나, 회사가 필수적으로 입력을 요청한 부분을
        기재하지 않은 경우
        <br />
        7. 부정한 용도로 서비스를 사용하고자 하는 경우
        <br />
        8. 이용자의 귀책사유로 인하여 승인이 불가능하거나 기타 규정한 제반
        사항을 위반하며 신청하는 경우
        <br />
        9. 회사의 정책에 적합하지 않는 회원으로 판단되는 경우나 서비스 제공이
        곤란한 경우
        <br />
        10. 회원의 이용 목적이나 서비스 이용 방법이 회사의 재산권이나 영업권을
        침해하거나 침해할 우려가 있는 경우
        <br />
        11. 비정상적인 방법을 통하여 아이디 및 도메인을 대량으로 생성하는 행위
        <br />
        12. 회사는 회원에 대해 회사정책에 따라 등급별로 구분하여 이용시간,
        이용횟수, 서비스 메뉴 등을 세분하여 이용에 차등을 둘 수 있습니다.
        <br />
        13. 회원은 회원 가입 시 기재한 개인정보의 내용에 변경이 발생한 경우,
        즉시 변경사항을 정정하여 기재하여야 합니다. 변경의 지체로 인하여 발생한
        회원의 손해에 대해 회사는 책임을 지지 않습니다.
        <br />
        14. 회사는 관련 법률 및 회사의 개인정보취급방침에서 정한 바에 따라
        회원에게 요청하는 회원정보 및 기타정보 항목을 추가, 삭제 등 변경하여
        수집 및 이용할 수 있습니다.
        <br />
        <p>제6조 개인정보보호 의무</p>
        1. 회사는 정보통신망법 등 관계 법령이 정하는 바에 따라 회원의 개인정보를
        보호하기 위해 노력합니다. 개인정보의 보호 및 사용에 대해서는 관련법 및
        회사의 개인정보취급방침이 적용됩니다. 다만, 회사의 공식 사이트 이외의
        링크된 사이트에서는 회사의 개인정보취급방침이 적용되지 않습니다.
        <br />
        2. 회사는 서비스를 중단하거나 회원이 개인정보 제공 동의를 철회한
        경우에는 신속하게 회원의 개인정보를 파기합니다. 단, 전자상거래 등에서의
        소비자 보호에 관한 법률 등 관련 법률에서 정하는 바에 따라 일정 정보는
        보관할 수 있습니다.
        <br />
        3. 회사는 서비스 개선 및 회원 대상의 서비스 소개 등의 목적으로 회원의
        동의 하에 관계 법령에서 정하는 바에 따라 추가적인 개인정보를 수집할 수
        있습니다.
        <br />
        4. 회사는 법률에 특별한 규정이 있는 경우를 제외하고는 회원의 별도 동의
        없이 회원의 계정정보를 포함한 일체의 개인정보를 제3자에게 공개하거나
        제공하지 아니합니다.
        <br />
        5. 회사는 향후 제공하는 서비스에서 회원의 편의를 위해서 회원의 계정
        정보를 사용할 수 있도록 링크 및 기타 방법을 제공할 수 있습니다.
        <p>제7조 회원의 아이디 및 비밀번호</p>
        1. 회원은 1인 1계정을 원칙으로 합니다.
        <br />
        2. 회원은 아이디와 비빌번호에 관해서 관리책임이 있습니다.
        <br />
        3. 회원은 아이디 및 비밀번호를 제3자가 이용하도록 제공하여서는 안됩니다.
        <br />
        4. 회사는 회원이 아이디 및 비밀번호를 소홀히 관리하여 발생하는 서비스
        이용상의 손해 또는 회사의 고의 또는 중대한 과실이 없는 제3자의 부정이용
        등으로 인한 손해에 대해 책임을 지지 않습니다.
        <br />
        5. 회원은 아이디 및 비밀번호가 도용되거나 제3자가 사용하고 있음을 인지한
        경우에는 이를 즉시 회사에 통지하고 회사의 안내에 따라야 합니다.
        <br />
        <p>제8조 결제, 환불 및 결제 취소</p>
        1. 회원은 회사가 제공하는 다양한 결제수단을 통해 유료서비스를 이용할 수
        있으며, 결제가 비정상적으로 처리되어 정상처리를 요청할 경우 회사는
        회원의 결제금액을 정상처리 할 의무를 가집니다.
        <br />
        2. 회사는 부정한 방법 또는 회사가 금지한 방법을 통해 충전 및 결제된
        금액에 대해서는 이를 취소하거나 환불을 제한할 수 있습니다.
        <br />
        3. 회원은 다음 각 호의 사유가 있으면 아래의 4항의 규정에 따라서
        회사로부터 결제 취소, 환불 및 보상을 받을 수 있습니다.
        <br />
        - 결제를 통해 사용할 수 있는 서비스가 전무하며 그에 대한 책임이 전적으로
        회사에 있을 경우 (단, 사전 공지된 시스템 정기 점검 등의 불가피한 경우는
        제외)
        <br />
        - 회사 또는 결제대행사의 시스템의 오류로 인하여 결제기록이 중복으로
        발생한 경우
        <br />
        - 서비스 종료 등의 명목으로 회사가 회원에게 해지를 통보하는 경우
        <br />
        - 기타 소비자 보호를 위하여 당사에서 따로 정하는 경우.
        <br />
        - 회원이 미사용한 아이템을 유료 결제 후 7일 이내에 환불 요청 하는 경우.
        <br />
        4. 환불, 결제 취소 절차는 다음 각 항목과 같습니다.
        <br />
        a. 환불을 원하는 회원은 전자 우편을 통해 회원 본인임을 인증하는 절차를
        거쳐 고객센터에 접수하셔야 하며 본인 인증과 동시에 환불을 신청하여야
        합니다.
        <br />
        b. 회사는 회원이 환불 요청 사유가 적합한지를 판단하고 3항의 환불사유가
        존재하고, 적합한 절차를 거친 것으로 판명된 회원에게 환불합니다.
        <br />
        c. 회사는 회원에게 환불되어야 할 금액 산정 방식과 절차를 회원에게 상세히
        설명하고 난 후 회원에게 해당 환불 및 결제 취소 처리합니다.
        <br />
        d. 회원은 구매시점으로부터 7일 이내인 경우 환불이 가능하며 구매시점 7일
        이후에는 시스템 오류로의 미지급 등 회사의 귀책사유로 인정되는 경우에만
        환불이 가능합니다.
        <br />
        5. 회원이 이용약관을 위반한 행위로 인해 이용정지 또는 강제탈퇴 되는 경우
        환불 및 보상하지 않습니다.
        <br />
        6. 회원의 자진탈퇴로 인해 계약이 해지되는 경우, 회원이 보유한 아이템은
        자동으로 소멸되어 복구 및 환불이 불가능합니다.
        <br />
        7. 신원 인증과정에서 회원의 등록정보가 허위 정보로 판명되거나 가입
        조건에 부합되지 않는 점이 판명될 경우 징계 및 강제 탈퇴가 되며 회원
        본인의 귀책사유로 인해 환불 및 보상이 불가능합니다.
        <br />
        8. 회사와 이용자간에 발생한 분쟁은 전자거래기본법 제32조에 의거하여
        설치된 기관인 전자거래분쟁조정위원회의 조정에 따를 수 있습니다.
        <br />
        9. 구독 아이템 환불 요청 방법
        <br />
        <br />
        Apple ID를 이용해 구독 상품을 구매한 경우 위플레이가 아니라 Apple에서
        환불 처리를 합니다. 먼저 구독을 취소하려면 설정 앱의 iTunes 항목에서
        Apple ID를 클릭해 Apple ID 보기 메뉴를 선택한 다음 나오는 화면에서 다시
        구독 메뉴를 골라 구독 취소를 하셔야합니다. 환불을 원하시면 iTunes를 열어
        귀하의 Apple ID를 클릭하고 구매 내역을 선택한 다음 구매 내역 중에 환불을
        원하는 항목을 찾아 문제 보고를 눌러 상담원과 연결하셔야합니다. Google
        Play 계정을 이용해 구독 상품을 구매한 경우 Google Play 앱에서 직접
        취소하거나 주문 번호와 함께 고객 센터로 연락해주셔야합니다.
        <br />
        <p>제9조 위플코인, 포인트 및 아이템의 사용 및 소멸</p>
        1. 위플코인, 포인트 및 아이템은 회사가 제공하는 서비스를 사용할 때 지불
        수단으로 이용될 수 있습니다.
        <br />
        2. 회원이 구매한 위플코인 및 아이템은 해당 회원의 최종 접속일로부터 2년
        경과 시 소멸됩니다. 단, 구매한 아이템의 사용 기간이 정해져 있는 경우
        해당 기간 내에서만 사용이 불가능합니다.
        <br />
        3. 회사가 회원에게 무상으로 지급한 위플코인, 포인트 및 아이템은 해당
        회원의 최종 접속일로부터 1년 경과 시 소멸됩니다. 단, 아이템의 사용
        기간이 정해져 있는 경우에 해당 기간 내에서만 사용이 가능합니다.
        <br />
        4. 위플코인의 사용 순서는 회사가 정하는 정책에 따릅니다.
        <br />
        <p>제10조 회사의 의무</p>
        1. 회사는 계속적이고 안정적인 서비스의 제공을 위하여 최선을 다하여
        노력합니다.
        <br />
        2. 회사는 회원이 안전하게 서비스를 이용할 수 있도록 현재 인터넷
        보안기술의 발전수준과 회사가 제공하는 서비스의 성격에 적합한
        보안시스템을 갖추고 운영해야 합니다.
        <br />
        3. 회사는 서비스를 이용하는 회원으로부터 제기되는 의견이나 불만이
        정당하다고 인정할 경우를 이를 처리하여야하 합니다. 이때 처리과정에
        대해서 고객에게 메일 및 게시판 등의 방법으로 전달합니다.
        <br />
        4. 회사는 정보통신망 이용촉진 및 정보보호에 관한 법률, 통신비밀보호법,
        전기통신사업법 등 서비스의 운영, 유지와 관련 있는 법규를 준수합니다.
        <br />
        <p>제11조 회원의 의무</p>
        회원은 다음 각호에 해당하는 행위를 해서는 안됩니다.
        <br />
        1. 이용 신청 또는 회원정보 변경 시 허위내용 등록하는 경우
        <br />
        2. 타인의 정보도용을 하는 경우
        <br />
        3. 회사의 운영자, 임직원, 회사를 사칭하는 경우
        <br />
        4. 회사가 게시한 정보의 변경을 하는 경우
        <br />
        5. 해킹을 통해서 다른 사용자의 정보를 취득하는 행위를 하는 경우
        <br />
        6. 회사와 다른 회원 및 기타 제3자를 희롱하거나, 위협하거나 명예를
        손상시키는 행위를 하는 경우
        <br />
        7. 외설, 폭력적인 메시지, 기타 공서양속에 반하는 정보를 공개 또는
        게시하는 행위를 하는 경우
        <br />
        8. 자신의 종교적 신념을 표현하거나 타인의 종교를 비하하는 경우
        <br />
        9. 허위사실 유포 및 사기 행위를 하는 경우
        <br />
        10. 국내법상 온라인 판매가 금지된 제품의 판매나 홍보를 하는 경우
        <br />
        11. 장물, 마약/향 정신성 약품 등 국내 반입 및 판매가 금지된 제품의
        판매나 홍보를 하는 경우
        <br />
        12. 매점매석과 같이 공정거래에 위반되는 행위를 하는 경우
        <br />
        13. 음란물 및 성인물을 게시하거나 판매 혹은 홍보를 하는 경우
        <br />
        14. 유흥업, 퇴폐향락업 및 불법업태에 관련한 사항을 게시하거나 홍보하는
        경우
        <br />
        15. 정치적, 사회적인 물의를 일으킬 수 있는 행위를 하는 경우
        <br />
        16. 회사와 기타 제3자의 저작권, 영업비밀, 특허권 등 지적재산권에 대한
        침해를 하는 경우
        <br />
        17. 정보통신설비의 오작동이나 정보 등의 파괴를 유발시키는 악성코드나
        데이터 등을 유포하는 경우
        <br />
        18. 기타 현행 법령에 위반되는 불법적인 행위를 하는 경우
        <br />
        19. 기타 "위플레이" 모바일 어플리케이션 또는 온라인 웹 페이지를 통하여
        추가로 공지되는 사항에 위배되는 경우
        <br />
        20. 회사는 회원이 전항에서 금지한 행위를 하는 경우, 위반 행위의 경중에
        따라 서비스의 이용정지/계약의 해지 등 서비스 이용 제한, 수사 기관에의
        고발 조치 등 합당한 조치를 취할 수 있습니다.
        <br />
        21. 회원은 회사의 명시적 사전 동의가 없는 한 서비스의 이용권한 및 기타
        이용계약상의 지위를 제3자에게 양도, 증여, 대여할 수 없으며 이를 담보로
        제공할 수 없습니다.
        <br />
        22. 회원은 관계법, 이 약관의 규정, 이용안내 및 서비스와 관련하여 공지한
        주의사항, 회사가 통지하는 사항 등을 준수하여야 하며, 기타 회사의 업무에
        방해되는 행위를 하여서는 안 됩니다.
        <br />
        23. 회원은 회사의 사전 허락 없이 회사가 정한 이용 목적과 방법에 반하여
        영업/광고활동 등을 할 수 없고, 회원의 서비스 이용이 회사의 재산권,
        영업권 또는 비즈니스 모델을 침해하여서는 안됩니다.
        <br />
        <p>제12조 서비스의 제공 및 변경</p>
        회사는 회원에게 아래와 같은 서비스를 제공합니다.
        <br />
        1. "위플레이" 통합계정 서비스
        <br />
        2. 기타회사가 추가 개발하거나 다른 회사와의 제휴 계약 등을 통해 회원에게
        제공하는 일체의 서비스
        <br />
        2. 서비스는 연중무휴, 1일 24시간 제공함을 원칙으로 합니다.
        <br />
        3. 회사는 정보통신설비의 보수점검, 교체 및 고장, 통신두절 또는 운영상
        상당한 이유가 있는 경우 서비스의 제공을 일시적으로 중단할 수 있습니다.
        이 경우 회사는 회원에게 공지사항 게시판 및 메일 등의 방법으로
        통지합니다. 다만, 회사가 사전에 통지할 수 없는 부득이한 사유가 있는 경우
        사후에 통지할 수 있습니다.
        <br />
        4. 회사는 회원과 별도로 서면 계약을 체결하여 "위플레이" 서비스 및 제반
        서비스의 브랜드 특성을 이용할 수 있는 명시적인 권리를 부여하지 아니하는
        한, 회원에게 회사 또는 "위플레이" 서비스의 상호, 상표, 서비스표, 로고,
        도메인 네임 및 기타 식별력 있는 브랜드 특성을 이용할 수 있는 권리를
        부여하지 않습니다.
        <br />
        5. "위플레이" 에서 제공하는 서비스는 계속 발전하고 있습니다. 회사가
        제공하는 서비스의 형태와 기능, 디자인 등 필요한 경우 수시로 변경되거나,
        중단될 수 있습니다. 회사는 이 경우 개별적인 변경에 대해서 회원에게 사전
        통지하지 않습니다. 다만, 회원에게 불리한 것으로 판단되는 경우 공지 또는
        전자우편으로 통하여 이를 공지합니다.
        <br />
        6. 전항에 의해서 제공되는 서비스가 변경 및 중단될 경우 무료로 제공되는
        서비스에 대해서는 회원에게 별도로 보상하지 않습니다.
        <br />
        <p>제13조 광고의 게제</p>
        1. 회사는 서비스 운영과 관련하여 회원정보, 고객이 입력한 정보를 활용하여
        광고를 게재할 수 있습니다. 회원은 서비스 이용 시 노출되는 맞춤 광고
        게재에 대해 동의합니다.
        <br />
        2. 회사는 서비스상에 게재되어 있거나 서비스를 통한 광고주의 판촉활동에
        회원이 참여하거나 교신 또는 거래를 함으로써 발생하는 손실과 손해에 대해
        책임을 지지 않습니다.
        <br />
        <p>제14조 전자우편을 통한 정보의 제공</p>
        1. 회사는 회원이 서비스 이용에 필요하다고 인정되는 다양한 정보를 회원이
        제공한 전자우편 주소로 제공할 수 있습니다.
        <br />
        2. 회사는 다음 각호에 해당하는 경우 회원의 동의여부와 상관없이
        전자우편으로 발송할 수 있습니다.
        <br />
        3. 이용 신청에서 입력한 전자우편 주소의 소유를 확인하기 위해서
        인증메일을 발송하는 경우
        <br />
        4. 회원의 정보가 변경되어 확인하기 위해서 인증메일을 발송하는 경우
        <br />
        5. 기타 서비스를 제공함에 있어 회원이 반드시 알아야 하는 중대한 정보라고
        회사가 판단하는 경우
        <br />
        <p>제15조 광고성 또는 맞춤형 정보의 제공</p>
        1. 회사는 본 서비스의 운영과 관련하여 광고성 정보를 제공하는 서비스를
        운영할 수 있습니다.
        <br />
        2. 회사는 광고성 정보 및 회원 맞춤형 정보를 문자 메시지나 이메일 또는
        카카오톡 플러스 친구, 앱 내 PUSH 메시지 형식으로 제공 할 수 있으며, 이는
        광고성 정보에 대한 사전 수신 동의가 있는 경우 등 법령상 허용된 경우에
        한하여 제공됩니다.
        <br />
        <p>제16조 서비스 이용의 제한</p>
        1. 회사는 천재지변이나 국가비상사태, 해결이 곤란한 기술적 결함 또는
        서비스 운영의 심각한 변화 등 불가항력적인 경우가 발생 또는 발생이 예상될
        때는 서비스의 전부 또는 일부를 예고 없이 제한하거나 중지할 수 있습니다.
        <br />
        2. 서비스를 이용하게 됨으로써 서비스 영역에서 발생하는 회원 사이의
        문제에 대해 회사는 책임을 지지 않습니다.
        <br />
        3. 회원의 관리 소홀로 인하여 ID 및 비밀번호의 유출로 인해 회원에게
        서비스 이용상의 손해가 발생하거나 제3자에 의한 부정이용 등으로 회원의
        의무조항을 위반한 경우 ID및 해당 도메인의 이용이 제한될 수 있습니다.
        <br />
        4. 회사가 본 약관 제11조의 위반 행위를 조사하는 과정에서 당해 회원 ID 및
        도메인이 특정 위반행위에 직접적으로 관련되어 있는 경우 등 다른 회원의
        권익 보호 및 서비스의 질서유지를 위해 불가피할 경우에는 해당 ID 및
        도메인의 이용을 일시적으로 정지할 수 있습니다. 이에 대해 회원은
        "위플레이" 서비스 홈페이지 또는 전자우편 등을 통해 이의신청을 할 수
        있습니다.
        <br />
        <p>제17조 게시물의 권리와 책임</p>
        1. 회원이 서비스 내에 작성한 텍스트, 이미지, 동영상, 링크 등의 기타
        정보(이하 "게시물")에 대한 책임 및 권리는 게시물을 등록한 회원에게
        있습니다.
        <br />
        2. 회사는 회원이 작성한 컨텐츠에 대해서 감시 및 관리할 수 없으며 이에
        대해서 책임지지 않습니다. 회사는 회원이 등록하는 게시물의 신뢰성,
        진실성, 정확성 등에 대해서 책임지지 않으며 보증하지 않습니다.
        <br />
        3. 서비스에 대한 저작권 및 지적재산권, 회사가 작성한 게시물의 저작권은
        회사에 귀속됩니다. 단, 회원이 단독 또는 공동으로 작성한 게시물 및
        제휴계약에 따라 제공된 저작물 등은 제외합니다.
        <br />
        4. 회원이 서비스 내에 게시하는 게시물은 검색결과 내지 서비스 운영 및
        홍보 등을 위해서 매체, 홈페이지, 자사의 다른 서비스, 기타의 방법(현재
        알려져 있고 향후 개발되는 것을 포함)에서 사용될 수 있습니다. 이때 해당
        노출을 위해 필요한 범위 내에서는 일부 수정, 복제, 편집되어 게시될 수
        있습니다. 이 경우, 회사는 저작권법 규정을 준수하며, 회원은 언제든지
        고객센터 또는 서비스 내 관리기능을 통해 해당 게시물에 대해 삭제,
        검색결과 제외, 비공개 등의 조치를 취할 수 있습니다.
        <br />
        5. 회원이 회원탈퇴를 한 경우에는 본인이 생성한 게시물은 삭제되지
        않습니다. 단, 탈퇴 하기 전 본인의 게시물은 스스로 삭제할 수 있습니다.
        게시물이 제3자에 의하여 보관되거나 무단복제 등을 통하여 복제됨으로써
        해당 저작물이 삭제되지 않고 재 게시된 경우에 대하여 회사는 책임을 지지
        않습니다. 또한, 본 약관 및 관계 법령을 위반한 회원의 경우 다른 회원을
        보호하고, 법원, 수사기관 또는 관련 기관의 요청에 따른 증거자료로
        활용하기 위해 회원탈퇴 후에도 관계 법령이 허용하는 한도에서 회원의
        아이디 및 회원정보를 보관할 수 있습니다.
        <br />
        6. 회원의 게시물 또는 저작물이 회사 또는 제3자의 저작권 등 지적재산권을
        침해함으로써 발생하는 민•형사상의 책임은 전적으로 회원이 부담하여야
        합니다.
        <br />
        <p>제18조 게시물의 관리</p>
        1. 회원의 게시물이 정보통신망법 및 저작권법등 관련법에 위반되는 내용을
        포함하는 경우, 권리자는 관련법이 정한 절차에 따라 해당 게시물의 게시중단
        및 삭제 등을 요청할 수 있으며, 회사는 관련법에 따라 조치를 취하여야
        합니다.
        <br />
        2. 회사는 전항에 따른 권리자의 요청이 없는 경우라도 권리침해가 인정될
        만한 사유가 있거나 본 약관 및 기타 회사 정책, 관련법, 지적재산권,
        공서양속에 위반되는 경우에는 관련법에 따라 해당 게시물에 대해 임시조치
        등을 취할 수 있습니다.
        <br />
        <p>제19조 거래 행위에 대한 책임</p>
        1. 회사는 효율적인 서비스를 위한 시스템 운영 및 관리 책임만을 부담하며,
        재화 또는 용역의 거래와 관련하여 구매자 또는 판매자를 대리하지 아니하고,
        사용자 사이에 성립된 거래 및 사용자가 제공하고 등록한 정보에 대해서는
        해당 사용자가 그에 대한 모든 책임을 부담하여야 합니다.
        <br />
        2. 회사는 회사가 제공하는 시스템을 통하여 이루어지는 구매자와 판매자
        간의 거래와 관련하여 판매의사 또는 구매의사의 존부 및 진정성, 등록물품의
        품질, 완전성, 안정성, 적법성 및 타인의 권리에 대한 비침해성, 구매자 또는
        판매자가 입력하는 정보 및 그 정보를 통하여 링크된 URL에 게재된 자료의
        진실성 또는 적법성 등 일체에 대하여 보증하지 아니하며, 이와 관련한
        일체의 위험과 책임은 해당 사용자가 전적으로 부담합니다.
        <br />
        3. 회사는 "위플레이"를 기반으로 한 거래시스템만을 제공할 뿐이며, 거래
        내용에 관한 모든 분쟁에 대해서는 해당 거래 당사자가 책임을 져야 합니다.
        <br />
        <p>제20조 책임제한</p>
        1. 회사는 회원의 약관, 서비스 이용 방법 및 이용 기준을 준수하지 않는 등
        회원의 귀책사유로 인한 서비스 이용의 장애에 대하여는 책임을 지지
        않습니다.
        <br />
        2. 회사는 서비스를 통하여 게재한 정보, 자료, 사실의 신뢰도, 정확성 등의
        내용에 관하여는 보증하지 않습니다.
        <br />
        3. 회사는 회원 간 또는 회원과 제3자 상호간에 서비스를 매개로 하여 거래
        등을 한 경우에는 책임이 면제됩니다.
        <br />
        4. 회사는 무료로 제공되는 서비스 이용과 관련하여 관련법에 특별한 규정이
        없는 한 책임을 지지 않습니다.
        <br />
        5. 회사는 천재지변, 전쟁, 기간통신사업자의 서비스 중지, 해결이 곤란한
        기술적 결함 기타 불가항력으로 인하여 서비스를 제공할 수 없는 경우 책임이
        면제됩니다.
        <br />
        6. 회사는 사전에 공지된 서비스용 설비의 보수, 교체, 정기점검, 공사 등
        부득이한 사유로 서비스가 중지되거나 장애가 발생한 경우에 대하서는 책임이
        면제됩니다.
        <br />
        7. 회원은 자신의 결정에 의하여 회사의 서비스를 사용하여 특정
        프로그램이나 정보 등을 다운받거나 접근함으로써 입게 되는 컴퓨터
        시스템상의 손해나 데이터, 정보의 상실에 대한 책임을 집니다.
        <br />
        8. 회사는 기간통신사업자가 전기통신서비스를 중지하거나 정상적으로
        제공하지 아니하여 손해가 발생한 경우에는 책임이 면제됩니다.
        <br />
        9. 회원의 컴퓨터 오류, 신상정보 및 전자우편 주소의 부정확한 기재,
        비밀번호 관리의 소홀 등 회원의 귀책사유로 인해 손해가 발생한 경우 회사는
        책임을 지지 않습니다.
        <br />
        10. 회사는 회원의 컴퓨터 환경이나 회사의 관리 범위에 있지 아니한 보안
        문제로 인하여 발생하는 제반 문제 또는 현재의 보안기술 수준으로 방어가
        곤란한 네트워크 해킹 등 회사의 귀책사유 없이 발생하는 문제에 대해서
        책임을 지지 않습니다.
        <br />
        11. 회사는 서비스가 제공한 내용에 대한 중요 정보의 정확성, 내용, 완전성,
        적법성, 신뢰성 등에 대하여 보증하거나 책임을 지지 않으며, 사이트의 삭제,
        저장실패, 잘못된 인도, 정보에 대한 제공에 대한 궁극적인 책임을 지지
        않습니다. 또한, 회사는 회원이 서비스 내 또는 웹사이트상에 게시 또는
        전송한 정보, 자료, 사실의 신뢰도, 정확성, 완결성, 품질 등 내용에
        대해서는 책임을 지지 않습니다.
        <br />
        12. 사용자가 자신의 개인정보를 타인에게 유출 또는 제공함으로써 발생하는
        피해에 대해서는 회사는 일체의 책임을 지지 않습니다.
        <br />
        13. 회사는 회원이 서비스를 이용하여 기대하는 효용을 얻지 못한 것에
        대하여 책임을 지지 않으며 서비스에 대한 취사 선택 또는 이용으로 발생하는
        손해 등에 대해서는 책임이 면제됩니다.
        <br />
        14. 회사는 회원의 게시물을 등록 전에 사전심사 하거나 상시적으로 게시물의
        내용을 확인 또는 검토하여야 할 의무가 없으며, 그 결과에 대한 책임을 지지
        않습니다.
        <br />
        15. WiFi환경이 아닌 곳에서 접속하여 데이터 요금 발생 시, 사용자는 부과된
        요금에 대해 회사에게 일체 지불 요청을 할 수 없습니다.
        <br />
        <p>제21조 준거법 및 재판관할</p>
        1. 회사와 회원 간 제기된 소송에는 대한민국법을 준거법으로 합니다.
        <br />
        2. 회사와 회원간 발생한 분쟁에 관한 소송은 민사소송법 상의 관할법원에
        제소합니다. <br />
      </DialogContentText>
    );
  } else if (type === "personal-info-usage") {
    return (
      <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
        위플레이는 이용자의 개인정보를 소중히 다루고 있습니다.
        위플레이는「정보통신망 이용촉진 및 정보보호 등에 관한 법률」을 비롯한
        관련 법규상의 개인정보 보호규정 및 개인정보 보호지침을 준수하며, 관련
        법령에 의거한 개인정보 처리방침을 제정하여 이용자의 권익 보호에 최선을
        다하고 있습니다.
        <p>위플레이 개인정보 처리방침</p>
        주식회사 위플레이(이하 '회사')는 이용자의 동의를 받아 개인정보를
        수집•이용 및 제공하고 있습니다. 또한 이용자의 개인정보 자기결정권을
        적극적으로 보장합니다. 위플레이는 개인정보 처리방침을 통해 수집하는
        개인정보의 종류, 이용목적, 처리방침 등을 안내하고 개인정보보호를 위해
        어떠한 조치가 취해지고 있는지 알려드림으로써 이용자가 보장된 권리에 따라
        안심하고 서비스를 이용할 수 있도록 노력하고 있습니다.
        <br />
        1. 수집하는 개인정보 항목 및 수집방법
        <br />
        2. 개인정보의 수집 및 이용 목적
        <br />
        3. 개인정보의 보유 및 이용 기간
        <br />
        4. 개인정보의 취급위탁
        <br />
        5. 개인정보의 공개 및 연동
        <br />
        6. 개인정보 파기절차 및 방법
        <br />
        7. 회원 및 법정대리인의 권리와 그 행사방법
        <br />
        8. 개인정보 자동 수집 장치의 설치•운영 및 거부에 관한 사항
        <br />
        9. 개인정보의 기술적•관리적 보호 대책
        <br />
        10. 개인정보에 관한 민원서비스
        <br />
        11. 기타
        <br />
        12. 고지의 의무
        <br />
        <p>1. 수집하는 개인정보 항목 및 수집방법</p>
        <div>1) 수집하는 개인정보 항목</div>
        가. 회원가입 시 수집하는 정보
        <br />
        위플레이는 회원가입, 원활한 고객상담, 각종 서비스의 제공을 위해
        회원가입시 아래와 같은 개인정보를 수집하고 있습니다. 선택수집 항목은
        입력하지 않아도 회원가입이 가능합니다.
        <br />
        - 필수수집 항목 : 아이디(이메일주소), 비밀번호, 닉네임, 생년월일, 성별,
        공개 프로필(학교, 직업, 지역, 키, 몸무게, 포지션, 선호 종목, 등), 휴대폰
        번호, 사진, 마케팅 수신 동의 여부, 위치정보 이용 동의여부
        <br />
        - 선택수집 항목 : 직장, 위치정보
        <br />
        <br />
        나. 서비스 이용과정이나 사업처리 과정에서의 수집정보
        <br />
        - IP Address, 방문 일시, 서비스 이용 기록, 불량 이용 기록, 접속 로그
        <br />
        - 사용 이동통신사 등<br />
        - 서비스 사용 중일 때 귀하의 모바일 기기의 지리적 위치
        <br />
        - 모바일 단말기 정보, 국가정보 (MCC)
        <br />
        - 광고 식별자 정보 (ADID, IDFA)
        <br />
        <br />
        다. 고객센터 이용시 또는 이벤트 응모시 수집정보
        <br />
        고객센터 이용시 또는 이벤트 응모시에는 고충처리, 경품발송 및 세금신고
        등을 위해 회원가입시 수집하지 않은 개인정보를 추가로 수집할 수 있습니다.
        아래의 항목 이외에도 유형에 따라 추가 수집하는 개인정보가 있을 수
        있습니다. 이 과정에서 수집 관련 내용을 안내하고 별도 동의를 받습니다.
        <br />
        - 고객센터 : 전화번호, 이메일주소, 실명 변경 정보 등<br />
        - 이벤트 응모 및 당첨 : 이름, 전화번호, 이메일주소, 배송지주소,
        주민등록번호*
        <br />
        ▶ 주민등록번호 수집 : 당첨자의 제세공과금 처리 등을 위해 관련법에 따라
        수집해야하는 경우에 한하며, 수집시 별도 동의를 받습니다.
        <br />
        <br />
        <div>2) 개인정보 수집방법</div>
        회사는 다음과 같은 방법으로 개인정보를 수집하고 있습니다.
        <br />
        - 회원가입 및 서비스 이용과정에서 개인정보 수집에 대해 동의를 하고 직접
        정보를 입력
        <br />
        - 홈페이지, 모바일앱, 모바일웹, 서면 양식, 전화, FAX, 문의메일, SNS,
        게시판
        <br />
        - 이벤트 응모, 배송 요청
        <br />
        - 생성정보 수집 툴을 통한 수집
        <br />
        - 협력회사로부터 공동 제휴 및 협력을 통한 정보 수집
        <br />
        <br />
        <p>2. 개인정보의 수집 및 이용 목적</p>
        회사는 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고 있는
        개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며, 이용 목적이
        변경되는 경우에는 개인정보 보호법 제 18조에 따라 별도의 동의를 받는 등
        필요한 조치를 이행할 예정입니다.
        <br />
        <div>1) 서비스 제공에 관한 계약 이행, 부가서비스 제공 및 회원 관리</div>
        매칭 서비스 및 콘텐츠 제공, 회원제 서비스 이용에 따른 본인확인, 개인
        식별, 가입의사 확인, 연령 확인, 가입 및 가입횟수 제한, 부가서비스 제공을
        위한 고유 이용자 특정, 불량회원의 부정이용 및 비인가 사용 방지, 회원탈퇴
        후 24시간내 재가입 방지, 불만처리 등 민원처리, 분쟁조정을 위한 기록보존,
        안내사항 전달, 회원탈퇴 의사의 확인
        <br />
        <div>2) 이벤트 관리</div>
        이벤트 응모, 당첨확인 및 관련안내, 경품 발송, 관련 민원처리, 세금신고
        <br />
        <div>3) 마케팅 및 광고에 활용</div>
        신규 서비스(제품) 개발 및 특화, 이벤트 등 광고성 정보 전달, 인구통계학적
        특성에 따른 서비스 제공 및 광고 게재, 접속 빈도 파악 또는 회원의 서비스
        이용에 대한 통계, 서비스의 유효성 확인
        <br />
        <p>3. 개인정보의 보유 및 이용 기간</p>
        회원가입시 및 서비스 이용 중 수집된 정보는 회원탈퇴시 혹은 개인정보 수집
        및 이용목적이 달성된 때까지 3개월간 보존하며 이후에는 해당 정보를 지체
        없이 파기합니다. 단, 다음의 정보에 대해서는 하기의 이유로 회원탈퇴
        후에도 명시한 기간 동안 해당 정보를 보존합니다.
        <br />
        <div>1) 회사 내부 방침에 의한 정보보유 사유</div>
        가. 부정이용 기록
        <br />
        - 보존 항목 : 부정이용기록(부정가입, 규정위반 기록 등 비정상적 서비스
        이용기록)
        <br />
        - 보존 이유 : 부정가입 및 부정이용 방지
        <br />
        - 보존 기간 : 3년
        <br />
        <br />
        나. 반복적 탈퇴/재가입 방지를 위한 정보
        <br />
        회원 탈퇴한 경우에는 회원 재가입, 임의 해지등을 반복적으로 행함으로써
        위플레이가 제공하는 서비스의 부정이용 및 이벤트 혜택 등의 이익 등을
        불•편법적으로 수취하거나 이 과정에서 명의도용 등의 우려가 있으므로
        이러한 행위의 차단 목적으로 회원 탈퇴 후 3개월 동안 회원정보를
        보관합니다.
        <br />
        - 보존 항목 : 아이디(이메일주소), 비밀번호, 휴대폰 번호, 기기 정보
        <br />
        - 보존 이유 : 재가입, 임의해지 등의 부정이용 방지
        <br />
        - 보존 기간 : 3개월
        <br />
        <br />
        다. 개인정보 유효기간제 도입에 따른 개인정보 분리보관 및 휴면계정의
        복원을 위한 정보
        <br />
        서비스를 장기간 이용하지 않는 이용자의 회원정보에 포함된 개인정보는
        별도로 분리보관됩니다. 서비스를 재이용하고 싶은 경우에는 휴면해치처리
        선택을 거쳐 정상아이디로 복원할 수 있습니다.
        <br />
        - 분리보관 항목 : 회원정보에 포함된 개인정보(필수, 선택정보 포함)
        <br />
        ▶ 분리보관 기준 : 기준 시점으로부터 1년 내 미로그인한 경우 휴면계정으로
        전환 및 개인정보 분리보관
        <br />
        ▶ 분리보관 제외 : 휴면통지 및 복원에 필요한 정보, 법령의 규정 및 내부
        규정에 따라 보존할 필요성이 있는 정보 제외
        <br />
        - 분리보관 이유 : 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」에
        따른 장기 미이용자 개인정보 보호
        <br />
        - 분리보관 기간 : 휴면계정 전환 이후 3년
        <br />
        <br />
        <div>2) 관련법령에 의한 정보보유 사유</div>
        「전자상거래 등에서의 소비자보호에 관한 법률」 등 관계법령의 규정에
        의하여 보존할 필요가 있는 경우 위플레이는 관계법령에서 정한 일정한 기간
        동안 회원정보를 보관합니다. 이 경우 위플레이는 보관하는 정보를 그 보관의
        목적으로만 이용하며 보존기간은 아래와 같습니다.
        <br />
        가. 계약 또는 청약철회 등에 관한 기록
        <br />
        - 보존 이유 : 전자상거래등에서의 소비자보호에 관한 법률
        <br />
        - 보존 기간 : 5년
        <br />
        <br />
        나. 대금결제 및 재화 등의 공급에 관한 기록
        <br />
        - 보존 이유 : 전자상거래등에서의 소비자보호에 관한 법률
        <br />
        - 보존 기간 : 5년
        <br />
        <br />
        다. 소비자의 불만 또는 분쟁처리에 관한 기록
        <br />
        - 보존 이유 : 전자상거래등에서의 소비자보호에 관한 법률
        <br />
        - 보존 기간 : 3년
        <br />
        <br />
        라. 앱 접속 기록
        <br />
        - 보존 이유 : 통신비밀보호법
        <br />
        - 보존 기간 : 2년
        <br />
        <br />
        마. 경품 등의 제공에 따른 제세공과금 처리 관련 항목
        <br />
        - 보존 이유 : 소득세법
        <br />
        - 보존 기간 : 5년
        <br />
        <br />
        바. 서비스 이용 기록, 접속 로그, 접속 IP 정보
        <br />
        - 보존 이유 : 통신비밀보호법
        <br />
        - 보존 기간 : 3개월
        <br />
        <br />
        사. 광고에 관한 기록
        <br />
        - 보존 이유 : 전자상거래등에서의 소비자보호에 관한 법률
        <br />
        - 보존 기간 : 6개월
        <br />
        <p>4. 개인정보의 취급위탁</p>
        고객이 서비스 이용과정 등에서 따로 동의하는 경우나 법령에 규정된 경우를
        제외하고는 고객의 개인 정보를 위에서 말씀드린 목적 범위를 초과하여
        이용하거나 제3자에게 제공 또는 공유하지 않습니다.하지만 서비스 향상,
        법령이나 이용 약관에 반하여 다른 고객에게 피해를 줄 수 있는 잘못된
        이용행위의 차단, 방지 및 안정적인 개인정보 취급을 위해서 고객의
        개인정보를 외부에 위탁하여 처리할 수 있습니다. 위탁처리 기관 및
        위탁업무의 내용은 아래를 참조해 주세요.
        <br />
        <p>5. 개인정보의 공개 및 연동</p>
        위플레이는 이용자의 동의가 있거나 법률의 규정에 의한 경우를 제외하고는
        어떠한 경우에도 이용 범위를 넘어 이용자의 개인정보를 이용하거나 외부에
        공개하지 않습니다. 이용자의 개인정보를 외부와 공유하는 경우에는 사전에
        해당 사실을 알리고 동의를 구하는 절차를 거치게 되며, 이용자들의 동의가
        없는 경우에는 추가적인 정보를 임의로 수집하거나 공유하지 않습니다. 단,
        아래의 경우는 예외로 합니다.
        <br />
        <div>1) 이용자가 동의한 경우</div>
        - 이용자가 사전에 공개한 경우
        <br />
        - 이용자가 연동에 동의한 경우
        <br />
        ▶ 이벤트 참가시 정보제공 가능함을 미리 안내합니다. 부정확한 개인정보
        혹은 개인정보 누락시 이벤트 당첨 등은 취소됩니다.
        <br />
        <div>2) 법령의 규정에 의거하여 적법한 절차에 따른 요청이 있는 경우</div>
        - 법령의 규정에 의거하여 수사 목적으로 법령에 정해진 절차와 방법에 따라
        수사기관의 요구가 있는 경우
        <br />
        - 법령의 규정에 의거하여 분쟁조정 등의 목적으로 법령에 정해진 절차와
        방법에 따라 관련기관의 요구가 있는 경우
        <br />
        - 기타 법령의 규정에 의거한 경우
        <br />
        <div>3) 기타</div>
        - 서비스 제공에 따른 요금 정산을 위하여 필요한 경우
        <br />
        - 통계작성, 학술연구 또는 시장조사를 위하여 개인을 식별 할 수 없는
        형태로 제공하는 경우
        <br />
        - 정보주체 또는 그 법정대리인이 의사표시를 할 수 없는 상태에 있거나
        주소불명 등으로 사전 동의를 받을 수 없는 경우로서 명백히 정보주체 또는
        제3자의 급박한 생명, 신체, 재산의 이익을 위하여 필요하다고 인정되는 경우
        <br />
        <p>6. 개인정보의 파기절차 및 방법</p>
        이용자의 개인정보는 원칙적으로 개인정보 수집 및 이용목적이 달성된 후에는
        해당 정보를 지체없이 파기합니다. 파기절차 및 방법은 다음과 같습니다.
        <br />
        <div>1) 파기절차</div>
        이용자가 회원가입 등을 위해 입력하신 정보는 목적이 달성된 후 별도의 DB로
        옮겨져(종이의 경우 별도의 서류함) 내부 방침 및 기타 관련 법령에 의한
        정보보호 사유에 따라(보유 및 이용기간 참조) 일정 기간 저장된 후
        파기됩니다. 동 개인정보는 법률에 의한 경우가 아니고서는 다른 목적으로
        이용되지 않습니다.
        <br />
        <div>2) 파기기준</div>
        - 이용자 본인 혹은 법정대리인의 회원탈퇴 요청
        <br />
        - 한국인터넷진흥원, 본인확인기관 등의 개인정보 관련기관을 통한 회원 탈퇴
        요청
        <br />
        - 개인정보 수집•이용 등에 대한 동의 철회 및 개인정보 삭제 또는 파기 요청
        <br />
        - 정보통신망법에 따른 장기 미이용자
        <br />
        <div>3) 파기방법</div>
        - 전자적 파일형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적
        방법을 사용하여 삭제
        <br />
        - 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기
        <br />
        <p>7. 회원 및 법정대리인의 권리와 그 행사방법</p>
        이용자의 개인정보는 원칙적으로 개인정보 수집 및 이용목적이 달성된 후에는
        해당 정보를 지체없이 파기합니다. 파기절차 및 방법은 다음과 같습니다.
        <br />
        <br />
        회원 및 법정 대리인은 아래의 방법으로 언제든지 등록되어 있는 자신의
        개인정보를 조회하거나 수정 및 삭제할 수 있으며 개인정보 제공동의를
        철회하여 가입해지를 요청할 수도 있습니다. 혹은 회원 및 법정 대리인은
        고객센터나 개인정보책임자에게 서면, 전화 또는 이메일로 개인정보의 조회,
        수정, 삭제, 제공 동의 철회를 요청할 수 있습니다.
        <br />
        회원 혹은 법정대리인이 개인정보의 오류에 대한 정정을 요청하신 경우에는
        정정을 완료하기 전까지 당해 개인정보를 이용 또는 제공하지 않습니다. 또한
        잘못된 개인정보를 제3자에게 이미 제공한 경우에는 정정 처리결과를
        제3자에게 지체없이 통지하여 정정이 이루어지도록 하겠습니다. 위플레이는
        회원 혹은 법정 대리인의 요청에 의해 해지 또는 삭제된 개인정보는 본
        개인정보 처리방침에 명시된 바에 따라 처리하고 그 외의 용도로 열람 또는
        이용할 수 없도록 처리하고 있습니다.
        <br />
        <p>8. 개인정보의 기술적/관리적 보호대책</p>
        위플레이는 회원들의 개인정보를 처리함에 있어 개인정보가 분실, 도난,
        누출, 변조 또는 훼손되지 않도록 안전성 확보를 위하여 다음과 같은
        기술적/관리적 대책을 강구하고 있습니다.
        <br />
        <div>1) 비밀번호 암호화</div>
        아이디의 비밀번호는 암호화되어 저장 및 관리되고 있어 본인만이 알고
        있으며, 개인정보의 확인 및 변경도 비밀번호를 알고 있는 본인에 의해서만
        가능합니다. 해당 암호화는 bcrypt 을 사용한 복수단편암호를 구현하여
        해싱방식을 사용합니다.
        <br />
        <div>2) 해킹 등에 대비한 대책</div>
        위플레이는 해킹이나 컴퓨터 바이러스 등에 의해 이용자의 개인정보가
        유출되거나 훼손되는 것을 막기 위해 최선을 다하고 있습니다. 개인정보의
        훼손에 대비해서 자료를 수시로 백업하고 있고, 최신 보안솔루션을 이용하여
        이용자들의 개인정보나 자료가 누출되거나 손상되지 않도록 방지하고 있으며,
        암호화통신 등을 통하여 네트워크상에서 개인정보를 안전하게 전송할 수
        있도록 하고 있습니다. 그리고 침입차단시스템을 이용하여 외부로부터의 무단
        접근을 통제하고 있으며, 기타 시스템적으로 보안성을 확보하기 위한 가능한
        모든 기술적 장치를 갖추려 노력하고 있습니다.
        <br />
        <div>3) 처리 직원의 최소화 및 교육</div>
        위플레이의 개인정보관련 처리 직원은 담당자에 한정하며, 개인정보 상세
        조회가 가능한 계정은 권한 분리 조치와 보안조치를 취하였습니다. 이 계정을
        위한 별도의 비밀번호는 절차를 거쳐 정기적으로 갱신하고 있으며, 담당자에
        대한 수시 교육을 통해 개인정보 처리방침의 준수를 항상 강조하고 있습니다.
        <br />
        <div>4) 개인정보보호전담기구의 운영</div>
        사내 개인정보보호전담기구 등을 통해 개인정보 처리방침의 이행 및 담당자의
        준수 여부를 확인하여 문제가 발견될 경우 즉시 수정하고 바로 잡을 수
        있도록 노력하고 있습니다. 단, 회원 본인의 부주의나 인터넷상의 문제로
        아이디, 비밀번호 등 개인정보가 유출되어 발생한 문제에 대해 위플레이는
        일체의 책임을 지지 않습니다.
        <br />
        <p>9. 개인정보에 관한 민원서비스</p>
        <div>1) 위플레이 민원서비스</div>
        위플레이는 회원의 개인정보를 보호하고 개인정보와 관련한 불만을 처리하기
        위하여 아래와 같이 관련 부서 및 개인정보관리책임자를 지정하고 있습니다.
        위플레이는 접수하신 내용에 대해 신속하고 충분한 답변을 드리기 위해
        노력하고 있습니다. 고객서비스 담당부서의 운영시간은 평일 오전 9시부터
        오후 6시까지입니다.
        <br />
        개인정보 보호 담당 책임자
        <br />
        이 름 : 이재혁 (대표이사)
        <br />
        메 일 : jaehyuk.lee@weplaysports.net
        <br />
        <div>2) 기타 개인정보 관련 민원</div>
        기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에
        문의하시기 바랍니다.
        <br />
        - 한국인터넷진흥원 개인정보보호
        (https://privacy.kisa.or.kr/kor/main.jsp/ 국번없이 118)
        <br />
        - 개인정보분쟁조정위원회 (hkopico.go.kr/ 1833-6972)
        <br />
        - 정보보호마크인증위원회 (www.eprivacy.or.kr / 02-580-0533~4)
        <br />
        - 대검찰청 인터넷범죄수사센터 (http://www.spo.go.kr/ 국번없이 1301)
        <br />
        - 경찰청 사이버안전국 (cyberbureau.police.go.kr/국번없이 182)
        <br />
        <p>10. 기타</p>
        위플레이에서의 설문조사나 이벤트 등의 행사시, 통계분석이나 경품제공 등을
        위해 선별적으로 개인정보 입력을 요청할 수 있습니다. 그러나 회원의 기본적
        인권 침해의 우려가 있는 민감한 개인정보(인종 및 민족, 출신지 및 본적지,
        정치적 성향 및 범죄기록, 건강상태 및 성생활 등)는 수집하지 않으며
        부득이하게 수집해야 할 경우 회원들의 사전동의를 반드시 구할 것입니다.
        그리고, 어떤 경우에라도 입력하신 정보를 회원들에게 사전에 밝힌 목적
        이외에 다른 목적으로는 사용하지 않으며 외부로 유출하지 않습니다.
        <br />
        위플레이가 제공하는 서비스 내에 광고를 게재하는 광고주나 검색 등의 각종
        디렉토리 또는 게시판에 링크되어 있는 웹사이트들이 이용자의 개인정보를
        수집할 수도 있을 것입니다. 또한 서비스 내에 링크되어 있는 기타
        웹사이트들이 개인정보를 수집하는 행위에 대해서는 본 개인정보 처리방침이
        적용되지 않음을 알려 드립니다.
        <br />
        <p>11. 고지의 의무</p>
        이 개인정보 처리방침은 2019년 10월에 제정되었습니다. 법령•정책 또는
        보안기술의 변경에 따라 중요 내용의 추가, 삭제 혹은 수정이 있을 때에는
        변경되는 개인정보 처리방침을 중요도에 따라 시행하기 최소 7일 전에
        변경이유 및 내용 등을 공지합니다.
        <br />
      </DialogContentText>
    );
  } else if (type === "personal-info-provide") {
    return (
      <DialogContentText>
        하의 개인정보는 본 항목에 동의하신 경우에 한하여 제3자에게 제공됩니다.
        본 동의는 선택적 동의사항으로 거부하실 수 있으며, 거부하실 경우에도
        회원가입은 가능합니다. 다만, 제휴사의 상품 소개 및 판매, 서비스 제공,
        사은•판촉행사 안내 등 이용 목적에 따른 혜택의 제한이 있을 수 있습니다.
        또한, 동의하시더라도 위플레이 고객센터나 연락중지시스템(Do Not Call
        센터, www.donotcall.or.kr)을 통해 언제든지 마케팅 활동에 대한 중지를
        요청할 수 있습니다.
        <br />
        <br />
        ※ 동의하시는 경우 제휴사로부터 상품이나 서비스의 홍보 및 판매권유 등의
        목적으로 개인정보를 이용하여 연락을 드릴 수 있습니다.
        <br />
        귀하의 개인정보는 회원 가입시 제 3자 제공 동의를 선택한 경우
        「개인정보보호법」 및 「신용정보의 이용 및 보호에 관한 법률」에 따라
        귀하의 개인(신용)정보를 다음과 같이 제3자에게 제공하고자 합니다.
        <br />
        <p>1. 개인정보의 제공</p>
        <br />
        개인정보를 제공받는 자<br />
        <p>2. 제공받는자의 개인정보 수집 이용</p>
        <br />
        1) 제공받는자의 개인정보 수집 이용 목적
        <br />
        ① 경품지급, 사은행사 등 고객의 편의 제공
        <br />
        ②시장조사 및 상품서비스 개발연구 <br />
        2) 제공할 개인정보의 항목
        <br />
        개인식별정보(성명, 생년월일, 성별, 국적, 연락처,선호 종목), 동의일자,
        본인인증결과값(CI), 이동통신사
        <br />
        <p>3. 제공받는자의 개인정보 보유 이용기간</p>
        <br />
        동의일로부터 2년
        <br />
        <p>4. 개인정보의 제3자 제공 동의</p>
        <br />
        귀하는 개인정보의 선택적인 제공에 대한 동의를 거부할 수 있습니다. 다만,
        관련 편의 제공(사은품, 할인쿠폰 제공 등), 사은행사(할인, 적립 등)안내 등
        이용 목적에 따른 혜택의 제한이 있을 수 있습니다.
        <br />
      </DialogContentText>
    );
  } else if (type === "marketing-usage") {
  }
};

AgreeText.propTypes = {
  type: PropTypes.string.isRequired
};

export default AgreeText;
