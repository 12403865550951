import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const getSize = size => {
  let number;
  const numberSize = parseInt(size);
  if (!size || numberSize === 1) {
    number = 48;
  } else if (numberSize === 2) {
    number = 40;
  } else if (numberSize === 3) {
    number = 35;
  } else if (numberSize === 4) {
    number = 30;
  } else if (numberSize === 5) {
    number = 25;
  }

  return `
    width:${number}px;
    height:${number}px;
  `;
};

const Container = styled.div`
  text-align: center;
`;

const IconWrapper = styled.div`
  & > img {
    ${props => getSize(props.size)};
  }
`;

const GradeTextDiv = styled.div`
  margin-top: 7px;
  font-size: 18px;
  font-weight: 800;
`;

function UserGrade({ grade }) {
  const GradeNumber = grade.replace(/[^0-9]/g, "");
  const FakeArray = GradeNumber ? Array(parseInt(GradeNumber)).fill(null).map( (x,i) => i ) : [0];
  //console.log(FakeArray);
  const GradeText = () => {
    switch (grade) {
      case "D":
        return "BEGINNER";
      case "C1":
        return "PLAYER1";
      case "C2":
        return "PLAYER2";
      case "C3":
        return "PLAYER3";
      case "C4":
        return "PLAYER4";
      case "C5":
        return "PLAYER5";
      case "B1":
        return "STAR1";
      case "B2":
        return "STAR2";
      case "B3":
        return "STAR3";
      case "A":
        return "ALL STAR";
      case "S":
        return "SUPER STAR";
      default:
        break;
    }
  };

  const GradeIcon = () => {
    if (grade.indexOf("D") !== -1) {
      return "https://d1jozj1mdbjtd9.cloudfront.net/level_badge_beginner.png";
    } else if (grade.indexOf("C") !== -1) {
      return "https://d1jozj1mdbjtd9.cloudfront.net/level_badge_player.png";
    } else if (grade.indexOf("B") !== -1) {
      return "https://d1jozj1mdbjtd9.cloudfront.net/level_badge_star.png";
    } else if (grade.indexOf("A") !== -1) {
      return "https://d1jozj1mdbjtd9.cloudfront.net/level_badge_allstar.png";
    } else if (grade.indexOf("S") !== -1) {
      return "https://d1jozj1mdbjtd9.cloudfront.net/level_badge_sperstar.png";
    }
  };

  if (grade) {
    return (
      <Container>
        <IconWrapper size={GradeNumber}>
          {FakeArray.map(item => (
            <img key={item} src={GradeIcon()} alt={`grade${item}`} />
          ))}
        </IconWrapper>
        <GradeTextDiv>{GradeText()}</GradeTextDiv>
      </Container>
    );
  } else {
    return <Container>미진행</Container>;
  }
}

UserGrade.propTypes = {
  grade: PropTypes.oneOf([
    "D",
    "C1",
    "C2",
    "C3",
    "C4",
    "C5",
    "B1",
    "B2",
    "B3",
    "A",
    "S"
  ])
};

export default UserGrade;
