import React from "react";
import styled from "styled-components";
import Proptypes from "prop-types";

const Container = styled.input`
  /* width: 100%;
  height: 35px;
  padding: 5px 15px;
  color : #000;
  background-color: ${props => props.theme.fff};
  border: ${props => props.theme.boxBorder};
  border-radius: ${props => props.theme.borderRadius}; */

  width: 100%;
  height: 40px;
  max-width: 500px;
  padding: 5px 15px;
  font-size: 15px;
  color : #000;
  background-color:#EBEBEB;
  border: ${props => props.theme.boxBorder};
  border-radius: 10px;
  font-family: 'NanumSqure';
  &:active, &:focus {
    background-color: #fff;
    border:1px solid #2b2b2b;
  }
`;

const Input = ({ className='custom-input',placeholder, required = true, value, onChange, type = 'text', onKeyDown }) => (
  <Container
    className={className}
    placeholder={placeholder}
    required={required}
    value={value}
    onChange={onChange}
    type={type}
    onKeyDown={onKeyDown}
  />
);

Input.propTypes = {
  placeholder: Proptypes.string,
  required: Proptypes.bool,
  value:Proptypes.string,
  onChange:Proptypes.func.isRequired,
  type:Proptypes.string,
  onKeyDown:Proptypes.func
};

export default Input;
