import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Helmet } from "rl-react-helmet";
import Loader from "../../Components/Loader";
import Avatar from "../../Components/Avatar";

import Button from "../../Components/Button";
import Fab from "../../Components/Fab";
import SwipeableViews from "react-swipeable-views";
import Input from "../../Components/Input";
import { Arrow, Party, Close, LinkCopy, Dots } from "../../Components/Icons";

import { Link } from "react-router-dom";
import { useTheme } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Slide from "@material-ui/core/Slide";
import InputBase from "@material-ui/core/InputBase";
import SearchIcon from "@material-ui/icons/Search";
import { Divider, Zoom, withStyles } from "@material-ui/core";
import PullToRefresh from "rmc-pull-to-refresh";
import DedicateLoader from "../../Components/DedicateLoader";
import RefreshLoader from "../../Components/RefreshLoader";
import BaseHeader from "../../Components/Header/BaseHeader";
import DeclineDialog from "../../Components/DeclineDialog";

const Wrapper = styled.div`
  min-height: calc(100vh - 76px);
  background-color:#fff;
`;

const ContentWrapper = styled.div`
  /* padding-top: 134px; */
  margin: auto 0px;
  background-color: #fff;
  /* min-height: 100vh; */
  padding-top: 20px;
  min-height: calc(100vh - 124px);
  display: flex;
  flex-direction: column;
  @media (min-width: 500px) {
    max-width: ${props => props.theme.maxWidth};
    margin: 0 auto;
    width: 100%;
    position: relative;
  }
`;

const LoadingWrapper = styled(ContentWrapper)`
  min-height: 100vh;
`;

const SearchWrapper = styled(ContentWrapper)`
  background-color: #fff;
  min-height: 100vh;
  padding-top: 206px;
  overflow-y: scroll;
`;

const InviteListButton = styled.button`
  padding: 5px 11px;
  background-color: #fff;
  color: #fff;
  border: 1px solid #888;
  color: #000;
  border-radius: 5px;
`;

const MyTabs = styled(Tabs)`
  color: #000;
  padding: 15px 25px;
  max-width: 500px;
  width: 100%;
  font-weight: 600;
  margin: 0 auto;
  & button:not(.Mui-selected) {
    z-index: 0;
    color: #fff;
    font-weight: 400;
  }
  .MuiTabs-flexContainer {
    background-color: #65a6ff;
    border-radius: 20px;
  }
  .MuiTabs-indicator {
    top: 0px;
    height: 4px;
    border-radius: 20px;
    height: 38px;
    background-color: #fff;
  }
`;

const MyTabPanel = styled(TabPanel)`
  .MuiListItemText-secondary {
    color: #000;
  }
  & {
    /* padding-bottom: 47px; */
  }
`;

const InviteWrapper = styled.div`
  background-color: #f2f2f2;
  border-radius: 5px;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const InviteHeader = styled.div`
  text-align: right;
  padding: 5px;
`;

const InviteContent = styled.div`
  text-align: center;
  font-size: 23px;
  color: #000;
  margin: 0px 30px 50px 30px;
  flex: 1;
  display: flex;
  font-weight: 500;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  line-height: 1.5em;
`;

const InviteFooter = styled.div`
  padding: 0px 20px 20px;
`;

const ShareIconsWrap = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  &:last-child {
    padding: 15px 0px;
  }
`;

const ShareIconBox = styled.a`
  font-size: 13px;
  display: flex;
  flex-direction: column;
  & > img {
    max-width: 50px;
  }
`;
// const FacebookIcon = styled.img``;

// const NaverIcon = styled.img``;

const SNSIcon = styled.img``;

const WhiteIcon = styled.div`
  background-color: #fff;
  border-radius: 10px;
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const EmptySearchResult = styled.div`
  margin: 0px 15px;
  padding: 15px 0px;
  color: #969696;
  font-size: 14px;
  border-bottom: 1px solid #d5d5d5;
`;

const Title = styled.span`
  color: white;
  font-size: 24px;
  flex: 1;
  margin-left: -26px;
  text-align: center;
  font-weight: 600;
  /* position: absolute;
  width: 100%; */
`;

const HiddenInput = styled.input`
  opacity: 0;
  position: absolute;
  border: 0;
  touch-action: none;
  z-index:-1;
`;

const SliderButton = styled.div`
  display:flex;
  font-size:14px;
`;

const ActionButton = styled.span`
  height:100%;
  background-color: rgb(16, 142, 233);
  color: white;
  white-space:nowrap;
  display: flex;
  align-items: center;
  padding: 10px;
  height: 55px;
  min-width: 55px;
  justify-content: center;
  &:first-child {
    background-color:${props=>props.theme.yellowColor};
    color:#000;
  }
  &:nth-child(2) {
    background-color:#227fff;
    color:#fff;
  }
  &:last-child {
    background-color:#d5d5d5;
    color:#000;
  }
`;

const DotsWrap = styled.div`
  cursor: pointer;
  padding-right:10px;
`;

const UserInfoRows = styled.div`
  flex-grow: 1;
  margin: 0px 0px;
  background-color: #fff;
  color:#000;
  min-height: 55vh;
  padding-top: 64px;
  padding-bottom: 70px;
  max-width:500px;
`;

const UserInfoRow = styled.div`
  display: flex;
  font-size: 15px;
  flex-direction: column;
  padding: 15px 20px;
  text-align: center;
`;

const InputInfoColumn = styled.div`
  &:nth-child(1) {
    font-weight:500;
    font-size:16px;
  }
`;


const BioTextarea = styled.textarea`
  width:100%;
  max-width:500px;
  padding: 15px;
  background-color: #EBEBEB;
  border: ${props => props.theme.boxBorder};
  border-radius: 10px;
  color : #000;
  resize:none;
  font-size:16px;
  line-height: 1.4em;
  font-family: 'NanumSqure';
  height: 200px;
  &:focus {
    background-color: #fff;
    border:1px solid #2b2b2b;
  }
`;


const Form = styled.div`
    display: flex;
    flex: 1;
`;

const BottomWrap = styled.div`
  display: flex;
  justify-content: center;
`;


const styles = {
  tabRoot: {
    fontSize: 15,
    fontFamily: "NanumSqure",
    zIndex: 1,
    minHeight: "38px"
  },
  userWrap: {
    padding: "0px 15px",
    backgroundColor: "#fff",
    color: "#000",
    maxWidth: "600px",
    width: "100%",
    overflowY: "auto",
    "& ul.MuiList-padding": {
      paddingTop: 15,
      paddingBottom: 74,
      backgroundColor: "#fff"
    },
    "& div.MuiListItemAvatar-root": {
      position: "relative"
    },
    "& div.MuiTypography-body2": {
      fontFamily: "NanumSqure",
      fontSize: 15
    }
  },
  hr: {
    height: "50%"
  },
  appBar: {
    position: "fixed",
    backgroundColor: "#227fff"
  },
  title: {
    marginLeft: 16,
    flex: 1
  },
  invite: {
    marginLeft: "auto"
  },
  dialog: {
    "& .MuiDialog-paper": {
      backgroundColor: "#113f80"
    }
  },
  inviteDialog: {
    "& .MuiDialog-container": {
      alignItems: "flex-end"
    },
    "& .MuiDialog-paperWidthSm": {
      width: "100%",
      margin: 0,
      height: "50vh",
      maxWidth: 500,
      maxHeight: 400
    }
  },
  toolBar: {
    height: "200px",
    display: "block",
    textAlign: "right",
    paddingTop: "5px"
  },
  slideList: {
    paddingRight:0,
    paddingLeft:5,
    "& > .MuiListItem-gutters": {
      paddingRight:0,
      
    }
  },
  mainToolBar: {
    display: "flex"
  },
  tabToolBar: {
    maxWidth: 500,
    width: "100%",
    fontWeight: 600,
    margin: "0 auto"
  },
  search: {
    position: "relative",
    top: "25%",
    borderBottom: "1px solid"
  },
  searchIcon: {
    width: 40,
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  inputRoot: {
    width: "100%",
    color: "inherit",
    fontSize: "14px"
  },
  inputInput: {
    padding: "8px 8px 8px 40px",
    transition: "width 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    width: "100%"
  },
  SwipeView: {
    flex: 1,
    width: "100%",
    maxWidth: "924px",
    display: "flex",
    flexDirection: "column",
    margin: "0 auto",
    "& > .react-swipeable-view-container": {
      flex: 1
    }
  },
  boxRoot: {
    height: "100%",
    display: "flex",
    flexDirection: "column"
  },
  panelRoot: {
    height: "100%"
  }
};

function TabPanel(props) {
  const { children, value, index, classes, ...other } = props;
  if (props.className.indexOf("mapWrapper") !== -1) {
    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        <Box className={classes.boxRoot} p={0}>
          {children}
        </Box>
      </Typography>
    );
  } else {
    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        // {...other}
        className={classes.panelRoot}
      >
        <Box className={classes.boxRoot} p={0}>
          {children}
        </Box>
      </Typography>
    );
  }
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`
  };
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const TeamUserProfilePresenter = ({
  loading,
  data,
  requestUsers,
  setRequestUsers,
  invitedUsers,
  setInvitedUsers,
  applying,
  inviteUser,
  openSearch,
  openInvite,
  openShare,
  defaultHandleBack,
  handleInviteOpen,
  handleInviteClose,
  handleShareOpen,
  handleShareClose,
  handleSearchOpen,
  handleSearchClose,
  handleInvite,
  onAcceptTeam,
  onDeclineTeam,

  searchUsers,
  search,
  searching,
  searchRequest,
  searchInvite,
  handleProfileButton,
  selectRequest,
  setSelectRequest,
  declineMessage,
  onRefresh,
  refreshing,
  classes
}) => {
  if (loading) {
    return (
      <Wrapper>
        <Loader />
        <AppBar className={classes.appBar}>
          <Toolbar className={classes.mainToolBar}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="close"
              onClick={defaultHandleBack}
            >
              <Close />
            </IconButton>
            <Title>{"신청/초대 관리"}</Title>
          </Toolbar>
          <Toolbar className={classes.tabToolBar}>
            <MyTabs
              value={0}
              onChange={()=>{}}
              indicatorColor="primary"
              textColor="inherit"
              variant="fullWidth"
              aria-label="tabs"
            >
              <Tab
                className={classes.tabRoot}
                label="가입 신청"
                {...a11yProps(0)}
              />
              <Tab
                className={classes.tabRoot}
                label="회원 초대"
                {...a11yProps(1)}
              />
            </MyTabs>
          </Toolbar>
        </AppBar>
        <LoadingWrapper bgColor={"#fff"}>

        </LoadingWrapper>
      </Wrapper>
    );
  } else if (!loading && data && data.seeFullTeam) {
    
    const {
      seeFullTeam: { id, teamName, isHost, isManager }
    } = data;

    const theme = useTheme();
    const [value, setValue] = useState(0);
    const [checked, setChecked] = useState(data.seeFullTeam.requestUsers.map(i => false));
    const [openDecline, setOpenDecline] = useState(false);
    

    const handleChangeIndex = index => {
      setValue(index);
    };

    const handleOpenDecline = (req) => () => {
      console.log(req);
      setSelectRequest(req);
      setOpenDecline(true);
    };
  
    const handleCloseDecline = () => {
      setOpenDecline(false);
    };

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    const handleToggleChecked = index => () => {
      const newChecked = [...checked];
      newChecked[index] = !newChecked[index];
      setChecked(newChecked);
    };

    useEffect(() => {
      console.log(data.seeFullTeam);
      setRequestUsers(data.seeFullTeam.requestUsers);
      setInvitedUsers(data.seeFullTeam.inviteUsers);
    }, [data]);

    useEffect(() => {
      setRequestUsers(
        data.seeFullTeam.requestUsers.filter(
          req => req.user.username.indexOf(searchRequest.value) !== -1
        )
      );
    }, [searchRequest.value]);

    useEffect(() => {
      setInvitedUsers(
        data.seeFullTeam.inviteUsers.filter(
          user => user.username.indexOf(searchInvite.value) !== -1
        )
      );
    }, [searchInvite.value]);

    const kakaoSendLink = e => {
      e.preventDefault();

      console.log("kakao Link!");
      window.Kakao.Link.sendDefault({
        objectType: "feed",
        content: {
          title: "내가 원할때 언제나! WePlay",
          imageUrl: "https://d1jozj1mdbjtd9.cloudfront.net/weplay_logo_scrapimage.png",
          link: {
            mobileWebUrl: `https://play.weplaysports.net/#/team/${id}`,
            webUrl: `https://play.weplaysports.net/#/team/${id}`
          }
        },
        success: function(authObj) {
          console.log(authObj);
        },
        fail: function(error) {
          console.log(error);
          alert(JSON.stringify(error));
        }
      });
    };

    const CopyUrlToClipboard = () => {
      const obShareUrl = document.getElementById("ShareUrl");
      obShareUrl.value = `https://play.weplaysports.net/#/team/${id}`;
      obShareUrl.select();
      document.execCommand("copy");
      obShareUrl.blur();

      if(window.webkit && data && data.me) {
        try {
          if(typeof window.webkit.messageHandlers.showAlert.postMessage === "function") {
            window.webkit.messageHandlers.showAlert.postMessage("클립보드에 복사되었습니다.");
          }
        } catch(e) {
          alert(e);
          console.log(e);
        }
      }
    };

    return (
      <Wrapper>
        <AppBar className={classes.appBar}>
          <Toolbar className={classes.mainToolBar}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="close"
              onClick={defaultHandleBack}
            >
              <Close />
            </IconButton>
            <Title>{"신청/초대 관리"}</Title>
          </Toolbar>
          <Toolbar className={classes.tabToolBar}>
            <MyTabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="inherit"
              variant="fullWidth"
              aria-label="tabs"
            >
              <Tab
                className={classes.tabRoot}
                label="가입 신청"
                {...a11yProps(0)}
              />
              <Tab
                className={classes.tabRoot}
                label="회원 초대"
                {...a11yProps(1)}
              />
            </MyTabs>
            {(isHost || isManager) && (
              <Fab
                type={"invite"}
                top={"14px"}
                right={"20px"}
                width={"40px"}
                onClick={handleSearchOpen}
              />
              // <InviteButton onClick={handleSearchOpen}>초대</InviteButton>
            )}
          </Toolbar>
        </AppBar>

        {applying && <Loader />}
        <Helmet>
          <title>{teamName} | WePlay</title>
        </Helmet>

        <PullToRefresh
          style={{ overflow: "auto", paddingTop:114, minHeight:"100vh", maxHeight:"100vh" }}
          direction="down"
          distanceToRefresh={50}
          damping={100}
          onRefresh={() => onRefresh()}
          refreshing={refreshing}
          //onScroll={e => onScroll(e)}
          indicator={{
            activate: <DedicateLoader />,
            deactivate: <></>,
            release: <RefreshLoader />,
            finish: <></>
          }}
        >

        <ContentWrapper>
          <Fab
            type={"share"}
            top={"85%"}
            right={"20px"}
            onClick={handleShareOpen}
          />

          <SwipeableViews
            className={classes.SwipeView}
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={value}
            onChangeIndex={handleChangeIndex}
            disabled={true}
          >
            <MyTabPanel value={value} index={0} classes={classes}>
              <Grid className={classes.userWrap} item xs={12} md={6}>
                <Input
                  placeholder={"검색하세요"}
                  value={searchRequest.value}
                  onChange={searchRequest.onChange}
                />
                {requestUsers.length === 0 ? (
                  <EmptySearchResult>가입 신청이 없습니다.</EmptySearchResult>
                ) : (
                  <List dense>
                    {requestUsers.length > 0 &&
                      requestUsers.map((req,i) => (
                          <ListItem 
                            key={req.user.id}
                            className={classes.slideList}
                            button 
                            onClick={handleToggleChecked(i)}     
                          >
                            <ListItemAvatar>
                              <Avatar size={"sm"} url={req.user.avatar} />
                            </ListItemAvatar>

                            {req.user.basket ? (
                              <ListItemText
                                primary={`${req.user.username}(${
                                  req.user.basket.backNo
                                    ? req.user.basket.backNo
                                    : "미등록"
                                })`}
                                secondary={`Lv.${req.user.level} ｜ ${
                                  req.user.basket.position
                                    ? req.user.basket.position
                                    : "미등록"
                                }`}
                              />
                            ) : (
                              <ListItemText
                                primary={req.user.username}
                                secondary={`Lv.${req.user.level}`}
                              />
                            )}

                            {checked[i] ? (
                              <SliderButton elevation={4}>
                                <Zoom in={checked[i]}>
                                  <ActionButton
                                    onClick={handleProfileButton(req.user.id)}
                                  >
                                    프로필
                                  </ActionButton>
                                </Zoom>
                                <Zoom
                                  in={checked[i]}
                                  style={{
                                    transitionDelay: checked[i]
                                      ? "100ms"
                                      : "0ms"
                                  }}
                                >
                                  <ActionButton onClick={onAcceptTeam(req)}>
                                    수락
                                  </ActionButton>
                                </Zoom>
                                <Zoom
                                  in={checked[i]}
                                  style={{
                                    transitionDelay: checked[i]
                                      ? "200ms"
                                      : "0ms"
                                  }}
                                >
                                  <ActionButton
                                    onClick={handleOpenDecline(req)}
                                  >
                                    거부
                                  </ActionButton>
                                </Zoom>
                              </SliderButton>
                            ) : (
                              <DotsWrap>
                                <Dots />
                              </DotsWrap>
                            )}
                          </ListItem>
                      ))}
                  </List>
                )}
              </Grid>
            </MyTabPanel>

            <MyTabPanel value={value} index={1} classes={classes}>
              <Grid className={classes.userWrap} item xs={12} md={6}>
                <Input
                  placeholder={"검색하세요"}
                  value={searchInvite.value}
                  onChange={searchInvite.onChange}
                />
                {invitedUsers.length === 0 ? (
                  <EmptySearchResult>초대한 회원이 없습니다.</EmptySearchResult>
                ) : (
                  <List dense>
                    {invitedUsers.length > 0 &&
                      invitedUsers.map(user => (
                        <Link key={user.id} to={`/user/${user.id}`}>
                          <ListItem button>
                            <ListItemAvatar>
                              <Avatar size={"sm"} url={user.avatar} />
                            </ListItemAvatar>
                            {user.basket ? (
                              <ListItemText
                                primary={`${user.username}(${
                                  user.basket.backNo
                                    ? user.basket.backNo
                                    : "미등록"
                                })`}
                                secondary={`Lv.${user.level} ｜ ${
                                  user.basket.position
                                    ? user.basket.position
                                    : "미등록"
                                }`}
                              />
                            ) : (
                              <ListItemText
                                primary={user.username}
                                secondary={`Lv.${user.level}`}
                              />
                            )}
                            <ListItemSecondaryAction>
                              <IconButton
                                edge="end"
                                color="inherit"
                                aria-label="detail"
                              >
                                <Arrow />
                              </IconButton>
                            </ListItemSecondaryAction>
                          </ListItem>
                        </Link>
                      ))}
                  </List>
                )}
              </Grid>
            </MyTabPanel>
          </SwipeableViews>
        </ContentWrapper>
        </PullToRefresh>


        <DeclineDialog 
          title={selectRequest?selectRequest.user.username:""}
          type={"team"}
          open={openDecline}
          handleClose={handleCloseDecline}
          handleSend={onDeclineTeam}
          Transition={Transition}
          messages={declineMessage}
          isLoading={applying}
        />
        {/* <Dialog
          className={classes.dialog}
          fullScreen
          open={openDecline}
          onClose={handleCloseDecline}
          TransitionComponent={Transition}
        >
          <BaseHeader
            isClose={false}
            isBack={true}
            text={"가입 신청 거부"}
            onClickBack={handleCloseDecline}
          />
          <Form>
            <UserInfoRows>
              <UserInfoRow>

                <InputInfoColumn>
                  <BioTextarea placeholder="거부 사유를 입력해주세요" {...declineMessage} cols="4" rows="6"></BioTextarea>
                </InputInfoColumn>
              </UserInfoRow>
              {applying ? <CircularProgress /> : <Button color={"#000"} position={"relative"} onClick={onDeclineTeam(selectUser)} text={"보내기"} />}
            </UserInfoRows>
          </Form>
        </Dialog> */}

        <Dialog
          className={classes.dialog}
          fullScreen
          open={openSearch}
          onClose={handleSearchClose}
          TransitionComponent={Transition}
        >
          <AppBar className={classes.appBar}>
            <Toolbar className={classes.toolBar}>
              <IconButton
                edge="end"
                color="inherit"
                onClick={handleSearchClose}
                aria-label="close"
              >
                <Close />
              </IconButton>
              <div className={classes.search}>
                <div className={classes.searchIcon}>
                  <SearchIcon />
                </div>
                <InputBase
                  placeholder="회원을 검색하세요"
                  classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput
                  }}
                  inputProps={{ "aria-label": "search" }}
                  value={search.value}
                  onChange={search.onChange}
                />
              </div>
            </Toolbar>
          </AppBar>

          <SearchWrapper>
            <List dense>
              {searching && <CircularProgress />}
              {searchUsers &&
                searchUsers.length > 0 &&
                searchUsers.map(user => (
                  <ListItem key={user.id}>
                    <ListItemAvatar>
                      <Link to={`/user/${user.id}`}>
                        <Avatar size={"sm"} url={user.avatar} />
                      </Link>
                    </ListItemAvatar>
                    <ListItemText
                      primary={user.username}
                      secondary={`Lv. ${user.basket ? user.basket.level : "0"}`}
                    />
                    <InviteListButton onClick={handleInviteOpen(user)}>
                      + 초대
                    </InviteListButton>
                  </ListItem>
                ))}
            </List>
          </SearchWrapper>
        </Dialog>

        <Dialog
          className={classes.inviteDialog}
          open={openInvite}
          onClose={handleInviteClose}
          TransitionComponent={Transition}
        >
          <InviteWrapper>
            <InviteHeader>
              <IconButton
                edge="end"
                color="inherit"
                onClick={handleInviteClose}
                aria-label="close"
              >
                <Close fill={"#000"} />
              </IconButton>
            </InviteHeader>
            <InviteContent>
              <Party />
              <div>
                {inviteUser && inviteUser.username}님에게 초대장을
                <br />
                보내시겠습니까?
              </div>
            </InviteContent>
            <InviteFooter>
              <Button color={"#000"} text={"보내기"} onClick={handleInvite} />
            </InviteFooter>
          </InviteWrapper>
        </Dialog>

        <Dialog
          className={classes.inviteDialog}
          open={openShare}
          onClose={handleShareClose}
          TransitionComponent={Transition}
        >
          <InviteWrapper>
            <InviteHeader>
              <IconButton
                edge="end"
                color="inherit"
                onClick={handleShareClose}
                aria-label="close"
              >
                <Close fill={"#000"} />
              </IconButton>
            </InviteHeader>
            <InviteContent>
              <ShareIconsWrap>
                <ShareIconBox id="kakao-link-btn" onClick={kakaoSendLink}>
                  <SNSIcon src={"https://d1jozj1mdbjtd9.cloudfront.net/share_btn_kakao.png"} />
                  <p>카카오톡</p>
                </ShareIconBox>
                <ShareIconBox
                  href={`https://www.facebook.com/sharer.php?u=play.weplaysports.net/#/team/${id}`}
                >
                  <SNSIcon src={"https://d1jozj1mdbjtd9.cloudfront.net/share_btn_facebook.png"} />
                  <p>페이스북</p>
                </ShareIconBox>
                <ShareIconBox
                  href={`https://lineit.line.me/share/ui?url=play.weplaysports.net/#/team/${id}&text=위플레이로초대합니다`}
                >
                  <SNSIcon src={"https://d1jozj1mdbjtd9.cloudfront.net/share_btn_line.png"} />
                  <p>라인</p>
                </ShareIconBox>
                <ShareIconBox
                  href={`https://twitter.com/intent/tweet?url=play.weplaysports.net/#/team/${id}&text=위플레이로초대합니다`}
                >
                  <SNSIcon src={"https://d1jozj1mdbjtd9.cloudfront.net/share_btn_twitter.png"} />
                  <p>트위터</p>
                </ShareIconBox>
              </ShareIconsWrap>
              <Divider />
              <ShareIconsWrap>
                <ShareIconBox>
                  <WhiteIcon onClick={CopyUrlToClipboard}>
                    <LinkCopy fill={"#000"} />
                  </WhiteIcon>
                  <p>링크복사</p>
                </ShareIconBox>
                <HiddenInput id="ShareUrl" type="text" />
              </ShareIconsWrap>
            </InviteContent>
          </InviteWrapper>
        </Dialog>
      </Wrapper>
    );
  }
  return null;
};


export default withStyles(styles)(TeamUserProfilePresenter);
