import { gql } from "apollo-boost";

export const GET_MY_TEAM = gql`
  query seeMyTeam($sportId:String!) {
    seeMyTeam(sportId:$sportId) {
      id
      teamName
      bio
      location {
        id
        latitude
        longitude
      }
      place {
        id
        placeName
        address
        shortAddress
      }
    }
  }
`;

export const GET_ME = gql`
  {
    me {
      id
      username
    }
  }
`;

export const ADD_GAME = gql`
  mutation addGame(
    $sportId: String!
    $homeId: String!
    $placeId: String
    $lat:Float
    $lng:Float
    $placeName:String
    $address:String
    $shortAddress:String
    $playerCnt: Int!
    $gameDate: String!
    $startTime: String!
    $endTime: String!
    $title: String!
    $message: String!
  ) {
    addGame(
      sportId: $sportId
      homeId: $homeId
      placeId: $placeId
      lat:$lat
      lng:$lng
      placeName:$placeName
      address:$address
      shortAddress:$shortAddress
      playerCnt: $playerCnt
      gameDate: $gameDate
      startTime: $startTime
      endTime: $endTime
      title: $title
      message: $message
    ) {
      id
      
    }
  }
`;

export const EDIT_PLACE = gql`
  mutation editPlace(
    $lat:Float!
    $lng: Float!
    $placeName: String!
    $address: String!
    $shortAddress: String!
  ) {
    editPlace(
      lat: $lat
      lng: $lng
      placeName: $placeName
      address: $address
      shortAddress: $shortAddress
    ) {
      id
      placeName
    }
  }
`;

