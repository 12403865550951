import { gql } from "apollo-boost";

export const CREATE_TEAM = gql`
  mutation createTeam(
    $logo: String
    $profiles: [String]
    $teamName: String!
    $sportId: String!
    $contact: String!
    $uniformColor: String
    $matchingInfo: String
    $matchingPosition: String
    $bio: String
    $level: Int!
    #$placeId: String
    $lat: Float
    $lng: Float
    $placeName: String
    $address: String
    $shortAddress: String
  ) {
    createTeam(
      logo: $logo
      profiles:$profiles
      teamName: $teamName
      sportId: $sportId
      contact: $contact
      uniformColor:$uniformColor
      matchingInfo:$matchingInfo
      matchingPosition:$matchingPosition
      bio: $bio
      level: $level
      lat : $lat
      lng : $lng
      placeName : $placeName
      address : $address
      shortAddress : $shortAddress
    ) {
      id
      teamName
    }
  }
`;

export const SEARCH_PLACE = gql`
  query seePlaces($skip:Int, $first:Int, $term:String) {
    seePlaces(skip:$skip, first:$first, term:$term) {
      id
      placeName
      address
      picture {
        id
        url
      }
    }
  }
`;

