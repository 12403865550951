import { gql } from "apollo-boost";

export const SEE_TEAMS = gql`
  query seeCupGames($first: Int, $skip: Int, $cupId: String!) {
    seeCupGames(first: $first, skip: $skip, cupId: $cupId) {
      id
      gameName
      sportsCode {
        id
        code
      }
      homeTeam {
        id
        logo
        teamName
        level
      }
      awayTeam {
        id
        logo
        teamName
        level
      }
      place {
        id
        placeName
        address
      }
      winner
      playerCnt
      gameStatus
      gameDate
      startTime
      endTime
    }
  }
`;