import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  flex:1;
  align-items:center;
  justify-content:center;
  display:flex;
  background-color:#fff;
  color:#000;
  flex-direction: column;
`;

const Logo = styled.img`
  margin-bottom:20px;
  width: 100px;
  margin-bottom: 50px;
`;

const Code = styled.div`
  color:red;
  font-size:20px;
  margin-bottom: 15px;
`;
const Text = styled.div`
  font-size:18px;
`;

export const NotFound = ({ text }) => {
  return (
    <Container>
      <Logo src={"https://d1jozj1mdbjtd9.cloudfront.net/weplay_symbol.png"} />
      <Code>
        {"404 Not Found"}
      </Code>
      <Text>
        {text}
      </Text>
    </Container>
  )
}