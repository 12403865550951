import React, { useEffect } from "react";
import styled from "styled-components";
import { Helmet } from "rl-react-helmet";
import Loader from "../../Components/Loader";
import BoldText from "../../Components/BoldText";
import BaseHeader from "../../Components/Header/BaseHeader";
import PostCode from "../../Components/PostCode";
import Button from "../../Components/Button";
import Input from "../../Components/Input";
import Select from "../../Components/Select";
import DatePicker from "../../Components/DatePicker";
import TimePicker from "../../Components/TimePicker";
import CircularProgress from "@material-ui/core/CircularProgress";
import moment from "moment";
import "moment/locale/ko";

const Wrapper = styled.div`
  width: 100%;
  flex-grow: 1;
  display: flex;
  @media (min-width: 500px) {
    
    margin: 0 auto;
  }
  & .MuiInputBase-input {
    background-color: #EBEBEB!important;
    border-radius: 8px!important;
    padding: 10px 10px!important;
  }
`;

const UserInfoRows = styled.div`
  flex-grow: 1;
  /* margin: 0px 10px; */
  background-color: #fff;
  color:#000;
  min-height: 55vh;
  padding-top: 10vh;
  padding-bottom: 30px;
`;

const UserInfoRow = styled.div`
  display: flex;
  font-size: 15px;

  flex-direction: column;
  padding: 15px 20px;

`;

const InputInfoColumn = styled.div`
  &:nth-child(1) {
    margin-bottom: 15px;
    font-weight:500;
    font-size:16px;
  }
  &:nth-child(2) {
    display: flex;
    flex-direction: column;
  }
`;

const TitleRow = styled.div`
   margin-bottom: 15px;
   font-weight:500;
    font-size:16px;
`;

const ProfileHint = styled.span`
  font-size:14px;
  padding-left:10px;
`;

const BioTextarea = styled.textarea`
  width:100%;
  max-width:500px;
  padding: 5px 15px;
  background-color: #EBEBEB;
  border: ${props => props.theme.boxBorder};
  border-radius: 10px;
  color : #000;
  resize:none;
  font-size:16px;
  font-family: 'NanumSqure';
  &:focus {
    background-color: #fff;
    border:1px solid #2b2b2b;
  }
`;

const Form = styled.div`
    display: flex;
    flex: 1;
    margin: 0 auto;
    max-width: ${props => props.theme.maxWidth};
`;

const BottomWrap = styled.div`
  display: flex;
  justify-content: center;
`;

export default ({
  uploading,
  gameDateInput,
  startTimeInput,
  endTimeInput,
  titleInput,
  messageInput,
  contactInput,
  playerCntInput,
  setPlaceId,
  loading,
  onSubmit,
  onClickClose,
  setAddressInfo,
  data
}) => {
  if (loading === true) {
    return (
      <Wrapper>
        <Loader />
        <BaseHeader
          isClose={true}
          text={"경기 수정"}
          onClickClose={onClickClose}
        />
        <Form>
          <UserInfoRows></UserInfoRows>
        </Form>
      </Wrapper>
    );
  } else if (!loading && data && data.seeFullGame) {
    const {
      seeFullGame: {
        gameDate,
        startTime,
        endTime,
        title,
        message,
        playerCnt,
        place: {
          id:placeId,
          address,
          //placeName,
          contact,
          //location: { latitude, longitude }
        }
      }
    } = data;
    useEffect(()=> {
      
      setPlaceId(placeId);
      gameDateInput.setValue(moment(gameDate).format("YYYY-MM-DD"));
      startTimeInput.setValue(startTime);
      endTimeInput.setValue(endTime);
      if(title) titleInput.setValue(title);
      if(message) messageInput.setValue(message);
      if(contact) contactInput.setValue(contact);
      if(playerCnt) playerCntInput.setValue(playerCnt);
    },[]);

    return (
      <Wrapper>
        <Helmet>
          <title>경기 수정 | WePlay</title>
        </Helmet>
        {uploading && <Loader />}
        <BaseHeader
          isClose={true}
          text={"경기 수정"}
          onClickClose={onClickClose}
        />
        <Form>
          <UserInfoRows>

            <UserInfoRow>
              <InputInfoColumn>경기 일정<ProfileHint>*</ProfileHint></InputInfoColumn>
              <InputInfoColumn>
                <DatePicker 
                 gameDate={gameDateInput}
                />
              </InputInfoColumn>
            </UserInfoRow>

            <UserInfoRow>
              <InputInfoColumn>경기 시간<ProfileHint>*</ProfileHint></InputInfoColumn>
              <InputInfoColumn>
                <TimePicker 
                 startTime={startTimeInput}
                 endTime={endTimeInput}
                />
              </InputInfoColumn>
            </UserInfoRow>

            <UserInfoRow>
              <InputInfoColumn>경기장<ProfileHint>*</ProfileHint></InputInfoColumn>
                <InputInfoColumn>
                  <PostCode
                    setAddressInfo={setAddressInfo}
                    defaultValue={address}
                    text={"경기장 변경"}
                  />
              </InputInfoColumn>
              
            </UserInfoRow>

            <UserInfoRow>
              <InputInfoColumn>
                <BoldText text={"경기장 연락처"} />
              </InputInfoColumn>
              <InputInfoColumn>
                <Input value={contactInput.value} defaultValue={""} onChange={contactInput.onChange} placeholder={"'-'를 제외하고 입력해주세요"} />
              </InputInfoColumn>
            </UserInfoRow>
          

            <UserInfoRow>
              <InputInfoColumn>
                <BoldText text={"플레이어 수"} /><ProfileHint>*</ProfileHint>
              </InputInfoColumn>
              <InputInfoColumn>
                <Select
                  items={[
                    { text: "3 vs 3", value: 3 },
                    { text: "5 vs 5", value: 5 }
                  ]}
                  value={playerCntInput.value}
                  onChange={playerCntInput.onChange}
                />
              </InputInfoColumn>
            </UserInfoRow>

            <UserInfoRow>
              <InputInfoColumn><BoldText text={"경기 소개"} /><ProfileHint>* 최대 300자 제한</ProfileHint></InputInfoColumn>
              <TitleRow>
                {/* <Select
                  items={[
                    { text: "3 vs 3", value: 3 },
                    { text: "5 vs 5", value: 5 }
                  ]}
                  value={playerCnt.value}
                  onChange={playerCnt.onChange}
                /> */}
                <Input value={titleInput.value} defaultValue={""} onChange={titleInput.onChange} placeholder={"제목을 입력하세요"} />
              </TitleRow>

              <InputInfoColumn>
                <BioTextarea {...messageInput} cols="4" rows="6"></BioTextarea>
              </InputInfoColumn>
            </UserInfoRow>

            {uploading ? (
                <CircularProgress />
              ) : (
                <Button color={"#000"} position={"relative"} onClick={onSubmit} text={"수정하기"} />
              )}
          </UserInfoRows>
        </Form>
      </Wrapper>
    );
  }
};
